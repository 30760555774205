import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getStorageItem, updateStorageItem } from 'helpers/localStorage'
import { getStorageKey } from '.'
import { t } from 'helpers/i18n'

export default createSelectionListViewComponent({
  pageTitle: () => t('list'),
  allowInfiniteLoader: () => false,
  allowSearch: () => false,
  getTextField: () => 'name',
  getValue: () => getStorageItem(getStorageKey(), {}).filterDto?.listId,
  getOnChange: (self) => (each) =>
    updateStorageItem(getStorageKey(), {}, (draft) => {
      draft.filterDto.listId = each.id
      draft.filterDto.listName = each.name
    }),
})()

export const I18N_SESSION_KEY = 'i18n'
export const AUTH_SESSION_KEY = 'auth'
export const INACTIVITY_SESSION_KEY = 'inactivityWarning'
export const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000 // 2 hours
export const REFRESH_TOKEN_INTERVAL = 20 * 60 * 1000 // 20 minutes
export const REFRESH_TOKEN_SESSION_KEY = 'tokenRefreshed'
export const USER_LOGGED_IN_SESSION_KEY = 'userLoggedIn'
export const IMPERSONATOR_USERNAME = 'TRMSMASTER2'
export const IMPERSONATION_SOURCE_URL = '/distributor/manageCustomers'
export const LAST_TAB_VISITED_STORAGE_KEY = 'lastTabVisited'
export const LAST_TENANT_STORAGE_KEY = 'lastTenant'
export const OFFLINE_SESSION_KEY = 'isOffline'
export const SERVICE_WORKER_WAITING = 'serviceWorkerRegistrationWaiting'

import { PLACEHOLDER } from 'helpers/utils'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'

export default createChildListViewComponent({
  getStorageKey: (self) => self.props.storageKey ?? 'orders.formView.orderSuppliers',
  allowSearch: () => false,
  renderItem:
    ({ props }) =>
    (item) => (
      <IonItem lines="full" onClick={() => props.onClick(item)} detail>
        <IonLabel>
          {item.supplierName}
          <br />
          <IonText color="medium">
            <small>
              {t('poNumber:')} {item.purchaseOrderNumber || PLACEHOLDER}
              <br />
              {t('dueDate:')} {formatDateTime(item.dueDate) || t('none')}
              <br />
              {t('hold:')} {item.hold ? <IonText color="danger">{t('yes')}</IonText> : t('no')}
            </small>
          </IonText>
        </IonLabel>
      </IonItem>
    ),
  getSortByFields: (self) => [
    { fieldName: 'supplierName', languageKey: 'supplier' },
    { fieldName: 'purchaseOrderNumber', languageKey: 'poNumber' },
    { fieldName: 'dueDate', languageKey: 'dueDate' },
    { fieldName: 'hold', languageKey: 'hold' },
  ],
  getDefaultSortByField: (self) => 'supplierName',
})()

import { IonItem, IonButton } from '@ionic/react'
import { isEmpty, isNil } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { updateSessionItem } from 'helpers/sessionStorage'
import { Inventory, inventoryItemSize } from 'components/Inventory/ListView'
import Icon from 'elements/Icon'

export default createListViewComponent({
  getStorageKey: () => 'cycleCounts.formView.addFromInventory',
  pageTitle: (self) => t('addFromInventory'),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys.splice(index, 1)
          } else {
            draft.selectedRowKeys.push(item.id)
          }
        })
      }}
      detail={false}
      button
    >
      <Inventory self={self} item={item} showLastCycleCount />
      <Icon.Check checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: (self) => inventoryItemSize(self) + 18,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={async () => {
        try {
          self.setState('loadingIsOpen', true)

          const response = await self.props.populateCycleCountItems(
            self.props.match.params.itemId,
            self.state.items
              .filter((each) => self.state.selectedRowKeys.includes(each.id))
              .map((each) => each.barcode)
          )

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const seed = Date.now() * -1

          updateSessionItem('cycleCounts.formView', {}, (draft) => {
            response.value.data.items
              .filter((each) => each.productId && !each.lockedForCycleCount)
              .forEach((each, index) => {
                if (isNil(draft.detailItems.find((one) => one.barcode === each.barcode))) {
                  draft.detailItems.push({
                    ...each,
                    id: seed - index,
                    cycleCountId: self.props.match.params.itemId,
                  })
                }
              })
          })

          self.updateState((draft) => {
            draft.loadingIsOpen = false
            draft.selectedRowKeys = []
          })

          showClientNotifications({ response })
        } catch (error) {
          self.setState('loadingIsOpen', false)
          showError({ error })
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
    >
      {t('addToCycleCount')}
      {!isEmpty(self.state.selectedRowKeys) ? ` (${self.state.selectedRowKeys.length})` : ''}
    </IonButton>
  ),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'itemDescription', languageKey: 'description' },
    { fieldName: 'lastCycleCount', languageKey: 'lastCycleCount' },
  ],
  allowOffline: (self) => false,
})()

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t, toLocaleCurrency, toLocaleNumber } from 'helpers/i18n'
import { formatDateTime, tryParseMoment, ZERO_HOUR } from 'helpers/dateTime'
import { isReadOnly } from 'helpers/formViews'

const timeSpan = (self, item) => {
  if (item.timeType === 'StartStop') {
    const startMoment = tryParseMoment(item.startTime, ZERO_HOUR).year(2000).month(1).date(1)
    const stopMoment = tryParseMoment(item.stopTime, ZERO_HOUR).year(2000).month(1).date(1)

    return `${startMoment.format('h:mm A')} To ${stopMoment.format('h:mm A')}`
  }

  return t('na')
}

export default createChildListViewComponent({
  getStorageKey: () => 'jobs.formView.labour',
  allowSearch: () => false,
  allowDelete: (self) => (item) => !isReadOnly(self),
  renderItem: (self) => (item) => (
    <IonItem lines="full" onClick={() => self.props.onClick(item)} button>
      <IonLabel>
        {item.id > 0 ? item.id : t('na')} - {item.performedByName}
        <br />
        <IonText color="medium">
          <small>
            {t('labourRate:')} {item.labourRateDescription}
            <br />
            {t('date:')} {formatDateTime(item.labourDate)}, {t('time:')} {timeSpan(self, item)}
            <br />
            {t('hours:')}{' '}
            {toLocaleNumber(item.actualHours, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            , {t('total:')} {toLocaleCurrency(item.totalCost)}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'performedByName', languageKey: 'performedBy' },
    { fieldName: 'labourRateDescription', languageKey: 'labourRate' },
    { fieldName: 'labourDate', languageKey: 'date' },
    { fieldName: 'timeSpan', languageKey: 'time' },
    { fieldName: 'actualHours', languageKey: 'hours' },
    { fieldName: 'totalCost', languageKey: 'total' },
  ],
  getDefaultSortByField: (self) => 'labourDate',
  getDefaultSortOrder: (self) => 'desc',
})()

import { getSharedItemNoScope, getPrivateItemNoScope } from 'helpers/localForage'
import { LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import { getSessionItem } from 'helpers/sessionStorage'
import { AUTH_SESSION_KEY, IMPERSONATOR_USERNAME } from 'options/auth'

export const getCustomerLocationCounts = (customerId) => {
  const { tenant } = getSessionItem(AUTH_SESSION_KEY).current

  return getPrivateItemNoScope(
    `${tenant.subdomain}.${customerId}.${IMPERSONATOR_USERNAME}.${LOCATION_COUNTS_SAVED_KEY}`,
    []
  )
}

export const getOfflineTranslations = (customerId) => {
  try {
    const { tenant, user } = getSessionItem(AUTH_SESSION_KEY).current

    return getSharedItemNoScope(`${tenant.subdomain}.${customerId}.translations.${user.languageCode}`)
  } catch (error) {
    console.warn(error)

    return null
  }
}

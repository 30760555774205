import * as React from 'react'
import { useLocation } from 'react-router-dom'
import useOnlineStatus from '@rehooks/online-status'
import { IonList, IonItem, IonLabel, IonText } from '@ionic/react'
import { get, isEmpty } from 'lodash'
import { t } from 'helpers/i18n'
import { getMenuItemThisLevel } from 'helpers/auth'
import Icon from 'elements/Icon'

export default function (props) {
  const [underDevelopment, setUnderDevelopment] = React.useState(false)

  const url = useLocation().pathname
  const level = getMenuItemThisLevel(url)
  const items = get(level, 'items', [])
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (isEmpty(items)) {
      window.setTimeout(() => setUnderDevelopment(true), 250)
    }
  }, [])

  return !isEmpty(items) ? (
    <IonList>
      {items.map((each) => {
        const disabled = each.disabled || (!isOnline && !each.allowOffline)
        return (
          <IonItem
            key={each.key}
            lines="full"
            {...get(props, `listItemProps.${each.key}`, { routerLink: `${url}/${each.key}` })}
            disabled={disabled}
          >
            <Icon slot="start" type={each.iconName} />
            <IonLabel>
              <IonText color={disabled ? 'medium' : undefined}>{t(each.languageKey)}</IonText>
            </IonLabel>
          </IonItem>
        )
      })}
    </IonList>
  ) : underDevelopment ? (
    <p dangerouslySetInnerHTML={{ __html: t('underDevelopmentDescription') }} className="ion-margin" />
  ) : null
}

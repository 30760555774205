import * as React from 'react'
import cx from 'clsx'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonPopover,
  IonText,
  IonRow,
  IonCol,
  IonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import {
  set,
  toString as str,
  cloneDeep,
  isEmpty,
  isNil,
  pick,
  range,
  get,
  isEqual,
  orderBy,
  omit,
} from 'lodash'
import { PLACEHOLDER, message, tryParseInt } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showValidationErrors } from 'helpers/errors'
import { getSessionItem, updateSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { formatDateTime } from 'helpers/dateTime'
import {
  MONTHS,
  FREQUENCIES,
  DAYS_OF_WEEK,
  MONTH_MODES,
  SKIP_NOTIFICATION_CHECKS,
  JOB_FREQUENCIES,
  RECURRING_FREQUENCIES,
  WEEKS_OF_MONTH,
} from 'options/tasks'
import taskTypes from 'options/tasks/taskTypes'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import Icon from 'elements/Icon'
import DatePicker from 'elements/DatePicker'

const RadioContainer = styled.div`
  height: 68px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`

const HiddenContainer = styled.div`
  height: 44px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  visibility: hidden;
`

export const getStorageKey = () => 'assets.formView.tasks.formView'

export default function (props) {
  const {
    parentStorageKey = 'assets.formView',
    taskType = taskTypes.ScheduledJob,
    settingsType = 'asset',
    parentRecord = getSessionItem('assets.formView', {}).item,
  } = props

  const [state, updateState] = useImmer({
    tabsActiveKey: 'task',
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  function fetchItem() {
    const parentState = getSessionItem(parentStorageKey)
    const item =
      props.match.params.itemId === '0'
        ? {
            id: Date.now() * -1,
            active: true,
            taskType,
            frequency: 'Once',
            jobTaskSettings: { assignedOperatorId: 0, ...props.jobTaskSettings },
            recurOnMonths: [],
            recurOnDaysOfMonth: [],
            recurOnDaysOfWeek: [],
            recurOnWeeksOfMonth: [],
            sendToEmails: props.user.email ? [props.user.email] : [],
            monthMode: MONTH_MODES.MonthDay,
            startDate: new Date().toJSON(),
            recurPeriods: 1,
            owner: props.user.userName,
          }
        : cloneDeep(parentState.taskItems.find((one) => str(one.id) === props.match.params.itemId))

    if (item.taskType === taskTypes.ScheduledJob && settingsType === 'asset') {
      set(item, 'jobTaskSettings.assetId', parentRecord?.id)
      set(item, 'jobTaskSettings.assetBarcode', parentRecord?.barcode)
    }

    item.sendToEmails = item.sendToEmails.join(' ')

    updateState((draft) => {
      draft.item = cloneDeep(item)
      draft.itemOriginal = cloneDeep(item)
      draft.parentState = parentState
    })
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.name)) {
      errors.name = t('errorMissingRequiredField')
    }

    if (isNil(values.jobTaskSettings.templateId)) {
      errors.templateId = t('errorMissingRequiredField')
    }

    if (isNil(values.jobTaskSettings.assignedOperatorId)) {
      errors.assignedOperatorId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.sendToEmails) && SKIP_NOTIFICATION_CHECKS.some((one) => get(values, one) === true)) {
      errors.sendToEmails = t('errorMissingRequiredField')
    }

    if (isEmpty(values.recurOnDaysOfWeek) && values.frequency === 'Weekly') {
      errors.recurOnDaysOfWeek = t('errorMissingRequiredField')
    }

    if (
      isEmpty(values.recurOnDaysOfMonth) &&
      values.monthMode === MONTH_MODES.MonthDay &&
      values.frequency === 'Monthly'
    ) {
      errors.recurOnDaysOfMonth = t('errorMissingRequiredField')
    }

    if (
      isEmpty(values.recurOnWeeksOfMonth) &&
      values.monthMode === MONTH_MODES.Weekday &&
      values.frequency === 'Monthly'
    ) {
      errors.recurOnWeeksOfMonth = t('errorMissingRequiredField')
    }

    if (
      isEmpty(values.recurOnDaysOfWeek) &&
      values.monthMode === MONTH_MODES.Weekday &&
      values.frequency === 'Monthly'
    ) {
      errors.recurOnDaysOfWeek = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function transformSaveItemParams(params = {}) {
    return {
      ...(params.frequency === 'Monthly' && isEmpty(params.recurOnMonths)
        ? { recurOnMonths: range(1, 13) }
        : {}),
      ...(settingsType === 'asset' ? { scheduleDescription: '?' } : {}),
      activeDescription: params.active ? t('yes') : t('no'),
      taskId: params.id,
      includes: undefined,
      sendToEmails: params.sendToEmails.split(/[,;\s]/).filter((v, i, a) => a.indexOf(v) === i),
    }
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.taskItems.findIndex((one) => str(one.id) === props.match.params.itemId)
            const item = { ...values, ...transformSaveItemParams(values) }

            if (index > -1) {
              draft.taskItems.splice(index, 1, item)
            } else {
              draft.taskItems.unshift(item)
            }

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    ;(async function () {
      if (state.item) {
        const { templateId, assignedOperatorId } = state.item.jobTaskSettings

        if (templateId && !assignedOperatorId) {
          try {
            const jobTemplate = await props.getJobTemplate(templateId).then((r) => r.value.data)

            if (jobTemplate.assignedToId) {
              const assignedOperator = await props
                .getOperator(jobTemplate.assignedToId)
                .then((r) => r.value.data)

              setState('assignedOperator', assignedOperator)
            } else {
              setState('assignedOperator', null)
            }
          } catch (error) {
            console.error(error)
          }
        }
      }
    })()
  }, [state.item?.jobTaskSettings?.templateId, state.item?.jobTaskSettings?.assignedOperatorId])

  React.useEffect(() => {
    ;(async function () {
      if (state.item) {
        const { assignedOperatorId } = state.item.jobTaskSettings

        if (assignedOperatorId) {
          try {
            const assignedOperator = await props.getOperator(assignedOperatorId).then((r) => r.value.data)

            setState('assignedOperator', assignedOperator)
          } catch (error) {
            console.error(error)
          }
        } else {
          setState('assignedOperator', null)
        }
      }
    })()
  }, [state.item?.jobTaskSettings?.assignedOperatorId])

  if (isNil(state.item)) {
    return (
      <Page title={PLACEHOLDER}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const { readOnly } = state.parentState ?? {}
  const pageTitle = props.match.params.itemId === '0' ? t('scheduleJob') : state.item.name || PLACEHOLDER

  function hasUnsavedChanges() {
    return !state.item.id || !isEqual(state.item, state.itemOriginal)
  }

  async function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      default:
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          try {
            setState('loadingIsOpen', true)

            const job = await props
              .createFromTemplate(state.item.jobTaskSettings.templateId, state.item.jobTaskSettings)
              .then((r) => r.value.data)

            window.location.href = `/jobs/jobs/${job.id}`
          } catch (error) {
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        }
        break
    }
  }

  const actionsMenuItems = (function () {
    return [
      <IonItem
        key="runNow"
        lines="full"
        onClick={() => handleActionsMenuClick('runNow')}
        disabled={readOnly || state.item.id <= 0}
      >
        <IonLabel>{t('runNow')}</IonLabel>
      </IonItem>,
    ]
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
            {props.match.params.itemId === '0' ? t('add') : t('update')}
          </IonButton>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="task" tab={t('task')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.name })}
          >
            <IonLabel position="stacked">{t('name')}</IonLabel>
            <IonInput
              value={state.item.name}
              onIonInput={(e) => setItemValue('name', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('taskType')}</IonLabel>
            <IonInput value={t(taskTypes[state.item.taskType])} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          {['lastRun', 'nextRun'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{t(each)}</IonLabel>
              <IonInput
                value={formatDateTime(state.item[each], { showTime: true })}
                placeholder={PLACEHOLDER}
                disabled
              />
            </IonItem>
          ))}
          <IonItem lines="full">
            <IonLabel>{t('active')}</IonLabel>
            <IonToggle
              checked={state.item.active}
              onIonChange={(e) => setItemValue('active', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="settings" tab={t('settings')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.templateId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectJobTemplate`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('jobTemplate')}</small>
              </IonText>
              <br />
              {state.item.jobTaskSettings?.jobTemplateName || state.item.jobTaskSettings?.templateId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          {state.item.jobTaskSettings?.templateId
            ? [
                <IonItem
                  key="assignedOperatorId"
                  lines="none"
                  className={cx('tofino-stacked-item tofino-required-item', {
                    'tofino-error-item': state.errors?.assignedOperatorId,
                  })}
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectAssignedOperator`)
                  }}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('assignedTo')}</small>
                    </IonText>
                    <br />
                    {state.item.jobTaskSettings?.assignedOperatorName ||
                      state.item.jobTaskSettings?.assignedOperatorId ||
                      t('fromTemplate')}
                  </IonLabel>
                </IonItem>,
                <IonItem key="assignedOperatorInfo" lines="full">
                  <IonLabel className="ion-text-wrap" style={{ marginTop: 0 }}>
                    <IonText color="medium">
                      <small>
                        {state.assignedOperator
                          ? `${t('assignedToTaskInfo')} ${state.assignedOperator?.name} ${
                              state.assignedOperator?.email ? `(${state.assignedOperator?.email})` : ''
                            }`
                          : t('noAssignedToTaskInfo')}
                      </small>
                    </IonText>
                  </IonLabel>
                </IonItem>,
              ]
            : null}
          {SKIP_NOTIFICATION_CHECKS.map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel>{t(each.replace('jobTaskSettings.', ''))}</IonLabel>
              <IonToggle
                checked={get(state.item, each)}
                onIonChange={(e) => setItemValue(each, e.detail.checked)}
                disabled={readOnly}
              />
            </IonItem>
          ))}
          {SKIP_NOTIFICATION_CHECKS.some((one) => get(state.item, one) === true) && (
            <IonItem
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': state.errors?.sendToEmails,
              })}
            >
              <IonLabel position="stacked">{t('notifyIfTaskIsSkipped')}</IonLabel>
              <IonInput
                type="email"
                inputMode="email"
                inputmode="email"
                value={state.item.sendToEmails}
                onIonInput={(e) => setItemValue('sendToEmails', e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key="schedule" tab={t('schedule')} forceRender>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectFrequency`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('frequency')}</small>
              </IonText>
              <br />
              {t(FREQUENCIES[state.item.frequency])}
            </IonLabel>
          </IonItem>
          {!JOB_FREQUENCIES.includes(state.item.frequency) && (
            <DatePicker
              label={state.item.frequency !== 'Once' ? t('startingFrom') : t('startingFromOnceLabel')}
              value={state.item.startDate}
              onChange={(value) => setItemValue('startDate', value)}
              disabled={readOnly}
              showTime
            />
          )}
          {Object.keys(RECURRING_FREQUENCIES).includes(state.item.frequency) && (
            <IonItem lines="full">
              <IonLabel position="stacked">{t(RECURRING_FREQUENCIES[state.item.frequency])}</IonLabel>
              <IonInput
                value={state.item.recurPeriods}
                type="number"
                inputmode="number"
                inputMode="number"
                placeholder={PLACEHOLDER}
                onIonInput={(e) => setItemValue('recurPeriods', tryParseInt(e.target.value, 1))}
                min={1}
                disabled={readOnly}
                clearOnEdit
              />
            </IonItem>
          )}
          {state.item.frequency === 'Weekly' && (
            <IonItem
              lines="full"
              className={cx('tofino-stacked-item tofino-required-item', {
                'tofino-error-item': state.errors?.recurOnDaysOfWeek,
              })}
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectRecurOnDaysOfWeek`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('weekday')}</small>
                </IonText>
                <br />
                {state.item.recurOnDaysOfWeek.map((each) => t(DAYS_OF_WEEK[each])).join(', ') || (
                  <span className="tofino-placeholder">{PLACEHOLDER}</span>
                )}
              </IonLabel>
            </IonItem>
          )}
          {state.item.frequency === 'Monthly' && (
            <IonItem
              lines="full"
              className={cx('tofino-stacked-item', {
                'tofino-error-item': state.errors?.recurOnMonths,
              })}
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectRecurOnMonthsMultiple`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('month')}</small>
                </IonText>
                <br />
                {state.item.recurOnMonths.length < 12
                  ? state.item.recurOnMonths.map((each) => t(Object.keys(MONTHS)[each - 1])).join(', ') ||
                    t('all')
                  : t('all')}
              </IonLabel>
            </IonItem>
          )}
          {state.item.frequency === 'Monthly' && [
            <IonRow key="MonthDay">
              <IonCol size="2">
                <IonItem
                  lines="none"
                  onClick={() => setItemValue('monthMode', MONTH_MODES.MonthDay)}
                  disabled={readOnly}
                >
                  <RadioContainer>
                    <Icon.Radio checked={state.item.monthMode === MONTH_MODES.MonthDay} />
                  </RadioContainer>
                </IonItem>
              </IonCol>
              <IonCol size="10">
                <IonItem
                  lines="full"
                  className={cx('tofino-stacked-item tofino-required-item', {
                    'tofino-error-item': state.errors?.recurOnDaysOfMonth,
                  })}
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectRecurOnDaysOfMonthMultiple`)
                  }}
                  disabled={readOnly || state.item.monthMode !== MONTH_MODES.MonthDay}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('monthDay')}</small>
                    </IonText>
                    <br />
                    {orderBy(state.item.recurOnDaysOfMonth)
                      .map((each) => (each === 32 ? t('last') : each))
                      .join(', ') || PLACEHOLDER}
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>,
            <IonRow key="WeekOfMonth">
              <IonCol size="2">
                <IonItem
                  lines="none"
                  onClick={() => setItemValue('monthMode', MONTH_MODES.Weekday)}
                  disabled={readOnly}
                >
                  <RadioContainer>
                    <Icon.Radio checked={state.item.monthMode === MONTH_MODES.Weekday} />
                  </RadioContainer>
                </IonItem>
              </IonCol>
              <IonCol size="10">
                <IonItem
                  lines="none"
                  className={cx('tofino-stacked-item tofino-required-item', {
                    'tofino-error-item': state.errors?.recurOnWeeksOfMonth,
                  })}
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectRecurOnWeeksOfMonth`)
                  }}
                  disabled={readOnly || state.item.monthMode !== MONTH_MODES.Weekday}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('weekday')}</small>
                    </IonText>
                    <br />
                    {state.item.recurOnWeeksOfMonth.map((each) => t(WEEKS_OF_MONTH[each - 1])).join(', ') || (
                      <span className="tofino-placeholder">{PLACEHOLDER}</span>
                    )}
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>,
            <IonRow key="DaysOfWeek">
              <IonCol size="2">
                <IonItem
                  lines="full"
                  onClick={() => setItemValue('monthMode', MONTH_MODES.Weekday)}
                  disabled={readOnly}
                >
                  <HiddenContainer>
                    <Icon.Radio checked={state.item.monthMode === MONTH_MODES.Weekday} />
                  </HiddenContainer>
                </IonItem>
              </IonCol>
              <IonCol size="10">
                <IonItem
                  lines="full"
                  className={cx('tofino-stacked-item', {
                    'tofino-error-item': state.errors?.recurOnDaysOfWeek,
                  })}
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectRecurOnDaysOfWeek`)
                  }}
                  disabled={readOnly || state.item.monthMode !== MONTH_MODES.Weekday}
                  button
                >
                  <IonLabel>
                    {state.item.recurOnDaysOfWeek.map((each) => t(DAYS_OF_WEEK[each])).join(', ') || (
                      <span className="tofino-placeholder">{PLACEHOLDER}</span>
                    )}
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>,
          ]}
          {state.item.frequency === 'Yearly' && (
            <IonRow>
              <IonCol>
                <IonItem
                  lines="full"
                  className="tofino-stacked-item tofino-required-item"
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectRecurOnMonthsSingle`)
                  }}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('month')}</small>
                    </IonText>
                    <br />
                    {state.item.recurOnMonths.length < 12
                      ? t(Object.keys(MONTHS)[get(state.item, 'recurOnMonths[0]', 1) - 1]) || t('all')
                      : t('all')}
                  </IonLabel>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem
                  lines="full"
                  className="tofino-stacked-item tofino-required-item"
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectRecurOnDaysOfMonthSingle`)
                  }}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('day')}</small>
                    </IonText>
                    <br />
                    {get(state.item, 'recurOnDaysOfMonth[0]', 1)}
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {JOB_FREQUENCIES.includes(state.item.frequency) && [
            <IonItem key="enterScheduleValue" lines="full">
              <IonLabel className="ion-text-wrap">
                <IonText color="medium">
                  <small>{t('enterScheduleValue')}</small>
                </IonText>
              </IonLabel>
            </IonItem>,
            <IonItem key="recurPeriods" lines="full" className="tofino-stacked-item tofino-required-item">
              <IonLabel position="stacked">{t(state.item.frequency)}</IonLabel>
              <IonInput
                value={state.item.recurPeriods}
                type="number"
                inputmode="number"
                inputMode="number"
                placeholder={PLACEHOLDER}
                onIonInput={(e) => setItemValue('recurPeriods', tryParseInt(e.target.value, 1))}
                min={1}
                disabled={readOnly}
                clearOnEdit
              />
            </IonItem>,
            <IonItem key="repeatForRecurPeriod" lines="full">
              <IonLabel>{t('repeat')}</IonLabel>
              <IonToggle
                checked={state.item.repeatForRecurPeriod}
                onIonChange={(e) => setItemValue('repeatForRecurPeriod', e.detail.checked)}
                disabled={readOnly}
              />
            </IonItem>,
          ]}
        </Tabs.TabPane>
      </Tabs>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

import { isEmpty, cloneDeep } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'
import { showError } from 'helpers/errors'
import { updateSessionItem } from 'helpers/sessionStorage'
import Filter from 'containers/Assets/FormView/CopyFromExisting/Filter'
import Icon from 'elements/Icon'

export default createListViewComponent({
  getStorageKey: () => 'assets.formView.copyFromExisting',
  pageTitleLanguageKey: 'selectAsset',
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          draft.selectedRowKeys = draft.selectedRowKeys.includes(item.id) ? [] : [item.id]
        })
      }}
    >
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description}
            <br />
            {t('category:')} {item.assetCategoryName || t('none')}, {t('location:')}{' '}
            {item.locationName || PLACEHOLDER}
            <br />
            {t('lastActivity:')} {formatDateTime(item.lastActivityDate, { showTime: true })}, {t('status:')}{' '}
            {item.statusName}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: () => 93,
  allowSearch: (self) => true,
  allowOffline: (self) => false,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
      onClick={async () => {
        try {
          self.setState('loadingIsOpen', true)

          const { id, ...rest } = await self.props
            .getItem(self.state.selectedRowKeys[0])
            .then((r) => r.value.data)
            .then(cloneDeep)

          const [warrantyItems, toleranceItems] = await Promise.all([
            self.props.getWarrantyItems(id),
            self.props.getToleranceItems(id),
          ])

          updateSessionItem('assets.formView', {}, (draft) => {
            Object.assign(draft.item, rest)

            draft.warrantyItems = warrantyItems.value.data.items.map((each) => ({
              ...each,
              id: undefined,
              assetId: id,
            }))
            draft.toleranceItems = toleranceItems.value.data.items.map((each) => ({
              ...each,
              id: undefined,
              assetId: id,
            }))
          })

          self.props.history.goBack()
        } catch (error) {
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
    >
      {t('copy')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'assetId' },
    { fieldName: 'assetCategoryName', languageKey: 'assetCategory' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'lastActivityDate', languageKey: 'lastActivity' },
    { fieldName: 'statusName', languageKey: 'status' },
  ],
})(Filter)

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'
import { AUTH_SESSION_KEY } from 'options/auth'

export default createSelectionListViewComponent({
  pageTitle: () => getSessionItem(AUTH_SESSION_KEY).current.customer.tagSettings.tag3,
  getItemSize: () => 45,
  allowSearch: () => true,
  getIdField: () => 'displayName',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.assetDisplayName || '',
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.assetId = each.id
      draft.item.assetName = each.name || '*'
      draft.item.assetNumber = each.number
      draft.item.assetBarcode = each.barcode
      draft.item.assetDisplayName = each.displayName
    }),
})()

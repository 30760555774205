import produce from 'immer'
import { set } from 'lodash'
import { setSessionItem, getSessionItem } from 'helpers/sessionStorage'
import { I18N_SESSION_KEY } from 'options/auth'

const trySetSessionItem = (key, value) => {
  try {
    setSessionItem(key, value)
  } catch (error) {
    console.warn(error)
  }
}

export const LOCALES_GET = 'LOCALES_GET'
export const TRANSLATIONS_GET = 'TRANSLATIONS_GET'

export default (
  state = getSessionItem(I18N_SESSION_KEY, {
    locales: [
      { code: 'en', iso2: 'en', name: 'English', nativeName: 'English', isDefault: true },
      { code: 'fr', iso2: 'fr', name: 'French', nativeName: 'Français', isDefault: false },
      { code: 'es', iso2: 'es', name: 'Spanish', nativeName: 'Español', isDefault: false },
    ],
  }),
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case `${LOCALES_GET}_FULFILLED`:
        draft.locales = action.payload.data
        trySetSessionItem(I18N_SESSION_KEY, { ...draft })
        break

      case `${TRANSLATIONS_GET}_FULFILLED`:
        draft.translations = action.payload.data
        set(window, 'i18n.translations', draft.translations)
        trySetSessionItem(I18N_SESSION_KEY, { ...draft })
        break

      default:
        break
    }
  })

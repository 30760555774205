import * as React from 'react'
import { useImmer } from 'use-immer'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { useDebouncedCallback } from 'use-debounce'
import {
  IonButton,
  IonSpinner,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonLoading,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react'
import { set, isNil, isEmpty, pick, cloneDeep, omit } from 'lodash'
import { tryParseInt, filterKeys, PLACEHOLDER, DEBOUNCE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import splitTypes from 'options/cycleCounts/cycleCountSplitTypes'
import Page from 'elements/Page'

export const getStorageKey = () => 'cycleCounts.formView.split'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/inventory'
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const { itemsOnCount } = await props.getItem(itemId).then((r) => r.value.data)

      setState('item', {
        itemsOnCount,
        splitType: 'ByCounts',
        splitQuantity: null,
      })
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.splitQuantity) {
      errors.splitQuantity = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const { splitType, splitQuantity } = values

          const response = await props.splitCycleCount({
            id: tryParseInt(props.match.params.itemId, 0),
            splitType,
            splitQuantity,
          })

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          props.history.goBack()
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  const fetchSplitDistributionText = useDebouncedCallback(async () => {
    try {
      const { splitType, splitQuantity } = state.item

      const response = await props
        .getSplitDistributionText({
          id: tryParseInt(props.match.params.itemId, 0),
          splitType,
          splitQuantity,
        })
        .then((r) => r.value.data)

      updateState((draft) => {
        draft.item.splitQuantity = response.splitQuantity
        draft.splitDistributionText = response.splitDistributionText
      })
    } catch (error) {
      showError({ error })
    }
  }, DEBOUNCE)

  React.useEffect(() => {
    if (state.item?.splitType && state.item?.splitQuantity) {
      fetchSplitDistributionText()
    } else {
      setState('splitDistributionText', null)
    }
  }, [state.item?.splitType, state.item?.splitQuantity])

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('splitCycleCount')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
          {t('splitCount')}
        </IonButton>
      }
    >
      <IonItem lines="full">
        <IonLabel position="stacked">{t('itemsOnCount')}</IonLabel>
        <IonInput value={state.item.itemsOnCount} disabled />
      </IonItem>
      <IonItem
        lines="full"
        className="tofino-stacked-item"
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectType`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('splitType')}</small>
          </IonText>
          <br />
          {t(splitTypes[state.item.splitType])}
        </IonLabel>
      </IonItem>
      <IonItem
        lines="full"
        className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.splitQuantity })}
      >
        <IonLabel position="stacked">
          {state.item.splitType === 'ByItems' ? t('numberOfItemsPerCount') : t('numberOfCounts')}
        </IonLabel>
        <IonInput
          value={state.item.splitQuantity}
          type="number"
          inputmode="number"
          inputMode="number"
          min={1}
          placeholder={PLACEHOLDER}
          onKeyPress={filterKeys(/\D/)}
          onIonInput={(e) => setState('item.splitQuantity', tryParseInt(e.target.value, null))}
          clearOnEdit
        />
      </IonItem>
      <p className="ion-margin">{state.splitDistributionText}</p>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

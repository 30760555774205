import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import locationActions from 'actions/locations'
import assetCategoryActions from 'actions/assetCategories'
import Component from 'components/Assets/FormView/CopyFromExisting/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getLocations: locationActions.getItemsOffline,
  getAssetCategories: assetCategoryActions.getItemsOffline,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

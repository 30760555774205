import { IonItem, IonButton } from '@ionic/react'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { message } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { createCheckOfflineInventory } from 'helpers/offlineData'
import { updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '..'
import { Inventory, inventoryItemSize } from 'components/Inventory/ListView'
import Icon from 'elements/Icon'
import Filter from 'containers/Count/CountItems/SearchInventory/Filter'

export default createListViewComponent({
  getStorageKey: () => 'inventory.countItems.searchInventory',
  pageTitle: (self) => t('searchInventory'),
  getViewDidEnterHandler: createCheckOfflineInventory,
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <Inventory self={self} item={item} />
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: inventoryItemSize,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={() => {
        const selectedItem = self.state.items.find((one) => one.id === self.state.selectedRowKeys[0])

        if (selectedItem.lockedForCycleCount) {
          message.error(t('errorLockedForCycleCount'))
        } else {
          updateSessionItem(getStorageKey(), {}, (draft) => {
            try {
              draft.inventory = selectedItem
              draft.item.locationId = selectedItem.locationId
              draft.item.locationName = selectedItem.locationName
              draft.item.barcode = selectedItem.barcode
              draft.item.inventoryBarcode = selectedItem.inventoryBarcode
              draft.item.description = selectedItem.itemDescription
              draft.item.count = self.props.scannerSettings.showOnhandQuantity ? selectedItem.onHand : 0
              draft.item.count2 = self.props.scannerSettings.showOnhandQuantity ? selectedItem.onHand2 : 0

              self.props.history.goBack()
            } catch (error) {
              showError({ error })
            }
          })
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys)}
    >
      {t('select')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'itemDescription', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
  ],
})(Filter)

import styled from 'styled-components'
import tofino from 'images/tofino.png'

const Container = styled.div`
  img {
    max-width: calc(100vw - 32px);
  }
`

export default function (props) {
  return (
    <Container className="ion-margin-vertical ion-text-center">
      <img src={props.file ? `data:${props.file.fileType};base64,${props.file.contents}` : tofino} alt="" />
    </Container>
  )
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonPopover,
  IonItem,
  IonLabel,
  IonSpinner,
  IonInput,
  IonText,
  IonSearchbar,
  IonLoading,
  IonAlert,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import {
  get,
  set,
  isNil,
  isEmpty,
  camelCase,
  pick,
  intersection,
  cloneDeep,
  omit,
  isEqual,
  isFinite,
} from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { getSessionItem, setSessionItem, removeSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import Stepper from 'elements/Stepper'
import {
  asyncSleep,
  simulatePressEnter,
  message,
  tryParseInt,
  strEqual,
  getTagDisplayNameField,
  getTagName,
  PLACEHOLDER,
} from 'helpers/utils'
import { validateOperatorTag, validateJobTag, validateAssetTag, validateOtherTags } from 'helpers/procurement'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { getUserPermission } from 'helpers/auth'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Menu from 'elements/Menu'
import Button from 'elements/Button'
import Dropdown from 'elements/Dropdown'
import SearchbarContainer from 'elements/SearchbarContainer'
import BarcodeScanner from 'elements/BarcodeScanner'

const qtyKey = 'quantityOrdered'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  '3', // Asset
  'A',
  'B',
  'C',
  'D',
  'E',
]

function Description({ product, item }) {
  return (
    <p className="ion-margin-horizontal">
      {product ? (
        <>
          {product.description}
          <br />
          {t('item#:')} {product.itemNumber}, {t('pkg:')} {product.packageSizeUom}, {t('price:')}{' '}
          {toLocaleCurrency(product.price)}
          <br />
          {t('supplier:')} {product.defaultSupplierName || t('notAssigned')}
        </>
      ) : item.locationId ? (
        <IonText color="medium">{t('enterBarcodeInfo')}</IonText>
      ) : (
        <IonText color="medium">{t('selectLocationFirst')}</IonText>
      )}
    </p>
  )
}

export const getStorageKey = () => 'orders.addItems'

export default function (props) {
  const quantityOrderedMin = props.customer.generalSettings.allowZeroQuantityOnOrderedItems ? 0 : 1
  const { parentStorageKey = 'orders.formView', selectedLocationKey = 'orders.addItems.selectedLocation' } =
    props

  const ref1 = React.useRef()
  const ref2 = React.useRef()
  const ref3 = React.useRef()
  const refA = React.useRef()
  const refB = React.useRef()
  const refC = React.useRef()
  const refD = React.useRef()
  const refE = React.useRef()
  const refBarcode = React.useRef()
  const refQuantity = React.useRef()

  const [state, updateState] = useImmer({
    showTagFields: true,
    requireQuantity: true,
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledPurch`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2', '3'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function getTagRef(tagType) {
    return {
      2: ref2,
      1: ref1,
      3: ref3,
      A: refA,
      B: refB,
      C: refC,
      D: refD,
      E: refE,
    }[tagType]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  function focusNextTag(tagType) {
    const indexes = ['2', '1', '3', 'A', 'B', 'C', 'D', 'E', 'Barcode', 'Quantity']
    const tagRefs = [ref2, ref1, ref3, refA, refB, refC, refD, refE, refBarcode, refQuantity]

    for (let index = indexes.indexOf(tagType) + 1; index < indexes.length; index++) {
      try {
        tagRefs[index].current.setFocus()
        return true
      } catch (error) {
        console.warn(error)
      }
    }

    return false
  }

  function findProduct(item = state.item) {
    const { barcode, locationId } = item

    validateFields(async () => {
      try {
        updateState((draft) => {
          draft.loadingIsOpen = true
          draft.product = null
          draft.item[qtyKey] = null
        })

        const results = await props
          .getProducts({
            search: barcode,
            locationIds: locationId ? [locationId] : [],
          })
          .then((r) => r.value.data.items)

        const product = results.find((one) => strEqual(one.barcode, barcode))

        if (product) {
          updateState((draft) => {
            draft.product = product
            draft.item.barcode = product.barcode
            draft.item[qtyKey] = null
          })

          if (state.requireQuantity) {
            window.setTimeout(() => refQuantity.current.setFocus(), 1000)
          }
        } else {
          message.error(t('itemNotFound'))
        }
        setState('loadingIsOpen', false)
      } catch (error) {
        setState('loadingIsOpen', false)
        showError({ error })
      }
    })
  }

  function handleTagKeyPress(tagType) {
    if (tagType === 'Barcode') {
      return (e) => {
        if (e.key === 'Enter') {
          findProduct(state.item)
        } else {
          setState('product', null)
        }
      }
    }

    if (tagType === 'Quantity') {
      return (e) => {
        if (e.key === 'Enter') {
          saveItem()
        } else if (e.key.match(/\D/)) {
          stopEvent(e)
        }
      }
    }

    return (e) => {
      if (e.key === 'Enter') {
        validateFields(() => {
          setState('loadingIsOpen', false)

          if (!focusNextTag(tagType)) {
            saveItem()
          }
        })
      }
    }
  }

  function handleBarcodeInput(e) {
    updateState((draft) => {
      draft.item.barcode = e.target.value
      draft.product = null
    })
  }

  async function handleBarcodeScan({ rawValue: value }) {
    setState('scanBarcodeIsOpen', false)
    await asyncSleep(500)
    refBarcode.current.setFocus()
    refBarcode.current.value = value
    handleBarcodeInput({ target: { value } })
    simulatePressEnter(refBarcode.current)
  }

  function handleQuantityStep(value) {
    updateState((draft) => {
      draft.item[qtyKey] = isFinite(value)
        ? Math.max(tryParseInt(draft.item[qtyKey], 0) + value, quantityOrderedMin)
        : quantityOrderedMin
    })
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isNil(values)) {
      callback(errors, values)
      return
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateAssetTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    values[qtyKey] = Math.max(tryParseInt(values[qtyKey], 0), quantityOrderedMin)

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (intersection(tagTypes, Object.keys(errors)).length) {
        setState('showTagFields', true)
      }

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        const orderItem = {
          ...values,
          [qtyKey]: values[qtyKey] || quantityOrderedMin,
          barcode: state.product.barcode,
          description: state.product.description,
          assetName: values.assetName || `*${values.assetDisplayName}`,
          jobName: values.jobName || `*${values.jobDisplayName}`,
          locationName: values.locationName || `*${values.locationDisplayName}`,
          operatorName: values.operatorName || `*${values.operatorDisplayName}`,
          assetDisplayName: values.assetDisplayName,
          operatorDisplayName: values.operatorDisplayName,
          jobDisplayName: values.jobDisplayName,
          locationDisplayName: values.locationDisplayName,
        }

        try {
          setState('loadingIsOpen', true)

          const response = await props.populateOrderItems([orderItem])

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const populatedItem = cloneDeep(response.value.data.items[0])

          populatedItem.id = populatedItem.orderItemId = values.id || Date.now() * -1
          populatedItem.orderId = tryParseInt(props.match.params.parentId, 0)
          populatedItem.populated = true
          populatedItem.timestamp = new Date().toJSON()

          updateSessionItem('orders.formView', {}, (draft) => {
            draft.orderItems.unshift(populatedItem)
            draft.tabsActiveKey = 'items'
          })

          message.success(`${populatedItem.barcode} ${t('addToOrderSuccess')}`)

          if (state.quickscan) {
            updateState((draft) => {
              draft.item.barcode = ''
              draft.item[qtyKey] = null
              draft.product = null
            })
          } else {
            fetchItem()
          }
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  function fetchItem() {
    updateState((draft) => {
      draft.item = {
        locationId: 0,
        operatorName: '*',
        operatorDisplayName: '',
        jobName: '*',
        jobDisplayName: '',
        assetName: '*',
        assetDisplayName: '',
        userName: props.user.userName,
        [qtyKey]: null,
        ...getStorageItem(selectedLocationKey, {}),
      }
      draft.product = null
    })
  }

  function handleMenuClick({ key }) {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (intersection(tagTypes, Object.keys(errors)).length) {
        setState('showTagFields', true)
      }

      setState('loadingIsOpen', false)

      if (isEmpty(errors)) {
        switch (key) {
          case 'scanBarcode':
            setState('scanBarcodeIsOpen', true)
            break

          case 'punchout': {
            const { item, itemOriginal, orderItems, orderItemsOriginal } = getSessionItem(
              parentStorageKey,
              {}
            )
            const parentHasUnsavedChanges =
              !item.id || !isEqual(item, itemOriginal) || !isEqual(orderItems, orderItemsOriginal)

            if (parentHasUnsavedChanges) {
              message.error(t('saveChangesFirst'))
            } else {
              setSessionItem(getStorageKey(), {
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
                item: values,
              })
              removeSessionItem(`${getStorageKey()}.${key}`)
              props.history.push(`${props.match.url}/${key}`)
            }
            break
          }

          case 'searchProducts':
          case 'addNonStock':
          case 'addFromList':
            setSessionItem(getStorageKey(), {
              ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              item: values,
            })
            removeSessionItem(`${getStorageKey()}.${key}`)
            props.history.push(`${props.match.url}/${key}`)
            break

          default:
            message.info(t('underDevelopment'))
            break
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['quickscan', 'requireQuantity', 'showTagFields']))
  }, [state.quickscan, state.requireQuantity, state.showTagFields])

  React.useEffect(() => {
    if (state.item) {
      setStorageItem(
        selectedLocationKey,
        pick(state.item, [
          'locationId',
          'locationName',
          'locationNumber',
          'locationBarcode',
          'locationDisplayName',
        ])
      )
    }
  }, [state.item])

  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/orders'
    }
  }, [])

  const pageTitle = t('addToOrder')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const parentRecord = getSessionItem('orders.formView', {}).item
  const allowPunchout =
    !props.useInventoryBarcode &&
    ['New'].includes(parentRecord.status) &&
    getUserPermission('AllowPunchout') === 'Yes'
  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        <IonButton
          onClick={(e) => {
            updateState((draft) => {
              draft.popoverIsOpen = true
              draft.popoverEvent = e
            })
          }}
        >
          <Icon type="Menu" size="26" color={state.quickscan ? 'warning' : undefined} />
        </IonButton>
      }
      footer={
        <IonButton
          color="secondary"
          expand="full"
          onClick={() => saveItem()}
          disabled={isNil(state.product) || !isOnline}
        >
          {t('addToOrder')}
        </IonButton>
      }
    >
      <>
        <IonItem
          lines="full"
          className={cx('tofino-stacked-item tofino-required-item', {
            'tofino-error-item': state.errors?.locationId,
          })}
          onClick={() => {
            if (!props.match.params.itemId) {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }
          }}
          button={!props.match.params.itemId}
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {state.item.locationId ? (
              <IonText color={props.match.params.itemId ? 'medium' : undefined}>
                {state.item.locationDisplayName || state.item.locationId}
              </IonText>
            ) : (
              <span className="tofino-placeholder">{PLACEHOLDER}</span>
            )}
          </IonLabel>
        </IonItem>
        {Boolean(state.showTagFields) &&
          !isEmpty(enabledTagTypes) &&
          enabledTagTypes.map((tagType) => (
            <IonItem
              key={tagType}
              lines="full"
              className={cx('tofino-stacked-item', {
                'tofino-required-item': getTagIsRequired(tagType),
                'tofino-error-item': get(state, `errors.${tagType}`),
              })}
            >
              <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
              <IonInput
                ref={getTagRef(tagType)}
                value={getTagDisplayName(tagType)}
                placeholder={PLACEHOLDER}
                onIonInput={handleTagInput(tagType)}
                onKeyPress={handleTagKeyPress(tagType)}
                onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
              />
              {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
            </IonItem>
          ))}
        {Boolean(state.item.locationId) && (
          <SearchbarContainer lines="full">
            <IonSearchbar
              ref={refBarcode}
              value={state.item.barcode}
              placeholder={t('enterBarcode')}
              onIonInput={handleBarcodeInput}
              onKeyPress={handleTagKeyPress('Barcode')}
              onIonClear={() => setState('product', null)}
              disabled={props.match.params.itemId}
              maxlength={200}
            />
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  {props.scannerSettings.enableCameraScanning && [
                    <Menu.Item key="scanBarcode">{t('scanBarcode')}</Menu.Item>,
                    <Menu.Divider key="divider" />,
                  ]}
                  <Menu.Item key="searchProducts">{t('searchProducts')}</Menu.Item>
                  <Menu.Divider />
                  {allowPunchout && <Menu.Item key="punchout">{t('punchout')}</Menu.Item>}
                  {getUserPermission('AddNonStockItems') !== 'No' && (
                    <Menu.Item key="addNonStock">{t('addNonStock')}</Menu.Item>
                  )}
                  {!props.useInventoryBarcode && <Menu.Item key="addFromList">{t('addFromList')}</Menu.Item>}
                </Menu>
              }
              trigger={['click']}
              placement={state.showTagFields ? 'topRight' : 'bottomRight'}
            >
              <Button>
                <Icon type="MoreHoriz" color="primary" />
              </Button>
            </Dropdown>
          </SearchbarContainer>
        )}
        {state.requireQuantity && (
          <IonItem lines="full">
            <IonLabel>{t('quantity')}</IonLabel>
            <IonInput
              ref={refQuantity}
              value={state.item[qtyKey]}
              onIonInput={(e) =>
                setState(
                  `item.${qtyKey}`,
                  Math.max(tryParseInt(e.target.value, quantityOrderedMin), quantityOrderedMin)
                )
              }
              onKeyPress={handleTagKeyPress('Quantity')}
              className="ion-text-right"
              type="number"
              inputmode="number"
              inputMode="number"
              placeholder={PLACEHOLDER}
              min={0}
              clearOnEdit
            />
            <Stepper onClick={handleQuantityStep} min={quantityOrderedMin} />
          </IonItem>
        )}
        <Description scannerSettings={props.scannerSettings} item={state.item} product={state.product} />
      </>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <IonItem lines="full" onClick={() => setState('quickscan', !state.quickscan)}>
          <IonLabel>{t('quickscan')}</IonLabel>
          {state.quickscan && <Icon type="Check" slot="end" color="success" />}
        </IonItem>
        <IonItem lines="full" onClick={() => setState('requireQuantity', !state.requireQuantity)}>
          <IonLabel>{t('requireQuantity')}</IonLabel>
          {state.requireQuantity && <Icon type="Check" slot="end" color="success" />}
        </IonItem>
        {!isEmpty(enabledTagTypes) && (
          <IonItem lines="none" onClick={() => setState('showTagFields', !state.showTagFields)}>
            <IonLabel>{t('showTagFields')}</IonLabel>
            {state.showTagFields && <Icon type="Check" slot="end" color="success" />}
          </IonItem>
        )}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <IonModal isOpen={state.scanBarcodeIsOpen}>
        {state.scanBarcodeIsOpen && (
          <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
        )}
      </IonModal>
    </Page>
  )
}

import { createListViewContainer } from 'factories/ListView'
import cycleCountActions from 'actions/cycleCounts'
import Component from 'components/CycleCounts/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(cycleCountActions.getListViewItems(params)),
    deleteItem: (id) => dispatch(cycleCountActions.deleteItems([id])),
  }),
})(Component)

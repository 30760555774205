import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'

const defaultActions = createActions(entityNames.tagLists, { memoizeGetOptions: true })

export default {
  ...defaultActions,

  getItemsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.tagLists'

    try {
      const response = await dispatch(
        defaultActions.getItems({ pageIndex: 0, mobileDownload: true, tagType: 'All' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

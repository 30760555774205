import { partition } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { isUploadSuccessful } from 'helpers/locationCounts'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.uploadResults',
  pageTitleLanguageKey: 'uploadResults',
  getSearchHeader: ({ props, state, updateState, fetchItems }) => {
    const itemsUploaded = (state.items ?? []).filter((each) => !each.error)
    const [successful, unsuccessful] = partition(itemsUploaded, (each) => isUploadSuccessful(each))

    return (
      <dl className="ion-margin tofino-tabular-list">
        <dt>{t('locationsUploaded')}</dt>
        <dd>{itemsUploaded.length}</dd>
        <dt>{t('uploadedSuccessfully')}</dt>
        <dd>{successful.length}</dd>
        <dt>{t('uploadedWithErrors')}</dt>
        <dd>{unsuccessful.length}</dd>
      </dl>
    )
  },
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => (
      <IonItem key={item.id} lines="full" routerLink={`${props.match.url}/${item.id}`}>
        <IonLabel>
          {item.locationName}
          <br />
          <small>
            {item.error ? (
              <IonText color="danger">{t('uploadFailed')}</IonText>
            ) : !isUploadSuccessful(item) ? (
              <IonText color="danger">{t('uploadedWithErrors')}</IonText>
            ) : (
              <IonText color="success">{t('uploaded')}</IonText>
            )}
          </small>
        </IonLabel>
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
})()

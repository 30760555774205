import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPopover,
  IonLoading,
  IonAlert,
  IonSpinner,
  IonInput,
  IonText,
  IonRow,
  IonCol,
  IonTextarea,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { set, isNil, isEmpty, isEqual, cloneDeep, pick, omit, uniqBy } from 'lodash'
import { message, PLACEHOLDER, asyncSleep } from 'helpers/utils'
import { createSaveDocumentItems } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { formatDateTime, formatUserTime } from 'helpers/dateTime'
import { getStorageItem } from 'helpers/localStorage'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Tabs from 'elements/Tabs'
import FileUpload from 'elements/FileUpload'
import Documents from 'containers/Documents/ChildListView'

export const getStorageKey = () => 'jobRequests.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/jobs'
    }
  }, [])

  const [state, updateState] = useImmer({
    tabsActiveKey: 'jobRequest',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props.newItem({}).then((r) => cloneDeep(r.value.data))

      const documentItems = item.id
        ? await props
            .getDocumentItems({
              request: {
                domainObjectId: item.id,
                domainObjectType: 'JobRequest',
                documentType: 'ObjectDocument',
              },
            })
            .then((r) => r.value.data.items)
        : await Promise.resolve([])

      item.locationId = item.locationId || null
      item.locationName = item.locationId ? item.locationName : ''

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.title)) {
      errors.title = t('errorMissingRequiredField')
    }

    if (isEmpty(values.createdByName)) {
      errors.createdByName = t('errorMissingRequiredField')
    }

    if (isEmpty(values.createdByEmail)) {
      errors.createdByEmail = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function saveItem() {
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'JobRequest')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const saved =
            props.match.params.itemId === '0'
              ? await props.createItem(values)
              : await props.updateItem(values)

          const itemId = saved.value.data.id

          await saveDocumentItems(itemId)

          if (props.match.params.itemId === '0') {
            const resp = await props.sendCreateNotification({ id: itemId })

            showClientNotifications({ response: resp })
          }

          setState('loadingIsOpen', false)

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/jobs/jobRequests/${itemId}`
          } else {
            await fetchItem(itemId)
          }
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal)
    )
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle =
    props.match.params.itemId === '0'
      ? t('createJobRequest')
      : `${t('jobRequest')} - ${props.match.params.itemId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = ['Closed', 'Declined'].includes(state.item.status)

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'createJob':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
          props.history.push(`${props.match.url}/createJob`)
        }
        break

      case 'decline':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
          props.history.push(`${props.match.url}/decline`)
        }
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    if (state.item.status === 'Closed') {
      return []
    }

    return [
      state.tabsActiveKey === 'documents' ? (
        <IonItem
          key="addDocument"
          lines="full"
          onClick={() => handleActionsMenuClick('addDocument')}
          disabled={readOnly}
        >
          <IonLabel>{t('addDocument')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id ? (
        <IonItem key="createJob" lines="full" onClick={() => handleActionsMenuClick('createJob')}>
          <IonLabel>{t('createJob')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && state.item.status === 'Open' ? (
        <IonItem key="decline" lines="full" onClick={() => handleActionsMenuClick('decline')}>
          <IonLabel>{t('decline')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        <>
          {readOnly && (
            <IonButton expand="full" color="secondary" onClick={() => props.history.goBack()}>
              {t('close')}
            </IonButton>
          )}
          {!readOnly && (
            <IonButton expand="full" color="secondary" onClick={() => saveItem()} disabled={!isOnline}>
              {t('save')}
            </IonButton>
          )}
        </>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="jobRequest" tab={t('jobRequest')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('id')}</IonLabel>
            <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem
            lines="full"
            className={cx({
              'tofino-required-item': !readOnly,
              'tofino-error-item': state.errors?.title,
            })}
          >
            <IonLabel position="stacked">{t('title')}</IonLabel>
            <IonInput
              value={state.item.title}
              onIonInput={(e) => setItemValue('title', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('createdDate')}</IonLabel>
                <IonInput
                  value={formatDateTime(state.item.createdDate, { showTime: true })}
                  placeholder={PLACEHOLDER}
                  disabled
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem
                lines="full"
                className={cx({
                  'tofino-required-item': !readOnly,
                  'tofino-error-item': state.errors?.createdByName,
                })}
              >
                <IonLabel position="stacked">{t('createdBy')}</IonLabel>
                <IonInput
                  value={state.item.createdByName}
                  onIonInput={(e) => setItemValue('createdByName', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={readOnly}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem
            lines="full"
            className={cx({
              'tofino-required-item': !readOnly,
              'tofino-error-item': state.errors?.createdByEmail,
            })}
          >
            <IonLabel position="stacked">{t('email')}</IonLabel>
            <IonInput
              type="email"
              inputMode="email"
              inputmode="email"
              value={state.item.createdByEmail}
              onIonInput={(e) => setItemValue('createdByEmail', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('status')}</IonLabel>
            <IonInput value={state.item.statusName} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            lines="full"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('location')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || PLACEHOLDER}
            </IonLabel>
          </IonItem>
          <IonItem
            className="tofino-stacked-item"
            lines="full"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectAssetCategory`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('assetCategory')}</small>
              </IonText>
              <br />
              {state.item.assetCategoryId ? state.item.assetCategoryName || t('all') : t('all')}
            </IonLabel>
          </IonItem>
          <IonItem
            className="tofino-stacked-item"
            lines="full"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectAsset`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('asset')}</small>
              </IonText>
              <br />
              {state.item.assetName || state.item.assetId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('description')}</IonLabel>
            <IonTextarea
              value={state.item.description}
              onIonInput={(e) => setItemValue('description', e.target.value)}
              rows={3}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
              autoGrow
            />
          </IonItem>
          {['Closed', 'Declined'].includes(state.item.status) && (
            <>
              <IonItem lines="full">
                <IonLabel position="stacked">
                  {state.item.status === 'Closed' ? t('convertedComments') : t('declinedComments')}
                </IonLabel>
                <IonTextarea
                  value={state.item.jobConversionComment}
                  rows={3}
                  placeholder={PLACEHOLDER}
                  disabled
                  autoGrow
                />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked">
                  {state.item.status === 'Closed' ? t('convertedDate') : t('declinedDate')}
                </IonLabel>
                <IonInput
                  value={formatUserTime(state.item.jobConversionDate, state.item.jobConversionBy)}
                  disabled
                />
              </IonItem>
            </>
          )}
          {state.item.status === 'Closed' && (
            <>
              {state.item.jobId && state.item.jobName ? (
                <IonItem
                  lines="full"
                  className="tofino-stacked-item"
                  onClick={() => {
                    window.location.href = `/jobs/jobs/${state.item.jobId}`
                  }}
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('job')}</small>
                    </IonText>
                    <br />
                    <IonText color="secondary">{state.item.jobName}</IonText>
                  </IonLabel>
                </IonItem>
              ) : (
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('job')}</IonLabel>
                  <IonInput value={state.item.jobName || PLACEHOLDER} disabled />
                </IonItem>
              )}
              {state.item.jobAssetId && state.item.jobAssetName ? (
                <IonItem
                  lines="full"
                  className="tofino-stacked-item"
                  onClick={() => {
                    window.location.href = `/assets/assets/${state.item.jobAssetId}`
                  }}
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('jobAsset')}</small>
                    </IonText>
                    <br />
                    <IonText color="secondary">{state.item.jobAssetName}</IonText>
                  </IonLabel>
                </IonItem>
              ) : (
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('jobAsset')}</IonLabel>
                  <IonInput value={state.item.jobAssetName || PLACEHOLDER} disabled />
                </IonItem>
              )}
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="JobRequest"
            items={state.documentItems ?? []}
            onChange={(values) => setState('documentItems', values)}
          />
        </Tabs.TabPane>
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

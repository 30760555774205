switch (window.location.hostname) {
  case 'mobile.tofino1.com':
    window.getEnv = () => 'Production'
    module.exports = require('./config-prod')
    break

  case 'mobile-s.trmstest.com':
    window.getEnv = () => 'Staging'
    module.exports = require('./config-staging')
    break

  case 'mobile.trmstest.com':
    window.getEnv = () => 'Test'
    module.exports = require('./config-test')
    break

  case 'mobile.trmsdr.com':
    window.getEnv = () => 'Production'
    module.exports = require('./config-prod-dr')
    break

  case 'mobile-s.trmsdr.com':
    window.getEnv = () => 'Staging'
    module.exports = require('./config-staging-dr')
    break

  case 'mobile-t.trmsdr.com':
    window.getEnv = () => 'Test'
    module.exports = require('./config-test-dr')
    break

  default:
    window.getEnv = () => 'Development'
    module.exports = require('./config-dev')
    break
}

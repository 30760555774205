import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
  IonToggle,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { tryParseInt, filterLocationsByGroupIds, FILTER_SCROLL_TOP } from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray } from 'helpers/formViews'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import orderStatusOptions from 'options/orders/statusOptions'
import dateRangeTypes from 'options/dateRangeTypes'
import orderTypeOptions from 'options/orders/orderTypes'
import ModalSelect from 'elements/ModalSelect'
import DateRangePicker from 'elements/DateRangePicker'
import confirmationStatusOptions from 'options/orders/confirmationStatusOptions'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const contentRef = React.useCallback((node) => {
    if (node !== null) {
      const scrollTop = tryParseInt(getSessionItem(FILTER_SCROLL_TOP))

      if (scrollTop) {
        node.scrollToPoint(0, scrollTop, 0)
        removeSessionItem(FILTER_SCROLL_TOP)
      }
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.getLocationGroups(),
        props.getLocations(),
        props.getUsers(),
        props.getSuppliers(),
      ])

      updateState((draft) => {
        draft.loadingIsOpen = false
        draft.locationGroups = get(responses[0], 'value.data.items', [])
        draft.locations = get(responses[1], 'value.data.items', [])
        draft.users = get(responses[2], 'value.data.items', [])
        draft.suppliers = get(responses[3], 'value.data.items', [])
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const locationGroupIds = filterDto?.locationGroupIds ?? []
  const locationIds = filterDto?.locationIds ?? []
  const statusFilter = filterDto?.statusFilter ?? 'All'
  const createdBy = filterDto?.createdBy ?? []
  const supplierIds = filterDto?.supplierIds ?? []
  const dateRangeType = filterDto?.dateRange?.dateRangeType ?? 'YearToDate'
  const lastNValue = filterDto?.dateRange?.lastNValue ?? 1
  const customStartDate = filterDto?.dateRange?.customStartDate
  const customEndDate = filterDto?.dateRange?.customEndDate
  const expeditedOnly = filterDto?.expeditedOnly ?? false
  const excludeExternal = filterDto?.excludeExternal ?? false
  const includeUnconfirmedOnly = filterDto?.includeUnconfirmedOnly ?? false
  const confirmationStatusFilter = includeUnconfirmedOnly ? 'Unconfirmed' : filterDto?.confirmationStatusFilter ?? 'All'
  const issueOrderFilter = filterDto?.issueOrderFilter ?? 'All'

  if (state.activeView === 'dateRangeType') {
    return (
      <ModalSelect
        title={t('dateRangeType')}
        options={Object.entries(dateRangeTypes).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeType}
        onChange={(value) => {
          if (value === 'Custom') {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: getStartDate(dateRangeType, lastNValue).toISOString(),
                customEndDate: getEndDate(dateRangeType, lastNValue).toISOString(),
              },
            })
          } else {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: null,
                customEndDate: null,
              },
            })
          }
        }}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'locationGroupIds') {
    return (
      <ModalSelect
        title={t('locationGroup')}
        options={(state.locationGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationGroupIds}
        onChange={(values) => {
          assignFilter({
            locationGroupIds: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationIds') {
    return (
      <ModalSelect
        title={t('location')}
        options={(state.locations ?? []).filter(filterLocationsByGroupIds(locationGroupIds)).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationIds}
        onChange={(values) => setFilter('locationIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'supplierIds') {
    return (
      <ModalSelect
        title={t('supplier')}
        options={(state.suppliers ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={supplierIds}
        onChange={(values) => setFilter('supplierIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'issueOrderFilter') {
    return (
      <ModalSelect
        title={t('usagePurchase')}
        options={Object.entries(orderTypeOptions).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={issueOrderFilter}
        onChange={(value) => setFilter('issueOrderFilter', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'statusFilter') {
    return (
      <ModalSelect
        title={t('status')}
        options={Object.entries(orderStatusOptions).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={statusFilter || 'All'}
        onChange={(value) => setFilter('statusFilter', value || 'All')}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'confirmationStatus') {
    return (
      <ModalSelect
        title={t('confirmationStatus')}
        options={Object.entries(confirmationStatusOptions).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={confirmationStatusFilter || 'All'}
        onChange={(value) =>
          assignFilter({
            confirmationStatusFilter: value || 'All',
            includeUnconfirmedOnly: value === 'Unconfirmed',
          })
        }
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'createdBy') {
    return (
      <ModalSelect
        title={t('createdBy')}
        options={(state.users ?? []).map((each) => ({
          value: each.userName,
          text: each.displayName,
        }))}
        value={createdBy}
        onChange={(values) => setFilter('createdBy', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        forceOverscroll={false}
        onIonScroll={(e) => setSessionItem(FILTER_SCROLL_TOP, e.detail.scrollTop)}
        scrollEvents
      >
        <DateRangePicker {...{ dateRangeType, lastNValue, customStartDate, customEndDate, setState, setFilter }} />
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locationGroups, locationGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locations, locationIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'statusFilter')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('status')}</small>
            </IonText>
            <br />
            {t(orderStatusOptions[statusFilter])}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'createdBy')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('createdBy')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.users, createdBy, 'userName')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'supplierIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('supplier')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.suppliers, supplierIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'issueOrderFilter')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('usagePurchase')}</small>
            </IonText>
            <br />
            {t(orderTypeOptions[issueOrderFilter]) || t('all')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'confirmationStatus')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('confirmationStatus')}</small>
            </IonText>
            <br />
            {t(confirmationStatusOptions[confirmationStatusFilter])}
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('excludeExternal')}</IonLabel>
          <IonToggle checked={excludeExternal} onIonChange={(e) => setFilter('excludeExternal', e.detail.checked)} />
        </IonItem>
        {/* <IonItem lines="full">
          <IonLabel>{t('showUnconfirmed')}</IonLabel>
          <IonToggle
            checked={includeUnconfirmedOnly}
            onIonChange={(e) => setFilter('includeUnconfirmedOnly', e.detail.checked)}
          />
        </IonItem> */}
        <IonItem lines="full">
          <IonLabel>{t('expeditedOnly')}</IonLabel>
          <IonToggle checked={expeditedOnly} onIonChange={(e) => setFilter('expeditedOnly', e.detail.checked)} />
        </IonItem>
      </IonContent>
    </>
  )
}

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { isEmpty } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { getPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { showError } from 'helpers/errors'
import { isItemOrdered } from 'helpers/locationCounts'
import Icon from 'elements/Icon'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.reviewOrder',
  pageTitleLanguageKey: 'reviewOrder',
  getToolbarIcon: () => <Icon type="Info" size="26" outlined />,
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => {
      const barcode = props.useInventoryBarcode ? item.inventoryBarcode : item.barcode

      return (
        <IonItem key={item.id} lines="full" routerLink={`${props.match.url}/${encodeURIComponent(barcode)}`}>
          <IonLabel>
            {barcode}
            <br />
            <small>{item.description || PLACEHOLDER}</small>
            <br />
            {props.scannerSettings.showInventoryDescription ? (
              <>
                <small>{item.description || PLACEHOLDER}</small>
                <br />
              </>
            ) : null}
            <IonText color="success">
              <small>
                {[item.quantityToOrder > 0 ? `${t('ordered:')} ${item.quantityToOrder}` : null]
                  .filter((each) => !isEmpty(each))
                  .join(', ')}
              </small>
            </IonText>
          </IonLabel>
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const { locationCountDetails = [] } = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY, {})

        if (isEmpty(locationCountDetails.filter(isItemOrdered))) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToReview')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowDelete: (self) => (item) => true,
  allowSearch: (self) => true,
})()

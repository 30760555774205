import { createFormViewContainer } from 'factories/FormView'
import actions from 'actions/jobRequests'
import documentActions from 'actions/documents'
import { createDocumentsDispatchToProps } from 'helpers/formViews'
import Component from 'components/JobRequests/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    sendCreateNotification: (params) => dispatch(actions.sendCreateNotification(params)),
    createJob: (params) => dispatch(actions.createJob(params)),
    decline: (params) => dispatch(actions.decline(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
  }),
})(Component)

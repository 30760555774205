import { isEmpty, toString as str, trim, toLower } from 'lodash'
import store from 'helpers/store'
import selectors from 'selectors'
import { tryParseInt, tryParseFloat } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'

export const formatValue = ({ value, displayFormat = 'Text', dtoFieldName = '' }) => {
  const getOptions = (digits, style, currency) => ({
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    style,
    currency,
  })

  const text = trim(str(value))

  if (isEmpty(text)) {
    return text
  }

  if (toLower(dtoFieldName).endsWith('id')) {
    return value < 0 ? t('na') : text
  }

  const locale = selectors.auth.locale(store.getState())

  try {
    switch (displayFormat) {
      case 'Int':
        return tryParseInt(text, 0).toLocaleString(locale, getOptions(0))

      case 'Float':
        return tryParseFloat(text, 0).toLocaleString(locale)

      case 'Float1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1))

      case 'Float2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2))

      case 'Float3':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(3))

      case 'Float4':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(4))

      case 'Currency':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(undefined, 'currency', 'USD'))

      case 'Currency0':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(0, 'currency', 'USD'))

      case 'Currency1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1, 'currency', 'USD'))

      case 'Currency2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2, 'currency', 'USD'))

      case 'Currency3':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(3, 'currency', 'USD'))

      case 'Currency4':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(4, 'currency', 'USD'))

      case 'Percent':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(undefined, 'percent'))

      case 'Percent0':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(0, 'percent'))

      case 'Percent1':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(1, 'percent'))

      case 'Percent2':
        return tryParseFloat(text, 0).toLocaleString(locale, getOptions(2, 'percent'))

      case 'Date':
        return formatDateTime(text, { showDate: true, showTime: false })

      case 'Time':
        return formatDateTime(text, { showDate: false, showTime: true })

      case 'DateTime':
        return formatDateTime(text, { showDate: true, showTime: true })

      case 'DateTimeSeconds':
        return formatDateTime(text, { showDate: true, showTime: true, showSeconds: true })

      case 'Boolean':
        return ['yes', 'true', '1'].includes(toLower(text)) ? t('yes') : t('no')

      default:
        return text
    }
  } catch (error) {
    return text
  }
}

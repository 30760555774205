import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
  IonTextarea,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import { get, set, cloneDeep, isNil, camelCase, pick, isEmpty, omit, trimStart } from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import cx from 'clsx'
import { tryParseFloat, getTagDisplayNameField, getTagName, PLACEHOLDER, message } from 'helpers/utils'
import { validateOperatorTag, validateJobTag, validateOtherTags } from 'helpers/procurement'
import { t } from 'helpers/i18n'
import { getChangedItems } from 'helpers/formViews'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import serviceStatusOptions from 'options/assets/serviceStatusOptions'
import itemConditions from 'options/assets/itemConditions'
import { Emitter, stopEvent } from 'helpers/events'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import Icon from 'elements/Icon'
import DatePicker from 'elements/DatePicker'
import Tolerances from 'containers/Assets/Tolerances'
import History from 'containers/Assets/History'
import Documents from 'containers/Documents/ChildListView'

export const TRACK_ASSETS_AFTER_CLOSE_KEY = 'assets.trackAssets.afterDrawerClose'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  'A',
  'B',
  'C',
  'D',
  'E',
]

export const getStorageKey = () => 'trackAssets.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/assets'
    }
  }, [])

  const [state, updateState] = useImmer({ tabsActiveKey: 'maintenance' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const response = await props.getItem(itemId)

      const item = cloneDeep({
        ...response.value.data,
        ...getSessionItem('trackAssets.tags', {}),
      })

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      const [documentItems, toleranceItems, historyItems] = await Promise.all([
        props.getDocumentItems({
          request: {
            domainObjectId: item.id,
            domainObjectType: 'Asset',
            documentType: 'ObjectDocument',
          },
        }),
        props.getToleranceItems(item.id),
        props.getHistoryItems(item.id),
      ])

      updateState((draft) => {
        draft.item = cloneDeep(item)
        draft.documentItems = get(documentItems, 'value.data.items', [])
        draft.toleranceItems = get(toleranceItems, 'value.data.items', [])
        draft.toleranceItemsOriginal = get(toleranceItems, 'value.data.items', [])
        draft.historyItems = get(historyItems, 'value.data.items', [])
        draft.historyItemsOriginal = get(historyItems, 'value.data.items', [])
      })

      removeSessionItem('trackAssets.tags')
    } catch (error) {
      showError({ error })
    }
  }

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledAssets`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const response = await props.updateItem(values)

          await saveToleranceHistoryItems(response.value.data.id)

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const { redirectUrl } = getSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY, {})

          if (redirectUrl) {
            removeSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY)
            window.location.href = redirectUrl
            return
          }

          props.history.goBack()
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  async function saveToleranceHistoryItems(assetId) {
    const { updating } = getChangedItems(state.toleranceItemsOriginal, state.toleranceItems)

    if (!isEmpty(updating)) {
      const response = await props.createTolerancesHistoryItems(
        assetId,
        updating.map((each) => ({
          id: 0,
          assetId,
          toleranceId: each.id,
          entryDate: new Date().toJSON(),
          userName: props.user.userName,
          value: each.newValue,
          min: each.min,
          max: each.max,
          notify: each.notify,
          notifySent: false,
        }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('trackAsset')

  if (isNil(state.item)) {
    return (
      <Page>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'sortOptions':
        if (state.tabsActiveKey === 'tolerances') {
          Emitter.emit('trackAssets.formView.tolerances.sortOptions')
        }

        if (state.tabsActiveKey === 'history') {
          Emitter.emit('trackAssets.formView.history.sortOptions')
        }

        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const defaultItems = []

    switch (state.tabsActiveKey) {
      case 'tolerances':
        return [
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ]

      case 'history':
        return [
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ]

      case 'documents':
        return [
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ]

      default:
        return defaultItems
    }
  })().filter(Boolean)

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        <IonRow>
          {state.item.status === 'In' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => setItemValue('status', 'Out')}
                disabled={!isOnline}
              >
                {t('checkOut')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'Out' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => setItemValue('status', 'In')}
                disabled={!isOnline}
              >
                {t('checkIn')}
              </IonButton>
            </IonCol>
          )}
          <IonCol>
            <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
              {t('saveAndClose')}
            </IonButton>
          </IonCol>
        </IonRow>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="asset" tab={t('asset')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('assetId')}</IonLabel>
            <IonInput value={state.item.barcode} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('partNumber')}</IonLabel>
            <IonInput value={state.item.itemNumber} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('description')}</IonLabel>
            <IonInput value={state.item.itemNumber} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem
            key={state.item.status}
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectServiceStatus`)
            }}
            disabled={state.item.status === 'Out'}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('status')}</small>
              </IonText>
              <br />
              {state.item.serviceStatus ? t(serviceStatusOptions[state.item.serviceStatus]) : t('none')}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('location')}</IonLabel>
            <IonInput value={state.item.locationName} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('subLocation')}</IonLabel>
            <IonInput value={state.item.binLocation} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('manufacturer')}</IonLabel>
            <IonInput value={state.item.manufacturer} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('reorderBarcode')}</IonLabel>
            <IonInput value={state.item.replacementBarcode} placeholder={PLACEHOLDER} disabled />
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="maintenance" tab={t('maintenance')} forceRender>
          <DatePicker
            label={t('lastCalibration')}
            value={state.item.lastCalibrationDate}
            onChange={(value) => setItemValue('lastCalibrationDate', value)}
          />
          <DatePicker
            label={t('nextCalibration')}
            value={state.item.nextCalibrationDate}
            onChange={(value) => setItemValue('nextCalibrationDate', value)}
          />
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectCondition`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('itemCondition')}</small>
              </IonText>
              <br />
              {t(itemConditions[state.item.itemCondition])}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectLifeRemaining`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('remainingLife')}</small>
              </IonText>
              <br />
              {`${state.item.lifeRemaining}%`}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('serviceHours')}</IonLabel>
            <IonInput
              value={state.item.serviceHours}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('serviceHours', tryParseFloat(e.target.value, 0))}
              clearOnEdit
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('mileage')}</IonLabel>
            <IonInput
              value={state.item.mileage}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('mileage', tryParseFloat(e.target.value, 0))}
              clearOnEdit
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('comments')}</IonLabel>
            <IonTextarea
              value={state.item.historyComment}
              onIonInput={(e) => setItemValue('historyComment', e.target.value)}
              placeholder={PLACEHOLDER}
              rows={3}
              autoGrow
            />
          </IonItem>
        </Tabs.TabPane>
        {!isEmpty(enabledTagTypes) && (
          <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
            {enabledTagTypes.map((tagType) => (
              <IonItem
                key={tagType}
                lines="full"
                className={cx('tofino-stacked-item', {
                  'tofino-required-item': getTagIsRequired(tagType),
                  'tofino-error-item': get(state, `errors.${tagType}`),
                })}
              >
                <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
                <IonInput
                  value={getTagDisplayName(tagType)}
                  placeholder={PLACEHOLDER}
                  onIonInput={handleTagInput(tagType)}
                  onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
                />
                {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
              </IonItem>
            ))}
          </Tabs.TabPane>
        )}
        <Tabs.TabPane key="tolerances" tab={t('tolerances')} forceRender>
          <Tolerances
            settingsType="jobs"
            storageKey="trackAssets.formView.tolerances"
            parentRecord={state.item}
            items={state.toleranceItems}
            originals={state.toleranceItemsOriginal}
            onClick={(item) => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/tolerances/${item.id}`)
            }}
            onChange={(values) => setState('toleranceItems', values)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="history" tab={t('history')} forceRender>
          <History
            storageKey="trackAssets.formView.history"
            parentRecord={state.item}
            items={state.historyItems}
            originals={state.historyItems}
            onClick={(item) => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/history/${item.id}`)
            }}
            onChange={(values) => setState('historyItems', values)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Asset"
            items={state.documentItems ?? []}
            readOnly
          />
        </Tabs.TabPane>
      </Tabs>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
    </Page>
  )
}

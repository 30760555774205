import * as React from 'react'
import {
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonSearchbar,
  IonText,
  IonAlert,
  IonPopover,
  IonModal,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { useImmer } from 'use-immer'
import cx from 'clsx'
import { set, isNil, isEmpty, isFinite, cloneDeep } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { t } from 'helpers/i18n'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import {
  asyncSleep,
  simulatePressEnter,
  message,
  tryParseInt,
  strEqual,
  MAX_NUMBER,
  PLACEHOLDER,
  FOCUS_DELAY,
  createBlurByTagName,
  createFocusByClassName,
} from 'helpers/utils'
import { stopEvent } from 'helpers/events'
import { showError, showValidationErrors } from 'helpers/errors'
import {
  getShowCount,
  getCurrentCount,
  getOrderQuantity,
  getStoredInventoryForLocationId,
} from 'helpers/locationCounts'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { getSharedProducts } from 'helpers/offlineData'
import Page from 'elements/Page'
import Stepper from 'elements/Stepper'
import Icon from 'elements/Icon'
import Spin from 'elements/Spin'
import Header from './Header'
import Button from 'elements/Button'
import SearchbarContainer from 'elements/SearchbarContainer'
import BarcodeScanner from 'elements/BarcodeScanner'

const blurInputs = createBlurByTagName('input')
const focusSearchBar = createFocusByClassName('searchbar-input')
const focusFirstInput = createFocusByClassName('native-input')

function Description({
  inventory,
  scannerSettings,
  product,
  externalStatus,
  externalStatusError,
  externalStatusLoading,
}) {
  const externalStatusNoInternet = externalStatusError && !externalStatusError.response
  const externalStatusNotAvailable = externalStatus && isEmpty(externalStatus.items)
  const { quantityOnHand = 0, quantityOnOrder = 0 } = externalStatus?.items?.[0] ?? {}

  return (
    <>
      <p className="ion-margin-horizontal">
        {inventory ? (
          <>
            {inventory.itemDescription}
            <br />
            {scannerSettings.showInventoryDescription && inventory.description ? (
              <>
                {inventory.description}
                <br />
              </>
            ) : null}
            {t('onOrder:')} {inventory.onOrder}
            <br />
            {t('minMax:')} {[inventory.min, inventory.max].join('/')}
            <br />
            {t('pkg:')} {inventory.packageSizeUom || PLACEHOLDER}
            <br />
            {t('binLocation:')} {inventory.binLocation || PLACEHOLDER}
            <br />
            {t('mustCount:')} {t(inventory.mustCount ? 'yes' : 'no')}
          </>
        ) : product ? (
          <>
            {product.description}
            <br />
            {t('pkg:')} {product.packageSizeUom || PLACEHOLDER}
          </>
        ) : (
          <IonText color="medium">{t('enterBarcodeInfo')}</IonText>
        )}
      </p>
      {(inventory || product) && (externalStatus || externalStatusError) ? (
        <p className="ion-margin">
          <IonText color={externalStatusNoInternet ? 'medium' : undefined}>
            <u>{t('distributor')}</u>
            <br />
            {t('onHand:')}{' '}
            {externalStatusNoInternet
              ? t('noInternet')
              : externalStatusNotAvailable
                ? t('notAvailable')
                : quantityOnHand}
            <br />
            {t('onOrder:')}{' '}
            {externalStatusNoInternet
              ? t('noInternet')
              : externalStatusNotAvailable
                ? t('notAvailable')
                : quantityOnOrder}
          </IonText>
        </p>
      ) : externalStatusLoading ? (
        <div className="ion-margin">
          <Spin />
        </div>
      ) : null}
    </>
  )
}

export default function (props) {
  const { customerNumber, generalSettings } = props.customer
  const { enableExternalItemStatusRequest } = generalSettings

  const refBarcode = React.useRef()
  const refCount = React.useRef()
  const refCount2 = React.useRef()
  const refQuantityToOrder = React.useRef()

  const [state, updateState] = useImmer({
    offlineProducts: [],
    offlineInventory: [],
  })

  const showCountInput = () => state.item && state.inventory && getShowCount(state.currentLocation)
  const showCount2Input = () => state.item && state.inventory && props.scannerSettings.showCount2
  const showQuantityToOrderInput = () => state.item && (state.inventory || state.product)
  const quantityToOrderIsDisabled = () =>
    props.tenantGroupIsCardinal &&
    state.currentLocation?.cartType === 'Par' &&
    state.currentLocation?.minPar &&
    showCountInput()

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function resetForm() {
    focusSearchBar()

    updateState((draft) => {
      draft.item = null
      draft.product = null
      draft.inventory = null
      draft.externalStatus = null
      draft.externalStatusError = null
      draft.externalStatusLoading = false
      draft.itemBarcode = ''
    })

    window.setTimeout(focusSearchBar, FOCUS_DELAY)
  }

  function fetchByInventoryBarcode(
    barcode,
    currentCount = state.currentCount,
    currentLocation = state.currentLocation,
    offlineInventory = state.offlineInventory
  ) {
    let item = null
    let product = null // eslint-disable-line prefer-const
    let inventory = null

    if (barcode) {
      try {
        message.destroy()

        inventory = offlineInventory.find(
          (one) => one.locationId === currentCount.locationId && strEqual(one.inventoryBarcode, barcode)
        )

        if (isNil(inventory)) {
          window.setTimeout(focusSearchBar, FOCUS_DELAY)
          throw new Error(t('itemNotFound'))
        }

        if (inventory.lockedForCycleCount) {
          throw new Error(t('errorLockedForCycleCount'))
        }

        item = currentCount.locationCountDetails.find((one) => one.inventoryId === inventory.id)

        if (isNil(item)) {
          item = {
            inventoryId: inventory.id,
            binLocation: inventory.binLocation,
            inventoryBarcode: inventory.inventoryBarcode,
            barcode: inventory.barcode,
            itemNumber: inventory.itemNumber,
            description: inventory.itemDescription,
            price: inventory.price,
            onHand: inventory.onHand,
            onHand2: inventory.onHand2,
            timeScanned: new Date().toJSON(),
            locationCountId: currentCount.id,
            packageSize: inventory.itemPackageSize,
            count: null,
            count2: null,
            quantityToOrder: 0,
          }

          if (getShowCount(currentLocation)) {
            item.count = props.scannerSettings.showOnhandQuantity ? item.onHand : 0

            if (props.scannerSettings.showCount2) {
              item.count2 = props.scannerSettings.showOnhandQuantity ? item.onHand2 : 0
            }

            if (currentLocation?.cartType !== 'Req') {
              item.quantityToOrder = item.quantityToOrderOriginal = getOrderQuantity({
                scanType: props.customer.generalSettings.scanType,
                isCardinal: props.tenantGroupIsCardinal,
                cartType: currentLocation?.cartType,
                minPar: currentLocation?.minPar,
                min: inventory?.min,
                max: inventory?.max,
                packageSize: product?.packageSize ?? inventory?.itemPackageSize,
                count: item.count,
                count2: item.count2,
              })
            }
          }
        }
      } catch (error) {
        showError({ error })
      }
    }

    // console.log('fetchByInventoryBarcode:', { item, product, inventory })

    updateState((draft) => {
      draft.item = item
      draft.product = product
      draft.inventory = inventory
      draft.externalStatus = null
      draft.externalStatusError = null
      draft.externalStatusLoading = false
      draft.timestamp = new Date().toJSON()

      if (inventory) {
        draft.itemBarcode = inventory.inventoryBarcode
      }
    })

    fetchExternalItemStatus({ product, inventory, currentLocation })

    window.setTimeout(focusFirstInput, FOCUS_DELAY)
  }

  // eslint-disable-next-line max-params
  function fetchByProductBarcode(
    barcode,
    currentCount = state.currentCount,
    currentLocation = state.currentLocation,
    offlineProducts = state.offlineProducts,
    offlineInventory = state.offlineInventory
  ) {
    let item = null
    let product = null
    let inventory = null

    if (barcode) {
      try {
        message.destroy()

        product = offlineProducts.find((one) => strEqual(one.barcode, barcode))

        if (isNil(product)) {
          window.setTimeout(focusSearchBar, FOCUS_DELAY)
          throw new Error(t('itemNotFound'))
        }

        inventory = offlineInventory.find(
          (one) => one.locationId === currentCount.locationId && strEqual(one.barcode, barcode)
        )

        if (inventory?.lockedForCycleCount) {
          throw new Error(t('errorLockedForCycleCount'))
        }

        if (inventory?.id) {
          item = currentCount.locationCountDetails.find((one) => one.inventoryId === inventory.id)
        } else {
          message.info(t('locationCountInventoryNotFound'), 5000)
        }

        if (isNil(item)) {
          item = currentCount.locationCountDetails.find((one) => one.productId === product.id)
        }

        if (isNil(item)) {
          item = {
            productId: product.id,
            inventoryId: inventory?.id,
            binLocation: inventory?.binLocation,
            inventoryBarcode: inventory?.inventoryBarcode,
            barcode: product.barcode,
            itemNumber: inventory?.itemNumber,
            description: product.description,
            price: product.price,
            packageSizeUom: product.packageSizeUom,
            onHand: inventory?.onHand ?? 0,
            onHand2: inventory?.onHand2 ?? 0,
            timeScanned: new Date().toJSON(),
            locationCountId: currentCount.id,
            packageSize: product.packageSize,
            unitOfMeasure: product.unitOfMeasureDescription,
            count: null,
            count2: null,
            quantityToOrder: 0,
          }

          if (getShowCount(currentLocation) && !isNil(inventory)) {
            item.count = props.scannerSettings.showOnhandQuantity ? item.onHand : 0

            if (props.scannerSettings.showCount2) {
              item.count2 = props.scannerSettings.showOnhandQuantity ? item.onHand2 : 0
            }

            if (currentLocation?.cartType !== 'Req') {
              item.quantityToOrder = item.quantityToOrderOriginal = getOrderQuantity({
                scanType: props.customer.generalSettings.scanType,
                isCardinal: props.tenantGroupIsCardinal,
                cartType: currentLocation?.cartType,
                minPar: currentLocation?.minPar,
                min: inventory?.min,
                max: inventory?.max,
                packageSize: product?.packageSize ?? inventory?.itemPackageSize,
                count: item.count,
                count2: item.count2,
              })
            }
          }
        }
      } catch (error) {
        showError({ error })
      }
    }

    // console.log('fetchByProductBarcode:', { item, product, inventory })

    updateState((draft) => {
      draft.item = item
      draft.product = product
      draft.inventory = inventory
      draft.externalStatus = null
      draft.externalStatusError = null
      draft.externalStatusLoading = false
      draft.timestamp = new Date().toJSON()

      if (product) {
        draft.itemBarcode = product.barcode
      }
    })

    fetchExternalItemStatus({ product, inventory, currentLocation })

    window.setTimeout(focusFirstInput, FOCUS_DELAY)
  }

  async function focusInput(inputRef) {
    try {
      await inputRef.current.setFocus()
    } catch (error) {
      console.warn(error)
    }
  }

  async function fetchExternalItemStatus({ product, inventory, currentLocation }) {
    if ((inventory || product) && enableExternalItemStatusRequest) {
      try {
        setState('externalStatusLoading', true)

        const response = await props.getExternalItemStatus({
          customerNumber,
          supplierId: inventory?.supplierId || inventory?.itemSupplierId || 0,
          locationBarcode: currentLocation?.barcode,
          itemBarcode: product?.barcode,
        })

        setState('externalStatus', response.value.data)
      } catch (error) {
        setState('externalStatusError', error)
      } finally {
        setState('externalStatusLoading', false)
      }
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (tryParseInt(values.count, 0) > MAX_NUMBER) {
      errors.count = t('quantityTooLarge')
    }

    if (tryParseInt(values.count2, 0) > MAX_NUMBER) {
      errors.count2 = t('quantityTooLarge')
    }

    if (tryParseInt(values.quantityToOrder, 0) > MAX_NUMBER) {
      errors.quantityToOrder = t('quantityTooLarge')
    }

    callback(errors, values)
  }

  async function updateItem() {
    try {
      await updatePrivateItem(LOCATION_COUNTS_CURRENT_KEY, {}, (draft) => {
        const { id = Date.now() * -1, barcode, inventoryBarcode, quantityToOrder } = state.item
        const count = showCountInput() ? state.item.count : null
        const count2 = showCount2Input() ? state.item.count2 : null

        const index = draft.locationCountDetails.findIndex((each) =>
          props.useInventoryBarcode
            ? strEqual(each.inventoryBarcode, inventoryBarcode)
            : strEqual(each.barcode, barcode)
        )

        if (isNil(count) && isNil(count2) && quantityToOrder === 0) {
          if (index > -1) {
            draft.locationCountDetails.splice(index, 1)
          }
        } else if (index === -1) {
          draft.locationCountDetails.unshift({ ...state.item, id, count, count2 })
        } else {
          draft.locationCountDetails[index] = { ...state.item, id, count, count2 }
        }
      })

      const response = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)
      setState('currentCount', response)

      message.success(
        getShowCount(state.currentLocation) ? t('quantityHasBeenSaved') : t('orderHasBeenSaved')
      )

      if (props.match.params.itemBarcode) {
        props.history.goBack()
      } else {
        resetForm()
      }
    } catch (error) {
      showError({ error })
    }
  }

  function saveItem({ changeOrderQuantityConfirmed, partialPackageConfirmed, orderQuantityConfirmed } = {}) {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        if (
          !changeOrderQuantityConfirmed &&
          props.scannerSettings.confirmOrderQuantity &&
          values.quantityToOrder !== values.quantityToOrderOriginal
        ) {
          const buttons = [
            {
              text: t('cancel'),
              role: 'cancel',
              handler: () => {
                updateState((draft) => {
                  draft.item.quantityToOrder = draft.item.quantityToOrderOriginal
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  focusInput(refQuantityToOrder)
                }, 250)
              },
            },
            {
              text: t('ok'),
              handler: () => {
                updateState((draft) => {
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  saveItem({
                    changeOrderQuantityConfirmed: true,
                    partialPackageConfirmed,
                    orderQuantityConfirmed,
                  })
                }, 250)
              },
            },
          ]

          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmChangeOrderQuantity')
            draft.alertButtons = buttons
          })
        } else if (!partialPackageConfirmed && values.quantityToOrder % values.packageSize > 0) {
          const buttons = [
            {
              text: t('cancel'),
              role: 'cancel',
              handler: () => {
                updateState((draft) => {
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  focusInput(refQuantityToOrder)
                }, 250)
              },
            },
            {
              text: t('ok'),
              handler: () => {
                updateState((draft) => {
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  saveItem({
                    changeOrderQuantityConfirmed,
                    partialPackageConfirmed: true,
                    orderQuantityConfirmed,
                  })
                }, 250)
              },
            },
          ]

          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmPartialPackage')
            draft.alertButtons = buttons
          })
        } else if (!orderQuantityConfirmed && values.quantityToOrder > 999) {
          const buttons = [
            {
              text: t('cancel'),
              role: 'cancel',
              handler: () => {
                updateState((draft) => {
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  focusInput(refQuantityToOrder)
                }, 250)
              },
            },
            {
              text: t('ok'),
              handler: () => {
                updateState((draft) => {
                  draft.alertIsOpen = false
                })
                window.setTimeout(() => {
                  saveItem({
                    changeOrderQuantityConfirmed,
                    partialPackageConfirmed,
                    orderQuantityConfirmed: true,
                  })
                }, 250)
              },
            },
          ]

          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmOrderQuantity')
            draft.alertButtons = buttons
          })
        } else {
          updateItem()
        }
      } else {
        showValidationErrors({ errors })
      }
    })
  }

  function handleCancel() {
    if (isEmpty(props.match.params.itemBarcode)) {
      resetForm()
    } else {
      props.history.goBack()
    }
  }

  function handleKeyPress(input) {
    switch (input) {
      case 'barcode':
        return async (e) => {
          if (e.key === 'Enter') {
            if (props.useInventoryBarcode) {
              await fetchByInventoryBarcode(e.target.value)
            } else {
              await fetchByProductBarcode(e.target.value)
            }
          }
        }

      case 'count':
        return async (e) => {
          if (e.key === 'Enter') {
            if (props.scannerSettings.showCount2) {
              await focusInput(refCount2)
            } else if (quantityToOrderIsDisabled()) {
              saveItem()
            } else {
              await focusInput(refQuantityToOrder)
            }
          } else if (e.key.match(/\D/)) {
            stopEvent(e)
          }
        }

      case 'count2':
        return async (e) => {
          if (e.key === 'Enter') {
            if (quantityToOrderIsDisabled()) {
              saveItem()
            } else {
              await focusInput(refQuantityToOrder)
            }
          } else if (e.key.match(/\D/)) {
            stopEvent(e)
          }
        }

      case 'quantityToOrder':
        return (e) => {
          if (e.key === 'Enter') {
            saveItem()
          } else if (e.key.match(/\D/)) {
            stopEvent(e)
          }
        }

      default:
        return undefined
    }
  }

  async function fetchItem() {
    try {
      const { currentCount, currentLocation } = await getCurrentCount()
      const offlineProducts = props.useInventoryBarcode ? [] : await getSharedProducts()
      const offlineInventory = await getStoredInventoryForLocationId(currentLocation.id)

      updateState((draft) => {
        draft.currentCount = currentCount
        draft.currentLocation = currentLocation
        draft.offlineProducts = offlineProducts
        draft.offlineInventory = offlineInventory
      })

      if (isEmpty(props.match.params.itemBarcode)) {
        window.setTimeout(focusSearchBar, FOCUS_DELAY)
      } else {
        const decodedBarcode = decodeURIComponent(props.match.params.itemBarcode)

        setState((draft) => {
          draft.itemBarcode = decodedBarcode
          draft.searchIsDisabled = true
        })

        setState('searchIsDisabled', true)

        if (props.useInventoryBarcode) {
          await fetchByInventoryBarcode(decodedBarcode, currentCount, currentLocation, offlineInventory)
        } else {
          await fetchByProductBarcode(
            decodedBarcode,
            currentCount,
            currentLocation,
            offlineProducts,
            offlineInventory
          )
        }
      }
    } catch (error) {
      showError({ error })

      if (!isEmpty(props.match.params.itemBarcode)) {
        props.history.goBack()
      }
    }
  }

  useIonViewWillLeave(() => {
    blurInputs()
  })

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const setCount = React.useCallback((name, value) => {
    updateState((draft) => {
      draft.item[name] = isFinite(value) ? value : tryParseInt(value, 0)
    })
    refreshQuantityToOrder()
  }, [])

  const refreshQuantityToOrder = useDebouncedCallback(() => {
    updateState((draft) => {
      if (draft.currentLocation?.cartType !== 'Req') {
        draft.item.quantityToOrder = draft.item.quantityToOrderOriginal = getOrderQuantity({
          scanType: props.customer.generalSettings.scanType,
          isCardinal: props.tenantGroupIsCardinal,
          cartType: draft.currentLocation?.cartType,
          minPar: draft.currentLocation?.minPar,
          min: draft.inventory.min,
          max: draft.inventory.max,
          packageSize: draft.product?.packageSize ?? draft.inventory?.itemPackageSize,
          count: draft.item.count,
          count2: draft.item.count2,
        })
      }
    })
  }, 25)

  const handleCountInput = React.useCallback((e) => {
    setCount('count', tryParseInt(e.target.value, 0))
  }, [])

  const handleCountStep = React.useCallback((value) => {
    updateState((draft) => {
      draft.item.count = !isNil(value) ? Math.max(tryParseInt(draft.item.count, 0) + value, 0) : 0
    })
    refreshQuantityToOrder()
  }, [])

  const handleCount2Input = React.useCallback((e) => {
    setCount('count2', tryParseInt(e.target.value, 0))
  }, [])

  const handleCount2Step = React.useCallback((value) => {
    updateState((draft) => {
      draft.item.count2 = !isNil(value) ? Math.max(tryParseInt(draft.item.count2, 0) + value, 0) : 0
    })
    refreshQuantityToOrder()
  }, [])

  const handleQuantityToOrderInput = React.useCallback(({ target: { value } }) => {
    setState('item.quantityToOrder', isFinite(value) ? value : tryParseInt(value, 0))
  }, [])

  const handleQuantityToOrderStep = React.useCallback((value) => {
    updateState((draft) => {
      draft.item.quantityToOrder = !isNil(value)
        ? Math.max(tryParseInt(draft.item.quantityToOrder, 0) + value, 0)
        : 0
    })
  }, [])

  const pageTitle = isNil(state.item) || getShowCount(state.currentLocation) ? t('countItem') : t('orderItem')

  function handleBarcodeInput(e) {
    updateState((draft) => {
      draft.item = null
      draft.product = null
      draft.inventory = null
      draft.externalStatus = null
      draft.externalStatusError = null
      draft.externalStatusLoading = false
      draft.itemBarcode = e.target.value
    })
  }

  async function handleBarcodeScan({ rawValue: value }) {
    setState('scanBarcodeIsOpen', false)
    await asyncSleep(500)
    refBarcode.current.setFocus()
    refBarcode.current.value = value
    handleBarcodeInput({ target: { value } })
    simulatePressEnter(refBarcode.current)
  }

  const pageHeader = (
    <SearchbarContainer lines="none">
      <IonSearchbar
        ref={refBarcode}
        value={state.itemBarcode}
        placeholder={t('enterBarcode')}
        onKeyPress={handleKeyPress('barcode')}
        onIonClear={resetForm}
        onIonInput={handleBarcodeInput}
        disabled={state.searchIsDisabled}
        maxlength={200}
        clearOnEdit
      />
      {props.scannerSettings.enableCameraScanning && (
        <Button onClick={() => setState('scanBarcodeIsOpen', true)}>
          <Icon type="barcode_scanner" style={{ color: 'var(--ion-color-primary)' }} symbols />
        </Button>
      )}
    </SearchbarContainer>
  )

  const pageFooter = !isNil(state.item) ? (
    <IonRow>
      <IonCol>
        <IonButton color="transparent" expand="full" onClick={handleCancel}>
          {t(isEmpty(props.match.params.itemBarcode) ? 'skip' : 'cancel')}
        </IonButton>
      </IonCol>
      <IonCol className="ion-text-right">
        <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
          {t(state.item.id ? 'update' : 'save')}
        </IonButton>
      </IonCol>
    </IonRow>
  ) : null

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        <IonButton
          onClick={(e) => {
            updateState((draft) => {
              draft.popoverIsOpen = true
              draft.popoverEvent = e
            })
          }}
        >
          <Icon type="Info" size="26" outlined />
        </IonButton>
      }
      header={pageHeader}
      footer={pageFooter}
    >
      {showCountInput() && (
        <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.count })}>
          <IonLabel>{t('count')}</IonLabel>
          <IonInput
            ref={refCount}
            className="ion-text-right"
            value={state.item.count}
            onIonInput={handleCountInput}
            onKeyPress={handleKeyPress('count')}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
          <Stepper onClick={handleCountStep} />
        </IonItem>
      )}
      {showCount2Input() && (
        <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.count2 })}>
          <IonLabel>{t('count2')}</IonLabel>
          <IonInput
            ref={refCount2}
            className="ion-text-right"
            value={state.item.count2}
            onIonInput={handleCount2Input}
            onKeyPress={handleKeyPress('count2')}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
          <Stepper onClick={handleCount2Step} />
        </IonItem>
      )}
      {showQuantityToOrderInput() && (
        <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.quantityToOrder })}>
          <IonLabel>{t('order')}</IonLabel>
          <IonInput
            ref={refQuantityToOrder}
            className="ion-text-right"
            value={state.item.quantityToOrder}
            onIonInput={handleQuantityToOrderInput}
            onKeyPress={handleKeyPress('quantityToOrder')}
            disabled={quantityToOrderIsDisabled()}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
          <Stepper onClick={handleQuantityToOrderStep} disabled={quantityToOrderIsDisabled()} />
        </IonItem>
      )}
      <Description
        scannerSettings={props.scannerSettings}
        inventory={state.inventory}
        product={state.product}
        externalStatus={state.externalStatus}
        externalStatusError={state.externalStatusError}
        externalStatusLoading={state.externalStatusLoading}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <Header className="ion-margin tofino-stacked-list" />
      </IonPopover>
      <IonModal isOpen={state.scanBarcodeIsOpen}>
        {state.scanBarcodeIsOpen && (
          <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
        )}
      </IonModal>
    </Page>
  )
}

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import authActions from 'actions/auth'
import userActions from 'actions/users'
import Component from 'components/Settings/User/Language'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  refreshToken: authActions.refreshToken,
  updateUser: userActions.updateItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

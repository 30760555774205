import { createListViewContainer } from 'factories/ListView'
import { ISSUED_ITEMS_UPLOAD_RESULTS_KEY } from 'options/inventory/issue'
import { getPrivateItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'
import Component from 'components/Issue/UploadResults'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: () => getPrivateItem(ISSUED_ITEMS_UPLOAD_RESULTS_KEY).then(wrapItems),
  }),
})(Component)

import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { DAYS_OF_WEEK } from 'options/tasks'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/Tasks/FormView/SelectRecurOnDaysOfWeek'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(DAYS_OF_WEEK.map((each, index) => ({ id: index, displayName: t(each) }))),
  }),
})(Component)

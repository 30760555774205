import * as React from 'react'
import { IonItem, IonLabel, IonList } from '@ionic/react'
import { t } from 'helpers/i18n'
import { getUserSettings } from 'helpers/settings'
import { userStartupOptions } from 'options/startupOptions'
import { SETTINGS_USER_STARTUP_KEY } from 'options/settings'
import { setStorageItem } from 'helpers/localStorage'
import Page from 'elements/Page'
import Icon from 'elements/Icon'

export default function (props) {
  const [startupPage, setStartupPage] = React.useState(getUserSettings().startupPage)

  return (
    <Page title={t('startupPage')}>
      <IonList>
        {Object.entries(userStartupOptions).map(([key, value]) => (
          <IonItem
            key={key}
            lines="full"
            onClick={() => {
              setStorageItem(SETTINGS_USER_STARTUP_KEY, key)
              setStartupPage(key)
            }}
            detail={false}
            button
          >
            <IonLabel>{t(value)}</IonLabel>
            <Icon.Radio checked={startupPage === key} />
          </IonItem>
        ))}
      </IonList>
    </Page>
  )
}

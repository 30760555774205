import produce from 'immer'
import { snakeCase, set } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.requisitions
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']

        normalizeDateRange(draft)
      }),
  }),

  saveRequisition: (params = {}, options) => ({
    type: `${ENTITY_NAME}_SAVE`,
    payload: createPost(entityName, { action: 'save' })(
      produce(params, (draft) => {
        set(draft, 'requisitionItems.creating', (draft.requisitionItems?.creating ?? []).reverse())
      }),
      options
    ),
  }),

  sendRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_SEND`,
    payload: createPost(entityName, { action: `${requisitionId}/send` })(),
  }),

  resendRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_RESEND`,
    payload: createPost(entityName, { action: `${requisitionId}/send` })(),
  }),

  approveRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_APPROVE`,
    payload: createPost(entityName, { action: `${requisitionId}/approve` })(),
  }),

  unapproveRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_UNAPPROVE`,
    payload: createPost(entityName, { action: `${requisitionId}/unapprove` })(),
  }),

  cancelRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_CANCEL`,
    payload: createPost(entityName, { action: `${requisitionId}/cancel` })(),
  }),

  closeRequisition: (requisitionId) => ({
    type: `${ENTITY_NAME}_CLOSE`,
    payload: createPost(entityName, { action: `${requisitionId}/close` })(),
  }),

  emailRequisition: (params) => ({
    type: `${ENTITY_NAME}_EMAIL`,
    payload: createPost(entityName, { action: 'email' })(params),
  }),
}

import { isEmpty, set } from 'lodash'
import { IonItem, IonLabel, IonButton, IonText } from '@ionic/react'
import { tryParseInt } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { formatUserTime } from 'helpers/dateTime'
import { showClientNotifications, showError } from 'helpers/errors'
import { Emitter } from 'helpers/events'
import { LISTS_FETCH_ITEMS_EVENT } from 'options/events'
import Icon from 'elements/Icon'
import Segmenter from 'elements/Segmenter'

export default createListViewComponent({
  pageTitle: (self) => t(`addListTo${self.props.entityType}`),
  getStorageKey: () => 'lists.childListView.addList',
  getInsignificantFilterDtoFields: (self) => ['type'],
  getListHeader: ({ props, state, updateState, fetchItems, firstPageIndex }) => (
    <Segmenter
      value={state.filterDto?.type ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Product', text: t('product') },
        { value: 'Asset', text: t('asset') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.type', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
    />
  ),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys.splice(index, 1)
          } else {
            draft.selectedRowKeys.push(item.id)
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel>
        {item.name}
        <br />
        <IonText color="medium">
          <small>
            {t('type:')} {item.typeName}, {t('itemsOnList:')} {item.numberOfItemsOnList}
            <br />
            {t('created:')} {formatUserTime(item.createdDate, item.createdBy)}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Check checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: () => 75,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
      onClick={async () => {
        try {
          const items = self.state.items.filter((each) => self.state.selectedRowKeys.includes(each.listId))

          self.setState('loadingIsOpen', true)

          const response = await self.props.createItems(
            items.map((each) => ({
              entityId: tryParseInt(self.props.match.params.itemId),
              entityType: self.props.entityType,
              listId: each.listId,
              listType: each.type,
            }))
          )

          showClientNotifications({ response })

          if (response.value.data.items[0]) {
            Emitter.emit(LISTS_FETCH_ITEMS_EVENT)

            self.setState('selectedRowKeys', [])

            self.props.history.goBack()
          }
        } catch (error) {
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
    >
      {t('addList')} {!isEmpty(self.state.selectedRowKeys) ? `(${self.state.selectedRowKeys.length})` : ''}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'name', languageKey: 'name' },
    { fieldName: 'typeName', languageKey: 'type' },
    { fieldName: 'numberOfItemsOnList', languageKey: 'itemsOnList' },
    { fieldName: 'numberOfAssociations', languageKey: 'associations' },
    { fieldName: 'createdBy', languageKey: 'createdBy' },
    { fieldName: 'createdDate', languageKey: 'createdDate' },
  ],
})()

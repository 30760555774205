import { snakeCase, omit } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createChildPost, createOrphanedChildPost } from 'helpers/api'

const entityName = entityNames.orders
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'orderItems'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  ...createChildActions(entityName, childName),

  populateOrderItems: (params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_POPULATE`,
    payload: createOrphanedChildPost(entityName, childName, { action: 'populate' })(params),
  }),

  issueOrderItem: (parentId, params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_ISSUE`,
    payload: createChildPost(entityName, childName, { action: 'issue' })(
      parentId,
      omit(params, ['jobDisplayName', 'assetDisplayName', 'locationDisplayName', 'operatorDisplayName'])
    ),
  }),
}

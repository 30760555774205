import { IonItem, IonLabel, IonText } from '@ionic/react'
import { isEmpty } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { getPrivateItem } from 'helpers/localForage'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import { showError } from 'helpers/errors'
import Filter from 'containers/Count/ReviewItems/Filter'

export default createListViewComponent({
  getStorageKey: () => 'inventory.count.reviewItems',
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => (
      <IonItem key={item.id} lines="full" routerLink={`${props.match.url}/${item.id}`}>
        <IonLabel>
          {props.useInventoryBarcode ? item.inventoryBarcode || item.barcode : item.barcode}
          <br />
          <IonText color="medium">
            <small>
              {item.description || PLACEHOLDER}
              <br />
              {item.locationName}, {t('counted:')}{' '}
              {`${(props.scannerSettings.showCount2 ? [item.count, item.count2] : [item.count]).join('/')}`}
            </small>
          </IonText>
        </IonLabel>
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const items = await getPrivateItem(COUNTED_ITEMS_SAVED_KEY, [])

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToReview')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowDelete: (self) => (item) => true,
  allowSearch: (self) => true,
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'quantityCounted', languageKey: 'quantityCounted' },
  ],
})(Filter)

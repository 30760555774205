import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'notifyOnClosure',
  getItemSize: () => 45,
  allowSearch: () => true,
  getIdField: () => 'userName',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.notifyUser || '',
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.notifyUser = each.userName
      draft.item.notifyName = each.fullname
    }),
})()

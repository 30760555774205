import * as React from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonTabBar,
  IonTabButton,
  IonLabel,
} from '@ionic/react'
import useOnlineStatus from '@rehooks/online-status'
import { isNil, isEqual } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getPageTitle } from 'helpers/auth'
import selectors from 'selectors'
import authActions from 'actions/auth'
import { getInnerHeight } from 'helpers/utils'
import { REFRESH_TOKEN_INTERVAL } from 'options/auth'
import { t } from 'helpers/i18n'
import Icon from 'elements/Icon'

const isStandalone = () =>
  window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches

const headerHeight = 44
const tabBarSpace = isStandalone() ? 12 : 0
const tabBarHeight = 51 + tabBarSpace

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--ion-item-border-color);
`

const Inner = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`

const Footer = styled.div`
  margin-bottom: -0.5px;

  ion-button {
    &[expand='full'] {
      height: 51px;
    }

    &[color='transparent'] {
      border-left: none;
    }
  }
`

const Container = styled.div`
  padding-bottom: ${`${tabBarSpace}px`};

  ion-tab-button {
    color: white;

    &.tab-selected {
      color: orange;
    }
  }
`

const TabBar = React.memo(
  (props) => (
    <Container>
      <IonTabBar>
        <IonTabButton tab="welcome" href="/welcome" className="ion-hide" />
        {(props.menuItems ?? []).map((each) => (
          <IonTabButton
            key={each.key}
            tab={each.key}
            href={`/${each.key}`}
            disabled={
              each.disabled ||
              (!props.isOnline && !each.allowOffline && !window.location.pathname.startsWith(`/${each.key}`))
            }
          >
            <Icon type={each.iconName} size="22" />
            <IonLabel>{t(each.languageKey)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </Container>
  ),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
)

function Component({
  title,
  header,
  footer,
  toolbarButton,
  children,
  backButtonHref,
  refreshToken,
  style,
  backButton,
  ...rest
}) {
  const url = useLocation().pathname
  const isOnline = useOnlineStatus()
  const menuItems = useSelector(selectors.auth.menuItems)
  const defaultHref = backButtonHref || url.replace(/\/([^/]*?)$/, '')
  const innerHeight = getInnerHeight()
  const refInterval = React.useRef()

  React.useEffect(() => {
    if (isOnline) {
      refreshToken()
    }

    refInterval.current = window.setInterval(refreshToken, REFRESH_TOKEN_INTERVAL)

    return () => window.clearInterval(refInterval.current)
  }, [])

  const pageTitle = title || getPageTitle(url)

  return (
    <>
      <Helmet>
        <title>{pageTitle} - TRMS</title>
      </Helmet>
      <IonPage style={{ height: innerHeight, ...style }} {...rest}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {backButton || <IonBackButton text="" defaultHref={defaultHref || undefined} />}
            </IonButtons>
            <IonTitle>{pageTitle}</IonTitle>
            {toolbarButton && <IonButtons slot="end">{toolbarButton}</IonButtons>}
          </IonToolbar>
        </IonHeader>
        <IonContent forceOverscroll={false}>
          <Wrapper style={{ height: innerHeight - tabBarHeight - headerHeight }}>
            {!isNil(header) && <Header>{header}</Header>}
            <Inner>{children}</Inner>
            {!isNil(footer) && <Footer>{footer}</Footer>}
          </Wrapper>
        </IonContent>
        <TabBar menuItems={menuItems} isOnline={isOnline} />
      </IonPage>
    </>
  )
}

export default connect(null, { refreshToken: authActions.refreshToken })(Component)

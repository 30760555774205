import { set } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { isReadOnly } from 'helpers/formViews'
import Segmenter from 'elements/Segmenter'

export default createChildListViewComponent({
  getStorageKey: () => 'cycleCounts.formView.details',
  allowSearch: () => true,
  allowDelete: (self) => (item) => !isReadOnly(self),
  getListHeader: ({ props, state, updateState, fetchItems }) => (
    <Segmenter
      value={state.filterDto?.countStatus ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Counted', text: t('counted') },
        { value: 'NotCounted', text: t('notCounted') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.countStatus', value)
        })
      }}
    />
  ),
  filterItems: (self) => (each) => {
    const countStatus = self.state.filterDto?.countStatus ?? 'All'
    const counted = each.count > -1 || each.count2 > -1

    if (countStatus === 'Counted') {
      return counted
    }

    if (countStatus === 'NotCounted') {
      return !counted
    }

    return true
  },
  renderItem:
    ({ props }) =>
    (item) => {
      const original = props.originals.find((one) => one.id === item.id)
      const originalIsChanged = original
        ? original.count !== item.count || original.count2 !== item.count2
        : item.count > -1 || item.count2 > -1

      return (
        <IonItem lines="full" onClick={() => props.onClick(item)} detail>
          <IonLabel>
            {props.useInventoryBarcode ? item.inventoryBarcode || item.barcode : item.barcode}
            <br />
            <small>
              <IonText color="medium">
                {item.description || PLACEHOLDER}
                <br />
                {props.scannerSettings.showInventoryDescription ? (
                  <>
                    {item.inventoryDescription || PLACEHOLDER}
                    <br />
                  </>
                ) : null}
                {t('binLocation:')} {item.binLocation || PLACEHOLDER}
                <br />
                {t('onhand:')}{' '}
                {(props.scannerSettings.showCount2 ? [item.onHand, item.onHand2] : [item.onHand]).join('/')},{' '}
                <IonText color={originalIsChanged ? 'warning' : undefined}>
                  {t('count:')}{' '}
                  {(props.scannerSettings.showCount2
                    ? [
                        item.count === -1 ? PLACEHOLDER : item.count,
                        item.count2 === -1 ? PLACEHOLDER : item.count2,
                      ]
                    : [item.count === -1 ? PLACEHOLDER : item.count]
                  ).join('/')}
                </IonText>
              </IonText>
            </small>
          </IonLabel>
        </IonItem>
      )
    },
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'binLocation', languageKey: 'binLocation' },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'count', languageKey: 'count' },
    { fieldName: 'count2', languageKey: 'count2' },
  ],
  getDefaultSortByField: (self) => (self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode'),
})()

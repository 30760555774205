import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import OrdersListView from 'containers/Orders/ListView'
import OrdersFormView from 'containers/Orders/FormView'
import OrdersFormViewSelectBillTo from 'containers/Orders/FormView/SelectBillTo'
import OrdersFormViewSelectShipTo from 'containers/Orders/FormView/SelectShipTo'
import OrdersAddItems from 'containers/Orders/AddItems'
import OrdersAddItemsSelectLocation from 'containers/Orders/AddItems/SelectLocation'
import OrdersAddItemsSelectOperator from 'containers/Orders/AddItems/SelectOperator'
import OrdersAddItemsSelectJob from 'containers/Orders/AddItems/SelectJob'
import OrdersAddItemsSelectAsset from 'containers/Orders/AddItems/SelectAsset'
import OrdersAddItemsSelectTagA from 'containers/Orders/AddItems/SelectTagA'
import OrdersAddItemsSelectTagB from 'containers/Orders/AddItems/SelectTagB'
import OrdersAddItemsSelectTagC from 'containers/Orders/AddItems/SelectTagC'
import OrdersAddItemsSelectTagD from 'containers/Orders/AddItems/SelectTagD'
import OrdersAddItemsSelectTagE from 'containers/Orders/AddItems/SelectTagE'
import OrdersAddItemsAddFromList from 'containers/Orders/AddItems/AddFromList'
import OrdersAddItemsAddFromListSelectList from 'containers/Orders/AddItems/AddFromList/SelectList'
import OrdersAddItemsSearchProducts from 'containers/Orders/AddItems/SearchProducts'
import OrdersAddItemsPunchout from 'containers/Orders/AddItems/Punchout'
import OrderItemsFormView from 'containers/Orders/Items/FormView'
import OrderItemsFormViewSelectAsset from 'containers/Orders/Items/FormView/SelectAsset'
import OrderItemsFormViewSelectJob from 'containers/Orders/Items/FormView/SelectJob'
import OrderItemsFormViewSelectLocation from 'containers/Orders/Items/FormView/SelectLocation'
import OrderItemsFormViewSelectOperator from 'containers/Orders/Items/FormView/SelectOperator'
import OrderItemsFormViewSelectSupplier from 'containers/Orders/Items/FormView/SelectSupplier'
import OrderItemsFormViewSelectTagA from 'containers/Orders/Items/FormView/SelectTagA'
import OrderItemsFormViewSelectTagB from 'containers/Orders/Items/FormView/SelectTagB'
import OrderItemsFormViewSelectTagC from 'containers/Orders/Items/FormView/SelectTagC'
import OrderItemsFormViewSelectTagD from 'containers/Orders/Items/FormView/SelectTagD'
import OrderItemsFormViewSelectTagE from 'containers/Orders/Items/FormView/SelectTagE'
import OrderSuppliersFormView from 'containers/Orders/Suppliers/FormView'
import RequisitionsListView from 'containers/Requisitions/ListView'
import RequisitionsFormView from 'containers/Requisitions/FormView'
import RequisitionsFormViewSelectShipTo from 'containers/Requisitions/FormView/SelectShipTo'
import RequisitionsFormViewSelectSendTo from 'containers/Requisitions/FormView/SelectSendTo'
import RequisitionsAddItems from 'containers/Requisitions/AddItems'
import RequisitionsAddItemsSelectLocation from 'containers/Requisitions/AddItems/SelectLocation'
import RequisitionsAddItemsSelectOperator from 'containers/Requisitions/AddItems/SelectOperator'
import RequisitionsAddItemsSelectJob from 'containers/Requisitions/AddItems/SelectJob'
import RequisitionsAddItemsSelectAsset from 'containers/Requisitions/AddItems/SelectAsset'
import RequisitionsAddItemsSelectTagA from 'containers/Requisitions/AddItems/SelectTagA'
import RequisitionsAddItemsSelectTagB from 'containers/Requisitions/AddItems/SelectTagB'
import RequisitionsAddItemsSelectTagC from 'containers/Requisitions/AddItems/SelectTagC'
import RequisitionsAddItemsSelectTagD from 'containers/Requisitions/AddItems/SelectTagD'
import RequisitionsAddItemsSelectTagE from 'containers/Requisitions/AddItems/SelectTagE'
import RequisitionsAddItemsAddFromList from 'containers/Requisitions/AddItems/AddFromList'
import RequisitionsAddItemsAddFromListSelectList from 'containers/Requisitions/AddItems/AddFromList/SelectList'
import RequisitionsAddItemsSearchProducts from 'containers/Requisitions/AddItems/SearchProducts'
import RequisitionsAddItemsPunchout from 'containers/Requisitions/AddItems/Punchout'
import RequisitionItemsFormView from 'containers/Requisitions/Items/FormView'
import RequisitionItemsFormViewSelectAsset from 'containers/Requisitions/Items/FormView/SelectAsset'
import RequisitionItemsFormViewSelectJob from 'containers/Requisitions/Items/FormView/SelectJob'
import RequisitionItemsFormViewSelectLocation from 'containers/Requisitions/Items/FormView/SelectLocation'
import RequisitionItemsFormViewSelectOperator from 'containers/Requisitions/Items/FormView/SelectOperator'
import RequisitionItemsFormViewSelectSupplier from 'containers/Requisitions/Items/FormView/SelectSupplier'
import RequisitionItemsFormViewSelectTagA from 'containers/Requisitions/Items/FormView/SelectTagA'
import RequisitionItemsFormViewSelectTagB from 'containers/Requisitions/Items/FormView/SelectTagB'
import RequisitionItemsFormViewSelectTagC from 'containers/Requisitions/Items/FormView/SelectTagC'
import RequisitionItemsFormViewSelectTagD from 'containers/Requisitions/Items/FormView/SelectTagD'
import RequisitionItemsFormViewSelectTagE from 'containers/Requisitions/Items/FormView/SelectTagE'
import RequisitionItemsFormViewSelectUnitOfMeasure from 'containers/Requisitions/Items/FormView/SelectUnitOfMeasure'
import RfqsListView from 'containers/Rfqs/ListView'
import RfqsFormView from 'containers/Rfqs/FormView'

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/orders/orders': OrdersListView,
    '/orders/orders/:itemId(\\d+)': OrdersFormView,
    '/orders/orders/:itemId(\\d+)/selectBillTo': OrdersFormViewSelectBillTo,
    '/orders/orders/:itemId(\\d+)/selectShipTo': OrdersFormViewSelectShipTo,
    '/orders/orders/:parentId(\\d+)/addItems': OrdersAddItems,
    '/orders/orders/:parentId(\\d+)/addItems/selectLocation': OrdersAddItemsSelectLocation,
    '/orders/orders/:parentId(\\d+)/addItems/selectOperator': OrdersAddItemsSelectOperator,
    '/orders/orders/:parentId(\\d+)/addItems/selectJob': OrdersAddItemsSelectJob,
    '/orders/orders/:parentId(\\d+)/addItems/selectAsset': OrdersAddItemsSelectAsset,
    '/orders/orders/:parentId(\\d+)/addItems/selectTagA': OrdersAddItemsSelectTagA,
    '/orders/orders/:parentId(\\d+)/addItems/selectTagB': OrdersAddItemsSelectTagB,
    '/orders/orders/:parentId(\\d+)/addItems/selectTagC': OrdersAddItemsSelectTagC,
    '/orders/orders/:parentId(\\d+)/addItems/selectTagD': OrdersAddItemsSelectTagD,
    '/orders/orders/:parentId(\\d+)/addItems/selectTagE': OrdersAddItemsSelectTagE,
    '/orders/orders/:parentId(\\d+)/addItems/addFromList': OrdersAddItemsAddFromList,
    '/orders/orders/:parentId(\\d+)/addItems/addFromList/selectList': OrdersAddItemsAddFromListSelectList,
    '/orders/orders/:parentId(\\d+)/addItems/searchProducts': OrdersAddItemsSearchProducts,
    '/orders/orders/:parentId(\\d+)/addItems/punchout': OrdersAddItemsPunchout,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock': OrderItemsFormView,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectAsset': OrderItemsFormViewSelectAsset,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectJob': OrderItemsFormViewSelectJob,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectLocation': OrderItemsFormViewSelectLocation,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectOperator': OrderItemsFormViewSelectOperator,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectSupplier': OrderItemsFormViewSelectSupplier,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectTagA': OrderItemsFormViewSelectTagA,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectTagB': OrderItemsFormViewSelectTagB,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectTagC': OrderItemsFormViewSelectTagC,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectTagD': OrderItemsFormViewSelectTagD,
    '/orders/orders/:parentId(\\d+)/addItems/addNonStock/selectTagE': OrderItemsFormViewSelectTagE,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)': OrderItemsFormView,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectAsset':
      OrderItemsFormViewSelectAsset,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectJob': OrderItemsFormViewSelectJob,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectLocation':
      OrderItemsFormViewSelectLocation,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectOperator':
      OrderItemsFormViewSelectOperator,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectSupplier':
      OrderItemsFormViewSelectSupplier,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectTagA': OrderItemsFormViewSelectTagA,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectTagB': OrderItemsFormViewSelectTagB,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectTagC': OrderItemsFormViewSelectTagC,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectTagD': OrderItemsFormViewSelectTagD,
    '/orders/orders/:parentId(\\d+)/orderItems/:itemId(-\\d+|\\d+)/selectTagE': OrderItemsFormViewSelectTagE,
    '/orders/orders/:parentId(\\d+)/orderSuppliers/:itemId(-\\d+|\\d+)': OrderSuppliersFormView,
    '/orders/requisitions': RequisitionsListView,
    '/orders/requisitions/:itemId(\\d+)': RequisitionsFormView,
    '/orders/requisitions/:itemId(\\d+)/selectShipTo': RequisitionsFormViewSelectShipTo,
    '/orders/requisitions/:itemId(\\d+)/selectSendTo': RequisitionsFormViewSelectSendTo,
    '/orders/requisitions/:parentId(\\d+)/addItems': RequisitionsAddItems,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectLocation': RequisitionsAddItemsSelectLocation,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectOperator': RequisitionsAddItemsSelectOperator,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectJob': RequisitionsAddItemsSelectJob,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectAsset': RequisitionsAddItemsSelectAsset,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectTagA': RequisitionsAddItemsSelectTagA,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectTagB': RequisitionsAddItemsSelectTagB,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectTagC': RequisitionsAddItemsSelectTagC,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectTagD': RequisitionsAddItemsSelectTagD,
    '/orders/requisitions/:parentId(\\d+)/addItems/selectTagE': RequisitionsAddItemsSelectTagE,
    '/orders/requisitions/:parentId(\\d+)/addItems/addFromList': RequisitionsAddItemsAddFromList,
    '/orders/requisitions/:parentId(\\d+)/addItems/addFromList/selectList':
      RequisitionsAddItemsAddFromListSelectList,
    '/orders/requisitions/:parentId(\\d+)/addItems/searchProducts': RequisitionsAddItemsSearchProducts,
    '/orders/requisitions/:parentId(\\d+)/addItems/punchout': RequisitionsAddItemsPunchout,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock': RequisitionItemsFormView,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectAsset':
      RequisitionItemsFormViewSelectAsset,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectJob': RequisitionItemsFormViewSelectJob,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectLocation':
      RequisitionItemsFormViewSelectLocation,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectOperator':
      RequisitionItemsFormViewSelectOperator,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectSupplier':
      RequisitionItemsFormViewSelectSupplier,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectTagA':
      RequisitionItemsFormViewSelectTagA,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectTagB':
      RequisitionItemsFormViewSelectTagB,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectTagC':
      RequisitionItemsFormViewSelectTagC,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectTagD':
      RequisitionItemsFormViewSelectTagD,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectTagE':
      RequisitionItemsFormViewSelectTagE,
    '/orders/requisitions/:parentId(\\d+)/addItems/addNonStock/selectUnitOfMeasure':
      RequisitionItemsFormViewSelectUnitOfMeasure,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)': RequisitionItemsFormView,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectAsset':
      RequisitionItemsFormViewSelectAsset,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectJob':
      RequisitionItemsFormViewSelectJob,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectLocation':
      RequisitionItemsFormViewSelectLocation,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectOperator':
      RequisitionItemsFormViewSelectOperator,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectSupplier':
      RequisitionItemsFormViewSelectSupplier,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectTagA':
      RequisitionItemsFormViewSelectTagA,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectTagB':
      RequisitionItemsFormViewSelectTagB,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectTagC':
      RequisitionItemsFormViewSelectTagC,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectTagD':
      RequisitionItemsFormViewSelectTagD,
    '/orders/requisitions/:parentId(\\d+)/requisitionItems/:itemId(-\\d+|\\d+)/selectTagE':
      RequisitionItemsFormViewSelectTagE,
    '/orders/rfqs': RfqsListView,
    '/orders/rfqs/:itemId(\\d+)': RfqsFormView,
  }),
})

export default createTabViewContainer()(Component)

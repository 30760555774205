import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { MONTHS } from 'options/tasks'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/Tasks/FormView/SelectRecurOnMonthsSingle'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(Object.keys(MONTHS).map((each, index) => ({ id: index + 1, displayName: t(each) }))),
  }),
})(Component)

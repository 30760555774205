import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, toString as str, cloneDeep, trimStart, isEmpty } from 'lodash'
import { PLACEHOLDER, getTagDisplayNameField } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { getSessionItem } from 'helpers/sessionStorage'
import { formatDateTime } from 'helpers/dateTime'
import assetStatusOptions from 'options/assets/statusOptions'
import itemConditions from 'options/assets/itemConditions'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  'A',
  'B',
  'C',
  'D',
  'E',
]

export default function (props) {
  const { parentStorageKey = 'assets.formView' } = props

  const [state, updateState] = useImmer({ tabsActiveKey: 'maintenance' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchItem() {
    try {
      const parentState = getSessionItem(parentStorageKey)
      const item = cloneDeep(
        parentState.historyItems.find((one) => str(one.id) === props.match.params.itemId)
      )

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      updateState((draft) => {
        draft.item = item
        draft.parentState = parentState
      })
    } catch (error) {
      showError({ error })
      props.history.goBack()
    }
  }

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledAssets`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const backButtonHref = `/assets/trackAssets/${props.match.params.parentId}`

  if (isNil(state.item)) {
    return (
      <Page title={t('history')} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)
  const pageTitle = `${t('history')} - ${state.item.assetBarcode}`

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
          {t('close')}
        </IonButton>
      }
      backButtonHref={backButtonHref}
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="maintenance" tab={t('maintenance')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('date')}</IonLabel>
            <IonInput
              value={formatDateTime(state.item.actionDate, { showTime: true })}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('action')}</IonLabel>
            <IonInput
              value={
                assetStatusOptions[state.item.statusChange]
                  ? t(assetStatusOptions[state.item.statusChange])
                  : undefined
              }
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('itemCondition')}</IonLabel>
            <IonInput
              value={
                itemConditions[state.item.itemCondition]
                  ? t(itemConditions[state.item.itemCondition])
                  : undefined
              }
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('lifeRemaining')}</IonLabel>
            <IonInput value={`${state.item.lifeRemaining}%`} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('serviceHours')}</IonLabel>
            <IonInput value={state.item.servicehours} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('mileage')}</IonLabel>
            <IonInput value={state.item.mileage} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('comments')}</IonLabel>
            <IonTextarea value={state.item.comment} placeholder={PLACEHOLDER} rows={3} disabled autoGrow />
          </IonItem>
        </Tabs.TabPane>
        {!isEmpty(enabledTagTypes) && (
          <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
            {enabledTagTypes.map((tagType) => (
              <IonItem key={tagType} lines="full">
                <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
                <IonInput value={getTagDisplayName(tagType)} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            ))}
          </Tabs.TabPane>
        )}
      </Tabs>
    </Page>
  )
}

import { snakeCase } from 'lodash'
import { createGet } from 'helpers/api'
import { createActions } from 'helpers/actions'
import { setSharedItemNoScope, getSharedItemNoScope } from 'helpers/localForage'
import entityNames from 'options/entityNames'

const entityName = entityNames.tenants
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  getTenantInfo: (params) => (dispatch) => {
    const subdomain = params.subdomain || 'undefined'
    const getStorageKey = () => `tenantInfo.${subdomain}`

    return dispatch({
      type: `${ENTITY_NAME}_INFO_GET`,
      payload: createGet(entityName, { action: `subdomain/${encodeURIComponent(subdomain)}` })(),
    })
      .then(async (response) => {
        await setSharedItemNoScope(getStorageKey(), response.value.data)

        return response
      })
      .catch(async (error) => {
        if (!error.response) {
          const data = await getSharedItemNoScope(getStorageKey())

          if (data) {
            return { value: { data } }
          }
        }

        throw error
      })
  },
}

import { IonItem, IonLabel, IonButton, IonText } from '@ionic/react'
import { isEmpty, isNil } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER, asyncSleep } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError } from 'helpers/errors'
import Icon from 'elements/Icon'

const CHECK_STATUS_INTERVAL = 2500

let stopChecking = true
let punchoutWindow = null

export default createListViewComponent({
  getStorageKey: () => 'requisitions.addItems.punchout',
  pageTitle: (self) => t('selectSupplierCatalog'),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel className="ion-text-wrap">
        {item.name}
        {item.punchoutDescription ? (
          <>
            <br />
            <IonText color="medium">
              <small>{item.punchoutDescription || PLACEHOLDER}</small>
            </IonText>
          </>
        ) : null}
      </IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  allowInfiniteLoader: () => false,
  allowSearch: (self) => true,
  getFooter: (self) => {
    const { props, state, setState, updateState } = self
    const parentRecord = getSessionItem('requisitions.formView', {}).item

    async function checkPunchoutStatus(punchoutRequestId) {
      if (punchoutWindow?.closed) {
        stopChecking = true
        setState('alertIsOpen', false)
      }

      if (!stopChecking) {
        try {
          const response = await props.getPunchoutRequest(punchoutRequestId)

          if (response.value.data.status === 'Pending') {
            window.setTimeout(() => checkPunchoutStatus(punchoutRequestId), CHECK_STATUS_INTERVAL)
          } else {
            try {
              if (punchoutWindow) {
                punchoutWindow.close()
              }

              setState('alertIsOpen', false)

              await asyncSleep()

              setState('loadingIsOpen', true)

              await asyncSleep()

              removeSessionItem('requisitions.formView')

              window.location.href = `/orders/requisitions/${parentRecord.id}`
            } catch (error) {
              console.warn(error)
            }
          }
        } catch (error) {
          showError({ error })
        }
      }
    }

    return (
      <IonButton
        color="secondary"
        expand="full"
        onClick={async () => {
          try {
            setState('loadingIsOpen', true)

            const selectedItem = state.items.find((one) => state.selectedRowKeys.includes(one.id))
            const values = getSessionItem('requisitions.addItems', {}).item

            const punchoutRequest = await props
              .createPunchoutRequest({
                ...values,
                punchoutRequestType: 'Requisition',
                requestId: parentRecord.id,
                supplierId: selectedItem.id,
              })
              .then((r) => r.value.data)

            if (isNil(punchoutRequest)) {
              throw new Error('Save response is invalid')
            }

            setState('loadingIsOpen', false)

            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertHeader = t('accessingSupplierCatalogTitle')
              draft.alertMessage = t('accessingSupplierCatalogDescription')
              draft.alertButtons = [
                {
                  text: t('cancel'),
                  handler: () => {
                    stopChecking = true

                    if (punchoutWindow) {
                      punchoutWindow.close()
                    }
                  },
                },
                {
                  text: t('open'),
                  handler: () => {
                    stopChecking = false
                    punchoutWindow = window.open(punchoutRequest.supplierUrl)
                    return false
                  },
                },
              ]
            })

            punchoutWindow = window.open(punchoutRequest.supplierUrl)

            stopChecking = false

            checkPunchoutStatus(punchoutRequest.id)
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        }}
        disabled={isEmpty(state.selectedRowKeys) || !self.isOnline}
      >
        {t('select')}
      </IonButton>
    )
  },
  getSortByFields: (self) => [{ fieldName: 'name', languageKey: 'name' }],
  allowOffline: (self) => false,
})()

import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import usagePurchaseOptions from 'options/orders/usagePurchaseOptions'
import ModalSelect from 'elements/ModalSelect'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const response = await props.getSuppliers()

      updateState((draft) => {
        draft.suppliers = get(response, 'value.data.items', [])
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const supplierId = filterDto?.supplierId ?? null
  const usagePurchase = filterDto?.usagePurchase ?? 'All'
  const parentSupplierIds = props.parentRecord.orderItems.map((each) => each.supplierId)

  if (state.activeView === 'supplierId') {
    return (
      <ModalSelect
        title={t('supplier')}
        options={[
          { value: null, text: t('all') },
          ...(state.suppliers ?? [])
            .filter((each) => parentSupplierIds.includes(each.id))
            .map((each) => ({
              value: each.id,
              text: each.displayName,
            })),
        ]}
        value={supplierId}
        onChange={(value) => setFilter('supplierId', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'usagePurchase') {
    return (
      <ModalSelect
        title={t('usagePurchase')}
        options={Object.entries(usagePurchaseOptions).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={usagePurchase}
        onChange={(value) => setFilter('usagePurchase', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'supplierId')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('supplier')}</small>
            </IonText>
            <br />
            {supplierId
              ? (state.suppliers ?? []).find((one) => one.id === supplierId)?.displayName ?? supplierId
              : t('all')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'usagePurchase')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('usagePurchase')}</small>
            </IonText>
            <br />
            {t(usagePurchaseOptions[usagePurchase])}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  )
}

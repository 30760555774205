import { IonRow, IonCol, IonItem, IonLabel, IonText, IonInput } from '@ionic/react'
import { filterKeys, tryParseInt } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import dateRangeTypes from 'options/dateRangeTypes'
import DatePicker from 'elements/DatePicker'

export default function ({
  dateRangeType,
  lastNValue,
  customStartDate,
  customEndDate,
  setState,
  setFilter,
  isFieldAll,
  extraDateRangeTypes = {},
}) {
  return (
    <>
      {dateRangeType.startsWith('LastN') || dateRangeType.startsWith('NextN') ? (
        <IonRow>
          <IonCol>
            <IonItem
              lines="full"
              className="tofino-stacked-item"
              onClick={() => setState('activeView', 'dateRangeType')}
              disabled={isFieldAll}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('dateRangeType')}</small>
                </IonText>
                <br />
                {t({ ...dateRangeTypes, ...extraDateRangeTypes }[dateRangeType])}
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem lines="full" disabled={isFieldAll}>
              <IonLabel position="stacked">
                {t(dateRangeType.replace('LastN', '').replace('NextN', ''))}
              </IonLabel>
              <IonInput
                value={lastNValue}
                type="number"
                inputmode="number"
                inputMode="number"
                min={1}
                onKeyPress={filterKeys(/\D/)}
                onIonInput={(e) => setFilter('dateRange.lastNValue', tryParseInt(e.target.value, 1))}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      ) : (
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'dateRangeType')}
          disabled={isFieldAll}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('dateRangeType')}</small>
            </IonText>
            <br />
            {t({ ...dateRangeTypes, ...extraDateRangeTypes }[dateRangeType])}
          </IonLabel>
        </IonItem>
      )}
      {dateRangeType === 'Custom' ? (
        <IonRow>
          <IonCol>
            <DatePicker
              label={t('startDate')}
              value={isFieldAll ? null : customStartDate}
              onChange={(value) =>
                setFilter(
                  'dateRange.customStartDate',
                  new Date(new Date(value).setHours(0, 0, 0, 0)).toJSON()
                )
              }
              disabled={isFieldAll}
            />
          </IonCol>
          <IonCol>
            <DatePicker
              label={t('endDate')}
              value={isFieldAll ? null : customEndDate}
              onChange={(value) =>
                setFilter(
                  'dateRange.customEndDate',
                  new Date(new Date(value).setHours(23, 59, 59, 0)).toJSON()
                )
              }
              disabled={isFieldAll}
            />
          </IonCol>
        </IonRow>
      ) : (
        <IonRow>
          <IonCol>
            <DatePicker
              label={t('startDate')}
              value={isFieldAll ? null : getStartDate(dateRangeType, lastNValue).toISOString()}
              disabled
            />
          </IonCol>
          <IonCol>
            <DatePicker
              label={t('endDate')}
              value={isFieldAll ? null : getEndDate(dateRangeType, lastNValue).toISOString()}
              disabled
            />
          </IonCol>
        </IonRow>
      )}
    </>
  )
}

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'location',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.locationId,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.locationId = each.id
      draft.item.locationName = each.name || '*'
      draft.item.locationNumber = each.number
      draft.item.locationBarcode = each.barcode
      draft.item.locationDisplayName = each.displayName
      draft.item.barcode = ''
      draft.product = null
      draft.quantity = 1
    }),
})()

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonSpinner,
  IonTextarea,
  IonButton,
  IonToggle,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, trim, isEmpty, cloneDeep, toString as str } from 'lodash'
import { tryParseFloat, PLACEHOLDER } from 'helpers/utils'
import { allowPricing } from 'helpers/auth'
import { t } from 'helpers/i18n'
import { updateSessionItem, getSessionItem } from 'helpers/sessionStorage'
import { showError, showValidationErrors } from 'helpers/errors'
import { formatDateTime } from 'helpers/dateTime'
import Page from 'elements/Page'
import DatePicker from 'elements/DatePicker'

// export const getStorageKey = () => 'orders.formView.orderSuppliers.formView';
const parentStorageKey = 'orders.formView'

export default function (props) {
  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  function fetchItem() {
    try {
      const parentState = getSessionItem(parentStorageKey)
      const item =
        props.match.params.itemId === '0'
          ? {
              id: Date.now() * -1,
              orderId: parentState.item.id,
            }
          : parentState.orderSupplierItems.find((one) => str(one.id) === props.match.params.itemId)

      updateState((draft) => {
        draft.item = cloneDeep(item)
        draft.parentState = parentState
      })
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.orderSupplierItems.findIndex(
              (one) => str(one.id) === props.match.params.itemId
            )

            if (index > -1) {
              draft.orderSupplierItems.splice(index, 1, values)
            } else {
              draft.orderSupplierItems.unshift(values)
            }

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const pageTitle = t('supplierInformation')
  const backButtonHref = `/order/orders/${props.match.params.parentId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
          {t('save')}
        </IonButton>
      }
      backButtonHref={backButtonHref}
    >
      <IonItem lines="full">
        <IonLabel position="stacked">{t('supplier')}</IonLabel>
        <IonInput value={state.item.supplierName} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('poNumber')}</IonLabel>
        <IonInput
          value={state.item.purchaseOrderNumber}
          onIonInput={(e) => setItemValue('purchaseOrderNumber', e.target.value)}
          placeholder={PLACEHOLDER}
        />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('specialInstructions')}</IonLabel>
        <IonTextarea
          value={state.item.shippingInstructions}
          onIonInput={(e) => setItemValue('shippingInstructions', e.target.value)}
          placeholder={PLACEHOLDER}
          rows={3}
          disabled={props.tenantGroupIsCardinal}
          autoGrow
        />
      </IonItem>
      <DatePicker
        label={t('dueDate')}
        value={state.item.dueDate}
        onChange={(value) => setItemValue('dueDate', value)}
        disabled={props.tenantGroupIsCardinal}
      />
      {allowPricing() && (
        <>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('adjustments')} ($)</IonLabel>
            <IonInput
              value={state.item.adjustments}
              onIonInput={(e) => setItemValue('adjustments', tryParseFloat(e.target.value, 0.0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              disabled={props.tenantGroupIsCardinal}
              clearOnEdit
            />
          </IonItem>

          <IonItem lines="full">
            <IonLabel position="stacked">{t('freight')} ($)</IonLabel>
            <IonInput
              value={state.item.freight}
              onIonInput={(e) => setItemValue('freight', tryParseFloat(e.target.value, 0.0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              disabled={props.tenantGroupIsCardinal}
              clearOnEdit
            />
          </IonItem>
        </>
      )}
      <IonItem lines="full">
        <IonLabel>{t('holdOrderForThisSupplier')}</IonLabel>
        <IonToggle checked={state.item.hold} onIonChange={(e) => setItemValue('hold', e.detail.checked)} />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('confirmedBySupplier')}</IonLabel>
        <IonInput
          value={
            !state.item.isAcknowledged
              ? t('no')
              : [t('yes'), formatDateTime(state.item.ackDate, { showTime: true })]
                  .map(trim)
                  .filter((each) => !isEmpty(each))
                  .join(' - ')
          }
          placeholder={PLACEHOLDER}
          disabled
        />
      </IonItem>
    </Page>
  )
}

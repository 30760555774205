import { set } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { removeSessionItem } from 'helpers/sessionStorage'
import Segmenter from 'elements/Segmenter'
import Filter from 'containers/CycleCounts/ListView/Filter'

export default createListViewComponent({
  getStorageKey: () => 'cycleCounts.listView',
  getInsignificantFilterDtoFields: (self) => ['dateRange', 'dateRangeField', 'status'],
  getListHeader: ({ props, state, updateState, fetchItems, firstPageIndex, isOnline }) => (
    <Segmenter
      value={state.filterDto?.status ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Open', text: t('open') },
        { value: 'Committed', text: t('committed') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.status', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
      disabled={!isOnline}
    />
  ),
  getPopoverContent: (self) => (
    <IonItem
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
        removeSessionItem('cycleCounts.create')
        self.props.history.push(`${self.props.match.url}/create`)
      }}
    >
      <IonLabel>{t('createCycleCount')}</IonLabel>
    </IonItem>
  ),
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={() => {
        removeSessionItem('cycleCounts.create')
        self.props.history.push(`${self.props.match.url}/create`)
      }}
      disabled={!self.isOnline}
    >
      {t('createCycleCount')}
    </IonButton>
  ),
  renderItem: (self) => (item) => (
    <IonItem key={item.id} lines="full" routerLink={`${self.props.match.url}/${item.id}`}>
      <IonLabel>
        {item.id} - {item.locationName}
        <br />
        <IonText color="medium">
          <small>
            {item.typeDescription}, {item.status}
            <br />
            {t('itemsOnCount:')} {item.itemsOnCount}
            <br />
            {t('created:')} {formatUserTime(item.createdDate, item.createdBy) || PLACEHOLDER}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getItemSize: () => 93,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowOffline: (self) => false,
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'typeDescription', languageKey: 'type' },
    { fieldName: 'statusDescription', languageKey: 'status' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'createdDate', languageKey: 'createdDate' },
  ],
})(Filter)

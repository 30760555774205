import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/jobRequests'
import documentActions from 'actions/documents'
import Component from 'components/JobRequests/FormView/CreateJob'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

export default connect(mapStateToProps, {
  createJob: actions.createJob,
  copyAllObjectDocuments: documentActions.copyAllObjectDocuments,
})(Component)

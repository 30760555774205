import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { isEmpty, cloneDeep } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER, tryParseInt, message } from 'helpers/utils'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { getStorageItem } from 'helpers/localStorage'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { showClientNotifications, showError } from 'helpers/errors'
import Icon from 'elements/Icon'

export const getStorageKey = () => 'requisitions.addItems.addFromList'

export default createListViewComponent({
  getStorageKey,
  pageTitle: (self) => t('addFromList'),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys.splice(index, 1)
          } else {
            draft.selectedRowKeys.unshift(item.id)
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description || PLACEHOLDER}
            <br />
            {t('item#:')} {item.itemNumber}, {t('pkg:')} {item.packageSizeUom}, {t('price:')}{' '}
            {toLocaleCurrency(item.price)}
            <br />
            {t('supplier:')} {item.supplierName || t('notAssigned')}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Check checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  allowInfiniteLoader: (self) => false,
  allowSearch: (self) => true,
  getInsignificantFilterDtoFields: (self) => ['listId', 'listName'],
  getListHeader: (self) => {
    const listName = getStorageItem(getStorageKey(), {}).filterDto?.listName
    return (
      <IonItem
        lines="none"
        className="tofino-stacked-item"
        style={{ borderTop: '1px solid var(--ion-border-color)' }}
        onClick={() => {
          self.setState('selectedRowKeys', [])
          self.props.history.push(`${self.props.match.url}/selectList`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('list')}</small>
          </IonText>
          <br />
          <IonText color={isEmpty(listName) ? 'medium' : undefined}>{listName || t('select')}</IonText>
        </IonLabel>
      </IonItem>
    )
  },
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={async () => {
        const selectedItems = self.state.items.filter((one) => self.state.selectedRowKeys.includes(one.id))
        const values = getSessionItem('requisitions.addItems', {}).item

        const requisitionItems = selectedItems.map((each) => ({
          ...values,
          quantity: values.quantity || 1,
          barcode: each.barcode,
          description: each.description,
          assetName: values.assetName || `*${values.assetDisplayName}`,
          jobName: values.jobName || `*${values.jobDisplayName}`,
          locationName: values.locationName || `*${values.locationDisplayName}`,
          operatorName: values.operatorName || `*${values.operatorDisplayName}`,
        }))

        try {
          self.setState('loadingIsOpen', true)

          const response = await self.props.populateRequisitionItems(requisitionItems)

          self.setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const populatedItems = cloneDeep(response.value.data.items)

          const seed = Date.now() * -1

          populatedItems.forEach((each, index) => {
            each.id = each.requisitionItemId = values.id || seed - index
            each.requisitionId = tryParseInt(self.props.match.params.parentId, 0)
            each.populated = true
            each.timestamp = new Date().toJSON()
          })

          updateSessionItem('requisitions.formView', {}, (draft) => {
            draft.requisitionItems.unshift(...populatedItems)
            draft.tabsActiveKey = 'items'
          })

          self.setState('selectedRowKeys', [])

          message.success(`${populatedItems.length} ${t('items')} ${t('addToRequisitionSuccess')}`)
        } catch (error) {
          self.setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
    >
      {t('addToRequisition')}{' '}
      {self.state.selectedRowKeys.length ? `(${self.state.selectedRowKeys.length})` : ''}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'barcode' },
    { fieldName: 'itemNumber', languageKey: 'itemNumber' },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'price', languageKey: 'price' },
    { fieldName: 'supplierName', languageKey: 'supplier' },
  ],
  allowOffline: (self) => false,
})()

import { createFormViewContainer } from 'factories/FormView'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/cycleCounts'
import cycleCountDetailActions from 'actions/cycleCounts/cycleCountDetails'
import cycleCountDifferenceReportActions from 'actions/reports/inventoryCycleCountDifferenceReport'
import Component from 'components/CycleCounts/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    commitCycleCount: (params) => dispatch(actions.commitCycleCount(params)),
    getCycleCountDifferenceReportItems: (params, options) =>
      dispatch(cycleCountDifferenceReportActions.getItems(params, options)),
    emailCycleCountDifferenceReportItems: (params) =>
      dispatch(cycleCountDifferenceReportActions.emailReport(params)),
    ...createChildItemsDispatchToProps(dispatch, 'Detail', cycleCountDetailActions),
  }),
})(Component)

import { createChildFormViewContainer } from 'factories/ChildFormView'
import actions from 'actions/assets/assetHistory'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import Component from 'components/Assets/History/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getJob: (jobId) => dispatch(jobActions.getItem(jobId)),
    getOperator: (operatorId) => dispatch(operatorActions.getItem(operatorId)),
  }),
})(Component)

import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { toString as str, isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { getUserPermission } from 'helpers/auth'
import Icon from 'elements/Icon'
import Filter from 'containers/Orders/ListView/Filter'

export default createListViewComponent({
  getStorageKey: () => 'orders.listView',
  renderItem: (self) => (item) => (
    <IonItem key={item.id} lines="full" routerLink={`${self.props.match.url}/${item.id}`}>
      <IonLabel>
        {item.isExpedited && <Icon type="priority_high" color="danger" size="16" className="ml-neg-5" />}
        {[item.id, item.locationNames]
          .map(str)
          .filter((each) => !isEmpty(each))
          .join(' - ')}
        <br />
        <IonText color="medium">
          <small>
            {item.statusName || PLACEHOLDER}
            <br />
            {t('created:')} {formatUserTime(item.createDate, item.userName)}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getItemSize: () => 75,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowDelete: (self) => (item) => item.status === 'New',
  getDeleteConfirmMessage: (self) => `${t('confirmDeleteOrder')} ${t('confirmDeleteOrderDescription')}`,
  allowOffline: (self) => false,
  getPopoverContent: (self) =>
    getUserPermission('Purchase') === 'Yes' ? (
      <IonItem
        lines="full"
        onClick={() => {
          self.updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
          self.props.history.push(`${self.props.match.url}/0`)
        }}
      >
        <IonLabel>{t('createOrder')}</IonLabel>
      </IonItem>
    ) : null,
  getFooter: (self) =>
    getUserPermission('Purchase') === 'Yes' ? (
      <IonButton
        color="secondary"
        expand="full"
        onClick={() => {
          self.props.history.push(`${self.props.match.url}/0`)
        }}
        disabled={!self.isOnline}
      >
        {t('createOrder')}
      </IonButton>
    ) : null,
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'statusName', languageKey: 'status' },
    { fieldName: 'userName', languageKey: 'createdBy' },
    { fieldName: 'createDate', languageKey: 'createdDate' },
  ],
})(Filter)

import { createListViewContainer } from 'factories/ListView'
import { COUNTED_ITEMS_UPLOAD_RESULTS_KEY } from 'options/inventory/count'
import { getPrivateItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'
import Component from 'components/Count/UploadResults'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: () => getPrivateItem(COUNTED_ITEMS_UPLOAD_RESULTS_KEY, []).then(wrapItems),
  }),
})(Component)

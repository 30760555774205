import { split, isEmpty } from 'lodash'
import { IonAlert } from '@ionic/react'
import { t } from 'helpers/i18n'

export default function ({ isOpen, onCancel, onSubmit = () => {} }) {
  return (
    <IonAlert
      cssClass="tofino-required-alert"
      backdropDismiss={false}
      isOpen={isOpen}
      header={t('recipients')}
      inputs={[{ name: 'input', type: 'textarea', attributes: { rows: 4 } }]}
      buttons={[
        { text: t('cancel'), role: 'cancel' },
        {
          text: t('send'),
          handler: ({ input }) => {
            onSubmit(split(input, /[\s,;]/).filter((each) => !isEmpty(each)))
            return false
          },
        },
      ]}
      onDidDismiss={onCancel}
    />
  )
}

import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
  IonToggle,
  IonRow,
  IonCol,
  IonInput,
} from '@ionic/react'
import { PLACEHOLDER, tryParseInt, filterLocationsByGroupIds, FILTER_SCROLL_TOP } from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray, getMultiSelectDisplayNameFromObject } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import dateRangeTypes from 'options/dateRangeTypes'
import dateRangeFields from 'options/assets/dateRangeFields'
import assetStatusOptions from 'options/assets/statusOptions'
import ModalSelect from 'elements/ModalSelect'
import DateRangePicker from 'elements/DateRangePicker'

const periodOptions = {
  Days: 'days',
  Months: 'months',
  Years: 'years',
}

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const contentRef = React.useCallback((node) => {
    if (node !== null) {
      const scrollTop = tryParseInt(getSessionItem(FILTER_SCROLL_TOP))

      if (scrollTop) {
        node.scrollToPoint(0, scrollTop, 0)
        removeSessionItem(FILTER_SCROLL_TOP)
      }
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.getLocationGroups(),
        props.getLocations(),
        props.getAssetCategories(),
        props.getUsers(),
        props.getOperators(),
        props.getJobs(),
      ])

      updateState((draft) => {
        draft.loadingIsOpen = false
        draft.locationGroups = get(responses[0], 'value.data.items', [])
        draft.locations = get(responses[1], 'value.data.items', [])
        draft.assetCategories = get(responses[2], 'value.data.items', [])
        draft.users = get(responses[3], 'value.data.items', [])
        draft.operators = get(responses[4], 'value.data.items', [])
        draft.jobs = get(responses[5], 'value.data.items', [])
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const locationGroupIds = filterDto?.locationGroupIds ?? []
  const locationIds = filterDto?.locationIds ?? []
  const jobIds = filterDto?.jobIds ?? []
  const dateRangeField = filterDto?.dateRangeField ?? 'All'
  const dateRangeType = filterDto?.dateRange?.dateRangeType ?? 'YearToDate'
  const lastNValue = filterDto?.dateRange?.lastNValue ?? 1
  const customStartDate = filterDto?.dateRange?.customStartDate
  const customEndDate = filterDto?.dateRange?.customEndDate
  const assetStatusList = filterDto?.assetStatusList ?? []
  const assetCategoryIds = filterDto?.assetCategoryIds ?? []
  const supervisors = filterDto?.supervisors ?? []
  const operatorIds = filterDto?.operatorIds ?? []
  const isRental = filterDto?.isRental ?? false
  const isOverdue = filterDto?.isOverdue ?? false
  const isCritical = filterDto?.isCritical ?? false
  const periodForCheckoutCount = filterDto?.periodForCheckoutCount ?? 0
  const periodForCheckoutCountType = filterDto?.periodForCheckoutCountType ?? 'Days'
  const calibrationRequiredPeriodInDays = filterDto?.calibrationRequiredPeriodInDays ?? undefined

  if (state.activeView === 'dateRangeField') {
    return (
      <ModalSelect
        title={t('dateType')}
        options={Object.entries(dateRangeFields).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeField}
        onChange={(value) => setFilter('dateRangeField', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'dateRangeType') {
    return (
      <ModalSelect
        title={t('dateRangeType')}
        options={Object.entries(dateRangeTypes).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeType}
        onChange={(value) => {
          if (value === 'Custom') {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: getStartDate(dateRangeType, lastNValue).toISOString(),
                customEndDate: getEndDate(dateRangeType, lastNValue).toISOString(),
              },
            })
          } else {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: null,
                customEndDate: null,
              },
            })
          }
        }}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'locationGroupIds') {
    return (
      <ModalSelect
        title={t('locationGroup')}
        options={(state.locationGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationGroupIds}
        onChange={(values) => {
          assignFilter({
            locationGroupIds: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationIds') {
    return (
      <ModalSelect
        title={t('location')}
        options={(state.locations ?? []).filter(filterLocationsByGroupIds(locationGroupIds)).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationIds}
        onChange={(values) => setFilter('locationIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'jobIds') {
    return (
      <ModalSelect
        title={t('job')}
        options={(state.jobs ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationIds}
        onChange={(values) => setFilter('jobIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'assetCategoryIds') {
    return (
      <ModalSelect
        title={t('assetCategory')}
        options={(state.assetCategories ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={assetCategoryIds}
        onChange={(values) => {
          assignFilter({
            assetCategoryIds: values,
            assetIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'supervisors') {
    return (
      <ModalSelect
        title={t('supervisor')}
        options={(state.users ?? []).map((each) => ({
          value: each.userName,
          text: each.displayName,
        }))}
        value={supervisors}
        onChange={(value) => setFilter('supervisors', value)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'operatorIds') {
    return (
      <ModalSelect
        title={t('operator')}
        options={(state.operators ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={operatorIds}
        onChange={(values) => setFilter('operatorIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'assetStatusList') {
    return (
      <ModalSelect
        title={t('status')}
        options={Object.entries(assetStatusOptions).map((each) => ({
          value: each[0],
          text: t(each[1]),
        }))}
        value={assetStatusList}
        onChange={(values) => setFilter('assetStatusList', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'periodForCheckoutCountType') {
    return (
      <ModalSelect
        title={t('checkoutPeriod')}
        options={Object.entries(periodOptions).map((each) => ({
          value: each[0],
          text: t(each[1]),
        }))}
        value={periodForCheckoutCountType}
        onChange={(value) => setFilter('periodForCheckoutCountType', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        forceOverscroll={false}
        onIonScroll={(e) => setSessionItem(FILTER_SCROLL_TOP, e.detail.scrollTop)}
        scrollEvents
      >
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'dateRangeField')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('dateType')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(dateRangeFields, dateRangeField)}
          </IonLabel>
        </IonItem>
        <DateRangePicker
          {...{ dateRangeType, lastNValue, customStartDate, customEndDate, setState, setFilter }}
          isFieldAll={dateRangeField === 'All'}
        />
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locationGroups, locationGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locations, locationIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'jobIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('job')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.jobs, jobIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'assetCategoryIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('assetCategory')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.assetCategories, assetCategoryIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'supervisors')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('supervisor')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.users, supervisors, 'userName')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'operatorIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('operator')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.operators, operatorIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'assetStatusList')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('status')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(assetStatusOptions, assetStatusList)}
          </IonLabel>
        </IonItem>
        <IonRow>
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('numberOfCheckoutsInPeriod')}</IonLabel>
              <IonInput
                value={periodForCheckoutCount}
                type="number"
                inputmode="number"
                inputMode="number"
                min={0}
                onIonInput={(e) => setFilter('periodForCheckoutCount', tryParseInt(e.target.value, 0))}
                clearOnEdit
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem
              lines="full"
              className="tofino-stacked-item"
              onClick={() => setState('activeView', 'periodForCheckoutCountType')}
              button
            >
              <IonLabel style={{ marginTop: '24px', marginBottom: '23px' }}>
                {getMultiSelectDisplayNameFromObject(periodOptions, periodForCheckoutCountType)}
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonItem lines="full">
          <IonLabel position="stacked">{t('calibrationRequiredPeriodInDays')}</IonLabel>
          <IonInput
            value={calibrationRequiredPeriodInDays}
            type="number"
            inputmode="number"
            inputMode="number"
            onIonInput={(e) =>
              setFilter('calibrationRequiredPeriodInDays', tryParseInt(e.target.value, undefined))
            }
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('rental')}</IonLabel>
          <IonToggle checked={isRental} onIonChange={(e) => setFilter('isRental', e.detail.checked)} />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('overdue')}</IonLabel>
          <IonToggle checked={isOverdue} onIonChange={(e) => setFilter('isOverdue', e.detail.checked)} />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('critical')}</IonLabel>
          <IonToggle checked={isCritical} onIonChange={(e) => setFilter('isCritical', e.detail.checked)} />
        </IonItem>
      </IonContent>
    </>
  )
}

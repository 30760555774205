import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPopover,
  IonLoading,
  IonAlert,
  IonSpinner,
  IonInput,
  IonText,
  IonRow,
  IonCol,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import useOnlineStatus from '@rehooks/online-status'
import { get, set, isNil, isEmpty, camelCase, isEqual, cloneDeep, pick, uniqBy, omit } from 'lodash'
import { message, isSignificant, PLACEHOLDER, DEBOUNCE } from 'helpers/utils'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showClientNotifications } from 'helpers/errors'
import { formatUserTime } from 'helpers/dateTime'
import { allowPricing } from 'helpers/auth'
import { Emitter } from 'helpers/events'
import { getChangedItems, createSaveDocumentItems } from 'helpers/formViews'
import requisitionerFields from 'options/requisitionerFields'
import Tabs from 'elements/Tabs'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Comments from 'elements/Comments'
import OrderItems from 'containers/DraftOrder/Items'
import OrderSuppliers from 'containers/DraftOrder/Suppliers'
import Documents from 'containers/Documents/ChildListView'
import FileUpload from 'elements/FileUpload'

export const getStorageKey = () => 'inventory.issue.draftOrder'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/inventory/issue'
    }
  }, [])

  const [state, updateState] = useImmer({ tabsActiveKey: 'items' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
    saveItem()
  }, [])

  function handleDeleteDraftOrder() {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
      draft.alertIsOpen = true
      draft.alertMessage = t('confirmDeleteOrder')
      draft.alertButtons = [
        { text: t('cancel'), role: 'cancel' },
        {
          text: t('delete'),
          handler: async () => {
            try {
              setState('loadingIsOpen', true)

              const response = await props.deleteItem(state.item.id)

              setState('loadingIsOpen', false)
              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              fetchItem(0)
            } catch (error) {
              setState('loadingIsOpen', false)
              showError({ error })
            }
          },
        },
      ]
    })
  }

  async function fetchItem(itemId) {
    if (itemId) {
      setState('loadingIsOpen', true)
    } else {
      updateState((draft) => {
        draft.item = null
        draft.tabsActiveKey = 'items'
        draft.loadingIsOpen = false
      })
    }

    try {
      const id = itemId ?? (await props.getDraftOrder().then((r) => r.value.data.id))
      const item = await props.getItem(id).then((r) => cloneDeep(r.value.data))

      const [orderItems, orderSupplierItems, documentItems] = await Promise.all([
        item.id
          ? props.getOrderItems(item.id, { includeInventoryStatus: true }).then((r) => r.value.data.items)
          : Promise.resolve([]),
        item.id ? props.getOrderSupplierItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
        item.id
          ? props
              .getDocumentItems({
                request: {
                  domainObjectId: item.id,
                  domainObjectType: 'Order',
                  documentType: 'ObjectDocument',
                },
              })
              .then((r) => r.value.data.items)
          : Promise.resolve([]),
      ])

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.orderItems = cloneDeep(orderItems)
        draft.orderItemsOriginal = cloneDeep(orderItems)
        draft.orderSupplierItems = cloneDeep(orderSupplierItems)
        draft.orderSupplierItemsOriginal = cloneDeep(orderSupplierItems)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function handleDocumentsChange(values) {
    const saveDocumentItems = createSaveDocumentItems(
      {
        props,
        state: { documentItems: values, documentItemsOriginal: state.documentItemsOriginal },
      },
      'Order'
    )

    try {
      updateState((draft) => {
        draft.documentItems = values
        draft.fileUploadIsOpen = false
        draft.loadingIsOpen = true
      })

      await saveDocumentItems(state.item.id)

      const documentItems = await props.getDocumentItems({
        request: {
          domainObjectId: state.item.id,
          domainObjectType: 'Order',
          documentType: 'ObjectDocument',
        },
      })

      updateState((draft) => {
        draft.loadingIsOpen = false
        draft.documentItems = get(documentItems, 'value.data.items', [])
        draft.documentItemsOriginal = get(documentItems, 'value.data.items', [])
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.orderItems, state.orderItemsOriginal) ||
      !isEqual(state.orderSupplierItems, state.orderSupplierItemsOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal)
    )
  }

  function handleSubmit() {
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('submit'),
        handler: async () => {
          setState('errors', {})

          if (
            props.customer.generalSettings.requireRequisitionerOnOrder &&
            requisitionerFields.some(({ key }) => isEmpty(state.item[key])) &&
            state.orderItems.some((one) => one.quantityOrdered > 0)
          ) {
            updateState((draft) => {
              draft.tabsActiveKey = 'order'
              requisitionerFields.forEach(({ key }) => {
                if (isEmpty(state.item[key])) {
                  set(draft, `errors.${key}`, t('errorMissingRequiredField'))
                }
              })
            })
            message.error(t('errorMissingRequiredField'))
          } else {
            try {
              setState('loadingIsOpen', true)

              const response = await props.submitItem(state.item.id)

              setState('loadingIsOpen', false)
              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              fetchItem(0)
            } catch (error) {
              setState('loadingIsOpen', false)
              showError({ error })
            }
          }
        },
      },
    ]
    updateState((draft) => {
      draft.alertIsOpen = true
      draft.alertMessage = t('confirmSubmitIssuedItemsTitle')
      draft.alertButtons = buttons
    })
  }

  async function saveOrderItems(orderItems = state.orderItems) {
    try {
      const { updating, deleting } = getChangedItems(state.orderItemsOriginal, orderItems)
      const responses = await Promise.all(updating.map((each) => props.issueOrderItem(state.item.id, each)))

      for (const response of responses) {
        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }
      }

      if (!isEmpty(deleting)) {
        try {
          const response = await props.deleteOrderItems(
            state.item.id,
            deleting.map((each) => each.id)
          )

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        }
      }
    } catch (error) {
      showError({ error })
    } finally {
      fetchItem(state.item.id)
    }
  }

  const saveItem = useDebouncedCallback(async (refetchItem) => {
    if (isNil(state.item)) {
      fetchItem(0)
      return
    }

    try {
      await props.updateItem({ ...state.item })

      if (refetchItem) {
        await fetchItem(state.item.id)
      } else {
        updateState((draft) => {
          draft.itemOriginal = cloneDeep(draft.item)
        })
      }
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }, DEBOUNCE)

  useIonViewWillEnter(() => {
    setState('loadingIsOpen', true)
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (sessionItem) {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })

      saveItem(true)
    } else {
      fetchItem(0)
    }

    removeSessionItem(getStorageKey())
  })

  if (isNil(state.item)) {
    return (
      <Page title={t('draftOrder')}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const pageTitle = t('draftOrder')

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'issueItem':
        props.history.push(`${props.match.url}/issueItem`)
        break

      case 'showFilter':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('inventory.issue.draftOrder.orderItems.showFilter')
        }
        break

      case 'sortOptions':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('inventory.issue.draftOrder.orderItems.sortOptions')
        }

        if (state.tabsActiveKey === 'suppliers') {
          Emitter.emit('inventory.issue.draftOrder.orderSuppliers.sortOptions')
        }

        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const head = (
      <IonItem
        key="issueItem"
        lines="full"
        onClick={() => handleActionsMenuClick('issueItem')}
        disabled={hasUnsavedChanges()}
      >
        <IonLabel>{t('issueItem')}</IonLabel>
      </IonItem>
    )

    const tail = [
      <IonItem key="deleteDraftOrder" lines="none" onClick={handleDeleteDraftOrder}>
        <IonLabel>{t('deleteDraftOrder')}</IonLabel>
      </IonItem>,
    ]

    const defaultItems = [head, ...tail].filter(Boolean)

    switch (state.tabsActiveKey) {
      case 'items':
        return [
          head,
          <IonItem key="showFilter" lines="none" onClick={() => handleActionsMenuClick('showFilter')}>
            <IonLabel>{t('showFilter')}</IonLabel>
          </IonItem>,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ]

      case 'suppliers':
        return [
          head,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ]

      case 'documents':
        return [
          head,
          <IonItem key="addDocument" lines="full" onClick={() => handleActionsMenuClick('addDocument')}>
            <IonLabel>{t('addDocument')}</IonLabel>
          </IonItem>,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ]

      default:
        return defaultItems
    }
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={hasUnsavedChanges() || !isOnline}
          >
            <Icon
              type="Menu"
              size="26"
              color={
                state.tabsActiveKey === 'items' &&
                Object.entries(state.orderItemsFilterDto ?? {}).some(([key, value]) => isSignificant(value))
                  ? 'warning'
                  : undefined
              }
            />
          </IonButton>
        ) : null
      }
      footer={
        <IonRow>
          {state.tabsActiveKey === 'items' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('issueItem')}
                disabled={!isOnline}
              >
                {t('issue')}
              </IonButton>
            </IonCol>
          )}
          {state.tabsActiveKey === 'documents' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('addDocument')}
                disabled={!isOnline}
              >
                {t('add')}
              </IonButton>
            </IonCol>
          )}
          <IonCol>
            <IonButton
              expand="full"
              color="secondary"
              onClick={handleSubmit}
              disabled={hasUnsavedChanges() || isEmpty(state.orderItems) || !isOnline}
            >
              {t('submit')}
            </IonButton>
          </IonCol>
        </IonRow>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="order" tab={t('order')} disabled={hasUnsavedChanges()} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('orderId')}</IonLabel>
            <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('created')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.createDate, state.item.userName)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          {['billTo', 'shipTo'].map((each) => (
            <IonItem
              key={each}
              lines="full"
              className="tofino-stacked-item"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/${camelCase(`select-${each}`)}`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t(each)}</small>
                </IonText>
                <br />
                {get(state, `item.${each}AddressName`) || PLACEHOLDER}
              </IonLabel>
            </IonItem>
          ))}
          {requisitionerFields.map(({ key, type }) => (
            <IonItem
              key={key}
              lines="full"
              className={cx({
                'tofino-error-item': get(state, `errors.${key}`),
                'tofino-required-item':
                  props.customer.generalSettings.requireRequisitionerOnOrder &&
                  state.orderItems.some((one) => one.quantityOrdered > 0),
              })}
            >
              <IonLabel position="stacked">{t(key)}</IonLabel>
              <IonInput
                type={type}
                inputmode={type}
                value={state.item[key]}
                onIonInput={(e) => setItemValue(key, e.target.value)}
                placeholder={PLACEHOLDER}
              />
            </IonItem>
          ))}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('code')}</IonLabel>
            <IonInput
              value={state.item.accountCode}
              onIonInput={(e) => setItemValue('accountCode', e.target.value)}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          {allowPricing()
            ? [
                ...(props.customer.moduleSettings.enableTax
                  ? ['exemptTotal', 'taxableTotal', 'taxTotal']
                  : []),
                'subTotal',
                'adjustmentsTotal',
                'freightTotal',
                'orderTotal',
              ].map((each) => (
                <IonItem key={each} lines="full">
                  <IonLabel position="stacked">
                    {each === 'taxTotal'
                      ? `${t('taxTotal')} (${state.item.taxRate.toLocaleString(props.locale, {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })})`
                      : each === 'subTotal'
                        ? t('subtotal')
                        : t(each)}
                  </IonLabel>
                  <IonInput value={toLocaleCurrency(state.item[each])} disabled />
                </IonItem>
              ))
            : null}
          <Comments value={state.item.comment} onChange={(value) => setItemValue('comment', value)} />
        </Tabs.TabPane>
        <Tabs.TabPane key="items" tab={t('itemsTab')} disabled={hasUnsavedChanges()} forceRender>
          <OrderItems
            items={state.orderItems ?? []}
            onChange={(values) => {
              updateState((draft) => {
                draft.orderItems = values
                draft.loadingIsOpen = true
              })
              saveOrderItems(values)
            }}
            onClick={(item) => {
              setSessionItem(getStorageKey(), pick(state, ['tabsActiveKey']))
              props.history.push(`${props.match.url}/${state.item.id}/orderItems/${item.id}`)
            }}
            parentRecord={{
              ...state.item,
              orderItems: state.orderItems,
              orderSupplierItems: state.orderSupplierItems,
            }}
            onFilter={(value) => setState('orderItemsFilterDto', value)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="suppliers" tab={t('suppliers')} disabled={hasUnsavedChanges()} forceRender>
          <OrderSuppliers
            storageKey="inventory.issue.draftOrder.orderSuppliers"
            items={state.orderSupplierItems ?? []}
            onClick={(item) => {
              setSessionItem(getStorageKey(), pick(state, ['tabsActiveKey']))
              props.history.push(`${props.match.url}/${state.item.id}/orderSuppliers/${item.id}`)
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} disabled={hasUnsavedChanges()} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Order"
            items={state.documentItems ?? []}
            onChange={handleDocumentsChange}
          />
        </Tabs.TabPane>
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) => handleDocumentsChange(uniqBy([fileList[0], ...state.documentItems], 'id'))}
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import reducer from 'reducers'

const middleware = [thunk, promise]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger({ collapsed: true }))
}

export default configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
  enhancers: [],
})

import { createListViewContainer } from 'factories/ListView'
import { wrapItems } from 'helpers/utils'
import { getPrivateItem } from 'helpers/localForage'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import inventoryActions from 'actions/inventory'
import Component from 'components/Count/UploadItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await getPrivateItem(COUNTED_ITEMS_SAVED_KEY, [])

      return Promise.resolve(items).then(wrapItems)
    },
    updateOnHand: (params = []) => dispatch(inventoryActions.updateOnHand(params)),
  }),
})(Component)

import { useImmer } from 'use-immer'
import { IonButton, IonText, useIonViewDidEnter } from '@ionic/react'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getSharedLocations } from 'helpers/offlineData'
import { getPrivateItem, removePrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_CURRENT_KEY, LOCATION_COUNTS_COMPLETED_RESULTS_KEY } from 'options/locationCounts'
import Page from 'elements/Page'

export default function (props) {
  const [state, updateState] = useImmer({})

  useIonViewDidEnter(async () => {
    try {
      const completed = await getPrivateItem(LOCATION_COUNTS_COMPLETED_RESULTS_KEY)
      const location = await getSharedLocations().then((r) =>
        r.find((one) => one.id === completed.locationId)
      )

      updateState((draft) => {
        draft.completedCount = completed
        draft.completedLocation = location
      })
    } catch (error) {
      removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)
      removePrivateItem(LOCATION_COUNTS_COMPLETED_RESULTS_KEY)
      window.location.href = '/inventory/locationCounts'
    }
  })

  const locationCountDetails = state.completedCount?.locationCountDetails ?? []

  return (
    <Page
      title={t('completedResults')}
      header={
        <dl className="ion-margin tofino-tabular-list">
          <dt>{t('location')}</dt>
          <dd>{state.completedLocation?.displayName ?? PLACEHOLDER}</dd>
          <dt>{t('itemsAtLocation')}</dt>
          <dd>{state.completedLocation?.itemsAtLocation ?? PLACEHOLDER}</dd>
          <dt>{t('itemsCountedToday')}</dt>
          <dd>{locationCountDetails.length}</dd>
          <dt>{t('orderItemsSaved')}</dt>
          <dd>{locationCountDetails.filter((each) => each.quantityToOrder).length}</dd>
          <dt>{t('countedToday')}</dt>
          <dd>{t('yes')}</dd>
        </dl>
      }
      footer={
        <IonButton
          expand="full"
          color="secondary"
          onClick={() => {
            window.location.href = '/inventory/locationCounts/selectLocation'
          }}
        >
          {t('selectNextLocation')}
        </IonButton>
      }
    >
      <div className="ion-padding">
        <IonText color="success">{t('completedResultsInfo')}</IonText>
      </div>
    </Page>
  )
}

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import inventoryActions from 'actions/inventory'
import { filterBySearch, wrapItems, wrapData } from 'helpers/utils'
import { getSharedInventory } from 'helpers/offlineData'
import { updatePrivateItem } from 'helpers/localForage'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import Component from 'components/Count/CountItems'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getInventory: (params) =>
    dispatch(inventoryActions.getItems({ pageIndex: 0, ...params })).catch(async (error) => {
      if (!error.response) {
        const items = await getSharedInventory().then((r) =>
          r
            .filter((each) => params.locationIds.includes(each.locationId))
            .filter(filterBySearch(params.search))
        )

        return wrapItems(items)
      }

      throw error
    }),
  updateOnHand: (params = {}) =>
    dispatch(inventoryActions.updateOnHand([params])).catch(async (error) => {
      if (!error.response) {
        await updatePrivateItem(COUNTED_ITEMS_SAVED_KEY, [], (draft) => {
          const index = draft.findIndex((one) => one.inventoryId === params.inventoryId)

          if (index === -1) {
            draft.unshift({ id: Date.now() * -1, timestamp: new Date().toJSON(), ...params })
          } else {
            Object.assign(draft[index], params)
          }
        })

        return wrapData({
          clientNotifications: [
            {
              type: 'Warning',
              description: 'quantityHasBeenSavedOnDevice',
            },
          ],
          successCount: 1,
          failureCount: 0,
        })
      }

      throw error
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import * as React from 'react'
import { useImmer } from 'use-immer'
import { IonButton, IonText, IonAlert, useIonViewDidEnter } from '@ionic/react'
import { set } from 'lodash'
import { t } from 'helpers/i18n'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import { getPrivateItem, removePrivateItem } from 'helpers/localForage'
import { showError } from 'helpers/errors'
import Page from 'elements/Page'

export default function (props) {
  const [state, updateState] = useImmer({ xxx: 0 })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  useIonViewDidEnter(async () => {
    try {
      const xxx = await getPrivateItem(COUNTED_ITEMS_SAVED_KEY, []).then((r) => r.length)

      updateState((draft) => {
        draft.xxx = xxx

        if (xxx === 0) {
          draft.alertIsOpen = true
          draft.alertMessage = t('nothingToClear')
          draft.alertButtons = [
            {
              text: t('ok'),
              role: 'cancel',
              handler: () => props.history.goBack(),
            },
          ]
        }
      })
    } catch (error) {
      showError({ error })
    }
  })

  return (
    <Page
      title={t('clearSavedCountedItems')}
      footer={
        <IonButton
          expand="full"
          color="secondary"
          onClick={() =>
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('confirmClearCountedItems')
              draft.alertButtons = [
                {
                  text: t('cancel'),
                  role: 'cancel',
                },
                {
                  text: t('clear'),
                  handler: async () => {
                    try {
                      await removePrivateItem(COUNTED_ITEMS_SAVED_KEY)
                      props.history.goBack() // inventory/count
                    } catch (error) {
                      showError({ error })
                    }
                  },
                },
              ]
            })
          }
        >
          {t('clear')}
        </IonButton>
      }
    >
      <div className="ion-padding-horizontal">
        <p>
          <IonText color="medium">{t('clearCountedItemsSummary').replace('XXX', state.xxx ?? 0)}</IonText>
        </p>
        <p>
          <IonText color="danger">{t('clearCountedItemsWarning')}</IonText>
        </p>
      </div>
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'assignedTo',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.assignedToId ?? 0,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.assignedToId = each.id
      draft.item.assignedToName = each.name || each.displayName
    }),
})()

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonPopover,
  IonItem,
  IonLabel,
  IonSpinner,
  IonInput,
  IonText,
  IonSearchbar,
  IonLoading,
  IonAlert,
  IonRow,
  IonCol,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { get, set, isNil, isEmpty, camelCase, pick, intersection, cloneDeep, omit, isFinite } from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, setStorageItem, updateStorageItem } from 'helpers/localStorage'
import Stepper from 'elements/Stepper'
import {
  asyncSleep,
  simulatePressEnter,
  message,
  tryParseInt,
  strEqual,
  getTagDisplayNameField,
  getTagName,
  PLACEHOLDER,
  FOCUS_DELAY,
} from 'helpers/utils'
import { validateOperatorTag, validateJobTag, validateAssetTag, validateOtherTags } from 'helpers/procurement'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { createCheckOfflineInventory } from 'helpers/offlineData'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { ISSUED_ITEMS_SAVED_KEY } from 'options/inventory/issue'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Menu from 'elements/Menu'
import Button from 'elements/Button'
import Dropdown from 'elements/Dropdown'
import SearchbarContainer from 'elements/SearchbarContainer'
import BarcodeScanner from 'elements/BarcodeScanner'

const qtyKey = 'quantityIssued'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  '3', // Asset
  'A',
  'B',
  'C',
  'D',
  'E',
]

function Description({ inventory, scannerSettings, item }) {
  return (
    <p className="ion-margin-horizontal">
      {inventory ? (
        <>
          {inventory.itemDescription}
          <br />
          {scannerSettings.showInventoryDescription && inventory.description ? (
            <>
              {inventory.description}
              <br />
            </>
          ) : null}
          {t('onhand:')}{' '}
          {(scannerSettings.showCount2 ? [inventory.onHand, inventory.onHand2] : [inventory.onHand]).join(
            '/'
          )}
          <br />
          {t('onOrder:')} {inventory.onOrder}
          <br />
          {t('minMax:')} {[inventory.min, inventory.max].join('/')}
          <br />
          {t('pkg:')} {inventory.packageSizeUom || PLACEHOLDER}
          <br />
          {t('binLocation:')} {inventory.binLocation || PLACEHOLDER}
        </>
      ) : item.locationId ? (
        <IonText color="medium">{t('enterBarcodeInfo')}</IonText>
      ) : (
        <IonText color="medium">{t('selectLocationFirst')}</IonText>
      )}
    </p>
  )
}

export const getStorageKey = () => 'issue.issueItem'
export const getSelectedLocationKey = () => 'issue.issueItem.selectedLocation'

export default function (props) {
  const ref1 = React.useRef()
  const ref2 = React.useRef()
  const ref3 = React.useRef()
  const refA = React.useRef()
  const refB = React.useRef()
  const refC = React.useRef()
  const refD = React.useRef()
  const refE = React.useRef()
  const refBarcode = React.useRef()
  const refQuantity = React.useRef()

  const [state, updateState] = useImmer({
    showTagFields: true,
    requireQuantity: true,
    ...getStorageItem(getStorageKey(), {}),
    ...getSessionItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}Enabled`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2', '3'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function getTagRef(tagType) {
    return {
      2: ref2,
      1: ref1,
      3: ref3,
      A: refA,
      B: refB,
      C: refC,
      D: refD,
      E: refE,
    }[tagType]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  function focusNextTag(tagType) {
    const indexes = ['2', '1', '3', 'A', 'B', 'C', 'D', 'E', 'Barcode', 'Quantity']
    const tagRefs = [ref2, ref1, ref3, refA, refB, refC, refD, refE, refBarcode, refQuantity]

    for (let index = indexes.indexOf(tagType) + 1; index < indexes.length; index++) {
      try {
        tagRefs[index].current.setFocus()
        return true
      } catch (error) {
        console.warn(error)
      }
    }

    return false
  }

  function findInventory(item = state.item) {
    const { barcode, inventoryBarcode, locationId } = item

    validateFields(async () => {
      try {
        updateState((draft) => {
          draft.loadingIsOpen = true
          draft.inventory = null
          draft.item[qtyKey] = null
        })

        const results = await props
          .getInventory({
            search: props.useInventoryBarcode ? inventoryBarcode : barcode,
            locationIds: locationId ? [locationId] : [],
          })
          .then((r) => r.value.data.items)

        const inventory = results
          .filter((each) => each.locationId === locationId)
          .find((one) => {
            if (props.useInventoryBarcode) {
              return strEqual(one.inventoryBarcode, inventoryBarcode)
            }

            if (props.tenantGroupIsCardinal) {
              return strEqual(one.barcode, barcode)
            }

            return (
              strEqual(one.barcode, barcode) ||
              strEqual(one.barcode2, barcode) ||
              strEqual(one.barcode3, barcode)
            )
          })

        if (inventory) {
          if (inventory.lockedForCycleCount) {
            throw new Error(t('errorLockedForCycleCount'))
          }

          updateState((draft) => {
            draft.inventory = inventory
            draft.item.barcode = inventory.barcode
            draft.item.inventoryBarcode = inventory.inventoryBarcode
            draft.item[qtyKey] = null
          })

          if (state.requireQuantity) {
            window.setTimeout(() => refQuantity.current.setFocus(), 1000)
          }
        } else {
          message.error(t('itemNotFound'))
        }
        setState('loadingIsOpen', false)
      } catch (error) {
        setState('loadingIsOpen', false)
        showError({ error })
      }
    })
  }

  function handleTagKeyPress(tagType) {
    if (tagType === 'Barcode') {
      return (e) => {
        if (e.key === 'Enter') {
          findInventory(state.item)
        } else {
          setState('inventory', null)
        }
      }
    }

    if (tagType === 'Quantity') {
      return (e) => {
        if (e.key === 'Enter') {
          saveItem()
        } else if (e.key.match(/\D/)) {
          stopEvent(e)
        }
      }
    }

    return (e) => {
      if (e.key === 'Enter') {
        validateFields(() => {
          setState('loadingIsOpen', false)

          if (!focusNextTag(tagType)) {
            saveItem()
          }
        })
      }
    }
  }

  function handleBarcodeInput({ target: { value } }) {
    updateState((draft) => {
      if (props.useInventoryBarcode) {
        draft.item.inventoryBarcode = value
      } else {
        draft.item.barcode = value
      }

      draft.inventory = null
    })
  }

  async function handleBarcodeScan({ rawValue: value }) {
    setState('scanBarcodeIsOpen', false)
    await asyncSleep(500)
    refBarcode.current.setFocus()
    refBarcode.current.value = value
    handleBarcodeInput({ target: { value } })
    simulatePressEnter(refBarcode.current)
  }

  function handleQuantityStep(value) {
    updateState((draft) => {
      draft.item[qtyKey] = isFinite(value) ? Math.max(tryParseInt(draft.item[qtyKey], 0) + value, 1) : 1
    })
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isNil(values)) {
      callback(errors, values)
      return
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateAssetTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    values[qtyKey] = Math.max(tryParseInt(values[qtyKey], 0), 1)

    setState('item', values)

    callback(errors, values)
  }

  function resetForm() {
    updateState((draft) => {
      draft.item = {
        locationId: 0,
        operatorName: '*',
        operatorDisplayName: '',
        jobName: '*',
        jobDisplayName: '',
        assetName: '*',
        assetDisplayName: '',
        userName: props.user.userName,
        [qtyKey]: null,
        ...getStorageItem(getSelectedLocationKey(), {}),
      }
      draft.inventory = null
    })
  }

  function saveItem() {
    if (state.submitting) {
      return
    }

    setState('submitting', true)

    validateFields(async (errors, values) => {
      try {
        setState('errors', errors)

        if (intersection(tagTypes, Object.keys(errors)).length) {
          setState('showTagFields', true)
        }

        if (isEmpty(errors)) {
          setState('loadingIsOpen', true)

          const orderItem = {
            ...values,
            [qtyKey]: values[qtyKey] || 1,
            barcode: state.inventory.barcode,
            inventoryBarcode: state.inventory.inventoryBarcode,
            inventoryId: state.inventory.id,
            description: state.inventory.itemDescription,
            assetName: values.assetName || `*${values.assetDisplayName}`,
            jobName: values.jobName || `*${values.jobDisplayName}`,
            jobBarcode:
              values.jobBarcode ||
              (!props.customer.tagSettings.tag1Restricted ? values.jobDisplayName : undefined),
            locationName: values.locationName || `*${values.locationDisplayName}`,
            operatorName: values.operatorName || `*${values.operatorDisplayName}`,
            assetDisplayName: values.assetDisplayName,
            operatorDisplayName: values.operatorDisplayName,
            jobDisplayName: values.jobDisplayName,
            inventoryDisplayName: values.inventoryDisplayName,
            locationDisplayName: values.locationDisplayName,
          }

          if (props.match.params.itemId) {
            await updatePrivateItem(ISSUED_ITEMS_SAVED_KEY, [], (draft) => {
              const index = draft.findIndex((one) => strEqual(one.id, props.match.params.itemId))

              draft.splice(index, 1, orderItem)
            })

            setState('loadingIsOpen', false)
            message.warn(t('issueQuantityHasBeenSavedOnDevice'))
            props.history.goBack()
          } else {
            try {
              setState('loadingIsOpen', true)

              const response = await props.issueOrderItem(orderItem)

              setState('loadingIsOpen', false)
              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              if (state.quickscan) {
                updateState((draft) => {
                  draft.item.barcode = ''
                  draft.item.inventoryBarcode = ''
                  draft.item[qtyKey] = null
                  draft.inventory = null
                })
              } else {
                resetForm()
              }
            } catch (error) {
              setState('loadingIsOpen', false)
              showError({ error })
            } finally {
              setState('loadingIsOpen', false)
            }
          }
        } else {
          setState('loadingIsOpen', false)
          showValidationErrors({ errors })
        }
      } finally {
        setState('submitting', false)
        tryFocusInput(refBarcode)
      }
    })
  }

  function tryFocusInput(refInput) {
    try {
      refInput.current.setFocus()
    } catch (error) {
      console.warn(error)
      window.setTimeout(() => tryFocusInput(refInput), FOCUS_DELAY)
    }
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  async function fetchItem(itemId = props.match.params.itemId) {
    if (props.match.params.itemId) {
      try {
        const response = await getPrivateItem(ISSUED_ITEMS_SAVED_KEY).then((r) =>
          r.find((one) => strEqual(one.id, itemId))
        )

        updateState((draft) => {
          draft.item = response
          draft.requireQuantity = true
        })

        findInventory(response)
      } catch (error) {
        showError({ error })
      }
    } else {
      resetForm()
    }
  }

  function handleMenuClick({ key }) {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (intersection(tagTypes, Object.keys(errors)).length) {
        setState('showTagFields', true)
      }

      setState('loadingIsOpen', false)

      if (isEmpty(errors)) {
        switch (key) {
          case 'scanBarcode':
            setState('scanBarcodeIsOpen', true)
            break

          case 'searchInventory':
            setSessionItem('issue.issueItem', {
              ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              item: values,
            })
            updateStorageItem('issue.issueItem.searchInventory', {}, (draft) => {
              set(draft, 'filterDto.locationIds', [values.locationId])
            })
            removeSessionItem('issue.issueItem.searchInventory')
            props.history.push(`${props.match.url}/searchInventory`)
            break

          default:
            message.info(t('underDevelopment'))
            break
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['quickscan', 'requireQuantity', 'showTagFields']))
  }, [state.quickscan, state.requireQuantity, state.showTagFields])

  React.useEffect(() => {
    if (state.item) {
      setStorageItem(
        getSelectedLocationKey(),
        pick(state.item, [
          'locationId',
          'locationName',
          'locationNumber',
          'locationBarcode',
          'locationDisplayName',
        ])
      )
    }
  }, [state.item])

  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    createCheckOfflineInventory({ props, updateState, isOnline })()
  }, [isOnline])

  const pageTitle = t('issueItem')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        <IonButton
          onClick={(e) => {
            updateState((draft) => {
              draft.popoverIsOpen = true
              draft.popoverEvent = e
            })
          }}
        >
          <Icon type="Menu" size="26" color={state.quickscan ? 'warning' : undefined} />
        </IonButton>
      }
      footer={
        props.match.params.itemId ? (
          <IonRow>
            <IonCol>
              <IonButton color="transparent" expand="full" onClick={() => props.history.goBack()}>
                {t('cancel')}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="secondary"
                expand="full"
                onClick={() => saveItem()}
                disabled={isNil(state.inventory) || state.inventory.lockedForCycleCount}
              >
                {t('save')}
              </IonButton>
            </IonCol>
          </IonRow>
        ) : (
          <IonButton
            color="secondary"
            expand="full"
            onClick={() => saveItem()}
            disabled={state.submitting || isNil(state.inventory) || state.inventory.lockedForCycleCount}
          >
            {t('issue')}
          </IonButton>
        )
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item', {
          'tofino-error-item': state.errors?.locationId,
        })}
        onClick={() => {
          if (!props.match.params.itemId) {
            setSessionItem(getStorageKey(), pick(state, ['item']))
            props.history.push(`${props.match.url}/selectLocation`)
          }
        }}
        button={!props.match.params.itemId}
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('location')}</small>
          </IonText>
          <br />
          {state.item.locationId ? (
            <IonText color={props.match.params.itemId ? 'medium' : undefined}>
              {state.item.locationDisplayName}
            </IonText>
          ) : (
            <span className="tofino-placeholder">{PLACEHOLDER}</span>
          )}
        </IonLabel>
      </IonItem>
      {Boolean(state.showTagFields) &&
        !isEmpty(enabledTagTypes) &&
        enabledTagTypes.map((tagType) => (
          <IonItem
            key={tagType}
            lines="full"
            className={cx('tofino-stacked-item', {
              'tofino-required-item': getTagIsRequired(tagType),
              'tofino-error-item': get(state, `errors.${tagType}`),
            })}
          >
            <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
            <IonInput
              ref={getTagRef(tagType)}
              value={getTagDisplayName(tagType)}
              placeholder={PLACEHOLDER}
              onIonInput={handleTagInput(tagType)}
              onKeyPress={handleTagKeyPress(tagType)}
              onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
            />
            {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
          </IonItem>
        ))}
      {Boolean(state.item.locationId) && (
        <SearchbarContainer lines="full">
          <IonSearchbar
            ref={refBarcode}
            value={props.useInventoryBarcode ? state.item.inventoryBarcode : state.item.barcode}
            placeholder={t('enterBarcode')}
            onIonInput={handleBarcodeInput}
            onKeyPress={handleTagKeyPress('Barcode')}
            onIonClear={() => setState('inventory', null)}
            disabled={props.match.params.itemId}
            maxlength={200}
          />
          <Dropdown
            overlay={
              <Menu onClick={handleMenuClick}>
                {props.scannerSettings.enableCameraScanning && [
                  <Menu.Item key="scanBarcode">{t('scanBarcode')}</Menu.Item>,
                  <Menu.Divider key="divider" />,
                ]}
                <Menu.Item key="searchInventory">{t('searchInventory')}</Menu.Item>
              </Menu>
            }
            trigger={['click']}
            placement={state.showTagFields ? 'topRight' : 'bottomRight'}
          >
            <Button>
              <Icon type="MoreHoriz" color="primary" />
            </Button>
          </Dropdown>
        </SearchbarContainer>
      )}
      {state.requireQuantity && state.item?.locationId ? (
        <IonItem lines="full">
          <IonLabel>{t('quantity')}</IonLabel>
          <IonInput
            ref={refQuantity}
            value={state.item[qtyKey]}
            onIonInput={(e) => setState(`item.${qtyKey}`, tryParseInt(e.target.value, 1))}
            onKeyPress={handleTagKeyPress('Quantity')}
            className="ion-text-right"
            type="number"
            inputmode="number"
            inputMode="number"
            placeholder={PLACEHOLDER}
            min={1}
            clearOnEdit
          />
          <Stepper onClick={handleQuantityStep} min={1} />
        </IonItem>
      ) : null}
      <Description scannerSettings={props.scannerSettings} item={state.item} inventory={state.inventory} />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <IonItem lines="full" onClick={() => setState('quickscan', !state.quickscan)}>
          <IonLabel>{t('quickscan')}</IonLabel>
          {state.quickscan && <Icon type="Check" slot="end" color="success" />}
        </IonItem>
        <IonItem lines="full" onClick={() => setState('requireQuantity', !state.requireQuantity)}>
          <IonLabel>{t('requireQuantity')}</IonLabel>
          {state.requireQuantity && <Icon type="Check" slot="end" color="success" />}
        </IonItem>
        {!isEmpty(enabledTagTypes) && (
          <IonItem lines="none" onClick={() => setState('showTagFields', !state.showTagFields)}>
            <IonLabel>{t('showTagFields')}</IonLabel>
            {state.showTagFields && <Icon type="Check" slot="end" color="success" />}
          </IonItem>
        )}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <IonModal isOpen={state.scanBarcodeIsOpen}>
        {state.scanBarcodeIsOpen && (
          <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
        )}
      </IonModal>
    </Page>
  )
}

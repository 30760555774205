import { get, partition } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'

export default createListViewComponent({
  getStorageKey: () => 'inventory.issue.uploadResults',
  pageTitleLanguageKey: 'uploadResults',
  getSearchHeader: ({ props, state, updateState, fetchItems }) => {
    const issuedItems = state.items ?? []
    const [successful, unsuccessful] = partition(
      issuedItems,
      (each) => !each.error && each.response.failureCount === 0
    )

    return (
      <dl className="ion-margin tofino-tabular-list">
        <dt>{t('issuedItems')}</dt>
        <dd>{issuedItems.length}</dd>
        <dt>{t('uploadedSuccessfully')}</dt>
        <dd>{successful.length}</dd>
        <dt>{t('uploadedWithErrors')}</dt>
        <dd>{unsuccessful.length}</dd>
      </dl>
    )
  },
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (each) => {
      const success = !each.error && each.response.failureCount === 0
      const message = success ? get(each, 'response.clientNotifications[0].description') : each.error.message

      return (
        <IonItem key={each.id} lines="full">
          <IonLabel>
            {props.useInventoryBarcode ? each.inventoryBarcode || each.barcode : each.barcode}
            <br />
            <small>
              <IonText color="medium">
                {each.description || PLACEHOLDER}
                <br />
                {each.locationName}, {t('issued:')} {each.quantityIssued}
              </IonText>
              <br />
              {success ? (
                <IonText color="success">{`${t('success')} - ${message}`}</IonText>
              ) : (
                <IonText color="danger">{`${t('error')} - ${message}`}</IonText>
              )}
            </small>
          </IonLabel>
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
})()

import { isEmpty } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import inventoryActions from 'actions/inventory'
import { getSharedInventory, getSharedLocations } from 'helpers/offlineData'
import { wrapItems, sortItems, filterBySearch } from 'helpers/utils'
import Component from 'components/Count/CountItems/SearchInventory'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(inventoryActions.getListViewItems(params)).catch(async (error) => {
        if (!error.response) {
          const { locationIds = [] } = params
          const [items, locations] = await Promise.all([getSharedInventory(), getSharedLocations()])

          const filterLocationIds = isEmpty(locationIds) ? locations.map((each) => each.id) : locationIds

          const unsortedItems = items
            .filter((each) => isEmpty(filterLocationIds) || filterLocationIds.includes(each.locationId))
            .filter(filterBySearch(params.search))

          return Promise.resolve(sortItems(unsortedItems, params.sortByField, params.sortOrder)).then(
            wrapItems
          )
        }

        throw error
      }),
  }),
})(Component)

import produce from 'immer'
import { snakeCase, set } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createGet, createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.orders
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useListForOptions: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']
        draft.statusFilter = draft.statusFilter ?? 'All'

        // T20-2503
        draft.confirmationStatusFilter = draft.includeUnconfirmedOnly
          ? 'Unconfirmed'
          : draft.confirmationStatusFilter ?? 'All'
        draft.includeUnconfirmedOnly = draft.confirmationStatusFilter === 'Unconfirmed'

        normalizeDateRange(draft)
      }),
  }),

  getDraftOrder: () => ({
    type: `${ENTITY_NAME}_DRAFT_GET`,
    payload: createGet(entityName)(0),
  }),

  submitItem: (orderId) => ({
    type: `${ENTITY_NAME}_SUBMIT_ITEM`,
    payload: createPost(entityName, { action: `${orderId}/submit` })(),
  }),

  saveOrder: (params = {}, options) => ({
    type: `${ENTITY_NAME}_SAVE`,
    payload: createPost(entityName, { action: 'save' })(
      produce(params, (draft) => {
        set(draft, 'orderItems.creating', (draft.orderItems?.creating ?? []).reverse())
      }),
      options
    ),
  }),

  cancelOrder: (orderId) => ({
    type: `${ENTITY_NAME}_CANCEL`,
    payload: createPost(entityName, { action: `${orderId}/cancel` })(),
  }),

  emailOrder: (params) => ({
    type: `${ENTITY_NAME}_EMAIL`,
    payload: createPost(entityName, { action: 'email' })(params),
  }),

  sendOrder: (orderId) => ({
    type: `${ENTITY_NAME}_SEND`,
    payload: createPost(entityName, { action: `${orderId}/send` })(),
  }),

  resendOrder: ({ orderId, supplierId } = {}) => ({
    type: `${ENTITY_NAME}_RESEND`,
    payload: createPost(entityName, { action: `${orderId}/resend` })({ supplierId }),
  }),

  requestApproval: (orderId) => ({
    type: `${ENTITY_NAME}_REQUEST_APPROVAL`,
    payload: createPost(entityName, { action: `${orderId}/requestApproval` })(),
  }),

  approveOrder: (orderId) => ({
    type: `${ENTITY_NAME}_APPROVE`,
    payload: createPost(entityName, { action: `${orderId}/approve` })(),
  }),

  processOrder: (orderId, options) => ({
    type: `${ENTITY_NAME}_PROCESS`,
    payload: createPost(entityName, { action: `${orderId}/markProcessed` })({ orderId }, options),
  }),

  undoProcessed: (orderId, options) => ({
    type: `${ENTITY_NAME}_UNDO_PROCESSED`,
    payload: createPost(entityName, { action: `${orderId}/undoProcessed` })({ orderId }, options),
  }),

  returnItems: (params) => ({
    type: `${ENTITY_NAME}_RETURN_ITEMS`,
    payload: createPost(entityName, { action: 'returnItems' })(params),
  }),
}

import { createListViewContainer } from 'factories/ListView'
import { getSessionItem } from 'helpers/sessionStorage'
import productActions from 'actions/products'
import orderItemActions from 'actions/orders/orderItems'
import Component from 'components/Orders/AddItems/SearchProducts'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { locationId } = getSessionItem('orders.addItems', {}).item

      return dispatch(
        productActions.getListViewItems({
          ...params,
          locationIds: locationId ? [locationId] : [],
          active: 'Active',
        })
      )
    },
    populateOrderItems: (params) => dispatch(orderItemActions.populateOrderItems(params)),
    copyToPrivate: (params) => dispatch(productActions.copyToPrivate(params)),
  }),
})(Component)

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'
import { PLACEHOLDER } from 'helpers/utils'
import assetStatusOptions from 'options/assets/statusOptions'

export default createChildListViewComponent({
  allowSearch: () => false,
  renderItem:
    ({ props }) =>
    (item) => (
      <IonItem lines="full" onClick={() => props.onClick(item)} detail>
        <IonLabel>
          {formatDateTime(item.actionDate, { showTime: true })}
          <br />
          <IonText color="medium">
            <small>
              {t('action:')}{' '}
              {assetStatusOptions[item.statusChange] ? t(assetStatusOptions[item.statusChange]) : PLACEHOLDER}
              <br />
              {t('comments:')} {item.comment || PLACEHOLDER}
            </small>
          </IonText>
        </IonLabel>
      </IonItem>
    ),
  getSortByFields: (self) => [
    { fieldName: 'actionDate', languageKey: 'date' },
    { fieldName: 'statusChange', languageKey: 'action' },
    { fieldName: 'itemCondition', languageKey: 'condition' },
    { fieldName: 'lifeRemaining', languageKey: 'remainingLife' },
    { fieldName: 'servicehours', languageKey: 'serviceHours' },
    { fieldName: 'mileage', languageKey: 'mileage' },
  ],
  getDefaultSortByField: (self) => 'actionDate',
  getDefaultSortOrder: (self) => 'desc',
})()

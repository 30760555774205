import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem } from 'helpers/sessionStorage'
import { Emitter } from 'helpers/events'
import { LISTS_CHANGE_ITEM_EVENT } from 'options/events'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'list',
  getItemSize: () => 45,
  allowSearch: () => false,
  getIdField: () => 'listId',
  getTextField: () => 'listName',
  getValue: () => getSessionItem(getStorageKey(), {}).listId,
  getOnChange: (self) => (each) => {
    Emitter.emit(LISTS_CHANGE_ITEM_EVENT, each)
  },
})()

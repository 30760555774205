import { isEmpty } from 'lodash'
import { IonItem, IonLabel, IonToggle, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { removePrivateItemNoScope } from 'helpers/localForage'
import { IMPERSONATOR_USERNAME } from 'options/auth'
import { LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import { asyncSleep, createSelectRowHandler } from 'helpers/utils'
import SelectDeselect from 'elements/SelectDeselect'

export default createListViewComponent({
  getStorageKey: () => 'distributor.clearCustomers',
  getPopoverContent: ({ updateState }) => (
    <SelectDeselect updateState={updateState} filterItems={(each) => each.locationCounts > 0} />
  ),
  renderItem:
    ({ state, updateState }) =>
    (item) => (
      <IonItem key={item.id} lines="full">
        <IonLabel>
          {item.displayName}
          <br />
          {item.locationCountsCleared ? (
            <IonText color="success">
              <small>{t('cleared')}</small>
            </IonText>
          ) : (
            <IonText color="medium">
              <small>{`${t('locationsCountedOrdered:')} ${item.locationCounts}`}</small>
            </IonText>
          )}
        </IonLabel>
        <IonToggle
          checked={(state.selectedRowKeys ?? []).includes(item.id)}
          onIonChange={createSelectRowHandler({ each: item, updateState })}
          disabled={item.locationCounts === 0}
        />
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, setState, updateState } = self
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('clear'),
        handler: async () => {
          try {
            setState('loadingIsOpen', true)

            await Promise.all(
              (state.selectedRowKeys ?? []).map((each) =>
                removePrivateItemNoScope(
                  `${props.tenant.subdomain}.${each}.${IMPERSONATOR_USERNAME}.${LOCATION_COUNTS_SAVED_KEY}`
                )
              )
            )

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('customersClearedSuccessfully')
              draft.alertButtons = null
              draft.selectedRowKeys = []
              draft.items
                .filter((each) => (state.selectedRowKeys ?? []).includes(each.id))
                .forEach((one) => {
                  one.locationCounts = 0
                  one.locationCountsCleared = true
                })
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          }
        },
      },
    ]

    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.selectedRowKeys)}
        onClick={() => {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmClearCustomers')
            draft.alertButtons = buttons
          })
        }}
      >
        {t('clear')}
      </IonButton>
    )
  },
  allowSearch: (self) => true,
})()

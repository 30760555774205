import { createSelectionListViewContainer } from 'factories/SelectionListView'
import serviceStatusOptions from 'options/assets/serviceStatusOptions'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/FormView/SelectServiceStatus'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(
        Object.entries(serviceStatusOptions).map(([key, value]) => ({
          id: key,
          displayName: t(value),
        }))
      ),
  }),
})(Component)

import produce from 'immer'
import CryptoJS from 'crypto-js'
import { get, isEmpty, defaultTo, cloneDeep, remove, unset, sortBy, omit } from 'lodash'
import { IonItem, IonLabel, IonToggle, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { getPrivateItemNoScope, setSharedItemNoScope, updatePrivateItemNoScope } from 'helpers/localForage'
import { LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import { IMPERSONATOR_USERNAME } from 'options/auth'
import { sendClientLog } from 'helpers/auth'
import { asyncSleep, createSelectRowHandler, setLoadingMessage } from 'helpers/utils'
import { isUploadSuccessful } from 'helpers/locationCounts'
import SelectDeselect from 'elements/SelectDeselect'

export default createListViewComponent({
  getStorageKey: () => 'distributor.uploadCountsAndOrders',
  getViewDidEnterHandler:
    ({ props, updateState, isOnline }) =>
    async () => {
      try {
        const items = await props.getItems().then((r) => get(r, 'value.data.items', []))

        updateState((draft) => {
          draft.selectedRowKeys = items.filter((each) => each.locationCounts > 0).map((each) => each.id)
        })
      } catch (error) {
        showError({ error })
      }
    },
  getPopoverContent: ({ updateState }) => (
    <SelectDeselect updateState={updateState} filterItems={(each) => each.locationCounts > 0} />
  ),
  renderItem:
    ({ props, state, updateState, isOnline }) =>
    (item) => (
      <IonItem key={item.id} lines="full">
        <IonLabel>
          {item.displayName}
          <br />
          <IonText
            color={
              item.locationCountsUploadedWithErrors || item.locationCountsUploadFailed
                ? 'danger'
                : item.locationCountsUploadedSuccessfully
                  ? 'success'
                  : 'medium'
            }
          >
            {item.locationCountsUploadedWithErrors ? (
              <small>{t('uploadedWithErrors')}</small>
            ) : item.locationCountsUploadFailed ? (
              <small>{t('uploadFailed')}</small>
            ) : item.locationCountsUploadedSuccessfully ? (
              <small>{t('uploaded')}</small>
            ) : (
              <small>{`${t('locationsCountedOrdered:')} ${item.locationCounts}`}</small>
            )}
          </IonText>
        </IonLabel>
        <IonToggle
          checked={(state.selectedRowKeys ?? []).includes(item.id)}
          onIonChange={createSelectRowHandler({ each: item, updateState })}
          disabled={item.locationCounts === 0}
        />
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, setState, updateState } = self
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('upload'),
        handler: async () => {
          try {
            setState('loadingIsOpen', true)

            let customersUploadedSuccessfully = true
            const customerIds = state.selectedRowKeys ?? []

            for (const [customerIndex, customerId] of customerIds.entries()) {
              let locationCountsUploadedWithErrors = false
              let locationCountsUploadFailed = false

              const countedKey = `${props.tenant.subdomain}.${customerId}.${IMPERSONATOR_USERNAME}.${LOCATION_COUNTS_SAVED_KEY}`
              const unsortedItems = await getPrivateItemNoScope(countedKey, [])
              const items = sortBy(unsortedItems ?? [], ['completedDate', 'createdDate'])

              setLoadingMessage(
                `${t('uploading')} ${customerIndex + 1} ${t('of')} ${customerIds.length} ${t('customers')}`,
                true
              )

              for (const item of items) {
                try {
                  const payload = produce(item, (draft) => {
                    unset(draft, 'id')
                    unset(draft, 'locationCountId')

                    draft.status = 'Completed'
                    draft.source = 'TRMSMobile'
                    draft.customerId = customerId

                    draft.locationCountDetails.forEach((each) => {
                      unset(each, 'id')
                      unset(draft, 'locationCountId')

                      each.count = defaultTo(each.count, -1)
                      each.count2 = defaultTo(each.count2, -1)
                      each.customerId = customerId
                    })

                    draft.locationCountDetails = sortBy(draft.locationCountDetails, ['timeScanned'])
                  })
                  const hashable = JSON.stringify(omit(payload, ['orderId']))
                  const response = await props
                    .uploadAndCommit({
                      ...payload,
                      uploadHashValue: CryptoJS.SHA256(hashable).toString(CryptoJS.enc.Hex),
                    })
                    .then((r) => cloneDeep(r.value.data))

                  if (response.orderCreateStatus === 'Success') {
                    console.log('orderId:', response.orderId)
                    for (let retry = 1; retry <= 3; retry++) {
                      const success = await updatePrivateItemNoScope(countedKey, [], (draft) => {
                        draft.forEach((each) => {
                          if (each.id === item.id) {
                            each.orderId = response.orderId
                          }
                        })
                      })

                      if (success) {
                        break
                      } else {
                        await sendClientLog('Error', 'Upload Items: Save OrderID', { retry })
                        await asyncSleep(retry * 1000)
                      }
                    }
                  }

                  if (isUploadSuccessful(response)) {
                    for (let retry = 1; retry <= 3; retry++) {
                      const success = await updatePrivateItemNoScope(countedKey, [], (draft) => {
                        remove(draft, (each) => each.id === item.id)
                      })

                      if (success) {
                        break
                      } else {
                        await sendClientLog('Error', 'Upload Items: Distributor', { retry })
                        await asyncSleep(retry * 1000)
                      }
                    }
                  } else {
                    locationCountsUploadedWithErrors = true
                    customersUploadedSuccessfully = true
                  }
                } catch (error) {
                  locationCountsUploadFailed = true
                  customersUploadedSuccessfully = true
                }
              }

              await Promise.all([
                props
                  .getLocations({ customerId })
                  .then((r) =>
                    setSharedItemNoScope(
                      `${props.tenant.subdomain}.${customerId}.offlineData.locations`,
                      get(r, 'value.data.items', [])
                    )
                  ),
                props
                  .getLocationGroups({ customerId })
                  .then((r) =>
                    setSharedItemNoScope(
                      `${props.tenant.subdomain}.${customerId}.offlineData.locationGroups`,
                      get(r, 'value.data.items', [])
                    )
                  ),
              ])

              const locationCountsUploadedSuccessfully =
                !locationCountsUploadedWithErrors && !locationCountsUploadFailed

              updateState((draft) => {
                draft.items
                  .filter((each) => each.id === customerId)
                  .forEach((each) => {
                    each.locationCountsUploadedSuccessfully = locationCountsUploadedSuccessfully
                    each.locationCountsUploadedWithErrors = locationCountsUploadedWithErrors
                    each.locationCountsUploadFailed = locationCountsUploadFailed
                    if (locationCountsUploadedSuccessfully) {
                      each.locationCounts = 0
                    }
                  })
              })
            }

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = customersUploadedSuccessfully
                ? t('locationCountsUploadedSuccessfully')
                : t('locationCountsUploadedWithErrors')
              draft.alertButtons = null
              draft.selectedRowKeys = []
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        },
      },
    ]

    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.selectedRowKeys) || !self.isOnline}
        onClick={() => {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmUploadData')
            draft.alertButtons = buttons
          })
        }}
      >
        {t('upload')}
      </IonButton>
    )
  },
  allowSearch: (self) => true,
  allowOffline: (self) => false,
})()

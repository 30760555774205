import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonButton,
  IonLoading,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { set, isNil, isEmpty, pick, defaultTo, cloneDeep, omit } from 'lodash'
import { tryParseInt, filterKeys, tryParseFloat, PLACEHOLDER, DEBOUNCE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showValidationErrors } from 'helpers/errors'
import cycleCountTypes from 'options/cycleCounts/cycleCountTypes'
import Page from 'elements/Page'

export const getStorageKey = () => 'cycleCounts.create'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/inventory/cycleCounts'
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  function fetchItem() {
    setState('item', {
      locationGroupId: 0,
      locationId: 0,
      type: 'Audit',
    })
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (['Audit', 'Price'].includes(values.type)) {
      if (isNil(values.itemsToCount)) {
        errors.itemsToCount = t('errorMissingRequiredField')
      }

      if (isNil(values.elapsedDays)) {
        errors.elapsedDays = t('errorMissingRequiredField')
      }
    }

    if (values.type === 'Price') {
      if (isNil(values.minimumPrice)) {
        errors.minimumPrice = t('errorMissingRequiredField')
      }
    }

    if (values.type === 'RangeOfBarcodes') {
      if (isEmpty(values.firstBarcode)) {
        errors.firstBarcode = t('errorMissingRequiredField')
      }

      if (isEmpty(values.lastBarcode)) {
        errors.lastBarcode = t('errorMissingRequiredField')
      }
    }

    if (values.type === 'RangeOfBinLocations') {
      if (isEmpty(values.firstBinLocation)) {
        errors.firstBinLocation = t('errorMissingRequiredField')
      }

      if (isEmpty(values.lastBinLocation)) {
        errors.lastBinLocation = t('errorMissingRequiredField')
      }
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          const {
            locationId,
            type,
            itemsToCount = 0,
            elapsedDays,
            minimumPrice: basePrice,
            firstBarcode,
            lastBarcode,
            firstBinLocation,
            lastBinLocation,
          } = values

          setState('loadingIsOpen', true)

          const response = await props.createItem({
            locationId,
            type,
            itemsToCount: type !== 'Custom' ? itemsToCount : undefined,
            elapsedDays: defaultTo(elapsedDays, undefined),
            basePrice: defaultTo(basePrice, undefined),
            firstBarcode: defaultTo(firstBarcode, undefined),
            lastBarcode: defaultTo(lastBarcode, undefined),
            firstBinLocation: defaultTo(firstBinLocation, undefined),
            lastBinLocation: defaultTo(lastBinLocation, undefined),
          })

          window.location.href = `/inventory/cycleCounts/${response.value.data.id}`
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(async () => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      try {
        const { locationId, type, firstBarcode, lastBarcode, firstBinLocation, lastBinLocation } =
          sessionItem.item
        let itemsAtLocation = null
        let itemsToCount = null

        if (locationId) {
          itemsAtLocation = await props
            .getItemCount({
              locationId,
              unlockedInventoryOnly: true,
            })
            .then((r) => r.value.data)
        }

        if (type === 'RangeOfBarcodes' && firstBarcode && lastBarcode && locationId) {
          itemsToCount = await props
            .getItemCount({
              locationId,
              firstBarcode,
              lastBarcode,
              unlockedInventoryOnly: true,
            })
            .then((r) => r.value.data)
        }

        if (type === 'RangeOfBinLocations' && firstBinLocation && lastBinLocation && locationId) {
          itemsToCount = await props
            .getItemCount({
              locationId,
              firstBinLocation,
              lastBinLocation,
              unlockedInventoryOnly: true,
            })
            .then((r) => r.value.data)
        }

        updateState((draft) => {
          Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))

          draft.item.itemsAtLocation = itemsAtLocation
          draft.item.itemsToCount = itemsToCount
        })
      } catch (error) {
        showError({ error })
      }
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    ;(async function () {
      if (
        state.item?.type === 'RangeOfBarcodes' &&
        state.item?.firstBarcode &&
        state.item?.lastBarcode &&
        state.item?.locationId
      ) {
        try {
          const { locationId, firstBarcode, lastBarcode } = state.item

          const itemsToCount = await props
            .getItemCount({
              locationId,
              firstBarcode,
              lastBarcode,
              unlockedInventoryOnly: true,
            })
            .then((r) => r.value.data)

          setItemValue('itemsToCount', itemsToCount)
        } catch (error) {
          showError({ error })
        }
      } else {
        setItemValue('itemsToCount', null)
      }

      if (
        state.item?.type === 'RangeOfBinLocations' &&
        state.item?.firstBinLocation &&
        state.item?.lastBinLocation &&
        state.item?.locationId
      ) {
        try {
          const { locationId, firstBinLocation, lastBinLocation } = state.item

          const itemsToCount = await props
            .getItemCount({
              locationId,
              firstBinLocation,
              lastBinLocation,
              unlockedInventoryOnly: true,
            })
            .then((r) => r.value.data)

          setItemValue('itemsToCount', itemsToCount)
        } catch (error) {
          showError({ error })
        }
      } else {
        setItemValue('itemsToCount', null)
      }
    })()
  }, [
    state.item?.type,
    state.item?.firstBarcode,
    state.item?.lastBarcode,
    state.item?.firstBinLocation,
    state.item?.lastBinLocation,
  ])

  const pageTitle = t('createCycleCount')
  const backButtonHref = '/inventory/cycleCounts'

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
          {t('create')}
        </IonButton>
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item', {
          'tofino-error-item': state.errors?.locationGroupId,
        })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectLocationGroup`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('locationGroup')}</small>
          </IonText>
          <br />
          {state.item.locationGroupName || state.item.locationGroupId || t('all')}
        </IonLabel>
      </IonItem>
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item', {
          'tofino-error-item': state.errors?.locationId,
        })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectLocation`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('location')}</small>
          </IonText>
          <br />
          {state.item.locationName || state.item.locationId || (
            <span className="tofino-placeholder">{PLACEHOLDER}</span>
          )}
        </IonLabel>
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('itemsAtLocation')}</IonLabel>
        <IonInput value={state.item.itemsAtLocation} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item', { 'tofino-error-item': state.errors?.type })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectType`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('type')}</small>
          </IonText>
          <br />
          {t(cycleCountTypes[state.item.type])}
        </IonLabel>
      </IonItem>
      {['Audit', 'Price'].includes(state.item.type) && (
        <IonItem
          lines="full"
          className={cx('tofino-required-item', {
            'tofino-error-item': state.errors?.itemsToCount,
          })}
        >
          <IonLabel position="stacked">{t('itemsToCount')}</IonLabel>
          <IonInput
            value={state.item.itemsToCount}
            type="number"
            inputmode="number"
            inputMode="number"
            min={1}
            placeholder={PLACEHOLDER}
            onKeyPress={filterKeys(/\D/)}
            onIonInput={(e) => setItemValue('itemsToCount', tryParseInt(e.target.value, 1))}
            clearOnEdit
          />
        </IonItem>
      )}
      {['RangeOfBarcodes', 'RangeOfBinLocations'].includes(state.item.type) && (
        <IonItem lines="full">
          <IonLabel position="stacked">{t('itemsToCountForRangeType')}</IonLabel>
          <IonInput value={state.item.itemsToCount} placeholder={PLACEHOLDER} disabled />
        </IonItem>
      )}
      {['Audit', 'Price'].includes(state.item.type) && (
        <IonItem
          lines="full"
          className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.elapsedDays })}
        >
          <IonLabel position="stacked">{t('elapsedDays')}</IonLabel>
          <IonInput
            value={state.item.elapsedDays}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            onKeyPress={filterKeys(/\D/)}
            onIonInput={(e) => setItemValue('elapsedDays', tryParseInt(e.target.value, 0))}
            clearOnEdit
          />
        </IonItem>
      )}
      {state.item.type === 'Price' && (
        <IonItem
          lines="full"
          className={cx('tofino-required-item', {
            'tofino-error-item': state.errors?.minimumPrice,
          })}
        >
          <IonLabel position="stacked">{t('minimumPrice')} ($)</IonLabel>
          <IonInput
            value={state.item.minimumPrice}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            onIonInput={(e) => setItemValue('minimumPrice', tryParseFloat(e.target.value, 0.0))}
            clearOnEdit
          />
        </IonItem>
      )}
      {state.item.type === 'RangeOfBarcodes' && (
        <>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.firstBarcode,
            })}
          >
            <IonLabel position="stacked">{t('firstBarcode')}</IonLabel>
            <IonInput
              value={state.item.firstBarcode}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('firstBarcode', e.target.value)}
              debounce={DEBOUNCE}
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.lastBarcode,
            })}
          >
            <IonLabel position="stacked">{t('lastBarcode')}</IonLabel>
            <IonInput
              value={state.item.lastBarcode}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('lastBarcode', e.target.value)}
              debounce={DEBOUNCE}
            />
          </IonItem>
        </>
      )}
      {state.item.type === 'RangeOfBinLocations' && (
        <>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.firstBinLocation,
            })}
          >
            <IonLabel position="stacked">{t('firstBinLocation')}</IonLabel>
            <IonInput
              value={state.item.firstBinLocation}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('firstBinLocation', e.target.value)}
              debounce={DEBOUNCE}
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.lastBinLocation,
            })}
          >
            <IonLabel position="stacked">{t('lastBinLocation')}</IonLabel>
            <IonInput
              value={state.item.lastBinLocation}
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('lastBinLocation', e.target.value)}
              debounce={DEBOUNCE}
            />
          </IonItem>
        </>
      )}
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

import { isEmpty } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import inventoryActions from 'actions/inventory'
import { getSharedInventory, getSharedLocations } from 'helpers/offlineData'
import {
  wrapItems,
  sortItems,
  filterBySearch,
  filterLocationsByGroupIds,
  filterLocationsByVendingTypes,
} from 'helpers/utils'
import Component from 'components/Inventory/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(inventoryActions.getListViewItems(params)).catch(async (error) => {
        if (!error.response) {
          const { locationGroupIds = [], locationVendingTypes = [], locationIds = [] } = params
          const [items, locations] = await Promise.all([getSharedInventory(), getSharedLocations()])

          const filterLocationIds =
            isEmpty(locationIds) && (!isEmpty(locationGroupIds) || !isEmpty(locationVendingTypes))
              ? locations
                  .filter(filterLocationsByGroupIds(locationGroupIds))
                  .filter(filterLocationsByVendingTypes(locationVendingTypes))
                  .map((each) => each.id)
              : locationIds

          const unsortedItems = items
            .filter((each) => isEmpty(filterLocationIds) || filterLocationIds.includes(each.locationId))
            .filter(filterBySearch(params.search))

          return Promise.resolve(sortItems(unsortedItems, params.sortByField, params.sortOrder)).then(
            wrapItems
          )
        }

        throw error
      }),
    deleteItem: (id) => dispatch(inventoryActions.deleteItems([id])),
  }),
})(Component)

import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import { createGet, createPost } from 'helpers/api'
import entityNames from 'options/entityNames'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'

const entityName = entityNames.customers
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const defaultActions = createActions(entityName, {
  useSelectionList: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeCustomerIds =
        draft.alwaysIncludeCustomerIds === false ? false : !isEmpty(draft.customerIds)
    }),
})

export default {
  ...defaultActions,

  getHierarchy: (params) => ({
    type: `${ENTITY_NAME}_HIERARCHY_GET`,
    payload: createGet(entityName, { action: 'hierarchy' })(params),
  }),

  getNumberSelectionList: (params) => ({
    type: `${ENTITY_NAME}_NUMBER_SELECTION_LIST_GET`,
    payload: createPost(entityName, { action: 'numberSelectionList' })(params),
  }),

  updateReferenceDate: (params) => ({
    type: `${ENTITY_NAME}_REFERENCE_DATE_UPDATE`,
    payload: createPost(entityName, { action: `${params.id}/updateReferenceDate` })(params),
  }),

  getOptionsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.customers.options'

    try {
      const response = await dispatch(
        defaultActions.getOptions({ pageIndex: 0, mobileDownload: true, active: 'Active' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonToggle,
  IonLoading,
  IonPopover,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import moment from 'moment'
import { get, set, isNil, isEmpty, pick, camelCase, cloneDeep, toString as str, omit, trimStart } from 'lodash'
import { allowPricing, getUserPermission } from 'helpers/auth'
import { tryParseInt, getTagDisplayNameField, getTagName, tryParseFloat, PLACEHOLDER, message } from 'helpers/utils'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import {
  validateOperatorTag,
  validateJobTag,
  validateAssetTag,
  validateOtherTags,
  getTagFieldNames,
} from 'helpers/procurement'
import { getSessionItem, setSessionItem, removeSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import DatePicker from 'elements/DatePicker'
import Icon from 'elements/Icon'
import useOnlineStatus from '@rehooks/online-status'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  '3', // Asset
  'A',
  'B',
  'C',
  'D',
  'E',
]

export const getStorageKey = () => 'orders.formView.orderItems.formView'
const parentStorageKey = 'orders.formView'

export default function (props) {
  const quantityOrderedMin = props.customer.generalSettings.allowZeroQuantityOnOrderedItems ? 0 : 1

  const [state, updateState] = useImmer({ tabsActiveKey: 'item' })

  const isOnline = useOnlineStatus()

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      let item = null
      const parentState = getSessionItem(parentStorageKey)
      const parentRecord = { ...parentState.item, orderItems: parentState.orderItems }

      if (itemId) {
        item = cloneDeep(parentState.orderItems.find((one) => str(one.id) === itemId))
      } else {
        const values = getSessionItem('orders.addItems', {}).item

        item = await props
          .newItem({
            assetDisplayName: values.assetDisplayName,
            assetId: values.assetId,
            jobDisplayName: values.jobDisplayName,
            jobId: values.jobId,
            locationDisplayName: values.locationDisplayName,
            locationId: values.locationId,
            locationName: values.locationName,
            operatorDisplayName: values.operatorDisplayName,
            operatorId: values.operatorId,
            tagA: values.tagA,
            tagB: values.tagB,
            tagC: values.tagC,
            tagD: values.tagD,
            tagE: values.tagE,
            quantityOrdered: values.quantityOrdered || quantityOrderedMin,
            supplierId: 0,
            nonStock: true,
            quantityIssued: 0,
            quantityReceived: 0,
            price: 0,
            barcode: `SO${Date.now() * 10000 + 621355968000000000}`,
            packageSize: 1,
            userName: props.user.userName,
            populated: false,
          })
          .then((r) => r.value.data)
          .then(cloneDeep)
      }

      const assetName = trimStart(item.assetName, '*')
      item.assetDisplayName = item.assetId
        ? await props
            .getAsset(item.assetId)
            .then((r) => r.value.data.displayName)
            .catch(() => assetName)
        : assetName

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      updateState((draft) => {
        draft.item = item
        draft.parentState = parentState
        draft.parentRecord = parentRecord
      })
    } catch (error) {
      showError({ error })
    }
  }

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledPurch`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2', '3'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.barcode)) {
      errors.barcode = t('errorMissingRequiredField')
    }

    if (!values.quantityOrdered) {
      setItemValue('quantityOrdered', quantityOrderedMin)
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.itemNumber)) {
      errors.itemNumber = t('errorMissingRequiredField')
    }

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateAssetTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    values.quantityOrdered = Math.max(tryParseInt(values.quantityOrdered, 0), quantityOrderedMin)
    values.quantityReceived = Math.min(tryParseInt(values.quantityReceived, 0), values.quantityOrdered)

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const { copyTagValuesToAllItems } = values

          const orderItem = {
            ...values,
            quantityOrdered: values.quantityOrdered || quantityOrderedMin,
            assetName: values.assetName || `*${values.assetDisplayName}`,
            jobName: values.jobName || `*${values.jobDisplayName}`,
            locationName: values.locationName || `*${values.locationDisplayName}`,
            operatorName: values.operatorName || `*${values.operatorDisplayName}`,
            assetDisplayName: values.assetDisplayName,
            operatorDisplayName: values.operatorDisplayName,
            jobDisplayName: values.jobDisplayName,
            inventoryDisplayName: values.inventoryDisplayName,
            locationDisplayName: values.locationDisplayName,
            copyTagValuesToAllItems: false,
          }

          const response = await props.populateOrderItems([omit(orderItem, ['id', 'orderId'])])

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const populatedItem = cloneDeep(response.value.data.items[0])

          populatedItem.id = populatedItem.orderItemId = values.id || Date.now() * -1
          populatedItem.orderId = tryParseInt(props.match.params.parentId, 0)
          populatedItem.populated = true
          populatedItem.timestamp = new Date().toJSON()

          updateSessionItem(parentStorageKey, {}, (draft) => {
            try {
              const index = draft.orderItems.findIndex((one) => str(one.id) === props.match.params.itemId)

              if (index > -1) {
                draft.orderItems.splice(index, 1, populatedItem)
              } else {
                draft.orderItems.unshift(populatedItem)
              }

              message.success(`${populatedItem.barcode} ${t('addToOrderSuccess')}`)

              if (copyTagValuesToAllItems) {
                const item = draft.orderItems.find((one) => str(one.id) === props.match.params.itemId)
                const tagValues = pick(item, getTagFieldNames())
                draft.orderItems.filter((each) => each.id !== item.id).forEach((each) => Object.assign(each, tagValues))
              }

              props.history.goBack()
            } catch (error) {
              showError({ error })
            }
          })
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('orderItem')
  const backButtonHref = `/orders/orders/${props.match.params.parentId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const orderIsReadOnly = state.parentState?.readOnly || ['Draft', 'Cancelled'].includes(state.parentRecord.status)
  const orderIsEditable =
    !orderIsReadOnly &&
    state.parentRecord.status !== 'Processed' &&
    (['New', 'PendingApproval'].includes(state.parentRecord.status) ||
      props.customer.generalSettings.allowOrderEditAfterSend)
  const orderIsReceivable =
    ['Sent', 'Received', 'Processed'].includes(state.parentRecord.status) &&
    state.parentRecord.orderItems.some((one) => one.quantityOrdered > 0)
  const barcodeDisabled = !state.item.nonStock || state.item.id > 0
  const orderIsPartiallyReceived = state.parentRecord.orderItems.some((one) => one.quantityReceived > 0)
  const quantityReceivedDisabled =
    state.item.quantityOrdered <= 0 || !orderIsReceivable || getUserPermission('ReceiveOrders') === 'No'
  const notMultipleOfPackageSize =
    props.customer.generalSettings.orderCalculationType === 'Accumulate' &&
    state.item.quantityOrdered > 0 &&
    state.item.quantityOrdered % state.item.packageSize > 0
  const orderIsLocked = orderIsPartiallyReceived && !props.customer.generalSettings.allowItemEditAfterReceipt
  const allFieldsDisabled =
    orderIsLocked &&
    getUserPermission('Purchase') === 'No' &&
    getUserPermission('ReceiveOrders') === 'No' &&
    quantityReceivedDisabled
  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)
  const createDate = moment(state.parentRecord.createDate).startOf('day')

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        orderIsReadOnly || allFieldsDisabled ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
            {props.match.params.itemId ? t('update') : t('save')}
          </IonButton>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="item" tab={t('item')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.barcode,
            })}
          >
            <IonLabel position="stacked">{t('barcode')}</IonLabel>
            <IonInput
              value={state.item.barcode}
              onIonInput={(e) => setItemValue('barcode', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={orderIsReadOnly || barcodeDisabled}
            />
          </IonItem>
          {props.useInventoryBarcode && (
            <IonItem lines="full">
              <IonLabel position="stacked">{t('inventoryBarcode')}</IonLabel>
              <IonInput value={state.item.inventoryBarcode} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          )}
          {['itemNumber', 'description'].map((each) => (
            <IonItem
              key={each}
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': get(state, `errors.${each}`),
              })}
            >
              <IonLabel position="stacked">{t(each)}</IonLabel>
              <IonInput
                value={get(state, `item.${each}`)}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={orderIsReadOnly || !orderIsEditable}
              />
            </IonItem>
          ))}
          {state.item.quantityIssued > 0 && (
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('quantityIssued')}</IonLabel>
                  <IonInput value={state.item.quantityIssued} disabled />
                </IonItem>
              </IonCol>
              {allowPricing() && (
                <IonCol>
                  <IonItem lines="full">
                    <IonLabel position="stacked">{t('issuedPrice')} ($)</IonLabel>
                    <IonInput value={state.item.issuedPrice} disabled />
                  </IonItem>
                </IonCol>
              )}
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonItem
                lines="full"
                className={cx('tofino-required-item', {
                  'tofino-error-item': state.errors?.quantityOrdered,
                })}
              >
                <IonLabel position="stacked">{t('quantityOrdered')}</IonLabel>
                <IonInput
                  style={notMultipleOfPackageSize ? { color: 'red' } : null}
                  value={state.item.quantityOrdered}
                  onIonInput={(e) =>
                    setItemValue(
                      'quantityOrdered',
                      Math.max(tryParseInt(e.target.value, quantityOrderedMin), quantityOrderedMin)
                    )
                  }
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  placeholder={PLACEHOLDER}
                  min={0}
                  disabled={orderIsReadOnly || !orderIsEditable}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
            {allowPricing() && (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('orderedPrice')} ($)</IonLabel>
                  <IonInput
                    value={state.item.price}
                    onIonInput={(e) => setItemValue('price', tryParseFloat(e.target.value, 0.0))}
                    type="number"
                    inputmode="number"
                    inputMode="number"
                    placeholder={PLACEHOLDER}
                    min={0}
                    disabled={orderIsReadOnly || !orderIsEditable}
                    clearOnEdit
                  />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('received')}</IonLabel>
                <IonInput
                  value={state.item.quantityReceived}
                  onIonInput={(e) => setItemValue('quantityReceived', tryParseInt(e.target.value, 0))}
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  placeholder={PLACEHOLDER}
                  min={0}
                  disabled={orderIsReadOnly || quantityReceivedDisabled || state.item.lockedForCycleCount}
                  clearOnEdit
                />
                {state.item.lockedForCycleCount && (
                  <Icon
                    type="Lock"
                    color="danger"
                    size="20"
                    style={{ marginTop: '16px', marginRight: '-16px' }}
                    slot="end"
                    onClick={(e) =>
                      updateState((draft) => {
                        draft.popoverIsOpen = true
                        draft.popoverEvent = e
                        draft.popoverContent = (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t('onhandQuantitiesLockedForCycleCount'),
                            }}
                            style={{
                              color: 'var(--ion-color-black-tint)',
                              fontSize: 'var(--tofino-font-size-small)',
                            }}
                            className="ion-margin"
                          />
                        )
                      })
                    }
                  />
                )}
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('externalPO')}</IonLabel>
                <IonInput
                  value={state.item.externalPurchaseOrderNumber}
                  onIonInput={(e) => setItemValue('externalPurchaseOrderNumber', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={orderIsReadOnly || !orderIsEditable}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('package')}</IonLabel>
                <IonInput value={state.item.packageSizeUom} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('binLocation')}</IonLabel>
                <IonInput
                  value={state.item.binLocation}
                  onIonInput={(e) => setItemValue('binLocation', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={orderIsReadOnly || !orderIsEditable}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            disabled={orderIsReadOnly || !orderIsEditable}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('location')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectSupplier`)
            }}
            disabled={orderIsReadOnly || !orderIsEditable}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('supplier')}</small>
              </IonText>
              <br />
              {state.item.supplierName || state.item.supplierId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('supplierItemNumber')}</IonLabel>
            <IonInput
              value={state.item.supplierItemNumber}
              onIonInput={(e) => setItemValue('supplierItemNumber', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={orderIsReadOnly || !orderIsEditable}
            />
          </IonItem>
          <DatePicker
            label={t('dueDate')}
            value={createDate.clone().add(state.item.leadTime, 'days').toDate().toJSON()}
            onChange={(value) => setItemValue('leadTime', moment(value).startOf('day').diff(createDate, 'days'))}
            disabled={orderIsReadOnly || !orderIsEditable}
          />
          <IonItem lines="full">
            <IonLabel position="stacked">{t('manufacturer')}</IonLabel>
            <IonInput
              value={state.item.manufacturer}
              onIonInput={(e) => setItemValue('manufacturer', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={orderIsReadOnly || !orderIsEditable}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('source')}</IonLabel>
            <IonInput value={state.item.source} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('expedited')}</IonLabel>
            <IonToggle
              checked={state.item.expedite}
              onIonChange={(e) => setItemValue('expedite', e.detail.checked)}
              disabled={orderIsReadOnly || !orderIsEditable}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('taxable')}</IonLabel>
            <IonToggle
              checked={state.item.taxable}
              onIonChange={(e) => setItemValue('taxable', e.detail.checked)}
              disabled={orderIsReadOnly || !orderIsEditable}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('nonInventoryItem')}</IonLabel>
            <IonToggle
              checked={state.item.nonInventory}
              onIonChange={(e) => setItemValue('nonInventory', e.detail.checked)}
              disabled={orderIsReadOnly || !orderIsEditable || !state.item.nonStock || state.item.id > 0}
            />
          </IonItem>
        </Tabs.TabPane>
        {!isEmpty(enabledTagTypes) && (
          <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
            {enabledTagTypes.map((tagType) => (
              <IonItem
                key={tagType}
                lines="full"
                className={cx('tofino-stacked-item', {
                  'tofino-required-item': getTagIsRequired(tagType),
                  'tofino-error-item': get(state, `errors.${tagType}`),
                })}
                disabled={orderIsReadOnly || !orderIsEditable}
              >
                <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
                <IonInput
                  value={getTagDisplayName(tagType)}
                  placeholder={PLACEHOLDER}
                  onIonInput={handleTagInput(tagType)}
                  onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
                  disabled={orderIsReadOnly || !orderIsEditable}
                />
                {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
              </IonItem>
            ))}
            {!orderIsReadOnly && orderIsEditable ? (
              <IonItem lines="full">
                <IonLabel>{t('copyTagValuesToAllItems')}</IonLabel>
                <IonToggle
                  checked={state.item.copyTagValuesToAllItems}
                  onIonChange={(e) => setItemValue('copyTagValuesToAllItems', e.detail.checked)}
                />
              </IonItem>
            ) : null}
          </Tabs.TabPane>
        )}
      </Tabs>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {state.popoverContent}
      </IonPopover>
    </Page>
  )
}

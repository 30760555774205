import { connect } from 'react-redux'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { createAuthStateToProps } from 'helpers/auth'
import locationActions from 'actions/locations'
import { getSharedLocations } from 'helpers/offlineData'
import Component from 'components/Issue/IssueItem/SearchInventory/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getLocations: (params = {}) =>
    dispatch(locationActions.getItems({ locationVendingTypes: ['NonVending'], ...params })).catch(
      async (error) => {
        if (!error.response) {
          const items = await getSharedLocations().then((r) =>
            r
              .filter((each) => ['Primary', 'Secondary'].includes(each.locationType))
              .filter(filterBySearch(params.search))
          )

          return wrapItems(items)
        }

        throw error
      }
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

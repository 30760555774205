import { wrapItems } from 'helpers/utils'
import { getSessionItem } from 'helpers/sessionStorage'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import Component from 'components/Lists/ChildListView/SelectList'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: () => {
      const { items = [], listType = 'Product' } = getSessionItem('lists.childListView', {})

      return wrapItems(items.filter((each) => each.listType === listType))
    },
  }),
})(Component)

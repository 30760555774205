import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import JobRequestsListView from 'containers/JobRequests/ListView'
import JobRequestsFormView from 'containers/JobRequests/FormView'
import JobRequestsFormViewSelectLocation from 'containers/JobRequests/FormView/SelectLocation'
import JobRequestsFormViewSelectAsset from 'containers/JobRequests/FormView/SelectAsset'
import JobRequestsFormViewSelectAssetCategory from 'containers/JobRequests/FormView/SelectAssetCategory'
import JobRequestsFormViewCreateJob from 'containers/JobRequests/FormView/CreateJob'
import JobRequestsFormViewCreateJobSelectJobTemplate from 'containers/JobRequests/FormView/CreateJob/SelectJobTemplate'
import JobRequestsFormViewDecline from 'containers/JobRequests/FormView/Decline'
import JobsListView from 'containers/Jobs/ListView'
import JobsFormView from 'containers/Jobs/FormView'
import JobsCreateFromTemplate from 'containers/Jobs/FormView/CreateFromTemplate'
import JobsFormViewSelectLocation from 'containers/Jobs/FormView/SelectLocation'
import JobsFormViewSelectAssetCategory from 'containers/Jobs/FormView/SelectAssetCategory'
import JobsFormViewSelectAsset from 'containers/Jobs/FormView/SelectAsset'
import JobsFormViewSelectReason from 'containers/Jobs/FormView/SelectReason'
import JobsFormViewSelectAssignedOperator from 'containers/Jobs/FormView/SelectAssignedOperator'
import JobsFormViewSelectOperators from 'containers/Jobs/FormView/SelectOperators'
import JobsFormViewSelectStatus from 'containers/Jobs/FormView/SelectStatus'
import JobsFormViewSelectPriority from 'containers/Jobs/FormView/SelectPriority'
import JobsFormViewSelectNotifyUser from 'containers/Jobs/FormView/SelectNotifyUser'
import JobsChecklistFormView from 'containers/Jobs/Checklist/FormView'
import JobsLabourFormView from 'containers/Jobs/Labour/FormView'
import JobsLabourFormViewSelectLabourRate from 'containers/Jobs/Labour/FormView/SelectLabourRate'
import JobsLabourFormViewSelectLabourType from 'containers/Jobs/Labour/FormView/SelectLabourType'
import JobsLabourFormViewSelectOperator from 'containers/Jobs/Labour/FormView/SelectOperator'
import JobsLabourFormViewSelectSupplier from 'containers/Jobs/Labour/FormView/SelectSupplier'
import ListsChildListViewAddList from 'containers/Lists/ChildListView/AddList'
import ListsChildListViewSelectList from 'containers/Lists/ChildListView/SelectList'
import AssetWarrantiesFormView from 'containers/Assets/Warranties/FormView'
import AssetTolerancesFormView from 'containers/Assets/Tolerances/FormView'

const JobsTolerancesFormView = (props) => (
  <AssetTolerancesFormView parentStorageKey="jobs.formView" settingsType="jobs" {...props} />
)
const JobsWarrantiesFormView = (props) => (
  <AssetWarrantiesFormView parentStorageKey="jobs.formView" settingsType="jobs" {...props} />
)

const JobsFormViewAddList = (props) => <ListsChildListViewAddList entityType="Job" {...props} />

const JobsFormViewSelectList = (props) => <ListsChildListViewSelectList entityType="Job" {...props} />

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/jobs/jobRequests': JobRequestsListView,
    '/jobs/jobRequests/:itemId(\\d+)': JobRequestsFormView,
    '/jobs/jobRequests/:itemId(\\d+)/selectLocation': JobRequestsFormViewSelectLocation,
    '/jobs/jobRequests/:itemId(\\d+)/selectAsset': JobRequestsFormViewSelectAsset,
    '/jobs/jobRequests/:itemId(\\d+)/selectAssetCategory': JobRequestsFormViewSelectAssetCategory,
    '/jobs/jobRequests/:itemId(\\d+)/createJob': JobRequestsFormViewCreateJob,
    '/jobs/jobRequests/:itemId(\\d+)/createJob/selectJobTemplate':
      JobRequestsFormViewCreateJobSelectJobTemplate,
    '/jobs/jobRequests/:itemId(\\d+)/decline': JobRequestsFormViewDecline,
    '/jobs/jobs': JobsListView,
    '/jobs/jobs/0/createFromTemplate': JobsCreateFromTemplate,
    '/jobs/jobs/:itemId(\\d+)': JobsFormView,
    '/jobs/jobs/:itemId(\\d+)/selectLocation': JobsFormViewSelectLocation,
    '/jobs/jobs/:itemId(\\d+)/selectAssetCategory': JobsFormViewSelectAssetCategory,
    '/jobs/jobs/:itemId(\\d+)/selectAsset': JobsFormViewSelectAsset,
    '/jobs/jobs/:itemId(\\d+)/selectReason': JobsFormViewSelectReason,
    '/jobs/jobs/:itemId(\\d+)/selectAssignedOperator': JobsFormViewSelectAssignedOperator,
    '/jobs/jobs/:itemId(\\d+)/selectOperators': JobsFormViewSelectOperators,
    '/jobs/jobs/:itemId(\\d+)/selectPriority': JobsFormViewSelectPriority,
    '/jobs/jobs/:itemId(\\d+)/selectStatus': JobsFormViewSelectStatus,
    '/jobs/jobs/:itemId(\\d+)/selectNotifyUser': JobsFormViewSelectNotifyUser,
    '/jobs/jobs/:itemId(\\d+)/addList': JobsFormViewAddList,
    '/jobs/jobs/:itemId(\\d+)/selectList': JobsFormViewSelectList,
    '/jobs/jobs/:parentId(\\d+)/checklist/:itemId(-\\d+|\\d+)': JobsChecklistFormView,
    '/jobs/jobs/:parentId(\\d+)/labour/:itemId(-\\d+|\\d+)': JobsLabourFormView,
    '/jobs/jobs/:parentId(\\d+)/labour/:itemId(-\\d+|\\d+)/selectLabourRate':
      JobsLabourFormViewSelectLabourRate,
    '/jobs/jobs/:parentId(\\d+)/labour/:itemId(-\\d+|\\d+)/selectLabourType':
      JobsLabourFormViewSelectLabourType,
    '/jobs/jobs/:parentId(\\d+)/labour/:itemId(-\\d+|\\d+)/selectOperator': JobsLabourFormViewSelectOperator,
    '/jobs/jobs/:parentId(\\d+)/labour/:itemId(-\\d+|\\d+)/selectSupplier': JobsLabourFormViewSelectSupplier,
    '/jobs/jobs/:parentId(\\d+)/tolerances/:itemId(-\\d+|\\d+)': JobsTolerancesFormView,
    '/jobs/jobs/:parentId(\\d+)/warranties/:itemId(-\\d+|\\d+)': JobsWarrantiesFormView,
  }),
})

export default createTabViewContainer()(Component)

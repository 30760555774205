import * as React from 'react'
import styled from 'styled-components'
import cx from 'clsx'
import moment from 'moment'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonRow,
  IonCol,
  IonDatetime,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, toString as str, cloneDeep, isEmpty, isNil, pick, round, get, omit } from 'lodash'
import { PLACEHOLDER, tryParseFloat, tryParseInt } from 'helpers/utils'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { tryParseMoment, ZERO_HOUR } from 'helpers/dateTime'
import { showError, showValidationErrors } from 'helpers/errors'
import { formatValue } from 'helpers/listViews'
import { getSessionItem, setSessionItem, updateSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem } from 'helpers/localStorage'
import labourTypes from 'options/jobLabourTypes'
import Page from 'elements/Page'
import DatePicker from 'elements/DatePicker'
import Icon from 'elements/Icon'

const RadioContainer = styled.div`
  height: 68px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`

export const getStorageKey = () => 'jobs.formView.labour.formView'

export default function (props) {
  const { parentStorageKey = 'jobs.formView' } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)

      if (name === 'actualHours') {
        draft.item.timeSpan = 'N/A'
        draft.item.startTime = ZERO_HOUR.toJSON()
        draft.item.stopTime = ZERO_HOUR.toJSON()
      }

      if (name === 'timeType' && value === 'Duration') {
        draft.item.startTime = ZERO_HOUR.toJSON()
        draft.item.stopTime = ZERO_HOUR.toJSON()
      }

      if (name === 'startTime' || name === 'stopTime') {
        const startMoment = tryParseMoment(draft.item.startTime, ZERO_HOUR).year(2000).month(1).date(1)
        const stopMoment = tryParseMoment(draft.item.stopTime, ZERO_HOUR).year(2000).month(1).date(1)

        draft.item.actualHours = round(Math.abs(startMoment.diff(stopMoment, 'minutes')) / 60.0, 2)
      }

      const labourRate = draft.jobLabourRates.find((one) => one.id === draft.item.labourRateId)
      draft.item.totalCost = draft.item.actualHours * get(labourRate, 'rate', 0)
    })
  }, [])

  async function fetchItem() {
    try {
      const jobLabourRates = await props.getJobLabourRates({ pageIndex: 0 }).then((r) => r.value.data.items)
      const labourRateId =
        tryParseInt(getStorageItem('jobs.formView.labour.formView.labourRateId')) ||
        get(jobLabourRates, '[0].id')

      const parentState = getSessionItem(parentStorageKey)
      const item =
        props.match.params.itemId === '0'
          ? {
              id: Date.now() * -1,
              jobId: parentState.item.id,
              timeType: 'Duration',
              labourType: 'Operator',
              actualHours: 0,
              startTime: ZERO_HOUR.toJSON(),
              stopTime: ZERO_HOUR.toJSON(),
              labourDate: moment().startOf('day').add(12, 'hours').toJSON(),
              totalCost: 0,
              labourRateId,
              labourRateDescription: jobLabourRates.find((one) => one.id === labourRateId)?.displayName,
            }
          : parentState.labourItems.find((one) => str(one.id) === props.match.params.itemId)

      updateState((draft) => {
        draft.item = cloneDeep(item)
        draft.parentState = parentState
        draft.jobLabourRates = jobLabourRates
      })
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.labourRateId) {
      errors.labourRateId = t('errorMissingRequiredField')
    }

    if (!values.performedById) {
      errors.performedById = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function transformSaveItemParams(params = {}) {
    const { startTime, stopTime, timeType, actualHours, labourRateId, labourDate } = params
    const labourRate = state.jobLabourRates.find((one) => one.id === labourRateId)
    const startMoment = tryParseMoment(startTime, ZERO_HOUR).year(2000).month(1).date(1)
    const stopMoment = tryParseMoment(stopTime, ZERO_HOUR).year(2000).month(1).date(1)
    const hours =
      timeType === 'StartStop'
        ? round(Math.abs(startMoment.diff(stopMoment, 'minutes')) / 60.0, 2)
        : Math.max(actualHours, 0)

    return {
      startTime: startMoment.clone().toJSON(),
      stopTime: stopMoment.clone().toJSON(),
      actualHours: hours,
      timeSpan:
        timeType === 'StartStop'
          ? `${startMoment.format('h:mm A')} To ${stopMoment.format('h:mm A')}`
          : t('na'),
      labourRateDescription: labourRate
        ? `${labourRate.description} (${toLocaleCurrency(labourRate?.rate)}/hr)`
        : '',
      totalCost: hours * get(labourRate, 'rate', 0),
      labourDate: moment(labourDate).startOf('day').add(12, 'hours').toJSON(),
    }
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.labourItems.findIndex((one) => str(one.id) === props.match.params.itemId)
            const item = { ...values, ...transformSaveItemParams(values) }

            if (index > -1) {
              draft.labourItems.splice(index, 1, item)
            } else {
              draft.labourItems.unshift(item)
            }

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  if (isNil(state.item)) {
    return (
      <Page title={PLACEHOLDER}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const { readOnly } = state.parentState ?? {}
  const pageTitle =
    props.match.params.itemId === '0'
      ? t('addLabour')
      : `${state.item.id > 0 ? state.item.id : t('na')} - ${state.item.performedByName || t('na')}` ||
        PLACEHOLDER

  return (
    <Page
      title={pageTitle}
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
            {props.match.params.itemId === '0' ? t('add') : t('update')}
          </IonButton>
        )
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item', {
          'tofino-error-item': state.errors?.labourRateId,
        })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectLabourRate`)
        }}
        disabled={readOnly}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('labourRate')}</small>
          </IonText>
          <br />
          {state.item.labourRateDescription || state.item.labourRateId || (
            <IonText color="medium">{t('select')}</IonText>
          )}
        </IonLabel>
      </IonItem>
      <IonItem
        lines="full"
        className="tofino-stacked-item"
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectLabourType`)
        }}
        disabled={readOnly}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('labourType')}</small>
          </IonText>
          <br />
          {t(labourTypes[state.item.labourType])}
        </IonLabel>
      </IonItem>
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item', {
          'tofino-error-item': state.errors?.performedById,
        })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          if (state.item.labourType === 'Supplier') {
            props.history.push(`${props.match.url}/selectSupplier`)
          }
          if (state.item.labourType === 'Operator') {
            props.history.push(`${props.match.url}/selectOperator`)
          }
        }}
        disabled={readOnly}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('performedBy')}</small>
          </IonText>
          <br />
          {state.item.performedByName || state.item.performedById || (
            <IonText color="medium">{t('select')}</IonText>
          )}
        </IonLabel>
      </IonItem>
      <DatePicker
        label={t('labourDate')}
        value={state.item.labourDate}
        onChange={(value) => setItemValue('labourDate', value)}
        disabled={readOnly}
      />
      <IonRow>
        <IonCol size="2">
          <IonItem lines="none" onClick={() => setItemValue('timeType', 'StartStop')} disabled={readOnly}>
            <RadioContainer>
              <Icon.Radio checked={state.item.timeType === 'StartStop'} />
            </RadioContainer>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('from')}</IonLabel>
            <IonDatetime
              displayFormat="h:mm A"
              value={state.item.startTime}
              onIonChange={(e) => setItemValue('startTime', e.detail.value)}
              disabled={state.item.timeType !== 'StartStop'}
            />
            <Icon slot="end" type="AccessTime" style={{ marginTop: '25px', marginRight: '0' }} />
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('to')}</IonLabel>
            <IonDatetime
              displayFormat="h:mm A"
              value={state.item.stopTime}
              onIonChange={(e) => setItemValue('stopTime', e.detail.value)}
              disabled={state.item.timeType !== 'StartStop'}
            />
            <Icon slot="end" type="AccessTime" style={{ marginTop: '25px', marginRight: '0' }} />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="2">
          <IonItem lines="full" onClick={() => setItemValue('timeType', 'Duration')} disabled={readOnly}>
            <RadioContainer>
              <Icon.Radio checked={state.item.timeType === 'Duration'} />
            </RadioContainer>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">
              {t('duration')} ({t('hours')})
            </IonLabel>
            <IonInput
              type="number"
              inputmode="number"
              inputMode="number"
              value={state.item.actualHours}
              onIonInput={(e) => setItemValue('actualHours', tryParseFloat(e.target.value, 0))}
              min={0}
              disabled={state.item.timeType !== 'Duration'}
              clearOnEdit
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('totalCost')}</IonLabel>
        <IonInput value={formatValue({ value: state.item.totalCost, displayFormat: 'Float2' })} disabled />
      </IonItem>
    </Page>
  )
}

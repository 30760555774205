import * as React from 'react'
import { useImmer } from 'use-immer'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import {
  IonButton,
  IonSpinner,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonPopover,
  IonLoading,
  IonAlert,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react'
import { set, isNil, isEmpty, pick, cloneDeep, omit, get } from 'lodash'
import { tryParseInt, filterKeys, PLACEHOLDER, message } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Page from 'elements/Page'
import Icon from 'elements/Icon'

export const getStorageKey = () => 'inventory.formView.transfer'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = `/inventory/inventory/${props.match.params.itemId}`
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const { barcode, locationId } = await props.getItem(itemId).then((r) => r.value.data)

      const [moveData] = await props
        .getMoveData([
          {
            barcode,
            fromLocationId: locationId,
            toLocationId: state.item?.toLocationId ?? 0,
            onHandQuantityToMove: state.item?.onHandQuantityToMove ?? 0,
            onHand2QuantityToMove: state.item?.onHand2QuantityToMove ?? 0,
          },
        ])
        .then((r) => get(r, 'value.data.items', []))

      setState('item', moveData)
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.toLocationId) {
      errors.toLocationId = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        const onHandQuantityToMove =
          Math.min(values.fromOnHandQuantity ?? 0, values.onHandQuantityToMove ?? 0) ?? 0
        const onHand2QuantityToMove =
          Math.min(values.fromOnHand2Quantity ?? 0, values.onHand2QuantityToMove ?? 0) ?? 0
        const moveItems = async () => {
          try {
            setState('loadingIsOpen', true)

            const response = await props.moveItems([
              {
                barcode: values.barcode,
                fromLocationId: values.fromLocationId,
                toLocationId: values.toLocationId,
                onHandQuantityToMove,
                onHand2QuantityToMove,
              },
            ])

            setState('loadingIsOpen', false)
            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            props.history.goBack()
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          }
        }

        if (
          props.customer.generalSettings.enableFillToMax &&
          onHandQuantityToMove + onHand2QuantityToMove > values.fillToMaxQuantity
        ) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertHeader = t('confirmTransferQuantityTitle')
            draft.alertMessage = t('confirmTransferQuantity')
            draft.alertButtons = [
              {
                text: t('cancel'),
                role: 'cancel',
              },
              {
                text: t('transfer'),
                handler: () => moveItems(),
              },
            ]
          })
        } else {
          moveItems()
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('transferInventory')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const transferQuantity = Math.min(state.item.fromOnHandQuantity, state.item.onHandQuantityToMove)
  const deficiencyQuantity =
    state.item.fromOnHandQuantity < state.item.onHandQuantityToMove
      ? state.item.onHandQuantityToMove - state.item.fromOnHandQuantity
      : 0

  const transfer2Quantity = Math.min(state.item.fromOnHand2Quantity, state.item.onHand2QuantityToMove)
  const deficiency2Quantity =
    state.item.fromOnHand2Quantity < state.item.onHand2QuantityToMove
      ? state.item.onHand2QuantityToMove - state.item.fromOnHand2Quantity
      : 0

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        <IonButton
          onClick={(e) => {
            updateState((draft) => {
              draft.popoverIsOpen = true
              draft.popoverEvent = e
            })
          }}
          disabled={!isOnline}
        >
          <Icon type="Info" size="26" outlined />
        </IonButton>
      }
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
          {t('transfer')}
        </IonButton>
      }
    >
      <IonRow>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('onhand')}</IonLabel>
            <IonInput value={state.item.fromOnHandQuantity} disabled />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('onhand2')}</IonLabel>
              <IonInput value={state.item.fromOnHand2Quantity} disabled />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('quantity')}</IonLabel>
            <IonInput
              value={state.item.onHandQuantityToMove}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              onKeyPress={filterKeys(/\D/)}
              onIonInput={(e) => {
                if (!state.item.toLocationId && props.customer.generalSettings.enableFillToMax) {
                  message.error(t('selectToLocationFirst'))
                } else {
                  setState('item.onHandQuantityToMove', tryParseInt(e.target.value, 0))
                }
              }}
              clearOnEdit
            />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('quantity2')}</IonLabel>
              <IonInput
                value={state.item.onHand2QuantityToMove}
                type="number"
                inputmode="number"
                inputMode="number"
                min={0}
                placeholder={PLACEHOLDER}
                onKeyPress={filterKeys(/\D/)}
                onIonInput={(e) => {
                  if (!state.item.toLocationId && props.customer.generalSettings.enableFillToMax) {
                    message.error(t('selectToLocationFirst'))
                  } else {
                    setState('item.onHand2QuantityToMove', tryParseInt(e.target.value, 0))
                  }
                }}
                clearOnEdit
              />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('transfer')}</IonLabel>
            <IonInput value={transferQuantity} disabled />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('transfer2')}</IonLabel>
              <IonInput value={transfer2Quantity} disabled />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="full" className={cx({ 'tofino-error-item': deficiencyQuantity > 0 })}>
            <IonLabel position="stacked">{t('deficiency')}</IonLabel>
            <IonInput style={{ color: 'var(--ion-color-medium)' }} value={deficiencyQuantity} readonly />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full" className={cx({ 'tofino-error-item': deficiency2Quantity > 0 })}>
              <IonLabel position="stacked">{t('deficiency2')}</IonLabel>
              <IonInput style={{ color: 'var(--ion-color-medium)' }} value={deficiency2Quantity} readonly />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item ion-margin-bottom', {
          'tofino-error-item': state.errors?.toLocationId,
        })}
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectLocation`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('toLocation')}</small>
          </IonText>
          <br />
          {state.item.toLocationName || state.item.toLocationId || (
            <span className="tofino-placeholder">{PLACEHOLDER}</span>
          )}
        </IonLabel>
      </IonItem>
      {props.customer.generalSettings.enableFillToMax && (
        <>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('binLocation')}</IonLabel>
                <IonInput value={state.item.toBinLocation || PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('fillToMax')}</IonLabel>
                <IonInput value={state.item.fillToMaxQuantity} disabled />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('min')}</IonLabel>
                <IonInput value={state.item.toMin} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('max')}</IonLabel>
                <IonInput value={state.item.toMax} disabled />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('onHand')}</IonLabel>
                <IonInput value={state.item.toOnHandQuantity} disabled />
              </IonItem>
            </IonCol>
            {props.scannerSettings.showCount2 && (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('onHand2')}</IonLabel>
                  <IonInput value={state.item.toOnHand2Quantity} disabled />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
        </>
      )}
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <dl className="ion-margin tofino-stacked-list">
          <dt>{t('barcode')}</dt>
          <dd>{state.item.barcode || '---'}</dd>
          {props.useInventoryBarcode && (
            <>
              <dt>{t('inventoryBarcode')}</dt>
              <dd>{state.item.inventoryBarcode || '---'}</dd>
            </>
          )}
          <dt>{t('description')}</dt>
          <dd>{state.item.itemDescription || '---'}</dd>
          <dt>{t('fromLocation')}</dt>
          <dd>{state.item.fromLocationName || '---'}</dd>
        </dl>
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

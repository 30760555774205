import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import ManageCustomers from 'containers/Distributor/ManageCustomers'
import UploadItems from 'containers/Distributor/UploadItems'
import ClearCustomers from 'containers/Distributor/ClearCustomers'

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/distributor/manageCustomers': ManageCustomers,
    '/distributor/uploadCountsAndOrders': UploadItems,
    '/distributor/clearCustomers': ClearCustomers,
  }),
})

export default createTabViewContainer()(Component)

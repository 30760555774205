import produce from 'immer'
import { isNil } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { getPrivateItem } from 'helpers/localForage'
import { getStoredInventoryForLocationId } from 'helpers/locationCounts'
import Component from 'components/LocationCounts/CurrentCount/CheckMustCount'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const { locationId, locationCountDetails } = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)

      return getStoredInventoryForLocationId(locationId)
        .then((r) =>
          r
            .filter((each) => each.mustCount && !each.lockedForCycleCount)
            .map((each) =>
              produce(each, (draft) => {
                draft.isCounted = locationCountDetails.some(
                  (one) => one.inventoryId === draft.id && (!isNil(one.count) || !isNil(one.count2))
                )
              })
            )
            .filter((each) => {
              const { countStatus } = params

              if (countStatus === 'Counted') {
                return each.isCounted
              }

              if (countStatus === 'NotCounted') {
                return !each.isCounted
              }

              return true
            })
            .filter(filterBySearch(params.search))
        )
        .then(wrapItems)
    },
  }),
})(Component)

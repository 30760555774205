import { isEmpty, debounce, camelCase } from 'lodash'
import { findUniqOrFirst, strEqual, message, DEBOUNCE } from 'helpers/utils'
import { getChangedItems } from 'helpers/formViews'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'

export const getReceivedItems = (originals = [], items = []) => {
  const { creating, updating } = getChangedItems(originals, items)

  return [
    ...creating.filter((each) => each.quantityReceived > 0),
    ...updating.filter((each) => originals.find((one) => one.id === each.id)?.quantityReceived < each.quantityReceived),
  ]
}

export const recalculateQuantityOrdered = (each, customer) =>
  each.scanType === 'Package' || (each.scanType === 'Default' && customer.generalSettings.scanType === 'Package')
    ? each.quantityOrdered * each.packageSize
    : each.quantityOrdered

export const recalculateQuantity = (each, customer) =>
  each.scanType === 'Package' || (each.scanType === 'Default' && customer.generalSettings.scanType === 'Package')
    ? each.quantity * each.packageSize
    : each.quantity

export const calculateRequisitionTotals = ({ customer, item: requisition, requisitionItems }) => {
  const exemptTotal = requisitionItems
    .filter((each) => !each.taxable)
    .reduce((total, each) => total + each.quantity * each.price, 0)

  const taxableTotal = requisitionItems
    .filter((each) => each.taxable)
    .reduce((total, each) => total + each.quantity * each.price, 0)

  const taxTotal = customer.moduleSettings.enableTax ? taxableTotal * requisition.taxRate : 0

  return {
    exemptTotal,
    taxableTotal,
    taxTotal,
    requisitionTotal: exemptTotal + taxableTotal + taxTotal,
  }
}

export const calculateOrderTotals = ({ customer, item: order, orderItems, orderSupplierItems = [] }) => {
  const exemptTotal = orderItems
    .filter((each) => !each.taxable)
    .reduce((total, each) => total + each.quantityOrdered * each.price, 0)

  const taxableTotal = orderItems
    .filter((each) => each.taxable)
    .reduce((total, each) => total + each.quantityOrdered * each.price, 0)

  const taxTotal = customer.moduleSettings.enableTax ? taxableTotal * order.taxRate : 0

  const subTotal = exemptTotal + taxableTotal + taxTotal

  const adjustmentsTotal = orderSupplierItems.map((each) => each.adjustments).reduce((total, each) => total + each, 0)

  const freightTotal = orderSupplierItems.map((each) => each.freight).reduce((total, each) => total + each, 0)

  return {
    exemptTotal,
    taxableTotal,
    taxTotal,
    subTotal,
    adjustmentsTotal,
    freightTotal,
    orderTotal: subTotal + adjustmentsTotal + freightTotal,
  }
}

export const validateOperatorTag = async ({ props, values, errors, getTagIsRestricted, getTagIsRequired }) => {
  // Operator (restricted)
  if (values.operatorId || !isEmpty(values.operatorDisplayName)) {
    try {
      const operators = await props
        .getOperators({
          search: values.operatorDisplayName,
          operatorIds: values.operatorId ? [values.operatorId] : [],
        })
        .then((r) => r.value.data.items)

      const operator = values.operatorId
        ? operators.find((one) => one.id === values.operatorId)
        : findUniqOrFirst(operators, 'displayName', values.operatorDisplayName)

      if (operator) {
        values.operatorId = operator.id
        values.operatorName = operator.name
        values.operatorNumber = operator.number
        values.operatorBarcode = operator.barcode
        values.operatorDisplayName = operator.displayName
      } else if (getTagIsRestricted('2')) {
        errors['2'] = t('errorMissingRequiredField')
      }
    } catch (error) {
      showError({ error })

      if (getTagIsRestricted('2')) {
        errors['2'] = t('errorMissingRequiredField')
      }
    }
  }

  // Operator (required)
  if (getTagIsRequired('2') && isEmpty(values.operatorDisplayName)) {
    errors['2'] = t('errorMissingRequiredField')
  }
}

export const validateJobTag = async ({ props, values, errors, getTagIsRestricted, getTagIsRequired }) => {
  // Job (restricted)
  if (values.jobId || !isEmpty(values.jobDisplayName)) {
    try {
      const jobs = await props
        .getJobs({
          search: values.jobDisplayName,
          jobIds: values.jobId ? [values.jobId] : [],
        })
        .then((r) => r.value.data.items)

      const job = values.jobId
        ? jobs.find((one) => one.id === values.jobId)
        : findUniqOrFirst(jobs, 'displayName', values.jobDisplayName)

      if (job) {
        values.jobId = job.id
        values.jobName = job.name
        values.jobNumber = job.number
        values.jobBarcode = job.barcode
        values.jobDisplayName = job.displayName
      } else if (getTagIsRestricted('1')) {
        errors['1'] = t('errorMissingRequiredField')
      }
    } catch (error) {
      showError({ error })

      if (getTagIsRestricted('1')) {
        errors['1'] = t('errorMissingRequiredField')
      }
    }
  }

  // Job (required)
  if (getTagIsRequired('1') && isEmpty(values.jobDisplayName)) {
    errors['1'] = t('errorMissingRequiredField')
  }
}

export const validateAssetTag = async ({ props, values, errors, getTagIsRestricted, getTagIsRequired }) => {
  // Asset (restricted)
  if (values.assetId || !isEmpty(values.assetDisplayName)) {
    try {
      const assets = await props
        .getAssets({
          search: values.assetDisplayName,
          assetIds: values.assetId ? [values.assetId] : [],
        })
        .then((r) => r.value.data.items)

      const asset = values.assetId
        ? assets.find((one) => one.id === values.assetId)
        : findUniqOrFirst(assets, 'displayName', values.assetDisplayName)

      if (asset) {
        values.assetId = asset.id
        values.assetName = asset.name
        values.assetNumber = asset.number
        values.assetBarcode = asset.barcode
        values.assetDisplayName = asset.displayName
      } else if (getTagIsRestricted('3')) {
        errors['3'] = t('errorMissingRequiredField')
      }
    } catch (error) {
      showError({ error })

      if (getTagIsRestricted('3')) {
        errors['3'] = t('errorMissingRequiredField')
      }
    }
  }

  // Asset (required)
  if (getTagIsRequired('3') && isEmpty(values.assetDisplayName)) {
    errors['3'] = t('errorMissingRequiredField')
  }
}

export const validateOtherTags = async ({ props, values, errors, getTagIsRestricted, getTagIsRequired }) => {
  // Other tags
  for (const tagType of ['A', 'B', 'C', 'D', 'E']) {
    if (!isEmpty(values[`tag${tagType}`])) {
      try {
        const results = await props
          .getTagLists()
          .then((r) => r.value.data.items.filter((each) => each.tagType === tagType))

        const tagListItem = results.find((one) => strEqual(one.description, values[`tag${tagType}`]))

        if (tagListItem) {
          values[`tag${tagType}`] = tagListItem.description
        } else if (getTagIsRestricted(tagType)) {
          errors[tagType] = t('errorMissingRequiredField')
        }
      } catch (error) {
        showError({ error })

        if (getTagIsRestricted(tagType)) {
          errors[tagType] = t('errorMissingRequiredField')
        }
      }
    }

    if (getTagIsRequired(tagType) && isEmpty(values[`tag${tagType}`])) {
      errors[tagType] = t('errorMissingRequiredField')
    }
  }
}

export const validateQuantityIssued = debounce((quantity, value) => {
  if (value > quantity) {
    message.error(t('errorQuantityIssued'))
  }
}, DEBOUNCE)

export const validateQuantityRequisitioned = debounce((quantityIssued, value) => {
  if (value < quantityIssued) {
    message.error(t('errorQuantityRequisitioned'))
  }
}, DEBOUNCE)

export const getTagFieldNames = () => [
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`job-${each}`)),
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`asset-${each}`)),
  ...['id', 'barcode', 'displayName', 'name', 'number'].map((each) => camelCase(`operator-${each}`)),
  'tagA',
  'tagB',
  'tagC',
  'tagD',
  'tagD',
  'tagE',
]

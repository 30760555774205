import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/auth'
import { IonButton } from '@ionic/react'
import Icon from 'elements/Icon'

function Component(props) {
  return props.impersonator ? (
    <IonButton onClick={props.exitAsCustomer}>
      <Icon type="ExitToApp" size="26" />
    </IonButton>
  ) : null
}

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

export default connect(mapStateToProps, { exitAsCustomer: actions.exitAsCustomer })(Component)

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'supplier',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.orderSupplierId,
  getOnChange: (self) => async (each) => {
    const { barcode, nonStock } = getSessionItem(getStorageKey(), {}).item

    const supplierDetailItems = !nonStock
      ? (await self.props
          .findByBarcode({ barcode })
          .then((r) => self.props.getSupplierDetailItems(r.value.data.id))
          .catch(() => {})) ?? null
      : null

    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.orderSupplierId = each.id
      draft.item.orderSupplierName = each.displayName

      if (each.id && !draft.item.nonStock) {
        const supplierDetailItem = (supplierDetailItems?.value?.data?.items ?? []).find(
          (one) => one.supplierId === each.id
        )

        if (supplierDetailItem) {
          const { supplierItemNumber, price } = supplierDetailItem

          Object.assign(draft.item, { supplierItemNumber, price })
        } else {
          draft.item.supplierItemNumber = ''
        }
      } else {
        draft.item.supplierItemNumber = ''
      }
    })
  },
})()

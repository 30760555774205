import { createListViewContainer } from 'factories/ListView'
import jobActions from 'actions/jobs'
import Component from 'components/Jobs/FormView/CreateFromTemplate'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(jobActions.getListViewItems({ isTemplate: true, ...params })),
    createFromTemplate: (id, params) => dispatch(jobActions.createFromTemplate(id, params)),
  }),
})(Component)

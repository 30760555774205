import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import locationVendingTypeOptions from 'options/locationVendingTypes'
import ModalSelect from 'elements/ModalSelect'
import { filterLocationsByGroupIds, filterLocationsByVendingTypes } from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray, getMultiSelectDisplayNameFromObject } from 'helpers/formViews'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([props.getLocationGroups(), props.getLocations()])

      updateState((draft) => {
        draft.locationGroups = get(responses[0], 'value.data.items', [])
        draft.locations = get(responses[1], 'value.data.items', [])
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const locationGroupIds = filterDto?.locationGroupIds ?? []
  const locationVendingTypes = filterDto?.locationVendingTypes ?? []
  const locationIds = filterDto?.locationIds ?? []

  if (state.activeView === 'locationGroupIds') {
    return (
      <ModalSelect
        title={t('locationGroup')}
        options={(state.locationGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationGroupIds}
        onChange={(values) => {
          assignFilter({
            locationGroupIds: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationVendingTypes') {
    return (
      <ModalSelect
        title={t('locationType')}
        options={Object.entries(locationVendingTypeOptions)
          .filter((each) => each[0] !== 'All')
          .map((each) => ({
            value: each[0],
            text: t(each[1]),
          }))}
        value={locationVendingTypes}
        onChange={(values) => {
          assignFilter({
            locationVendingTypes: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationIds') {
    return (
      <ModalSelect
        title={t('location')}
        options={(state.locations ?? [])
          .filter(filterLocationsByGroupIds(locationGroupIds))
          .filter(filterLocationsByVendingTypes(locationVendingTypes))
          .map((each) => ({
            value: each.id,
            text: each.displayName,
          }))}
        value={locationIds}
        onChange={(values) => setFilter('locationIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locationGroups, locationGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationVendingTypes')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationType')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(locationVendingTypeOptions, locationVendingTypes)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locations, locationIds)}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  )
}

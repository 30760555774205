import { createListViewContainer } from 'factories/ListView'
import assetActions from 'actions/assets'
import locationActions from 'actions/locations'
import Component from 'components/Assets/ListView/MoveAssets'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params) =>
      dispatch(locationActions.getOptions({ ...params, locationVendingTypes: ['NonVending'] })),
    moveAssets: (params) => dispatch(assetActions.moveAssets(params)),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import jobRequestActions from 'actions/jobRequests'
import Component from 'components/JobRequests/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params) => dispatch(jobRequestActions.getListViewItems(params)),
    deleteItem: (id) => dispatch(jobRequestActions.deleteItems([id])),
  }),
})(Component)

import { capitalize } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getDownloadTypeTimestamp, trimMobileDownloadItems } from 'helpers/offlineData'
import locationActions from 'actions/locations'
import locationGroupActions from 'actions/locationGroups'
import inventoryActions from 'actions/inventory'
import productActions from 'actions/products'
import supplierActions from 'actions/suppliers'
import jobActions from 'actions/jobs'
import jobGroupActions from 'actions/jobGroups'
import jobReasonGroupActions from 'actions/jobReasonGroups'
import jobReasonActions from 'actions/jobReasons'
import jobStatusOptionActions from 'actions/jobStatusOptions'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import orderActions from 'actions/orders'
import userActions from 'actions/users'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import offlineDataTypes from 'options/offlineDataTypes'
import entityNames from 'options/entityNames'
import Component from 'components/Download/Customer'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const downloadTimestamps = await Promise.all(
        Object.keys(offlineDataTypes).map(getDownloadTypeTimestamp)
      )

      return Promise.resolve(
        Object.entries(offlineDataTypes).map(([key, value], index) => ({
          id: key,
          displayName: capitalize(t(value)),
          downloadTimestamp: downloadTimestamps[index],
          disabled: key !== 'inventory',
        }))
      ).then(wrapItems)
    },
    getProducts: (params) =>
      dispatch(
        productActions.getItems({
          pageIndex: 0,
          mobileDownload: true,
          active: 'Active',
          ...params,
        })
      ).then(trimMobileDownloadItems(entityNames.products)),
    getLocations: (params) =>
      dispatch(
        locationActions.getItems({
          pageIndex: 0,
          mobileDownload: true,
          ...params,
        })
      ).then(trimMobileDownloadItems(entityNames.locations)),
    getLocationGroups: (params) =>
      dispatch(locationGroupActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
    getInventory: (params) =>
      dispatch(
        inventoryActions.getItems({
          pageIndex: 0,
          mobileDownload: true,
          ...params,
        })
      ).then(trimMobileDownloadItems(entityNames.inventory)),
    getSuppliers: (params) =>
      dispatch(supplierActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
    getSupplierOptions: (params) =>
      dispatch(
        supplierActions.getOptions({
          pageIndex: 0,
          mobileDownload: true,
          active: 'Active',
          ...params,
        })
      ),
    getJobs: (params) =>
      dispatch(
        jobActions.getItems({
          pageIndex: 1,
          pageSize: 200,
          mobileDownload: true,
          isTemplate: false,
          isFuture: false,
          active: 'All',
          ...params,
        })
      ),
    getJobOptions: (params) =>
      dispatch(
        jobActions.getOptions({
          pageIndex: 0,
          mobileDownload: true,
          closed: false,
          isTemplate: false,
          isFuture: false,
          active: 'All',
          dateRangeField: 'All',
          ...params,
        })
      ),
    getJobGroupOptions: (params) =>
      dispatch(jobGroupActions.getOptions({ pageIndex: 0, mobileDownload: true, ...params })),
    getJobReasonGroupOptions: (params) =>
      dispatch(jobReasonGroupActions.getOptions({ pageIndex: 0, mobileDownload: true, ...params })),
    getJobReasonOptions: (params) =>
      dispatch(jobReasonActions.getOptions({ pageIndex: 0, mobileDownload: true, ...params })),
    getJobStatusOptions: (params) =>
      dispatch(jobStatusOptionActions.getOptions({ pageIndex: 0, mobileDownload: true, ...params })),
    getAssetCategories: (params) =>
      dispatch(assetCategoryActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
    getAssets: (params) => dispatch(assetActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
    getAssetOptions: (params) =>
      dispatch(assetActions.getOptions({ pageIndex: 0, mobileDownload: true, ...params })),
    getOrders: (params) => dispatch(orderActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
    getUserOptions: (params) =>
      dispatch(userActions.getOptions({ pageIndex: 0, mobileDownload: true, active: 'Active', ...params })),
    getOperatorOptions: (params) =>
      dispatch(
        operatorActions.getOptions({
          pageIndex: 0,
          mobileDownload: true,
          active: 'Active',
          ...params,
        })
      ),
    getTagLists: (params) =>
      dispatch(tagListActions.getItems({ pageIndex: 0, mobileDownload: true, tagType: 'All', ...params })),
  }),
})(Component)

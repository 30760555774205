import { createSelectionListViewContainer } from 'factories/SelectionListView'
import cycleCountTypes from 'options/cycleCounts/cycleCountTypes'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/CycleCounts/Create/SelectType'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(
        Object.entries(cycleCountTypes)
          .filter(([key, _]) => key !== 'All')
          .map(([key, value]) => ({ id: key, displayName: t(value) }))
      ),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import { LOCATION_COUNTS_UPLOAD_RESULTS_KEY } from 'options/locationCounts'
import { getPrivateItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'
import Component from 'components/LocationCounts/UploadResults'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: () => getPrivateItem(LOCATION_COUNTS_UPLOAD_RESULTS_KEY).then(wrapItems),
  }),
})(Component)

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import cycleCountActions from 'actions/cycleCounts'
import Component from 'components/CycleCounts/Split'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getItem: cycleCountActions.getItem,
  getSplitDistributionText: cycleCountActions.getSplitDistributionText,
  splitCycleCount: cycleCountActions.splitCycleCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

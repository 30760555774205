import { createFormViewContainer } from 'factories/FormView'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/assets'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import assetToleranceActions from 'actions/assets/assetTolerances'
import documentActions from 'actions/documents'
import assetHistoryActions from 'actions/assets/assetHistory'
import Component from 'components/TrackAssets/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    updateItem: (params) => dispatch(actions.updateTracking(params)),
    getJob: (jobId) => dispatch(jobActions.getItem(jobId)),
    getJobs: (params) => dispatch(jobActions.getOptions({ pageIndex: 0, ...params })),
    getOperator: (operatorId) => dispatch(operatorActions.getItem(operatorId)),
    getOperators: (params) => dispatch(operatorActions.getOptions({ pageIndex: 0, ...params })),
    getTagLists: () => dispatch(tagListActions.getItemsOffline()),
    createTolerancesHistoryItems: (parentId, items) =>
      dispatch(assetToleranceActions.createHistoryItems(parentId, items)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
    ...createChildItemsDispatchToProps(dispatch, 'History', assetHistoryActions),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import productActions from 'actions/products'
import Component from 'components/Inventory/Create'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(
        productActions.getListViewItems({
          ...params,
          active: 'Active',
        })
      ),
    copyToPrivate: (params) => dispatch(productActions.copyToPrivate(params)),
  }),
})(Component)

import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { IonRow, IonCol, IonButton } from '@ionic/react'

const Container = styled(IonRow)`
  margin-bottom: -0.5px;

  ion-button {
    font-size: var(--tofino-font-size-regular);
    border-right: none;

    &[color='secondary'] {
      border: 1px solid var(--ion-color-secondary);
    }
  }
`

export default function (props) {
  const { onChange = () => {}, items = [], disabled, ...rest } = props

  return !isEmpty(items) ? (
    <Container {...rest}>
      {items.map((each) => (
        <IonCol key={each.value}>
          <IonButton
            expand="full"
            onClick={() => onChange(each.value)}
            color={each.value === props.value ? 'secondary' : 'transparent'}
            size="small"
            disabled={each.disabled || disabled}
          >
            {each.text}
          </IonButton>
        </IonCol>
      ))}
    </Container>
  ) : null
}

import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost } from 'helpers/api'

const entityName = entityNames.jobs
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName, {
    useSelectionList: true,
    transformGetItemsParams: (params = {}) =>
      produce(params, (draft) => {
        draft.locationVendingTypes = ['NonVending']
        draft.approvalStatus = draft.approvalStatus ?? 'All'
        draft.overdueStatus = draft.overdueStatus ?? 'All'
        draft.isFuture = draft.isFuture ?? false
        draft.alwaysIncludeJobIds = draft.alwaysIncludeJobIds === false ? false : !isEmpty(draft.jobIds)
        draft.active = draft.active ?? 'Active'
        draft.assignedToId = draft.assignedToId ?? -1

        normalizeDateRange(draft)
      }),
  }),

  requestApprovalToBegin: (jobId) => ({
    type: `${ENTITY_NAME}_REQUEST_BEGIN_APPROVAL`,
    payload: createPost(entityName, { action: `${jobId}/approvalRequest?type=RequestToBegin` })(),
  }),

  requestApprovalToClose: (jobId) => ({
    type: `${ENTITY_NAME}_REQUEST_CLOSE_APPROVAL`,
    payload: createPost(entityName, { action: `${jobId}/approvalRequest?type=RequestToClose` })(),
  }),

  generateId: (params) => ({
    type: `${ENTITY_NAME}_GENERATE_ID`,
    payload: createPost(entityName, { action: '?generateId=true' })(params),
  }),

  createFromTemplate: (jobId, params = {}) => ({
    type: `${ENTITY_NAME}_CREATE_FROM_TEMPLATE`,
    payload: createPost(entityName, { action: `${jobId}/createFromTemplate` })(params),
  }),

  getCalendarList: (params = {}) => ({
    type: `${ENTITY_NAME}_CALENDAR_LIST_GET`,
    payload: createPost(entityName, { action: 'calendarList' })(params),
  }),
}

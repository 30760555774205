import { isNil } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import Filter from 'containers/DraftOrder/Items/Filter'
import Icon from 'elements/Icon'

export default createChildListViewComponent({
  getStorageKey: () => 'inventory.issue.draftOrder.orderItems',
  allowSearch: () => true,
  allowDelete: (self) => (item) => true,
  filterItems: (self) => (each) => {
    const { supplierId } = self.state.filterDto ?? {}
    const usagePurchase = self.state.filterDto?.usagePurchase ?? 'All'

    if (!isNil(supplierId) && each.supplierId !== supplierId) {
      return false
    }

    if (usagePurchase === 'UsageOnly' && each.quantityUsed === 0 && each.quantityUsed2 === 0) {
      return false
    }

    if (usagePurchase === 'PurchaseOnly' && each.quantityOrdered === 0) {
      return false
    }

    return true
  },
  renderItem:
    ({ props }) =>
    (each) => (
      <IonItem lines="full" onClick={() => props.onClick(each)} detail>
        <IonLabel>
          {each.expedite && <Icon type="priority_high" color="danger" size="16" className="ml-neg-5" />}
          {props.useInventoryBarcode ? each.inventoryBarcode || each.barcode : each.barcode}
          <br />
          <IonText color="medium">
            <small>
              {each.description}
              <br />
              {each.locationName}, {t('issued:')} {each.quantityIssued}, {t('ordered:')}{' '}
              {each.quantityOrdered}
              <br />
              {t('supplier:')} {each.supplierName}
            </small>
          </IonText>
        </IonLabel>
      </IonItem>
    ),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'quantityIssued', languageKey: 'quantityIssued' },
    { fieldName: 'quantityOrdered', languageKey: 'quantityOrdered' },
  ],
  getDefaultSortByField: (self) => 'id',
  getDefaultSortOrder: () => 'desc',
})(Filter)

import produce from 'immer'
import CryptoJS from 'crypto-js'
import { get, isEmpty, defaultTo, cloneDeep, remove, unset, sortBy, omit } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { sendClientLog } from 'helpers/auth'
import { removePrivateItem, updatePrivateItem, setPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_SAVED_KEY, LOCATION_COUNTS_UPLOAD_RESULTS_KEY } from 'options/locationCounts'
import { setLoadingMessage, asyncSleep } from 'helpers/utils'
import { isItemCounted, isItemOrdered, isUploadSuccessful } from 'helpers/locationCounts'
import { getSharedLocations, setSharedLocations } from 'helpers/offlineData'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.uploadItems',
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const items = await props.getItems().then((r) => get(r, 'value.data.items', []))

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToUpload')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  getSearchHeader: ({ state }) => {
    const readyForUpload = (state.items ?? []).length
    const itemsOrderedInTotal = (state.items ?? [])
      .flatMap((each) => get(each, 'locationCountDetails', []))
      .filter(isItemOrdered).length

    return (
      <div className="ion-padding-horizontal">
        <p className="font-weight-500">
          {`${readyForUpload} ${t('locationsReadyForUpload')}`}
          <br />
          {`${itemsOrderedInTotal} ${t('itemsOrderedInTotal')}`}
        </p>
        <p>
          <IonText color="medium">
            <span dangerouslySetInnerHTML={{ __html: t('uploadLocationCountInfo') }} />
          </IonText>
        </p>
        <p>
          <IonText color="danger">{`${t('uploadDataWarning')}`}</IonText>
        </p>
      </div>
    )
  },
  renderItem: () => (each) => (
    <IonItem key={each.id} lines="full">
      <IonLabel>
        {each.locationName}
        <br />
        <IonText color="medium">
          <small>{`${t('itemsCounted:')} ${each.locationCountDetails.filter(isItemCounted).length}, ${t(
            'itemsOrdered:'
          )} ${each.locationCountDetails.filter(isItemOrdered).length}`}</small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, updateState, setState, fetchItems } = self
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('upload'),
        handler: async () => {
          try {
            const items = sortBy(state.items ?? [], ['completedDate', 'createdDate'])

            await removePrivateItem(LOCATION_COUNTS_UPLOAD_RESULTS_KEY)

            updateState((draft) => {
              draft.loadingIsOpen = true
              draft.loadingMessage = t('uploading...')
              draft.uploadButtonDisabled = true
            })

            const uploadResults = []
            let uploadedWithErrors = false

            for (const [itemIndex, item] of items.entries()) {
              // console.log({ itemIndex, item })

              try {
                setLoadingMessage(
                  `${t('uploadingLocationCounts')} ${itemIndex + 1} ${t('of')} ${items.length}`
                )

                const payload = produce(item, (draft) => {
                  unset(draft, 'id')
                  unset(draft, 'locationCountId')

                  draft.status = 'Completed'
                  draft.source = 'TRMSMobile'

                  draft.locationCountDetails.forEach((each) => {
                    unset(each, 'id')
                    unset(each, 'locationCountId')

                    each.count = defaultTo(each.count, -1)
                    each.count2 = defaultTo(each.count2, -1)
                  })

                  draft.locationCountDetails = sortBy(draft.locationCountDetails, ['timeScanned'])
                })
                const hashable = JSON.stringify(omit(payload, ['orderId']))
                const response = await props
                  .uploadAndCommit({
                    ...payload,
                    uploadHashValue: CryptoJS.SHA256(hashable).toString(CryptoJS.enc.Hex),
                  })
                  .then((r) => cloneDeep(r.value.data))

                uploadResults.push(response)

                if (response.orderCreateStatus === 'Success') {
                  console.log('orderId:', response.orderId)
                  for (let retry = 1; retry <= 3; retry++) {
                    const success = await updatePrivateItem(LOCATION_COUNTS_SAVED_KEY, [], (draft) => {
                      draft.forEach((each) => {
                        if (each.id === item.id) {
                          each.orderId = response.orderId
                        }
                      })
                    })

                    if (success) {
                      break
                    } else {
                      await sendClientLog('Error', 'Upload Items:  Save OrderID', { retry })
                      await asyncSleep(retry * 1000)
                    }
                  }
                }

                if (isUploadSuccessful(response)) {
                  for (let retry = 1; retry <= 3; retry++) {
                    const success = await updatePrivateItem(LOCATION_COUNTS_SAVED_KEY, [], (draft) => {
                      remove(draft, (each) => each.id === item.id)
                    })

                    if (success) {
                      break
                    } else {
                      await sendClientLog('Error', 'Upload Items: Location Counts', { attempts: retry })
                      await asyncSleep(retry * 1000)
                    }
                  }
                } else {
                  uploadedWithErrors = true
                }
              } catch (error) {
                uploadResults.push({ ...item, error })
                uploadedWithErrors = true
              }
            }

            setLoadingMessage(t('finalizingUpload...'))

            await setPrivateItem(LOCATION_COUNTS_UPLOAD_RESULTS_KEY, uploadResults)

            const locationIds = (await getSharedLocations()).map((each) => each.id)
            const locations = await props
              .getLocations({ locationIds, alwaysIncludeLocationIds: false })
              .then((r) => get(r, 'value.data.items', []))
            await setSharedLocations(locations)

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = uploadedWithErrors
                ? t('locationCountsUploadedWithErrors')
                : t('locationCountsUploadedSuccessfully')
              draft.alertButtons = [
                {
                  text: t('ok'),
                  role: 'cancel',
                  handler: () => {
                    window.location.href = '/inventory/locationCounts/uploadResults'
                  },
                },
              ]
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            setState('uploadButtonDisabled', false)
            showError({ error })
            fetchItems()
          }
        },
      },
    ]

    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.items) || state.uploadButtonDisabled || !self.isOnline}
        onClick={() => {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmUploadData')
            draft.alertButtons = buttons
          })
        }}
      >
        {t('upload')}
      </IonButton>
    )
  },
  allowOffline: (self) => false,
})()

import produce from 'immer'
import { set } from 'lodash'
import { IonItem, IonLabel, IonText, IonToggle } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { isReadOnly } from 'helpers/formViews'
import Segmenter from 'elements/Segmenter'

export default createChildListViewComponent({
  getStorageKey: () => 'jobs.formView.checklist',
  allowSearch: () => false,
  allowDelete: (self) => (item) => !isReadOnly(self) && !self.props.showSwitches,
  getListHeader: ({ state, updateState }) => (
    <Segmenter
      className="ion-margin-top"
      value={state.filterDto?.completed ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Complete', text: t('complete') },
        { value: 'Incomplete', text: t('incomplete') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.completed', value)
        })
      }}
    />
  ),
  filterItems: (self) => (each) => {
    const completed = self.state.filterDto?.completed ?? 'All'

    if (completed === 'Complete') {
      return each.complete
    }

    if (completed === 'Incomplete') {
      return !each.complete
    }

    return true
  },
  renderItem: (self) => (item) => {
    const description = (
      <IonLabel>
        {item.description}
        <br />
        <IonText color="medium">
          <small>
            {t('priority:')} {item.priority}, {t('notes:')} {item.notes || PLACEHOLDER}
            <br />
            {t('completed:')} {item.complete ? formatUserTime(item.lastUserDate, item.lastUser) : PLACEHOLDER}
          </small>
        </IonText>
      </IonLabel>
    )

    if (self.props.showSwitches) {
      return (
        <IonItem lines="full">
          {description}
          <IonToggle
            checked={item.complete}
            onIonChange={(e) =>
              self.props.onChange(
                produce(self.props.items, (draft) => {
                  const draftItem = draft.find((one) => one.id === item.id)

                  draftItem.complete = e.target.checked
                  draftItem.lastUser = draftItem.complete ? self.props.user.userName : null
                  draftItem.lastUserDate = draftItem.complete ? new Date().toJSON() : null
                })
              )
            }
          />
        </IonItem>
      )
    }

    return (
      <IonItem lines="full" onClick={() => self.props.onClick(item)} button>
        {description}
      </IonItem>
    )
  },
  getSortByFields: (self) => [
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'notes', languageKey: 'notes' },
    { fieldName: 'priority', languageKey: 'priority' },
    { fieldName: 'lastUserDate', languageKey: 'completedDate' },
    { fieldName: 'complete', languageKey: 'complete' },
  ],
  getDefaultSortByField: (self) => 'priority',
})()

import * as React from 'react'
import { useImmer } from 'use-immer'
import { set, isNil, cloneDeep, pick, isEmpty, omit, uniqBy } from 'lodash'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import {
  IonAlert,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonTextarea,
  IonSpinner,
  IonLoading,
  IonPopover,
  useIonViewDidEnter,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { PLACEHOLDER, message, tryParseQueryString } from 'helpers/utils'
import { getUserPermission, getLogoutUrl, logout } from 'helpers/auth'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem } from 'helpers/localStorage'
import { createSaveDocumentItems } from 'helpers/formViews'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { formatDateTime } from 'helpers/dateTime'
import Page from 'elements/Page'
import Exit from 'elements/Exit'
import Logo from 'elements/Logo'
import Icon from 'elements/Icon'
import Tabs from 'elements/Tabs'
import FileUpload from 'elements/FileUpload'
import Documents from 'containers/Documents/ChildListView'

function Welcome(props) {
  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  React.useEffect(() => {
    if (tryParseQueryString(props.location.search).unauthorizedStartupPage) {
      setState('alertIsOpen', true)
    }
  }, [])

  return (
    <Page title={t('welcome')} toolbarButton={<Exit />}>
      <Logo file={props.logo} />
      <div className="ion-padding">
        <div
          dangerouslySetInnerHTML={{ __html: t('welcomeLine1') }}
          style={{ margin: '16px 0', fontSize: 'xx-large', fontWeight: 600 }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: t('welcomeLine2') }}
          style={{ margin: '16px 0', lineHeight: '1.5em' }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: t('welcomeLine3') }}
          style={{ margin: '16px 0', lineHeight: '1.5em', fontSize: 'large', fontWeight: 600 }}
        />
      </div>
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={t('unauthorizedStartupPage')}
        buttons={[{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

function CreateJobRequest(props) {
  const getStorageKey = () => 'jobRequests.formView'

  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({
    tabsActiveKey: 'jobRequest',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = state.item?.id) {
    try {
      setState('loadingIsOpen', true)

      const item = itemId
        ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
        : await props.newItem({}).then((r) => cloneDeep(r.value.data))

      item.locationId = item.locationId || null
      item.locationName = item.locationId ? item.locationName : ''

      const documentItems = item.id
        ? await props
            .getDocumentItems({
              request: {
                domainObjectId: item.id,
                domainObjectType: 'JobRequest',
                documentType: 'ObjectDocument',
              },
            })
            .then((r) => r.value.data.items)
        : await Promise.resolve([])

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.title)) {
      errors.title = t('errorMissingRequiredField')
    }

    if (isEmpty(values.createdByName)) {
      errors.createdByName = t('errorMissingRequiredField')
    }

    if (isEmpty(values.createdByEmail)) {
      errors.createdByEmail = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function saveItem(saveAndNew = false) {
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'JobRequest')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const saved = !state.item.id ? await props.createItem(values) : await props.updateItem(values)

          const itemId = saved.value.data.id

          await saveDocumentItems(itemId)

          if (!state.item.id) {
            const resp = await props.sendCreateNotification({ id: itemId })

            showClientNotifications({ response: resp })
          }

          setState('loadingIsOpen', false)

          if (saveAndNew) {
            fetchItem(0)
          } else {
            fetchItem(itemId)
          }
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  React.useEffect(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  }, [props.ionViewDidEnter])

  React.useEffect(() => {
    setState('tabsVisible', false)
    setState('tabsActiveKey', 'jobRequest')

    window.setTimeout(() => setState('tabsVisible', true), 1000)
  }, [state.item?.id])

  const readOnly = !isOnline

  const pageTitle = !state.item?.id ? t('createJobRequest') : `${t('jobRequest')} - ${state.item.id}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    if (state.item.status === 'Closed') {
      return []
    }

    return [
      state.tabsActiveKey === 'documents' ? (
        <IonItem
          key="addDocument"
          lines="full"
          onClick={() => handleActionsMenuClick('addDocument')}
          disabled={readOnly}
        >
          <IonLabel>{t('addDocument')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        <IonRow>
          <IonCol>
            <IonButton color="transparent" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
              {t('save')}
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton color="secondary" expand="full" onClick={() => saveItem(true)} disabled={!isOnline}>
              {t('saveAndNew')}
            </IonButton>
          </IonCol>
        </IonRow>
      }
      backButton={
        <IonButton
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = `${t('confirmLogout')} ${t('unsavedChangesWillBeLost')}`
              draft.alertButtons = [
                { text: t('cancel'), role: 'cancel' },
                {
                  text: t('logout'),
                  handler: () => {
                    logout().finally(() => {
                      sessionStorage.clear()
                      window.location.href = getLogoutUrl({ props, isOnline })
                    })
                  },
                },
              ]
            })
          }}
        >
          <Icon type="power_settings_new" size="26" />
        </IonButton>
      }
    >
      {state.tabsVisible ? (
        <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
          <Tabs.TabPane key="jobRequest" tab={t('jobRequest')} forceRender>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('id')}</IonLabel>
              <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
            </IonItem>
            <IonItem
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': state.errors?.title,
              })}
            >
              <IonLabel position="stacked">{t('title')}</IonLabel>
              <IonInput
                value={state.item.title}
                onIonInput={(e) => setItemValue('title', e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('createdDate')}</IonLabel>
                  <IonInput
                    value={formatDateTime(state.item.createdDate, { showTime: true })}
                    placeholder={PLACEHOLDER}
                    disabled
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem
                  lines="full"
                  className={cx('tofino-required-item', {
                    'tofino-error-item': state.errors?.createdByName,
                  })}
                >
                  <IonLabel position="stacked">{t('createdBy')}</IonLabel>
                  <IonInput
                    value={state.item.createdByName}
                    onIonInput={(e) => setItemValue('createdByName', e.target.value)}
                    placeholder={PLACEHOLDER}
                    disabled={readOnly}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonItem
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': state.errors?.createdByEmail,
              })}
            >
              <IonLabel position="stacked">{t('email')}</IonLabel>
              <IonInput
                type="email"
                inputMode="email"
                inputmode="email"
                value={state.item.createdByEmail}
                onIonInput={(e) => setItemValue('createdByEmail', e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('status')}</IonLabel>
              <IonInput value={state.item.statusName} placeholder={PLACEHOLDER} disabled />
            </IonItem>
            <IonItem
              className={cx('tofino-stacked-item tofino-required-item', {
                'tofino-error-item': state.errors?.locationId,
              })}
              lines="full"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectLocation`)
              }}
              disabled={readOnly}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('location')}</small>
                </IonText>
                <br />
                {state.item.locationName || state.item.locationId || t('none')}
              </IonLabel>
            </IonItem>
            <IonItem
              className="tofino-stacked-item"
              lines="full"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectAssetCategory`)
              }}
              disabled={readOnly}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('assetCategory')}</small>
                </IonText>
                <br />
                {state.item.assetCategoryId ? state.item.assetCategoryName || t('all') : t('all')}
              </IonLabel>
            </IonItem>
            <IonItem
              className="tofino-stacked-item"
              lines="full"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectAsset`)
              }}
              disabled={readOnly}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('asset')}</small>
                </IonText>
                <br />
                {state.item.assetName || state.item.assetId || t('none')}
              </IonLabel>
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('description')}</IonLabel>
              <IonTextarea
                value={state.item.description}
                onIonInput={(e) => setItemValue('description', e.target.value)}
                rows={3}
                disabled={readOnly}
                placeholder={PLACEHOLDER}
                autoGrow
              />
            </IonItem>
          </Tabs.TabPane>
          <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
            <Documents
              domainObjectId={state.item.id}
              domainObjectType="JobRequest"
              items={state.documentItems ?? []}
              onChange={(values) => setState('documentItems', values)}
            />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <IonSpinner className="ion-margin" />
      )}
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

export default function (props) {
  const [ionViewDidEnter, setIonViewDidEnter] = React.useState(Date.now())

  useIonViewDidEnter(() => {
    setIonViewDidEnter(Date.now())
  })

  return getUserPermission('AllowCreateJobRequestOnly') === 'Yes' ? (
    <CreateJobRequest ionViewDidEnter={ionViewDidEnter} {...props} />
  ) : (
    <Welcome {...props} />
  )
}

import { createListViewContainer } from 'factories/ListView'
import { getSessionItem } from 'helpers/sessionStorage'
import productActions from 'actions/products'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import Component from 'components/Requisitions/AddItems/SearchProducts'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { locationId } = getSessionItem('requisitions.addItems', {}).item

      return dispatch(
        productActions.getListViewItems({
          ...params,
          locationIds: locationId ? [locationId] : [],
          active: 'Active',
        })
      )
    },
    populateRequisitionItems: (params) => dispatch(requisitionItemActions.populateRequisitionItems(params)),
    copyToPrivate: (params) => dispatch(productActions.copyToPrivate(params)),
  }),
})(Component)

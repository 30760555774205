import produce from 'immer'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'

const defaultActions = createActions(entityNames.assetCategories, {
  memoizeGetOptions: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.includeUnassignedSelection = draft.includeUnassignedSelection ?? true
    }),
})

export default {
  ...defaultActions,

  getItemsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.assetCategories'

    try {
      const response = await dispatch(
        defaultActions.getItems({ pageIndex: 0, mobileDownload: true, active: 'Active' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

export default {
  assetCategories: 'assetCategories',
  assets: 'assets',
  customers: 'customers',
  cycleCounts: 'cycleCounts',
  dataImport: 'dataImport',
  documents: 'documents',
  entityLists: 'entityLists',
  inventory: 'inventory',
  inventoryCycleCountDifferenceReport: 'inventoryCycleCountDifferenceReport',
  jobGroups: 'jobGroups',
  jobLabourRates: 'jobLabourRates',
  jobReasonGroups: 'jobReasonGroups',
  jobReasons: 'jobReasons',
  jobRequests: 'jobRequests',
  jobStatusOptions: 'jobStatusOptions',
  jobs: 'jobs',
  lists: 'lists',
  locationCounts: 'locationCounts',
  locationGroups: 'locationGroups',
  locations: 'locations',
  operatorGroups: 'operatorGroups',
  operators: 'operators',
  orders: 'orders',
  products: 'products',
  punchout: 'punchout',
  requisitions: 'requisitions',
  rfqs: 'rfqs',
  suppliers: 'suppliers',
  tagLists: 'tagLists',
  tasks: 'tasks',
  tenants: 'tenants',
  unitsOfMeasure: 'unitsOfMeasure',
  userGroups: 'userGroups',
  users: 'users',
}

import { range, toString as str } from 'lodash'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import Component from 'components/Jobs/FormView/SelectPriority'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      Promise.resolve(
        wrapItems([
          { id: 0, displayName: t('none') },
          ...range(1, 7).map((each) => ({ id: each, displayName: str(each) })),
        ])
      ),
  }),
})(Component)

import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set, range } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
  IonToggle,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import {
  tryParseInt,
  filterAssetsByCategoryIds,
  filterLocationsByGroupIds,
  FILTER_SCROLL_TOP,
} from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray, getMultiSelectDisplayNameFromObject } from 'helpers/formViews'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import dateRangeTypes from 'options/dateRangeTypes'
import overdueStatusOptions from 'options/jobs/overdueStatus'
import approvalStatusOptions from 'options/jobs/approvalStatus'
import dateRangeFields from 'options/jobs/dateRangeFields'
import ModalSelect from 'elements/ModalSelect'
import DateRangePicker from 'elements/DateRangePicker'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const contentRef = React.useCallback((node) => {
    if (node !== null) {
      const scrollTop = tryParseInt(getSessionItem(FILTER_SCROLL_TOP))

      if (scrollTop) {
        node.scrollToPoint(0, scrollTop, 0)
        removeSessionItem(FILTER_SCROLL_TOP)
      }
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.getLocationGroups(),
        props.getLocations(),
        props.getUsers(),
        props.getJobGroups(),
        props.getJobReasons(),
        props.getJobStatusOptions(),
        props.getAssetCategories(),
        props.getAssets(),
        props.getOperators(),
      ])

      updateState((draft) => {
        draft.loadingIsOpen = false
        draft.locationGroups = get(responses[0], 'value.data.items', [])
        draft.locations = get(responses[1], 'value.data.items', [])
        draft.users = get(responses[2], 'value.data.items', [])
        draft.jobGroups = get(responses[3], 'value.data.items', [])
        draft.jobReasons = get(responses[4], 'value.data.items', [])
        draft.jobStatusOptions = [
          { id: 0, displayName: t('all') },
          ...get(responses[5], 'value.data.items', []),
        ]
        draft.assetCategories = get(responses[6], 'value.data.items', [])
        draft.assets = get(responses[7], 'value.data.items', [])
        draft.operators = [{ id: 0, displayName: t('all') }, ...get(responses[8], 'value.data.items', [])]
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const handleDateRangeTypeChange = (value) => {
    if (value === 'Custom') {
      assignFilter({
        dateRange: {
          dateRangeType: value,
          customStartDate: getStartDate(dateRangeType, lastNValue).toISOString(),
          customEndDate: getEndDate(dateRangeType, lastNValue).toISOString(),
        },
      })
    } else {
      assignFilter({
        dateRange: {
          dateRangeType: value,
          customStartDate: null,
          customEndDate: null,
        },
      })
    }
  }

  const locationGroupIds = filterDto?.locationGroupIds ?? []
  const locationIds = filterDto?.locationIds ?? []
  const dateRangeField = filterDto?.dateRangeField ?? 'All'
  const dateRangeType = filterDto?.dateRange?.dateRangeType ?? 'YearToDate'
  const lastNValue = filterDto?.dateRange?.lastNValue ?? 1
  const customStartDate = filterDto?.dateRange?.customStartDate
  const customEndDate = filterDto?.dateRange?.customEndDate
  const createdBy = filterDto?.createdBy ?? ''
  const critical = filterDto?.critical ?? false
  const trackDowntime = filterDto?.trackDowntime ?? false
  const overdueStatus = filterDto?.overdueStatus ?? 'All'
  const priority = filterDto?.priority ?? 0
  const jobGroupIds = filterDto?.jobGroupIds ?? []
  const jobReasonIds = filterDto?.jobReasonIds ?? []
  const jobStatusOptionId = filterDto?.jobStatusOptionId ?? 0
  const approvalStatus = filterDto?.approvalStatus ?? 'All'
  const assetCategoryIds = filterDto?.assetCategoryIds ?? []
  const assetIds = filterDto?.assetIds ?? []
  const assignedToId = filterDto?.assignedToId ?? 0
  const extraDateRangeTypes = dateRangeField === 'DueDate' ? { NextNDays: 'nextNDays' } : {}

  React.useEffect(() => {
    if (!Object.keys({ ...dateRangeTypes, ...extraDateRangeTypes }).includes(dateRangeType)) {
      handleDateRangeTypeChange('Custom')
    }
  }, [dateRangeType, extraDateRangeTypes])

  if (state.activeView === 'dateRangeField') {
    return (
      <ModalSelect
        title={t('dateType')}
        options={Object.entries(dateRangeFields).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeField}
        onChange={(value) => setFilter('dateRangeField', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'dateRangeType') {
    return (
      <ModalSelect
        title={t('dateRangeType')}
        options={Object.entries({ ...dateRangeTypes, ...extraDateRangeTypes }).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeType}
        onChange={(value) => handleDateRangeTypeChange(value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'locationGroupIds') {
    return (
      <ModalSelect
        title={t('locationGroup')}
        options={(state.locationGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationGroupIds}
        onChange={(values) => {
          assignFilter({
            locationGroupIds: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationIds') {
    return (
      <ModalSelect
        title={t('location')}
        options={(state.locations ?? []).filter(filterLocationsByGroupIds(locationGroupIds)).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationIds}
        onChange={(values) => setFilter('locationIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'assetCategoryIds') {
    return (
      <ModalSelect
        title={t('assetCategory')}
        options={(state.assetCategories ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={assetCategoryIds}
        onChange={(values) => {
          assignFilter({
            assetCategoryIds: values,
            assetIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'assetIds') {
    return (
      <ModalSelect
        title={t('asset')}
        options={(state.assets ?? []).filter(filterAssetsByCategoryIds(assetCategoryIds)).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={assetIds}
        onChange={(values) => setFilter('assetIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'createdBy') {
    return (
      <ModalSelect
        title={t('createdBy')}
        options={[
          { value: '', text: t('all') },
          ...(state.users ?? []).map((each) => ({
            value: each.userName,
            text: each.displayName,
          })),
        ]}
        value={createdBy}
        onChange={(value) => setFilter('createdBy', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'assignedToId') {
    return (
      <ModalSelect
        title={t('assignedTo')}
        options={(state.operators ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={assignedToId}
        onChange={(values) => setFilter('assignedToId', values)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'overdueStatus') {
    return (
      <ModalSelect
        title={t('overdue')}
        options={Object.entries(overdueStatusOptions).map((each) => ({
          value: each[0],
          text: t(each[1]),
        }))}
        value={overdueStatus}
        onChange={(value) => setFilter('overdueStatus', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'priority') {
    return (
      <ModalSelect
        title={t('priority')}
        options={[
          { value: 0, text: t('all') },
          ...range(1, 7).map((each) => ({
            value: each,
            text: each,
          })),
        ]}
        value={priority}
        onChange={(value) => setFilter('priority', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'jobGroupIds') {
    return (
      <ModalSelect
        title={t('jobGroup')}
        options={(state.jobGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={jobGroupIds}
        onChange={(values) => setFilter('jobGroupIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'jobReasonIds') {
    return (
      <ModalSelect
        title={t('reason')}
        options={(state.jobReasons ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={jobReasonIds}
        onChange={(values) => setFilter('jobReasonIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'jobStatusOptionId') {
    return (
      <ModalSelect
        title={t('status')}
        options={(state.jobStatusOptions ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={jobStatusOptionId}
        onChange={(values) => setFilter('jobStatusOptionId', values)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'approvalStatus') {
    return (
      <ModalSelect
        title={t('approvalStatus')}
        options={Object.entries(approvalStatusOptions).map((each) => ({
          value: each[0],
          text: t(each[1]),
        }))}
        value={approvalStatus}
        onChange={(value) => setFilter('approvalStatus', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        forceOverscroll={false}
        onIonScroll={(e) => setSessionItem(FILTER_SCROLL_TOP, e.detail.scrollTop)}
        scrollEvents
      >
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'dateRangeField')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('dateType')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(dateRangeFields, dateRangeField)}
          </IonLabel>
        </IonItem>
        <DateRangePicker
          {...{ dateRangeType, lastNValue, customStartDate, customEndDate, setState, setFilter }}
          isFieldAll={dateRangeField === 'All'}
          extraDateRangeTypes={extraDateRangeTypes}
        />
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locationGroups, locationGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locations, locationIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'jobGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('jobGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.jobGroups, jobGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'jobReasonIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('reason')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.jobReasons, jobReasonIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'assetCategoryIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('assetCategory')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.assetCategories, assetCategoryIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'assetIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('asset')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.assets, assetIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'createdBy')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('createdBy')}</small>
            </IonText>
            <br />
            {createdBy || t('all')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'assignedToId')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('assignedTo')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.operators, assignedToId)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'jobStatusOptionId')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('status')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.jobStatusOptions, jobStatusOptionId)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'approvalStatus')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('approvalStatus')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(approvalStatusOptions, approvalStatus)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'overdueStatus')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('overdue')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(overdueStatusOptions, overdueStatus)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'priority')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('priority')}</small>
            </IonText>
            <br />
            {priority || t('all')}
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('critical')}</IonLabel>
          <IonToggle checked={critical} onIonChange={(e) => setFilter('critical', e.detail.checked)} />
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('trackDowntime')}</IonLabel>
          <IonToggle
            checked={trackDowntime}
            onIonChange={(e) => setFilter('trackDowntime', e.detail.checked)}
          />
        </IonItem>
      </IonContent>
    </>
  )
}

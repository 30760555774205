import produce from 'immer'
import { isNil, snakeCase, isEmpty, unset } from 'lodash'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'
import { createActions, normalizeDateRange } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const getPeriodForCheckoutCountInDays = (count, type) =>
  count && type ? count * (type === 'Years' ? 365 : type === 'Months' ? 30 : 1) : undefined

const entityName = entityNames.assets
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const defaultActions = createActions(entityName, {
  useSelectionList: true,
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      const { filterByStatusChangeDate = false } = draft

      if (
        !filterByStatusChangeDate ||
        (filterByStatusChangeDate && (isNil(draft.statusChangeStartDate) || isNil(draft.statusChangeEndDate)))
      ) {
        unset(draft, 'filterByStatusChangeDate')
        unset(draft, 'statusChangeStartDate')
        unset(draft, 'statusChangeEndDate')
      }

      draft.locationVendingTypes = ['NonVending']

      draft.alwaysIncludeAssetIds = draft.alwaysIncludeAssetIds === false ? false : !isEmpty(draft.assetIds)
      draft.alwaysIncludeAssetBarcodes =
        draft.alwaysIncludeAssetBarcodes === false ? false : !isEmpty(draft.assetBarcodes)

      draft.periodForCheckoutCountInDays = getPeriodForCheckoutCountInDays(
        draft.periodForCheckoutCount,
        draft.periodForCheckoutCountType
      )

      unset(draft, 'periodForCheckoutCount')
      unset(draft, 'periodForCheckoutCountType')

      normalizeDateRange(draft)
    }),
})

export default {
  ...defaultActions,

  checkinAssets: (params = {}) => ({
    type: `${ENTITY_NAME}_CHECKIN`,
    payload: createPost(entityName, { action: 'checkin' })(params),
  }),

  moveAssets: (params = {}) => ({
    type: `${ENTITY_NAME}_MOVE`,
    payload: createPost(entityName, { action: 'move' })(params),
  }),

  updateMileage: ({ id, mileage }) => ({
    type: `${ENTITY_NAME}_UPDATE_MILEAGE`,
    payload: createPost(entityName, { action: `${id}/updateMileage` })({ mileage }),
  }),

  updateServiceHours: ({ id, serviceHours }) => ({
    type: `${ENTITY_NAME}_UPDATE_SERVICE_HOURS`,
    payload: createPost(entityName, { action: `${id}/updateServiceHours` })({ serviceHours }),
  }),

  updateTracking: (params = {}) => ({
    type: `${ENTITY_NAME}_UPDATE_TRACKING`,
    payload: createPost(entityName, { action: `${params.id}/updateTracking` })(params),
  }),

  generateId: (params) => ({
    type: `${ENTITY_NAME}_GENERATE_ID`,
    payload: createPost(entityName, { action: '?generateId=true' })(params),
  }),

  getOptionsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.assets.options'

    try {
      const response = await dispatch(
        defaultActions.getOptions({ pageIndex: 0, mobileDownload: true, active: 'Active' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

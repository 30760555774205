import { connect } from 'react-redux'
import { wrapItems } from 'helpers/utils'
import { createAuthStateToProps } from 'helpers/auth'
import { getSharedLocationGroups } from 'helpers/offlineData'
import Component from 'components/LocationCounts/SelectLocation/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const dispatchToProps = (dispatch, props) => ({
  getLocationGroups: () => getSharedLocationGroups().then(wrapItems),
})

export default connect(mapStateToProps, dispatchToProps)(Component)

import { createListViewContainer } from 'factories/ListView'
import requisitionActions from 'actions/requisitions'
import Component from 'components/Requisitions/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(requisitionActions.getListViewItems({ locationVendingTypes: ['NonVending'], ...params })),
    deleteItem: (id) => dispatch(requisitionActions.deleteItems([id])),
  }),
})(Component)

import { t } from 'helpers/i18n'
import Page from 'elements/Page'

export default function (props) {
  const pageTitle =
    props.match.params.itemId === '0' ? t('createRfq') : `${t('rfq')} - ${props.match.params.itemId}`

  return (
    <Page title={pageTitle}>
      <p dangerouslySetInnerHTML={{ __html: t('underDevelopmentDescription') }} className="ion-margin" />
    </Page>
  )
}

import produce from 'immer'
import { createActions, normalizeDateRange } from 'helpers/actions'
import entityNames from 'options/entityNames'

const entityName = entityNames.jobs

export default createActions(entityName, {
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.isTemplate = true
      draft.locationVendingTypes = ['NonVending']
      draft.active = draft.active ?? 'Active'
      draft.isFuture = false

      normalizeDateRange(draft)
    }),
  transformGetOptionsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.isTemplate = true
      draft.sortByField = 'name'
      draft.sortOrder = 'ASC'
      draft.locationVendingTypes = ['NonVending']
      draft.active = draft.active ?? 'Active'
      draft.isFuture = false

      normalizeDateRange(draft)
    }),
})

import { createListViewContainer } from 'factories/ListView'
import supplierActions from 'actions/suppliers'
import punchoutActions from 'actions/punchout'
import Component from 'components/Requisitions/AddItems/Punchout'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params) =>
      dispatch(supplierActions.getItems({ punchoutSuppliersOnly: true, active: 'Active', ...params })),
    getPunchoutRequest: (itemId) => dispatch(punchoutActions.getItem(itemId)),
    createPunchoutRequest: (params) => dispatch(punchoutActions.createItem(params)),
  }),
})(Component)

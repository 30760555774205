import { IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import useOnlineStatus from '@rehooks/online-status'
import { t } from 'helpers/i18n'
import { getUserSettings } from 'helpers/settings'
import { userStartupOptions } from 'options/startupOptions'
import Page from 'elements/Page'

export default function (props) {
  const isOnline = useOnlineStatus()
  const userSettings = getUserSettings()

  const items = [
    {
      key: 'startupPage',
      value: t(userStartupOptions[userSettings.startupPage]),
      routerLink: `${props.match.url}/startupPage`,
    },
    {
      key: 'language',
      value: (props.locales ?? []).find((one) => one.code === userSettings.languageCode).nativeName,
      routerLink: `${props.match.url}/language`,
      disabled: !isOnline,
    },
  ]

  return (
    <Page>
      <IonList>
        {items.map((each) => (
          <IonItem
            key={each.key}
            lines="full"
            routerLink={each.routerLink}
            disabled={each.disabled}
            className="tofino-stacked-item"
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t(each.key)}</small>
              </IonText>
              <br />
              {each.value}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </Page>
  )
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import { useSelector } from 'react-redux'
import { IonText, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getCartTypeLabel, getCurrentCount, getShowCount, isItemCounted, isItemOrdered } from 'helpers/locationCounts'
import selectors from 'selectors'
import { showError } from 'helpers/errors'

export default function (props) {
  const { showCountedToday, ...rest } = props

  const [state, updateState] = useImmer({})

  const showCount = getShowCount(state.item)
  const { isCounted } = state.location ?? {}

  async function fetchItem() {
    try {
      const response = await getCurrentCount()

      updateState((draft) => {
        draft.item = response.currentCount
        draft.location = response.currentLocation
      })
    } catch (error) {
      showError({ error })
    }
  }

  const refTimeout = React.useRef()

  React.useEffect(() => {
    refTimeout.current = window.setTimeout(fetchItem, 250)

    return () => {
      window.clearTimeout(refTimeout.current)
    }
  }, [])

  useIonViewWillEnter(() => {
    window.clearTimeout(refTimeout.current)
  })

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const tenantGroupIsCardinal = useSelector(selectors.auth.tenantGroupIsCardinal)

  return (
    <dl {...rest}>
      <dt>{t('location')}</dt>
      <dd>
        {state.location?.displayName || PLACEHOLDER}{' '}
        {state.item?.orderId ? (
          <IonText color="danger">
            <small>({t('viewOnly')})</small>
          </IonText>
        ) : null}
      </dd>
      {tenantGroupIsCardinal && (
        <>
          <dt>{t('cartType')}</dt>
          <dd>{getCartTypeLabel(state.location)}</dd>
        </>
      )}
      <dt>{t('itemsAtLocation')}</dt>
      <dd>{state.location?.itemsAtLocation ?? PLACEHOLDER}</dd>
      {showCount && (
        <>
          <dt>{t('itemsCounted')}</dt>
          <dd>{state.item ? state.item.locationCountDetails.filter(isItemCounted).length : PLACEHOLDER}</dd>
        </>
      )}
      <dt>{t('itemsOrdered')}</dt>
      <dd>{state.item ? state.item.locationCountDetails.filter(isItemOrdered).length : PLACEHOLDER}</dd>
      {showCountedToday && (
        <>
          <dt>{t('countedToday')}</dt>
          <dd>
            {state.item ? (
              <IonText color={isCounted ? 'danger' : 'success'}>{isCounted ? t('yes') : t('no')}</IonText>
            ) : (
              PLACEHOLDER
            )}
          </dd>
        </>
      )}
    </dl>
  )
}

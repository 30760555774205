import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { toString as str, isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import Filter from 'containers/Rfqs/ListView/Filter'
import Icon from 'elements/Icon'

export default createListViewComponent({
  getStorageKey: () => 'rfqs.listView',
  renderItem: (self) => (item) => (
    <IonItem key={item.id} lines="full" routerLink={`${self.props.match.url}/${item.id}`}>
      <IonLabel>
        {item.isExpedited && <Icon type="priority_high" color="danger" size="16" className="ml-neg-5" />}
        {[item.id, item.locationNames]
          .map(str)
          .filter((each) => !isEmpty(each))
          .join(' - ')}
        <br />
        <IonText color="medium">
          <small>
            {item.statusName || PLACEHOLDER}
            <br />
            {t('created:')} {formatUserTime(item.createDate, item.userName)}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getItemSize: () => 75,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowDelete: (self) => (item) => item.status === 'Draft',
  getDeleteConfirmMessage: (self) => `${t('confirmDeleteRfq')} ${t('confirmDeleteRfqDescription')}`,
  allowOffline: (self) => false,
  getPopoverContent: (self) => (
    <IonItem
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
        self.props.history.push(`${self.props.match.url}/0`)
      }}
    >
      <IonLabel>{t('createRfq')}</IonLabel>
    </IonItem>
  ),
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={() => {
        self.props.history.push(`${self.props.match.url}/0`)
      }}
      disabled={!self.isOnline}
    >
      {t('createOrder')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'statusName', languageKey: 'status' },
    { fieldName: 'userName', languageKey: 'createdBy' },
    { fieldName: 'createDate', languageKey: 'createdDate' },
  ],
})(Filter)

import * as React from 'react'
import { set } from 'lodash'
import { useImmer } from 'use-immer'
import { IonItem, IonLabel, IonList, IonLoading } from '@ionic/react'
import useOnlineStatus from '@rehooks/online-status'
import { t } from 'helpers/i18n'
import { getUserSettings } from 'helpers/settings'
import { showError } from 'helpers/errors'
import Page from 'elements/Page'
import Icon from 'elements/Icon'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      props.history.goBack()
    }
  }, [])

  const [state, updateState] = useImmer({ languageCode: getUserSettings().languageCode })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function updateLanguageCode(code) {
    try {
      updateState((draft) => {
        draft.languageCode = code
        draft.loadingIsOpen = true
      })

      await props.updateUser({ ...props.user, languageCode: code }, { type: 'userSettings' })
      await props.refreshToken(true)

      window.location.reload(true)
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  return (
    <Page title={t('startupPage')}>
      <IonList>
        {(props.locales ?? []).map((each) => (
          <IonItem
            key={each.code}
            lines="full"
            onClick={() => updateLanguageCode(each.code)}
            detail={false}
            button
          >
            <IonLabel>{each.nativeName}</IonLabel>
            <Icon.Radio checked={state.languageCode === each.code} />
          </IonItem>
        ))}
      </IonList>
      <IonLoading spinner="lines-small" isOpen={state.loadingIsOpen} message={t('pleaseWait...')} />
    </Page>
  )
}

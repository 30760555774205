import { get, isEmpty, cloneDeep, remove, omit } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { sendClientLog } from 'helpers/auth'
import { removePrivateItem, updatePrivateItem, setPrivateItem } from 'helpers/localForage'
import { ISSUED_ITEMS_SAVED_KEY, ISSUED_ITEMS_UPLOAD_RESULTS_KEY } from 'options/inventory/issue'
import { setLoadingMessage, asyncSleep } from 'helpers/utils'

export default createListViewComponent({
  getStorageKey: () => 'inventory.issue.uploadItems',
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const items = await props.getItems().then((r) => get(r, 'value.data.items', []))

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToUpload')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  getSearchHeader: ({ state }) => {
    const readyForUpload = (state.items ?? []).length

    return (
      <div className="ion-padding-horizontal">
        <p className="font-weight-500">{`${readyForUpload} ${t('issuedItemsReadyForUpload')}`}</p>
        <p>
          <IonText color="medium">
            <span dangerouslySetInnerHTML={{ __html: t('uploadIssuedItemsInfo') }} />
          </IonText>
        </p>
        <p>
          <IonText color="danger">{`${t('uploadDataWarning')}`}</IonText>
        </p>
      </div>
    )
  },
  renderItem: () => (each) => (
    <IonItem key={each.id} lines="full" className="ion-hide">
      <IonLabel>{each.id}</IonLabel>
    </IonItem>
  ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, updateState, setState, fetchItems } = self
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('upload'),
        handler: async () => {
          try {
            const items = state.items ?? []

            await removePrivateItem(ISSUED_ITEMS_UPLOAD_RESULTS_KEY)

            updateState((draft) => {
              draft.loadingIsOpen = true
              draft.loadingMessage = t('uploading...')
              draft.uploadButtonDisabled = true
            })

            const uploadResults = []
            let uploadedWithErrors = false

            for (const [itemIndex, item] of items.entries()) {
              try {
                setLoadingMessage(`${t('uploading')} ${itemIndex + 1} ${t('of')} ${items.length}`)

                const orderItem = omit(item, ['id', 'orderId'])

                const response = await props.issueOrderItem(orderItem).then((r) => cloneDeep(r.value.data))

                uploadResults.push({ ...item, response })

                if (response.failureCount === 0 && response.successCount > 0) {
                  for (let retry = 1; retry <= 3; retry++) {
                    const success = await updatePrivateItem(ISSUED_ITEMS_SAVED_KEY, [], (draft) => {
                      remove(draft, (each) => each.id === item.id)
                    })

                    if (success) {
                      break
                    } else {
                      await sendClientLog('Error', 'Upload Items: Issue', { retry })
                      await asyncSleep(retry * 1000)
                    }
                  }
                } else {
                  uploadedWithErrors = true
                }
              } catch (error) {
                uploadResults.push({ ...item, error })
                uploadedWithErrors = true
              }
            }

            setLoadingMessage(t('finalizingUpload...'))

            await setPrivateItem(ISSUED_ITEMS_UPLOAD_RESULTS_KEY, uploadResults)

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = uploadedWithErrors
                ? t('issuedItemsUploadedWithErrors')
                : t('issuedItemsUploadedSuccessfully')
              draft.alertButtons = [
                {
                  text: t('ok'),
                  role: 'cancel',
                  handler: () => {
                    window.location.href = '/inventory/issue/uploadResults'
                  },
                },
              ]
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            setState('uploadButtonDisabled', false)
            showError({ error })
            fetchItems()
          }
        },
      },
    ]

    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.items) || state.uploadButtonDisabled || !self.isOnline}
        onClick={() => {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmUploadData')
            draft.alertButtons = buttons
          })
        }}
      >
        {t('upload')}
      </IonButton>
    )
  },
  allowOffline: (self) => false,
})()

import { showError } from 'helpers/errors'
import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'selectLocation',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.toLocationId,
  getOnChange: (self) => async (each) => {
    try {
      const { item } = getSessionItem(getStorageKey(), {})

      const [moveData] = await self.props
        .getMoveData([
          {
            ...item,
            toLocationId: each.id,
            toLocationName: each.displayName,
          },
        ])
        .then((r) => r?.value?.data?.items ?? [])

      updateSessionItem(getStorageKey(), {}, (draft) => {
        draft.item = {
          ...moveData,
          toLocationId: moveData.toLocationId || each.id,
          toLocationName: moveData.toLocationName || each.displayName,
          onHandQuantityToMove: self.props.customer.generalSettings.enableFillToMax
            ? Math.max(0, moveData.fillToMaxQuantity)
            : moveData.onHandQuantityToMove,
          onHand2QuantityToMove: self.props.customer.generalSettings.enableFillToMax
            ? 0
            : moveData.onHand2QuantityToMove,
        }
      })
    } catch (error) {
      showError({ error })
    }
  },
})()

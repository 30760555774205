import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationGroupActions from 'actions/locationGroups'
import { t } from 'helpers/i18n'
import Component from 'components/Download/Customer/SelectLocationGroup'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(locationGroupActions.getOptions(params)).then((r) =>
        produce(r, (draft) => {
          draft.value.data.items.unshift({ id: 0, displayName: t('all') })
          draft.value.data.recordCount++
        })
      ),
  }),
})(Component)

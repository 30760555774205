import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { useDebouncedCallback } from 'use-debounce'
import { set, get, isNil, cloneDeep, uniqBy, isEmpty, pick, omit, unset, isEqual, invoke, camelCase } from 'lodash'
import {
  IonSpinner,
  IonPopover,
  IonLoading,
  IonAlert,
  IonItem,
  IonLabel,
  IonButton,
  IonText,
  IonInput,
  IonToggle,
  IonTextarea,
  IonRow,
  IonCol,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { message, asyncSleep, PLACEHOLDER, tryParseInt, tryParseFloat, DEBOUNCE } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem } from 'helpers/localStorage'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { Emitter } from 'helpers/events'
import {
  getChangedItems,
  createSaveImage,
  createLabelFactory,
  createSaveDocumentItems,
  createSaveWarrantyItems,
  createSaveToleranceItems,
} from 'helpers/formViews'
import { getUserPermission, allowPricing } from 'helpers/auth'
import { LISTS_DELETE_ITEM_EVENT, LISTS_SELECT_ITEM_EVENT } from 'options/events'
import { TRACK_ASSETS_AFTER_CLOSE_KEY } from 'components/TrackAssets/FormView'
import serviceStatusOptions from 'options/assets/serviceStatusOptions'
import taskTypes from 'options/tasks/taskTypes'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Tabs from 'elements/Tabs'
import Image from 'elements/Image'
import Lists from 'containers/Lists/ChildListView'
import Documents from 'containers/Documents/ChildListView'
import FileUpload from 'elements/FileUpload'
import DatePicker from 'elements/DatePicker'
import Tolerances from 'containers/Assets/Tolerances'
import Warranties from 'containers/Assets/Warranties'
import Tasks from 'containers/Assets/Tasks'
import History from 'containers/Assets/History'

export const ASSETS_FORM_VIEW_RETURN_TO_JOB = 'assets.formView.returnToJob'

export const getStorageKey = () => 'assets.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/assets'
    }
  }, [])

  const [state, updateState] = useImmer({
    tabsActiveKey: 'asset',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    const {
      assetCategoryId = 0,
      supervisor = '',
      overdueNotificationGroupId = 0,
      price = 0,
      mileage = 0,
      serviceHours = 0,
      description = '',
      locationId = 0,
      manufacturer = '',
      replacementBarcode = '',
    } = getSessionItem('assets.formView.newItem', {})

    try {
      setState('loadingIsOpen', true)

      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props
              .newItem({
                assetCategoryId,
                supervisor,
                overdueNotificationGroupId,
                price,
                mileage,
                serviceHours,
                description,
                locationId,
                manufacturer,
                replacementBarcode,
              })
              .then((r) => cloneDeep(r.value.data))

      const [fieldSettings, documentItems, toleranceItems, warrantyItems, historyItems, taskItems, image] =
        await Promise.all([
          props.getSettings({ type: 'asset' }).then((r) => r.value.data.fieldSettings),
          item.id
            ? props
                .getDocumentItems({
                  request: {
                    domainObjectId: item.id,
                    domainObjectType: 'Asset',
                    documentType: 'ObjectDocument',
                  },
                })
                .then((r) => r.value.data.items)
            : Promise.resolve([]),
          item.id ? props.getToleranceItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
          item.id ? props.getWarrantyItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
          item.id ? props.getHistoryItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
          item.id
            ? props
                .getTaskItems({
                  active: 'All',
                  taskType: taskTypes.ScheduledJob,
                  assetId: item.id,
                })
                .then((r) => r.value.data.items)
            : Promise.resolve([]),
          item.id
            ? props
                .getDocumentContents({
                  request: {
                    domainObjectId: item.id,
                    domainObjectType: 'Asset',
                    documentType: 'ObjectImage',
                    documentName: item.barcode,
                  },
                })
                .catch(() => {})
            : Promise.resolve(null),
        ])

      updateState((draft) => {
        draft.fieldSettings = fieldSettings
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
        draft.toleranceItems = cloneDeep(toleranceItems)
        draft.toleranceItemsOriginal = cloneDeep(toleranceItems)
        draft.warrantyItems = cloneDeep(warrantyItems)
        draft.warrantyItemsOriginal = cloneDeep(warrantyItems)
        draft.historyItems = cloneDeep(historyItems)
        draft.historyItemsOriginal = cloneDeep(historyItems)
        draft.taskItems = cloneDeep(taskItems)
        draft.taskItemsOriginal = cloneDeep(taskItems)
        draft.image = cloneDeep(image?.value?.data)
        draft.imageOriginal = cloneDeep(image?.value?.data)
      })

      removeSessionItem('assets.formView.newItem')
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.barcode)) {
      errors.barcode = t('errorMissingRequiredField')
    } else if (/^[a-zA-Z0-9-*_.$/+%\s]+$/.test(values.barcode) === false) {
      errors.barcode = t('invalidCharacter')
    }

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    if (isEmpty(values.itemNumber)) {
      errors.itemNumber = t('errorMissingRequiredField')
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  async function saveTaskItems(assetId) {
    const { creating, updating, deleting } = getChangedItems(state.taskItemsOriginal, state.taskItems)

    if (!isEmpty(deleting)) {
      const response = await props.deleteTaskItems(deleting.map((each) => each.id))

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(updating)) {
      const response = await props.updateTaskItems(updating)

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(creating)) {
      const response = await props.createTaskItems(
        creating.map(
          produce((draft) => {
            unset(draft, 'id')
            unset(draft, 'taskId')
            set(draft, 'lastRun', '0001-01-01T12:00:00Z')
            set(draft, 'jobTaskSettings.assetBarcode', state.item.barcode)
            set(draft, 'jobTaskSettings.assetId', assetId)
            set(draft, 'jobTaskSettings.previousJobId', 0)
          })
        )
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

  function saveItem() {
    const saveWarrantyItems = createSaveWarrantyItems({ props, state })
    const saveToleranceItems = createSaveToleranceItems({ props, state })
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'Asset')
    const saveImage = createSaveImage({ props, state }, 'Asset')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        try {
          const saved = values.id ? await props.updateItem(values) : await props.createItem(values)

          await Promise.all([
            saveWarrantyItems(saved.value.data.id),
            saveToleranceItems(saved.value.data.id),
            saveDocumentItems(saved.value.data.id),
            saveTaskItems(saved.value.data.id),
            saveImage(saved.value.data.id, saved.value.data.barcode),
          ])

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/assets/assets/${saved.value.data.id}`
          } else {
            await fetchItem(saved.value.data.id)
          }
        } catch (error) {
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  const fetchInventoryAvailability = useDebouncedCallback(async () => {
    const { locationId, replacementBarcode: barcode } = state.item ?? {}

    if (locationId && barcode) {
      try {
        const response = await props.getInventoryAvailability({
          locationId,
          barcode,
        })

        setState('inventoryAvailability', response.value.data)
      } catch (error) {
        showError({ error })
      }
    }
  }, DEBOUNCE)

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal) ||
      !isEqual(state.warrantyItems, state.warrantyItemsOriginal) ||
      !isEqual(state.toleranceItems, state.toleranceItemsOriginal) ||
      !isEqual(state.historyItems, state.historyItemsOriginal) ||
      !isEqual(state.taskItems, state.taskItemsOriginal)
    )
  }

  React.useEffect(() => {
    fetchInventoryAvailability()
  }, [state.item?.locationId, state.item?.replacementBarcode])

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    if (getSessionItem(ASSETS_FORM_VIEW_RETURN_TO_JOB)) {
      updateState((draft) => {
        draft.returnToJob = getSessionItem(ASSETS_FORM_VIEW_RETURN_TO_JOB)
      })
      removeSessionItem(ASSETS_FORM_VIEW_RETURN_TO_JOB)
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    function handleSelectList() {
      props.history.push(`${props.match.url}/selectList`)
    }

    Emitter.on(LISTS_SELECT_ITEM_EVENT, handleSelectList)

    return () => Emitter.off(LISTS_SELECT_ITEM_EVENT, handleSelectList)
  }, [props.match.params.itemId])

  const pageTitle = props.match.params.itemId === '0' ? t('createAsset') : state.item ? state.item.barcode : t('asset')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = getUserPermission('Assets') !== 'Edit'

  function updateField(dtoFieldName) {
    switch (dtoFieldName) {
      case 'mileage':
      case 'serviceHours':
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('update'),
            handler: async () => {
              try {
                setState('loadingIsOpen', true)

                const response = await invoke(props, camelCase(`update-${dtoFieldName}`), state.item)

                showClientNotifications({ response })

                const historyItems = await props.getHistoryItems(state.item.id).then((r) => r.value.data.items)

                updateState((draft) => {
                  draft.itemOriginal[dtoFieldName] = draft.item[dtoFieldName]
                  draft.historyItems = draft.historyItemsOriginal = historyItems
                })
              } catch (error) {
                showError({ error })
              } finally {
                setState('loadingIsOpen', false)
              }
            },
          },
        ]
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t(camelCase(`confirmUpdate-${dtoFieldName}`))
            .replace('xxx', state.itemOriginal[dtoFieldName])
            .replace('yyy', state.item[dtoFieldName])
          draft.alertButtons = buttons
        })

        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  async function handleGenerateClick() {
    setState('errors', {})

    const params = {
      createdDate: new Date().toJSON(),
      createdBy: props.user.userName,
      ...state.item,
    }

    if (!params.locationId) {
      message.error(t('selectLocationFirst'))
      return
    }

    try {
      setState('loadingIsOpen', true)

      params.locationId = params.locationId ?? 0

      const saved = await props.generateId(params)

      await asyncSleep()

      window.location.href = `/assets/assets/${saved.value.data.id}`
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'addTolerance':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/tolerances/0`)
        break

      case 'scheduleJob':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/tasks/0`)
        break

      case 'addWarranty':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/warranties/0`)
        break

      case 'copyFromExistingAsset':
        removeSessionItem('assets.formView.copyFromExisting')
        setSessionItem(getStorageKey(), pick(state, ['item']))
        props.history.push(`${props.match.url}/copyFromExisting`)
        break

      case 'addListToAsset':
        props.history.push(`${props.match.url}/addList`)
        break

      case 'removeListFromAsset':
        Emitter.emit(LISTS_DELETE_ITEM_EVENT)
        break

      case 'sortOptions':
        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }

        if (state.tabsActiveKey === 'lists') {
          Emitter.emit('lists.childListView.items.sortOptions')
        }

        if (state.tabsActiveKey === 'history') {
          Emitter.emit('assets.formView.history.sortOptions')
        }

        if (state.tabsActiveKey === 'tolerances') {
          Emitter.emit('assets.formView.tolerances.sortOptions')
        }

        if (state.tabsActiveKey === 'warranty') {
          Emitter.emit('assets.formView.warranty.sortOptions')
        }

        if (state.tabsActiveKey === 'maintenance') {
          Emitter.emit('assets.formView.tasks.sortOptions')
        }
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      case 'returnToJob':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          window.location.href = `/jobs/jobs/${state.returnToJob?.jobId}`
        }
        break

      case 'createJob':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem('jobs.formView.newItemParams', {
            assetId: state.item.id,
            assetName: state.item.barcode,
            locationId: state.item.locationId,
            locationName: state.item.locationName,
            assetCategoryId: state.item.assetCategoryId,
            assetCategoryName: state.item.assetCategoryName,
          })
          window.location.href = '/jobs/jobs/0'
        }
        break

      case 'trackAsset':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY, {
            redirectUrl: `/assets/assets/${state.item.id}`,
          })
          setSessionItem('trackAssets', {
            item: {
              barcode: state.item.barcode,
              operatorId: props.user.operatorId,
              operatorBarcode: props.user.operatorBarcode,
              operatorDisplayName: props.user.operatorName,
            },
            asset: state.item,
          })
          window.location.href = '/assets/trackAssets'
        }
        break

      case 'uploadImage':
        setState('uploadImageIsOpen', true)
        break

      case 'removeImage':
        setState('image', null)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const defaultItems = [
      !state.item.id ? (
        <IonItem
          key="copyFromExistingAsset"
          lines="full"
          onClick={() => handleActionsMenuClick('copyFromExistingAsset')}
        >
          <IonLabel>{t('copyFromExistingAsset')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && state.returnToJob?.jobId ? (
        <IonItem key="returnToJob" lines="none" onClick={() => handleActionsMenuClick('returnToJob')}>
          <IonLabel>{t('returnToJob')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id ? (
        <IonItem key="createJob" lines="full" onClick={() => handleActionsMenuClick('createJob')}>
          <IonLabel>{t('createJob')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id ? (
        <IonItem key="trackAsset" lines="full" onClick={() => handleActionsMenuClick('trackAsset')}>
          <IonLabel>{t('trackAsset')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)

    switch (state.tabsActiveKey) {
      case 'maintenance':
        return [
          <IonItem key="scheduleJob" lines="full" onClick={() => handleActionsMenuClick('scheduleJob')}>
            <IonLabel>{t('scheduleJob')}</IonLabel>
          </IonItem>,
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'tolerances':
        return [
          <IonItem key="addTolerance" lines="full" onClick={() => handleActionsMenuClick('addTolerance')}>
            <IonLabel>{t('addTolerance')}</IonLabel>
          </IonItem>,
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'warranty':
        return [
          <IonItem key="addWarranty" lines="full" onClick={() => handleActionsMenuClick('addWarranty')}>
            <IonLabel>{t('addWarranty')}</IonLabel>
          </IonItem>,
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'history':
        return [
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'documents':
        return [
          <IonItem key="addDocument" lines="full" onClick={() => handleActionsMenuClick('addDocument')}>
            <IonLabel>{t('addDocument')}</IonLabel>
          </IonItem>,
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'lists':
        return [
          <IonItem key="addListToAsset" lines="none" onClick={() => handleActionsMenuClick('addListToAsset')}>
            <IonLabel>{t('addListToAsset')}</IonLabel>
          </IonItem>,
          <IonItem
            key="removeListFromAsset"
            lines="full"
            onClick={() => handleActionsMenuClick('removeListFromAsset')}
            disabled={readOnly || isEmpty(state.listItems)}
          >
            <IonLabel>{t('removeListFromAsset')}</IonLabel>
          </IonItem>,
          ...defaultItems,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      case 'image':
        return [
          !readOnly ? (
            <IonItem
              key="uploadImage"
              lines="none"
              onClick={() => handleActionsMenuClick('uploadImage')}
              disabled={!isOnline}
            >
              <IonLabel>{t('uploadImage')}</IonLabel>
            </IonItem>
          ) : null,
          !readOnly ? (
            <IonItem
              key="removeImage"
              lines="full"
              onClick={() => handleActionsMenuClick('removeImage')}
              disabled={isEmpty(state.image) || !isOnline}
            >
              <IonLabel>{t('removeImage')}</IonLabel>
            </IonItem>
          ) : null,
          ...defaultItems,
        ].filter(Boolean)

      default:
        return defaultItems
    }
  })().filter(Boolean)

  const createLabel = createLabelFactory(state.fieldSettings)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonRow>
            {Object.entries({
              maintenance: 'scheduleJob',
              tolerances: 'addTolerance',
              warranty: 'addWarranty',
              documents: 'addDocument',
              lists: 'addListToAsset',
            }).map(([key, value]) =>
              state.tabsActiveKey === key ? (
                <IonCol key={key}>
                  <IonButton
                    color="transparent"
                    expand="full"
                    onClick={() => handleActionsMenuClick(value)}
                    disabled={!isOnline}
                  >
                    {key === 'maintenance' ? t('schedule') : t('add')}
                  </IonButton>
                </IonCol>
              ) : null
            )}
            <IonCol>
              <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
                {t('save')}
              </IonButton>
            </IonCol>
          </IonRow>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="asset" tab={t('asset')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('location')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonRow>
            <IonCol size={state.item.id ? 12 : 8}>
              <IonItem
                lines="full"
                className={cx('tofino-required-item', {
                  'tofino-error-item': state.errors?.barcode,
                })}
              >
                <IonLabel position="stacked">{createLabel('barcode')}</IonLabel>
                <IonInput
                  value={state.item.barcode}
                  onIonInput={(e) => setItemValue('barcode', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={readOnly}
                />
              </IonItem>
            </IonCol>
            {!state.item.id && (
              <IonCol size={4}>
                <IonItem lines="full" onClick={handleGenerateClick} detail={false} className="ion-text-right" button>
                  <IonLabel color="secondary" style={{ marginTop: '32px', marginBottom: '18px' }}>
                    {t('generate')}
                  </IonLabel>
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectCategory`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('assetCategoryId')}</small>
              </IonText>
              <br />
              {state.item.assetCategoryName || state.item.assetCategoryId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.itemNumber,
            })}
          >
            <IonLabel position="stacked">{createLabel('itemNumber')}</IonLabel>
            <IonInput
              value={state.item.itemNumber}
              onIonInput={(e) => setItemValue('itemNumber', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectServiceStatus`)
            }}
            disabled={readOnly || state.item.status === 'Out'}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('serviceStatus')}</small>
              </IonText>
              <br />
              {state.item.serviceStatus ? t(serviceStatusOptions[state.item.serviceStatus]) : t('none')}
            </IonLabel>
          </IonItem>
          <DatePicker
            label={createLabel('inServiceDate')}
            value={state.item.inServiceDate}
            onChange={(value) => setItemValue('inServiceDate', value)}
            disabled={readOnly}
          />
          <DatePicker
            label={createLabel('revisionDate')}
            value={state.item.revisionDate}
            onChange={(value) => setItemValue('revisionDate', value)}
            disabled={readOnly}
          />
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.description,
            })}
          >
            <IonLabel position="stacked">{createLabel('description')}</IonLabel>
            <IonTextarea
              value={state.item.description}
              onIonInput={(e) => setItemValue('description', e.target.value)}
              rows={3}
              autoGrow
            />
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="general" tab={t('general')} forceRender>
          {['binLocation', 'manufacturer', 'modelNumber', 'serialNumber'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(each)}</IonLabel>
              <IonInput
                value={state.item[each]}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
          ))}
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectSupervisor`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('supervisor')}</small>
              </IonText>
              <br />
              {state.item.supervisorName || state.item.supervisor || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          {['accountNumber', 'costCenter'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(each)}</IonLabel>
              <IonInput
                value={state.item[each]}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
          ))}
          {allowPricing() && (
            <IonItem lines="full">
              <IonLabel position="stacked">{createLabel('price')} ($)</IonLabel>
              <IonInput
                value={state.item.price}
                onIonInput={(e) => setItemValue('price', tryParseFloat(e.target.value, 0.0))}
                type="number"
                inputmode="number"
                inputMode="number"
                placeholder={PLACEHOLDER}
                min={0}
                clearOnEdit
              />
            </IonItem>
          )}
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('maxCheckoutDays')}</IonLabel>
            <IonInput
              value={state.item.maxCheckoutDays}
              onIonInput={(e) => setItemValue('maxCheckoutDays', tryParseInt(e.target.value, 0.0))}
              type="number"
              inputmode="number"
              inputMode="number"
              placeholder={PLACEHOLDER}
              min={0}
              clearOnEdit
            />
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectNotificationGroup`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('overdueNotificationGroupId')}</small>
              </IonText>
              <br />
              {state.item.overdueNotificationGroupName || state.item.overdueNotificationGroupId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('replacementBarcode')}</IonLabel>
            <IonInput
              value={state.item.replacementBarcode}
              onIonInput={(e) => setItemValue('replacementBarcode', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('inventoryAvailability')}</IonLabel>
            <IonInput
              value={
                isNil(state.item?.locationId)
                  ? t('na')
                  : isEmpty(state.item.replacementBarcode)
                    ? t('noReorderBarcodeDefined')
                    : state.inventoryAvailability?.id
                      ? Object.entries({
                          onHand: 'onhand',
                          onHand2: 'onhand2',
                          onOrder: 'onOrder',
                        })
                          .map(([key, value]) => `${t(value)}: ${get(state.inventoryAvailability, key, '?')}`)
                          .join(', ')
                      : t('na')
              }
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          {['keywords', 'comment'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(each)}</IonLabel>
              <IonTextarea
                value={state.item[each]}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                rows={3}
                autoGrow
              />
            </IonItem>
          ))}
          {['isCritical', 'isRental', 'certificationRequired'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel>{createLabel(each)}</IonLabel>
              <IonToggle
                checked={state.item[each]}
                onIonChange={(e) => setItemValue(each, e.detail.checked)}
                disabled={readOnly}
              />
            </IonItem>
          ))}
        </Tabs.TabPane>
        {isOnline && (
          <Tabs.TabPane key="image" tab={t('image')} forceRender>
            <Image value={state.image} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane key="maintenance" tab={t('maintenance')} forceRender>
          {['lastCalibrationDate', 'nextCalibrationDate'].map((each) => (
            <DatePicker
              key={each}
              label={createLabel(each)}
              value={state.item[each]}
              onChange={(value) => setItemValue(each, value)}
              disabled={readOnly}
            />
          ))}
          {['serviceHours', 'mileage'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(each)}</IonLabel>
              <IonInput
                value={state.item[each]}
                onIonInput={(e) => setItemValue(each, tryParseFloat(e.target.value, 0.0))}
                type="number"
                inputmode="number"
                inputMode="number"
                placeholder={PLACEHOLDER}
                min={0}
                clearOnEdit
              />
              {state.item.id ? (
                <Icon
                  slot="end"
                  type="Save"
                  style={{ margin: '25px 0 0 0' }}
                  {...(state.item[each] !== state.itemOriginal[each]
                    ? { color: 'primary', onClick: () => updateField(each) }
                    : { color: 'medium' })}
                />
              ) : null}
            </IonItem>
          ))}
          <IonItem lines="none">
            <IonLabel>{t('scheduledJobs')}</IonLabel>
          </IonItem>
          <Tasks
            items={state.taskItems ?? []}
            onChange={(values) => setState('taskItems', values)}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/tasks/${item.id}`)
            }}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="tolerances" tab={t('tolerances')} forceRender>
          <Tolerances
            settingsType="assets"
            storageKey="assets.formView.tolerances"
            items={state.toleranceItems ?? []}
            onChange={(values) => setState('toleranceItems', values)}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/tolerances/${item.id}`)
            }}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="warranty" tab={t('warranty')} forceRender>
          <Warranties
            storageKey="assets.formView.warranties"
            items={state.warrantyItems ?? []}
            onChange={(values) => setState('warrantyItems', values)}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/warranties/${item.id}`)
            }}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="loto" tab={t('loto')} forceRender>
          <IonItem lines="none">
            <IonLabel position="stacked">{t('loto')}</IonLabel>
            <IonTextarea
              value={state.item.lotoNotes}
              onIonInput={(e) => setItemValue('lotoNotes', e.target.value)}
              placeholder={PLACEHOLDER}
              rows={10}
              disabled={readOnly}
              autoGrow
            />
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="history" tab={t('history')} forceRender>
          <History
            storageKey="assets.formView.history"
            parentRecord={state.item}
            items={state.historyItems}
            originals={state.historyItems}
            onClick={(item) => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/history/${item.id}`)
            }}
            onChange={(values) => setState('historyItems', values)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Asset"
            items={state.documentItems ?? []}
            onChange={(values) => setState('documentItems', values)}
          />
        </Tabs.TabPane>
        {state.item.id ? (
          <Tabs.TabPane key="lists" tab={t('lists')} forceRender>
            <Lists
              entityType="Asset"
              entityId={state.item.id}
              domainObjectType="Asset"
              locationId={state.item.locationId}
              readOnly={readOnly}
              onFetch={(items) => setState('listItems', items)}
            />
          </Tabs.TabPane>
        ) : null}
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <FileUpload
        title={null}
        label={t('uploadImage')}
        hint={t('uploadImageHintMobile')}
        isOpen={state.uploadImageIsOpen}
        onCancel={() => setState('uploadImageIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            if (fileList[0]) {
              draft.image = {
                contents: fileList[0].fileContents,
                fileType: fileList[0].fileType,
              }
            }

            draft.uploadImageIsOpen = false
          })
        }
        accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/png,image/gif"
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

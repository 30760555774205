import * as React from 'react'
import { useImmer } from 'use-immer'
import { IonButton, IonText, IonAlert, useIonViewDidEnter } from '@ionic/react'
import { get, set } from 'lodash'
import { t } from 'helpers/i18n'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { showError } from 'helpers/errors'
import Page from 'elements/Page'
import Header from './Header'

export default function (props) {
  const [state, updateState] = useImmer({ xxx: 0, yyy: 0 })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  useIonViewDidEnter(async () => {
    try {
      const savedItems = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY).then((r) =>
        get(r, 'locationCountDetails', [])
      )

      const xxx = savedItems.length
      const yyy = savedItems.filter((each) => each.quantityToOrder).length

      updateState((draft) => {
        draft.xxx = xxx
        draft.yyy = yyy

        if (xxx + yyy === 0) {
          draft.alertIsOpen = true
          draft.alertMessage = t('nothingToClear')
          draft.alertButtons = [
            {
              text: t('ok'),
              role: 'cancel',
              handler: () => props.history.goBack(),
            },
          ]
        }
      })
    } catch (error) {
      showError({ error })
    }
  })

  return (
    <Page
      title={t('clearLocation')}
      header={<Header className="ion-margin tofino-tabular-list" />}
      footer={
        <IonButton
          expand="full"
          color="secondary"
          onClick={() =>
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('confirmClearLocation')
              draft.alertButtons = [
                {
                  text: t('cancel'),
                  role: 'cancel',
                },
                {
                  text: t('clear'),
                  handler: async () => {
                    try {
                      await updatePrivateItem(LOCATION_COUNTS_CURRENT_KEY, {}, (x) => {
                        x.locationCountDetails = []
                      })
                      props.history.goBack() // inventory/currentLocation
                    } catch (error) {
                      showError({ error })
                    }
                  },
                },
              ]
            })
          }
        >
          {t('clear')}
        </IonButton>
      }
    >
      <div className="ion-padding-horizontal">
        <p>
          <IonText color="medium">
            {t('clearLocationSummary')
              .replace('XXX', state.xxx ?? 0)
              .replace('YYY', state.yyy ?? 0)}
          </IonText>
        </p>
        <p>
          <IonText color="danger">{t('clearLocationWarning')}</IonText>
        </p>
      </div>
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

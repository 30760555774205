import styled from 'styled-components'
import { IonButton } from '@ionic/react'
import Icon from 'elements/Icon'

const Container = styled.div`
  ion-button {
    height: 32px;
    margin-left: 6px;

    &::part(native) {
      padding-left: 6px;
      padding-right: 6px;
    }

    .material-icons {
      color: var(--ion-color-primary) !important;
    }
  }
`

const Min = styled.span`
  color: var(--ion-color-primary) !important;
  font-size: var(--tofino-font-size-regular);
  font-weight: 600;
  padding-left: 6px;
  padding-right: 6px;
`

export default function (props) {
  const { onClick = () => {}, min = 0 } = props

  return (
    <Container>
      <IonButton color="secondary" size="small" onClick={() => onClick(1)} disabled={props.disabled}>
        <Icon type="Add" color="primary" />
      </IonButton>
      <IonButton color="secondary" size="small" onClick={() => onClick(-1)} disabled={props.disabled}>
        <Icon type="Remove" color="primary" />
      </IonButton>
      <IonButton color="secondary" size="small" onClick={() => onClick(null)} disabled={props.disabled}>
        <Min>{min}</Min>
      </IonButton>
    </Container>
  )
}

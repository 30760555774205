import produce from 'immer'
import { getSessionItem } from 'helpers/sessionStorage'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import assetActions from 'actions/assets'
import { t } from 'helpers/i18n'
import Component from 'components/JobRequests/FormView/SelectAsset'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { locationId, assetCategoryId } = getSessionItem('jobRequests.formView', {}).item
      return dispatch(
        assetActions.getOptions({
          assetCategoryIds: assetCategoryId ? [assetCategoryId] : [],
          locationIds: locationId ? [locationId] : [],
          locationVendingTypes: ['NonVending'],
          ...params,
        })
      ).then((r) =>
        produce(r, (draft) => {
          if (params.pageIndex <= 1 && !params.search) {
            draft.value.data.items.unshift({ id: 0, displayName: t('none') })
            draft.value.data.recordCount++
          }
        })
      )
    },
  }),
})(Component)

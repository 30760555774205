import { memoize } from 'lodash'
import { createFormViewContainer } from 'factories/FormView'
import actions from 'actions/orders'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import supplierActions from 'actions/suppliers'
import Component from 'components/Orders/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    getSupplier: memoize((supplierId) => dispatch(supplierActions.getItem(supplierId))),
    populateOrderItems: (params) => dispatch(orderItemActions.populateOrderItems(params)),
    saveOrder: (params) => dispatch(actions.saveOrder(params)),
    cancelOrder: (params) => dispatch(actions.cancelOrder(params)),
    deleteOrder: (orderId) => dispatch(actions.deleteItem(orderId)),
    sendOrder: (params) => dispatch(actions.sendOrder(params)),
    resendOrder: (params) => dispatch(actions.resendOrder(params)),
    emailOrder: (params) => dispatch(actions.emailOrder(params)),
    requestApproval: (params) => dispatch(actions.requestApproval(params)),
    approveOrder: (params) => dispatch(actions.approveOrder(params)),
    processOrder: (params, options) => dispatch(actions.processOrder(params, options)),
    undoProcessed: (params, options) => dispatch(actions.undoProcessed(params, options)),
    generatePurchaseOrderNumber: (params) =>
      dispatch(orderSupplierActions.generatePurchaseOrderNumber(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'Order', orderItemActions),
    ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', orderSupplierActions),
  }),
})(Component)

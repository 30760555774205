import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'
import { AUTH_SESSION_KEY } from 'options/auth'

export default createSelectionListViewComponent({
  pageTitle: () => getSessionItem(AUTH_SESSION_KEY).current.customer.tagSettings.tagC,
  allowInfiniteLoader: () => false,
  allowSearch: () => false,
  getIdField: () => 'displayName',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.tagC || '',
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.tagC = each.id
    }),
})()

import * as React from 'react'
import cx from 'clsx'
import { useImmer } from 'use-immer'
import { IonSpinner, IonButton, IonItem, IonLabel, IonInput, useIonViewDidEnter } from '@ionic/react'
import { set, toString as str, cloneDeep, isEmpty, isNil } from 'lodash'
import { PLACEHOLDER, tryParseFloat } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { showValidationErrors, showError } from 'helpers/errors'
import Page from 'elements/Page'

export default function (props) {
  const { parentStorageKey = 'assets.formView' } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)

      if (name === 'newValue') {
        if (isNil(value)) {
          draft.item.newValue = 0
          draft.item.valueChanged = false
        } else {
          draft.item.newValue = value
          draft.item.valueChanged = true
        }
      }
    })
  }, [])

  function fetchItem() {
    const parentState = getSessionItem(parentStorageKey)
    const item =
      props.match.params.itemId === '0'
        ? { id: Date.now() * -1, assetId: parentState.item.assetId }
        : parentState.toleranceItems.find((one) => str(one.id) === props.match.params.itemId)

    updateState((draft) => {
      draft.item = cloneDeep(item)
      draft.parentState = parentState
      draft.item.lastUpdated = formatUserTime(item.lastUpdateDate, item.lastUpdatedBy)
    })
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.name)) {
      errors.name = t('errorMissingRequiredField')
    }

    if (isNil(values.min)) {
      errors.min = t('errorMissingRequiredField')
    } else if (values.min > values.max) {
      errors.min = t('minValidationError')
    }

    if (isNil(values.max)) {
      errors.max = t('errorMissingRequiredField')
    } else if (values.max < values.mind) {
      errors.max = errors.max ?? t('maxValidationError')
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.toleranceItems.findIndex((one) => str(one.id) === props.match.params.itemId)

            if (index > -1) {
              draft.toleranceItems.splice(index, 1, values)
            } else {
              draft.toleranceItems.unshift(values)
            }

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  if (isNil(state.item)) {
    return (
      <Page title={PLACEHOLDER}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const { readOnly } = state.parentState ?? {}
  const pageTitle = props.match.params.itemId === '0' ? t('addTolerance') : state.item.name || PLACEHOLDER

  return (
    <Page
      title={pageTitle}
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
            {props.match.params.itemId === '0' ? t('add') : t('update')}
          </IonButton>
        )
      }
    >
      {parentStorageKey === 'assets.formView' ? (
        <>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.name })}
          >
            <IonLabel position="stacked">{t('name')}</IonLabel>
            <IonInput
              value={state.item.name}
              onIonInput={(e) => setItemValue('name', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.min })}
          >
            <IonLabel position="stacked">{t('min')}</IonLabel>
            <IonInput
              value={state.item.min}
              type="number"
              inputmode="number"
              inputMode="number"
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('min', tryParseFloat(e.target.value, null))}
              disabled={readOnly}
              clearOnEdit
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.max })}
          >
            <IonLabel position="stacked">{t('max')}</IonLabel>
            <IonInput
              value={state.item.max}
              type="number"
              inputmode="number"
              inputMode="number"
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('max', tryParseFloat(e.target.value, null))}
              disabled={readOnly}
              clearOnEdit
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('notify')}</IonLabel>
            <IonInput
              type="email"
              inputmode="email"
              inputMode="email"
              value={state.item.notify}
              onIonInput={(e) => setItemValue('notify', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
        </>
      ) : (
        <>
          {['min', 'max', 'notify'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{t(each)}</IonLabel>
              <IonInput value={state.item[each]} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          ))}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('lastUpdated')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.lastUpdateDate, state.item.lastUpdatedBy)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx({
              'tofino-error-item':
                state.item.lastUpdated &&
                (state.item.lastValue > state.item.max || state.item.lastValue < state.item.min),
            })}
          >
            <IonLabel position="stacked">{t('lastValue')}</IonLabel>
            <IonInput
              color="medium"
              value={state.item.lastUpdated ? state.item.lastValue : null}
              placeholder={PLACEHOLDER}
              readonly
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx({
              'tofino-error-item':
                state.item.valueChanged &&
                (state.item.newValue > state.item.max || state.item.newValue < state.item.min),
            })}
          >
            <IonLabel position="stacked">{t('newValue')}</IonLabel>
            <IonInput
              value={state.item.valueChanged ? state.item.newValue : null}
              type="number"
              inputmode="number"
              inputMode="number"
              placeholder={PLACEHOLDER}
              onIonInput={(e) => setItemValue('newValue', tryParseFloat(e.target.value, null))}
              disabled={readOnly}
              clearOnEdit
            />
          </IonItem>
        </>
      )}
    </Page>
  )
}

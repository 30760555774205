import CryptoJS from 'crypto-js'
import { Redirect } from 'react-router-dom'
import { get, set, isEmpty, toLower, pick } from 'lodash'
import { useSelector } from 'react-redux'
import { baseUrl } from 'config'
import { apiClient, getConfig, createPost } from 'helpers/api'
import selectors from 'selectors'
import store from 'helpers/store'
import {
  setSharedItem,
  getSharedItem,
  setSharedItemNoScope,
  getSharedItemNoScope,
  getPrivateItem,
} from 'helpers/localForage'
import { t } from 'helpers/i18n'
import entityNames from 'options/entityNames'
import { LOGO_GET } from 'reducers/auth'
import { LAST_TENANT_STORAGE_KEY, SERVICE_WORKER_WAITING } from 'options/auth'
import { setStorageItemNoScope, getStorageItemNoScope, removeStorageItemNoScope } from 'helpers/localStorage'
import { getUserSettings, getCustomerSettings } from 'helpers/settings'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'

export const getMenuItemAllLevels = (url = window.location.pathname) => {
  const parts = url.split('/').filter((each) => !isEmpty(each))
  const level0 = selectors.auth.menuItems(store.getState())
  const level1 = level0.find((one) => toLower(one.key) === get(parts, '[0]'))
  const level2 = get(level1, 'items', []).find((one) => one.key === get(parts, '[1]'))
  const level3 = get(level2, 'items', []).find((one) => one.key === get(parts, '[2]'))

  return [level1, level2, level3]
}

export const getMenuItemThisLevel = (url = window.location.pathname) => {
  const [level1, level2, level3] = getMenuItemAllLevels(url)

  return level3 || level2 || level1
}

export const getPageTitle = (url = window.location.pathname) =>
  t(getMenuItemThisLevel(url)?.languageKey ?? 'underDevelopment')

export const getPossibleRoutes = (menuItems = [], parent = '') => {
  const routes = []

  menuItems.forEach(({ items = [], key = '' }) => {
    const route = [parent, key].join('/')

    routes.push(route)

    if (!isEmpty(items)) {
      routes.push(...getPossibleRoutes(items, route))
    }
  })

  return routes
}

async function updateServiceWorker() {
  if (getStorageItemNoScope(SERVICE_WORKER_WAITING)) {
    if (navigator.serviceWorker?.getRegistrations) {
      await navigator.serviceWorker.getRegistrations().then(async (registrations) => {
        for (const registration of registrations) {
          await registration.unregister()
        }
      })
    }

    removeStorageItemNoScope(SERVICE_WORKER_WAITING)
  }
}

export const login = async ({ tenant, userName, password }) => {
  const url = `${baseUrl}/api/login`
  const group = `POST ${url}`

  const data = { tenant, userName, password }

  const getStorageKey = () => `users.${tenant}.${userName}`.toLowerCase()

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', { tenant, userName, password: 'xxx' })
    console.log('Results:', response)
    console.groupEnd()

    setStorageItemNoScope(LAST_TENANT_STORAGE_KEY, response.data.tenant)

    await updateServiceWorker()

    await setSharedItem(getStorageKey(), response.data)

    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', { tenant, userName, password: 'xxx' })
    console.warn(error)
    console.groupEnd()

    if (!error.response) {
      const response = { data: await getSharedItem(getStorageKey()) }

      if (response?.data?.user?.password) {
        if (response.data.user.password === encryptPassword(password)) {
          return response
        }
        set(error, 'response.data', {
          Errors: [
            {
              TitleLanguageKey: 'unableToLogYouIn',
              DescriptionLanguageKey: 'unsuccessfulLogin',
            },
          ],
        })
      } else {
        set(error, 'response.data', {
          Errors: [
            {
              TitleLanguageKey: 'unableToLogYouIn',
              DescriptionLanguageKey: 'unsuccessfulLoginOffline',
            },
          ],
        })
      }
    }

    throw error
  }
}

export const loginSso = async ({ tenant, accessToken, idToken }) => {
  const url = `${baseUrl}/api/loginSso`
  const group = `POST ${url}`

  const data = { tenant, accessToken, idToken }

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.log('Results:', response)
    console.groupEnd()

    setStorageItemNoScope(LAST_TENANT_STORAGE_KEY, response.data.tenant)

    await updateServiceWorker()

    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.warn(error)
    console.groupEnd()
    throw error
  }
}

export const loginAsCustomer = async (customerId) => {
  const url = `${baseUrl}/api/dcribMasterLogin/${customerId}`
  const group = `POST ${url}`

  const getStorageKey = () => `impersonations.${customerId}`

  try {
    const response = await apiClient.post(url, null, getConfig())
    console.groupCollapsed(group)
    console.log('Results:', response)
    console.groupEnd()

    await setSharedItem(getStorageKey(), response.data)

    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.warn(error)
    console.groupEnd()

    if (!error.response) {
      const data = await getSharedItem(getStorageKey())

      if (data) {
        return { data }
      }
    }

    throw error
  }
}

export const getLogo =
  ({ id = 0, logoimg = '', subdomain } = {}) =>
  (dispatch) => {
    const getStorageKey = () => `logo.${subdomain || 'undefined'}`

    return dispatch({
      type: LOGO_GET,
      payload: createPost(entityNames.documents, { action: 'getContents' })({
        request: {
          domainObjectId: id,
          domainObjectType: 'Tenant',
          documentType: 'CompanyLogo',
          documentName: logoimg.split('\\').reverse()[0],
        },
      }),
    })
      .then(async (response) => {
        await setSharedItemNoScope(getStorageKey(), response.value.data)

        return response
      })
      .catch(async (error) => {
        if (!error.response) {
          const data = await getSharedItemNoScope(getStorageKey())

          if (data) {
            return { data }
          }
        }

        throw error
      })
  }

export const logout = async () => {
  const url = `${baseUrl}/api/users/logout`
  const group = `POST ${url}`
  const data = {}

  try {
    const response = await apiClient.post(url, data, getConfig())
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.log('Results:', response)
    console.groupEnd()
    return response
  } catch (error) {
    console.groupCollapsed(group)
    console.log('Request:', data)
    console.warn(error)
    console.groupEnd()
    throw error
  }
}

export const restricted = (Component) => {
  const authenticatedSelector = (state) => !isEmpty(state?.auth?.current?.authenticationJwt)

  function UserIsAuthenticated(props) {
    const authenticated = useSelector(authenticatedSelector)

    return authenticated ? <Component {...props} /> : <Redirect to="/login" />
  }

  return UserIsAuthenticated
}

export const getUserPermission = (permissionType) => {
  try {
    return (
      selectors.auth
        .user(store.getState())
        .coreUserSettings.permissions.reduce(
          (acc, each) => ({ ...acc, [each.permissionType]: each.value }),
          {}
        )[permissionType] ?? 'No'
    )
  } catch (error) {
    console.warn(error)
    return 'No'
  }
}

function hexToBytes(hex) {
  const bytes = []

  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16))
  }

  return bytes
}

function bytesToWords(ba) {
  const words = []

  for (let i = 0; i < ba.length; i++) {
    words[(i / 4) | 0] |= ba[i] << (24 - 8 * i)
  }

  return CryptoJS.lib.WordArray.create(words)
}

export const encryptPassword = (password = '') =>
  CryptoJS.HmacSHA1(
    CryptoJS.enc.Utf16LE.parse(password),
    bytesToWords(hexToBytes('A912FDCB017CA396'))
  ).toString(CryptoJS.enc.Base64)

export const createAuthStateToProps = (state, props) =>
  Object.entries(selectors.auth).reduce((acc, each) => ({ ...acc, [each[0]]: each[1](state) }), {})

export const getStartupPage = async ({ user, customer }) => {
  const userSettings = getUserSettings()
  const customerSettings = getCustomerSettings()

  const startupPage =
    userSettings.startupPage === 'basedOnCustomerSetting'
      ? customerSettings.startupPage
      : userSettings.startupPage

  const getPermission = (permissionType) =>
    user.coreUserSettings.permissions.find((one) => one.permissionType === permissionType).value

  let href = '/welcome'

  switch (startupPage) {
    case 'showOrdersPage':
      if (getPermission('Master') === 'Yes' || getPermission('Orders') !== 'None') {
        href = '/orders'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showInventoryPage':
      if (
        customer.moduleSettings.enableInventory &&
        (getPermission('Master') === 'Yes' || getPermission('Inventory') !== 'None')
      ) {
        href = '/inventory'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showJobsPage':
      if (getPermission('Master') === 'Yes' || getPermission('Jobs') !== 'None') {
        href = '/jobs'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showAssetsPage':
      if (
        customer.moduleSettings.enableAssetManagement &&
        (getPermission('Master') === 'Yes' || getPermission('Assets') !== 'None')
      ) {
        href = '/inventory'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showIssuePage':
      if (
        customer.moduleSettings.enableInventory &&
        (getPermission('Master') === 'Yes' || getPermission('Inventory') !== 'None')
      ) {
        href = '/inventory/issue'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showCountPage':
      if (
        customer.moduleSettings.enableInventory &&
        (getPermission('Master') === 'Yes' || getPermission('Inventory') !== 'None')
      ) {
        href = '/inventory/count'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showCycleCountsPage':
      if (
        customer.moduleSettings.enableInventory &&
        (getPermission('Master') === 'Yes' || getPermission('CycleCounts') === 'Yes')
      ) {
        href = '/inventory/cycleCounts'
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    case 'showLocationCountsPage':
      if (
        customer.moduleSettings.enableInventory &&
        (getPermission('Master') === 'Yes' || getPermission('LocationCounts') === 'Yes')
      ) {
        if (await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)) {
          href = '/inventory/currentLocation'
        } else {
          href = '/inventory/locationCounts'
        }
      } else {
        href += '?unauthorizedStartupPage=true'
      }
      break

    default:
      break
  }

  return href
}

export const allowPricing = () => {
  try {
    const customer = selectors.auth.customer(store.getState())

    if (customer.moduleSettings.enablePricing !== true) {
      return false
    }

    if (getUserPermission('Master') === 'Yes') {
      return true
    }

    return getUserPermission('DisplayPricing') === 'Yes'
  } catch (error) {
    return false
  }
}

export const getLogoutUrl = (self) =>
  self.props.tenant?.ssoEnabled && self.props.ssoLogoutUrl && self.isOnline
    ? `${self.props.ssoLogoutUrl}&post_logout_redirect_uri=${encodeURIComponent(
        `${window.location.origin}/login`
      )}`
    : '/login'

export const sendClientLog = async (
  type, // 'None' | 'Success' | 'Error' | 'Warning' | 'Information'
  comment,
  payload = {}
) => {
  try {
    await createPost('logger', { action: 'clientLog' })({
      type,
      comment,
      detail: JSON.stringify({
        location: window.location.href,
        environment: pick(process.env, ['REACT_APP_NAME', 'REACT_APP_VERSION']),
        navigator: pick(navigator, [
          'appCodeName',
          'appName',
          'appVersion',
          'platform',
          'product',
          'productSub',
          'userAgent',
          'vendor',
          'vendorSub',
          'language',
          'languages',
        ]),
        window: pick(window, ['innerHeight', 'innerWidth', 'outerHeight', 'outerWidth']),
        screen: pick(window.screen, ['colorDepth', 'height', 'isExtended', 'pixelDepth', 'width']),
        orientation: pick(window.screen?.orientation, ['angle', 'type']),
        indexedDB: Boolean(indexedDB),
        localStorage: Boolean(localStorage),
        sessionStorage: Boolean(sessionStorage),
        ...payload,
      }),
    })
  } catch (error) {
    console.error(error)
  }
}

import { createListViewContainer } from 'factories/ListView'
import { wrapItems, PLACEHOLDER } from 'helpers/utils'
import { getPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import locationCountActions from 'actions/locationCounts'
import entityNames from 'options/entityNames'
import locationActions from 'actions/locations'
import { getSharedLocations, trimMobileDownloadItems } from 'helpers/offlineData'
import Component from 'components/LocationCounts/UploadItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const responses = await Promise.all([
        getPrivateItem(LOCATION_COUNTS_SAVED_KEY, []),
        getSharedLocations(),
      ])

      const items = responses[0].map((each) => ({
        ...each,
        locationName: responses[1].find((one) => one.id === each.locationId)?.displayName ?? PLACEHOLDER,
      }))

      return Promise.resolve(items).then(wrapItems)
    },
    uploadAndCommit: (params) => dispatch(locationCountActions.uploadAndCommit(params)),
    getLocations: (params) =>
      dispatch(
        locationActions.getItems({
          pageIndex: 0,
          mobileDownload: true,
          ...params,
        })
      ).then(trimMobileDownloadItems(entityNames.locations)),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import orderActions from 'actions/orders'
import Component from 'components/Orders/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(orderActions.getListViewItems({ locationVendingTypes: ['NonVending'], ...params })),
    deleteItem: (id) => dispatch(orderActions.deleteItems([id])),
  }),
})(Component)

import produce from 'immer'
import { getSessionItem } from 'helpers/sessionStorage'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import assetActions from 'actions/assets'
import assetWarrantyActions from 'actions/assets/assetWarranties'
import assetToleranceActions from 'actions/assets/assetTolerances'
import { t } from 'helpers/i18n'
import Component from 'components/Jobs/FormView/SelectAsset'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { locationId, assetCategoryId } = getSessionItem('jobs.formView', {}).item
      return dispatch(
        assetActions.getItems({
          assetCategoryIds: assetCategoryId ? [assetCategoryId] : [],
          locationIds: locationId ? [locationId] : [],
          locationVendingTypes: ['NonVending'],
          ...params,
        })
      ).then((r) =>
        produce(r, (draft) => {
          if (params.pageIndex <= 1 && !params.search) {
            draft.value.data.items.unshift({ id: 0, displayName: t('none') })
            draft.value.data.recordCount++
          }
        })
      )
    },
    ...createChildItemsDispatchToProps(dispatch, 'Warranty', assetWarrantyActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
  }),
})(Component)

import { useSelector } from 'react-redux'
import { useImmer } from 'use-immer'
import { IonItem, IonLabel, IonTextarea, IonButton, IonAlert } from '@ionic/react'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import selectors from 'selectors'
import { formatUserTime } from 'helpers/dateTime'
import Icon from 'elements/Icon'

export default function (props) {
  const { onChange = () => {}, label = t('comments'), title = t('addComment') } = props

  const user = useSelector(selectors.auth.user)
  const [state, updateState] = useImmer({})

  return (
    <>
      <IonItem lines="full">
        <IonLabel position="stacked">{label}</IonLabel>
        <IonButton
          color={props.disabled ? 'primary' : 'secondary'}
          disabled={props.disabled}
          style={{ width: '28px', height: '28px', marginTop: '8px' }}
          onClick={() =>
            updateState((draft) => {
              draft.alertIsOpen = true
            })
          }
          slot="end"
        >
          <Icon size="21" type="Add" />
        </IonButton>
        <IonTextarea value={props.value} rows={1} placeholder={PLACEHOLDER} readonly autoGrow />
      </IonItem>
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={title}
        inputs={[{ name: 'input', type: 'textarea', attributes: { rows: 4 } }]}
        buttons={[
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('ok'),
            handler: ({ input }) =>
              onChange(
                `${formatUserTime(new Date().toJSON(), user.userName)}: ${input}${
                  props.value ? `\n${props.value}` : ''
                }`
              ),
          },
        ]}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.alertIsOpen = false
          })
        }
      />
    </>
  )
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPopover,
  IonLoading,
  IonAlert,
  IonSpinner,
  IonInput,
  IonText,
  IonModal,
  IonRow,
  IonCol,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonContent,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import {
  get,
  set,
  unset,
  isNil,
  isEmpty,
  isEqual,
  cloneDeep,
  pick,
  uniqBy,
  invoke,
  remove,
  omit,
  uniq,
} from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { message, PLACEHOLDER, asyncSleep, DEBOUNCE } from 'helpers/utils'
import { calculateOrderTotals, calculateRequisitionTotals } from 'helpers/procurement'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { formatUserTime } from 'helpers/dateTime'
import { allowPricing, getUserPermission } from 'helpers/auth'
import { Emitter } from 'helpers/events'
import { getChangedItems, createSaveDocumentItems } from 'helpers/formViews'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import requisitionerFields from 'options/requisitionerFields'
import Tabs from 'elements/Tabs'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Comments from 'elements/Comments'
import Documents from 'containers/Documents/ChildListView'
import FileUpload from 'elements/FileUpload'
import RequisitionItems from 'containers/Requisitions/Items'
import DatePicker from 'elements/DatePicker'
import SendEmail from 'elements/SendEmail'

const CHECKBOXES = {
  convertToOrder: [
    'setQuantityToUnissuedQuantity',
    'addRequisitionDocumentsToOrder',
    'closeRequisitionAfterConvertingToOrder',
    'copyRequisitionCommentsToOrder',
  ],
  convertToRfq: [
    'setQuantityToUnissuedQuantity',
    'addRequisitionDocumentsToRfq',
    'closeRequisitionAfterConvertingToRfq',
  ],
}

export const getStorageKey = () => 'requisitions.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/orders'
    }
  }, [])

  const [state, updateState] = useImmer({
    tabsActiveKey: 'items',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props.newItem().then((r) => cloneDeep(r.value.data))

      const [requisitionItems, documentItems] = await Promise.all([
        item.id
          ? props
              .getRequisitionItems(item.id, { includeIssueStatus: true, includeItemStatus: true })
              .then((r) => r.value.data.items)
          : Promise.resolve([]),
        item.id
          ? props
              .getDocumentItems({
                request: {
                  domainObjectId: item.id,
                  domainObjectType: 'Requisition',
                  documentType: 'ObjectDocument',
                },
              })
              .then((r) => r.value.data.items)
          : Promise.resolve([]),
      ])

      const populatedRequisitionItems = requisitionItems.map((each) => ({
        ...each,
        populated: true,
        timestamp: new Date().toJSON(),
      }))

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.requisitionItems = cloneDeep(populatedRequisitionItems)
        draft.requisitionItemsOriginal = cloneDeep(populatedRequisitionItems)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function refetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const item = await props.getItem(itemId).then((r) => cloneDeep(r.value.data))

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    callback(errors, values)
  }

  function saveItem() {
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'Requisition')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        try {
          const response = await props.saveRequisition({
            requisition: state.item,
            requisitionItems: getChangedItems(state.requisitionItemsOriginal, state.requisitionItems),
          })

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const itemId = response.value.data.items[0].id

          await saveDocumentItems(itemId)

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/orders/requisitions/${itemId}`
          } else {
            await fetchItem(itemId)
          }
        } catch (error) {
          setState('tabsActiveKey', 'items')
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  async function invokeAction(action) {
    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    try {
      setState('loadingIsOpen', true)

      const response = await invoke(props, action, state.item.id)

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      refetchItem()
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function invokeExtraAction(action) {
    setState('errors', {})

    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    if (['sendRequisition', 'resendRequisition'].includes(action) && isEmpty(state.item.sendTo)) {
      updateState((draft) => {
        draft.errors = { sendTo: t('errorMissingRequiredField') }
        draft.tabsActiveKey = 'requisition'
      })
      message.error(t('errorMissingRequiredField'))
    } else {
      try {
        setState('loadingIsOpen', true)

        if (props[action]) {
          const response = await invoke(props, action, state.item.id)

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          refetchItem(state.item.id)
        } else {
          message.info(t('underDevelopment'))
        }
      } catch (error) {
        showError({ error })
      } finally {
        setState('loadingIsOpen', false)
      }
    }
  }

  async function handleSendEmailSubmit(recipients) {
    if (isEmpty(recipients)) {
      message.error(t('errorMissingRequiredField'))
    } else {
      try {
        const response = await props.emailRequisition({
          recipients,
          requisition: state.item,
          requisitionItems: state.requisitionItems,
        })

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        setState('sendEmailIsOpen', false)
      } catch (error) {
        showError({ error })
      }
    }
  }

  async function handleItemsRowAction(rowActionKey, selectedRowKeys) {
    switch (rowActionKey) {
      case 'copy':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          try {
            setState('loadingIsOpen', true)

            const copied = await props.newItem({}).then((r) => cloneDeep(r.value.data))

            unset(copied, 'id')

            Object.assign(
              copied,
              pick(state.item, [
                'requisitionerName',
                'requisitionerPhone',
                'requisitionerEmail',
                'shipToAddressId',
                'sendTo',
                'deliveryDueDate',
                'description',
                'internalComment',
              ])
            )

            const requisitionItems = state.requisitionItems
              .filter((each) => selectedRowKeys.includes(each.id))
              .map((each) => ({
                timeScanned: new Date().toJSON(),
                serverTimeScanned: new Date().toJSON(),
                userName: props.user.userName,
                productId: each.productId,
                barcode: each.barcode,
                itemNumber: each.itemNumber,
                description: each.description,
                locationId: each.locationId,
                binLocation: each.binLocation,
                manufacturer: each.manufacturer,
                nonStock: props.customer.generalSettings.addNonStockToProducts ? false : each.nonStock,
                price: each.price,
                unitOfMeasure: each.unitOfMeasure,
                packageSize: each.packageSize,
                packageSizeUom: each.packageSizeUom,
                quantity: each.quantity,
                taxable: each.taxable,
                taxableDescription: each.taxableDescription,
                expedite: each.expedite,
                packagePrice: each.packagePrice,
                packageUnitOfMeasure: each.packageUnitOfMeasure,
                deliveryDate: copied.deliveryDate,
                locationBarcode: each.locationBarcode,
                locationName: each.locationName,
                sourceCatalogName: each.sourceCatalogName,
                jobId: each.jobId,
                jobName: each.jobName,
                operatorId: each.operatorId,
                operatorName: each.operatorName,
                assetBarcode: each.assetBarcode,
                orderSupplierId: each.orderSupplierId,
                orderSupplierName: each.orderSupplierName,
                tagA: each.tagA,
                tagB: each.tagB,
                tagC: each.tagC,
                tagD: each.tagD,
                tagE: each.tagE,
              }))

            const response = await props.saveRequisition({
              requisition: copied,
              requisitionItems: { creating: requisitionItems },
            })

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await asyncSleep()

            window.location.href = `/orders/requisitions/${response.value.data.items[0].id}`
          } catch (error) {
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        }
        break

      case 'convertToOrder':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else if (!state.item.approved) {
          message.error(t('requisitionApprovalRequired'))
        } else {
          updateState((draft) => {
            draft.convertToOrderIsOpen = true
            draft.selectedRequisitionItems = draft.requisitionItems.filter((each) =>
              selectedRowKeys.includes(each.id)
            )
          })
        }
        break

      case 'convertToRfq':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else if (!state.item.approved) {
          message.error(t('requisitionApprovalRequired'))
        } else {
          updateState((draft) => {
            draft.convertToRfqIsOpen = true
            draft.selectedRequisitionItems = draft.requisitionItems.filter((each) =>
              selectedRowKeys.includes(each.id)
            )
          })
        }
        break

      case 'delete':
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('delete'),
            handler: () => {
              updateState((draft) => {
                remove(draft.requisitionItems, (each) => selectedRowKeys.includes(each.id))
              })
            },
          },
        ]
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmDeleteSelectedItems')
          draft.alertButtons = buttons
        })

        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  async function convertToOrder() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.newOrder(),
        props.populateOrderItems(
          state.selectedRequisitionItems
            .map((each) => ({
              ...each,
              quantityOrdered: state.convertToOrder?.setQuantityToUnissuedQuantity
                ? each.quantity - each.quantityIssued
                : each.quantity,
              supplierId: each.orderSupplierId,
              supplierName: each.orderSupplierName,
              requisitionId: undefined,
              requisitionItemId: undefined,
              quantityIssued: undefined,
              userName: props.user.userName,
            }))
            .filter((each) => each.quantityOrdered > 0)
        ),
      ])

      const item = cloneDeep(responses[0].value.data)

      Object.assign(
        item,
        pick(
          state.item,
          requisitionerFields.map((each) => each.key)
        )
      )

      if (state.convertToOrder?.copyRequisitionCommentsToOrder && !isEmpty(state.item.customerComment)) {
        item.comment = `${t('fromRequisition')} ${state.item.id}:\n${state.item.customerComment}`
      }

      const orderItems = get(responses[1], 'value.data.items', []).map((each) => ({
        ...each,
        source: `REQ${state.item.id}`,
        sourceIdNumber: state.item.id,
        sourceItemId: each.id,
        id: undefined,
        orderItemId: undefined,
        timeScanned: new Date().toJSON(),
        serverTimeScanned: new Date().toJSON(),
      }))

      const supplierIds = orderItems
        .filter((each) => Boolean(each.supplierId) && each.quantityOrdered > 0)
        .map((each) => each.supplierId)

      const suppliers = await Promise.all(
        uniq(supplierIds).map((each) => props.getSupplier(each).then((r) => r.value.data))
      )

      const purchaseOrderNumbers = await Promise.all(
        supplierIds.map((supplierId) =>
          props
            .generatePurchaseOrderNumber({
              orderId: 0,
              supplierId,
            })
            .then((each) => ({
              supplierId,
              ...each.value.data,
            }))
        )
      )

      const orderSuppliers = suppliers.map((each) => ({
        adjustments: 0,
        confirmedDescription: 'No',
        freight: 0,
        purchaseOrderNumber: '',
        shippingInstructions: each.shippingInstructions,
        supplierId: each.id,
        supplierName: each.name,
      }))

      Object.assign(item, calculateOrderTotals({ customer: props.customer, item, orderItems }))

      orderSuppliers.forEach((each) => {
        try {
          each.purchaseOrderNumber = purchaseOrderNumbers.find(
            (one) => one.supplierId === each.supplierId
          ).purchaseOrderNumber
        } catch (error) {
          console.warn(error)
        }
      })

      const response = await props.saveOrder({
        order: item,
        orderItems: { creating: orderItems },
        orderSuppliers: { creating: orderSuppliers },
      })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      const order = cloneDeep(response.value.data.items[0])

      if (state.convertToOrder?.addRequisitionDocumentsToOrder) {
        const copied = await props.copyAllObjectDocuments({
          sourceRequest: {
            domainObjectId: state.item.id,
            domainObjectType: 'Requisition',
          },
          destinationRequest: {
            domainObjectId: order.id,
            domainObjectType: 'Order',
          },
        })

        showClientNotifications({ response: copied })

        if (copied.value.data.failureCount > 0) {
          throw new Error()
        }
      }

      if (state.convertToOrder?.closeRequisitionAfterConvertingToOrder && state.item?.status === 'Open') {
        await invokeAction('closeRequisition')
      }

      await asyncSleep()

      window.location.href = `/orders/orders/${order.id}`
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function convertToRfq() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.newRfq(),
        props.populateRfqItems(
          state.selectedRequisitionItems
            .map((each) => ({
              ...each,
              userName: props.user.userName,
              quantity: state.convertToRfq?.setQuantityToUnissuedQuantity
                ? each.quantity - each.quantityIssued
                : each.quantity,
              requisitionId: undefined,
              requisitionItemId: undefined,
            }))
            .filter((each) => each.quantity > 0)
        ),
      ])

      const item = cloneDeep(responses[0].value.data)

      const rfqItems = get(responses[1], 'value.data.items', []).map((each) => ({
        ...each,
        sourceId: `REQ${state.item.id}`,
        sourceItemId: each.id,
        id: undefined,
        rfqItemId: undefined,
        timeScanned: new Date().toJSON(),
        serverTimeScanned: new Date().toJSON(),
      }))

      const response = await props.saveRfq({
        rfq: item,
        rfqItems: { creating: rfqItems },
      })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      const rfq = cloneDeep(response.value.data.items[0])

      rfq.sourceRequisitionId = state.item.id

      if (state.convertToRfq?.addRequisitionDocumentsToRfq) {
        const copied = await props.copyAllObjectDocuments({
          sourceRequest: {
            domainObjectId: state.item.id,
            domainObjectType: 'Requisition',
          },
          destinationRequest: {
            domainObjectId: rfq.id,
            domainObjectType: 'Rfq',
          },
        })

        showClientNotifications({ response: copied })

        if (copied.value.data.failureCount > 0) {
          throw new Error()
        }
      }

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      if (state.convertToRfq?.closeRequisitionAfterConvertingToRfq && state.item?.status === 'Open') {
        await invokeAction('closeRequisition')
      }

      await asyncSleep()

      window.location.href = `/orders/rfqs/${rfq.id}`
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  const refreshRequisitionTotals = useDebouncedCallback(() => {
    updateState((draft) => {
      Object.assign(draft.item, calculateRequisitionTotals({ customer: props.customer, ...draft }))
    })
  }, DEBOUNCE)

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.requisitionItems, state.requisitionItemsOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal)
    )
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })

      refreshRequisitionTotals()
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['convertToOrder', 'convertToRfq']))
  }, [state.convertToOrder, state.convertToRfq])

  const pageTitle =
    props.match.params.itemId === '0'
      ? t('createRequisition')
      : `${t('requisition')} - ${props.match.params.itemId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = ['Cancelled', 'Closed'].includes(state.item.status)

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'addToRequisition':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/addItems`)
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      case 'sendEmail':
        setState('sendEmailIsOpen', true)
        break

      case 'sortOptions':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('requisitions.formView.requisitionItems.sortOptions')
        }

        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }
        break

      case 'closeRequisition':
      case 'cancelRequisition':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = `${t(`confirm${key}`)} ${t(`confirm${key}description`)}`
            draft.alertButtons = [
              { text: t('no'), role: 'cancel' },
              { text: t('yes'), handler: () => invokeAction(key) },
            ]
          })
        }
        break

      case 'deleteRequisition':
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = `${t(`confirm${key}`)} ${t(`confirm${key}description`)} `
          draft.alertButtons = [
            { text: t('no'), role: 'cancel' },
            {
              text: t('yes'),
              handler: async () => {
                try {
                  const response = await props.deleteRequisition(state.item.id)
                  showClientNotifications({ response })

                  if (response.value.data.failureCount > 0) {
                    throw new Error()
                  }

                  props.history.goBack()
                } catch (error) {
                  showError({ error })
                }
              },
            },
          ]
        })
        break

      case 'showRowActions':
        setState('itemsRowActionsIsOpen', true)
        break

      case 'hideRowActions':
        setState('itemsRowActionsIsOpen', false)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const head = !readOnly ? (
      <IonItem
        key="addToRequisition"
        lines={state.tabsActiveKey === 'documents' ? 'none' : 'full'}
        onClick={() => handleActionsMenuClick('addToRequisition')}
      >
        <IonLabel>{t('addToRequisition')}</IonLabel>
      </IonItem>
    ) : null

    const tail = [
      state.item.id ? (
        <IonItem key="email" lines="full" onClick={() => handleActionsMenuClick('sendEmail')}>
          <IonLabel>{t('email')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && state.item.status === 'Open' ? (
        <IonItem
          key="closeRequisition"
          lines="none"
          onClick={() => handleActionsMenuClick('closeRequisition')}
          disabled={readOnly}
        >
          <IonLabel>{t('closeRequisition')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && ['Open', 'Closed'].includes(state.item.status) ? (
        <IonItem
          key="cancelRequisition"
          lines="full"
          onClick={() => handleActionsMenuClick('cancelRequisition')}
          disabled={readOnly && state.item.status !== 'Closed'}
        >
          <IonLabel>{t('cancelRequisition')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && state.item.status === 'Draft' ? (
        <IonItem
          key="deleteRequisition"
          lines="full"
          onClick={() => handleActionsMenuClick('deleteRequisition')}
        >
          <IonLabel>{t('deleteRequisition')}</IonLabel>
        </IonItem>
      ) : null,
    ]

    const defaultItems = [head, ...tail].filter(Boolean)

    switch (state.tabsActiveKey) {
      case 'items':
        return [
          head,
          <IonItem key="sortOptions" lines="none" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          state.itemsRowActionsIsOpen ? (
            <IonItem
              key="hideRowActions"
              lines="full"
              onClick={() => handleActionsMenuClick('hideRowActions')}
            >
              <IonLabel>{t('hideRowActions')}</IonLabel>
            </IonItem>
          ) : (
            <IonItem
              key="showRowActions"
              lines="full"
              onClick={() => handleActionsMenuClick('showRowActions')}
            >
              <IonLabel>{t('showRowActions')}</IonLabel>
            </IonItem>
          ),
          ...tail,
        ].filter(Boolean)

      case 'documents':
        return [
          head,
          !readOnly ? (
            <IonItem key="addDocument" lines="full" onClick={() => handleActionsMenuClick('addDocument')}>
              <IonLabel>{t('addDocument')}</IonLabel>
            </IonItem>
          ) : null,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ].filter(Boolean)

      default:
        return defaultItems
    }
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        <IonRow>
          {!readOnly && state.tabsActiveKey === 'items' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('addToRequisition')}
                disabled={!isOnline}
              >
                {t('add')}
              </IonButton>
            </IonCol>
          )}
          {!readOnly && state.tabsActiveKey === 'documents' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('addDocument')}
                disabled={!isOnline}
              >
                {t('add')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'Draft' && state.item.userAuthorizedForAllLocations && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('sendRequisition')}
                disabled={isEmpty(state.requisitionItems) || readOnly || !isOnline}
              >
                {t('send')}
              </IonButton>
            </IonCol>
          )}
          {['Open', 'Closed'].includes(state.item.status) && state.item.userAuthorizedForAllLocations && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('resendRequisition')}
                disabled={isEmpty(state.requisitionItems) || !isOnline}
              >
                {t('resend')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'Open' &&
            state.item.userAuthorizedForAllLocations &&
            getUserPermission('Requisitions') === 'All' && (
              <IonCol>
                <IonButton
                  expand="full"
                  color="transparent"
                  onClick={() =>
                    invokeExtraAction(state.item.approved ? 'unapproveRequisition' : 'approveRequisition')
                  }
                  disabled={isEmpty(state.requisitionItems) || !isOnline}
                >
                  {t(state.item.approved ? 'unapprove' : 'approve')}
                </IonButton>
              </IonCol>
            )}
          {readOnly ? (
            <IonCol>
              <IonButton expand="full" color="secondary" onClick={() => props.history.goBack()}>
                {t('close')}
              </IonButton>
            </IonCol>
          ) : (
            <IonCol>
              <IonButton expand="full" color="secondary" onClick={() => saveItem()} disabled={!isOnline}>
                {t('save')}
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="requisition" tab={t('requisition')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('requisitionId')}</IonLabel>
            <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('created')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.createDate, state.item.userName)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('status')}</IonLabel>
            <IonInput value={state.item.statusName} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              if (!readOnly) {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectShipTo`)
              }
            }}
            button={!readOnly}
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('shipTo')}</small>
              </IonText>
              <br />
              {state.item?.shipToAddressName || PLACEHOLDER}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('description')}</IonLabel>
            <IonInput
              value={state.item.description}
              onIonInput={(e) => setItemValue('description', e.target.value)}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('approved')}</IonLabel>
            <IonInput
              value={
                state.item.approved
                  ? formatUserTime(state.item.approvedDate, state.item.approveUserName)
                  : null
              }
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('requisitionNumber')}</IonLabel>
            <IonInput
              value={state.item.requisitionNumber}
              onIonInput={(e) => setItemValue('requisitionNumber', e.target.value)}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          {requisitionerFields.map(({ key, type }) => (
            <IonItem
              key={key}
              lines="full"
              className={cx({
                'tofino-error-item': get(state, `errors.${key}`),
                'tofino-required-item': props.customer.generalSettings.requireRequisitionerOnOrder,
              })}
            >
              <IonLabel position="stacked">{t(key)}</IonLabel>
              <IonInput
                type={type}
                inputmode={type}
                inputMode={type}
                value={state.item[key]}
                onIonInput={(e) => setItemValue(key, e.target.value)}
                placeholder={PLACEHOLDER}
              />
            </IonItem>
          ))}
          <DatePicker
            label={t('dueDate')}
            value={state.item.deliveryDueDate}
            onChange={(value) => setItemValue('deliveryDueDate', value)}
          />
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item', { 'tofino-error-item': state.errors?.sendTo })}
            onClick={() => {
              if (!readOnly) {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectSendTo`)
              }
            }}
            button={!readOnly}
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('sendTo')}</small>
              </IonText>
              <br />
              {state.item.sendToName || state.item.sendTo || PLACEHOLDER}
            </IonLabel>
          </IonItem>
          {allowPricing()
            ? [
                ...(props.customer.moduleSettings.enableTax
                  ? ['exemptTotal', 'taxableTotal', 'taxTotal']
                  : []),
                'requisitionTotal',
              ].map((each) => (
                <IonItem key={each} lines="full">
                  <IonLabel position="stacked">
                    {each === 'taxTotal'
                      ? `${t('taxTotal')} (${state.item.taxRate.toLocaleString(props.locale, {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })})`
                      : t(each)}
                  </IonLabel>
                  <IonInput value={toLocaleCurrency(state.item[each])} disabled />
                </IonItem>
              ))
            : null}
          <Comments
            value={state.item.customerComment}
            onChange={(value) => setItemValue('customerComment', value)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="items" tab={t('itemsTab')} forceRender>
          <RequisitionItems
            items={state.requisitionItems ?? []}
            onChange={(values) => {
              setState('requisitionItems', values)
              refreshRequisitionTotals()
            }}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/requisitionItems/${item.id}`)
            }}
            parentRecord={{
              ...state.item,
              requisitionItems: state.requisitionItems,
            }}
            rowActionsIsOpen={state.itemsRowActionsIsOpen}
            onRowAction={handleItemsRowAction}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Requisition"
            items={state.documentItems ?? []}
            onChange={(values) => setState('documentItems', values)}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <SendEmail
        isOpen={state.sendEmailIsOpen}
        onSubmit={handleSendEmailSubmit}
        onCancel={() => setState('sendEmailIsOpen', false)}
      />
      {['convertToOrder', 'convertToRfq'].map((key) => (
        <IonModal key={key} isOpen={get(state, `${key}IsOpen`)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton color="secondary" onClick={() => setState(`${key}IsOpen`, false)}>
                  {t('cancel')}
                </IonButton>
              </IonButtons>
              <IonTitle>{t(key)}</IonTitle>
              <IonButtons slot="end">
                <IonButton color="secondary" onClick={{ convertToOrder, convertToRfq }[key]}>
                  {t('convert')}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent forceOverscroll={false}>
            {CHECKBOXES[key].map((each) => {
              const checked = get(state, `${key}.${each}`)
              return (
                <IonItem key={each} lines="full" onClick={() => setState(`${key}.${each}`, !checked)}>
                  <IonLabel>{t(each)}</IonLabel>
                  <Icon.Check checked={checked} />
                </IonItem>
              )
            })}
          </IonContent>
        </IonModal>
      ))}
    </Page>
  )
}

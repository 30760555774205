import { createListViewContainer } from 'factories/ListView'
import { wrapItems } from 'helpers/utils'
import { getPrivateItem } from 'helpers/localForage'
import { ISSUED_ITEMS_SAVED_KEY } from 'options/inventory/issue'
import orderItemActions from 'actions/orders/orderItems'
import Component from 'components/Issue/UploadItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await getPrivateItem(ISSUED_ITEMS_SAVED_KEY, [])

      return Promise.resolve(items).then(wrapItems)
    },
    issueOrderItem: (params) => dispatch(orderItemActions.issueOrderItem(0, params)),
  }),
})(Component)

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { formatDateTime, isValidDate, tryParseDate } from 'helpers/dateTime'
import { isReadOnly } from 'helpers/formViews'

export default createChildListViewComponent({
  getStorageKey: () => 'assets.formView.warranty',
  allowSearch: () => false,
  allowDelete: (self) => (item) => !isReadOnly(self),
  renderItem: (self) => (item) => (
    <IonItem lines="full" onClick={() => self.props.onClick(item)} button>
      <IonLabel>
        {item.description}
        <br />
        <IonText color="medium">
          <small>
            {t('startDate:')} {formatDateTime(item.startDate)}
            <br />
            {t('expiryDate:')}{' '}
            <IonText
              color={
                isValidDate(item.expiryDate) && tryParseDate(item.expiryDate).getTime() < Date.now()
                  ? 'danger'
                  : undefined
              }
            >
              {formatDateTime(item.expiryDate)}
            </IonText>
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getSortByFields: (self) => [
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'startDate', languageKey: 'startDate' },
    { fieldName: 'expiryDate', languageKey: 'expiryDate' },
  ],
  getDefaultSortByField: (self) => 'description',
})()

import Icon from 'elements/Icon'
import { t } from 'helpers/i18n'

export default function (props) {
  return (
    <div className="ion-padding ion-text-center">
      {props.value?.fileType && props.value?.contents ? (
        <img
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          src={`data:${props.value.fileType};base64,${props.value.contents}`}
          alt=""
        />
      ) : (
        <div style={{ zoom: 0.95, textAlign: 'center', color: 'var(--ion-color-medium)' }}>
          <Icon type="NoPhotography" size={104} />
          <br />
          {t('noImage')}
        </div>
      )}
    </div>
  )
}

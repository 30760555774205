import { createListViewContainer } from 'factories/ListView'
import listActions from 'actions/lists'
import entityListActions from 'actions/entityLists'
import Component from 'components/Lists/ChildListView/AddList'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(listActions.getItems({ entityType: props.entityType, ...params })),
    createItems: (params) => dispatch(entityListActions.createItem(params)),
  }),
})(Component)

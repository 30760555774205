import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { isReadOnly } from 'helpers/formViews'

export default createChildListViewComponent({
  allowSearch: () => false,
  allowDelete: (self) => (item) => !isReadOnly(self) && self.props.settingsType === 'assets',
  renderItem: (self) => (item) => (
    <IonItem lines="full" onClick={() => self.props.onClick(item)} button>
      {self.props.settingsType === 'assets' ? (
        <IonLabel>
          {item.name}
          <br />
          <IonText color="medium">
            <small>
              {t('minMax:')} {item.min}/{item.max}
              <br />
              {t('notify:')} {item.notify || PLACEHOLDER}
              <br />
              {t('lastValue:')}{' '}
              {item.lastUpdated ? (
                <>
                  <IonText
                    color={item.lastValue > item.max || item.lastValue < item.min ? 'danger' : undefined}
                  >
                    {item.lastValue}
                  </IonText>
                  , {formatUserTime(item.lastUpdateDate, item.lastUpdatedBy)}
                </>
              ) : (
                PLACEHOLDER
              )}
            </small>
          </IonText>
        </IonLabel>
      ) : (
        <IonLabel>
          {item.name}
          <br />
          <IonText color="medium">
            <small>
              {t('minMax:')} {item.min}/{item.max}
              <br />
              {t('lastValue:')}{' '}
              {item.lastUpdated ? (
                <>
                  <IonText
                    color={item.lastValue > item.max || item.lastValue < item.min ? 'danger' : undefined}
                  >
                    {item.lastValue}
                  </IonText>
                  , {formatUserTime(item.lastUpdateDate, item.lastUpdatedBy)}
                </>
              ) : (
                PLACEHOLDER
              )}
              <br />
              {t('newValue:')}{' '}
              <IonText
                color={
                  item.valueChanged && (item.newValue > item.max || item.newValue < item.min)
                    ? 'danger'
                    : undefined
                }
              >
                {item.valueChanged ? item.newValue : PLACEHOLDER}
              </IonText>
            </small>
          </IonText>
        </IonLabel>
      )}
    </IonItem>
  ),
  getSortByFields: (self) => [
    { fieldName: 'name', languageKey: 'name' },
    { fieldName: 'min', languageKey: 'min' },
    { fieldName: 'max', languageKey: 'max' },
    { fieldName: 'notify', languageKey: 'notify' },
    { fieldName: 'lastValue', languageKey: 'lastValue' },
    { fieldName: 'lastUpdateDate', languageKey: 'lastUpdated' },
  ],
  getDefaultSortByField: (self) => 'name',
})()

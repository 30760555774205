import { IonItem, IonLabel, IonText } from '@ionic/react'
import b64toBlob from 'b64-to-blob'
import { saveAs } from 'file-saver'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'
import { showError } from 'helpers/errors'

export default createChildListViewComponent({
  getStorageKey: () => 'documents.childListView',
  allowSearch: () => false,
  allowDelete: (self) => (item) => true,
  renderItem: (self) => (item) => (
    <IonItem
      lines="full"
      onClick={async () => {
        try {
          if (item.fileContents && item.fileName && item.fileType) {
            saveAs(b64toBlob(item.fileContents, item.fileType), item.fileName)
          } else {
            const response = await self.props.downloadItem({
              request: {
                domainObjectId: self.props.domainObjectId,
                domainObjectType: self.props.domainObjectType,
                documentType: 'ObjectDocument',
                documentName: item.fileName,
              },
              targetTenantId: self.props.targetTenantId,
            })

            saveAs(response.value.data, item.fileName)
          }
        } catch (error) {
          showError({ error })
        }
      }}
      detail
    >
      <IonLabel>
        {item.fileName}
        <br />
        <IonText color="medium">
          <small>
            {t('dateAdded:')} {formatDateTime(item.fileDate, { showTime: true })}
            <br />
            {t('size:')} {item.fileSize}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getSortByFields: (self) => [
    { fieldName: 'fileName', languageKey: 'name' },
    { fieldName: 'fileDate', languageKey: 'dateAdded' },
    { fieldName: 'fileSize', languageKey: 'size' },
  ],
  getDefaultSortByField: (self) => 'fileName',
})()

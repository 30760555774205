import { get, remove } from 'lodash'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { createListViewContainer } from 'factories/ListView'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { isItemCounted, isItemOrdered } from 'helpers/locationCounts'
import Component from 'components/LocationCounts/CurrentCount/ReviewItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)
        .then((r) => get(r, 'locationCountDetails', []))
        .then((r) =>
          r
            .filter((each) => {
              if (params.itemStatus === 'Counted') {
                return isItemCounted(each)
              }

              if (params.itemStatus === 'Ordered') {
                return isItemOrdered(each)
              }

              return true
            })
            .filter(filterBySearch(params.search))
        )
        .then(wrapItems),
    deleteItem: (id) =>
      updatePrivateItem(LOCATION_COUNTS_CURRENT_KEY, {}, (draft) => {
        remove(draft.locationCountDetails, (each) => each.id === id)
      }),
  }),
})(Component)

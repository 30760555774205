import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'selectUser',
  getItemSize: () => 45,
  allowSearch: () => true,
  getIdField: () => 'userName',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.sendDifferenceReportTo || '',
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.sendDifferenceReportTo = each.userName
      draft.item.sendDifferenceReportToName = each.fullname
    }),
})()

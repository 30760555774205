import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import Component from 'components/Rfqs/ListView/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getLocationGroups: (params) => dispatch(locationGroupActions.getOptions(params)),
  getLocations: (params) =>
    dispatch(locationActions.getOptions({ locationVendingTypes: ['NonVending'], ...params })),
  getUsers: (params) => dispatch(userActions.getOptions({ active: 'Active', ...params })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { isEmpty } from 'lodash'
import { IonItem, IonLabel, IonButton } from '@ionic/react'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { getSessionItem } from 'helpers/sessionStorage'
import { showClientNotifications, showError } from 'helpers/errors'
import Icon from 'elements/Icon'

export default createListViewComponent({
  pageTitle: (self) => {
    const assetIds = getSessionItem('assets.listView', {}).selectedRowKeys
    return `${t('moveAssets')} (${assetIds.length} ${t('items')})`
  },
  getStorageKey: () => 'assets.listView.moveAssets',
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel>{item.displayName}</IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: () => 45,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      disabled={isEmpty(self.state.selectedRowKeys)}
      onClick={async () => {
        try {
          self.setState('loadingIsOpen', true)

          const assetIds = getSessionItem('assets.listView', {}).selectedRowKeys
          const moveToLocationId = self.state.selectedRowKeys[0]
          const response = await self.props.moveAssets({
            assetIds,
            moveToLocationId,
          })

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          self.props.history.goBack()
        } catch (error) {
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
    >
      {t('move')}
    </IonButton>
  ),
})()

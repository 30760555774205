import produce from 'immer'
import { getSessionItem } from 'helpers/sessionStorage'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import supplierActions from 'actions/suppliers'
import productActions from 'actions/products'
import supplierDetailActions from 'actions/products/supplierDetails'
import { getStorageKey } from 'components/Requisitions/Items/FormView'
import Component from 'components/Requisitions/Items/FormView/SelectSupplier'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const nonStockSuppliersOnly = getSessionItem(getStorageKey()).item.nonStock

      return dispatch(supplierActions.getOptions({ nonStockSuppliersOnly, ...params })).then((r) =>
        produce(r, (draft) => {
          if (params.pageIndex <= 1 && !params.search) {
            draft.value.data.items.unshift({ id: 0, displayName: '' })
            draft.value.data.recordCount++
          }
        })
      )
    },
    findByBarcode: (params) => dispatch(productActions.findByBarcode(params)),
    ...createChildItemsDispatchToProps(dispatch, 'SupplierDetail', supplierDetailActions),
  }),
})(Component)

import { IonItem, IonLabel, IonToggle, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { createSelectRowHandler } from 'helpers/utils'
import { showError } from 'helpers/errors'
import SelectDeselect from 'elements/SelectDeselect'

export default createListViewComponent({
  getStorageKey: () => 'distributor.offlineData',
  getViewDidEnterHandler:
    ({ isOnline, updateState }) =>
    () => {
      try {
        if (!isOnline) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('mustBeOnlineToDownloadData')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  getPopoverContent: ({ updateState }) => (
    <SelectDeselect updateState={updateState} filterItems={(each) => !each.disabled} />
  ),
  getListHeader: () => (
    <p dangerouslySetInnerHTML={{ __html: t('downloadDcribDataSummary') }} className="ion-margin" />
  ),
  renderItem:
    ({ props, state, updateState, isOnline }) =>
    (each) => (
      <IonItem key={each.id} lines="full" disabled={each.disabled}>
        <IonLabel style={{ padding: '9px 0' }}>{each.displayName}</IonLabel>
        <IonToggle
          checked={(state.selectedRowKeys ?? []).includes(each.id)}
          onIonChange={createSelectRowHandler({ each, updateState })}
          disabled={!isOnline}
        />
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state } = self
    return (
      <IonButton
        expand="full"
        color="secondary"
        onClick={() => {
          props.history.push(
            `${props.match.url}/selectCustomers?types=${(state.selectedRowKeys ?? []).join(',')}`
          )
        }}
        disabled={!self.isOnline}
      >
        {t('selectCustomers...')}
      </IonButton>
    )
  },
  allowOffline: (self) => false,
})()

import cx from 'clsx'
import { IonRow, IonCol, IonItem, IonLabel, IonDatetime } from '@ionic/react'
import { HARD_SPACE, PLACEHOLDER } from 'helpers/utils'
import { isValidDate, getDateTimeFormat } from 'helpers/dateTime'
import Icon from 'elements/Icon'

export default function (props) {
  const {
    onChange = () => {},
    disabled = false,
    value: __IGNORE__VALUE__,
    error,
    required,
    showDate = true,
    showTime = false,
    displayFormat = getDateTimeFormat({ showDate, showTime }),
    ...rest
  } = props

  const valueIsValid = isValidDate(props.value)
  const showClearButton = valueIsValid && !required && !disabled

  return (
    <IonRow {...rest}>
      <IonCol size={showClearButton ? 10 : 12}>
        <IonItem
          lines="full"
          disabled={disabled}
          className={cx({ 'tofino-required-item': required, 'tofino-error-item': error })}
        >
          <IonLabel position="stacked">{props.label}</IonLabel>
          <IonDatetime
            value={valueIsValid ? props.value : undefined}
            onIonChange={(e) => onChange(e.detail.value)}
            placeholder={PLACEHOLDER}
            min="1970"
            max="2070"
            disabled={disabled}
            displayFormat={displayFormat}
          />
          {!showClearButton && <Icon slot="end" type="CalendarToday" style={{ margin: '25px 0 0 0' }} />}
        </IonItem>
      </IonCol>
      {showClearButton && (
        <IonCol size="2">
          <IonItem
            lines="full"
            className={cx({ 'tofino-required-item': required, 'tofino-error-item': error })}
          >
            <IonLabel position="stacked">{HARD_SPACE}</IonLabel>
            <Icon
              type="Cancel"
              color="medium"
              onClick={(e) => onChange(null)}
              style={{ position: 'absolute', top: '25px', right: '20px' }}
            />
          </IonItem>
        </IonCol>
      )}
    </IonRow>
  )
}

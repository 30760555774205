import { IonItem, IonLabel, IonText } from '@ionic/react'
import { set, isEmpty } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { showError } from 'helpers/errors'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { getPrivateItem } from 'helpers/localForage'
import { getStoredInventoryForLocationId } from 'helpers/locationCounts'
import Segmenter from 'elements/Segmenter'
import Icon from 'elements/Icon'
import Header from './Header'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.checkMustCount',
  pageTitleLanguageKey: 'checkMustCount',
  getToolbarIcon: () => <Icon type="Info" size="26" outlined />,
  getPopoverContent: () => <Header className="ion-margin tofino-stacked-list" />,
  getListHeader: ({ state, updateState, firstPageIndex }) => (
    <Segmenter
      value={state.filterDto?.countStatus ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Counted', text: t('counted') },
        { value: 'NotCounted', text: t('notCounted') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.countStatus', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
    />
  ),
  renderItem: (self) => (item) => {
    const barcode = self.props.useInventoryBarcode ? item.inventoryBarcode : item.barcode

    return (
      <IonItem
        key={item.id}
        lines="full"
        routerLink={
          !self.state.currentCount?.orderId
            ? `${self.props.match.url}/${encodeURIComponent(barcode)}`
            : undefined
        }
      >
        <IonLabel>
          {barcode}
          <br />
          <IonText color="medium">
            <small>{item.itemDescription || PLACEHOLDER}</small>
            {self.props.scannerSettings.showInventoryDescription ? (
              <>
                <br />
                <small>{item.description || PLACEHOLDER}</small>
              </>
            ) : null}
          </IonText>
          <br />
          <IonText color={item.isCounted ? 'success' : 'danger'}>
            <small>{t(item.isCounted ? 'counted' : 'notCounted')}</small>
          </IonText>
        </IonLabel>
      </IonItem>
    )
  },
  allowInfiniteLoader: () => false,
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const currentCount = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)
        const items = await getStoredInventoryForLocationId(currentCount.locationId).then((r) =>
          r.filter((each) => each.mustCount && !each.lockedForCycleCount)
        )

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.currentCount = null
            draft.alertIsOpen = true
            draft.alertMessage = t('noMustCountItemsForLocation')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        } else {
          updateState((draft) => {
            draft.currentCount = currentCount
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowSearch: (self) => true,
})()

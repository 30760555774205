import { without } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'

export default createChildListViewComponent({
  getStorageKey: () => 'requisitions.formView.requisitionItems',
  allowSearch: () => true,
  allowDelete: (self) => (item) => !isReadOnly(self),
  renderItem: (self) => (item) => (
    <IonItem
      lines="full"
      onClick={() =>
        self.props.rowActionsIsOpen
          ? self.updateState((draft) => {
              draft.selectedRowKeys = draft.selectedRowKeys.includes(item.id)
                ? without(draft.selectedRowKeys, item.id)
                : [...draft.selectedRowKeys, item.id]
            })
          : self.props.onClick(item)
      }
      detail={!self.props.rowActionsIsOpen}
    >
      <IonLabel>
        {item.expedite && <Icon type="priority_high" color="danger" size="16" className="ml-neg-5" />}
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description}
            <br />
            {item.locationName}, {t('requisitioned:')} {item.quantity}
          </small>
        </IonText>
      </IonLabel>
      {self.props.rowActionsIsOpen ? (
        <Icon.Check
          color={self.state.selectedRowKeys.includes(item.id) ? 'secondary' : 'dark'}
          checked={self.state.selectedRowKeys.includes(item.id)}
        />
      ) : null}
    </IonItem>
  ),

  getRowActionItems: (self) =>
    [
      !isReadOnly(self) ? { key: 'delete', underline: true } : null,
      getUserPermission('Purchase') !== 'No' ? { key: 'copy', underline: true } : null,
      (!isReadOnly(self) || self.props.parentRecord.status === 'Closed') &&
      getUserPermission('Orders') !== 'None'
        ? { key: 'convertToOrder' }
        : null,
    ].filter(Boolean),
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'barcode' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'itemNumber', languageKey: 'itemNumber' },
    { fieldName: 'timeScanned', languageKey: 'createdDate' },
  ],
  getDefaultSortByField: (self) => 'id',
  getDefaultSortOrder: (self) => 'desc',
})()

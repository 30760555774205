import { IonItem, IonButton } from '@ionic/react'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { getSessionItem } from 'helpers/sessionStorage'
import { createCheckOfflineInventory } from 'helpers/offlineData'
import { Inventory, inventoryItemSize } from 'components/Inventory/ListView'
import Icon from 'elements/Icon'
import Filter from 'containers/Issue/IssueItem/SearchInventory/Filter'

export default createListViewComponent({
  getStorageKey: () => 'issue.issueItem.searchInventory',
  pageTitle: (self) => t('searchInventory'),
  getViewDidEnterHandler: createCheckOfflineInventory,
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <Inventory self={self} item={item} />
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: inventoryItemSize,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={async () => {
        const selectedItem = self.state.items.find((one) => self.state.selectedRowKeys.includes(one.id))
        const values = getSessionItem('issue.issueItem', {}).item

        const orderItem = {
          ...values,
          quantityIssued: values.quantityIssued || 1,
          barcode: selectedItem.barcode,
          inventoryId: selectedItem.id,
          inventoryBarcode: selectedItem.inventoryBarcode,
          description: selectedItem.itemDescription,
          assetName: values.assetName || `*${values.assetDisplayName}`,
          assetBarcode: values.assetBarcode,
          jobName: values.jobName || `*${values.jobDisplayName}`,
          jobBarcode:
            values.jobBarcode ||
            (!self.props.customer.tagSettings.tag1Restricted ? values.jobDisplayName : undefined),
          operatorName: values.operatorName || `*${values.operatorDisplayName}`,
          operatorBarcode: values.operatorBarcode,
          assetDisplayName: values.assetDisplayName,
          operatorDisplayName: values.operatorDisplayName,
          jobDisplayName: values.jobDisplayName,
          inventoryDisplayName: values.inventoryDisplayName,
          locationId: selectedItem.locationId,
          locationName: selectedItem.locationName,
          locationDisplayName: selectedItem.locationName,
        }

        try {
          self.setState('loadingIsOpen', true)

          const response = await self.props.issueOrderItem(orderItem)

          self.setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }
        } catch (error) {
          self.setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys)}
    >
      {t('issueItem')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'itemDescription', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
  ],
})(Filter)

import { isEmpty } from 'lodash'
import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getStorageItem, updateStorageItem } from 'helpers/localStorage'

const getStorageKey = () => 'customer.offlineData.inventory'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'location',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getStorageItem(getStorageKey())?.locationIds ?? [],
  getOnChange: (self) => (each) => {
    updateStorageItem(getStorageKey(), {}, (draft) => {
      if (each.id === 0) {
        draft.locationIds = []
      } else if (isEmpty(draft.locationIds)) {
        draft.locationIds = [each.id]
      } else {
        const index = draft.locationIds.findIndex((one) => one === each.id)

        if (index > -1) {
          draft.locationIds.splice(index, 1)
        } else {
          draft.locationIds.push(each.id)
        }
      }
      draft.locationNames = self.state.items
        .filter((item) => draft.locationIds.includes(item.id))
        .map((item) => item.displayName)
        .join(', ')
    })
  },
  allowMultiple: true,
})()

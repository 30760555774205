import { createFormViewContainer } from 'factories/FormView'
import actions from 'actions/rfqs'
import Component from 'components/Rfqs/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
  }),
})(Component)

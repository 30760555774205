import { cloneDeep } from 'lodash'
import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'asset',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.assetId,
  getOnChange: (self) => async (each) => {
    const [toleranceItems, warrantyItems] = await Promise.all([
      each.id ? self.props.getToleranceItems(each.id).then((r) => r.value.data.items) : Promise.resolve([]),
      each.id ? self.props.getWarrantyItems(each.id).then((r) => r.value.data.items) : Promise.resolve([]),
    ])

    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.assetId = each.id
      draft.item.assetName = each.displayName
      draft.item.locationId = each.locationId ?? 0
      draft.item.locationName = each.locationName ?? ''
      draft.toleranceItems = cloneDeep(toleranceItems)
      draft.toleranceItemsOriginal = cloneDeep(toleranceItems)
      draft.warrantyItems = cloneDeep(warrantyItems)
      draft.warrantyItemsOriginal = cloneDeep(warrantyItems)
    })
  },
})()

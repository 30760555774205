import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import inventoryActions from 'actions/inventory'
import { getSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from 'components/Inventory/FormView/Transfer'
import Component from 'components/Inventory/FormView/Transfer/SelectLocation'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { item } = getSessionItem(getStorageKey(), {})

      return dispatch(locationActions.getOptions({ locationVendingTypes: ['NonVending'], ...params })).then(
        (r) =>
          produce(r, (draft) => {
            draft.value.data.items = draft.value.data.items.filter((each) => each.id !== item.fromLocationId)
            draft.value.data.recordCount = draft.value.data.items.length
          })
      )
    },
    getMoveData: (params) => dispatch(inventoryActions.getMoveData(params)),
  }),
})(Component)

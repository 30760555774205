import { range } from 'lodash'
import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'month',
  getItemSize: () => 45,
  allowSearch: () => false,
  getValue: () => {
    const value = getSessionItem(getStorageKey(), {}).item?.recurOnMonths
    return value.length < 12 ? value : []
  },
  getOnChange: (self) => (each) => {
    updateSessionItem(getStorageKey(), {}, (draft) => {
      if (each.id === 0) {
        draft.item.recurOnMonths = range(1, 13)
      } else if (draft.item.recurOnMonths.length < 12) {
        const index = draft.item.recurOnMonths.findIndex((one) => one === each.id)

        if (index > -1) {
          draft.item.recurOnMonths.splice(index, 1)
        } else {
          draft.item.recurOnMonths.push(each.id)
        }
      } else {
        draft.item.recurOnMonths = [each.id]
      }
    })
  },
  allowMultiple: true,
})()

import styled from 'styled-components'
import { IonText, IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import cx from 'clsx'
import { snakeCase } from 'lodash'

const Container = styled(IonText)`
  display: inline;
  vertical-align: middle;
`

export default function Icon(props) {
  const { type, size = 20, bold, outlined, symbols, style, ...rest } = props

  return (
    <Container {...rest}>
      <i
        className={cx({
          'material-icons': !symbols,
          'material-icons-outlined': outlined,
          'material-symbols-outlined': symbols,
        })}
        style={{
          fontSize: size ? `${size}px` : undefined,
          fontWeight: bold ? 'bold' : undefined,
          ...style,
        }}
      >
        {snakeCase(type)}
      </i>
    </Container>
  )
}

Icon.Chevron = function Chevron(props) {
  return (
    <IonIcon
      slot="end"
      icon={chevronForward}
      style={{
        top: 0,
        right: 0,
        margin: '0 10px',
        padding: 0,
        height: '68px',
        fontSize: '20px',
        position: 'absolute',
      }}
      onClick={props.onClick}
    />
  )
}

Icon.Check = function Check({ checked, ...rest }) {
  return checked ? (
    <Icon size="28" color="secondary" type="CheckBox" outlined {...rest} />
  ) : (
    <Icon size="28" color="dark" type="CheckBoxOutlineBlank" outlined {...rest} />
  )
}

Icon.Radio = function Radio({ checked, ...rest }) {
  return checked ? (
    <Icon size="28" color="secondary" type="CheckCircleOutline" outlined {...rest} />
  ) : (
    <Icon size="28" color="dark" type="RadioButtonUnchecked" outlined {...rest} />
  )
}

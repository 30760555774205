import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { IonPopover } from '@ionic/react'
import { stopEvent } from 'helpers/events'
import Icon from 'elements/Icon'

const Text = styled.p`
  color: var(--ion-color-black-tint);
  font-size: var(--tofino-font-size-small);
`

export default function (props) {
  const { title, ...rest } = props

  const [state, updateState] = useImmer({})

  function handleIconClick(e) {
    stopEvent(e)
    updateState((draft) => {
      draft.popoverIsOpen = true
      draft.popoverEvent = e
    })
  }

  return title ? (
    <>
      <Icon type="HelpOutline" onClick={handleIconClick} {...rest} />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <Text className="ion-margin" dangerouslySetInnerHTML={{ __html: title }} />
      </IonPopover>
    </>
  ) : null
}

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import jobActions from 'actions/jobs'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import Component from 'components/TrackAssets'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getAssets: (params) => dispatch(assetActions.getItems({ pageIndex: 0, ...params })),
  getJobs: (params) => dispatch(jobActions.getOptions({ pageIndex: 0, ...params })),
  getOperators: (params) => dispatch(operatorActions.getOptions({ pageIndex: 0, ...params })),
  getTagLists: () => dispatch(tagListActions.getItemsOffline()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import userActions from 'actions/users'
import jobGroupActions from 'actions/jobGroups'
import jobReasonActions from 'actions/jobReasons'
import jobStatusOptions from 'actions/jobStatusOptions'
import assetCategoryActions from 'actions/assetCategories'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import Component from 'components/Jobs/ListView/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getLocationGroups: locationGroupActions.getItems,
  getLocations: locationActions.getItems,
  getUsers: userActions.getOptions,
  getJobGroups: jobGroupActions.getOptions,
  getJobReasons: jobReasonActions.getOptions,
  getJobStatusOptions: jobStatusOptions.getOptions,
  getAssetCategories: assetCategoryActions.getItems,
  getAssets: assetActions.getOptions,
  getOperators: operatorActions.getOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { getTranslations, tryGetTranslations } from 'helpers/i18n'
import { TRANSLATIONS_GET } from 'reducers/i18n'

export default {
  getTranslations: (params) => ({
    type: TRANSLATIONS_GET,
    payload: getTranslations(params),
  }),

  tryGetTranslations: (params) => ({
    type: `TRY_${TRANSLATIONS_GET}`,
    payload: tryGetTranslations(params),
  }),
}

import { isEmpty, orderBy } from 'lodash'
import { getChangedItems } from 'helpers/formViews'
import { showClientNotifications } from 'helpers/errors'

export const prioritizeChecklistItems = (items) =>
  orderBy(items, ['priority']).map((each, index) => ({
    ...each,
    priority: index + 1,
  }))

export const createSaveChecklistItems = (self) => async (jobId) => {
  const { checklistItems, checklistItemsOriginal } = self.state

  const { creating, updating, deleting } = getChangedItems(
    checklistItemsOriginal,
    prioritizeChecklistItems(checklistItems)
  )

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteChecklistItems(
      jobId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateChecklistItems(
      jobId,
      updating.map((each) => ({ ...each, jobId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createChecklistItems(
      jobId,
      creating.map(({ id, jobChecklistId, ...rest }) => ({
        ...rest,
        jobId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveLabourItems = (self) => async (jobId) => {
  const { creating, updating, deleting } = getChangedItems(
    self.state.labourItemsOriginal,
    self.state.labourItems
  )

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteLabourItems(
      jobId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateLabourItems(
      jobId,
      updating.map((each) => ({ ...each, jobId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createLabourItems(
      jobId,
      creating.map(({ id, jobLabourId, ...rest }) => ({
        ...rest,
        jobId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

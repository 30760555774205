import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonToggle,
  IonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import moment from 'moment'
import { get, set, isNil, isEmpty, pick, camelCase, cloneDeep, omit, trimStart } from 'lodash'
import { allowPricing } from 'helpers/auth'
import {
  strEqual,
  tryParseInt,
  getTagDisplayNameField,
  getTagName,
  tryParseFloat,
  PLACEHOLDER,
} from 'helpers/utils'
import { validateOperatorTag, validateJobTag, validateAssetTag, validateOtherTags } from 'helpers/procurement'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import DatePicker from 'elements/DatePicker'
import Icon from 'elements/Icon'
import useOnlineStatus from '@rehooks/online-status'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  '3', // Asset
  'A',
  'B',
  'C',
  'D',
  'E',
]

export const getStorageKey = () => 'inventory.draftOrder.orderItems.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({ tabsActiveKey: 'item' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const [parentRecord, item] = await Promise.all([
        props.getOrder(props.match.params.parentId).then((r) => r.value.data),
        props
          .getOrderItems(props.match.params.parentId)
          .then((r) => r.value.data.items.find((one) => strEqual(one.id, itemId)))
          .then(cloneDeep),
      ])

      const assetName = trimStart(item.assetName, '*')
      item.assetDisplayName = item.assetId
        ? await props
            .getAsset(item.assetId)
            .then((r) => r.value.data.displayName)
            .catch(() => assetName)
        : assetName

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      updateState((draft) => {
        draft.item = item
        draft.parentRecord = parentRecord
      })
    } catch (error) {
      showError({ error })
    }
  }

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}Enabled`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2', '3'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.quantityIssued) {
      setItemValue('quantityIssued', 1)
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.itemNumber)) {
      errors.itemNumber = t('errorMissingRequiredField')
    }

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateAssetTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    values.quantityIssued = Math.max(tryParseInt(values.quantityIssued, 0), 1)

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const orderItem = {
            ...values,
            quantityIssued: values.quantityIssued || 1,
            locationName: values.locationName || `*${values.locationDisplayName}`,
            locationBarcode: values.locationBarcode,
            assetName: values.assetName || `*${values.assetDisplayName}`,
            assetBarcode: values.assetBarcode,
            jobName: values.jobName || `*${values.jobDisplayName}`,
            jobBarcode:
              values.jobBarcode ||
              (!props.customer.tagSettings.tag1Restricted ? values.jobDisplayName : undefined),
            operatorName: values.operatorName || `*${values.operatorDisplayName}`,
            operatorBarcode: values.operatorBarcode,
            assetDisplayName: values.assetDisplayName,
            operatorDisplayName: values.operatorDisplayName,
            jobDisplayName: values.jobDisplayName,
            inventoryDisplayName: values.inventoryDisplayName,
            locationDisplayName: values.locationDisplayName,
          }

          const response = await props.issueOrderItem(props.match.params.parentId, orderItem)

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          props.history.goBack()
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('issuedItem')
  const backButtonHref = '/inventory/issue/draftOrder'

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)
  const createDate = moment(state.parentRecord.createDate).startOf('day')

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
          {t('save')}
        </IonButton>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="item" tab={t('item')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('barcode')}</IonLabel>
            <IonInput value={state.item.barcode} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          {props.useInventoryBarcode && (
            <IonItem lines="full">
              <IonLabel position="stacked">{t('inventoryBarcode')}</IonLabel>
              <IonInput value={state.item.inventoryBarcode} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          )}
          {['itemNumber', 'description'].map((each) => (
            <IonItem
              key={each}
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': get(state, `errors.${each}`),
              })}
            >
              <IonLabel position="stacked">{t(each)}</IonLabel>
              <IonInput
                value={get(state, `item.${each}`)}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                placeholder={PLACEHOLDER}
              />
            </IonItem>
          ))}
          <IonRow>
            <IonCol>
              <IonItem
                lines="full"
                className={cx('tofino-required-item', {
                  'tofino-error-item': state.errors?.quantityIssued,
                })}
              >
                <IonLabel position="stacked">{t('quantityIssued')}</IonLabel>
                <IonInput
                  value={state.item.quantityIssued}
                  onIonInput={(e) => setItemValue('quantityIssued', tryParseInt(e.target.value, 1))}
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  placeholder={PLACEHOLDER}
                  min={1}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
            {allowPricing() && (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('issuedPrice')} ($)</IonLabel>
                  <IonInput value={state.item.issuedPrice} disabled />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('quantityOrdered')}</IonLabel>
                <IonInput value={state.item.quantityOrdered} disabled />
              </IonItem>
            </IonCol>
            {allowPricing() && (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('orderedPrice')} ($)</IonLabel>
                  <IonInput
                    value={state.item.price}
                    onIonInput={(e) => setItemValue('price', tryParseFloat(e.target.value, 0.0))}
                    type="number"
                    inputmode="number"
                    inputMode="number"
                    placeholder={PLACEHOLDER}
                    min={0}
                    clearOnEdit
                  />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('location')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectSupplier`)
            }}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('supplier')}</small>
              </IonText>
              <br />
              {state.item.supplierName || state.item.supplierId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('supplierItemNumber')}</IonLabel>
            <IonInput value={state.item.supplierItemNumber} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <DatePicker
            label={t('dueDate')}
            value={createDate.clone().add(state.item.leadTime, 'days').toDate().toJSON()}
            onChange={(value) =>
              setItemValue('leadTime', moment(value).startOf('day').diff(createDate, 'days'))
            }
          />
          <IonItem lines="full">
            <IonLabel position="stacked">{t('manufacturer')}</IonLabel>
            <IonInput value={state.item.manufacturer} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('source')}</IonLabel>
            <IonInput value={state.item.source} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('expedited')}</IonLabel>
            <IonToggle
              checked={state.item.expedite}
              onIonChange={(e) => setItemValue('expedite', e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('taxable')}</IonLabel>
            <IonToggle
              checked={state.item.taxable}
              onIonChange={(e) => setItemValue('taxable', e.detail.checked)}
            />
          </IonItem>
        </Tabs.TabPane>
        {!isEmpty(enabledTagTypes) && (
          <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
            {enabledTagTypes.map((tagType) => (
              <IonItem
                key={tagType}
                lines="full"
                className={cx('tofino-stacked-item', {
                  'tofino-required-item': getTagIsRequired(tagType),
                  'tofino-error-item': get(state, `errors.${tagType}`),
                })}
              >
                <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
                <IonInput
                  value={getTagDisplayName(tagType)}
                  placeholder={PLACEHOLDER}
                  onIonInput={handleTagInput(tagType)}
                  onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
                />
                {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
              </IonItem>
            ))}
          </Tabs.TabPane>
        )}
      </Tabs>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

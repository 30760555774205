import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import { getLogoutUrl, logout } from 'helpers/auth'
import About from 'containers/About'
import ClearApplicationData from 'containers/Clear'
import CustomerDownloadData from 'containers/Download/Customer'
import CustomerDownloadDataSelectLocationGroup from 'containers/Download/Customer/SelectLocationGroup'
import CustomerDownloadDataSelectLocation from 'containers/Download/Customer/SelectLocation'
import DistributorDownloadData from 'containers/Download/Distributor'
import DistributorDownloadDataSelectCustomers from 'containers/Download/Distributor/SelectCustomers'
import CustomerSettings from 'containers/Settings/Customer'
import CustomerSettingsStartupPage from 'containers/Settings/Customer/Startup'
import UserSettings from 'containers/Settings/User'
import UserSettingsStartupPage from 'containers/Settings/User/Startup'
import UserSettingsLanguage from 'containers/Settings/User/Language'

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/more/about': About,
    '/more/clearApplicationData': ClearApplicationData,
    '/more/downloadOfflineData': props.userIsDcribDistributor
      ? DistributorDownloadData
      : CustomerDownloadData,
    '/more/downloadOfflineData/selectCustomers': DistributorDownloadDataSelectCustomers,
    '/more/downloadOfflineData/selectLocationGroup': CustomerDownloadDataSelectLocationGroup,
    '/more/downloadOfflineData/selectLocation': CustomerDownloadDataSelectLocation,
    '/more/settings/customerSettings': CustomerSettings,
    '/more/settings/customerSettings/startupPage': CustomerSettingsStartupPage,
    '/more/settings/userSettings': UserSettings,
    '/more/settings/userSettings/startupPage': UserSettingsStartupPage,
    '/more/settings/userSettings/language': UserSettingsLanguage,
  }),
  getNavListItemProps: (self) => ({
    logout: {
      onClick: () => {
        logout().finally(() => {
          sessionStorage.clear()
          window.location.href = getLogoutUrl(self)
        })
      },
      button: false,
    },
  }),
})

export default createTabViewContainer()(Component)

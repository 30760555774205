import { get, isEmpty, toString as str, invoke, camelCase, without, floor, intersection } from 'lodash'
import { IonItem, IonLabel, IonToggle, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import {
  getDownloadTimestampInfo,
  createHandleProgressWithPrefix,
  setSharedProducts,
  setSharedSupplierOptions,
  DOWNLOAD_BATCH_SIZE,
} from 'helpers/offlineData'
import {
  createSelectRowHandler,
  setLoadingMessage,
  setLoadingWithPrefix,
  asyncSleep,
  tryParseQueryString,
} from 'helpers/utils'
import { DOWNLOAD_PROGRESS } from 'options/events'
import { Emitter } from 'helpers/events'
import offlineDataTypes from 'options/offlineDataTypes'
import { showError } from 'helpers/errors'
import { setSharedItemNoScope } from 'helpers/localForage'
import SelectDeselect from 'elements/SelectDeselect'

export default createListViewComponent({
  getStorageKey: () => 'distributor.offlineData.selectCustomers',
  pageTitleLanguageKey: 'selectCustomers',
  getViewDidEnterHandler:
    ({ isOnline, updateState }) =>
    () => {
      try {
        if (!isOnline) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('mustBeOnlineToDownloadData')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  renderItem:
    ({ props, state, updateState, isOnline }) =>
    (item) => {
      const { downloadTimestampColor, downloadTimestampLabel } = getDownloadTimestampInfo({
        props,
        item,
      })
      return (
        <IonItem key={item.id} lines="full">
          <IonLabel>
            {item.displayName}
            <br />
            <IonText color={downloadTimestampColor}>
              <small>{`${t('downloaded:')} ${downloadTimestampLabel}`}</small>
            </IonText>
          </IonLabel>
          <IonToggle
            checked={(state.selectedRowKeys ?? []).includes(item.id)}
            onIonChange={createSelectRowHandler({ each: item, updateState })}
            disabled={!isOnline}
          />
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
  getPopoverContent: ({ updateState }) => <SelectDeselect updateState={updateState} />,
  getFooter: (self) => {
    const { props, state, updateState, setState } = self
    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.selectedRowKeys ?? []) || !self.isOnline}
        onClick={async () => {
          try {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.loadingIsOpen = true
              draft.loadingMessage = null
              draft.items.forEach((each) => {
                each.downloadSuccessful = false
              })
            })

            const types = props.location.search
              ? str(tryParseQueryString(props.location.search).types)
                  .split(',')
                  .filter((each) => !isEmpty(each))
              : []
            const customers = (await props.getCustomers().then((r) => get(r, 'value.data.items', []))).filter(
              (each) => (state.selectedRowKeys ?? []).includes(each.id)
            )

            // Supplier Options
            if (intersection(types, ['inventory']).length) {
              const response = await props.getSupplierOptions()
              await setSharedSupplierOptions(get(response, 'value.data.items', []))
            }

            // Products
            if (intersection(types, ['inventory']).length) {
              setLoadingMessage(`${t('downloading')} ${t('products')} (0%)`, true)

              let items = []
              let pageIndex = 1
              let recordCount = 0

              do {
                const response = await props.getProducts({
                  pageIndex,
                  pageSize: DOWNLOAD_BATCH_SIZE,
                })

                recordCount = get(response, 'value.data.recordCount', 0)
                items = [...items, ...get(response, 'value.data.items', [])]

                if (recordCount) {
                  setLoadingMessage(
                    `${t('downloading')} ${t('products')} (${floor((items.length * 100) / recordCount)}%)`,
                    true
                  )
                }

                pageIndex++
              } while (items.length < recordCount)

              setLoadingMessage(`${t('saving')} ${t('products')}...`, true)

              await setSharedProducts(items)
            }

            for (const [customerIndex, customer] of customers.entries()) {
              const customerId = customer.id
              const prefix = `${customer.displayName}<br />${customerIndex + 1} ${t('of')} ${
                customers.length
              } ${t('customers').toLowerCase()}<br />`

              // Locations
              if (intersection(types, ['inventory', 'jobs']).length) {
                const handleProgress = createHandleProgressWithPrefix(prefix, 'locations')
                Emitter.on(DOWNLOAD_PROGRESS, handleProgress)
                const response = await props.getLocations({ customerId })
                Emitter.off(DOWNLOAD_PROGRESS, handleProgress)

                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.locations`,
                  get(response, 'value.data.items', [])
                )
              }

              // Inventory
              if (intersection(types, ['inventory']).length) {
                setLoadingWithPrefix(prefix, `${t('downloading')} ${t('inventory')} (0%)`, true)

                let items = []
                let pageIndex = 1
                let recordCount = 0

                do {
                  const response = await props.getInventory({
                    pageIndex,
                    pageSize: DOWNLOAD_BATCH_SIZE,
                    customerId,
                  })

                  recordCount = get(response, 'value.data.recordCount', 0)
                  items = [...items, ...get(response, 'value.data.items', [])]

                  if (recordCount) {
                    setLoadingWithPrefix(
                      prefix,
                      `${t('downloading')} ${t('inventory')} (${floor((items.length * 100) / recordCount)}%)`,
                      true
                    )
                  }

                  pageIndex++
                } while (items.length < recordCount)

                setLoadingWithPrefix(prefix, `${t('saving')} ${t('inventory')}...`, true)

                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.inventory`,
                  items
                )
              }

              // Orders, Jobs, Assets
              for (const key of without(Object.keys(offlineDataTypes), 'inventory')) {
                if (intersection(types, [key]).length) {
                  const handleProgress = createHandleProgressWithPrefix(prefix, key)
                  Emitter.on(DOWNLOAD_PROGRESS, handleProgress)
                  const response = await invoke(props, camelCase(`get-${key}`), { customerId })
                  Emitter.off(DOWNLOAD_PROGRESS, handleProgress)

                  await setSharedItemNoScope(
                    `${props.tenant.subdomain}.${customerId}.offlineData.${key}`,
                    get(response, 'value.data.items', [])
                  )
                }
              }

              setLoadingWithPrefix(prefix, t('finalizingDownload...'))

              // Location Groups
              if (intersection(types, ['inventory', 'jobs']).length) {
                const response = await props.getLocationGroups({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.locationGroups`,
                  get(response, 'value.data.items', [])
                )
              }

              // User Options
              if (intersection(types, ['jobs']).length) {
                const response = await props.getUserOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.users.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Job Status Options
              if (intersection(types, ['jobs']).length) {
                const response = await props.getJobStatusOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.jobStatus.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Job Group Options
              if (intersection(types, ['jobs']).length) {
                const response = await props.getJobGroupOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.jobGroups.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Job Reason Options
              if (intersection(types, ['jobs']).length) {
                const response = await props.getJobReasonOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.jobReasons.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Job Reason Groups Options
              if (intersection(types, ['jobs']).length) {
                const response = await props.getJobReasonGroupOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.jobReasonGroups.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Operator Options
              if (intersection(types, ['inventory', 'jobs']).length) {
                const response = await props.getOperatorOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.operators.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Asset Categories
              if (intersection(types, ['inventory', 'jobs', 'assets']).length) {
                const response = await props.getAssetCategories({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.assetCategories`,
                  get(response, 'value.data.items', [])
                )
              }

              // Asset Options
              if (intersection(types, ['inventory', 'jobs']).length) {
                const response = await props.getAssetOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.assets.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // Job Options
              if (intersection(types, ['inventory']).length) {
                const response = await props.getJobOptions({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.jobs.options`,
                  get(response, 'value.data.items', [])
                )
              }

              // TagLists
              if (intersection(types, ['inventory']).length) {
                const response = await props.getTagLists({ customerId })
                await setSharedItemNoScope(
                  `${props.tenant.subdomain}.${customerId}.offlineData.tagLists`,
                  get(response, 'value.data.items', [])
                )
              }

              // Impersonation & Translations
              const impersonation = await props.tryLoginAsCustomer(customerId).then((r) => r.value.data)
              const translations = await props
                .tryGetTranslations({
                  customerId,
                  code: impersonation.user.languageCode,
                })
                .then((r) => r.value.data)

              await setSharedItemNoScope(
                `${props.tenant.subdomain}.0.impersonations.${customerId}`,
                impersonation
              )
              await setSharedItemNoScope(
                `${props.tenant.subdomain}.${customerId}.translations.${impersonation.user.languageCode}`,
                translations
              )
              updateState((draft) => {
                draft.items
                  .filter((each) => each.id === customerId)
                  .forEach((each) => {
                    each.downloadSuccessful = true
                    each.downloadTimestamp = new Date().toJSON()
                  })
              })
            }

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('downloadedDataSuccessfully')
              draft.alertButtons = [t('ok')]
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          }
        }}
      >
        {t('download')}
      </IonButton>
    )
  },
  allowSearch: (self) => true,
  allowOffline: (self) => false,
})()

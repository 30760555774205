import { set } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { formatUserTime, formatDateTime } from 'helpers/dateTime'
import Segmenter from 'elements/Segmenter'
import Filter from 'containers/Jobs/ListView/Filter'

export default createListViewComponent({
  getStorageKey: () => 'jobs.listView',
  getInsignificantFilterDtoFields: (self) => ['dateRange', 'dateRangeField', 'active'],
  getListHeader: ({ props, state, updateState, fetchItems, firstPageIndex, isOnline }) => (
    <Segmenter
      value={state.filterDto?.active ?? 'Active'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Active', text: t('open') },
        { value: 'Inactive', text: t('closed') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.active', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
      disabled={!isOnline}
    />
  ),
  renderItem: (self) => (item) => (
    <IonItem key={item.id} lines="full" routerLink={`${self.props.match.url}/${item.id}`}>
      <IonLabel>
        {item.barcode} - {item.name}
        <br />
        <IonText color="medium">
          <small>
            {item.activeDescription}, {item.locationName || PLACEHOLDER}
            <br />
            {t('asset:')} {item.assetName || t('none')}
            <br />
            {t('created:')} {formatUserTime(item.createdDate, item.createdBy)}
            <br />
            {t('dueDate:')} {formatDateTime(item.dueDate) || PLACEHOLDER}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getItemSize: () => 111,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowDelete: (self) => (item) => getUserPermission('Jobs') === 'All',
  allowOffline: (self) => false,
  getPopoverContent: (self) => (
    <IonItem
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
        self.props.history.push(`${self.props.match.url}/0`)
      }}
    >
      <IonLabel>{t('createJob')}</IonLabel>
    </IonItem>
  ),
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={() => {
        self.props.history.push(`${self.props.match.url}/0`)
      }}
      disabled={!self.isOnline}
    >
      {t('createJob')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'statusDescription', languageKey: 'status' },
    { fieldName: 'createdDate', languageKey: 'createdDate' },
    { fieldName: 'dueDate', languageKey: 'dueDate' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'assetBarcode', languageKey: 'asset' },
  ],
})(Filter)

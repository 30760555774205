import { createListViewContainer } from 'factories/ListView'
import assetActions from 'actions/assets'
import Component from 'components/Assets/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(assetActions.getListViewItems(params)),
    deleteItem: (id) => dispatch(assetActions.deleteItems([id])),
    deleteItems: (params) => dispatch(assetActions.deleteItems(params)),
    checkinAssets: (params) => dispatch(assetActions.checkinAssets(params)),
  }),
})(Component)

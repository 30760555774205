import { createListViewContainer } from 'factories/ListView'
import rfqActions from 'actions/rfqs'
import Component from 'components/Rfqs/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(rfqActions.getListViewItems({ locationVendingTypes: ['NonVending'], ...params })),
    deleteItem: (id) => dispatch(rfqActions.deleteItems([id])),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import inventoryActions from 'actions/inventory'
import cycleCountDetailActions from 'actions/cycleCounts/cycleCountDetails'
import { getSessionItem } from 'helpers/sessionStorage'
import Component from 'components/CycleCounts/FormView/AddFromInventory'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const { locationId } = getSessionItem('cycleCounts.formView', {}).item

      return dispatch(
        inventoryActions.getListViewItems({
          ...params,
          locationIds: locationId ? [locationId] : [],
          unlockedInventoryOnly: true,
        })
      )
    },
    populateCycleCountItems: (cycleCountId, params) =>
      dispatch(cycleCountDetailActions.populateCycleCountItems(cycleCountId, params)),
  }),
})(Component)

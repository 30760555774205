import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import Component from 'components/Requisitions/Items/FormView/SelectLocation'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(locationActions.getItems({ locationVendingTypes: ['NonVending'], ...params })),
  }),
})(Component)

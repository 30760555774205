import { isNil, without } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { isReadOnly } from 'helpers/formViews'
import Icon from 'elements/Icon'
import Filter from 'containers/Orders/Items/Filter'

export default createChildListViewComponent({
  getStorageKey: () => 'orders.formView.orderItems',
  allowSearch: () => true,
  allowDelete: (self) => (item) => !isReadOnly(self),
  filterItems: (self) => (each) => {
    const { supplierId } = self.state.filterDto ?? {}
    const usagePurchase = self.state.filterDto?.usagePurchase ?? 'All'

    if (!isNil(supplierId) && each.supplierId !== supplierId) {
      return false
    }

    if (usagePurchase === 'UsageOnly' && each.quantityUsed === 0 && each.quantityUsed2 === 0) {
      return false
    }

    if (usagePurchase === 'PurchaseOnly' && each.quantityOrdered === 0) {
      return false
    }

    return true
  },
  renderItem: (self) => (item) => (
    <IonItem
      lines="full"
      onClick={() =>
        self.props.rowActionsIsOpen
          ? self.updateState((draft) => {
              draft.selectedRowKeys = draft.selectedRowKeys.includes(item.id)
                ? without(draft.selectedRowKeys, item.id)
                : [...draft.selectedRowKeys, item.id]
            })
          : self.props.onClick(item)
      }
      detail={!self.props.rowActionsIsOpen}
    >
      <IonLabel>
        {item.expedite && <Icon type="priority_high" color="danger" size="16" className="ml-neg-5" />}
        {self.props.useInventoryBarcode ? item.inventoryBarcode || item.barcode : item.barcode}{' '}
        {item.lockedForCycleCount && <Icon type="Lock" color="danger" size="15" />}
        <br />
        <IonText color="medium">
          <small>
            {item.description}
            <br />
            {item.locationName},{' '}
            {item.quantityIssued > 0 && (
              <>
                {t('issued:')} {item.quantityIssued},
              </>
            )}{' '}
            {t('ordered:')} {item.quantityOrdered}, {t('received:')} {item.quantityReceived}
            <br />
            {t('supplier:')} {item.supplierName}
          </small>
        </IonText>
      </IonLabel>
      {self.props.rowActionsIsOpen ? (
        <Icon.Check
          color={self.state.selectedRowKeys.includes(item.id) ? 'secondary' : 'dark'}
          checked={self.state.selectedRowKeys.includes(item.id)}
        />
      ) : null}
    </IonItem>
  ),
  getRowActionItems: (self) =>
    [
      !isReadOnly(self) ? { key: 'delete', underline: true } : null,
      getUserPermission('Purchase') !== 'No' ? { key: 'copy' } : null,
    ].filter(Boolean),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'quantityOrdered', languageKey: 'quantityOrdered' },
    { fieldName: 'quantityReceived', languageKey: 'quantityReceived' },
  ],
  getDefaultSortByField: (self) => 'id',
  getDefaultSortOrder: (self) => 'desc',
})(Filter)

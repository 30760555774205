import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import cycleCountActions from 'actions/cycleCounts'
import locationActions from 'actions/locations'
import Component from 'components/CycleCounts/Create'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  createItem: cycleCountActions.createItem,
  getItemCount: locationActions.getItemCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonTextarea,
  IonLoading,
  IonText,
  IonToggle,
  IonSpinner,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isEmpty, pick, isNil, omit } from 'lodash'
import { PLACEHOLDER, tryParseInt, asyncSleep } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Page from 'elements/Page'
import useOnlineStatus from '@rehooks/online-status'

export const getStorageKey = () => 'jobRequests.formView.createJob'

export default function (props) {
  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function saveItem() {
    try {
      setState('loadingIsOpen', true)

      const response = await props.createJob(state.item)

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      await asyncSleep()

      if (state.item.addJobRequestDocumentsToJob) {
        const copied = await props.copyAllObjectDocuments({
          sourceRequest: {
            domainObjectId: state.item.id,
            domainObjectType: 'JobRequest',
          },
          destinationRequest: {
            domainObjectId: response.value.data.items[0].id,
            domainObjectType: 'Job',
          },
        })

        showClientNotifications({ response: copied })

        if (copied.value.data.failureCount > 0) {
          throw new Error()
        }
      }

      await asyncSleep()

      removeSessionItem('jobRequests.formView')

      props.history.goBack()
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      setState('item', {
        id: tryParseInt(props.match.params.itemId),
        comment: '',
        jobTemplateId: 0,
        jobTemplateName: '',
        addJobRequestDocumentsToJob: false,
      })
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('createJob')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton
          color="secondary"
          expand="full"
          disabled={isEmpty(state.item.comment) || !isOnline}
          onClick={() => saveItem()}
        >
          {t('createJob')}
        </IonButton>
      }
    >
      <IonItem lines="full" className="tofino-required-item">
        <IonLabel position="stacked">{t('comments')}</IonLabel>
        <IonTextarea
          value={state.item.comment}
          onIonInput={(e) => setItemValue('comment', e.target.value)}
          rows={8}
          placeholder={PLACEHOLDER}
          autoGrow
        />
      </IonItem>
      <IonItem
        lines="full"
        className="tofino-stacked-item"
        onClick={() => {
          setSessionItem(getStorageKey(), pick(state, ['item']))
          props.history.push(`${props.match.url}/selectJobTemplate`)
        }}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('jobTemplate')}</small>
          </IonText>
          <br />
          {state.item.jobTemplateName || state.item.jobTemplateId || t('none')}
        </IonLabel>
      </IonItem>
      <IonItem lines="full">
        <IonLabel>{t('addJobRequestDocumentsToJob')}</IonLabel>
        <IonToggle
          checked={state.item.addJobRequestDocumentsToJob}
          onIonChange={(e) => setItemValue('addJobRequestDocumentsToJob', e.detail.checked)}
        />
      </IonItem>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { WEEKS_OF_MONTH } from 'options/tasks'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/Tasks/FormView/SelectRecurOnWeeksOfMonth'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(WEEKS_OF_MONTH.map((each, index) => ({ id: index + 1, displayName: t(each) }))),
  }),
})(Component)

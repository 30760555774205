import { createChildFormViewContainer } from 'factories/ChildFormView'
import actions from 'actions/jobs/jobLabour'
import jobLabourRateActions from 'actions/jobLabourRates'
import Component from 'components/Jobs/Labour/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getJobLabourRates: (params = {}) =>
      dispatch(jobLabourRateActions.getItems({ active: 'Active', ...params })),
  }),
})(Component)

import * as React from 'react'
import { Route } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import useOnlineStatus from '@rehooks/online-status'
import { get, without } from 'lodash'
import { getMenuItemAllLevels, getPossibleRoutes } from 'helpers/auth'
import { setStorageItem } from 'helpers/localStorage'
import { LAST_TAB_VISITED_STORAGE_KEY } from 'options/auth'
import NavList from 'elements/NavList'
import Page from 'elements/Page'
import NotFound from 'containers/NotFound'
import Exit from 'elements/Exit'

export default ({ getAvailableRoutes = () => ({}), getNavListItemProps = () => ({}) } = {}) =>
  (props) => {
    const isOnline = useOnlineStatus()

    React.useEffect(() => {
      if (props.impersonator) {
        setStorageItem(LAST_TAB_VISITED_STORAGE_KEY, props.match.url)
      }
    }, [])

    const availableRoutes = getAvailableRoutes({ props })
    const possibleRoutes = getPossibleRoutes(
      get(getMenuItemAllLevels(props.match.url), '[0].items', []),
      props.match.url
    )

    return (
      <IonRouterOutlet>
        <Route path={props.match.url} exact>
          <Page toolbarButton={<Exit />}>
            <NavList listItemProps={getNavListItemProps({ props, isOnline })} />
          </Page>
        </Route>
        {Object.entries(availableRoutes).map(([path, component]) => (
          <Route key={path} path={path} component={component} exact />
        ))}
        {without(possibleRoutes, ...Object.keys(availableRoutes)).map((path) => (
          <Route key={path} path={path} component={NotFound} exact />
        ))}
      </IonRouterOutlet>
    )
  }

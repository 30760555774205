export const FREQUENCIES = {
  Once: 'once',
  Hourly: 'hourly',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Yearly: 'yearly',
  Hours: 'basedOnServiceHours',
  Miles: 'basedOnMileage',
}

export const JOB_FREQUENCIES = ['Hours', 'Miles']

export const RECURRING_FREQUENCIES = {
  Daily: 'repeatEveryNDays',
  Weekly: 'repeatEveryNWeeks',
  Yearly: 'repeatEveryNYears',
}

export const MONTH_MODES = {
  MonthDay: 'MonthDay',
  Weekday: 'Weekday',
}

export const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const WEEKS_OF_MONTH = ['first', 'second', 'third', 'fourth', 'last']

export const EMAIL_SETTINGS_CHECKS = [
  'includeHtmlInEmail',
  'includeHtmlAsAttachment',
  'includeCsvAsAttachment',
  'includeExcelAsAttachment',
  'includePdfAsAttachment',
  'includeJsonAsAttachment',
  'includeXmlAsAttachment',
]

export const SKIP_NOTIFICATION_CHECKS = [
  'jobTaskSettings.skipIfPreviousJobNotComplete',
  'jobTaskSettings.skipIfAssetOutOfService',
  'jobTaskSettings.skipIfAssetCheckedOut',
]

export const MONTHS = {
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
}

import * as React from 'react'
import cx from 'clsx'
import { useImmer } from 'use-immer'
import { IonSpinner, IonButton, IonItem, IonLabel, IonInput, useIonViewDidEnter } from '@ionic/react'
import { set, toString as str, cloneDeep, isEmpty, isNil } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showValidationErrors, showError } from 'helpers/errors'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { tryParseMoment, MIN_TIME, MAX_TIME } from 'helpers/dateTime'
import Page from 'elements/Page'
import DatePicker from 'elements/DatePicker'

export default function (props) {
  const { parentStorageKey = 'assets.formView' } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  function fetchItem() {
    const parentState = getSessionItem(parentStorageKey)
    const item =
      props.match.params.itemId === '0'
        ? { id: Date.now() * -1, assetId: parentState.item.assetId }
        : parentState.warrantyItems.find((one) => str(one.id) === props.match.params.itemId)

    updateState((draft) => {
      draft.item = cloneDeep(item)
      draft.parentState = parentState
    })
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    if (isEmpty(values.startDate)) {
      errors.startDate = t('errorMissingRequiredField')
    } else {
      const startDate = tryParseMoment(values.startDate, MIN_TIME)
      const expiryDate = tryParseMoment(values.expiryDate, MAX_TIME)

      if (startDate > expiryDate) {
        errors.startDate = t('startDateValidationError')
      }
    }

    if (isEmpty(values.expiryDate)) {
      errors.expiryDate = t('errorMissingRequiredField')
    } else {
      const startDate = tryParseMoment(values.startDate, MIN_TIME)
      const expiryDate = tryParseMoment(values.expiryDate, MAX_TIME)

      if (startDate > expiryDate) {
        errors.expiryDate = t('expiryDateValidationError')
      }
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.warrantyItems.findIndex((one) => str(one.id) === props.match.params.itemId)

            if (index > -1) {
              draft.warrantyItems.splice(index, 1, values)
            } else {
              draft.warrantyItems.unshift(values)
            }

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  if (isNil(state.item)) {
    return (
      <Page title={PLACEHOLDER}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const { readOnly } = state.parentState ?? {}
  const pageTitle =
    props.match.params.itemId === '0' ? t('addWarranty') : state.item.description || PLACEHOLDER

  return (
    <Page
      title={pageTitle}
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
            {props.match.params.itemId === '0' ? t('add') : t('update')}
          </IonButton>
        )
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.description })}
      >
        <IonLabel position="stacked">{t('description')}</IonLabel>
        <IonInput
          value={state.item.description}
          onIonInput={(e) => setItemValue('description', e.target.value)}
          placeholder={PLACEHOLDER}
          disabled={readOnly}
        />
      </IonItem>
      <DatePicker
        error={state.errors?.startDate}
        label={t('startDate')}
        value={state.item.startDate}
        onChange={(value) => setItemValue('startDate', value)}
        placeholder={PLACEHOLDER}
        disabled={readOnly}
        required
      />
      <DatePicker
        error={state.errors?.expiryDate}
        label={t('expiryDate')}
        value={state.item.expiryDate}
        onChange={(value) => setItemValue('expiryDate', value)}
        placeholder={PLACEHOLDER}
        disabled={readOnly}
        required
      />
    </Page>
  )
}

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { setStorageItem } from 'helpers/localStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'labourRate',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.labourRateId,
  getOnChange: (self) => (each) => {
    setStorageItem('jobs.formView.labour.formView.labourRateId', each.id)
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.labourRateId = each.id
      draft.item.labourRateDescription = each.displayName
      draft.item.totalCost = draft.item.actualHours * each.rate
    })
  },
})()

import produce from 'immer'
import localForage from 'localforage'
import { set, isNil, toString as str, isEmpty, isEqual } from 'lodash'
import { getSessionItem } from 'helpers/sessionStorage'
import { AUTH_SESSION_KEY } from 'options/auth'
import lzwCompress from 'lzwcompress'

export const sharedStorage = localForage.createInstance({ name: 'sharedStorage' })

export const privateStorage = localForage.createInstance({ name: 'privateStorage' })

set(window, 'sharedStorage', sharedStorage)
set(window, 'privateStorage', privateStorage)
set(window, 'lzwPack', lzwCompress.pack)
set(window, 'lzwUnpack', lzwCompress.unpack)

const getSharedScope = (key) => {
  const auth = getSessionItem(AUTH_SESSION_KEY, {})
  const subdomain = auth.current?.tenant?.subdomain
  const customerId = auth.current?.customer?.id

  return [process.env.REACT_APP_NAME, subdomain, customerId, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')
}

const getPrivateScope = (key) => {
  const auth = getSessionItem(AUTH_SESSION_KEY, {})
  const subdomain = auth.current?.tenant?.subdomain
  const customerId = auth.current?.customer?.id
  const userName = auth.current?.user?.userName

  return [process.env.REACT_APP_NAME, subdomain, customerId, userName, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')
}

export const getSharedItem = async (key, defaultValue) => {
  try {
    const serializedValue = await sharedStorage.getItem(getSharedScope(key))

    return isNil(serializedValue) ? defaultValue : lzwCompress.unpack(serializedValue)
  } catch (error) {
    return defaultValue
  }
}

export const getSharedItemNoScope = async (key, defaultValue) => {
  try {
    const serializedValue = await sharedStorage.getItem(`${process.env.REACT_APP_NAME}.${key}`)

    return isNil(serializedValue) ? defaultValue : lzwCompress.unpack(serializedValue)
  } catch (error) {
    return defaultValue
  }
}

export const setSharedItem = async (key, value) => {
  const getStorageKey = () => getSharedScope(key)

  await sharedStorage.setItem(getStorageKey(), lzwCompress.pack(value))
  await sharedStorage.setItem(`${getStorageKey()}.timestamp`, lzwCompress.pack(new Date().toJSON()))
}

export const setSharedItemNoScope = async (key, value) => {
  const getStorageKey = () => `${process.env.REACT_APP_NAME}.${key}`

  await sharedStorage.setItem(getStorageKey(), lzwCompress.pack(value))
  await sharedStorage.setItem(`${getStorageKey()}.timestamp`, lzwCompress.pack(new Date().toJSON()))
}

export const updateSharedItem = async (key, defaultValue, recipe) => {
  const value = await getSharedItem(key, defaultValue)
  const before = produce(value, recipe)
  await setSharedItem(key, before)
  const after = await getSharedItem(key)
  return isEqual(before, after)
}

export const updateSharedItemNoScope = async (key, defaultValue, recipe) => {
  const value = await getSharedItemNoScope(key, defaultValue)
  const before = produce(value, recipe)
  await setSharedItemNoScope(key, before)
  const after = await getSharedItemNoScope(key)
  return isEqual(before, after)
}

export const removeSharedItem = async (key) => {
  try {
    await sharedStorage.removeItem(getSharedScope(key))
  } catch (error) {
    console.warn(error)
  }
}

export const removeSharedItemNoScope = async (key) => {
  try {
    await sharedStorage.removeItem(`${process.env.REACT_APP_NAME}.${key}`)
  } catch (error) {
    console.warn(error)
  }
}

export const getPrivateItem = async (key, defaultValue) => {
  try {
    const serializedValue = await privateStorage.getItem(getPrivateScope(key))

    return isNil(serializedValue) ? defaultValue : lzwCompress.unpack(serializedValue)
  } catch (error) {
    return defaultValue
  }
}

export const getPrivateItemNoScope = async (key, defaultValue) => {
  try {
    const serializedValue = await privateStorage.getItem(`${process.env.REACT_APP_NAME}.${key}`)

    return isNil(serializedValue) ? defaultValue : lzwCompress.unpack(serializedValue)
  } catch (error) {
    return defaultValue
  }
}

export const setPrivateItem = async (key, value) => {
  const getStorageKey = () => getPrivateScope(key)

  await privateStorage.setItem(getStorageKey(), lzwCompress.pack(value))
  await privateStorage.setItem(`${getStorageKey()}.timestamp`, lzwCompress.pack(new Date().toJSON()))
}

export const setPrivateItemNoScope = async (key, value) => {
  const getStorageKey = () => `${process.env.REACT_APP_NAME}.${key}`

  await privateStorage.setItem(getStorageKey(), lzwCompress.pack(value))
  await privateStorage.setItem(`${getStorageKey()}.timestamp`, lzwCompress.pack(value))
}

export const updatePrivateItem = async (key, defaultValue, recipe) => {
  const value = await getPrivateItem(key, defaultValue)
  const before = produce(value, recipe)
  await setPrivateItem(key, before)
  const after = await getPrivateItem(key)
  return isEqual(before, after)
}

export const updatePrivateItemNoScope = async (key, defaultValue, recipe) => {
  const value = await getPrivateItemNoScope(key, defaultValue)
  const before = produce(value, recipe)
  await setPrivateItemNoScope(key, before)
  const after = await getPrivateItemNoScope(key)
  return isEqual(before, after)
}

export const removePrivateItem = async (key) => {
  try {
    await privateStorage.removeItem(getPrivateScope(key))
  } catch (error) {
    console.warn(error)
  }
}

export const removePrivateItemNoScope = async (key) => {
  try {
    await privateStorage.removeItem(`${process.env.REACT_APP_NAME}.${key}`)
  } catch (error) {
    console.warn(error)
  }
}

import { get, isEmpty, remove } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { sendClientLog } from 'helpers/auth'
import { removePrivateItem, updatePrivateItem, setPrivateItem } from 'helpers/localForage'
import { COUNTED_ITEMS_SAVED_KEY, COUNTED_ITEMS_UPLOAD_RESULTS_KEY } from 'options/inventory/count'
import { setLoadingMessage, asyncSleep } from 'helpers/utils'

export default createListViewComponent({
  getStorageKey: () => 'inventory.count.uploadItems',
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const items = await props.getItems().then((r) => get(r, 'value.data.items', []))

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToUpload')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  getSearchHeader: ({ state }) => {
    const readyForUpload = (state.items ?? []).length

    return (
      <div className="ion-padding-horizontal">
        <p className="font-weight-500">{`${readyForUpload} ${t('countedItemsReadyForUpload')}`}</p>
        <p>
          <IonText color="medium">
            <span dangerouslySetInnerHTML={{ __html: t('uploadCountedItemsInfo') }} />
          </IonText>
        </p>
        <p>
          <IonText color="danger">{`${t('uploadDataWarning')}`}</IonText>
        </p>
      </div>
    )
  },
  renderItem: () => (item) => (
    <IonItem key={item.id} lines="full" className="ion-hide">
      <IonLabel>{item.id}</IonLabel>
    </IonItem>
  ),
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, updateState, setState, fetchItems } = self
    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('upload'),
        handler: async () => {
          try {
            const items = state.items ?? []

            await removePrivateItem(COUNTED_ITEMS_UPLOAD_RESULTS_KEY)

            updateState((draft) => {
              draft.loadingIsOpen = true
              draft.loadingMessage = t('uploading...')
              draft.uploadButtonDisabled = true
            })

            const response = await props.updateOnHand(items)

            console.assert(response.value.data.items.length === items.length)

            setLoadingMessage(t('finalizingUpload...'))

            const uploadResults = response.value.data.items.map((each) => ({
              ...items.find((one) => one.locationId === each.locationId && one.barcode === each.barcode),
              ...each,
            }))

            await setPrivateItem(COUNTED_ITEMS_UPLOAD_RESULTS_KEY, uploadResults)

            for (let retry = 1; retry <= 3; retry++) {
              const success = await updatePrivateItem(COUNTED_ITEMS_SAVED_KEY, [], (draft) => {
                remove(
                  draft,
                  (each) =>
                    uploadResults.find(
                      (one) => one.locationId === each.locationId && one.barcode === each.barcode
                    ).id !== 0
                )
              })

              if (success) {
                break
              } else {
                await sendClientLog('Error', 'Upload Items: Inventory Count', { retry })
                await asyncSleep(retry * 1000)
              }
            }

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage =
                response.value.data.failureCount > 0
                  ? t('issuedItemsUploadedWithErrors')
                  : t('issuedItemsUploadedSuccessfully')
              draft.alertButtons = [
                {
                  text: t('ok'),
                  role: 'cancel',
                  handler: () => {
                    window.location.href = '/inventory/count/uploadResults'
                  },
                },
              ]
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            setState('uploadButtonDisabled', false)
            showError({ error })
            fetchItems()
          }
        },
      },
    ]

    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.items) || state.uploadButtonDisabled || !self.isOnline}
        onClick={() => {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('confirmUploadData')
            draft.alertButtons = buttons
          })
        }}
      >
        {t('upload')}
      </IonButton>
    )
  },
  allowOffline: (self) => false,
})()

import { get, toLower } from 'lodash'

const user = (state = {}) => get(state, 'auth.current.user', {})
const tenant = (state = {}) => get(state, 'auth.current.tenant', {})
const customer = (state = {}) => get(state, 'auth.current.customer', {})
const menuItems = (state = {}) => get(state, 'auth.current.menuItems', [])
const tenantTypeIsDcrib = (state = {}) => toLower(tenant(state).tenantType) === 'dcrib'
const userIsInRoleDistributor = (state = {}) => user(state).userIsInRoleDistributor
const userIsInRoleStaff = (state = {}) => user(state).userIsInRoleStaff

export default {
  user,
  tenant,
  customer,
  menuItems,
  userIsInRoleStaff,
  tenantTypeIsDcrib,
  userIsInRoleDistributor,
  logo: (state = {}) => state.auth?.current?.logo,
  locale: (state = {}) => get(user(state), 'languageCode', 'en'),
  locales: (state = {}) => state.i18n?.locales ?? [],
  impersonator: (state = {}) => state.auth?.impersonator,
  ssoLogoutUrl: (state = {}) => state?.auth?.current?.ssoLogoutUrl,
  userIsInRoleSupplier: (state = {}) => user(state).userIsInRoleSupplierAdmin,
  userIsDcribCustomer: (state = {}) => tenantTypeIsDcrib(state) && !userIsInRoleDistributor(state),
  userIsDcribDistributor: (state = {}) => tenantTypeIsDcrib(state) && userIsInRoleDistributor(state),
  tenantTypeIsTrms: (state = {}) => toLower(tenant(state).tenantType) === 'trms',
  tenantGroupIsCardinal: (state = {}) => toLower(user(state).tenantGroup) === 'cardinal',
  enableAssetManagement: (state = {}) => get(customer(state), 'moduleSettings.enableAssetManagement'),
  allowedFileTemplateTypes: (state = {}) => get(state, 'auth.current.allowedFileTemplateTypes', {}),
  tenantIsFnl: (state = {}) => ['fnlsafety', 'fnlaudit'].includes(toLower(tenant(state).subdomain)),
  // Mobile
  scannerSettings: (state = {}) => get(customer(state), 'scannerSettings', {}),
  useInventoryBarcode: (state = {}) => get(customer(state), 'generalSettings.useInventoryBarcode'),
}

import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createPost } from 'helpers/api'

const entityName = entityNames.dataImport
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const getItemStatus = createPost(entityName, { action: 'getItemStatus' })

export default {
  ...createActions(entityName),

  getItemStatus: (params) => ({
    type: `${ENTITY_NAME}_ITEM_STATUS_GET`,
    payload: getItemStatus(params),
  }),
}

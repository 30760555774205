import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPopover,
  IonLoading,
  IonAlert,
  IonSpinner,
  IonInput,
  IonText,
  IonRow,
  IonCol,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { useDebouncedCallback } from 'use-debounce'
import {
  get,
  set,
  unset,
  isNil,
  isEmpty,
  isEqual,
  cloneDeep,
  pick,
  uniqBy,
  invoke,
  remove,
  omit,
  camelCase,
  uniq,
} from 'lodash'
import { message, PLACEHOLDER, asyncSleep, isSignificant, DEBOUNCE } from 'helpers/utils'
import { calculateOrderTotals, getReceivedItems } from 'helpers/procurement'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { formatUserTime, isValidDate } from 'helpers/dateTime'
import { allowPricing, getUserPermission } from 'helpers/auth'
import { Emitter } from 'helpers/events'
import { getChangedItems, createSaveDocumentItems, createLabelFactory } from 'helpers/formViews'
import { getStorageItem } from 'helpers/localStorage'
import requisitionerFields from 'options/requisitionerFields'
import Tabs from 'elements/Tabs'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Comments from 'elements/Comments'
import Documents from 'containers/Documents/ChildListView'
import FileUpload from 'elements/FileUpload'
import OrderItems from 'containers/Orders/Items'
import OrderSuppliers from 'containers/Orders/Suppliers'
import SendEmail from 'elements/SendEmail'
import Help from 'elements/Help'
import PackingListNumber from './PackingListNumber'

export const getStorageKey = () => 'orders.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/orders'
    }
  }, [])

  const [state, updateState] = useImmer({
    tabsActiveKey: 'items',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props.newItem().then((r) => cloneDeep(r.value.data))

      const [fieldSettings, orderItems, orderSupplierItems, documentItems] = await Promise.all([
        props.getSettings({ type: 'order' }).then((r) => r.value.data.fieldSettings),

        item.id
          ? props.getOrderItems(item.id, { includeInventoryStatus: true }).then((r) => r.value.data.items)
          : Promise.resolve([]),
        item.id ? props.getOrderSupplierItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
        item.id
          ? props
              .getDocumentItems({
                request: {
                  domainObjectId: item.id,
                  domainObjectType: 'Order',
                  documentType: 'ObjectDocument',
                },
              })
              .then((r) => r.value.data.items)
          : Promise.resolve([]),
      ])

      const populatedOrderItems = orderItems.map((each) => ({
        ...each,
        populated: true,
        timestamp: new Date().toJSON(),
      }))

      updateState((draft) => {
        draft.fieldSettings = fieldSettings
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.orderItems = cloneDeep(populatedOrderItems)
        draft.orderItemsOriginal = cloneDeep(populatedOrderItems)
        draft.orderSupplierItems = cloneDeep(orderSupplierItems)
        draft.orderSupplierItemsOriginal = cloneDeep(orderSupplierItems)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function refetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const item = await props.getItem(itemId).then((r) => cloneDeep(r.value.data))

      updateState((draft) => {
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
      })
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function handleAddPackingListNumberSubmit(packingListNumber) {
    if (isEmpty(packingListNumber)) {
      message.error(t('errorMissingRequiredField'))
    } else {
      let orderItems = null

      updateState((draft) => {
        const receivedItemIds = getReceivedItems(draft.orderItemsOriginal, draft.orderItems).map((each) => each.id)

        draft.orderItems.forEach((each) => {
          if (receivedItemIds.includes(each.id)) {
            each.packingListNumber = packingListNumber
          }
        })

        orderItems = cloneDeep(draft.orderItems)

        draft.addPackingListNumberIsOpen = false
      })

      saveItem(orderItems)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    callback(errors, values)
  }

  function handleSave(packingListNumber) {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        if (props.customer.generalSettings.requirePackingListNumber) {
          const receivedItems = getReceivedItems(state.orderItemsOriginal, state.orderItems)

          if (receivedItems.some((one) => isEmpty(one.packingListNumber))) {
            setState('addPackingListNumberIsOpen', true)
          } else {
            saveItem()
          }
        } else {
          saveItem()
        }
      }
    })
  }

  function saveItem(orderItems = state.orderItems) {
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'Order')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        try {
          const response = await props.saveOrder({
            order: state.item,
            orderItems: getChangedItems(state.orderItemsOriginal, orderItems),
            orderSuppliers: getChangedItems(state.orderSupplierItemsOriginal, state.orderSupplierItems),
          })

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const itemId = response.value.data.items[0].id

          await saveDocumentItems(itemId)

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/orders/orders/${itemId}`
          } else {
            await fetchItem(itemId)
          }
        } catch (error) {
          setState('tabsActiveKey', 'items')
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  const refreshOrderTotals = useDebouncedCallback(() => {
    updateState((draft) => {
      Object.assign(draft.item, calculateOrderTotals({ customer: props.customer, ...draft }))
    })
  }, DEBOUNCE)

  async function invokeAction(action) {
    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    try {
      setState('loadingIsOpen', true)

      const response = await invoke(props, action, state.item.id)

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      refetchItem()
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function getSelectedSupplierId() {
    const supplierIds = uniq(state.orderItems.map((each) => each.supplierId))
    return supplierIds.length === 1 ? supplierIds[0] : state.orderItemsFilterDto?.supplierId
  }

  async function invokeExtraAction(action) {
    setState('errors', {})

    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    if (
      action === 'sendOrder' &&
      props.customer.generalSettings.requireRequisitionerOnOrder &&
      requisitionerFields.some(({ key }) => isEmpty(state.item[key]))
    ) {
      updateState((draft) => {
        draft.tabsActiveKey = 'order'
        draft.errors = requisitionerFields.reduce(
          (acc, { key }) => ({
            ...acc,
            [key]: isEmpty(state.item[key]) ? t('errorMissingRequiredField') : null,
          }),
          {}
        )
      })
      message.error(t('errorMissingRequiredField'))
    } else {
      try {
        setState('loadingIsOpen', true)

        if (action === 'resendOrder') {
          const supplierId = getSelectedSupplierId()

          if (isNil(supplierId)) {
            message.error(t('selectSupplierToResendOrder'))
          } else {
            const response = await props.resendOrder({
              orderId: state.item.id,
              supplierId,
            })

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await refetchItem(state.item.id)
          }
        } else if (props[action]) {
          const response = await invoke(props, action, state.item.id)

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          await refetchItem(state.item.id)
        } else {
          message.info(t('underDevelopment'))
        }
      } catch (error) {
        showError({ error })
      } finally {
        setState('loadingIsOpen', false)
      }
    }
  }

  async function handleSendEmailSubmit(recipients) {
    if (isEmpty(recipients)) {
      message.error(t('errorMissingRequiredField'))
    } else {
      try {
        const response = await props.emailOrder({
          recipients,
          order: state.item,
          orderItems: state.orderItems,
          orderSuppliers: state.orderSupplierItems,
        })

        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }

        setState('sendEmailIsOpen', false)
      } catch (error) {
        showError({ error })
      }
    }
  }

  async function handleItemsRowAction(rowActionKey, selectedRowKeys) {
    // console.log({ rowActionKey, selectedRowKeys })

    switch (rowActionKey) {
      case 'copy':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          try {
            setState('loadingIsOpen', true)

            const copied = await props.newItem({}).then((r) => cloneDeep(r.value.data))

            unset(copied, 'id')

            Object.assign(
              copied,
              pick(state.item, [
                'requisitionerName',
                'requisitionerPhone',
                'requisitionerEmail',
                'shipToAddressId',
                'billToAddressId',
                'taxRate',
                'scanType',
                'orderCalculationType',
              ])
            )

            const orderItems = state.orderItems
              .filter((each) => selectedRowKeys.includes(each.id))
              .map((each) => ({
                timeScanned: new Date().toJSON(),
                serverTimeScanned: new Date().toJSON(),
                userName: props.user.userName,
                productId: each.productId,
                barcode: each.barcode,
                inventoryBarcode: each.inventoryBarcode,
                itemNumber: each.itemNumber,
                description: each.description,
                locationId: each.locationId,
                binLocation: each.binLocation,
                manufacturer: each.manufacturer,
                nonStock: props.customer.generalSettings.addNonStockToProducts ? false : each.nonStock,
                price: each.price,
                unitOfMeasure: each.unitOfMeasure,
                packageSize: each.packageSize,
                packageSizeUom: each.packageSizeUom,
                quantityOrdered: each.quantityOrdered,
                packageQuantityOrdered: each.packageQuantityOrdered,
                taxable: each.taxable,
                taxableDescription: each.taxableDescription,
                expedite: each.expedite,
                packagePrice: each.packagePrice,
                packageUnitOfMeasure: each.packageUnitOfMeasure,
                leadTime: each.leadTime,
                nonInventory: each.nonInventory,
                accountNumber: each.accountNumber,
                reworkType: each.reworkType,
                isPersonalPurchase: each.isPersonalPurchase,
                receiveToOnHand2: each.receiveToOnHand2,
                supplierId: each.supplierId,
                supplierItemNumber: each.supplierItemNumber,
                deliveryDate: each.deliveryDate,
                trackingNumber: each.trackingNumber,
                carrier: each.carrier,
                locationBarcode: each.locationBarcode,
                locationName: each.locationName,
                supplierName: each.supplierName,
                scanType: each.scanType,
                orderCalculationType: each.orderCalculationType,
                procurementType: 'Purchase',
                sourceCatalogName: each.sourceCatalogName,
                tagA: each.tagA,
                tagB: each.tagB,
                tagC: each.tagC,
                tagD: each.tagD,
                tagE: each.tagE,
              }))

            const supplierIds = orderItems
              .filter((each) => Boolean(each.supplierId) && each.quantityOrdered > 0)
              .map((each) => each.supplierId)

            const suppliers = await Promise.all(
              uniq(supplierIds).map((each) => props.getSupplier(each).then((r) => r.value.data))
            )

            const purchaseOrderNumbers = await Promise.all(
              supplierIds.map((supplierId) =>
                props.generatePurchaseOrderNumber({ orderId: 0, supplierId }).then((each) => ({
                  supplierId,
                  ...each.value.data,
                }))
              )
            )

            const orderSuppliers = suppliers.map((each) => ({
              adjustments: 0,
              confirmedDescription: 'No',
              freight: 0,
              purchaseOrderNumber: '',
              shippingInstructions: each.shippingInstructions,
              supplierId: each.id,
              supplierName: each.name,
            }))

            Object.assign(copied, calculateOrderTotals({ customer: props.customer, item: copied, orderItems }))

            orderSuppliers.forEach((each) => {
              try {
                each.purchaseOrderNumber = purchaseOrderNumbers.find(
                  (one) => one.supplierId === each.supplierId
                ).purchaseOrderNumber
              } catch (error) {
                console.warn(error)
              }
            })

            const response = await props.saveOrder({
              order: copied,
              orderItems: { creating: orderItems },
              orderSuppliers: { creating: orderSuppliers },
            })

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await asyncSleep()

            window.location.href = `/orders/orders/${response.value.data.items[0].id}`
          } catch (error) {
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        }
        break

      case 'delete':
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('delete'),
            handler: () => {
              updateState((draft) => {
                remove(draft.orderItems, (each) => selectedRowKeys.includes(each.id))
              })
            },
          },
        ]
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmDeleteSelectedItems')
          draft.alertButtons = buttons
        })

        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.orderItems, state.orderItemsOriginal) ||
      !isEqual(state.orderSupplierItems, state.orderSupplierItemsOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal)
    )
  }

  const refreshOrderSupplierItems = useDebouncedCallback(async () => {
    let failureCount = 0

    try {
      setState('loadingIsOpen', true)

      const supplierIds = state.orderItems
        .filter((each) => Boolean(each.supplierId) && each.quantityOrdered > 0)
        .map((each) => each.supplierId)

      const suppliers = await Promise.all(
        uniq(supplierIds).map((each) => props.getSupplier(each).then((r) => r.value.data))
      )

      const seed = Date.now() * -1

      const orderSupplierItems = suppliers.map((each, index) => {
        const existing = state.orderSupplierItems.find((one) => one.supplierId === each.id)

        if (existing) {
          return existing
        }

        return {
          id: seed - index,
          orderSupplierId: seed - index,
          adjustments: 0,
          confirmedDescription: 'No',
          freight: 0,
          orderId: state.item.id,
          purchaseOrderNumber: '',
          shippingInstructions: each.shippingInstructions,
          supplierId: each.id,
          supplierName: each.name,
        }
      })

      const purchaseOrderNumbers = await Promise.all(
        orderSupplierItems.map((each) =>
          each.purchaseOrderNumber
            ? Promise.resolve(each)
            : props
                .generatePurchaseOrderNumber({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                })
                .then((response) => ({
                  orderId: each.orderId > 0 ? each.orderId : 0,
                  supplierId: each.supplierId,
                  ...response.value.data,
                }))
        )
      )

      orderSupplierItems.forEach((each) => {
        if (each.id < 0 && isEmpty(each.purchaseOrderNumber)) {
          try {
            each.purchaseOrderNumber = purchaseOrderNumbers.find(
              (one) => one.supplierId === each.supplierId
            ).purchaseOrderNumber
          } catch (error) {
            console.warn(error)
          }
        }
      })

      updateState((draft) => {
        draft.orderSupplierItems = orderSupplierItems
        draft.purchaseOrderNumbers = purchaseOrderNumbers
      })
    } catch (error) {
      failureCount++
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
      refreshOrderTotals()
    }

    return failureCount
  }, DEBOUNCE)

  useIonViewWillEnter(() => {
    setState('loadingIsOpen', true)
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })

      refreshOrderSupplierItems()
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle =
    props.match.params.itemId === '0' ? t('createOrder') : `${t('order')} - ${props.match.params.itemId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const orderIsReadOnly = ['Draft', 'Cancelled'].includes(state.item.status)

  const orderIsEditable =
    !orderIsReadOnly &&
    state.item.status !== 'Processed' &&
    (['New', 'PendingApproval'].includes(state.item.status) || props.customer.generalSettings.allowOrderEditAfterSend)

  const orderIsReceivable =
    ['Sent', 'Received', 'Processed'].includes(state.item.status) &&
    state.orderItems.some((one) => one.quantityOrdered > 0)

  const orderIsPartiallyReceived = state.orderItems.some((one) => one.quantityReceived > 0)
  const approvedTimeStamp = isValidDate(state.item.approveDate)
    ? formatUserTime(state.item.approveDate, state.item.approveUserName)
    : !['Draft', 'New', 'PendingApproval'].includes(state.item.status) && isValidDate(state.item.confirmDate)
      ? formatUserTime(state.item.confirmDate, state.item.userName)
      : ''

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'addToOrder':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/addItems`)
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      case 'sendEmail':
        setState('sendEmailIsOpen', true)
        break

      case 'showFilter':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('orders.formView.orderItems.showFilter')
        }
        break

      case 'sortOptions':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('orders.formView.orderItems.sortOptions')
        }

        if (state.tabsActiveKey === 'suppliers') {
          Emitter.emit('orders.formView.orderSuppliers.sortOptions')
        }

        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }
        break

      case 'cancelOrder':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = `${t(`confirm${key}`)} ${t(`confirm${key}description`)}`
            draft.alertButtons = [
              { text: t('no'), role: 'cancel' },
              { text: t('yes'), handler: () => invokeAction(key) },
            ]
          })
        }
        break

      case 'deleteOrder':
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = `${t(`confirm${key}`)} ${t(`confirm${key}description`)} `
          draft.alertButtons = [
            { text: t('no'), role: 'cancel' },
            {
              text: t('yes'),
              handler: async () => {
                try {
                  const response = await props.deleteOrder(state.item.id)
                  showClientNotifications({ response })

                  if (response.value.data.failureCount > 0) {
                    throw new Error()
                  }

                  props.history.goBack()
                } catch (error) {
                  showError({ error })
                }
              },
            },
          ]
        })
        break

      case 'showRowActions':
        setState('itemsRowActionsIsOpen', true)
        break

      case 'hideRowActions':
        setState('itemsRowActionsIsOpen', false)
        break

      case 'receiveAll':
      case 'unreceiveAll': {
        const supplierId = getSelectedSupplierId()

        if (isNil(supplierId)) {
          message.error(t('selectItemsSupplierOnFilter'))
        } else {
          const buttons = [
            { text: t('no'), role: 'cancel' },
            {
              text: t('yes'),
              handler: () =>
                updateState((draft) => {
                  draft.orderItems
                    .filter((each) => each.supplierId === supplierId && !each.lockedForCycleCount)
                    .forEach((one) => {
                      one.quantityReceived = key === 'receiveAll' ? one.quantityOrdered : 0
                    })
                }),
            },
          ]
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = `${t(`confirm${key}`)} ${t(`confirm${key}Description`)}`
            draft.alertButtons = buttons
          })
        }
        break
      }

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const head = [
      !orderIsReadOnly && !orderIsPartiallyReceived && orderIsEditable ? (
        <IonItem
          key="addToOrder"
          lines={state.tabsActiveKey === 'documents' ? 'none' : 'full'}
          onClick={() => handleActionsMenuClick('addToOrder')}
        >
          <IonLabel>{t('addToOrder')}</IonLabel>
        </IonItem>
      ) : null,
      !orderIsReadOnly && state.tabsActiveKey === 'documents' ? (
        <IonItem key="addDocument" lines="full" onClick={() => handleActionsMenuClick('addDocument')}>
          <IonLabel>{t('addDocument')}</IonLabel>
        </IonItem>
      ) : null,
      ...(!orderIsReadOnly && orderIsReceivable && getUserPermission('ReceiveOrders') !== 'No'
        ? [
            <IonItem key="receiveAll" lines="none" onClick={() => handleActionsMenuClick('receiveAll')}>
              <IonLabel>{t('receiveAll')}</IonLabel>
            </IonItem>,
            <IonItem key="unreceiveAll" lines="full" onClick={() => handleActionsMenuClick('unreceiveAll')}>
              <IonLabel>{t('unreceiveAll')}</IonLabel>
            </IonItem>,
          ]
        : []),
    ].filter(Boolean)

    const tail = [
      state.item.id ? (
        <IonItem key="email" lines="full" onClick={() => handleActionsMenuClick('sendEmail')}>
          <IonLabel>{t('email')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && !orderIsReadOnly && ['Sent', 'PendingApproval'].includes(state.item.status) ? (
        <IonItem
          key="cancelOrder"
          lines="full"
          onClick={() => handleActionsMenuClick('cancelOrder')}
          disabled={orderIsReadOnly && state.item.status !== 'Closed'}
        >
          <IonLabel>{t('cancelOrder')}</IonLabel>
        </IonItem>
      ) : null,
      state.item.id && !orderIsReadOnly && state.item.status === 'New' ? (
        <IonItem key="deleteOrder" lines="full" onClick={() => handleActionsMenuClick('deleteOrder')}>
          <IonLabel>{t('deleteOrder')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)

    const defaultItems = [...head, ...tail].filter(Boolean)

    switch (state.tabsActiveKey) {
      case 'items':
        return [
          ...head,
          <IonItem key="showFilter" lines="none" onClick={() => handleActionsMenuClick('showFilter')}>
            <IonLabel>{t('showFilter')}</IonLabel>
          </IonItem>,
          <IonItem key="sortOptions" lines="none" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          state.itemsRowActionsIsOpen ? (
            <IonItem key="hideRowActions" lines="full" onClick={() => handleActionsMenuClick('hideRowActions')}>
              <IonLabel>{t('hideRowActions')}</IonLabel>
            </IonItem>
          ) : (
            <IonItem key="showRowActions" lines="full" onClick={() => handleActionsMenuClick('showRowActions')}>
              <IonLabel>{t('showRowActions')}</IonLabel>
            </IonItem>
          ),
          ...tail,
        ].filter(Boolean)

      case 'suppliers':
        return [
          ...head,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ].filter(Boolean)

      case 'documents':
        return [
          ...head,
          <IonItem key="sortOptions" lines="full" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
          ...tail,
        ].filter(Boolean)

      default:
        return defaultItems
    }
  })().filter(Boolean)

  const createLabel = createLabelFactory(state.fieldSettings)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon
              type="Menu"
              size="26"
              color={
                state.tabsActiveKey === 'items' &&
                Object.entries(state.orderItemsFilterDto ?? {}).some(([key, value]) => isSignificant(value))
                  ? 'warning'
                  : undefined
              }
            />
          </IonButton>
        ) : null
      }
      footer={
        <IonRow>
          {!orderIsReadOnly && !orderIsPartiallyReceived && state.tabsActiveKey === 'items' && orderIsEditable && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('addToOrder')}
                disabled={!isOnline}
              >
                {t('add')}
              </IonButton>
            </IonCol>
          )}
          {!orderIsReadOnly && state.tabsActiveKey === 'documents' && (
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={() => handleActionsMenuClick('addDocument')}
                disabled={!isOnline}
              >
                {t('add')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'New' && state.item.userAuthorizedForAllLocations && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('sendOrder')}
                disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
              >
                {t('send')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'Sent' && state.item.userAuthorizedForAllLocations && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('resendOrder')}
                disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
              >
                {t('resend')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'PendingApproval' && !state.item.userAuthorizedForOrderValue && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('requestApproval')}
                disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
              >
                {t('requestApproval')}
              </IonButton>
            </IonCol>
          )}
          {state.item.status === 'PendingApproval' &&
            state.item.userAuthorizedForOrderValue &&
            state.item.userAuthorizedForAllLocations && (
              <IonCol>
                <IonButton
                  expand="full"
                  color="transparent"
                  onClick={() => invokeExtraAction('approveOrder')}
                  disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
                >
                  {t('approve')}
                </IonButton>
              </IonCol>
            )}
          {!props.tenantTypeIsTrms && ['Sent', 'Backorder'].includes(state.item.status) && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('processOrder')}
                disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
              >
                {t('process')}
              </IonButton>
            </IonCol>
          )}
          {!props.tenantTypeIsTrms && state.item.status === 'Processed' && (
            <IonCol>
              <IonButton
                expand="full"
                color="transparent"
                onClick={() => invokeExtraAction('undoProcessed')}
                disabled={isEmpty(state.orderItems) || orderIsReadOnly || !isOnline}
              >
                {t('undoProcessed')}
              </IonButton>
            </IonCol>
          )}
          {orderIsReadOnly && (
            <IonCol>
              <IonButton expand="full" color="secondary" onClick={() => props.history.goBack()}>
                {t('close')}
              </IonButton>
            </IonCol>
          )}
          {!orderIsReadOnly && (
            <IonCol>
              <IonButton expand="full" color="secondary" onClick={() => handleSave()} disabled={!isOnline}>
                {t('save')}
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="order" tab={t('order')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('orderId')}</IonLabel>
            <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('created')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.createDate, state.item.userName)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          {['billTo', 'shipTo'].map((each) => (
            <IonItem
              key={each}
              lines="full"
              className="tofino-stacked-item"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/${camelCase(`select-${each}`)}`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t(each)}</small>
                </IonText>
                <br />
                {get(state, `item.${each}AddressName`) || PLACEHOLDER}
              </IonLabel>
            </IonItem>
          ))}
          {requisitionerFields.map(({ key, type }) => (
            <IonItem
              key={key}
              lines="full"
              className={cx({
                'tofino-error-item': get(state, `errors.${key}`),
                'tofino-required-item': props.customer.generalSettings.requireRequisitionerOnOrder,
              })}
            >
              <IonLabel position="stacked">{t(key)}</IonLabel>
              <IonInput
                type={type}
                inputmode={type}
                value={state.item[key]}
                onIonInput={(e) => setItemValue(key, e.target.value)}
                placeholder={PLACEHOLDER}
              />
            </IonItem>
          ))}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('code')}</IonLabel>
            <IonInput
              value={state.item.accountCode}
              onIonInput={(e) => setItemValue('accountCode', e.target.value)}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">
              {createLabel('approveDate')} <Help title={state.item.orderApprovalInfo} size="17" />
            </IonLabel>
            <IonInput value={approvedTimeStamp} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          {['confirmDate', 'receivedDate'].map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(each)}</IonLabel>
              <IonInput
                value={formatUserTime(state.item[each], state.item.userName)}
                placeholder={PLACEHOLDER}
                disabled
              />
            </IonItem>
          ))}
          {allowPricing()
            ? [
                ...(props.customer.moduleSettings.enableTax ? ['exemptTotal', 'taxableTotal', 'taxTotal'] : []),
                'subTotal',
                'adjustmentsTotal',
                'freightTotal',
                'orderTotal',
              ].map((each) => (
                <IonItem key={each} lines="full">
                  <IonLabel position="stacked">
                    {each === 'taxTotal'
                      ? `${t('taxTotal')} (${state.item.taxRate.toLocaleString(props.locale, {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })})`
                      : each === 'subTotal'
                        ? t('subtotal')
                        : t(each)}
                  </IonLabel>
                  <IonInput value={toLocaleCurrency(state.item[each])} disabled />
                </IonItem>
              ))
            : null}
          <Comments value={state.item.comment} onChange={(value) => setItemValue('comment', value)} />
        </Tabs.TabPane>
        <Tabs.TabPane key="items" tab={t('itemsTab')} forceRender>
          <OrderItems
            items={state.orderItems ?? []}
            onChange={(values) => {
              setState('orderItems', values)
              refreshOrderSupplierItems()
            }}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly: orderIsReadOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/orderItems/${item.id}`)
            }}
            parentRecord={{
              ...state.item,
              orderItems: state.orderItems,
              orderSupplierItems: state.orderSupplierItems,
            }}
            onFilter={(value) => setState('orderItemsFilterDto', value)}
            rowActionsIsOpen={state.itemsRowActionsIsOpen}
            onRowAction={handleItemsRowAction}
            readOnly={orderIsReadOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="suppliers" tab={t('suppliers')} forceRender>
          <OrderSuppliers
            items={state.orderSupplierItems ?? []}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly: orderIsReadOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/orderSuppliers/${item.id}`)
            }}
            readOnly={orderIsReadOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Order"
            items={state.documentItems ?? []}
            onChange={(values) => setState('documentItems', values)}
            readOnly={orderIsReadOnly}
          />
        </Tabs.TabPane>
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <PackingListNumber
        isOpen={state.addPackingListNumberIsOpen}
        onCancel={() => setState('addPackingListNumberIsOpen', false)}
        onSubmit={handleAddPackingListNumberSubmit}
      />
      <SendEmail
        isOpen={state.sendEmailIsOpen}
        onSubmit={handleSendEmailSubmit}
        onCancel={() => setState('sendEmailIsOpen', false)}
      />
    </Page>
  )
}

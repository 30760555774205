import { isNil, defaultTo, memoize } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { getHoursPassed } from 'helpers/dateTime'
import { getPrivateItem } from 'helpers/localForage'
import { getSharedInventory, getSharedLocations } from 'helpers/offlineData'
import { LOCATION_COUNTS_CURRENT_KEY, LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import { t } from 'helpers/i18n'
import { AUTH_SESSION_KEY } from 'options/auth'
import { getSessionItem } from 'helpers/sessionStorage'

export const getIsCounted = async ({ id, lastCount }) => {
  try {
    const countedLocations = await getPrivateItem(LOCATION_COUNTS_SAVED_KEY, [])

    if (countedLocations.some((one) => one.locationId === id)) {
      return true
    }

    return getHoursPassed(lastCount) <= 12
  } catch (error) {
    console.warn(error)
    return false
  }
}

export const getCartTypeLabel = ({ cartType, minPar } = {}) => {
  if (cartType === 'Par') {
    if (minPar) {
      return `${t('par')} - ${t('minPar')}`
    }

    return t('par')
  }

  if (cartType === 'Req') {
    return t('requisition')
  }

  return PLACEHOLDER
}

export const getCurrentCount = async () => {
  const [currentCount, locations] = await Promise.all([
    getPrivateItem(LOCATION_COUNTS_CURRENT_KEY),
    getSharedLocations(),
  ])

  const location = locations.find((one) => one.id === currentCount.locationId)
  const isCounted = await getIsCounted(location)

  return { currentCount, currentLocation: { ...location, isCounted } }
}

export const getShowCount = (currentLocation) =>
  currentLocation?.cartType !== 'Req' ||
  getSessionItem(AUTH_SESSION_KEY, {}).current.user.tenantGroup.toLowerCase() !== 'cardinal'

export const getOrderQuantity = (options) => {
  // console.log('getOrderQuantity:', options)

  const {
    scanType = 'PerEach',
    isCardinal = false,
    cartType = 'Par',
    minPar = false,
    min = 0,
    max = 0,
    count,
    count2,
  } = options

  if (isNil(count) && isNil(count2)) {
    return 0
  }

  let orderQuantity = 0

  const packageSize = Math.max(1, options?.packageSize ?? 1)
  const countQuantity = defaultTo(count, 0) + defaultTo(count2, 0)
  const packageQuantity =
    scanType === 'Package'
      ? packageSize * Math.ceil((max - countQuantity) / packageSize)
      : max - countQuantity

  if (isCardinal) {
    const PAR = cartType === 'Par'
    const REQ = cartType === 'Req;'

    if (PAR && !minPar) {
      orderQuantity = packageQuantity

      if (orderQuantity < 0) {
        orderQuantity = 0
      }
    } else if (PAR && minPar) {
      if (countQuantity <= min) {
        orderQuantity = packageQuantity
      } else {
        orderQuantity = 0
      }
    } else if (REQ) {
      throw new Error("Invalid arguments: cartType === 'Req'")
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (countQuantity <= min) {
      orderQuantity = packageQuantity
    } else {
      orderQuantity = 0
    }
  }

  return orderQuantity
}

export const isItemCounted = (each) => !isNil(each.count) || !isNil(each.count2)

export const isItemOrdered = (each) => each.quantityToOrder > 0

export const isUploadSuccessful = (response) =>
  response?.inventoryUpdateStatus === 'Success' && response?.orderCreateStatus === 'Success'

export const getStoredInventoryForLocationId = memoize(
  (locationId) => getSharedInventory().then((r) => r.filter((each) => each.locationId === locationId)),
  JSON.stringify
)

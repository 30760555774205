import { isEqual, isEmpty, isArray, camelCase, isNil, snakeCase, get, uniqBy } from 'lodash'
import { showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'

export const createDocumentsDispatchToProps = (dispatch, documentActions) => ({
  getDocumentContents: (params) => dispatch(documentActions.getContents(params)),
  getDocumentItems: (params) =>
    dispatch(
      documentActions.getItems({
        ...params,
        pageIndex: 0,
      })
    ),
  createDocumentItems: (params) => dispatch(documentActions.createItems(params)),
  deleteDocumentItems: (params) => dispatch(documentActions.deleteItems(params)),
})

export const createLinkedItemsDispatchToProps = (dispatch, entityName, actions) => ({
  [`get${entityName}Items`]: (params) =>
    dispatch(
      actions.getItems({
        ...params,
        pageIndex: 0,
      })
    ),
  [`download${entityName}Items`]: (params) => dispatch(actions.downloadItems(params)),
  [`create${entityName}Items`]: (items) => dispatch(actions.createItems(items)),
  [`update${entityName}Items`]: (items) => dispatch(actions.updateItems(items)),
  [`delete${entityName}Items`]: (items) => dispatch(actions.deleteItems(items)),
})

export const createChildItemsDispatchToProps = (dispatch, childName, actions) => ({
  [`get${childName}Items`]: (parentId, params) => dispatch(actions.getChildItems(parentId, params)),
  [`download${childName}Items`]: (parentId, params) => dispatch(actions.downloadChildItems(parentId, params)),
  [`create${childName}Items`]: (parentId, items) => dispatch(actions.createChildItems(parentId, items)),
  [`update${childName}Items`]: (parentId, items) => dispatch(actions.updateChildItems(parentId, items)),
  [`delete${childName}Items`]: (parentId, items) => dispatch(actions.deleteChildItems(parentId, items)),
})

export const createLabelFactory =
  (fieldSettings = []) =>
  (fieldName) => {
    if (!isArray(fieldSettings)) {
      throw new Error('fieldSettings is not an Array')
    }

    const dtoFieldName = fieldName.split('.').map(camelCase).join('.')
    const column = fieldSettings.find((one) => one.dtoFieldName === dtoFieldName)

    if (!isEmpty(fieldSettings) && isNil(column)) {
      console.error(`fieldSettings with dtoFieldName === "${fieldName}" is not defined.`)
    }

    const labelLanguageKey =
      column?.recordLabelLanguageKey ||
      column?.columnHeadingLanguageKey ||
      snakeCase(dtoFieldName).toUpperCase()

    return t(labelLanguageKey)
  }

export const getChangedItems = (originals = [], items = []) => {
  const itemIds = items.map((each) => each.id)
  const originalIds = originals.map((each) => each.id)
  const creating = items
    .filter((each) => !originalIds.includes(each.id))
    .map(({ id, ...rest }) => ({ ...rest }))
  const updating = items.filter((each) => {
    const original = originals.find((one) => one.id === each.id)
    return original && !isEqual(original, each)
  })
  const deleting = originals.filter((each) => !itemIds.includes(each.id))

  return {
    creating, // creating items don't have id
    updating: uniqBy(updating, 'id'),
    deleting: uniqBy(deleting, 'id'),
  }
}

export const createSaveDocumentItems = (self, domainObjectType) => async (domainObjectId) => {
  const { deleting } = getChangedItems(self.state.documentItemsOriginal, self.state.documentItems)
  const uploading = self.state.documentItems.filter((each) => each.fileContents)
  const documentType = 'ObjectDocument'

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteDocumentItems(
      deleting.map((each) => ({
        domainObjectId,
        domainObjectType,
        documentType,
        documentName: each.fileName,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(uploading)) {
    const response = await self.props.createDocumentItems(
      uploading.map((each) => ({
        domainObjectId,
        domainObjectType,
        documentType,
        fileName: each.fileName,
        fileType: each.fileType,
        contents: each.fileContents,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveWarrantyItems = (self) => async (assetId) => {
  const { creating, updating, deleting } = getChangedItems(
    self.state.warrantyItemsOriginal,
    self.state.warrantyItems
  )

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteWarrantyItems(
      assetId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateWarrantyItems(
      assetId,
      updating.map((each) => ({ ...each, assetId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createWarrantyItems(
      assetId,
      creating.map(({ id, assetWarrantyId, ...rest }) => ({
        ...rest,
        assetId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveToleranceItems = (self) => async (assetId) => {
  const { creating, updating, deleting } = getChangedItems(
    self.state.toleranceItemsOriginal,
    self.state.toleranceItems
  )

  if (!isEmpty(deleting)) {
    const response = await self.props.deleteToleranceItems(
      assetId,
      deleting.map((each) => each.id)
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(updating)) {
    const response = await self.props.updateToleranceItems(
      assetId,
      updating.map((each) => ({ ...each, assetId }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }

  if (!isEmpty(creating)) {
    const response = await self.props.createToleranceItems(
      assetId,
      creating.map(({ id, assetToleranceId, ...rest }) => ({
        ...rest,
        assetId,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const createSaveToleranceHistoryItems = (self) => async (assetId) => {
  const { updating } = getChangedItems(self.state.toleranceItemsOriginal, self.state.toleranceItems)

  if (!isEmpty(updating)) {
    const response = await self.props.createTolerancesHistoryItems(
      assetId,
      updating.map((each) => ({
        id: 0,
        assetId,
        toleranceId: each.id,
        entryDate: new Date().toJSON(),
        userName: self.props.user.userName,
        value: each.newValue,
        min: each.min,
        max: each.max,
        notify: each.notify,
        notifySent: false,
      }))
    )

    showClientNotifications({ response })

    if (response.value.data.failureCount > 0) {
      throw new Error()
    }
  }
}

export const isReadOnly = (self) =>
  self?.props?.readOnly || self?.props?.parentRecord?.readOnly || self?.props?.rootRecord?.readOnly

export const saveImageFileExtensions = {
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'image/gif': '.gif',
}

export const createSaveImage =
  (self, domainObjectType, documentType = 'ObjectImage') =>
  async (domainObjectId, fileNameWithoutExtension) => {
    const { image, imageOriginal } = self.state

    if (!isEqual(image, imageOriginal) && imageOriginal?.fileName) {
      const response = await self.props.deleteDocumentItems([
        {
          domainObjectId,
          domainObjectType,
          documentType,
          documentName: imageOriginal.fileName,
        },
      ])

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEqual(image, imageOriginal) && image?.fileType && image?.contents) {
      const response = await self.props.createDocumentItems([
        {
          domainObjectId,
          domainObjectType,
          documentType,
          fileName: `${fileNameWithoutExtension}${get(saveImageFileExtensions, image.fileType)}`,
          fileType: image.fileType,
          contents: image.contents,
        },
      ])

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

export const getMultiSelectDisplayNameFromArray = (
  collection = [],
  values = [],
  idField = 'id',
  textField = 'displayName'
) => {
  const ids = isArray(values) ? values : [values]

  if (isEmpty(ids)) {
    return t('all')
  }

  return ids
    .map((each) =>
      get(
        collection.find((one) => one[idField] === each),
        textField,
        each
      )
    )
    .join(', ')
}

export const getMultiSelectDisplayNameFromObject = (collection, value) => {
  const ids = isArray(value) ? value : [value]

  if (isEmpty(ids)) {
    return t('all')
  }

  return ids.map((each) => t(get(collection, each))).join(', ')
}

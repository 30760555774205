import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { isEmpty } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER, asyncSleep } from 'helpers/utils'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { setSessionItem } from 'helpers/sessionStorage'
import { PRIVATE_DATABASE } from 'options/products'
import Icon from 'elements/Icon'
import Filter from 'containers/Inventory/Create/Filter'

export default createListViewComponent({
  getStorageKey: () => 'inventory.create',
  pageTitle: (self) => t('selectProduct'),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description || PLACEHOLDER}
            <br />
            {t('item#:')} {item.itemNumber}, {t('pkg:')} {item.packageSizeUom}, {t('price:')}{' '}
            {toLocaleCurrency(item.price)}
            <br />
            {t('supplier:')} {item.preferredSupplierName || PLACEHOLDER}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: () => 93,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={async () => {
        const selectedItem = self.state.items.find((one) => self.state.selectedRowKeys.includes(one.id))
        const catalogTableName = self.state.filterDto.catalogTableName ?? ''

        if (selectedItem) {
          try {
            self.setState('loadingIsOpen', true)

            if (catalogTableName !== PRIVATE_DATABASE && catalogTableName !== '') {
              await self.props
                .copyToPrivate({
                  catalogProductIds: [selectedItem.id],
                  catalogTableName,
                })
                .catch(() => {})
            }

            const {
              barcode,
              itemNumber,
              manufacturer,
              description: itemDescription,
              price,
              packageSizeUom,
              preferredSupplierName: itemSupplierName,
              reworkTypeDescription,
            } = selectedItem

            setSessionItem('inventory.formView.newItemParams', {
              barcode,
              itemNumber,
              manufacturer,
              itemDescription,
              price,
              packageSizeUom,
              itemSupplierName,
              reworkTypeDescription,
              supplierId: 0,
              onHand: 0,
              onHand2: 0,
              min: 0,
              max: 0,
              averageUsed: 0,
              averageOrdered: 0,
              doNotReorder: self.props.customer.generalSettings.autoDoNotReorder,
            })

            await asyncSleep()

            window.location.href = '/inventory/inventory/0'
          } catch (error) {
            self.setState('loadingIsOpen', false)
            showError({ error })
          } finally {
            self.setState('loadingIsOpen', false)
          }
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
    >
      {t('select')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'barcode' },
    { fieldName: 'itemNumber', languageKey: 'itemNumber' },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'price', languageKey: 'price' },
    { fieldName: 'preferredSupplierName', languageKey: 'supplier' },
  ],
  allowOffline: (self) => false,
})(Filter)

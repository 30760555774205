import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
  IonLoading,
  IonAlert,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, cloneDeep, isEmpty, isEqual, omit, remove } from 'lodash'
import { formatUserTime } from 'helpers/dateTime'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { message, PLACEHOLDER, asyncSleep } from 'helpers/utils'
import { getChangedItems } from 'helpers/formViews'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import Icon from 'elements/Icon'
import Details from 'containers/CycleCounts/Details'
import useOnlineStatus from '@rehooks/online-status'
import { formatValue } from 'helpers/listViews'

export const getStorageKey = () => 'cycleCounts.formView'

export default function (props) {
  const isOnline = useOnlineStatus()
  const [state, updateState] = useImmer({ tabsActiveKey: 'items' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const responses = await Promise.all([props.getItem(itemId), props.getDetailItems(itemId)])

      updateState((draft) => {
        draft.item = cloneDeep(responses[0].value.data)
        draft.itemOriginal = cloneDeep(responses[0].value.data)
        draft.detailItems = cloneDeep(responses[1].value.data.items)
        draft.detailItemsOriginal = cloneDeep(responses[1].value.data.items)
      })
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const response = await props.updateItem(values)

          await saveCycleCountDetails(response.value.data.id)

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          fetchItem()
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  async function saveCycleCountDetails(cycleCountId) {
    const { creating, updating, deleting } = getChangedItems(state.detailItemsOriginal, state.detailItems)

    if (!isEmpty(deleting)) {
      const response = await props.deleteDetailItems(
        cycleCountId,
        deleting.map((each) => each.id)
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(updating)) {
      const response = await props.updateDetailItems(
        cycleCountId,
        updating.map((each) => ({ ...each, cycleCountId }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }

    if (!isEmpty(creating)) {
      const response = await props.createDetailItems(
        cycleCountId,
        creating.map(({ id, jobLabourId, ...rest }) => ({
          ...rest,
          cycleCountId,
        }))
      )

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }
    }
  }

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.detailItems, state.detailItemsOriginal)
    )
  }

  function handleCommitClick() {
    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    const buttons = [
      { text: t('cancel'), role: 'cancel' },
      {
        text: t('commit'),
        handler: async () => {
          try {
            const { id: cycleCountId = 0, sendDifferenceReportTo } = state.item

            setState('loadingIsOpen', true)

            await props.commitCycleCount({ id: cycleCountId }).then((response) => showClientNotifications({ response }))

            if (!isEmpty(sendDifferenceReportTo)) {
              const cycleCount = await props.getItem(cycleCountId).then((r) => r.value.data)

              const reportItems = await props
                .getCycleCountDifferenceReportItems({ cycleCountId, pageIndex: 0 })
                .then((r) => r.value.data.items)

              await asyncSleep()

              await props
                .emailCycleCountDifferenceReportItems({
                  ...cycleCount,
                  customerName: props.customer.name,
                  reportItems,
                })
                .then((response) => showClientNotifications({ response }))
            }
          } catch (error) {
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
            fetchItem()
          }
        },
      },
    ]

    updateState((draft) => {
      draft.alertIsOpen = true
      draft.alertMessage = t('confirmCommitTitle')
      draft.alertButtons = buttons
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  if (isNil(state.item)) {
    return (
      <Page title={props.match.params.itemId}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = state.item.status === 'Committed'
  const pageTitle = `${state.item.id} - ${state.item.locationName}`

  function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    if (hasUnsavedChanges()) {
      message.error(t('saveChangesFirst'))
      return
    }

    switch (key) {
      case 'addFromInventory':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/addFromInventory`)
        break

      case 'splitCycleCount':
        props.history.push(`${props.match.url}/split`)
        break

      case 'clearAllItemCounts': {
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('clear'),
            handler: () =>
              updateState((draft) => {
                draft.detailItems.forEach((one) => {
                  one.count = -1
                  one.count2 = -1
                })
              }),
          },
        ]
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmClearCountValues')
          draft.alertButtons = buttons
        })
        break
      }

      case 'deleteAllUncountedItems': {
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('delete'),
            handler: () => {
              updateState((draft) => {
                remove(draft.detailItems, (each) => each.count === -1 && each.count2 === -1)
              })
            },
          },
        ]
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmDeleteUncountedItems')
          draft.alertButtons = buttons
        })
        break
      }

      case 'sortOptions':
        if (state.tabsActiveKey === 'items') {
          Emitter.emit('cycleCounts.formView.details.sortOptions')
        }
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const defaultItems = [
      !readOnly ? (
        <IonItem key="addFromInventory" lines="full" onClick={() => handleActionsMenuClick('addFromInventory')}>
          <IonLabel>{t('addFromInventory')}</IonLabel>
        </IonItem>
      ) : null,
      !readOnly ? (
        <IonItem
          key="splitCycleCount"
          lines="full"
          onClick={() => handleActionsMenuClick('splitCycleCount')}
          disabled={isEmpty(state.detailItems)}
        >
          <IonLabel>{t('splitCycleCount')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)

    switch (state.tabsActiveKey) {
      case 'items':
        return [
          ...defaultItems,
          !readOnly ? (
            <IonItem
              key="clearAllItemCounts"
              lines="none"
              onClick={() => handleActionsMenuClick('clearAllItemCounts')}
              disabled={isEmpty(state.detailItems)}
            >
              <IonLabel>{t('clearAllItemCounts')}</IonLabel>
            </IonItem>
          ) : null,
          !readOnly ? (
            <IonItem
              key="deleteAllUncountedItems"
              lines="full"
              onClick={() => handleActionsMenuClick('deleteAllUncountedItems')}
              disabled={isEmpty(state.detailItems)}
            >
              <IonLabel>{t('deleteAllUncountedItems')}</IonLabel>
            </IonItem>
          ) : null,
          <IonItem key="sortOptions" lines="none" onClick={() => handleActionsMenuClick('sortOptions')}>
            <IonLabel>{t('sortOptions')}</IonLabel>
          </IonItem>,
        ].filter(Boolean)

      default:
        return defaultItems
    }
  })().filter(Boolean)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        readOnly ? (
          <IonButton expand="full" color="secondary" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonRow>
            <IonCol>
              <IonButton expand="full" color="transparent" onClick={handleCommitClick} disabled={!isOnline}>
                {t('commit')}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="full" color="secondary" onClick={() => saveItem()} disabled={!isOnline}>
                {t('save')}
              </IonButton>
            </IonCol>
          </IonRow>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="cycleCount" tab={t('cycleCount')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('id')}</IonLabel>
            <IonInput value={state.item.id} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('location')}</IonLabel>
            <IonInput value={state.item.locationName} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('type')}</IonLabel>
                <IonInput value={state.item.typeDescription} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('status')}</IonLabel>
                <IonInput value={state.item.statusDescription} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('itemsToCount')}</IonLabel>
                <IonInput value={state.item.itemsToCount} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('itemsOnCount')}</IonLabel>
                <IonInput value={state.item.itemsOnCount} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('itemsCounted')}</IonLabel>
                <IonInput value={state.item.itemsCounted} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('itemsAdjusted')}</IonLabel>
                <IonInput value={state.item.itemsAdjusted} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
          </IonRow>
          {state.item.type === 'Audit' && (
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('elapsedDays')}</IonLabel>
                  <IonInput value={state.item.elapsedDays} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('inventoryAccuracy')}</IonLabel>
                  <IonInput
                    value={formatValue({ value: state.item.inventoryAccuracy, displayFormat: 'Percent2' })}
                    placeholder={PLACEHOLDER}
                    disabled
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {state.item.type === 'Price' && (
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('elapsedDays')}</IonLabel>
                  <IonInput value={state.item.elapsedDays} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('minimumPrice')} ($)</IonLabel>
                  <IonInput value={state.item.basePrice} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {state.item.type === 'RangeOfBarcodes' && (
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('firstBarcode')}</IonLabel>
                  <IonInput value={state.item.firstBarcode} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('lastBarcode')}</IonLabel>
                  <IonInput value={state.item.lastBarcode} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {state.item.type === 'RangeOfBinLocations' && (
            <IonRow>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('firstBinLocation')}</IonLabel>
                  <IonInput value={state.item.firstBinLocation} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('lastBinLocation')}</IonLabel>
                  <IonInput value={state.item.lastBinLocation} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {state.item.type !== 'Audit' && (
            <IonItem lines="full">
              <IonLabel position="stacked">{t('inventoryAccuracy')}</IonLabel>
              <IonInput
                value={formatValue({ value: state.item.inventoryAccuracy, displayFormat: 'Percent2' })}
                placeholder={PLACEHOLDER}
                disabled
              />
            </IonItem>
          )}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('created')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.createdDate, state.item.createdBy)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('committed')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.committedDate, state.item.committedBy)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item ion-margin-bottom"
            onClick={() => {
              setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
              props.history.push(`${props.match.url}/selectUser`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('sendReportTo')}</small>
              </IonText>
              <br />
              {state.item.sendDifferenceReportToName || state.item.sendDifferenceReportTo || t('none')}
            </IonLabel>
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="items" tab={t('itemsTab')} forceRender>
          <Details
            parentRecord={state.item}
            items={state.detailItems}
            originals={state.detailItemsOriginal}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/detailItems/${item.id}`)
            }}
            onChange={(values) => setState('detailItems', values)}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
      </Tabs>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

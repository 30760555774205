import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { isEmpty, cloneDeep } from 'lodash'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER, tryParseInt, message } from 'helpers/utils'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { updateSessionItem, getSessionItem } from 'helpers/sessionStorage'
import { PRIVATE_DATABASE } from 'options/products'
import Icon from 'elements/Icon'
import Filter from 'containers/Requisitions/AddItems/SearchProducts/Filter'

export default createListViewComponent({
  getStorageKey: () => 'requisitions.addItems.searchProducts',
  pageTitle: (self) => t('searchProducts'),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          const index = draft.selectedRowKeys.findIndex((one) => one === item.id)
          if (index > -1) {
            draft.selectedRowKeys = []
          } else {
            draft.selectedRowKeys = [item.id]
          }
        })
      }}
      detail={false}
      button
    >
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description || PLACEHOLDER}
            <br />
            {t('item#:')} {item.itemNumber}, {t('pkg:')} {item.packageSizeUom}, {t('price:')}{' '}
            {toLocaleCurrency(item.price)}
            <br />
            {t('supplier:')} {item.preferredSupplierName || PLACEHOLDER}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getItemSize: () => 93,
  allowSearch: (self) => true,
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={async () => {
        const selectedItem = self.state.items.find((one) => self.state.selectedRowKeys.includes(one.id))
        const values = getSessionItem('requisitions.addItems', {}).item
        const catalogTableName = self.state.filterDto.catalogTableName ?? ''

        const requisitionItem = {
          ...values,
          quantity: values.quantity || 1,
          barcode: selectedItem.barcode,
          description: selectedItem.description,
          assetName: values.assetName || `*${values.assetDisplayName}`,
          jobName: values.jobName || `*${values.jobDisplayName}`,
          locationName: values.locationName || `*${values.locationDisplayName}`,
          operatorName: values.operatorName || `*${values.operatorDisplayName}`,
          sourceCatalogName: catalogTableName !== PRIVATE_DATABASE ? catalogTableName : undefined,
        }

        try {
          self.setState('loadingIsOpen', true)

          if (catalogTableName !== PRIVATE_DATABASE && catalogTableName !== '') {
            await self.props
              .copyToPrivate({
                catalogProductIds: [selectedItem.id],
                catalogTableName,
                addingItemToOrder: true,
              })
              .catch(() => {})
          }

          const response = await self.props.populateRequisitionItems([requisitionItem])

          self.setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const populatedItem = cloneDeep(response.value.data.items[0])

          populatedItem.id = populatedItem.requisitionItemId = values.id || Date.now() * -1
          populatedItem.requisitionId = tryParseInt(self.props.match.params.parentId, 0)
          populatedItem.populated = true
          populatedItem.timestamp = new Date().toJSON()

          updateSessionItem('requisitions.formView', {}, (draft) => {
            draft.requisitionItems.unshift(populatedItem)
            draft.tabsActiveKey = 'items'
          })

          self.setState('selectedRowKeys', [])

          message.success(`${populatedItem.barcode} ${t('addToRequisitionSuccess')}`)
        } catch (error) {
          self.setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
    >
      {t('addToRequisition')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'barcode' },
    { fieldName: 'itemNumber', languageKey: 'itemNumber' },
    { fieldName: 'description', languageKey: 'description' },
    { fieldName: 'price', languageKey: 'price' },
    { fieldName: 'preferredSupplierName', languageKey: 'supplier' },
  ],
  allowOffline: (self) => false,
})(Filter)

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, cloneDeep, isEmpty, toString as str } from 'lodash'
import { tryParseInt, filterKeys, PLACEHOLDER } from 'helpers/utils'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import { showValidationErrors, showError } from 'helpers/errors'
import Page from 'elements/Page'

export default function (props) {
  const { parentStorageKey = 'cycleCounts.formView' } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  function fetchItem() {
    const parentState = getSessionItem(parentStorageKey)
    const item = parentState.detailItems.find((one) => str(one.id) === props.match.params.itemId)

    updateState((draft) => {
      draft.item = cloneDeep(item)
      draft.parentState = parentState
    })
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        updateSessionItem(parentStorageKey, {}, (draft) => {
          try {
            const index = draft.detailItems.findIndex((one) => str(one.id) === props.match.params.itemId)

            draft.detailItems.splice(index, 1, values)

            props.history.goBack()
          } catch (error) {
            showError({ error })
          }
        })
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const backButtonHref = `/inventory/cycleCounts/${props.match.params.parentId}`

  if (isNil(state.item)) {
    return (
      <Page title={props.match.params.itemId} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const { readOnly } = state.parentState ?? {}
  const pageTitle = props.useInventoryBarcode
    ? state.item.inventoryBarcode || state.item.barcode
    : state.item.barcode

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        readOnly ? (
          <IonButton expand="full" color="secondary" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton expand="full" color="secondary" onClick={() => saveItem()}>
            {t('update')}
          </IonButton>
        )
      }
    >
      <IonItem lines="full">
        <IonLabel position="stacked">{t('barcode')}</IonLabel>
        <IonInput value={state.item.barcode} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      {props.useInventoryBarcode && (
        <IonItem lines="full">
          <IonLabel position="stacked">{t('inventoryBarcode')}</IonLabel>
          <IonInput value={state.item.inventoryBarcode} placeholder={PLACEHOLDER} disabled />
        </IonItem>
      )}
      <IonItem lines="full">
        <IonLabel position="stacked">{t('binLocation')}</IonLabel>
        <IonInput value={state.item.binLocation} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('itemNumber')}</IonLabel>
        <IonInput value={state.item.itemNumber} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('description')}</IonLabel>
        <IonInput value={state.item.description} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonRow>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('package')}</IonLabel>
            <IonInput value={state.item.packageSizeUom} placeholder={PLACEHOLDER} disabled />
          </IonItem>
        </IonCol>
        {allowPricing() && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('price')} ($)</IonLabel>
              <IonInput value={state.item.price} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('onhand')}</IonLabel>
            <IonInput value={state.item.onHand} placeholder={PLACEHOLDER} disabled />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('onhand2')}</IonLabel>
              <IonInput value={state.item.onHand2} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonRow className="ion-margin-bottom">
        <IonCol>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('count')}</IonLabel>
            <IonInput
              value={state.item.count > -1 ? state.item.count : null}
              type="number"
              inputmode="number"
              inputMode="number"
              min={-1}
              placeholder={PLACEHOLDER}
              onKeyPress={filterKeys(/\D/)}
              onIonInput={(e) => setItemValue('count', tryParseInt(e.target.value, -1))}
              disabled={readOnly}
              clearOnEdit
            />
          </IonItem>
        </IonCol>
        {props.scannerSettings.showCount2 && (
          <IonCol>
            <IonItem lines="full">
              <IonLabel position="stacked">{t('count2')}</IonLabel>
              <IonInput
                value={state.item.count2 > -1 ? state.item.count2 : null}
                type="number"
                inputmode="number"
                inputMode="number"
                min={-1}
                placeholder={PLACEHOLDER}
                onKeyPress={filterKeys(/\D/)}
                onIonInput={(e) => setItemValue('count2', tryParseInt(e.target.value, -1))}
                disabled={readOnly}
                clearOnEdit
              />
            </IonItem>
          </IonCol>
        )}
      </IonRow>
    </Page>
  )
}

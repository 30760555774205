import styled from 'styled-components'
import { IonItem } from '@ionic/react'

export default styled(IonItem)`
  ion-searchbar {
    border: none;
    padding-left: 0;
  }

  .ant-btn {
    width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 7px;
    border-radius: 8px;
    background-color: var(--ion-internal-light-dark);
    border: none;
  }
`

import { createListViewContainer } from 'factories/ListView'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { getCustomerLocationCounts } from 'helpers/manageCustomers'
import customerActions from 'actions/customers'
import locationActions from 'actions/locations'
import locationGroupActions from 'actions/locationGroups'
import locationCountActions from 'actions/locationCounts'
import { trimMobileDownloadItems } from 'helpers/offlineData'
import entityNames from 'options/entityNames'
import Component from 'components/Distributor/UploadItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await dispatch(customerActions.getOptionsOffline()).then((r) =>
        r.value.data.items.filter(filterBySearch(params.search))
      )

      const locationCounts = await Promise.all(items.map((each) => getCustomerLocationCounts(each.id)))

      return wrapItems(
        items.map((each, index) => ({ ...each, locationCounts: locationCounts[index].length }))
      )
    },
    uploadAndCommit: (params) => dispatch(locationCountActions.uploadAndCommit(params)),
    getLocations: (params) =>
      dispatch(
        locationActions.getItems({
          pageIndex: 0,
          mobileDownload: true,
          locationVendingTypes: ['NonVending'],
          ...params,
        })
      ).then(trimMobileDownloadItems(entityNames.locations)),
    getLocationGroups: (params) =>
      dispatch(locationGroupActions.getItems({ pageIndex: 0, mobileDownload: true, ...params })),
  }),
})(Component)

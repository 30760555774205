import * as React from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import {
  IonSearchbar,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonList,
  IonAlert,
  IonModal,
} from '@ionic/react'
import useOnlineStatus from '@rehooks/online-status'
import { set, isEmpty, pick, isNil, invoke } from 'lodash'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { sortItems, filterBySearch, message, FILTER_SCROLL_TOP } from 'helpers/utils'
import { removeSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { Emitter } from 'helpers/events'
import Sort from 'elements/Sort'
import RowActions from 'elements/RowActions'

const Divider = styled.hr`
  border: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border-top: '1px solid var(--ion-item-border-color)';
`

export default ({
    getStorageKey = (self) => self.props.storageKey ?? '__CHILD_LIST_VIEW__',
    allowSearch = (self) => false,
    allowDelete = (self) => (item) => false,
    renderItem = (self) => (item) => null,
    getSortByFields = (self) => [],
    getDefaultSortOrder = (self) => null,
    getDefaultSortByField = (self) => null,
    filterItems = (self) => (each) => true,
    confirmDeleteLanguageKey = 'confirmDeleteItem',
    getIdField = (self) => 'id',
    getListHeader = (self) => null,
    getRowActionItems = (self) => [],
  } = {}) =>
  (Filter) =>
  (props) => {
    const isOnline = useOnlineStatus()

    const [state, updateState] = useImmer({
      search: '',
      filterDto: {},
      selectedRowKeys: [],
      ...getStorageItem(getStorageKey({ props }), {}),
    })

    const setState = React.useCallback((name, value) => {
      updateState((draft) => {
        set(draft, name, value)
      })
    }, [])

    const self = {
      props,
      state,
      updateState,
      setState,
      isOnline,
    }

    function handleDelete(item) {
      const buttons = [
        { text: t('cancel'), role: 'cancel' },
        {
          text: t('delete'),
          handler: () => {
            const { onChange = () => {}, items = [] } = props
            onChange(items.filter((each) => each[getIdField(self)] !== item[getIdField(self)]))
          },
        },
      ]

      return () => {
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t(confirmDeleteLanguageKey)
          draft.alertButtons = buttons
        })
      }
    }

    React.useEffect(() => {
      setStorageItem(getStorageKey({ props }), pick(state, ['sortByField', 'sortOrder']))
    }, [state.sortByField, state.sortOrder])

    React.useEffect(() => {
      function handleSortOptions() {
        updateState((draft) => {
          draft.modalIsOpen = true
          draft.modalContent = 'sort'
        })
      }

      Emitter.on(`${getStorageKey({ props })}.sortOptions`, handleSortOptions)

      return () => Emitter.off(`${getStorageKey({ props })}.sortOptions`, handleSortOptions)
    }, [])

    React.useEffect(() => {
      function handleShowFilter() {
        if (!isNil(Filter)) {
          updateState((draft) => {
            draft.modalIsOpen = true
            draft.modalContent = 'filter'
          })
        } else {
          message.info(t('underDevelopment'))
        }
      }

      Emitter.on(`${getStorageKey({ props })}.showFilter`, handleShowFilter)

      return () => Emitter.off(`${getStorageKey({ props })}.showFilter`, handleShowFilter)
    }, [])

    React.useEffect(() => {
      invoke(props, 'onFilter', state.filterDto)
    }, [state.filterDto])

    React.useEffect(() => {
      updateState((draft) => {
        draft.selectedRowAction = null
        draft.selectedRowKeys = []
      })
    }, [props.rowActionsIsOpen])

    React.useEffect(() => {
      setState('selectedRowKeys', [])
    }, [props.items, state.filterDto, state.search])

    const showSearch = allowSearch(self)
    const listHeader = getListHeader(self)
    const showListHeader = !isNil(listHeader)
    const sortByFields = getSortByFields(self)
    const unsortedItems = (props.items ?? [])
      .filter(filterItems({ props, state }))
      .filter(filterBySearch(state.search))
    const filteredItems = !isEmpty(sortByFields)
      ? sortItems(
          unsortedItems,
          state.sortByField ?? getDefaultSortByField(self),
          state.sortOrder ?? getDefaultSortOrder(self)
        )
      : unsortedItems
    const rowActionItems = getRowActionItems(self)

    return (
      <>
        {showSearch && (
          <IonSearchbar
            value={state.search}
            onIonChange={(e) => setState('search', e.detail.value)}
            maxlength={200}
          />
        )}
        {showListHeader && listHeader}
        {props.rowActionsIsOpen && (
          <RowActions
            style={{ padding: '0 11px 8px 12px' }}
            {...{
              setState,
              rowActionItems,
              idField: getIdField(self),
              items: filteredItems,
              onRowAction: props.onRowAction,
              selectedRowKeys: state.selectedRowKeys,
            }}
          />
        )}
        {(showSearch || showListHeader || props.rowActionsIsOpen) && <Divider />}
        {isEmpty(filteredItems) ? (
          <p className="ion-margin">{t('noData')}</p>
        ) : (
          <IonList>
            {filteredItems.map((item) => {
              const key = item[getIdField(self)]
              const renderedItem = renderItem(self)(item)

              return allowDelete(self)(item) && !props.readOnly && !props.rowActionsIsOpen ? (
                <IonItemSliding key={key}>
                  {renderedItem}
                  <IonItemOptions side="end">
                    <IonItemOption color="danger" onClick={handleDelete(item)} expandable>
                      {t('delete')}
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ) : (
                <React.Fragment key={key}>{renderedItem}</React.Fragment>
              )
            })}
          </IonList>
        )}
        <IonModal isOpen={state.modalIsOpen}>
          {state.modalIsOpen && state.modalContent === 'filter' && (
            <Filter
              filterDto={state.filterDto ?? {}}
              onChange={(value) => {
                updateState((draft) => {
                  draft.filterDto = isEmpty(value)
                    ? pick(draft.filterDto, ['dateRange', 'dateRangeField'])
                    : value
                })
              }}
              onClose={() => {
                setState('modalIsOpen', false)
                removeSessionItem(FILTER_SCROLL_TOP)
              }}
              parentRecord={props.parentRecord}
            />
          )}
          {state.modalIsOpen && state.modalContent === 'sort' && (
            <Sort
              sortByFields={sortByFields}
              sortByField={state.sortByField}
              sortOrder={state.sortOrder}
              onChange={(e) => {
                updateState((draft) => {
                  draft.sortByField = e.sortByField
                  draft.sortOrder = e.sortOrder
                })
              }}
              onClose={() => setState('modalIsOpen', false)}
            />
          )}
        </IonModal>
        <IonAlert
          backdropDismiss={false}
          isOpen={state.alertIsOpen}
          header={state.alertHeader}
          message={state.alertMessage}
          buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
          onDidDismiss={() => setState('alertIsOpen', false)}
        />
      </>
    )
  }

import Page from 'elements/Page'
import NavList from 'elements/NavList'

export default function NotFound(props) {
  return (
    <Page>
      <NavList />
    </Page>
  )
}

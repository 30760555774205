import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'day',
  getItemSize: () => 45,
  allowSearch: () => false,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.recurOnDaysOfMonth,
  getOnChange: (self) => (each) => {
    updateSessionItem(getStorageKey(), {}, (draft) => {
      const index = draft.item.recurOnDaysOfMonth.findIndex((one) => one === each.id)

      if (index > -1) {
        draft.item.recurOnDaysOfMonth.splice(index, 1)
      } else {
        draft.item.recurOnDaysOfMonth.push(each.id)
      }
    })
  },
  allowMultiple: true,
  allowSelectAllWhenMultiple: false,
})()

import { IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { t } from 'helpers/i18n'
import { getCustomerSettings } from 'helpers/settings'
import { customerStartupOptions } from 'options/startupOptions'
import Page from 'elements/Page'

export default function (props) {
  const customerSettings = getCustomerSettings()

  const items = [
    {
      key: 'startupPage',
      value: t(customerStartupOptions[customerSettings.startupPage]),
      routerLink: `${props.match.url}/startupPage`,
    },
  ]

  return (
    <Page>
      <IonList>
        {items.map((each) => (
          <IonItem key={each.key} lines="full" routerLink={each.routerLink} className="tofino-stacked-item">
            <IonLabel>
              <IonText color="medium">
                <small>{t(each.key)}</small>
              </IonText>
              <br />
              {each.value}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </Page>
  )
}

export const customerStartupOptions = {
  showWelcomePage: 'showWelcomePage',
  showOrdersPage: 'showOrdersPage',
  showInventoryPage: 'showInventoryPage',
  showJobsPage: 'showJobsPage',
  showAssetsPage: 'showAssetsPage',
  showIssuePage: 'showIssuePage',
  showCountPage: 'showCountPage',
  showCycleCountsPage: 'showCycleCountsPage',
  showLocationCountsPage: 'showLocationCountsPage',
}

export const userStartupOptions = {
  basedOnCustomerSetting: 'basedOnCustomerSetting',
  ...customerStartupOptions,
}

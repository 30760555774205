import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'status',
  allowInfiniteLoader: () => false,
  allowSearch: () => false,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.frequency,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.frequency = each.id

      if (draft.item.frequency === 'Yearly') {
        draft.item.recurOnMonths = [1]
        draft.item.recurOnDaysOfMonth = [1]
      }

      if (draft.item.frequency === 'Monthly') {
        draft.item.recurOnMonths = []
        draft.item.recurOnDaysOfMonth = []
      }
    }),
})()

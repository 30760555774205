import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react'
import { get, set } from 'lodash'
import { t } from 'helpers/i18n'
import sortOrders from 'options/sortOrders'
import ModalSelect from 'elements/ModalSelect'

export default function (props) {
  const {
    sortByFields = [],
    sortByField = null,
    sortOrder = null,
    onChange = () => {},
    onClose = () => {},
  } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  if (state.activeView === 'sortByField') {
    return (
      <ModalSelect
        title={t('sortBy')}
        options={[
          { value: null, text: t('default') },
          ...sortByFields.map((each) => ({
            value: each.fieldName,
            text: t(each.languageKey),
          })),
        ]}
        value={sortByField}
        onChange={(value) => onChange({ sortByField: value, sortOrder })}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'sortOrder') {
    return (
      <ModalSelect
        title={t('sortOrder')}
        options={[
          { value: null, text: t('default') },
          ...Object.entries(sortOrders).map((each) => ({ value: each[0], text: t(each[1]) })),
        ]}
        value={sortOrder}
        onChange={(value) => onChange({ sortByField, sortOrder: value })}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({ sortByField: null, sortOrder: null })}>
              {t('reset')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('sortOptions')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'sortByField')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('sortBy')}</small>
            </IonText>
            <br />
            {t(sortByFields.find((one) => one.fieldName === sortByField)?.languageKey ?? 'default')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'sortOrder')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('sortOrder')}</small>
            </IonText>
            <br />
            {t(get(sortOrders, sortOrder, 'default'))}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  )
}

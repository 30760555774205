import { get, remove } from 'lodash'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { createListViewContainer } from 'factories/ListView'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { isItemOrdered } from 'helpers/locationCounts'
import Component from 'components/LocationCounts/CurrentCount/ReviewOrder'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)
        .then((r) => get(r, 'locationCountDetails', []))
        .then((r) => r.filter(isItemOrdered).filter(filterBySearch(params.search)))
        .then(wrapItems),
    deleteItem: (id) =>
      updatePrivateItem(LOCATION_COUNTS_CURRENT_KEY, {}, (draft) => {
        remove(draft.locationCountDetails, (each) => each.id === id)
      }),
  }),
})(Component)

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import dataImportActions from 'actions/dataImport'
import Component from 'components/LocationCounts/CurrentCount/CountItems'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = { getExternalItemStatus: dataImportActions.getItemStatus }

export default connect(mapStateToProps, mapDispatchToProps)(Component)

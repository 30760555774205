import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'location',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.locationId,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.locationId = each.id
      draft.item.locationName = each.displayName
      draft.item.assetId = 0
      draft.item.assetName = ''
      draft.toleranceItems = []
      draft.toleranceItemsOriginal = []
      draft.warrantyItems = []
      draft.warrantyItemsOriginal = []
    }),
})()

import * as React from 'react'
import { useImmer } from 'use-immer'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, IonToast } from '@ionic/react'
import { useSelector } from 'react-redux'
import useOnlineStatus from '@rehooks/online-status'
import { set } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import { Emitter } from 'helpers/events'
import { NOTIFICATION } from 'options/events'
import { t } from 'helpers/i18n'
import selectors from 'selectors'
import { notification } from 'helpers/utils'
import createRoutes from 'routes'
import { getSessionItem, setSessionItem } from 'helpers/sessionStorage'
import { OFFLINE_SESSION_KEY } from 'options/auth'
import ErrorBoundary from 'components/ErrorBoundary'

const buttons = [
  {
    icon: 'close',
    role: 'cancel',
  },
]

export default function () {
  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  React.useEffect(() => {
    function handleNotification(params) {
      setState('notification', {})
      window.setTimeout(() => setState('notification', { isOpen: true, ...params }), 250)
    }

    Emitter.on(NOTIFICATION, handleNotification)

    return () => Emitter.off(NOTIFICATION, handleNotification)
  }, [])

  const locale = useSelector(selectors.auth.locale)

  React.useEffect(() => {
    moment.locale(locale)
  }, [locale])

  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    const wasOffline = getSessionItem(OFFLINE_SESSION_KEY)

    if (isOnline) {
      if (wasOffline) {
        notification.success({
          header: t('onlineStatus'),
          message: t('onlineStatusDescription'),
        })
      }

      setSessionItem(OFFLINE_SESSION_KEY, false)
    } else {
      notification.error({
        header: t('offlineStatus'),
        message: t('offlineStatusDescription'),
      })

      setSessionItem(OFFLINE_SESSION_KEY, true)
    }
  }, [isOnline])

  return (
    <ErrorBoundary>
      <IonApp>
        <IonReactRouter>{createRoutes()}</IonReactRouter>
        <IonToast
          position="top"
          buttons={buttons}
          onDidDismiss={() => setState('notification.isOpen', false)}
          {...state.notification}
        />
      </IonApp>
    </ErrorBoundary>
  )
}

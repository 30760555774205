import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
  IonToggle,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { PRIVATE_DATABASE } from 'options/products'
import ModalSelect from 'elements/ModalSelect'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const response = await props.getProductCatalogs()

      updateState((draft) => {
        draft.productCatalogs = [
          { catalogTableName: '', longName: t(PRIVATE_DATABASE) },
          ...get(response, 'value.data', []),
        ]
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const productCatalogs = state.productCatalogs ?? []
  const catalogTableName = filterDto?.catalogTableName ?? ''
  const selectFavorites = filterDto?.selectFavorites ?? false

  if (state.activeView === 'catalogTableName') {
    return (
      <ModalSelect
        title={t('database')}
        options={(state.productCatalogs ?? []).map((each) => ({
          value: each.catalogTableName,
          text: each.longName,
        }))}
        value={catalogTableName}
        onChange={(value) => {
          setFilter('catalogTableName', value)
        }}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  // console.log({ filterDto })

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'catalogTableName')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('database')}</small>
            </IonText>
            <br />
            {get(
              productCatalogs.find((one) => one.catalogTableName === catalogTableName),
              'longName',
              t(PRIVATE_DATABASE)
            )}
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <IonLabel>{t('favorites')}</IonLabel>
          <IonToggle
            checked={selectFavorites}
            onIonChange={(e) => setFilter('selectFavorites', e.detail.checked)}
          />
        </IonItem>
      </IonContent>
    </>
  )
}

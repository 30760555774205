import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonPopover,
  IonItem,
  IonLabel,
  IonSpinner,
  IonInput,
  IonText,
  IonSearchbar,
  IonLoading,
  IonAlert,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { get, set, isNil, isEmpty, camelCase, pick, intersection, cloneDeep, omit } from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import {
  asyncSleep,
  simulatePressEnter,
  message,
  strEqual,
  getTagDisplayNameField,
  getTagName,
  PLACEHOLDER,
} from 'helpers/utils'
import { validateOperatorTag, validateJobTag, validateOtherTags } from 'helpers/procurement'
import { showError, showValidationErrors } from 'helpers/errors'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Button from 'elements/Button'
import BarcodeScanner from 'elements/BarcodeScanner'
import SearchbarContainer from 'elements/SearchbarContainer'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  'A',
  'B',
  'C',
  'D',
  'E',
]

function Description({ asset }) {
  return (
    <p className="ion-margin-horizontal">
      {asset ? (
        <>
          {asset.description || PLACEHOLDER}
          <br />
          {t('partNumber:')} {asset.itemNumber || PLACEHOLDER}
          <br />
          {t('status:')} {asset.statusName || PLACEHOLDER}
          <br />
          {t('location:')} {asset.locationName || PLACEHOLDER}
          <br />
          {t('subLocation:')} {asset.binLocation || PLACEHOLDER}
        </>
      ) : (
        <IonText color="medium">{t('enterAssetIdInfo')}</IonText>
      )}
    </p>
  )
}

export const getStorageKey = () => 'trackAssets'

export default function (props) {
  const isOnline = useOnlineStatus()
  const ref1 = React.useRef()
  const ref2 = React.useRef()
  const refA = React.useRef()
  const refB = React.useRef()
  const refC = React.useRef()
  const refD = React.useRef()
  const refE = React.useRef()
  const refBarcode = React.useRef()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/assets'
    }
  }, [])

  const [state, updateState] = useImmer({ showTagFields: true, ...getStorageItem(getStorageKey(), {}) })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledAssets`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function getTagRef(tagType) {
    return {
      2: ref2,
      1: ref1,
      A: refA,
      B: refB,
      C: refC,
      D: refD,
      E: refE,
    }[tagType]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  function focusNextTag(tagType) {
    const indexes = ['2', '1', 'A', 'B', 'C', 'D', 'E', 'Barcode']
    const tagRefs = [ref2, ref1, refA, refB, refC, refD, refE, refBarcode]

    for (let index = indexes.indexOf(tagType) + 1; index < indexes.length; index++) {
      try {
        tagRefs[index].current.setFocus()
        return true
      } catch (error) {
        console.warn(error)
      }
    }

    return false
  }

  function findAsset(item = state.item) {
    const { barcode } = item

    validateFields(async () => {
      try {
        updateState((draft) => {
          draft.loadingIsOpen = true
          draft.asset = null
        })

        const results = await props.getAssets({ search: barcode }).then((r) => r.value.data.items)

        const asset = results.find((one) => strEqual(one.barcode, barcode))

        if (asset) {
          updateState((draft) => {
            draft.asset = asset
            draft.item.barcode = asset.barcode
          })
        } else {
          message.error(t('assetIdNotFound'))
        }
        setState('loadingIsOpen', false)
      } catch (error) {
        setState('loadingIsOpen', false)
        showError({ error })
      }
    })
  }

  function handleTagKeyPress(tagType) {
    if (tagType === 'Barcode') {
      return (e) => {
        if (e.key === 'Enter') {
          findAsset(state.item)
        } else {
          setState('asset', null)
        }
      }
    }

    return (e) => {
      if (e.key === 'Enter') {
        validateFields(() => {
          setState('loadingIsOpen', false)

          if (!focusNextTag(tagType)) {
            saveItem()
          }
        })
      }
    }
  }

  function handleBarcodeInput(e) {
    updateState((draft) => {
      draft.item.barcode = e.target.value
      draft.asset = null
    })
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isNil(values)) {
      callback(errors, values)
      return
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })
    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })
    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    setState('item', values)
    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (intersection(tagTypes, Object.keys(errors)).length) {
        setState('showTagFields', true)
      }

      if (isEmpty(errors)) {
        setState('loadingIsOpen', false)

        if (state.quickscan) {
          setSessionItem('trackAssets.quickscan', omit(values, ['barcode']))
        } else {
          removeSessionItem('trackAssets.quickscan')
        }

        setSessionItem('trackAssets.tags', omit(values, ['barcode']))

        props.history.push(`${props.match.url}/${state.asset.id}`)
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  async function handleBarcodeScan({ rawValue: value }) {
    setState('scanBarcodeIsOpen', false)
    await asyncSleep(500)
    refBarcode.current.setFocus()
    refBarcode.current.value = value
    handleBarcodeInput({ target: { value } })
    simulatePressEnter(refBarcode.current)
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  function fetchItem() {
    updateState((draft) => {
      draft.item = getSessionItem('trackAssets.quickscan', {})
      draft.asset = null
    })
    removeSessionItem('trackAssets.quickscan')
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    setStorageItem(getStorageKey(), pick(state, ['quickscan', 'showTagFields']))
  }, [state.quickscan, state.showTagFields])

  const pageTitle = t('trackAsset')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        <IonButton
          onClick={(e) => {
            updateState((draft) => {
              draft.popoverIsOpen = true
              draft.popoverEvent = e
            })
          }}
          disabled={!isOnline}
        >
          <Icon type="Menu" size="26" />
        </IonButton>
      }
      footer={
        <IonButton
          color="secondary"
          expand="full"
          onClick={() => saveItem()}
          disabled={isNil(state.asset) || !isOnline}
        >
          {t('track')}
        </IonButton>
      }
    >
      <>
        {Boolean(state.showTagFields) &&
          !isEmpty(enabledTagTypes) &&
          enabledTagTypes.map((tagType) => (
            <IonItem
              key={tagType}
              lines="full"
              className={cx('tofino-stacked-item', {
                'tofino-required-item': getTagIsRequired(tagType),
                'tofino-error-item': get(state, `errors.${tagType}`),
              })}
            >
              <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
              <IonInput
                ref={getTagRef(tagType)}
                value={getTagDisplayName(tagType)}
                placeholder={PLACEHOLDER}
                onIonInput={handleTagInput(tagType)}
                onKeyPress={handleTagKeyPress(tagType)}
                onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
              />
              {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
            </IonItem>
          ))}
        <SearchbarContainer lines="full">
          <IonSearchbar
            ref={refBarcode}
            value={state.item.barcode}
            placeholder={t('enterAssetId')}
            onIonInput={handleBarcodeInput}
            onKeyPress={handleTagKeyPress('Barcode')}
            onIonClear={() => setState('asset', null)}
            maxlength={200}
          />
          {props.scannerSettings.enableCameraScanning && (
            <Button onClick={() => setState('scanBarcodeIsOpen', true)}>
              <Icon type="barcode_scanner" style={{ color: 'var(--ion-color-primary)' }} symbols />
            </Button>
          )}
        </SearchbarContainer>
        <Description scannerSettings={props.scannerSettings} item={state.item} asset={state.asset} />
      </>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        <IonItem lines="full" onClick={() => setState('quickscan', !state.quickscan)}>
          <IonLabel>{t('quickscan')}</IonLabel>
          {state.quickscan && <Icon type="Check" slot="end" color="success" />}
        </IonItem>
        {!isEmpty(enabledTagTypes) && (
          <IonItem lines="none" onClick={() => setState('showTagFields', !state.showTagFields)}>
            <IonLabel>{t('showTagFields')}</IonLabel>
            {state.showTagFields && <Icon type="Check" slot="end" color="success" />}
          </IonItem>
        )}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <IonModal isOpen={state.scanBarcodeIsOpen}>
        {state.scanBarcodeIsOpen && (
          <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
        )}
      </IonModal>
    </Page>
  )
}

import { IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getSessionItem } from 'helpers/sessionStorage'
import { USER_LOGGED_IN_SESSION_KEY } from 'options/auth'
import { formatDateTime } from 'helpers/dateTime'
import Page from 'elements/Page'

export default function (props) {
  const items = [
    {
      key: 'customer',
      value: props.customer.name || PLACEHOLDER,
    },
    {
      key: 'user',
      value: props.user.displayName || PLACEHOLDER,
    },
    {
      key: 'loggedIn',
      value: formatDateTime(getSessionItem(USER_LOGGED_IN_SESSION_KEY), { showTime: true }),
    },
  ]

  return (
    <Page>
      <IonList>
        {items.map((each) => (
          <IonItem key={each.key} lines="full" className="tofino-stacked-item">
            <IonLabel>
              <small>{t(each.key)}</small>
              <br />
              <IonText color="medium">{each.value}</IonText>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </Page>
  )
}

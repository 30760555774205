import { IonItem, IonLabel, IonText } from '@ionic/react'
import { get, isEmpty } from 'lodash'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { setPrivateItem, getPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_SAVED_KEY, LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { showError } from 'helpers/errors'
import { isItemCounted, isItemOrdered } from 'helpers/locationCounts'
import Filter from 'containers/LocationCounts/EditCounts/Filter'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.editCounts',
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => {
      const locationCountDetails = get(item, 'locationCountDetails', [])
      const isCounted = locationCountDetails.filter(isItemCounted).length
      const isOrdered = locationCountDetails.filter(isItemOrdered).length

      return (
        <IonItem
          key={item.id}
          lines="full"
          onClick={async () => {
            try {
              await setPrivateItem(LOCATION_COUNTS_CURRENT_KEY, item)
              window.location.href = '/inventory/currentLocation'
            } catch (error) {
              showError({ error })
            }
          }}
          button
        >
          <IonLabel>
            {item.locationName}{' '}
            {item.orderId ? (
              <IonText color="danger">
                <small>({t('viewOnly')})</small>
              </IonText>
            ) : null}
            <br />
            <IonText color="medium">
              <small>{`${t('counted:')} ${isCounted}, ${t('ordered:')} ${isOrdered}`}</small>
            </IonText>
          </IonLabel>
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const items = await getPrivateItem(LOCATION_COUNTS_SAVED_KEY, [])

        if (isEmpty(items)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('noCountedLocations')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowDelete: (self) => (item) => true,
  allowSearch: (self) => true,
  getDeleteConfirmMessage: (self) => t('confirmDeleteLocationCount'),
})(Filter)

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'locationGroup',
  allowInfiniteLoader: () => false,
  allowSearch: () => false,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.locationGroupId,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.locationGroupId = each.id
      draft.item.locationGroupName = each.displayName
      draft.item.locationId = null
      draft.item.itemsAtLocation = null
      draft.item.itemsToCount = null
      draft.item.firstBarcode = null
      draft.item.lastBarcode = null
      draft.item.firstBinLocation = null
      draft.item.lastBinLocation = null
    }),
})()

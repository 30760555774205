import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonModal,
  IonButton,
  IonPopover,
  IonSearchbar,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import { FixedSizeList as List } from 'react-window'
import useOnlineStatus from '@rehooks/online-status'
import { get, set, isNil, isEmpty, floor, toString as str, pick } from 'lodash'
import { asyncSleep, DEBOUNCE, FILTER_SCROLL_TOP, isSignificant } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { removeSessionItem } from 'helpers/sessionStorage'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Button from 'elements/Button'
import SearchbarContainer from 'elements/SearchbarContainer'
import BarcodeScanner from 'elements/BarcodeScanner'

const DEFAULT_PAGE_SIZE = 50

export default ({
    pageTitleLanguageKey,
    pageTitle = (self) => (pageTitleLanguageKey ? t(pageTitleLanguageKey) : undefined),
    allowInfiniteLoader = (self) => true,
    getItemSize = (self) => {
      throw Error('getItemSize is not defined')
    },
    getIdField = (self) => 'id',
    getTextField = (self) => 'displayName',
    getValue = (self) => null,
    getOnChange = (self) => (value) => Promise.resolve(null),
    allowSearch = (self) => true,
    allowMultiple = false,
    allowSelectAllWhenMultiple = true,
    getInsignificantFilterDtoFields = (self) => ['dateRange', 'dateRangeField'],
    getToolbarIconColor = (self) =>
      Object.entries(self.state?.filterDto ?? {})
        .filter(([key, _]) => !getInsignificantFilterDtoFields(self).includes(key))
        .some(([_, value]) => isSignificant(value))
        ? 'warning'
        : undefined,
    getToolbarIcon = (self) => <Icon type="Menu" size="26" color={getToolbarIconColor(self)} />,
  }) =>
  (Filter) =>
  (props) => {
    const isOnline = useOnlineStatus()
    const refBarcode = React.useRef()

    const firstPageIndex = () => (!allowInfiniteLoader({ props }) ? 0 : isOnline ? 1 : 0)

    const [state, updateState] = useImmer({
      search: '',
      filterDto: {},
      pageIndex: firstPageIndex(),
      recordCount: 0,
    })

    const setState = React.useCallback((name, value) => {
      updateState((draft) => {
        set(draft, name, value)
      })
    }, [])

    async function prefetchItems(resetItems = false) {
      let response

      try {
        const { search = '', pageIndex = firstPageIndex(), filterDto = {} } = state

        if (resetItems) {
          updateState((draft) => {
            draft.items = null
            draft.recordCount = 0
          })
        }

        response = await props.getItems({
          pageIndex: firstPageIndex(),
          pageSize: (pageIndex + 1) * DEFAULT_PAGE_SIZE + 1,
          search,
          ...filterDto,
        })

        updateState((draft) => {
          draft.items = response.value.data.items
          draft.recordCount = response.value.data.recordCount || draft.items.length
        })
      } catch (error) {
        showError({ error })
      } finally {
        setState('viewDidEnter', true)
      }

      return response
    }

    async function fetchItems() {
      let response

      try {
        const { search = '', pageIndex = firstPageIndex(), filterDto = {} } = state

        response = await props.getItems({
          pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          search,
          ...filterDto,
        })

        if (isOnline) {
          updateState((draft) => {
            if (pageIndex <= firstPageIndex()) {
              draft.items = []
            } else {
              draft.items = draft.items || []
            }

            draft.items.splice((pageIndex - 1) * DEFAULT_PAGE_SIZE, 0, ...response.value.data.items)
            draft.recordCount = response.value.data.recordCount || draft.items.length
          })
        } else {
          updateState((draft) => {
            draft.items = response.value.data.items
            draft.recordCount = response.value.data.recordCount || draft.items.length
          })
        }
      } catch (error) {
        showError({ error })
        updateState((draft) => {
          draft.items = []
          draft.recordCount = 0
        })
      }

      return response
    }

    const self = {
      props,
      state,
      updateState,
      setState,
      fetchItems,
      prefetchItems,
      isOnline,
    }

    function isItemLoaded(index) {
      return !isNil(get(state, `items[${index}]`))
    }

    function loadMoreItems(startIndex) {
      setState('pageIndex', isOnline ? floor(startIndex / DEFAULT_PAGE_SIZE) + 1 : 0)
    }

    function handleBarcodeInput(e) {
      updateState((draft) => {
        draft.search = e.detail.value
        draft.pageIndex = firstPageIndex()
      })
    }

    async function handleBarcodeScan({ rawValue: value }) {
      setState('scanBarcodeIsOpen', false)
      await asyncSleep(500)
      refBarcode.current.setFocus()
      refBarcode.current.value = value
      handleBarcodeInput({ target: { value } })
    }

    const isSelected = (each, index) => {
      const value = getValue(self)

      if (allowMultiple) {
        if (allowSelectAllWhenMultiple) {
          if (index === 0 && isEmpty(value)) {
            return true
          }

          return index > 0 && value.includes(each)
        }

        return value.includes(each)
      }

      return str(each) === str(value)
    }

    const Row = ({ index, style }) => {
      if (isItemLoaded(index)) {
        const item = state.items[index]
        const Check = allowMultiple ? Icon.Check : Icon.Radio

        return (
          <div style={style}>
            <IonItem
              key={`${index}-${item[getIdField(self)]}`}
              lines="full"
              onClick={async () => {
                await getOnChange(self)(item)

                if (allowMultiple) {
                  prefetchItems()
                } else {
                  props.history.goBack()
                }
              }}
              detail={false}
              button
            >
              <IonLabel>{item[getTextField(self)] || t('notAssigned')}</IonLabel>
              <Check checked={isSelected(item[getIdField(self)], index)} />
            </IonItem>
          </div>
        )
      }

      return (
        <div style={style}>
          <div className="ion-text-center ion-margin-vertical">
            <IonSpinner name="lines-small" />
          </div>
        </div>
      )
    }

    React.useEffect(() => {
      if (state.viewDidEnter) {
        fetchItems()
      }
    }, [state.pageIndex, state.search, state.filterDto])

    useIonViewWillEnter(() => {
      setState('viewDidEnter', false)
    })

    useIonViewDidEnter(() => {
      prefetchItems()
    })

    React.useEffect(() => {
      if (state.viewDidEnter) {
        prefetchItems(true)
      }
    }, [isOnline])

    const itemCount = isNil(state.items)
      ? 0
      : isOnline
        ? Math.min(state.items.length + 1, state.recordCount)
        : state.items.length

    const popoverContent = [
      !isNil(Filter) ? (
        <IonItem
          key="showFilter"
          lines="none"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.popoverIsOpen = false
              draft.popoverEvent = null
              draft.modalIsOpen = true
              draft.modalContent = 'filter'
            })
          }}
          detail={false}
          button
        >
          <IonLabel>{t('showFilter')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter((each) => !isNil(each))
    const pageToolbarButton = !isEmpty(popoverContent) ? (
      <IonButton
        onClick={(e) => {
          updateState((draft) => {
            draft.popoverIsOpen = true
            draft.popoverEvent = e
          })
        }}
      >
        {getToolbarIcon(self)}
      </IonButton>
    ) : null

    return (
      <Page
        title={pageTitle(self)}
        toolbarButton={pageToolbarButton}
        header={
          allowSearch(self) ? (
            <SearchbarContainer lines="none">
              <IonSearchbar
                ref={refBarcode}
                onIonChange={handleBarcodeInput}
                debounce={DEBOUNCE}
                maxlength={200}
              />
              {props.scannerSettings.enableCameraScanning && (
                <Button onClick={() => setState('scanBarcodeIsOpen', true)}>
                  <Icon type="barcode_scanner" style={{ color: 'var(--ion-color-primary)' }} symbols />
                </Button>
              )}
            </SearchbarContainer>
          ) : null
        }
      >
        {isNil(state.items) ? (
          <IonSpinner className="ion-margin" />
        ) : isEmpty(state.items) ? (
          <p className="ion-margin">{t('noData')}</p>
        ) : allowInfiniteLoader(self) ? (
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={DEFAULT_PAGE_SIZE}
                threshold={floor(DEFAULT_PAGE_SIZE / 2)}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    ref={ref}
                    height={height}
                    itemCount={itemCount}
                    itemSize={getItemSize(self)}
                    width={width}
                    onItemsRendered={onItemsRendered}
                  >
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <IonList>
            {state.items.map((item, index) => {
              const Check = allowMultiple ? Icon.Check : Icon.Radio

              return (
                <IonItem
                  key={`${index}-${item[getIdField(self)]}`}
                  lines="full"
                  onClick={async () => {
                    await getOnChange(self)(item)

                    if (allowMultiple) {
                      prefetchItems()
                    } else {
                      props.history.goBack()
                    }
                  }}
                  detail={false}
                  button
                >
                  <IonLabel>{item[getTextField(self)] || t('notAssigned')}</IonLabel>
                  <Check checked={isSelected(item[getIdField(self)], index)} />
                </IonItem>
              )
            })}
          </IonList>
        )}
        <IonModal isOpen={state.modalIsOpen}>
          {state.modalIsOpen && state.modalContent === 'filter' && (
            <Filter
              filterDto={state.filterDto ?? {}}
              onChange={(value) => {
                updateState((draft) => {
                  draft.filterDto = isEmpty(value)
                    ? pick(draft.filterDto, ['dateRange', 'dateRangeField'])
                    : value
                })
              }}
              onClose={() => {
                setState('modalIsOpen', false)
                removeSessionItem(FILTER_SCROLL_TOP)
              }}
            />
          )}
        </IonModal>
        <IonPopover
          isOpen={state.popoverIsOpen}
          event={state.popoverEvent}
          onDidDismiss={() =>
            updateState((draft) => {
              draft.popoverIsOpen = false
              draft.popoverEvent = null
            })
          }
        >
          {popoverContent}
        </IonPopover>
        <IonModal isOpen={state.scanBarcodeIsOpen}>
          {state.scanBarcodeIsOpen && (
            <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
          )}
        </IonModal>
      </Page>
    )
  }

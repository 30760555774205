import produce from 'immer'
import { isNil, toString as str, isEmpty, isEqual } from 'lodash'
import { getSessionItem } from 'helpers/sessionStorage'
import { AUTH_SESSION_KEY } from 'options/auth'

const getScoped = (key) => {
  const auth = getSessionItem(AUTH_SESSION_KEY, {})
  const subdomain = auth.current?.tenant?.subdomain
  const customerId = auth.current?.customer?.id
  const userName = auth.current?.user?.userName

  return [process.env.REACT_APP_NAME, subdomain, customerId, userName, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')
}

export const getStorageItem = (key, defaultValue) => {
  try {
    const serializedValue = localStorage.getItem(getScoped(key))

    return isNil(serializedValue) ? defaultValue : JSON.parse(str(serializedValue))
  } catch (error) {
    return defaultValue
  }
}

export const getStorageItemNoScope = (key, defaultValue) => {
  try {
    const serializedValue = localStorage.getItem(`${process.env.REACT_APP_NAME}.${key}`)

    return isNil(serializedValue) ? defaultValue : JSON.parse(str(serializedValue))
  } catch (error) {
    return defaultValue
  }
}

export const setStorageItem = (key, value) => {
  const text = JSON.stringify(value)
  localStorage.setItem(getScoped(key), text)
}

export const updateStorageItem = (key, defaultValue, recipe) => {
  const value = getStorageItem(key, defaultValue)
  const before = produce(value, recipe)
  setStorageItem(key, before)
  const after = getStorageItem(key)
  return isEqual(before, after)
}

export const setStorageItemNoScope = (key, value) => {
  const text = JSON.stringify(value)
  localStorage.setItem(`${process.env.REACT_APP_NAME}.${key}`, text)
}

export const removeStorageItem = (key) => {
  try {
    localStorage.removeItem(getScoped(key))
  } catch (error) {
    console.warn(error)
  }
}

export const removeStorageItemNoScope = (key) => {
  try {
    localStorage.removeItem(`${process.env.REACT_APP_NAME}.${key}`)
  } catch (error) {
    console.warn(error)
  }
}

import { memoize } from 'lodash'
import { createFormViewContainer } from 'factories/FormView'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/requisitions'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import documentActions from 'actions/documents'
import supplierActions from 'actions/suppliers'
import orderActions from 'actions/orders'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import rfqActions from 'actions/rfqs'
import rfqItemActions from 'actions/rfqs/rfqItems'
import rfqSupplierActions from 'actions/rfqs/rfqSuppliers'
import Component from 'components/Requisitions/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    newItem: (params) => dispatch(actions.newItem(params)),
    populateRequisitionItems: (params) => dispatch(requisitionItemActions.populateRequisitionItems(params)),
    saveRequisition: (params) => dispatch(actions.saveRequisition(params)),
    deleteRequisition: (params) => dispatch(actions.deleteItem(params)),
    cancelRequisition: (params) => dispatch(actions.cancelRequisition(params)),
    closeRequisition: (params) => dispatch(actions.closeRequisition(params)),
    emailRequisition: (params) => dispatch(actions.emailRequisition(params)),
    sendRequisition: (params) => dispatch(actions.sendRequisition(params)),
    resendRequisition: (params) => dispatch(actions.resendRequisition(params)),
    approveRequisition: (params) => dispatch(actions.approveRequisition(params)),
    unapproveRequisition: (params) => dispatch(actions.unapproveRequisition(params)),
    getSupplier: memoize((supplierId) => dispatch(supplierActions.getItem(supplierId))),
    newOrder: (params) => dispatch(orderActions.newItem(params)),
    saveOrder: (params) => dispatch(orderActions.saveOrder(params)),
    populateOrderItems: (params) => dispatch(orderItemActions.populateOrderItems(params)),
    generatePurchaseOrderNumber: (params) =>
      dispatch(orderSupplierActions.generatePurchaseOrderNumber(params)),
    copyAllObjectDocuments: (params) => dispatch(documentActions.copyAllObjectDocuments(params)),
    newRfq: (params) => dispatch(rfqActions.newItem(params)),
    saveRfq: (params) => dispatch(rfqActions.saveRfq(params)),
    populateRfqItems: (params) => dispatch(rfqItemActions.populateRfqItems(params)),
    ...createChildItemsDispatchToProps(dispatch, 'Rfq', rfqItemActions),
    ...createChildItemsDispatchToProps(dispatch, 'RfqSupplier', rfqSupplierActions),
    ...createChildItemsDispatchToProps(dispatch, 'Order', orderItemActions),
    ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', orderSupplierActions),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'Requisition', requisitionItemActions),
  }),
})(Component)

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import operatorGroupActions from 'actions/operatorGroups'
import Component from 'components/Jobs/FormView/SelectOperators/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const dispatchToProps = (dispatch, props) => ({
  getOperatorGroups: (params) => dispatch(operatorGroupActions.getOptions(params)),
})

export default connect(mapStateToProps, dispatchToProps)(Component)

import * as React from 'react'
import { set } from 'lodash'
import { useImmer } from 'use-immer'
import useOnlineStatus from '@rehooks/online-status'
import { IonButton, IonText, IonLoading, IonAlert } from '@ionic/react'
import { asyncSleep } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getLogoutUrl, logout } from 'helpers/auth'
import { sharedStorage, privateStorage } from 'helpers/localForage'
import Page from 'elements/Page'

export default function (props) {
  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const buttons = [
    { text: t('cancel'), role: 'cancel' },
    {
      text: t('clear'),
      handler: async () => {
        updateState((draft) => {
          draft.loadingIsOpen = true
          draft.loadingMessage = t('clearingApplicationData...')
        })

        try {
          await privateStorage.dropInstance()
        } catch (error) {
          console.warn(error)
        }

        try {
          await sharedStorage.dropInstance()
        } catch (error) {
          console.warn(error)
        }

        window.localStorage.clear()

        await asyncSleep()

        setState('loadingIsOpen', false)

        await asyncSleep()

        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('applicationDataClearedSuccessfully')
          draft.alertButtons = [
            {
              text: t('ok'),
              role: 'cancel',
              handler: () => {
                logout().finally(() => {
                  sessionStorage.clear()
                  window.location.href = getLogoutUrl({ props, isOnline })
                })
              },
            },
          ]
        })
      },
    },
  ]

  return (
    <Page
      footer={
        <IonButton
          expand="full"
          color="secondary"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('confirmClearApplicationData')
              draft.alertButtons = buttons
            })
          }}
          disabled={!isOnline}
        >
          {t('clear')}
        </IonButton>
      }
    >
      <p
        dangerouslySetInnerHTML={{ __html: t('clearApplicationDataSummary') }}
        className="ion-margin-horizontal"
      />
      <p className="ion-margin-horizontal">
        <IonText color="danger">{t('clearApplicationDataWarning')}</IonText>
      </p>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

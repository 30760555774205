import { range } from 'lodash'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { wrapItems } from 'helpers/utils'
import Component from 'components/TrackAssets/FormView/SelectLifeRemaining'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(range(100, -10, -10).map((each) => ({ id: each, displayName: `${each}%` }))),
  }),
})(Component)

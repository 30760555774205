import produce from 'immer'
import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'

const entityName = entityNames.users
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const defaultActions = createActions(entityName, {
  idField: 'userName',
  memoizeGetOptions: true,
  transformGetOptionsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.active = draft.active ?? 'Active'
    }),
})

export default {
  ...defaultActions,

  resetPassword: (params) => ({
    type: `${ENTITY_NAME}_RESET_PASSWORD`,
    payload: createPost(entityName, { action: 'resetPassword' })(params),
  }),

  getRequisitionSendToList: (params) => ({
    type: `${ENTITY_NAME}_REQUISITION_SEND_TO_LIST`,
    payload: createPost(entityName, { action: 'requisitionSendToList' })(params),
  }),

  getOptionsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.users.options'

    try {
      const response = await dispatch(
        defaultActions.getOptions({ pageIndex: 0, mobileDownload: true, active: 'Active' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

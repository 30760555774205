/* eslint-disable camelcase */
import * as React from 'react'
import cx from 'clsx'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useImmer } from 'use-immer'
import useOnlineStatus from '@rehooks/online-status'
import { useDebouncedCallback } from 'use-debounce'
import { set, toLower, isEmpty } from 'lodash'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonLoading,
  IonButton,
  IonAlert,
  IonText,
} from '@ionic/react'
import { asyncSleep, DEBOUNCE, tryParseQueryString } from 'helpers/utils'
import { showError, showValidationErrors } from 'helpers/errors'
import { t } from 'helpers/i18n'
import { setStorageItem, getStorageItem, getStorageItemNoScope } from 'helpers/localStorage'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { INACTIVITY_SESSION_KEY, LAST_TENANT_STORAGE_KEY } from 'options/auth'
import { getSharedItemNoScope } from 'helpers/localForage'
import { getStartupPage, getLogoutUrl, logout, sendClientLog } from 'helpers/auth'
import Icon from 'elements/Icon'
import Logo from 'elements/Logo'

const getStorageKey = () => 'login.form'

const Wrapper = styled.div`
  ion-label {
    font-size: var(--tofino-font-size-small);
  }

  ion-input {
    input {
      color: var(--ion-color-secondary) !important;
      font-size: var(--tofino-font-size-regular);
    }
  }

  .tofino-error-item {
    ion-input {
      input {
        color: var(--ion-color-danger) !important;
      }
    }
  }
`

const Offline = styled.div`
  color: var(--ion-color-primary);
  line-height: 1.3;
  font-size: var(--tofino-font-size-small);
  margin: 12px 16px;
  visibility: ${(props) => (!props.isOnline && !props.ssoEnabled ? 'visible' : 'hidden')};
`

export default function (props) {
  const isOnline = useOnlineStatus()

  const refUserName = React.useRef()
  const refPassword = React.useRef()

  const { customerid, customerId, customerID, customer_id } = tryParseQueryString(
    window.location.hash ? tryParseQueryString(window.location.hash).state : window.location.search
  )

  const injectedTenant = customerid || customerId || customerID || customer_id

  const [state, updateState] = useImmer({
    ...getStorageItem(getStorageKey(), {}),
    ...(injectedTenant ? { tenant: injectedTenant } : {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchCustomers({ tenant, user }) {
    const tenantTypeIsDrcib = tenant.tenantType.toLowerCase() === 'dcrib'
    const { userIsInRoleDistributor } = user ?? {}

    if (tenantTypeIsDrcib && userIsInRoleDistributor) {
      try {
        await props.getCustomers()
      } catch (error) {
        if (!error.response) {
          setState('loadingIsOpen', false)
          await asyncSleep()

          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('noCustomerDataFound')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => {
                  logout().finally(() => {
                    sessionStorage.clear()
                    window.location.href = getLogoutUrl({ props, isOnline })
                  })
                },
              },
            ]
          })
          return
        }
        throw error
      }
    }
  }

  function validateFields(callback) {
    const errors = {}
    const { tenant, userName, password, tenantInfo } = state

    if (isEmpty(tenant)) {
      errors.tenant = t('errorMissingRequiredField')
    }

    if (isEmpty(userName) && !tenantInfo?.ssoEnabled) {
      errors.userName = t('errorMissingRequiredField')
    }

    if (isEmpty(password) && !tenantInfo?.ssoEnabled) {
      errors.password = t('errorMissingRequiredField')
    }

    callback(errors, { tenant, userName, password })
  }

  function handleLogin() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        if (state.tenantInfo?.ssoEnabled) {
          window.location.href = state.tenantInfo?.authenticationRedirectUrl.replace(
            /redirect_uri=\(redirect_uri\)/,
            `redirect_uri=${encodeURIComponent(`${window.location.origin}/login`)}${
              window.location.search ? `&state=${encodeURIComponent(window.location.search.replace(/^\?/, ''))}` : ''
            }`
          )
        } else {
          try {
            updateState((draft) => {
              draft.loadingIsOpen = true
              draft.loadingMessage = null
              draft.errors = {}
            })

            const data = await props
              .login({
                ...values,
                tenant: toLower(values.tenant),
              })
              .then((r) => r.value.data)

            await fetchCustomers(data)

            const startPage = await getStartupPage(data)

            await sendClientLog('Success', 'Login', { startPage })

            window.location.href = startPage
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          }
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  React.useEffect(() => {
    if (getSessionItem(INACTIVITY_SESSION_KEY)) {
      updateState((draft) => {
        draft.alertIsOpen = true
        draft.alertMessage = t('interactionWarningDescription')
        draft.alertButtons = [t('ok')]
      })

      removeSessionItem(INACTIVITY_SESSION_KEY)
    }
  }, [])

  React.useEffect(() => {
    ;(async function () {
      try {
        const lastTenant = getStorageItemNoScope(LAST_TENANT_STORAGE_KEY)

        if (lastTenant) {
          const response = await getSharedItemNoScope(`logo.${lastTenant.subdomain}`)

          setState('logo', response)
        }
      } catch (error) {
        console.warn(error)
      }
    })()
  }, [])

  React.useEffect(() => {
    setStorageItem(getStorageKey(), { tenant: toLower(state.tenant) })
  }, [state.tenant])

  const fetchTenantInfo = useDebouncedCallback(async (subdomain) => {
    try {
      const response = await props.getTenantInfo({ subdomain })
      setState('tenantInfo', response.value.data)
    } catch (error) {
      console.error(error)
      setState('tenantInfo', null)
    }
  }, DEBOUNCE)

  React.useEffect(() => {
    if (state.tenant) {
      fetchTenantInfo(toLower(state.tenant))
    } else {
      setState('tenantInfo', null)
      fetchTenantInfo.cancel()
    }
  }, [state.tenant, isOnline])

  React.useEffect(() => {
    async function tryLoginSso() {
      const { access_token, id_token } = tryParseQueryString(window.location.hash)

      if (access_token && id_token) {
        try {
          updateState((draft) => {
            draft.loadingIsOpen = true
            draft.loadingMessage = null
            draft.errors = {}
          })

          const data = await props
            .login({
              tenant: toLower(state.tenant),
              idToken: id_token,
              accessToken: access_token,
            })
            .then((r) => r.value.data)

          await fetchCustomers(data)

          const startPage = await getStartupPage(data)

          await sendClientLog('Success', 'Login SSO', { startPage })

          window.location.href = startPage
        } catch (error) {
          showError({ error })
          setState('loading', false)
        }
      }
    }

    if (window.location.hash) {
      tryLoginSso()
    }
  }, [window.location.hash])

  const pageTitle = t('login')

  return (
    <>
      <Helmet>
        <title>TRMS</title>
      </Helmet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent forceOverscroll={false}>
          <Logo file={state.logo} />
          <Wrapper>
            <IonItem lines="full" />
            <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.tenant })}>
              <Icon slot="start" type="Store" />
              <IonLabel>{t('customerId')}</IonLabel>
              <IonInput
                value={state.tenant}
                onIonInput={(e) => setState('tenant', e.target.value)}
                className="ion-text-right ion-padding-right"
                onKeyPress={(e) => e.key === 'Enter' && refUserName.current.setFocus()}
              />
              <Icon
                slot="end"
                type="Cancel"
                className={cx('cursor-pointer opacity-70', { invisible: isEmpty(state.tenant) })}
                onClick={() => setState('tenant', '')}
              />
            </IonItem>
            {!state.tenantInfo?.ssoEnabled && (
              <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.userName })}>
                <Icon slot="start" type="Person" />
                <IonLabel>{t('userId')}</IonLabel>
                <IonInput
                  ref={refUserName}
                  value={state.userName}
                  onIonInput={(e) => setState('userName', e.target.value)}
                  className="ion-text-right ion-padding-right"
                  onKeyPress={(e) => e.key === 'Enter' && refPassword.current.setFocus()}
                  autocomplete="username"
                />
                <Icon
                  slot="end"
                  type="Cancel"
                  className={cx('cursor-pointer opacity-70', { invisible: isEmpty(state.userName) })}
                  onClick={() => setState('userName', '')}
                />
              </IonItem>
            )}
            {!state.tenantInfo?.ssoEnabled && (
              <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.password })}>
                <Icon slot="start" type="Lock" />
                <IonLabel>{t('password')}</IonLabel>
                <IonInput
                  ref={refPassword}
                  value={state.password}
                  onIonInput={(e) => setState('password', e.target.value)}
                  className="ion-text-right ion-padding-right"
                  onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                  type={state.passwordVisible ? 'text' : 'password'}
                  autocomplete="current-password"
                />
                <Icon
                  slot="end"
                  type={state.passwordVisible ? 'Visibility' : 'VisibilityOff'}
                  className="cursor-pointer opacity-70"
                  onClick={() =>
                    updateState((draft) => {
                      draft.passwordVisible = !draft.passwordVisible
                    })
                  }
                />
              </IonItem>
            )}
            <Offline isOnline={isOnline} ssoEnabled={state.tenantInfo?.ssoEnabled}>
              <IonText color="medium">{t('offlineLoginInfo')}</IonText>
            </Offline>
            <IonButton
              color="secondary"
              expand="full"
              onClick={handleLogin}
              disabled={state.tenantInfo?.ssoEnabled && !isOnline}
            >
              {state.tenantInfo?.ssoEnabled ? t('loginUsingSSO') : t('login')}
            </IonButton>
          </Wrapper>
          <IonLoading
            spinner="lines-small"
            isOpen={state.loadingIsOpen}
            message={state.loadingMessage ?? t('pleaseWait...')}
          />
          <IonAlert
            backdropDismiss={false}
            isOpen={state.alertIsOpen}
            header={state.alertHeader}
            message={state.alertMessage}
            buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
            onDidDismiss={() => setState('alertIsOpen', false)}
          />
        </IonContent>
      </IonPage>
    </>
  )
}

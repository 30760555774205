import { createListViewContainer } from 'factories/ListView'
import { wrapItems, filterBySearch } from 'helpers/utils'
import authActions from 'actions/auth'
import customerActions from 'actions/customers'
import { getCustomerDownloadTimestamp } from 'helpers/offlineData'
import Component from 'components/Distributor/ManageCustomers'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await dispatch(customerActions.getOptionsOffline()).then((r) =>
        r.value.data.items.filter(filterBySearch(params.search))
      )

      const downloadTimestamps = await Promise.all(items.map((each) => getCustomerDownloadTimestamp(each.id)))

      return wrapItems(
        items
          .map((each, index) => ({ ...each, downloadTimestamp: downloadTimestamps[index] }))
          .filter((each) => each.downloadTimestamp)
      )
    },
    loginAsCustomer: (params) => dispatch(authActions.loginAsCustomer(params)),
  }),
})(Component)

import { createListViewContainer } from 'factories/ListView'
import { wrapItems, filterBySearch } from 'helpers/utils'
import { getCustomerLocationCounts } from 'helpers/manageCustomers'
import customerActions from 'actions/customers'
import Component from 'components/Distributor/ClearCustomers'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await dispatch(customerActions.getOptionsOffline()).then((r) =>
        r.value.data.items.filter(filterBySearch(params.search))
      )

      const locationCounts = await Promise.all(items.map((each) => getCustomerLocationCounts(each.id)))

      return wrapItems(
        items.map((each, index) => ({ ...each, locationCounts: locationCounts[index].length }))
      )
    },
  }),
})(Component)

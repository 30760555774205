import { snakeCase } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'

const entityName = entityNames.locationCounts
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

export default {
  ...createActions(entityName),

  uploadAndCommit: (params = {}) => ({
    type: `${ENTITY_NAME}_UPLOAD_AND_COMMIT`,
    payload: createPost(entityName, { action: 'uploadAndCommit' })(params),
  }),
}

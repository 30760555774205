import * as React from 'react'
import { useImmer } from 'use-immer'
import { IonSpinner, IonItem, IonLabel, IonText, IonLoading, IonAlert } from '@ionic/react'
import { set, orderBy, isNil, isEmpty, get } from 'lodash'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { Emitter } from 'helpers/events'
import {
  LISTS_DELETE_ITEM_EVENT,
  LISTS_SELECT_ITEM_EVENT,
  LISTS_CHANGE_ITEM_EVENT,
  LISTS_FETCH_ITEMS_EVENT,
} from 'options/events'
import { setSessionItem } from 'helpers/sessionStorage'
import Items from 'containers/Lists/ChildListView/Items'
import Segmenter from 'elements/Segmenter'

export const getStorageKey = () => 'lists.childListView'

export default function (props) {
  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  async function fetchItems() {
    let items = []

    try {
      const { entityType, entityId } = props

      const response = await props.getItems({ entityType, entityId })

      items = orderBy(response.value.data.items, ['listType', 'listName'], ['desc', 'asc'])

      setState('items', items)

      props.onFetch(items)

      if (isEmpty(items)) {
        updateState((draft) => {
          draft.listType = 'Product'
          draft.listId = null
          draft.listName = null
          draft.entityListId = null
        })
      } else {
        updateState((draft) => {
          const selected = items[0]

          draft.listType = selected.listType
          draft.listId = selected.listId
          draft.listName = selected.listName
          draft.entityListId = selected.entityListId
        })
      }
    } catch (error) {
      showError({ error })
    }

    return items
  }

  async function fetchChildItems() {
    try {
      setState('childItems', null)

      const { domainObjectType, locationId, entityType } = props
      const { listId, listType } = state
      const response = await props.getChildItems(listId, {
        listId,
        domainObjectType,
        locationId,
        listType,
        listItemMode: `${listType}List${entityType}Record`,
      })

      setState('childItems', response.value.data.items)
    } catch (error) {
      showError({ error })
    }
  }

  function handleDeleteItem() {
    if (state.listId) {
      const buttons = [
        { text: t('cancel'), role: 'cancel' },
        {
          text: t('remove'),
          handler: async () => {
            try {
              const response = await props.deleteItems([state.entityListId])

              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              const updatedItems = await fetchItems()
              const nextItem = updatedItems.find((one) => one.listType === state.listType) || updatedItems[0]

              updateState((draft) => {
                draft.listType = get(nextItem, 'listType', 'Product')
                draft.listId = get(nextItem, 'listId', null)
                draft.listName = get(nextItem, 'listName', null)
                draft.entityListId = get(nextItem, 'entityListId', null)
              })
            } catch (error) {
              showError({ error })
            }
          },
        },
      ]

      updateState((draft) => {
        draft.alertIsOpen = true
        draft.alertMessage = t('confirmRemoveListAssociation')
        draft.alertButtons = buttons
      })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [props.entityType, props.entityId])

  React.useEffect(() => {
    if (state.listId && state.listType) {
      fetchChildItems()
    } else {
      setState('childItems', [])
    }
  }, [state.listId, state.listType])

  React.useEffect(() => {
    Emitter.on(LISTS_DELETE_ITEM_EVENT, handleDeleteItem)

    return () => Emitter.off(LISTS_DELETE_ITEM_EVENT, handleDeleteItem)
  }, [state.entityListId])

  React.useEffect(() => {
    function handleChangeItem(item) {
      updateState((draft) => {
        draft.listType = item.listType
        draft.listId = item.listId
        draft.listName = item.listName
        draft.entityListId = item.entityListId
      })
    }

    Emitter.on(LISTS_CHANGE_ITEM_EVENT, handleChangeItem)

    return () => Emitter.off(LISTS_CHANGE_ITEM_EVENT, handleChangeItem)
  })

  React.useEffect(() => {
    Emitter.on(LISTS_FETCH_ITEMS_EVENT, fetchItems)

    return () => Emitter.off(LISTS_FETCH_ITEMS_EVENT, fetchItems)
  })

  if (isNil(state.items)) {
    return <IonSpinner className="ion-margin" />
  }

  return (
    <>
      <Segmenter
        className="ion-margin-top"
        value={state.listType}
        items={[
          {
            value: 'Product',
            text: t('product'),
            disabled: !state.items.find((one) => one.listType === 'Product'),
          },
          {
            value: 'Asset',
            text: t('asset'),
            disabled: !state.items.find((one) => one.listType === 'Asset'),
          },
        ]}
        onChange={(value) => {
          updateState((draft) => {
            const selected = state.items.find((one) => one.listType === value) || { listType: value }

            draft.listType = selected.listType
            draft.listId = selected.listId
            draft.listName = selected.listName
          })
        }}
      />
      <IonItem
        lines="full"
        className="tofino-stacked-item"
        onClick={() => {
          setSessionItem(getStorageKey(), state)
          Emitter.emit(LISTS_SELECT_ITEM_EVENT)
        }}
        disabled={!state.items.find((one) => one.listType === state.listType)}
        button
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('list')}</small>
          </IonText>
          <br />
          {state.listId ? state.listName : PLACEHOLDER}
        </IonLabel>
      </IonItem>
      {isNil(state.childItems) ? (
        <IonSpinner className="ion-margin" />
      ) : (
        <Items items={state.childItems} listType={state.listType} />
      )}
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </>
  )
}

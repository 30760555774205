import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'overdueNotificationGroup',
  getItemSize: () => 45,
  allowSearch: () => true,
  getTextField: () => 'description',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.overdueNotificationGroupId ?? 0,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.overdueNotificationGroupId = each.id
      draft.item.overdueNotificationGroupName = each.description
    }),
})()

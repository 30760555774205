import * as React from 'react'
import styled from 'styled-components'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonModal } from '@ionic/react'
import { round, isEmpty } from 'lodash'
import { showError } from 'helpers/errors'
import { t } from 'helpers/i18n'
import Upload from 'antd/es/upload'
import Icon from 'elements/Icon'

const Container = styled.div`
  .ant-upload.ant-upload-drag {
    background-color: var(--ion-color-transparent);

    p.ant-upload-drag-icon {
      margin-bottom: 8px;
    }

    .ant-upload-drag-icon {
      color: var(--ion-color-secondary);
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: var(--ion-color-primary);
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: var(--ion-color-medium);
  }

  .ant-upload-list {
    color: var(--ion-color-primary);
  }

  .ant-upload-list-item-info .anticon-paper-clip {
    top: 2px;
    color: var(--ion-color-primary);
  }

  .ant-upload-list-item-card-actions {
    display: none;
  }
`

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

export default function (props) {
  const {
    onUpload,
    onCancel,
    accept,
    title = t('addDocument'),
    label = t('uploadFile'),
    hint = t('uploadHintMobile'),
  } = props

  const [fileList, setFileList] = React.useState([])

  async function handleUpload() {
    try {
      const fileListContents = await Promise.all(fileList.map(getBase64))

      onUpload(
        fileList.map((file, index) => ({
          id: file.name,
          documentId: file.name,
          fileName: file.name,
          fileSize: `${round(file.size / 1024, 2)} KB`,
          fileDate: new Date().toJSON(),
          fileType: file.type,
          fileContents: fileListContents[index],
        }))
      )
    } catch (error) {
      showError({ error })
    }
  }

  const draggerProps = {
    name: 'fileList',
    onRemove: (file) => setFileList(fileList.filter((each) => each !== file)),
    beforeUpload: (file) => {
      setFileList([file])
      return false // NOTE: before upload check, return false will stop upload, only for modern browsers
    },
    fileList,
    accept,
  }

  React.useEffect(() => {
    setFileList([])
  }, [props.isOpen])

  return (
    <IonModal isOpen={props.isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={onCancel}>
              {t('cancel')}
            </IonButton>
          </IonButtons>
          {title ? <IonTitle>{title}</IonTitle> : null}
          <IonButtons key={fileList.map((each) => each.name).join('|')} slot="end">
            <IonButton color="secondary" onClick={handleUpload} disabled={isEmpty(fileList)}>
              {t('upload')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <Container className="ion-margin">
          <Upload.Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="Inbox" size={48} />
            </p>
            <p className="ant-upload-text">{label}</p>
            <p className="ant-upload-hint">{hint}</p>
          </Upload.Dragger>
        </Container>
      </IonContent>
    </IonModal>
  )
}

import * as React from 'react'
import styled from 'styled-components'
import { pick } from 'lodash'
import { sendClientLog } from 'helpers/auth'

const Container = styled.div`
  margin: 12px 24px;
`

const Stack = styled.pre`
  font-size: smaller;
`

const Message = styled.p`
  font-size: larger;
`

class Component extends React.Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch() {
    sendClientLog('Error', 'Error Boundary', {
      error: pick(this.state.error, ['message', 'stack']),
    })
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <h1>Sorry, something went wrong.</h1>
          <Message>
            Please <a onClick={() => window.location.reload(true)}>refresh</a> the page or try again later.
            You may also <a href="mailto:info@tofinosoftware.com">report</a> this error to us.
          </Message>
          <Stack>{this.state.error.stack}</Stack>
        </Container>
      )
    }

    return this.props.children
  }
}

export default Component

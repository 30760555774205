import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { FREQUENCIES } from 'options/tasks'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/Tasks/FormView/SelectFrequency'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(
        Object.entries(FREQUENCIES).map(([key, value]) => ({
          id: key,
          displayName: t(value),
        }))
      ),
  }),
})(Component)

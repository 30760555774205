import { set } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { formatDateTime } from 'helpers/dateTime'
import { isReadOnly } from 'helpers/formViews'
import Segmenter from 'elements/Segmenter'

export default createChildListViewComponent({
  getStorageKey: () => 'assets.formView.tasks',
  allowSearch: () => false,
  allowDelete: (self) => (item) => !isReadOnly(self),
  getListHeader: ({ state, updateState }) => (
    <Segmenter
      value={state.filterDto?.active ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Active', text: t('active') },
        { value: 'Inactive', text: t('inactive') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.active', value)
        })
      }}
    />
  ),
  filterItems: (self) => (each) => {
    const active = self.state.filterDto?.active ?? 'All'

    if (active === 'Active') {
      return each.active
    }

    if (active === 'Inactive') {
      return !each.active
    }

    return true
  },
  renderItem: (self) => (item) => (
    <IonItem lines="full" onClick={() => self.props.onClick(item)} button>
      <IonLabel>
        {item.name}
        <br />
        <IonText color="medium">
          <small>
            {t('lastRun:')} {formatDateTime(item.lastRun, { showTime: true }) || PLACEHOLDER}
            <br />
            {t('nextRun:')} {formatDateTime(item.nextRun, { showTime: true }) || PLACEHOLDER}
            <br />
            {t('active:')} {item.activeDescription}
          </small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getSortByFields: (self) => [
    { fieldName: 'name', languageKey: 'task' },
    { fieldName: 'lastRun', languageKey: 'lastRun' },
    { fieldName: 'nextRun', languageKey: 'nextRun' },
    { fieldName: 'active', languageKey: 'active' },
  ],
  getDefaultSortByField: (self) => 'nextRun',
})()

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import jobActions from 'actions/jobs'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import orderItemActions from 'actions/orders/orderItems'
import inventoryActions from 'actions/inventory'
import { wrapItems, wrapData, filterBySearch } from 'helpers/utils'
import {
  getSharedJobOptions,
  getSharedAssetOptions,
  getSharedOperatorOptions,
  getSharedInventory,
} from 'helpers/offlineData'
import { updatePrivateItem } from 'helpers/localForage'
import { ISSUED_ITEMS_SAVED_KEY } from 'options/inventory/issue'
import Component from 'components/Issue/IssueItem'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getJobs: (params) =>
    dispatch(jobActions.getOptions({ pageIndex: 0, ...params })).catch(async (error) => {
      if (!error.response) {
        const items = await getSharedJobOptions().then((r) => r.filter(filterBySearch(params.search)))

        return wrapItems(items)
      }

      throw error
    }),
  getAssets: (params) =>
    dispatch(assetActions.getOptions({ pageIndex: 0, ...params })).catch(async (error) => {
      if (!error.response) {
        const items = await getSharedAssetOptions().then((r) => r.filter(filterBySearch(params.search)))

        return wrapItems(items)
      }

      throw error
    }),
  getOperators: (params) =>
    dispatch(operatorActions.getOptions({ pageIndex: 0, ...params })).catch(async (error) => {
      if (!error.response) {
        const items = await getSharedOperatorOptions().then((r) => r.filter(filterBySearch(params.search)))

        return wrapItems(items)
      }

      throw error
    }),
  getInventory: (params) =>
    dispatch(inventoryActions.getItems({ pageIndex: 0, ...params })).catch(async (error) => {
      if (!error.response) {
        const items = await getSharedInventory().then((r) =>
          r
            .filter((each) => params.locationIds.includes(each.locationId))
            .filter(filterBySearch(params.search))
        )

        return wrapItems(items)
      }

      throw error
    }),
  getTagLists: () => dispatch(tagListActions.getItemsOffline()),
  issueOrderItem: (params) =>
    dispatch(orderItemActions.issueOrderItem(0, params)).catch(async (error) => {
      if (!error.response) {
        await updatePrivateItem(ISSUED_ITEMS_SAVED_KEY, [], (draft) => {
          draft.unshift({ id: Date.now() * -1, timestamp: new Date().toJSON(), ...params })
        })

        return wrapData({
          clientNotifications: [
            {
              type: 'Warning',
              description: 'issueQuantityHasBeenSavedOnDevice',
            },
          ],
          successCount: 1,
          failureCount: 0,
        })
      }

      throw error
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

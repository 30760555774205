import { capitalize } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import offlineDataTypes from 'options/offlineDataTypes'
import Component from 'components/Download/Distributor'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      Promise.resolve(
        Object.entries(offlineDataTypes).map(([key, value], index) => ({
          id: key,
          displayName: capitalize(t(value)),
          disabled: key !== 'inventory',
        }))
      ).then(wrapItems),
  }),
})(Component)

import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import supplierActions from 'actions/suppliers'
import { getSharedSupplierOptions } from 'helpers/offlineData'
import { wrapItems } from 'helpers/utils'
import Component from 'components/Inventory/FormView/SelectSupplier'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(supplierActions.getOptions(params))
        .catch(async (error) => {
          if (!error.response) {
            const items = await getSharedSupplierOptions()

            return wrapItems(items)
          }

          throw error
        })
        .then((r) =>
          produce(r, (draft) => {
            if (params.pageIndex <= 1 && !params.search) {
              draft.value.data.items.unshift({ id: 0, displayName: '' })
              draft.value.data.recordCount++
            }
          })
        ),
  }),
})(Component)

import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { createCheckOfflineInventory } from 'helpers/offlineData'
import { removeSessionItem } from 'helpers/sessionStorage'
import { formatDateTime } from 'helpers/dateTime'
import Filter from 'containers/Inventory/ListView/Filter'
import Icon from 'elements/Icon'

export const inventoryItemSize = (self) => (self.props.scannerSettings.showInventoryDescription ? 111 : 93)

export const Inventory = ({ self, item, showLastCycleCount }) => (
  <IonLabel>
    {self.props.useInventoryBarcode ? item.inventoryBarcode || item.barcode : item.barcode}{' '}
    {item.lockedForCycleCount && <Icon type="Lock" color="danger" size="15" />}
    <br />
    <IonText color="medium">
      <small>
        {item.itemDescription || PLACEHOLDER}
        <br />
        {self.props.scannerSettings.showInventoryDescription ? (
          <>
            {item.description || PLACEHOLDER}
            <br />
          </>
        ) : null}
        {item.locationName}, {t('bin:')} {item.binLocation || PLACEHOLDER}
        <br />
        {`${t('onhand:')} ${(self.props.scannerSettings.showCount2
          ? [item.onHand, item.onHand2]
          : [item.onHand]
        ).join('/')}`}
        , {t('onOrder:')} {item.onOrder}
        {showLastCycleCount ? (
          <>
            <br />
            {t('lastCycleCount:')} {formatDateTime(item.lastCycleCount) || PLACEHOLDER}
          </>
        ) : null}
      </small>
    </IonText>
  </IonLabel>
)

export default createListViewComponent({
  getStorageKey: () => 'inventory.listView',
  getViewDidEnterHandler: createCheckOfflineInventory,
  renderItem: (self) => (item) => (
    <IonItem key={item.id} lines="full" routerLink={`${self.props.match.url}/${item.id}`}>
      <Inventory self={self} item={item} />
    </IonItem>
  ),
  getItemSize: inventoryItemSize,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowDelete: (self) => (item) => self.isOnline && getUserPermission('Inventory') === 'Edit',
  getPopoverContent: (self) => (
    <IonItem
      lines="full"
      onClick={() => {
        self.updateState((draft) => {
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
        removeSessionItem('inventory.create')
        self.props.history.push(`${self.props.match.url}/create`)
      }}
      disabled={!self.isOnline}
    >
      <IonLabel>{t('addToInventory')}</IonLabel>
    </IonItem>
  ),
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      onClick={() => {
        removeSessionItem('inventory.create')
        self.props.history.push(`${self.props.match.url}/create`)
      }}
      disabled={!self.isOnline}
    >
      {t('addToInventory')}
    </IonButton>
  ),
  getSortByFields: (self) => [
    {
      fieldName: self.props.useInventoryBarcode ? 'inventoryBarcode' : 'barcode',
      languageKey: 'barcode',
    },
    { fieldName: 'itemDescription', languageKey: 'description' },
    { fieldName: 'locationName', languageKey: 'location' },
  ],
})(Filter)

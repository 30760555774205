import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonSpinner,
  IonTextarea,
  IonButton,
  IonToggle,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, trim, isEmpty } from 'lodash'
import { strEqual, tryParseFloat, PLACEHOLDER } from 'helpers/utils'
import { allowPricing } from 'helpers/auth'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { formatDateTime } from 'helpers/dateTime'
import Page from 'elements/Page'
import DatePicker from 'elements/DatePicker'
import useOnlineStatus from '@rehooks/online-status'

// export const getStorageKey = () => 'orders.formView.orderSuppliers.formView';

export default function (props) {
  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const item = await props
        .getOrderSupplierItems(props.match.params.parentId)
        .then((r) => r.value.data.items.find((one) => strEqual(one.id, itemId)))

      setState('item', item)
    } catch (error) {
      showError({ error })
    }
  }

  async function saveItem() {
    try {
      const response = await props.updateOrderSupplierItems(props.match.params.parentId, [state.item])

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      props.history.goBack()
    } catch (error) {
      showError({ error })
    }
  }

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const pageTitle = t('supplierInformation')
  const backButtonHref = `/inventory/issue/draftOrder`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
          {t('save')}
        </IonButton>
      }
      backButtonHref={backButtonHref}
    >
      <IonItem lines="full">
        <IonLabel position="stacked">{t('supplier')}</IonLabel>
        <IonInput value={state.item.supplierName} placeholder={PLACEHOLDER} disabled />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('poNumber')}</IonLabel>
        <IonInput
          value={state.item.purchaseOrderNumber}
          onIonInput={(e) => setItemValue('purchaseOrderNumber', e.target.value)}
          placeholder={PLACEHOLDER}
        />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('specialInstructions')}</IonLabel>
        <IonTextarea
          value={state.item.shippingInstructions}
          onIonInput={(e) => setItemValue('shippingInstructions', e.target.value)}
          placeholder={PLACEHOLDER}
          rows={3}
          disabled={props.tenantGroupIsCardinal}
          autoGrow
        />
      </IonItem>
      <DatePicker
        label={t('dueDate')}
        value={state.item.dueDate}
        onChange={(value) => setItemValue('dueDate', value)}
        disabled={props.tenantGroupIsCardinal}
      />
      {allowPricing() && (
        <>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('adjustments')} ($)</IonLabel>
            <IonInput
              value={state.item.adjustments}
              onIonInput={(e) => setItemValue('adjustments', tryParseFloat(e.target.value, 0.0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              disabled={props.tenantGroupIsCardinal}
              clearOnEdit
            />
          </IonItem>

          <IonItem lines="full">
            <IonLabel position="stacked">{t('freight')} ($)</IonLabel>
            <IonInput
              value={state.item.freight}
              onIonInput={(e) => setItemValue('freight', tryParseFloat(e.target.value, 0.0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              placeholder={PLACEHOLDER}
              disabled={props.tenantGroupIsCardinal}
              clearOnEdit
            />
          </IonItem>
        </>
      )}
      <IonItem lines="full">
        <IonLabel>{t('holdOrderForThisSupplier')}</IonLabel>
        <IonToggle checked={state.item.hold} onIonChange={(e) => setItemValue('hold', e.detail.checked)} />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('confirmedBySupplier')}</IonLabel>
        <IonInput
          value={
            !state.item.isAcknowledged
              ? t('no')
              : [t('yes'), formatDateTime(state.item.ackDate, { showTime: true })]
                  .map(trim)
                  .filter((each) => !isEmpty(each))
                  .join(' - ')
          }
          placeholder={PLACEHOLDER}
          disabled
        />
      </IonItem>
    </Page>
  )
}

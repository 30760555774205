import { range, get } from 'lodash'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { wrapItems } from 'helpers/utils'
import { getSessionItem } from 'helpers/sessionStorage'
import { MONTHS } from 'options/tasks'
import Component from 'components/Assets/Tasks/FormView/SelectRecurOnDaysOfMonthSingle'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const index = get(getSessionItem('assets.formView.tasks.formView'), 'item.recurOnMonths[0]', 1) - 1
      const numberOfDays = Object.values(MONTHS)[index]

      return wrapItems(range(1, numberOfDays + 1).map((each) => ({ id: each, displayName: each })))
    },
  }),
})(Component)

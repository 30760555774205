import { get, isEmpty, invoke, toString as str, camelCase, without, floor, intersection } from 'lodash'
import { IonItem, IonLabel, IonToggle, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { t } from 'helpers/i18n'
import { createSelectRowHandler, setLoadingMessage, asyncSleep, tryParseQueryString } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { setSharedItem } from 'helpers/localForage'
import {
  getDownloadTimestampInfo,
  setSharedProducts,
  setSharedSupplierOptions,
  setSharedLocations,
  setSharedInventory,
  setSharedLocationGroups,
  setSharedOperatorOptions,
  setSharedAssetOptions,
  setSharedJobOptions,
  setSharedJobStatusOptions,
  setSharedJobReasonOptions,
  setSharedJobGroupOptions,
  setSharedJobReasonGroupOptions,
  setSharedUserOptions,
  setSharedAssetCategories,
  createHandleProgress,
  DOWNLOAD_BATCH_SIZE,
} from 'helpers/offlineData'
import { getStorageItem } from 'helpers/localStorage'
import { DOWNLOAD_PROGRESS } from 'options/events'
import { Emitter } from 'helpers/events'
import offlineDataTypes from 'options/offlineDataTypes'
import SelectDeselect from 'elements/SelectDeselect'

const CUSTOMER_OFFLINE_DATA_INVENTORY = 'customer.offlineData.inventory'

export default createListViewComponent({
  getStorageKey: () => 'customer.offlineData',
  getViewDidEnterHandler:
    ({ props, updateState, isOnline }) =>
    () => {
      try {
        if (isOnline) {
          if (props.location.search) {
            updateState((draft) => {
              draft.selectedRowKeys = str(tryParseQueryString(props.location.search).types)
                .split(',')
                .filter((each) => !isEmpty(each))
            })
          }
        } else {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('mustBeOnlineToDownloadData')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  getPopoverContent: ({ updateState }) => (
    <SelectDeselect updateState={updateState} filterItems={(each) => !each.disabled} />
  ),
  renderItem: (self) => (item) => {
    const { props, state, updateState } = self
    const { downloadTimestampColor, downloadTimestampLabel } = getDownloadTimestampInfo({
      props,
      item,
    })

    const checked = (state.selectedRowKeys ?? []).includes(item.id)

    const result = [
      <IonItem key={item.id} lines={item.id === 'inventory' ? 'none' : 'full'} disabled={item.disabled}>
        <IonLabel>
          {item.displayName}
          <br />
          <IonText color={downloadTimestampColor}>
            <small>{`${t('downloaded:')} ${downloadTimestampLabel}`}</small>
          </IonText>
        </IonLabel>
        <IonToggle
          checked={checked}
          onIonChange={createSelectRowHandler({ each: item, updateState })}
          disabled={!self.isOnline}
        />
      </IonItem>,
    ]

    if (item.id === 'inventory') {
      const { locationGroupNames, locationNames } = getStorageItem(CUSTOMER_OFFLINE_DATA_INVENTORY) ?? {}

      result.push(
        <IonItem
          key="inventoryLocationGroups"
          className="tofino-stacked-item"
          lines="none"
          onClick={() => {
            props.history.push(`${props.match.url}/selectLocationGroup`)
          }}
          disabled={!checked || !self.isOnline}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {locationGroupNames || t('All')}
          </IonLabel>
        </IonItem>
      )

      result.push(
        <IonItem
          key="inventoryLocations"
          className="tofino-stacked-item"
          lines="full"
          onClick={() => {
            props.history.push(`${props.match.url}/selectLocation`)
          }}
          disabled={!checked || !self.isOnline}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {locationNames || t('All')}
          </IonLabel>
        </IonItem>
      )
    }

    return result
  },
  allowInfiniteLoader: () => false,
  getFooter: (self) => {
    const { props, state, updateState, setState } = self
    return (
      <IonButton
        expand="full"
        color="secondary"
        disabled={isEmpty(state.selectedRowKeys) || !self.isOnline}
        onClick={async () => {
          try {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.loadingIsOpen = true
              draft.loadingMessage = null
              draft.items.forEach((each) => {
                each.downloadSuccessful = false
              })
            })

            const types = state.selectedRowKeys ?? []
            const { locationGroupIds, locationIds } = getStorageItem(CUSTOMER_OFFLINE_DATA_INVENTORY, {})

            // Location Groups
            if (intersection(types, ['inventory', 'jobs']).length) {
              const locationGroups = await props
                .getLocationGroups({ locationGroupIds, alwaysIncludeLocationGroupIds: false })
                .then((r) => get(r, 'value.data.items', []))
              await setSharedLocationGroups(locationGroups)
            }

            // Locations
            if (intersection(types, ['inventory', 'jobs']).length) {
              const handleProgress = createHandleProgress('locations')
              Emitter.on(DOWNLOAD_PROGRESS, handleProgress)
              const locations = await props
                .getLocations({ locationGroupIds, locationIds, alwaysIncludeLocationIds: false })
                .then((r) => get(r, 'value.data.items', []))
              Emitter.off(DOWNLOAD_PROGRESS, handleProgress)
              await setSharedLocations(locations)
            }

            // Products
            if (intersection(types, ['inventory']).length) {
              setLoadingMessage(`${t('downloading')} ${t('products')} (0%)`, true)

              let items = []
              let pageIndex = 1
              let recordCount = 0

              do {
                const response = await props.getProducts({
                  pageIndex,
                  pageSize: DOWNLOAD_BATCH_SIZE,
                })

                recordCount = get(response, 'value.data.recordCount', 0)
                items = [...items, ...get(response, 'value.data.items', [])]

                if (recordCount) {
                  setLoadingMessage(
                    `${t('downloading')} ${t('products')} (${floor((items.length * 100) / recordCount)}%)`,
                    true
                  )
                }

                pageIndex++
              } while (items.length < recordCount)

              setLoadingMessage(`${t('saving')} ${t('products')}...`, true)

              await setSharedProducts(items)
            }

            // Inventory
            if (intersection(types, ['inventory']).length) {
              setLoadingMessage(`${t('downloading')} ${t('inventory')} (0%)`, true)

              let items = []
              let pageIndex = 1
              let recordCount = 0

              do {
                const response = await props.getInventory({
                  pageIndex,
                  pageSize: DOWNLOAD_BATCH_SIZE,
                  locationGroupIds,
                  locationIds,
                })

                recordCount = get(response, 'value.data.recordCount', 0)
                items = [...items, ...get(response, 'value.data.items', [])]

                if (recordCount) {
                  setLoadingMessage(
                    `${t('downloading')} ${t('inventory')} (${floor((items.length * 100) / recordCount)}%)`,
                    true
                  )
                }

                pageIndex++
              } while (items.length < recordCount)

              setLoadingMessage(`${t('saving')} ${t('inventory')}...`, true)

              await setSharedInventory(items)

              updateState((draft) => {
                draft.items
                  .filter((each) => each.id === 'inventory')
                  .forEach((each) => {
                    each.downloadSuccessful = true
                    each.downloadTimestamp = new Date().toJSON()
                  })
              })
            }

            // Orders, Jobs, Assets
            for (const key of without(Object.keys(offlineDataTypes), 'inventory')) {
              if (intersection(types, [key]).length) {
                const handleProgress = createHandleProgress(key)
                Emitter.on(DOWNLOAD_PROGRESS, handleProgress)
                const response = await invoke(props, camelCase(`get-${key}`))
                Emitter.off(DOWNLOAD_PROGRESS, handleProgress)

                await setSharedItem(`offlineData.${key}`, get(response, 'value.data.items', []))

                updateState((draft) => {
                  draft.items
                    .filter((each) => each.id === key)
                    .forEach((each) => {
                      each.downloadSuccessful = true
                      each.downloadTimestamp = new Date().toJSON()
                    })
                })
              }
            }

            setLoadingMessage(t('finalizingDownload...'))

            // Supplier Options
            if (intersection(types, ['inventory']).length) {
              const response = await props.getSupplierOptions()
              await setSharedSupplierOptions(get(response, 'value.data.items', []))
            }

            // User Options
            if (intersection(types, ['jobs']).length) {
              const response = await props.getUserOptions()
              await setSharedUserOptions(get(response, 'value.data.items', []))
            }

            // Job Status Options
            if (intersection(types, ['jobs']).length) {
              const response = await props.getJobStatusOptions()
              await setSharedJobStatusOptions(get(response, 'value.data.items', []))
            }

            // Job Group Options
            if (intersection(types, ['jobs']).length) {
              const response = await props.getJobGroupOptions()
              await setSharedJobGroupOptions(get(response, 'value.data.items', []))
            }

            // Job Reason Options
            if (intersection(types, ['jobs']).length) {
              const response = await props.getJobReasonOptions()
              await setSharedJobReasonOptions(get(response, 'value.data.items', []))
            }

            // Job Reason Groups Options
            if (intersection(types, ['jobs']).length) {
              const response = await props.getJobReasonGroupOptions()
              await setSharedJobReasonGroupOptions(get(response, 'value.data.items', []))
            }

            // Operator Options
            if (intersection(types, ['inventory', 'jobs']).length) {
              const response = await props.getOperatorOptions()
              await setSharedOperatorOptions(get(response, 'value.data.items', []))
            }

            // Asset Categories
            if (intersection(types, ['inventory', 'jobs', 'assets']).length) {
              const response = await props.getAssetCategories()
              await setSharedAssetCategories(get(response, 'value.data.items', []))
            }

            // Asset Options
            if (intersection(types, ['inventory', 'jobs']).length) {
              const response = await props.getAssetOptions()
              await setSharedAssetOptions(get(response, 'value.data.items', []))
            }

            // Job Options
            if (intersection(types, ['inventory']).length) {
              const response = await props.getJobOptions()
              await setSharedJobOptions(get(response, 'value.data.items', []))
            }

            // TagLists
            if (intersection(types, ['inventory']).length) {
              const response = await props.getTagLists()
              await setSharedItem('offlineData.tagLists', get(response, 'value.data.items', []))
            }

            setState('loadingIsOpen', false)
            await asyncSleep()

            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('downloadedDataSuccessfully')
              draft.alertButtons = [t('ok')]
            })
          } catch (error) {
            setState('loadingIsOpen', false)
            showError({ error })
          }
        }}
      >
        {t('download')}
      </IonButton>
    )
  },
  allowOffline: (self) => false,
})()

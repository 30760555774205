import produce from 'immer'
import { t } from 'helpers/i18n'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import jobStatusActions from 'actions/jobStatusOptions'
import Component from 'components/Jobs/FormView/SelectStatus'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(jobStatusActions.getItems({ ...params })).then((r) =>
        produce(r, (draft) => {
          if (params.pageIndex <= 1 && !params.search) {
            draft.value.data.items.unshift({ id: 0, displayName: t('none') })
            draft.value.data.recordCount++
          }
        })
      ),
  }),
})(Component)

import produce from 'immer'
import { isNil, toString as str, isEmpty } from 'lodash'

const getScoped = (key) =>
  [process.env.REACT_APP_NAME, key]
    .map(str)
    .filter((each) => !isEmpty(each))
    .join('.')

export const getSessionItem = (key, defaultValue) => {
  try {
    const serializedValue = sessionStorage.getItem(getScoped(key))

    return isNil(serializedValue) ? defaultValue : JSON.parse(str(serializedValue))
  } catch (error) {
    return defaultValue
  }
}

export const setSessionItem = (key, value) => {
  sessionStorage.setItem(getScoped(key), JSON.stringify(value))
}

export const updateSessionItem = (key, defaultValue, recipe) => {
  const value = getSessionItem(key, defaultValue)
  setSessionItem(key, produce(value, recipe))
}

export const removeSessionItem = (key) => {
  try {
    sessionStorage.removeItem(getScoped(key))
  } catch (error) {
    console.warn(error)
  }
}

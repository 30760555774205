import { IonItem, IonLabel, IonText } from '@ionic/react'
import { set, isNil } from 'lodash'
import { LOCATION_COUNTS_CURRENT_KEY, LOCATION_COUNTS_COMPLETED_RESULTS_KEY } from 'options/locationCounts'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { getSharedItem, setPrivateItem, removePrivateItem } from 'helpers/localForage'
import { showError } from 'helpers/errors'
import { getHoursPassed } from 'helpers/dateTime'
import Segmenter from 'elements/Segmenter'
import Filter from 'containers/LocationCounts/SelectLocation/Filter'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.selectLocation',
  getInsignificantFilterDtoFields: (self) => ['dateRange', 'dateRangeField', 'countStatus'],
  getListHeader: ({ props, state, updateState, fetchItems, firstPageIndex }) => (
    <Segmenter
      value={state.filterDto?.countStatus ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Counted', text: t('counted') },
        { value: 'NotCounted', text: t('notCounted') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.countStatus', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
    />
  ),
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      onClick={async () => {
        await setPrivateItem(LOCATION_COUNTS_CURRENT_KEY, {
          createdBy: self.props.user.userName,
          createdDate: new Date().toJSON(),
          locationId: item.id,
          customerId: self.props.customer.id,
          locationCountDetails: [],
        })
        await removePrivateItem(LOCATION_COUNTS_COMPLETED_RESULTS_KEY)
        window.location.href = '/inventory/currentLocation'
      }}
      button
    >
      <IonLabel>
        {item.displayName}
        <br />
        <IonText color={item.isCounted ? 'success' : 'danger'}>
          <small>{t(item.isCounted ? 'counted' : 'notCounted')}</small>
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getItemSize: () => 57,
  getViewDidEnterHandler:
    ({ props, updateState, isOnline }) =>
    async () => {
      try {
        const { maxDataAgeHours = 0, preventUsingExpiredData = false } = props.scannerSettings

        const buttons = [
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => props.history.goBack(),
          },
          {
            text: t('ok'),
            handler: () => {
              window.location.href = '/more/downloadOfflineData?types=inventory'
            },
          },
        ]

        const responses = await Promise.all([
          getSharedItem('offlineData.inventory.timestamp'),
          getSharedItem('offlineData.locations.timestamp'),
        ])

        if (responses.some(isNil)) {
          if (isOnline) {
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('inventoryDownloadRequired')
              draft.alertButtons = buttons
            })
          } else {
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertMessage = t('noInventoryDataFound')
              draft.alertButtons = [
                {
                  text: t('ok'),
                  role: 'cancel',
                  handler: () => props.history.goBack(),
                },
              ]
            })
          }
        } else if (maxDataAgeHours > 0) {
          if (getHoursPassed(responses[0]) > maxDataAgeHours) {
            if (preventUsingExpiredData) {
              updateState((draft) => {
                draft.alertIsOpen = true
                draft.alertMessage = t('inventoryRefreshRequired')
                draft.alertButtons = buttons
              })
            } else {
              updateState((draft) => {
                draft.alertIsOpen = true
                draft.alertMessage = t('inventoryDownloadRecommended')
                draft.alertButtons = [t('ok')]
              })
            }
          }
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowSearch: (self) => true,
  getSortByFields: (self) => [
    { fieldName: 'name', languageKey: 'name' },
    { fieldName: 'barcode', languageKey: 'barcode' },
    { fieldName: 'description', languageKey: 'description' },
  ],
})(Filter)

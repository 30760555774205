import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import AssetsListView from 'containers/Assets/ListView'
import AssetsListViewMoveAssets from 'containers/Assets/ListView/MoveAssets'
import AssetsFormView from 'containers/Assets/FormView'
import AssetsFormViewCopyFromExisting from 'containers/Assets/FormView/CopyFromExisting'
import AssetsFormViewSelectLocation from 'containers/Assets/FormView/SelectLocation'
import AssetsFormViewSelectCategory from 'containers/Assets/FormView/SelectCategory'
import AssetsFormViewSelectServiceStatus from 'containers/Assets/FormView/SelectServiceStatus'
import AssetsFormViewSelectSupervisor from 'containers/Assets/FormView/SelectSupervisor'
import AssetsFormViewSelectNotificationGroup from 'containers/Assets/FormView/SelectNotificationGroup'
import AssetsHistoryFormView from 'containers/Assets/History/FormView'
import AssetTolerancesFormView from 'containers/Assets/Tolerances/FormView'
import AssetWarrantiesFormView from 'containers/Assets/Warranties/FormView'
import AssetTasksFormView from 'containers/Assets/Tasks/FormView'
import AssetTasksFormViewSelectJobTemplate from 'containers/Assets/Tasks/FormView/SelectJobTemplate'
import AssetTasksFormViewSelectAssignedOperator from 'containers/Assets/Tasks/FormView/SelectAssignedOperator'
import AssetTasksFormViewSelectFrequency from 'containers/Assets/Tasks/FormView/SelectFrequency'
import AssetTasksFormViewSelectRecurOnDaysOfWeek from 'containers/Assets/Tasks/FormView/SelectRecurOnDaysOfWeek'
import AssetTasksFormViewSelectRecurOnWeeksOfMonth from 'containers/Assets/Tasks/FormView/SelectRecurOnWeeksOfMonth'
import AssetTasksFormViewSelectRecurOnMonthsSingle from 'containers/Assets/Tasks/FormView/SelectRecurOnMonthsSingle'
import AssetTasksFormViewSelectRecurOnMonthsMultiple from 'containers/Assets/Tasks/FormView/SelectRecurOnMonthsMultiple'
import AssetTasksFormViewSelectRecurOnDaysOfMonthMultiple from 'containers/Assets/Tasks/FormView/SelectRecurOnDaysOfMonthMultiple'
import AssetTasksFormViewSelectRecurOnDaysOfMonthSingle from 'containers/Assets/Tasks/FormView/SelectRecurOnDaysOfMonthSingle'
import TrackAssets from 'containers/TrackAssets'
import TrackAssetsSelectOperator from 'containers/TrackAssets/SelectOperator'
import TrackAssetsSelectJob from 'containers/TrackAssets/SelectJob'
import TrackAssetsSelectTagA from 'containers/TrackAssets/SelectTagA'
import TrackAssetsSelectTagB from 'containers/TrackAssets/SelectTagB'
import TrackAssetsSelectTagC from 'containers/TrackAssets/SelectTagC'
import TrackAssetsSelectTagD from 'containers/TrackAssets/SelectTagD'
import TrackAssetsSelectTagE from 'containers/TrackAssets/SelectTagE'
import TrackAssetsFormView from 'containers/TrackAssets/FormView'
import TrackAssetsFormViewSelectServiceStatus from 'containers/TrackAssets/FormView/SelectServiceStatus'
import TrackAssetsFormViewSelectCondition from 'containers/TrackAssets/FormView/SelectCondition'
import TrackAssetsFormViewSelectLifeRemaining from 'containers/TrackAssets/FormView/SelectLifeRemaining'
import TrackAssetsFormViewSelectOperator from 'containers/TrackAssets/FormView/SelectOperator'
import TrackAssetsFormViewSelectJob from 'containers/TrackAssets/FormView/SelectJob'
import TrackAssetsFormViewSelectTagA from 'containers/TrackAssets/FormView/SelectTagA'
import TrackAssetsFormViewSelectTagB from 'containers/TrackAssets/FormView/SelectTagB'
import TrackAssetsFormViewSelectTagC from 'containers/TrackAssets/FormView/SelectTagC'
import TrackAssetsFormViewSelectTagD from 'containers/TrackAssets/FormView/SelectTagD'
import TrackAssetsFormViewSelectTagE from 'containers/TrackAssets/FormView/SelectTagE'
import ListsChildListViewAddList from 'containers/Lists/ChildListView/AddList'
import ListsChildListViewSelectList from 'containers/Lists/ChildListView/SelectList'

const TrackAssetsTolerancesFormView = (props) => (
  <AssetTolerancesFormView parentStorageKey="trackAssets.formView" settingsType="jobs" {...props} />
)

const TrackAssetsHistoryFormView = (props) => (
  <AssetsHistoryFormView parentStorageKey="trackAssets.formView" settingsType="jobs" {...props} />
)

const AssetsFormViewAddList = (props) => <ListsChildListViewAddList entityType="Asset" {...props} />

const AssetsFormViewSelectList = (props) => <ListsChildListViewSelectList entityType="Asset" {...props} />

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/assets/trackAssets': TrackAssets,
    '/assets/trackAssets/selectOperator': TrackAssetsSelectOperator,
    '/assets/trackAssets/selectJob': TrackAssetsSelectJob,
    '/assets/trackAssets/selectTagA': TrackAssetsSelectTagA,
    '/assets/trackAssets/selectTagB': TrackAssetsSelectTagB,
    '/assets/trackAssets/selectTagC': TrackAssetsSelectTagC,
    '/assets/trackAssets/selectTagD': TrackAssetsSelectTagD,
    '/assets/trackAssets/selectTagE': TrackAssetsSelectTagE,
    '/assets/trackAssets/:itemId(\\d+)': TrackAssetsFormView,
    '/assets/trackAssets/:itemId(\\d+)/selectServiceStatus': TrackAssetsFormViewSelectServiceStatus,
    '/assets/trackAssets/:itemId(\\d+)/selectCondition': TrackAssetsFormViewSelectCondition,
    '/assets/trackAssets/:itemId(\\d+)/selectLifeRemaining': TrackAssetsFormViewSelectLifeRemaining,
    '/assets/trackAssets/:itemId(\\d+)/selectOperator': TrackAssetsFormViewSelectOperator,
    '/assets/trackAssets/:itemId(\\d+)/selectJob': TrackAssetsFormViewSelectJob,
    '/assets/trackAssets/:itemId(\\d+)/selectTagA': TrackAssetsFormViewSelectTagA,
    '/assets/trackAssets/:itemId(\\d+)/selectTagB': TrackAssetsFormViewSelectTagB,
    '/assets/trackAssets/:itemId(\\d+)/selectTagC': TrackAssetsFormViewSelectTagC,
    '/assets/trackAssets/:itemId(\\d+)/selectTagD': TrackAssetsFormViewSelectTagD,
    '/assets/trackAssets/:itemId(\\d+)/selectTagE': TrackAssetsFormViewSelectTagE,
    '/assets/trackAssets/:parentId(\\d+)/history/:itemId(\\d+)': TrackAssetsHistoryFormView,
    '/assets/trackAssets/:parentId(\\d+)/tolerances/:itemId(\\d+)': TrackAssetsTolerancesFormView,
    '/assets/assets': AssetsListView,
    '/assets/assets/moveAssets': AssetsListViewMoveAssets,
    '/assets/assets/:itemId(\\d+)': AssetsFormView,
    '/assets/assets/:itemId(\\d+)/copyFromExisting': AssetsFormViewCopyFromExisting,
    '/assets/assets/:itemId(\\d+)/addList': AssetsFormViewAddList,
    '/assets/assets/:itemId(\\d+)/selectList': AssetsFormViewSelectList,
    '/assets/assets/:itemId(\\d+)/selectLocation': AssetsFormViewSelectLocation,
    '/assets/assets/:itemId(\\d+)/selectCategory': AssetsFormViewSelectCategory,
    '/assets/assets/:itemId(\\d+)/selectServiceStatus': AssetsFormViewSelectServiceStatus,
    '/assets/assets/:itemId(\\d+)/selectSupervisor': AssetsFormViewSelectSupervisor,
    '/assets/assets/:itemId(\\d+)/selectNotificationGroup': AssetsFormViewSelectNotificationGroup,
    '/assets/assets/:parentId(\\d+)/history/:itemId(\\d+)': AssetsHistoryFormView,
    '/assets/assets/:parentId(\\d+)/tolerances/:itemId(-\\d+|\\d+)': AssetTolerancesFormView,
    '/assets/assets/:parentId(\\d+)/warranties/:itemId(-\\d+|\\d+)': AssetWarrantiesFormView,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)': AssetTasksFormView,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectJobTemplate':
      AssetTasksFormViewSelectJobTemplate,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectAssignedOperator':
      AssetTasksFormViewSelectAssignedOperator,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectFrequency':
      AssetTasksFormViewSelectFrequency,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnDaysOfWeek':
      AssetTasksFormViewSelectRecurOnDaysOfWeek,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnWeeksOfMonth':
      AssetTasksFormViewSelectRecurOnWeeksOfMonth,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnMonthsSingle':
      AssetTasksFormViewSelectRecurOnMonthsSingle,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnMonthsMultiple':
      AssetTasksFormViewSelectRecurOnMonthsMultiple,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnDaysOfMonthMultiple':
      AssetTasksFormViewSelectRecurOnDaysOfMonthMultiple,
    '/assets/assets/:parentId(\\d+)/tasks/:itemId(-\\d+|\\d+)/selectRecurOnDaysOfMonthSingle':
      AssetTasksFormViewSelectRecurOnDaysOfMonthSingle,
  }),
})

export default createTabViewContainer()(Component)

import { createListViewContainer } from 'factories/ListView'
import jobActions from 'actions/jobs'
import Component from 'components/Jobs/ListView'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(jobActions.getListViewItems({ isTemplate: false, ...params })),
    deleteItem: (id) => dispatch(jobActions.deleteItems([id])),
  }),
})(Component)

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { isEmpty, set, defaultTo } from 'lodash'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import { getPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import { PLACEHOLDER } from 'helpers/utils'
import { showError } from 'helpers/errors'
import { isItemCounted, isItemOrdered } from 'helpers/locationCounts'
import Segmenter from 'elements/Segmenter'
import Icon from 'elements/Icon'
import Header from './Header'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.reviewItems',
  pageTitleLanguageKey: 'reviewItems',
  getToolbarIcon: () => <Icon type="Info" size="26" outlined />,
  getPopoverContent: () => <Header className="ion-margin tofino-stacked-list" />,
  getListHeader: ({ state, updateState, firstPageIndex }) => (
    <Segmenter
      value={state.filterDto?.itemStatus ?? 'All'}
      items={[
        { value: 'All', text: t('all') },
        { value: 'Counted', text: t('counted') },
        { value: 'Ordered', text: t('ordered') },
      ]}
      onChange={(value) => {
        updateState((draft) => {
          set(draft, 'filterDto.itemStatus', value)
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
      }}
    />
  ),
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => {
      const itemStatus = state.filterDto?.itemStatus ?? 'All'
      const barcode = props.useInventoryBarcode ? item.inventoryBarcode : item.barcode
      const countedValue = props.scannerSettings.showCount2
        ? [item.count, item.count2].map((one) => defaultTo(one, PLACEHOLDER)).join('/')
        : defaultTo(item.count, PLACEHOLDER)

      return (
        <IonItem
          key={item.id}
          lines="full"
          routerLink={
            !state.currentCount?.orderId ? `${props.match.url}/${encodeURIComponent(barcode)}` : undefined
          }
        >
          <IonLabel>
            {barcode}
            <br />
            <small>{item.description || PLACEHOLDER}</small>
            <br />
            <IonText color="success">
              <small>
                {[
                  itemStatus !== 'Ordered' && isItemCounted(item) ? `${t('counted:')} ${countedValue}` : null,
                  itemStatus !== 'Counted' && isItemOrdered(item)
                    ? `${t('ordered:')} ${item.quantityToOrder}`
                    : null,
                ]
                  .filter((each) => !isEmpty(each))
                  .join(', ')}
              </small>
            </IonText>
          </IonLabel>
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
  getViewDidEnterHandler:
    ({ props, updateState }) =>
    async () => {
      try {
        const currentCount = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY, {})

        if (isEmpty(currentCount?.locationCountDetails)) {
          updateState((draft) => {
            draft.currentCount = null
            draft.alertIsOpen = true
            draft.alertMessage = t('nothingToReview')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        } else {
          updateState((draft) => {
            draft.currentCount = currentCount
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  allowDelete: (self) => (item) => !self.state?.currentCount?.orderId,
  allowSearch: (self) => true,
})()

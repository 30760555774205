import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import jobActions from 'actions/jobs'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import productActions from 'actions/products'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import Component from 'components/Requisitions/AddItems'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getJobs: (params) => dispatch(jobActions.getOptions({ pageIndex: 0, ...params })),
  getAssets: (params) => dispatch(assetActions.getOptions({ pageIndex: 0, ...params })),
  getOperators: (params) => dispatch(operatorActions.getOptions({ pageIndex: 0, ...params })),
  getProducts: (params) => dispatch(productActions.getItems({ pageIndex: 0, active: 'Active', ...params })),
  getTagLists: () => dispatch(tagListActions.getItemsOffline()),
  populateRequisitionItems: (params) => dispatch(requisitionItemActions.populateRequisitionItems(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createChildListViewComponent } from 'factories/ChildListView'
import { t, toLocaleCurrency } from 'helpers/i18n'

export default createChildListViewComponent({
  getStorageKey: () => 'lists.childListView.items',
  renderItem: (self) => (item) => (
    <IonItem lines="full">
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          {self.props.listType === 'Asset' ? (
            <small>
              {item.description}
              <br />
              {t('item#:')} {item.itemNumber}, {t('price:')} {toLocaleCurrency(item.price)}
              <br />
              {t('status:')} {item.statusDescription}
            </small>
          ) : (
            <small>
              {item.description}
              <br />
              {t('item#:')} {item.itemNumber}, {t('pkg:')} {item.packageSizeUom}, {t('price:')}{' '}
              {toLocaleCurrency(item.price)}
              <br />
              {t('onhand:')}{' '}
              {(self.props.scannerSettings._showCount2
                ? [item.quantityOnHand, item.quantityOnHand2]
                : [item.quantityOnHand]
              ).join('/')}
            </small>
          )}
        </IonText>
      </IonLabel>
    </IonItem>
  ),
  getSortByFields: (self) =>
    self.props.listType === 'Asset'
      ? [
          { fieldName: 'barcode', languageKey: 'assetId' },
          { fieldName: 'itemNumber', languageKey: 'itemNumber' },
          { fieldName: 'description', languageKey: 'description' },
          { fieldName: 'statusDescription', languageKey: 'status' },
          { fieldName: 'price', languageKey: 'price' },
          { fieldName: 'comment', languageKey: 'comment' },
        ]
      : self.props.listType === 'Product'
        ? [
            { fieldName: 'barcode', languageKey: 'barcode' },
            { fieldName: 'itemNumber', languageKey: 'itemNumber' },
            { fieldName: 'description', languageKey: 'description' },
            { fieldName: 'price', languageKey: 'price' },
            { fieldName: 'packageSizeUom', languageKey: 'pkg' },
            { fieldName: 'quantity', languageKey: 'quantity' },
            { fieldName: 'comment', languageKey: 'comment' },
            { fieldName: 'onHand', languageKey: 'onhand' },
          ]
        : [],
  getDefaultSortByField: (self) => 'barcode',
})()

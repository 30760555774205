import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import jobActions from 'actions/jobs'
import { getSharedJobOptions } from 'helpers/offlineData'
import { wrapItems, filterBySearch } from 'helpers/utils'
import Component from 'components/TrackAssets/SelectJob'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(jobActions.getOptions({ ...params }))
        .catch(async (error) => {
          if (!error.response) {
            const items = await getSharedJobOptions().then((r) => r.filter(filterBySearch(params.search)))

            return wrapItems(items)
          }

          throw error
        })
        .then((r) =>
          produce(r, (draft) => {
            if (params.pageIndex <= 1 && !params.search) {
              draft.value.data.items.unshift({ id: 0, displayName: '' })
              draft.value.data.recordCount++
            }
          })
        ),
  }),
})(Component)

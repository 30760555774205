import { createChildFormViewContainer } from 'factories/ChildFormView'
import jobActions from 'actions/jobs'
import assetActions from 'actions/assets'
import operatorActions from 'actions/operators'
import tagListActions from 'actions/tagLists'
import actions from 'actions/requisitions/requisitionItems'
import Component from 'components/Requisitions/Items/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getJobs: (params) => dispatch(jobActions.getOptions({ pageIndex: 0, ...params })),
    getJob: (jobId) => dispatch(jobActions.getItem(jobId)),
    getAssets: (params) => dispatch(assetActions.getOptions({ pageIndex: 0, ...params })),
    getAsset: (assetId) => dispatch(assetActions.getItem(assetId)),
    getOperators: (params) => dispatch(operatorActions.getOptions({ pageIndex: 0, ...params })),
    getOperator: (operatorId) => dispatch(operatorActions.getItem(operatorId)),
    getTagLists: () => dispatch(tagListActions.getItemsOffline()),
    populateRequisitionItems: (params) => dispatch(actions.populateRequisitionItems(params)),
  }),
})(Component)

import { range } from 'lodash'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Assets/Tasks/FormView/SelectRecurOnDaysOfMonthMultiple'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems([
        ...range(1, 32).map((each) => ({ id: each, displayName: each })),
        { id: 32, displayName: t('last') },
      ]),
  }),
})(Component)

import { createActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'

const defaultActions = createActions(entityNames.unitsOfMeasure, { memoizeGetOptions: true })

export default {
  ...defaultActions,

  getOptionsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.unitsOfMeasure.options'

    try {
      const response = await dispatch(
        defaultActions.getOptions({ pageIndex: 0, mobileDownload: true, active: 'Active' })
      )

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { tryParseInt, filterLocationsByGroupIds, FILTER_SCROLL_TOP } from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray, getMultiSelectDisplayNameFromObject } from 'helpers/formViews'
import cycleCountTypeOptions from 'options/cycleCounts/cycleCountTypes'
import { getStartDate, getEndDate } from 'helpers/dateTime'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import dateRangeTypes from 'options/dateRangeTypes'
import ModalSelect from 'elements/ModalSelect'
import DateRangePicker from 'elements/DateRangePicker'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const contentRef = React.useCallback((node) => {
    if (node !== null) {
      const scrollTop = tryParseInt(getSessionItem(FILTER_SCROLL_TOP))

      if (scrollTop) {
        node.scrollToPoint(0, scrollTop, 0)
        removeSessionItem(FILTER_SCROLL_TOP)
      }
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setFilter = (path, value) => {
    onChange(
      produce(filterDto, (draft) => {
        set(draft, path, value)
      })
    )
  }

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([
        props.getLocationGroups(),
        props.getLocations(),
        props.getUsers({ userGroupIds: filterDto?.userGroupIds ?? [] }),
        props.getUserGroups(),
      ])

      updateState((draft) => {
        draft.locationGroups = get(responses[0], 'value.data.items', [])
        draft.locations = get(responses[1], 'value.data.items', [])
        draft.users = get(responses[2], 'value.data.items', [])
        draft.userGroups = get(responses[3], 'value.data.items', [])
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const locationGroupIds = filterDto?.locationGroupIds ?? []
  const locationIds = filterDto?.locationIds ?? []
  const cycleCountType = filterDto?.type ?? 'All'
  const dateRangeType = filterDto?.dateRange?.dateRangeType ?? 'YearToDate'
  const lastNValue = filterDto?.dateRange?.lastNValue ?? 1
  const customStartDate = filterDto?.dateRange?.customStartDate
  const customEndDate = filterDto?.dateRange?.customEndDate
  const userGroupIds = filterDto?.userGroupIds ?? []
  const userNames = filterDto?.userNames ?? []

  React.useEffect(() => {
    props
      .getUsers({ userGroupIds })
      .then((r) => setState('users', r.value.data.items))
      .catch((error) => showError({ error }))
  }, [userGroupIds])

  if (state.activeView === 'dateRangeType') {
    return (
      <ModalSelect
        title={t('dateRangeType')}
        options={Object.entries(dateRangeTypes).map(([key, value]) => ({
          value: key,
          text: t(value),
        }))}
        value={dateRangeType}
        onChange={(value) => {
          if (value === 'Custom') {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: getStartDate(dateRangeType, lastNValue).toISOString(),
                customEndDate: getEndDate(dateRangeType, lastNValue).toISOString(),
              },
            })
          } else {
            assignFilter({
              dateRange: {
                dateRangeType: value,
                customStartDate: null,
                customEndDate: null,
              },
            })
          }
        }}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'locationGroupIds') {
    return (
      <ModalSelect
        title={t('locationGroup')}
        options={(state.locationGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationGroupIds}
        onChange={(values) => {
          assignFilter({
            locationGroupIds: values,
            locationIds: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'locationIds') {
    return (
      <ModalSelect
        title={t('location')}
        options={(state.locations ?? []).filter(filterLocationsByGroupIds(locationGroupIds)).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={locationIds}
        onChange={(values) => setFilter('locationIds', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'cycleCountType') {
    return (
      <ModalSelect
        title={t('type')}
        options={Object.entries(cycleCountTypeOptions).map((each) => ({
          value: each[0],
          text: t(each[1]),
        }))}
        value={cycleCountType}
        onChange={(value) => setFilter('type', value)}
        onCancel={() => setState('activeView', null)}
      />
    )
  }

  if (state.activeView === 'userGroupIds') {
    return (
      <ModalSelect
        title={t('createdByGroup')}
        options={(state.userGroups ?? []).map((each) => ({
          value: each.id,
          text: each.description,
        }))}
        value={userGroupIds}
        onChange={(values) => {
          assignFilter({
            userGroupIds: values,
            userNames: [],
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.activeView === 'userNames') {
    return (
      <ModalSelect
        title={t('createdBy')}
        options={(state.users ?? []).map((each) => ({
          value: each.userName,
          text: each.displayName,
        }))}
        value={userNames}
        onChange={(values) => setFilter('userNames', values)}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        forceOverscroll={false}
        onIonScroll={(e) => setSessionItem(FILTER_SCROLL_TOP, e.detail.scrollTop)}
        scrollEvents
      >
        <DateRangePicker
          {...{ dateRangeType, lastNValue, customStartDate, customEndDate, setState, setFilter }}
        />
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('locationGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locationGroups, locationGroupIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'locationIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('location')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.locations, locationIds)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'cycleCountType')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('type')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromObject(cycleCountTypeOptions, cycleCountType)}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'userGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('createdByGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.userGroups, userGroupIds, 'id', 'description')}
          </IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'userNames')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('createdBy')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.users, userNames, 'userName')}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  )
}

import countries from 'i18n-iso-countries'
import { sortBy } from 'lodash'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))

export function getCountries(locale = 'en') {
  const unsorted = Object.entries(countries.getNames(locale, { select: 'official' })).map(([alpha2, ...rest]) => [
    countries.alpha2ToAlpha3(alpha2),
    ...rest,
  ])
  return sortBy(unsorted, [(each) => each[1]])
}

export function getCountryName(alpha3, locale = 'en') {
  try {
    return countries.getName(alpha3, locale, { select: 'official' })
  } catch (error) {
    return alpha3
  }
}

import { isEmpty } from 'lodash'
import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '..'
import Filter from 'containers/Jobs/FormView/SelectOperators/Filter'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'secondaryAssignedTo',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.operatorIds ?? [],
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      if (each.id === 0) {
        draft.item.operatorIds = []
      } else if (isEmpty(draft.item.operatorIds)) {
        draft.item.operatorIds = [each.id]
      } else {
        const index = draft.item.operatorIds.findIndex((one) => one === each.id)

        if (index > -1) {
          draft.item.operatorIds.splice(index, 1)
        } else {
          draft.item.operatorIds.push(each.id)
        }
      }
      draft.item.operatorNames = self.state.items
        .filter((item) => draft.item.operatorIds.includes(item.id))
        .map((item) => item.displayName)
        .join(', ')
    }),
  allowMultiple: true,
})(Filter)

import {
  get,
  capitalize,
  lowerCase,
  orderBy,
  toLower,
  toString as str,
  trim,
  without,
  isEmpty,
  isString,
  intersection,
  uniqBy,
  trimStart,
} from 'lodash'
import { Emitter, stopEvent } from './events'
import { NOTIFICATION } from 'options/events'
import { setSessionItem, getSessionItem } from './sessionStorage'

export const MAX_NUMBER = 2147483647
export const PLACEHOLDER = '---'
export const HARD_SPACE = '\u00A0'
export const FOCUS_DELAY = 375
export const DEBOUNCE = 1250
export const FILTER_SCROLL_TOP = 'filter.scrollTop'
export const INNER_HEIGHT_SESSION_KEY = 'innerHeight'

export const wrapData = (data) => ({ value: { data } })
export const wrapItems = (items = []) => wrapData({ items, recordCount: items.length })
export const strEqual = (x, y) => toLower(trim(x)) === toLower(trim(y))
export const asyncSleep = (timeout = 1000) => new Promise((resolve) => window.setTimeout(resolve, timeout))

export const notification = {
  error: (params) => Emitter.emit(NOTIFICATION, { color: 'danger', duration: 2500, ...params }),
  warn: (params) => Emitter.emit(NOTIFICATION, { color: 'warning', duration: 2000, ...params }),
  success: (params) => Emitter.emit(NOTIFICATION, { color: 'success', duration: 1500, ...params }),
  info: (params) => Emitter.emit(NOTIFICATION, { color: 'secondary', duration: 1000, ...params }),
  destroy: () => Emitter.emit(NOTIFICATION, { isOpen: false }),
}

export const message = {
  error: (param, duration = 2500) => notification.error({ message: param, duration }),
  warn: (param, duration = 2000) => notification.warn({ message: param, duration }),
  success: (param, duration = 1500) => notification.success({ message: param, duration }),
  info: (param, duration = 1000) => notification.info({ message: param, duration }),
  destroy: () => notification.destroy(),
}

export const tryParseInt = (value, defaultValue = 0) => {
  const parsed = Number.parseInt(str(value))

  return Number.isNaN(parsed) ? defaultValue : parsed
}

export const tryParseFloat = (value, defaultValue = 0.0) => {
  const parsed = Number.parseFloat(str(value))

  return Number.isNaN(parsed) ? defaultValue : parsed
}

export const setLoadingMessage = (content, shouldCapitalize) => {
  Array.from(document.getElementsByClassName('loading-content')).forEach((element) => {
    element.innerHTML = shouldCapitalize ? capitalize(content) : content
  })
}

export const setLoadingWithPrefix = (prefix, content, shouldCapitalize) => {
  Array.from(document.getElementsByClassName('loading-content')).forEach((element) => {
    element.innerHTML = shouldCapitalize ? prefix + capitalize(content) : prefix + content
  })
}

export const createSelectRowHandler =
  ({ each, updateState }) =>
  (e) => {
    updateState((draft) => {
      draft.selectedRowKeys = draft.selectedRowKeys || []

      if (e.detail.checked) {
        draft.selectedRowKeys = [...draft.selectedRowKeys, each.id].filter((v, i, a) => a.indexOf(v) === i)
      } else {
        draft.selectedRowKeys = without(draft.selectedRowKeys, each.id)
      }
    })
  }

export const sortItems = (unsortedItems, sortByField, sortOrder) =>
  sortByField
    ? orderBy(
        unsortedItems,
        [
          (item) => {
            const sortByValue = get(item, sortByField)
            return isString(sortByValue) ? toLower(sortByValue) : sortByValue
          },
        ],
        [['desc', 'descend'].includes(toLower(sortOrder)) ? 'desc' : 'asc']
      )
    : unsortedItems

export const createFocusByClassName = (className) => () =>
  Array.from(document.getElementsByClassName(className)).forEach((el, index) => index === 0 && el.focus())

export const createBlurByTagName = (tagName) => () =>
  Array.from(document.getElementsByTagName(tagName)).forEach((el) => el.blur())

export const findUniqOrFirst = (items, path, value) => {
  const results = uniqBy(items, path)

  return results.length === 1
    ? results[0]
    : results.find((one) => lowerCase(get(one, path)).startsWith(lowerCase(value)))
}

export const setInnerHeight = () => setSessionItem(INNER_HEIGHT_SESSION_KEY, window.innerHeight)

export const getInnerHeight = () => getSessionItem(INNER_HEIGHT_SESSION_KEY) ?? window.innerHeight

export const getTagDisplayNameField = (tagType) =>
  get(
    {
      2: 'operatorDisplayName',
      1: 'jobDisplayName',
      3: 'assetDisplayName',
    },
    tagType,
    `tag${tagType}`
  )

export const getTagName = (tagType) => get({ 2: 'Operator', 1: 'Job', 3: 'Asset' }, tagType, `Tag${tagType}`)

export const filterBySearch = (search) => {
  const keyword = trim(toLower(search))

  return (each) =>
    isEmpty(keyword) || Object.values(each).some((value) => trim(toLower(value)).includes(keyword))
}

export const filterAssetsByCategoryIds =
  (assetCategoryIds = []) =>
  (each) => {
    if (isEmpty(assetCategoryIds)) {
      return true
    }

    if (assetCategoryIds.includes(each.assetCategoryId)) {
      return true
    }

    return false
  }

export const filterLocationsByGroupIds =
  (locationGroupIds = []) =>
  (each) => {
    if (isEmpty(locationGroupIds)) {
      return true
    }

    if (intersection(locationGroupIds, each.locationGroupIds).length) {
      return true
    }

    return false
  }

export const filterLocationsByVendingTypes =
  (locationVendingTypes = []) =>
  (each) => {
    if (isEmpty(locationVendingTypes)) {
      return true
    }

    if (locationVendingTypes.includes('Inactive') && each.locationType === 'Inactive') {
      return true
    }

    if (locationVendingTypes.includes('Vending') && each.locationType === 'Vending') {
      return true
    }

    if (locationVendingTypes.includes('NonVending') && ['Primary', 'Secondary'].includes(each.locationType)) {
      return true
    }

    return false
  }

export const filterKeys = (pattern) => (e) => {
  if (e.key.match(pattern)) {
    stopEvent(e)
  }
}

export const isSignificant = (value) =>
  value !== null &&
  value !== undefined &&
  value !== false &&
  value !== 0 &&
  value !== '' &&
  value !== 'All' &&
  value !== 'None' &&
  JSON.stringify(value) !== '[]' &&
  JSON.stringify(value) !== '{}'

export const tryParseQueryString = (value) => {
  try {
    const trimmed = trimStart(value, '?#')

    return isEmpty(trimmed) ? {} : Object.fromEntries(new URLSearchParams(trimmed))
  } catch (error) {
    console.warn(error)

    return {}
  }
}

export async function simulatePressEnter(ionElement) {
  const keyboardEvent = new KeyboardEvent('keypress', {
    code: 'Enter',
    key: 'Enter',
    keyCode: 13,
    view: window,
    bubbles: true,
  })
  const inputElement = await ionElement.getInputElement()
  inputElement.dispatchEvent(keyboardEvent)
}

import { createFormViewContainer } from 'factories/FormView'
import { toString as str } from 'lodash'
import { getSharedInventory } from 'helpers/offlineData'
import { wrapData } from 'helpers/utils'
import { createDocumentsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/inventory'
import orderActions from 'actions/orders'
import productActions from 'actions/products'
import documentActions from 'actions/documents'
import Component from 'components/Inventory/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getItem: (params) =>
      dispatch(actions.getItem(params)).catch(async (error) => {
        if (!error.response) {
          const items = await getSharedInventory()

          return wrapData(items.find((one) => str(one.id) === str(params)))
        }

        throw error
      }),
    newOrder: (params) => dispatch(orderActions.newItem(params)),
    getOrderSettings: (params) => dispatch(orderActions.getSettings(params)),
    getProduct: (params) => dispatch(productActions.getItem(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
  }),
})(Component)

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonTextarea,
  IonLoading,
  IonSpinner,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isEmpty, isNil, omit } from 'lodash'
import { PLACEHOLDER, tryParseInt, asyncSleep } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { showError, showClientNotifications } from 'helpers/errors'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Page from 'elements/Page'
import useOnlineStatus from '@rehooks/online-status'

export const getStorageKey = () => 'jobRequests.formView.decline'

export default function (props) {
  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)
    })
  }, [])

  async function saveItem() {
    try {
      setState('loadingIsOpen', true)

      const response = await props.decline(state.item)

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      await asyncSleep()

      removeSessionItem('jobRequests.formView')

      props.history.goBack()
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      setState('item', { id: tryParseInt(props.match.params.itemId), comment: '' })
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = t('decline')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        <IonButton
          color="secondary"
          expand="full"
          disabled={isEmpty(state.item.comment) || !isOnline}
          onClick={() => saveItem()}
        >
          {t('decline')}
        </IonButton>
      }
    >
      <IonItem lines="full" className="tofino-required-item">
        <IonLabel position="stacked">{t('comments')}</IonLabel>
        <IonTextarea
          value={state.item.comment}
          onIonInput={(e) => setItemValue('comment', e.target.value)}
          rows={8}
          placeholder={PLACEHOLDER}
          autoGrow
        />
      </IonItem>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

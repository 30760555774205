import 'core-js'
import 'resize-observer-polyfill'
import BarcodeDetectorPolyfill from 'barcode-detector-polyfill'
import ReactDOM from 'react-dom'
import { IonApp, IonLoading } from '@ionic/react'
import { isNil } from 'lodash'
import { Provider as StoreProvider } from 'react-redux'
import { enableAllPlugins } from 'immer'
import i18n from 'actions/i18n'
import store from 'helpers/store'
import { setInnerHeight } from 'helpers/utils'
import { AUTH_SESSION_KEY, I18N_SESSION_KEY, SERVICE_WORKER_WAITING } from 'options/auth'
import { getSessionItem } from 'helpers/sessionStorage'
import { setStorageItemNoScope } from 'helpers/localStorage'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from 'components/App'
import './styles.scss'

enableAllPlugins()
setInnerHeight()

// BarcodeDetector Polyfill
;(async () => {
  try {
    await window.BarcodeDetector.getSupportedFormats()
  } catch {
    window.BarcodeDetector = BarcodeDetectorPolyfill
  }
})()

const { REACT_APP_NAME = 'TRMS20-Mobile', REACT_APP_VERSION = 'x.x.x' } = process.env
console.log(`${REACT_APP_NAME.toUpperCase()} v${REACT_APP_VERSION} (${window.getEnv()})`)

const root = document.getElementById('root')
const locale = getSessionItem(AUTH_SESSION_KEY, {}).current?.user?.languageCode ?? 'en'

;(async () => {
  try {
    if (isNil(getSessionItem(I18N_SESSION_KEY))) {
      const messages = {
        en: 'Please wait...',
        es: 'Espere por favor...',
        fr: 'Veuillez patienter SVP...',
      }

      ReactDOM.render(
        <IonApp>
          <IonLoading
            spinner="lines-small"
            message={messages[locale] ? messages[locale] : messages.en}
            showBackdrop={false}
            isOpen
          />
        </IonApp>,
        root
      )

      await store.dispatch(i18n.getTranslations(locale))
    }

    ReactDOM.render(
      <StoreProvider store={store}>
        <App />
      </StoreProvider>,
      root
    )
  } catch (error) {
    ReactDOM.render(<div className="ion-padding">{error.message}</div>, root)
  }
})()

// Learn more: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      setStorageItemNoScope(SERVICE_WORKER_WAITING, true)
    }
  },
})

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonTextarea,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { set, isNil, cloneDeep, isEmpty, max, orderBy, omit } from 'lodash'
import { PLACEHOLDER, tryParseInt, filterKeys } from 'helpers/utils'
import { prioritizeChecklistItems } from 'helpers/jobs'
import { t } from 'helpers/i18n'
import { getSessionItem, removeSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { showError, showValidationErrors } from 'helpers/errors'
import { formatUserTime } from 'helpers/dateTime'
import Page from 'elements/Page'

export const getStorageKey = () => 'jobs.formView.checklist.formView'

export default function (props) {
  const { parentStorageKey = 'jobs.formView' } = props

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      const index = draft.siblings.findIndex((one) => one.id === draft.itemId)

      draft.siblings[index][name] = value

      if (name === 'complete') {
        draft.siblings[index].lastUser = value ? props.user.userName : null
        draft.siblings[index].lastUserDate = value ? new Date().toJSON() : null
      }

      if (name === 'priority') {
        // Step 1. Take out the value that was just changed
        const otherSiblings = draft.siblings.filter((each) => each.id !== draft.itemId)

        // Step 2. Prioritize and shift forward
        const shiftedSiblings = prioritizeChecklistItems(otherSiblings).map((each) => {
          if (each.priority >= value) {
            return { ...each, priority: each.priority + 1 }
          }
          return each
        })

        // Step 4. Append and sort again
        const appendedSiblings = orderBy(
          [...shiftedSiblings, draft.siblings.find((one) => one.id === draft.itemId)],
          ['priority']
        )

        // Step 5. Prioritize and replace
        draft.siblings = prioritizeChecklistItems(appendedSiblings)
      }
    })
  }, [])

  function fetchItem() {
    try {
      const parentState = getSessionItem(parentStorageKey)
      const parentRecord = parentState.item
      const siblingRecords = parentState.checklistItems
      const maxPriority = max(siblingRecords.map((each) => each.priority)) || 0

      const id = props.match.params.itemId === '0' ? Date.now() * -1 : tryParseInt(props.match.params.itemId)

      updateState((draft) => {
        draft.siblings =
          props.match.params.itemId === '0'
            ? [...siblingRecords, { id, jobId: parentRecord.id, priority: maxPriority + 1 }]
            : [...siblingRecords]

        draft.itemId = id
      })
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.siblings.find((one) => one.id === state.itemId))

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    if (isNil(values.priority)) {
      errors.priority = t('errorMissingRequiredField')
    }

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields((errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          updateSessionItem(parentStorageKey, {}, (draft) => {
            draft.checklistItems = state.siblings
          })
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }

        props.history.goBack()
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const backButtonHref = `/jobs/jobs/${props.match.params.parentId}`

  if (isNil(state.siblings)) {
    return (
      <Page title={PLACEHOLDER} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const item = state.siblings.find((one) => one.id === state.itemId)

  const pageTitle =
    props.match.params.itemId === '0' ? t('addChecklistItem') : item?.description || PLACEHOLDER

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        <IonButton color="secondary" expand="full" onClick={() => saveItem()}>
          {props.match.params.itemId === '0' ? t('add') : t('update')}
        </IonButton>
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.description })}
      >
        <IonLabel position="stacked">{t('description')}</IonLabel>
        <IonInput value={item.description} onIonInput={(e) => setItemValue('description', e.target.value)} />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('notes')}</IonLabel>
        <IonTextarea
          value={item.notes}
          onIonInput={(e) => setItemValue('notes', e.target.value)}
          rows={5}
          autoGrow
        />
      </IonItem>
      <IonItem
        lines="full"
        className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.priority })}
      >
        <IonLabel position="stacked">{t('priority')}</IonLabel>
        <IonInput
          type="number"
          inputmode="number"
          inputMode="number"
          value={item.priority}
          onIonInput={(e) => setItemValue('priority', tryParseInt(e.target.value, 1))}
          onKeyPress={filterKeys(/\D/)}
          min={1}
          placeholder={PLACEHOLDER}
          clearOnEdit
        />
      </IonItem>
      <IonItem lines="full">
        <IonLabel position="stacked">{t('completedDate')}</IonLabel>
        <IonInput value={formatUserTime(item.lastUserDate, item.lastUser) || PLACEHOLDER} disabled />
      </IonItem>
      <IonItem lines="full">
        <IonLabel>{t('completed')}</IonLabel>
        <IonToggle checked={item.complete} onIonChange={(e) => setItemValue('complete', e.detail.checked)} />
      </IonItem>
    </Page>
  )
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonSpinner,
  IonInput,
  IonText,
  IonSearchbar,
  IonAlert,
  IonRow,
  IonCol,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import cx from 'clsx'
import { set, isNil, isEmpty, pick, cloneDeep, omit } from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import { t } from 'helpers/i18n'
import { stopEvent } from 'helpers/events'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, setStorageItem } from 'helpers/localStorage'
import {
  asyncSleep,
  simulatePressEnter,
  message,
  tryParseInt,
  strEqual,
  MAX_NUMBER,
  PLACEHOLDER,
  FOCUS_DELAY,
  createBlurByTagName,
  createFocusByClassName,
} from 'helpers/utils'
import { showError, showClientNotifications, showValidationErrors } from 'helpers/errors'
import { createCheckOfflineInventory } from 'helpers/offlineData'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import Page from 'elements/Page'
import Stepper from 'elements/Stepper'
import Icon from 'elements/Icon'
import Menu from 'elements/Menu'
import Button from 'elements/Button'
import Dropdown from 'elements/Dropdown'
import SearchbarContainer from 'elements/SearchbarContainer'
import BarcodeScanner from 'elements/BarcodeScanner'

const blurInputs = createBlurByTagName('input')
const focusSearchBar = createFocusByClassName('searchbar-input')
const focusFirstInput = createFocusByClassName('native-input')

function Description({ inventory, scannerSettings, item }) {
  return (
    <p className="ion-margin-horizontal">
      {inventory ? (
        <>
          {inventory.itemDescription}
          <br />
          {scannerSettings.showInventoryDescription && inventory.description ? (
            <>
              {inventory.description}
              <br />
            </>
          ) : null}
          {t('onhand:')}{' '}
          {(scannerSettings.showCount2 ? [inventory.onHand, inventory.onHand2] : [inventory.onHand]).join(
            '/'
          )}
          <br />
          {t('onOrder:')} {inventory.onOrder}
          <br />
          {t('minMax:')} {[inventory.min, inventory.max].join('/')}
          <br />
          {t('pkg:')} {inventory.packageSizeUom || PLACEHOLDER}
          <br />
          {t('binLocation:')} {inventory.binLocation || PLACEHOLDER}
        </>
      ) : item.locationId ? (
        <IonText color="medium">{t('enterBarcodeInfo')}</IonText>
      ) : (
        <IonText color="medium">{t('selectLocationFirst')}</IonText>
      )}
    </p>
  )
}

export const getStorageKey = () => 'inventory.countItems'
export const getItemStorageKey = () => 'inventory.countItems.item'

export default function (props) {
  const refBarcode = React.useRef()
  const refCount = React.useRef()
  const refCount2 = React.useRef()

  const [state, updateState] = useImmer({ showTagFields: true, ...getStorageItem(getStorageKey(), {}) })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const showCountInput = () => state.item && state.inventory
  const showCount2Input = () => state.item && state.inventory && props.scannerSettings.showCount2

  async function fetchInventory({ barcode, inventoryBarcode, locationId }) {
    try {
      updateState((draft) => {
        draft.inventory = null
        draft.item.count = null
        draft.item.count2 = null
      })

      const results = await props
        .getInventory({
          search: props.useInventoryBarcode ? inventoryBarcode : barcode,
          locationIds: locationId ? [locationId] : [],
        })
        .then((r) => r.value.data.items)

      const inventory = results
        .filter((each) => each.locationId === locationId)
        .find((one) =>
          props.useInventoryBarcode
            ? strEqual(one.inventoryBarcode, inventoryBarcode)
            : strEqual(one.barcode, barcode)
        )

      if (inventory) {
        if (inventory.lockedForCycleCount) {
          throw new Error(t('errorLockedForCycleCount'))
        }

        const savedItem = await getPrivateItem(COUNTED_ITEMS_SAVED_KEY, []).then((r) =>
          r.find((one) => one.inventoryId === inventory.id)
        )

        updateState((draft) => {
          draft.inventory = inventory
          draft.item.barcode = inventory.barcode
          draft.item.inventoryBarcode = inventory.inventoryBarcode
          draft.item.description = inventory.itemDescription

          if (savedItem) {
            draft.item.count = savedItem.count
            draft.item.count2 = savedItem.count2
          } else {
            draft.item.count = props.scannerSettings.showOnhandQuantity ? inventory.onHand : 0

            if (props.scannerSettings.showCount2) {
              draft.item.count2 = props.scannerSettings.showOnhandQuantity ? inventory.onHand2 : 0
            }
          }
        })

        window.setTimeout(focusFirstInput, FOCUS_DELAY)
      } else {
        window.setTimeout(focusSearchBar, FOCUS_DELAY)
        message.error(t('itemNotFound'))
      }
    } catch (error) {
      showError({ error })
    }
  }

  function handleBarcodeInput(e) {
    updateState((draft) => {
      if (props.useInventoryBarcode) {
        draft.item.inventoryBarcode = e.target.value
      } else {
        draft.item.barcode = e.target.value
      }

      draft.inventory = null
      draft.item.count = null
      draft.item.count2 = null
    })
  }

  async function handleBarcodeScan({ rawValue: value }) {
    setState('scanBarcodeIsOpen', false)
    await asyncSleep(500)
    refBarcode.current.setFocus()
    refBarcode.current.value = value
    handleBarcodeInput({ target: { value } })
    simulatePressEnter(refBarcode.current)
  }

  async function focusInput(inputRef) {
    try {
      await inputRef.current.setFocus()
    } catch (error) {
      console.warn(error)
    }
  }

  function handleKeyPress(input) {
    switch (input) {
      case 'barcode':
        return (e) => {
          if (e.key === 'Enter') {
            fetchInventory(state.item)
          }
        }

      case 'count':
        return async (e) => {
          if (e.key === 'Enter') {
            if (props.scannerSettings.showCount2) {
              await focusInput(refCount2)
            } else {
              saveItem()
            }
          } else if (e.key.match(/\D/)) {
            stopEvent(e)
          }
        }

      case 'count2':
        return (e) => {
          if (e.key === 'Enter') {
            saveItem()
          } else if (e.key.match(/\D/)) {
            stopEvent(e)
          }
        }

      default:
        return undefined
    }
  }

  const setCount = React.useCallback((name, value) => {
    updateState((draft) => {
      draft.item[name] = isFinite(value) ? value : tryParseInt(value, 0)
    })
  }, [])

  const handleCountInput = React.useCallback((e) => {
    setCount('count', tryParseInt(e.target.value, 0))
  }, [])

  const handleCountStep = React.useCallback((value) => {
    updateState((draft) => {
      draft.item.count = !isNil(value) ? Math.max(tryParseInt(draft.item.count, 0) + value, 0) : 0
    })
  }, [])

  const handleCount2Input = React.useCallback((e) => {
    setCount('count2', tryParseInt(e.target.value, 0))
  }, [])

  const handleCount2Step = React.useCallback((value) => {
    updateState((draft) => {
      draft.item.count2 = !isNil(value) ? Math.max(tryParseInt(draft.item.count2, 0) + value, 0) : 0
    })
  }, [])

  function resetForm() {
    focusSearchBar()

    updateState((draft) => {
      draft.item = {
        locationId: 0,
        locationName: '',
        barcode: '',
        count: null,
        count2: null,
        ...getStorageItem(getItemStorageKey(), {}),
      }
      draft.inventory = null
    })

    window.setTimeout(focusSearchBar, FOCUS_DELAY)
  }

  function handleCancel() {
    if (props.match.params.itemId) {
      props.history.goBack()
    } else {
      resetForm()
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (tryParseInt(values.count, 0) > MAX_NUMBER) {
      errors.count = t('quantityTooLarge')
    }

    if (tryParseInt(values.count2, 0) > MAX_NUMBER) {
      errors.count2 = t('quantityTooLarge')
    }

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        const countItem = {
          ...values,
          barcode: state.inventory.barcode,
          inventoryId: state.inventory.id,
          inventoryBarcode: state.inventory.inventoryBarcode,
        }

        if (props.match.params.itemId) {
          await updatePrivateItem(COUNTED_ITEMS_SAVED_KEY, [], (draft) => {
            const index = draft.findIndex((one) => strEqual(one.id, props.match.params.itemId))

            draft.splice(index, 1, countItem)
          })

          message.warn(t('quantityHasBeenSavedOnDevice'))

          props.history.goBack()
        } else {
          try {
            const response = await props.updateOnHand(countItem)

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error(t('unableToSaveCountQuantities'))
            }

            resetForm()

            message.success(t('quantityHasBeenSaved'))
          } catch (error) {
            showError({ error })
          }
        }
      } else {
        showValidationErrors({ errors })
      }
    })
  }

  async function fetchItem() {
    if (props.match.params.itemId) {
      try {
        const response = await getPrivateItem(COUNTED_ITEMS_SAVED_KEY).then((r) =>
          r.find((one) => strEqual(one.id, props.match.params.itemId))
        )

        setState('item', response)

        fetchInventory(response)
      } catch (error) {
        showError({ error })
      }
    } else {
      resetForm()
    }
  }

  function handleMenuClick({ key }) {
    switch (key) {
      case 'scanBarcode':
        setState('scanBarcodeIsOpen', true)
        break

      case 'searchInventory':
        removeSessionItem(`${getStorageKey()}.searchInventory`)
        setStorageItem(`${getStorageKey()}.searchInventory`, {
          filterDto: { locationIds: [state.item.locationId] },
        })
        setSessionItem(getStorageKey(), pick(state, ['item']))
        props.history.push(`${props.match.url}/searchInventory`)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  useIonViewWillLeave(() => {
    blurInputs()
  })

  React.useEffect(() => {
    if (state.item) {
      setStorageItem(getItemStorageKey(), pick(state.item, ['locationId', 'locationName']))
    }
  }, [state.item])

  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    createCheckOfflineInventory({ props, updateState, isOnline })()
  }, [isOnline])

  const pageTitle = t('countItem')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      footer={
        state.item?.locationId ? (
          <IonRow>
            <IonCol>
              <IonButton
                color="transparent"
                expand="full"
                onClick={handleCancel}
                disabled={isNil(state.inventory)}
              >
                {props.match.params.itemId ? t('cancel') : t('skip')}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="secondary"
                expand="full"
                onClick={() => saveItem()}
                disabled={isNil(state.inventory) || state.inventory.lockedForCycleCount}
              >
                {t('save')}
              </IonButton>
            </IonCol>
          </IonRow>
        ) : null
      }
    >
      <IonItem
        lines="full"
        className={cx('tofino-stacked-item tofino-required-item', {
          'tofino-error-item': state.errors?.locationId,
        })}
        onClick={() => {
          if (!props.match.params.itemId) {
            setSessionItem(getStorageKey(), pick(state, ['item']))
            props.history.push(`${props.match.url}/selectLocation`)
          }
        }}
        button={!props.match.params.itemId}
      >
        <IonLabel>
          <IonText color="medium">
            <small>{t('location')}</small>
          </IonText>
          <br />
          {state.item.locationId ? (
            <IonText color={props.match.params.itemId ? 'medium' : undefined}>
              {state.item.locationName}
            </IonText>
          ) : (
            <span className="tofino-placeholder">{PLACEHOLDER}</span>
          )}
        </IonLabel>
      </IonItem>
      {state.item.locationId ? (
        <SearchbarContainer lines="full">
          <IonSearchbar
            ref={refBarcode}
            value={props.useInventoryBarcode ? state.item.inventoryBarcode : state.item.barcode}
            placeholder={t('enterBarcode')}
            onIonInput={handleBarcodeInput}
            onKeyPress={handleKeyPress('barcode')}
            onIonClear={() =>
              updateState((draft) => {
                draft.inventory = null
                draft.item.barcode = ''
                draft.item.inventoryBarcode = ''
              })
            }
            disabled={props.match.params.itemId}
            maxlength={200}
          />
          {!props.match.params.itemId && (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  {props.scannerSettings.enableCameraScanning && [
                    <Menu.Item key="scanBarcode">{t('scanBarcode')}</Menu.Item>,
                    <Menu.Divider key="divider" />,
                  ]}
                  <Menu.Item key="searchInventory">{t('searchInventory')}</Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement={state.showTagFields ? 'topRight' : 'bottomRight'}
            >
              <Button disabled={props.match.params.itemId}>
                <Icon type="MoreHoriz" color="primary" />
              </Button>
            </Dropdown>
          )}
        </SearchbarContainer>
      ) : null}
      {showCountInput() && (
        <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.count })}>
          <IonLabel>{t('count')}</IonLabel>
          <IonInput
            ref={refCount}
            className="ion-text-right"
            value={state.item.count}
            onIonInput={handleCountInput}
            onKeyPress={handleKeyPress('count')}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
          <Stepper onClick={handleCountStep} />
        </IonItem>
      )}
      {showCount2Input() && (
        <IonItem lines="full" className={cx({ 'tofino-error-item': state.errors?.count2 })}>
          <IonLabel>{t('count2')}</IonLabel>
          <IonInput
            ref={refCount2}
            className="ion-text-right"
            value={state.item.count2}
            onIonInput={handleCount2Input}
            onKeyPress={handleKeyPress('count2')}
            type="number"
            inputmode="number"
            inputMode="number"
            min={0}
            placeholder={PLACEHOLDER}
            clearOnEdit
          />
          <Stepper onClick={handleCount2Step} />
        </IonItem>
      )}
      <Description scannerSettings={props.scannerSettings} item={state.item} inventory={state.inventory} />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <IonModal isOpen={state.scanBarcodeIsOpen}>
        {state.scanBarcodeIsOpen && (
          <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
        )}
      </IonModal>
    </Page>
  )
}

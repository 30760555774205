import { createListViewContainer } from 'factories/ListView'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import assetActions from 'actions/assets'
import assetWarrantyActions from 'actions/assets/assetWarranties'
import assetToleranceActions from 'actions/assets/assetTolerances'
import Component from 'components/Assets/FormView/CopyFromExisting'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => dispatch(assetActions.getListViewItems(params)),
    getItem: (itemId) => dispatch(assetActions.getItem(itemId)),
    ...createChildItemsDispatchToProps(dispatch, 'Warranty', assetWarrantyActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
  }),
})(Component)

import { createFormViewContainer } from 'factories/FormView'
import actions from 'actions/jobs'
import assetActions from 'actions/assets'
import documentActions from 'actions/documents'
import jobChecklistActions from 'actions/jobs/jobChecklist'
import jobLabourActions from 'actions/jobs/jobLabour'
import assetWarrantyActions from 'actions/assets/assetWarranties'
import assetToleranceActions from 'actions/assets/assetTolerances'
import jobGroupActions from 'actions/jobGroups'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import Component from 'components/Jobs/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getItem: (params) => dispatch(actions.getItem(params)),
    newItem: (params) => dispatch(actions.newItem(params)),
    generateId: (params) => dispatch(actions.generateId(params)),
    requestApprovalToBegin: (id) => dispatch(actions.requestApprovalToBegin(id)),
    requestApprovalToClose: (id) => dispatch(actions.requestApprovalToClose(id)),
    createTolerancesHistoryItems: (parentId, items) =>
      dispatch(assetToleranceActions.createHistoryItems(parentId, items)),
    getJobGroups: (params) => dispatch(jobGroupActions.getOptions(params)),
    getAsset: (assetId) => dispatch(assetActions.getItem(assetId)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createChildItemsDispatchToProps(dispatch, 'Checklist', jobChecklistActions),
    ...createChildItemsDispatchToProps(dispatch, 'Labour', jobLabourActions),
    ...createChildItemsDispatchToProps(dispatch, 'Warranty', assetWarrantyActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
  }),
})(Component)

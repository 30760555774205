import produce from 'immer'
import { snakeCase, isEmpty } from 'lodash'
import { createActions } from 'helpers/actions'
import { createPost } from 'helpers/api'
import entityNames from 'options/entityNames'
import { setSharedItem, getSharedItem } from 'helpers/localForage'
import { wrapItems } from 'helpers/utils'

const entityName = entityNames.locations
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const defaultActions = createActions(entityName, {
  transformGetItemsParams: (params = {}) =>
    produce(params, (draft) => {
      draft.alwaysIncludeLocationIds =
        draft.alwaysIncludeLocationIds === false ? false : !isEmpty(draft.locationIds)
    }),
})

export default {
  ...defaultActions,

  sendCreateNotification: (params = {}) => ({
    type: `${ENTITY_NAME}_SEND_CREATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${params.id}/sendCreateNotification` })(params),
  }),

  sendUpdateNotification: (params = {}) => ({
    type: `${ENTITY_NAME}_SEND_UPDATE_NOTIFICATION`,
    payload: createPost(entityName, { action: `${params.id}/sendUpdateNotification` })(params),
  }),

  getAddressList: (params = {}) => ({
    type: `${ENTITY_NAME}_ADDRESS_LIST_GET`,
    payload: createPost(entityName, { action: `addressList?type=${params.type}` })(params),
  }),

  generateTopUpOrder: (params = {}) => ({
    type: `${ENTITY_NAME}_GENERATE_TOP_UP_ORDER`,
    payload: createPost(entityName, { action: `${params.id}/topup` })(params),
  }),

  getItemCount: (params = {}) => ({
    type: `${ENTITY_NAME}_ITEM_COUNT_GET`,
    payload: createPost(entityName, { action: `${params.locationId}/getItemCount` })(params),
  }),

  getItemsOffline: () => async (dispatch) => {
    const getStorageKey = () => 'offlineData.locations'

    try {
      const response = await dispatch(defaultActions.getItems({ pageIndex: 0, mobileDownload: true }))

      await setSharedItem(getStorageKey(), response.value.data.items)

      return response
    } catch (error) {
      if (!error.response) {
        const items = await getSharedItem(getStorageKey())

        if (items) {
          return wrapItems(items)
        }
      }

      throw error
    }
  },
}

import { isEmpty, get } from 'lodash'
import { IonItem, IonLabel } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { showError } from 'helpers/errors'
import { removeSessionItem } from 'helpers/sessionStorage'
import { I18N_SESSION_KEY } from 'options/auth'
import { getOfflineTranslations } from 'helpers/manageCustomers'
import { t } from 'helpers/i18n'
import { getStartupPage, sendClientLog } from 'helpers/auth'

export default createListViewComponent({
  getStorageKey: () => 'distributor.manageCustomers',
  getViewDidEnterHandler:
    ({ props, updateState, isOnline }) =>
    async () => {
      try {
        const items = await props.getItems().then((r) => get(r, 'value.data.items', []))

        if (isEmpty(items)) {
          const buttons = [
            {
              text: t('cancel'),
              role: 'cancel',
              handler: () => props.history.goBack(),
            },
            {
              text: t('ok'),
              handler: () => {
                window.location.href = '/more/downloadOfflineData'
              },
            },
          ]

          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('customerDownloadRequired')
            draft.alertButtons = buttons
          })
        }
      } catch (error) {
        showError({ error })
      }
    },
  renderItem:
    ({ props, state, setState, isOnline }) =>
    (item) => (
      <IonItem
        key={item.id}
        lines="full"
        onClick={async () => {
          try {
            setState('loadingIsOpen', true)

            if (isOnline || !isEmpty(await getOfflineTranslations(item.id))) {
              removeSessionItem(I18N_SESSION_KEY)
            }

            const customerId = item.id
            const data = await props.loginAsCustomer(customerId).then((r) => r.value.data)
            const startPage = await getStartupPage(data)

            await sendClientLog('Success', 'Login as Customer', { customerId, startPage })

            window.location.href = startPage
          } catch (error) {
            window.impersonationInProgress = false
            setState('loadingIsOpen', false)
            showError({ error })
          }
        }}
        button
      >
        <IonLabel>{item.displayName}</IonLabel>
      </IonItem>
    ),
  allowInfiniteLoader: () => false,
  allowSearch: (self) => true,
})()

import { createChildFormViewContainer } from 'factories/ChildFormView'
import { createChildItemsDispatchToProps } from 'helpers/formViews'
import actions from 'actions/orders/orderSuppliers'
import Component from 'components/DraftOrder/Suppliers/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', actions),
  }),
})(Component)

import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import { getSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from 'components/CycleCounts/Create'
import Component from 'components/CycleCounts/Create/SelectLocation'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const locationGroupId = getSessionItem(getStorageKey())?.item?.locationGroupId

      return dispatch(
        locationActions.getItems({
          locationGroupIds: locationGroupId ? [locationGroupId] : [],
          locationVendingTypes: ['NonVending'],
          ...params,
        })
      )
    },
  }),
})(Component)

import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useImmer } from 'use-immer'
import { set, isNil, cloneDeep, uniqBy, isEmpty, pick, range, omit, round, invoke, isEqual } from 'lodash'
import {
  IonSpinner,
  IonPopover,
  IonLoading,
  IonAlert,
  IonItem,
  IonLabel,
  IonButton,
  IonText,
  IonInput,
  IonToggle,
  IonTextarea,
  IonRow,
  IonCol,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import useOnlineStatus from '@rehooks/online-status'
import { message, asyncSleep, PLACEHOLDER, tryParseInt } from 'helpers/utils'
import { t, toLocaleNumber } from 'helpers/i18n'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { getStorageItem, updateStorageItem } from 'helpers/localStorage'
import { showError, showValidationErrors } from 'helpers/errors'
import { Emitter } from 'helpers/events'
import {
  createLabelFactory,
  createSaveDocumentItems,
  createSaveToleranceHistoryItems,
  createSaveWarrantyItems,
} from 'helpers/formViews'
import { formatUserTime, isValidDate, tryParseMoment, MIN_TIME, MAX_TIME } from 'helpers/dateTime'
import { createSaveChecklistItems, createSaveLabourItems, prioritizeChecklistItems } from 'helpers/jobs'
import { getUserPermission } from 'helpers/auth'
import { LISTS_DELETE_ITEM_EVENT, LISTS_SELECT_ITEM_EVENT } from 'options/events'
import { TRACK_ASSETS_AFTER_CLOSE_KEY } from 'components/TrackAssets/FormView'
import { ASSETS_FORM_VIEW_RETURN_TO_JOB } from 'components/Assets/FormView'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Tabs from 'elements/Tabs'
import Lists from 'containers/Lists/ChildListView'
import Documents from 'containers/Documents/ChildListView'
import FileUpload from 'elements/FileUpload'
import DatePicker from 'elements/DatePicker'
import Comments from 'elements/Comments'
import Labour from 'containers/Jobs/Labour'
import Tolerances from 'containers/Assets/Tolerances'
import Warranties from 'containers/Assets/Warranties'
import Checklist from 'containers/Jobs/Checklist'

const CheckboxLabel = styled(IonLabel)`
  line-height: 1.4em;
`

export const getDowntime = ({ downtimeStart, downtimeEnd }) =>
  isValidDate(downtimeStart) && isValidDate(downtimeEnd)
    ? round(moment.duration(moment(downtimeEnd).diff(moment(downtimeStart))).asHours(), 2)
    : 0

export const getStorageKey = () => 'jobs.formView'

export default function (props) {
  const isOnline = useOnlineStatus()

  React.useEffect(() => {
    if (!isOnline) {
      window.location.href = '/jobs'
    }
  }, [])

  const [state, updateState] = useImmer({
    tabsActiveKey: 'job',
    ...getStorageItem(getStorageKey(), {}),
  })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)

      if (name === 'approvedToBegin') {
        if (value) {
          draft.item.approvedToBeginBy = props.user.userName
          draft.item.approvedToBeginDate = new Date()
        } else {
          draft.item.approvedToBeginBy = null
          draft.item.approvedToBeginDate = null
          draft.item.approvedToClose = false
          draft.item.approvedToCloseBy = null
          draft.item.approvedToCloseDate = null
          draft.item.active = true
          draft.item.completedBy = null
          draft.item.completionDate = null
        }
      }

      if (name === 'approvedToClose') {
        if (value) {
          draft.item.approvedToCloseBy = props.user.userName
          draft.item.approvedToCloseDate = new Date()
          draft.item.approvedToBegin = true
          draft.item.approvedToBeginBy = draft.item.approvedToBeginBy || props.user.userName
          draft.item.approvedToBeginDate = draft.item.approvedToBeginDate || new Date()
        } else {
          draft.item.approvedToCloseBy = null
          draft.item.approvedToCloseDate = null
          draft.item.active = true
          draft.item.completedBy = null
          draft.item.completionDate = null
        }
      }

      if (name === 'active') {
        if (value) {
          draft.item.completedBy = null
          draft.item.completionDate = null
          draft.item.downtimeEnd = null
          draft.item.productionDowntime = 0
        } else {
          draft.item.completedBy = props.user.userName
          draft.item.completionDate = new Date()
          draft.item.downtimeEnd = new Date()
          draft.item.approvedToBegin = true
          draft.item.approvedToBeginBy = draft.item.approvedToBeginBy || props.user.userName
          draft.item.approvedToBeginDate = draft.item.approvedToBeginDate || new Date()
          draft.item.approvedToClose = true
          draft.item.approvedToCloseBy = draft.item.approvedToCloseBy || props.user.userName
          draft.item.approvedToCloseDate = draft.item.approvedToCloseDate || new Date()
        }
      }

      if (name === 'trackDowntime') {
        if (value === false) {
          draft.item.productionEqualsElapsed = true
          draft.item.productionDowntime = getDowntime(draft.item)
        }
      }

      if (!draft.item.active && draft.item.productionEqualsElapsed) {
        draft.item.productionDowntime = getDowntime(draft.item)
      }
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      setState('loadingIsOpen', true)

      const newItemParams = getSessionItem('jobs.formView.newItemParams')
      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props.newItem().then((r) => cloneDeep(r.value.data))

      if (!item.id && newItemParams) {
        Object.assign(item, newItemParams)
      }

      const [
        fieldSettings,
        jobGroups,
        documentItems,
        checklistItems,
        labourItems,
        toleranceItems,
        warrantyItems,
      ] = await Promise.all([
        props.getSettings({ type: 'job' }).then((r) => r.value.data.fieldSettings),
        props.getJobGroups({ includeUnassignedSelection: false }).then((r) => r.value.data.items),
        item.id
          ? props
              .getDocumentItems({
                request: {
                  domainObjectId: item.id,
                  domainObjectType: 'Job',
                  documentType: 'ObjectDocument',
                },
              })
              .then((r) => r.value.data.items)
          : Promise.resolve([]),
        item.id ? props.getChecklistItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
        item.id ? props.getLabourItems(item.id).then((r) => r.value.data.items) : Promise.resolve([]),
        item.assetId
          ? props.getToleranceItems(item.assetId).then((r) => r.value.data.items)
          : Promise.resolve([]),
        item.assetId
          ? props.getWarrantyItems(item.assetId).then((r) => r.value.data.items)
          : Promise.resolve([]),
      ])

      item.locationId = item.locationId || null
      item.locationName = item.locationId ? item.locationName : ''

      updateState((draft) => {
        draft.fieldSettings = fieldSettings
        draft.item = item
        draft.itemOriginal = cloneDeep(item)
        draft.documentItems = cloneDeep(documentItems)
        draft.documentItemsOriginal = cloneDeep(documentItems)
        draft.checklistItems = cloneDeep(checklistItems)
        draft.checklistItemsOriginal = cloneDeep(checklistItems)
        draft.labourItems = cloneDeep(labourItems)
        draft.labourItemsOriginal = cloneDeep(labourItems)
        draft.toleranceItems = cloneDeep(toleranceItems)
        draft.toleranceItemsOriginal = cloneDeep(toleranceItems)
        draft.warrantyItems = cloneDeep(warrantyItems)
        draft.warrantyItemsOriginal = cloneDeep(warrantyItems)
        draft.jobGroups = jobGroups
      })

      removeSessionItem('jobs.formView.newItemParams')
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.name)) {
      errors.name = t('errorMissingRequiredField')
    }

    if (isEmpty(values.barcode)) {
      errors.barcode = t('errorMissingRequiredField')
    } else if (values.barcode.match(/[^a-zA-Z0-9-.$/+%\s]/g)) {
      errors.barcode = t('invalidCharacter')
    }

    if (isEmpty(values.number)) {
      errors.number = t('errorMissingRequiredField')
    }

    if (isValidDate(values.downtimeStart) && isValidDate(values.downtimeEnd)) {
      const downtimeStart = tryParseMoment(values.downtimeStart, MIN_TIME)
      const downtimeEnd = tryParseMoment(values.downtimeEnd, MAX_TIME)

      if (downtimeStart > downtimeEnd) {
        errors.downtimeStart = t('downtimeStartValidationError')
        errors.downtimeEnd = t('downtimeEndValidationError')
      }
    }

    callback(errors, values)
  }

  function saveItem() {
    const saveChecklistItems = createSaveChecklistItems({ props, state })
    const saveLabourItems = createSaveLabourItems({ props, state })
    const saveWarrantyItems = createSaveWarrantyItems({ props, state })
    const saveToleranceHistoryItems = createSaveToleranceHistoryItems({ props, state })
    const saveDocumentItems = createSaveDocumentItems({ props, state }, 'Job')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        try {
          const saved = values.id ? await props.updateItem(values) : await props.createItem(values)

          await Promise.all([
            saveChecklistItems(saved.value.data.id),
            saveLabourItems(saved.value.data.id),
            saveWarrantyItems(saved.value.data.assetId),
            saveToleranceHistoryItems(saved.value.data.assetId),
            saveDocumentItems(saved.value.data.id),
          ])

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/jobs/jobs/${saved.value.data.id}`
          } else {
            await fetchItem(saved.value.data.id)
          }
        } catch (error) {
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  async function handleGenerateClick() {
    setState('errors', {})

    const params = {
      isTemplate: false,
      downtimeStart: new Date().toJSON(),
      createdDate: new Date().toJSON(),
      createdBy: props.user.userName,
      ...state.item,
    }

    if (!params.locationId) {
      message.error(t('selectLocationFirst'))
      return
    }

    try {
      setState('loadingIsOpen', true)

      params.locationId = params.locationId ?? 0

      const saved = await props.generateId(params)

      await asyncSleep()

      window.location.href = `/jobs/jobs/${saved.value.data.id}`
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  async function requestApproval(action) {
    try {
      setState('loadingIsOpen', true)

      await invoke(props, action, state.item.id)

      message.success(t('requestSentSuccessfully'))
    } catch (error) {
      showError({ error })
    } finally {
      setState('loadingIsOpen', false)
    }
  }

  function hasUnsavedChanges() {
    return (
      !state.item?.id ||
      !isEqual(state.item, state.itemOriginal) ||
      !isEqual(state.checklistItems, state.checklistItemsOriginal) ||
      !isEqual(state.labourItems, state.labourItemsOriginal) ||
      !isEqual(state.warrantyItems, state.warrantyItemsOriginal) ||
      !isEqual(state.toleranceItems, state.toleranceItemsOriginal) ||
      !isEqual(state.documentItems, state.documentItemsOriginal)
    )
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  React.useEffect(() => {
    function handleSelectList() {
      props.history.push(`${props.match.url}/selectList`)
    }

    Emitter.on(LISTS_SELECT_ITEM_EVENT, handleSelectList)

    return () => Emitter.off(LISTS_SELECT_ITEM_EVENT, handleSelectList)
  }, [props.match.params.itemId])

  const pageTitle =
    props.match.params.itemId === '0'
      ? t('createJob')
      : state.item
        ? `${t('job')} - ${state.item.barcode}`
        : t('job')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = !state.item.userCanEditRecord
  const requireChecklist = props.customer.generalSettings.requireChecklistCompleteToCloseJob
  const enableDowntimeStart = state.item.trackDowntime && getUserPermission('EditJobCloseDate') === 'Yes'
  const enableDowntimeEnd =
    !state.item.active && state.item.trackDowntime && getUserPermission('EditJobCloseDate') === 'Yes'
  const enableActiveCheckbox =
    state.item.id &&
    getUserPermission('CloseJobs') === 'Yes' &&
    (!requireChecklist || !state.checklistItems.find((one) => !one.complete))
  const enableProductionDowntime =
    !state.item.active &&
    state.item.trackDowntime &&
    !state.item.productionEqualsElapsed &&
    getUserPermission('EditJobCloseDate') === 'Yes'
  const enableProductionEqualsElapsedCheckbox =
    !state.item.active && state.item.trackDowntime && getUserPermission('EditJobCloseDate') === 'Yes'
  const userCanApproveRecord =
    state.item.id && (getUserPermission('CloseJobs') === 'Yes' || getUserPermission('Jobs') === 'All')

  async function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'addListToJob':
        props.history.push(`${props.match.url}/addList`)
        break

      case 'removeListFromJob':
        Emitter.emit(LISTS_DELETE_ITEM_EVENT)
        break

      case 'createFromTemplate':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/createFromTemplate`)
        break

      case 'addDocument':
        setState('fileUploadIsOpen', true)
        break

      case 'addChecklistItem':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/checklist/0`)
        break

      case 'addLabour':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/labour/0`)
        break

      case 'addWarranty':
        setSessionItem(getStorageKey(), omit(state, ['popoverIsOpen', 'popoverEvent']))
        props.history.push(`${props.match.url}/warranties/0`)
        break

      case 'sortOptions':
        if (state.tabsActiveKey === 'documents') {
          Emitter.emit('documents.childListView.sortOptions')
        }

        if (state.tabsActiveKey === 'lists') {
          Emitter.emit('lists.childListView.items.sortOptions')
        }

        if (state.tabsActiveKey === 'checklist') {
          Emitter.emit('jobs.formView.checklist.sortOptions')
        }

        if (state.tabsActiveKey === 'labour') {
          Emitter.emit('jobs.formView.labour.sortOptions')
        }

        if (state.tabsActiveKey === 'tolerances') {
          Emitter.emit('jobs.formView.tolerances.sortOptions')
        }

        if (state.tabsActiveKey === 'warranty') {
          Emitter.emit('jobs.formView.warranty.sortOptions')
        }
        break

      case 'showSwitches':
        setState('showSwitches', true)
        updateStorageItem(getStorageKey(), {}, (draft) => {
          draft.showSwitches = true
        })
        break

      case 'hideSwitches':
        setState('showSwitches', false)
        updateStorageItem(getStorageKey(), {}, (draft) => {
          draft.showSwitches = false
        })
        break

      case 'trackAsset':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          try {
            setState('loadingIsOpen', true)

            const asset = await props.getAsset(state.item.assetId).then((r) => r.value.data)

            setSessionItem(TRACK_ASSETS_AFTER_CLOSE_KEY, {
              redirectUrl: `/jobs/jobs/${state.item.id}`,
            })
            setSessionItem('trackAssets', {
              asset,
              item: {
                barcode: asset.barcode,
                jobId: state.item.id,
                jobDisplayName: `${state.item.barcode} - ${state.item.name}`,
                operatorId: props.user.operatorId,
                operatorBarcode: props.user.operatorBarcode,
                operatorDisplayName: props.user.operatorName,
              },
              quickscan: true,
              showTagFields: true,
            })

            window.location.href = '/assets/trackAssets'
          } catch (error) {
            showError({ error })
          } finally {
            setState('loadingIsOpen', false)
          }
        }
        break

      case 'editAsset':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem(ASSETS_FORM_VIEW_RETURN_TO_JOB, { jobId: state.item.id })

          window.location.href = `/assets/assets/${state.item.assetId}`
        }
        break

      case 'issueItems':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem('issue.issueItem', {
            quickscan: true,
            requireQuantity: true,
            showTagFields: true,
            item: {
              locationId: state.item.locationId,
              locationDisplayName: state.item.locationName,
              jobId: state.item.id,
              jobName: state.item.name,
              jobNumber: state.item.number,
              jobBarcode: state.item.barcode,
              jobDisplayName: `${state.item.barcode} - ${state.item.name}`,
              operatorId: props.user.operatorId,
              operatorDisplayName: props.user.operatorName,
              operatorBarcode: props.user.operatorBarcode,
              assetId: state.item.assetId && state.item.assetName ? state.item.assetId : undefined,
              assetDisplayName: state.item.assetId && state.item.assetName ? state.item.assetName : undefined,
              quantityIssued: 1,
              userName: props.user.userName,
            },
          })
          window.location.href = '/inventory/issue/issueItem'
        }
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    const defaultItems = [
      !state.item.id ? (
        <IonItem
          key="createFromTemplate"
          lines="full"
          onClick={() => handleActionsMenuClick('createFromTemplate')}
        >
          <IonLabel>{t('createFromTemplate')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter(Boolean)
    const editAsset =
      state.item.id && state.item.assetId ? (
        <IonItem key="editAsset" lines="none" onClick={() => handleActionsMenuClick('editAsset')}>
          <IonLabel>{t('editAsset')}</IonLabel>
        </IonItem>
      ) : null
    const trackAssets =
      state.item.id && state.item.assetId ? (
        <IonItem key="trackAsset" lines="full" onClick={() => handleActionsMenuClick('trackAsset')}>
          <IonLabel>{t('trackAsset')}</IonLabel>
        </IonItem>
      ) : null
    const issueItems =
      state.item.id && getUserPermission('Issue') === 'Yes' ? (
        <IonItem key="issueItems" lines="full" onClick={() => handleActionsMenuClick('issueItems')}>
          <IonLabel>{t('issueItems')}</IonLabel>
        </IonItem>
      ) : null
    const sortOptions = (
      <IonItem key="sortOptions" lines="none" onClick={() => handleActionsMenuClick('sortOptions')}>
        <IonLabel>{t('sortOptions')}</IonLabel>
      </IonItem>
    )

    switch (state.tabsActiveKey) {
      case 'general':
        return [...defaultItems, editAsset, trackAssets, issueItems].filter(Boolean)

      case 'checklist':
        return [
          ...defaultItems,
          <IonItem
            key="addChecklistItem"
            lines="full"
            onClick={() => handleActionsMenuClick('addChecklistItem')}
            disabled={readOnly}
          >
            <IonLabel>{t('addChecklistItem')}</IonLabel>
          </IonItem>,
          issueItems,
          sortOptions,
          state.showSwitches ? (
            <IonItem
              key="showSwitches"
              lines="none"
              onClick={() => handleActionsMenuClick('hideSwitches')}
              disabled={readOnly}
            >
              <IonLabel>{t('hideSwitches')}</IonLabel>
            </IonItem>
          ) : (
            <IonItem
              key="showSwitches"
              lines="none"
              onClick={() => handleActionsMenuClick('showSwitches')}
              disabled={readOnly}
            >
              <IonLabel>{t('showSwitches')}</IonLabel>
            </IonItem>
          ),
        ].filter(Boolean)

      case 'labour':
        return [
          ...defaultItems,
          <IonItem
            key="addLabour"
            lines="full"
            onClick={() => handleActionsMenuClick('addLabour')}
            disabled={readOnly}
          >
            <IonLabel>{t('addLabour')}</IonLabel>
          </IonItem>,
          issueItems,
          sortOptions,
        ].filter(Boolean)

      case 'tolerances':
        return [...defaultItems, issueItems, sortOptions].filter(Boolean)

      case 'warranty':
        return [
          ...defaultItems,
          <IonItem
            key="addWarranty"
            lines="full"
            onClick={() => handleActionsMenuClick('addWarranty')}
            disabled={readOnly}
          >
            <IonLabel>{t('addWarranty')}</IonLabel>
          </IonItem>,
          issueItems,
          sortOptions,
        ].filter(Boolean)

      case 'lists':
        return [
          ...defaultItems,
          <IonItem
            key="addListToJob"
            lines="none"
            onClick={() => handleActionsMenuClick('addListToJob')}
            disabled={readOnly}
          >
            <IonLabel>{t('addListToJob')}</IonLabel>
          </IonItem>,
          <IonItem
            key="removeListFromJob"
            lines="full"
            onClick={() => handleActionsMenuClick('removeListFromJob')}
            disabled={readOnly || isEmpty(state.listItems)}
          >
            <IonLabel>{t('removeListFromJob')}</IonLabel>
          </IonItem>,
          issueItems,
          sortOptions,
        ].filter(Boolean)

      case 'documents':
        return [
          ...defaultItems,
          <IonItem
            key="addDocument"
            lines="full"
            onClick={() => handleActionsMenuClick('addDocument')}
            disabled={readOnly}
          >
            <IonLabel>{t('addDocument')}</IonLabel>
          </IonItem>,
          editAsset,
          trackAssets,
          issueItems,
          sortOptions,
        ].filter(Boolean)

      default:
        return [...defaultItems, editAsset, trackAssets, issueItems].filter(Boolean)
    }
  })().filter(Boolean)

  const createLabel = createLabelFactory(state.fieldSettings)

  return (
    <Page
      title={pageTitle}
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonRow>
            {Object.entries({
              checklist: 'addChecklistItem',
              labour: 'addLabour',
              warranty: 'addWarranty',
              documents: 'addDocument',
              lists: 'addListToJob',
            }).map(([key, value]) =>
              state.tabsActiveKey === key ? (
                <IonCol key={key}>
                  <IonButton
                    color="transparent"
                    expand="full"
                    onClick={() => handleActionsMenuClick(value)}
                    disabled={!isOnline}
                  >
                    {t('add')}
                  </IonButton>
                </IonCol>
              ) : null
            )}
            <IonCol>
              <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
                {t('save')}
              </IonButton>
            </IonCol>
          </IonRow>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="job" tab={t('job')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('locationId')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || PLACEHOLDER}
            </IonLabel>
          </IonItem>
          <IonRow>
            <IonCol size={state.item.id ? 12 : 8}>
              <IonItem
                lines="full"
                className={cx('tofino-required-item', {
                  'tofino-error-item': state.errors?.barcode,
                })}
              >
                <IonLabel position="stacked">{createLabel('barcode')}</IonLabel>
                <IonInput
                  value={state.item.barcode}
                  onIonInput={(e) => setItemValue('barcode', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={readOnly}
                />
              </IonItem>
            </IonCol>
            {!state.item.id && (
              <IonCol size={4}>
                <IonItem
                  lines="full"
                  onClick={handleGenerateClick}
                  detail={false}
                  className="ion-text-right"
                  button
                >
                  <IonLabel color="secondary" style={{ marginTop: '32px', marginBottom: '18px' }}>
                    {t('generate')}
                  </IonLabel>
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.name })}
          >
            <IonLabel position="stacked">{createLabel('name')}</IonLabel>
            <IonInput
              value={state.item.name}
              onIonInput={(e) => setItemValue('name', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', { 'tofino-error-item': state.errors?.number })}
          >
            <IonLabel position="stacked">{createLabel('number')}</IonLabel>
            <IonInput
              value={state.item.number}
              onIonInput={(e) => setItemValue('number', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('description')}</IonLabel>
            <IonTextarea
              value={state.item.description}
              onIonInput={(e) => setItemValue('description', e.target.value)}
              rows={3}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
              autoGrow
            />
          </IonItem>
          <IonItem
            className="tofino-stacked-item"
            lines="full"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectAssetCategory`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('assetCategoryId')}</small>
              </IonText>
              <br />
              {state.item.assetCategoryId
                ? state.item.assetCategoryName || state.item.assetCategoryId
                : t('all')}
            </IonLabel>
          </IonItem>
          <IonItem
            className="tofino-stacked-item"
            lines="full"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectAsset`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('assetId')}</small>
              </IonText>
              <br />
              {state.item.assetName || state.item.assetId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('created')}</IonLabel>
            <IonInput
              value={formatUserTime(state.item.createdDate, state.item.createdBy)}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('requestedBy')}</IonLabel>
            <IonInput value={state.item.requestedBy} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            {state.item.approvedToBegin && state.item.approvedToBeginDate ? (
              <CheckboxLabel color="medium">
                <small>{t('approvedToBegin')}</small>
                <br />
                {formatUserTime(state.item.approvedToBeginDate, state.item.approvedToBeginBy)}
              </CheckboxLabel>
            ) : (
              <IonLabel>{t('approvedToBegin')}</IonLabel>
            )}
            <IonToggle
              checked={state.item.approvedToBegin}
              onIonChange={(e) => setItemValue('approvedToBegin', e.detail.checked)}
              disabled={readOnly || !userCanApproveRecord}
            />
          </IonItem>
          <IonItem lines="full">
            {state.item.approvedToClose && state.item.approvedToCloseDate ? (
              <CheckboxLabel color="medium">
                <small>{t('approvedToClose')}</small>
                <br />
                {formatUserTime(state.item.approvedToCloseDate, state.item.approvedToCloseBy)}
              </CheckboxLabel>
            ) : (
              <IonLabel>{t('approvedToClose')}</IonLabel>
            )}
            <IonToggle
              checked={state.item.approvedToClose}
              onIonChange={(e) => setItemValue('approvedToClose', e.detail.checked)}
              disabled={readOnly || !userCanApproveRecord}
            />
          </IonItem>
          <IonItem lines="full">
            {!state.item.active && state.item.completionDate ? (
              <CheckboxLabel color="medium">
                <small>{createLabel('active')}</small>
                <br />
                {formatUserTime(state.item.completionDate, state.item.completedBy)}
              </CheckboxLabel>
            ) : (
              <IonLabel>{createLabel('active')}</IonLabel>
            )}
            <IonToggle
              checked={!state.item.active}
              onIonChange={(e) => setItemValue('active', !e.detail.checked)}
              disabled={readOnly || !enableActiveCheckbox}
            />
          </IonItem>
          {state.item.id &&
            !state.item.approvedToBegin &&
            !state.item.approvedToClose &&
            state.item.active && (
              <IonItem
                lines="full"
                onClick={() => requestApproval('requestApprovalToBegin')}
                detail={false}
                button
              >
                <IonLabel color="secondary">{t('requestApprovalToBegin')}</IonLabel>
              </IonItem>
            )}
          {state.item.id &&
            state.item.approvedToBegin &&
            !state.item.approvedToClose &&
            state.item.active && (
              <IonItem
                lines="full"
                onClick={() => requestApproval('requestApprovalToClose')}
                detail={false}
                button
              >
                <IonLabel color="secondary">{t('requestApprovalToClose')}</IonLabel>
              </IonItem>
            )}
        </Tabs.TabPane>
        <Tabs.TabPane key="general" tab={t('general')} forceRender>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectReason`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('reasonId')}</small>
              </IonText>
              <br />
              {state.item.reasonDescription || state.item.reasonId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectAssignedOperator`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('assignedToId')}</small>
              </IonText>
              <br />
              {state.item.assignedToName || state.item.assignedToId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectOperators`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('operatorIds')}</small>
              </IonText>
              <br />
              {state.item.operatorNames || state.item.operatorIds.join(', ') || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectPriority`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('priority')}</small>
              </IonText>
              <br />
              {state.item.priority || t('none')}
            </IonLabel>
          </IonItem>
          <DatePicker
            label={createLabel('dueDate')}
            value={state.item.dueDate}
            onChange={(value) => setItemValue('dueDate', value)}
            disabled={readOnly}
          />
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('budgetHours')}</IonLabel>
            <IonInput
              value={state.item.budgetHours}
              onIonInput={(e) => setItemValue('budgetHours', tryParseInt(e.target.value, 0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              disabled={readOnly}
              clearOnEdit
            />
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectStatus`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('statusOptionId')}</small>
              </IonText>
              <br />
              {state.item.statusDescription || state.item.statusOptionId || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectNotifyUser`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{createLabel('notifyUser')}</small>
              </IonText>
              <br />
              {state.item.notifyName || state.item.notifyUser || t('none')}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{createLabel('trackDowntime')}</IonLabel>
            <IonToggle
              checked={state.item.trackDowntime}
              onIonChange={(e) => setItemValue('trackDowntime', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <DatePicker
            label={createLabel('downtimeStart')}
            value={state.item.downtimeStart}
            onChange={(value) => setItemValue('downtimeStart', value)}
            disabled={readOnly || !enableDowntimeStart}
            error={state.errors?.downtimeStart}
          />
          <DatePicker
            label={createLabel('downtimeEnd')}
            value={state.item.downtimeEnd}
            onChange={(value) => setItemValue('downtimeEnd', value)}
            disabled={readOnly || !enableDowntimeEnd}
            error={state.errors?.downtimeEnd}
          />
          <IonItem lines="full">
            <IonLabel position="stacked">{`${t('elapsedTime')} = ${toLocaleNumber(
              getDowntime(state.item)
            )} ${t('hours')}`}</IonLabel>
            <IonInput
              value={state.item.productionDowntime}
              onIonInput={(e) => setItemValue('productionDowntime', tryParseInt(e.target.value, 0))}
              type="number"
              inputmode="number"
              inputMode="number"
              min={0}
              disabled={readOnly || !enableProductionDowntime}
              clearOnEdit
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{createLabel('productionEqualsElapsed')}</IonLabel>
            <IonToggle
              checked={state.item.productionEqualsElapsed}
              onIonChange={(e) => setItemValue('productionEqualsElapsed', e.detail.checked)}
              disabled={readOnly || !enableProductionEqualsElapsedCheckbox}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{createLabel('notes')}</IonLabel>
            <IonTextarea
              value={state.item.notes}
              onIonInput={(e) => setItemValue('notes', e.target.value)}
              rows={3}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
              autoGrow
            />
          </IonItem>
          <Comments
            label={t('actionTaken')}
            title={t('addActionTaken')}
            value={state.item.actionTaken}
            onChange={(value) => setItemValue('actionTaken', value)}
            disabled={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="instructions" tab={t('instructions')} forceRender>
          <IonItem lines="none">
            <IonLabel position="stacked">{t('instructions')}</IonLabel>
            <IonTextarea
              value={state.item.instructions}
              onIonInput={(e) => setItemValue('instructions', e.target.value)}
              placeholder={PLACEHOLDER}
              rows={10}
              disabled={readOnly}
              autoGrow
            />
          </IonItem>
        </Tabs.TabPane>
        {state.item.assetId ? (
          <Tabs.TabPane key="loto" tab={t('loto')} forceRender>
            <IonItem lines="none">
              <IonLabel position="stacked">{t('loto')}</IonLabel>
              <IonTextarea
                value={state.item.assetLOTONotes}
                placeholder={PLACEHOLDER}
                rows={10}
                autoGrow
                readonly
              />
            </IonItem>
          </Tabs.TabPane>
        ) : null}
        <Tabs.TabPane key="checklist" tab={t('checklist')} forceRender>
          <Checklist
            items={state.checklistItems ?? []}
            onChange={(values) => setState('checklistItems', prioritizeChecklistItems(values))}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/checklist/${item.id}`)
            }}
            parentRecord={{ ...state.item, checklistItems: state.checklistItems }}
            showSwitches={state.showSwitches}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="labour" tab={t('labour')} forceRender>
          <Labour
            items={state.labourItems ?? []}
            onChange={(values) => setState('labourItems', values)}
            onClick={(item) => {
              setSessionItem(getStorageKey(), {
                readOnly,
                ...omit(state, ['popoverIsOpen', 'popoverEvent']),
              })
              props.history.push(`${props.match.url}/labour/${item.id}`)
            }}
            readOnly={readOnly}
          />
        </Tabs.TabPane>
        {state.item.assetId ? (
          <Tabs.TabPane key="tolerances" tab={t('tolerances')} forceRender>
            <Tolerances
              settingsType="jobs"
              storageKey="jobs.formView.tolerances"
              items={state.toleranceItems ?? []}
              onChange={(values) => setState('toleranceItems', values)}
              onClick={(item) => {
                setSessionItem(getStorageKey(), {
                  readOnly,
                  ...omit(state, ['popoverIsOpen', 'popoverEvent']),
                })
                props.history.push(`${props.match.url}/tolerances/${item.id}`)
              }}
              readOnly={readOnly}
            />
          </Tabs.TabPane>
        ) : null}
        {state.item.assetId ? (
          <Tabs.TabPane key="warranty" tab={t('warranty')} forceRender>
            <Warranties
              storageKey="jobs.formView.warranties"
              items={state.warrantyItems ?? []}
              onChange={(values) => setState('warrantyItems', values)}
              onClick={(item) => {
                setSessionItem(getStorageKey(), {
                  readOnly,
                  ...omit(state, ['popoverIsOpen', 'popoverEvent']),
                })
                props.history.push(`${props.match.url}/warranties/${item.id}`)
              }}
              readOnly={readOnly}
            />
          </Tabs.TabPane>
        ) : null}
        <Tabs.TabPane key="groups" tab={t('groups')} forceRender>
          <IonItem
            lines="full"
            onClick={() =>
              setItemValue(
                'jobGroupIds',
                state.item.jobGroupIds.length === state.jobGroups.length
                  ? []
                  : state.jobGroups.map((each) => each.id)
              )
            }
          >
            <IonLabel color="medium">{t('memberOf')}</IonLabel>
            <IonLabel color="medium" className="ion-text-right">
              {state.item.jobGroupIds.length} {t('selected')}&nbsp;&nbsp;
              <Icon.Check checked={state.item.jobGroupIds.length === state.jobGroups.length} />
            </IonLabel>
          </IonItem>
          {state.jobGroups.map((each) => (
            <IonItem
              key={each.id}
              lines="full"
              onClick={() =>
                updateState((draft) => {
                  const index = draft.item.jobGroupIds.findIndex((one) => one === each.id)
                  if (index > -1) {
                    draft.item.jobGroupIds.splice(index, 1)
                  } else {
                    draft.item.jobGroupIds.unshift(each.id)
                  }
                })
              }
            >
              <IonLabel>{each.description}</IonLabel>
              <IonLabel className="ion-text-right">
                <Icon.Check checked={state.item.jobGroupIds.includes(each.id)} />
              </IonLabel>
            </IonItem>
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
          <Documents
            domainObjectId={state.item.id}
            domainObjectType="Job"
            items={state.documentItems ?? []}
            onChange={(values) => setState('documentItems', values)}
          />
        </Tabs.TabPane>
        {state.item.id ? (
          <Tabs.TabPane key="lists" tab={t('lists')} forceRender>
            <Lists
              entityType="Job"
              entityId={state.item.id}
              domainObjectType="Job"
              locationId={state.item.locationId}
              readOnly={readOnly}
              onFetch={(items) => setState('listItems', items)}
            />
          </Tabs.TabPane>
        ) : null}
        <Tabs.TabPane key="custom" tab={t('custom')} forceRender>
          {range(1, 6).map((each) => (
            <IonItem key={each} lines="full">
              <IonLabel position="stacked">{createLabel(`custom${each}`)}</IonLabel>
              <IonInput
                value={state.item[`custom${each}`]}
                onIonInput={(e) => setItemValue(`custom${each}`, e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
          ))}
        </Tabs.TabPane>
      </Tabs>
      <FileUpload
        isOpen={state.fileUploadIsOpen}
        onCancel={() => setState('fileUploadIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            draft.documentItems = uniqBy([fileList[0], ...state.documentItems], 'id')
            draft.fileUploadIsOpen = false
          })
        }
      />
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {actionsMenuItems}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import jobActions from 'actions/jobs'
import Component from 'components/DraftOrder/Items/FormView/SelectJob'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(jobActions.getOptions({ ...params })).then((r) =>
        produce(r, (draft) => {
          if (params.pageIndex <= 1 && !params.search) {
            draft.value.data.items.unshift({ id: 0, displayName: '' })
            draft.value.data.recordCount++
          }
        })
      ),
  }),
})(Component)

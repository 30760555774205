import produce from 'immer'
import { isNil, toLower, floor } from 'lodash'
import { getSharedItem, getSharedItemNoScope, setSharedItem, setSharedItemNoScope } from 'helpers/localForage'
import { getSessionItem } from 'helpers/sessionStorage'
import { getHoursPassed, formatDateTime } from 'helpers/dateTime'
import { t } from 'helpers/i18n'
import { AUTH_SESSION_KEY } from 'options/auth'
import { setLoadingMessage, setLoadingWithPrefix } from 'helpers/utils'
import entityNames from 'options/entityNames'
import { showError } from 'helpers/errors'

export const DOWNLOAD_BATCH_SIZE = 750

export const getDownloadTypeTimestamp = (type) => getSharedItem(`offlineData.${type}.timestamp`)

export const getCustomerDownloadTimestamp = (customerId) =>
  getSharedItem(`impersonations.${customerId}.timestamp`)

export const getDownloadTimestampInfo = ({ props, item } = {}) => {
  const { maxDataAgeHours = 0 } = props.scannerSettings
  const downloadExpired = maxDataAgeHours > 0 && getHoursPassed(item.downloadTimestamp) > maxDataAgeHours
  const downloadNever = isNil(item.downloadTimestamp)
  const downloadTimestamp = formatDateTime(item.downloadTimestamp, { showTime: true })

  return {
    downloadTimestampColor: item.downloadSuccessful
      ? 'success'
      : downloadExpired || downloadNever
        ? 'danger'
        : 'medium',
    downloadTimestampLabel: downloadNever
      ? t('never')
      : downloadExpired
        ? `${downloadTimestamp} - ${t('expired')}`
        : downloadTimestamp,
  }
}

export const createHandleProgress = (entityName) => {
  setLoadingMessage(`${t('downloading')} ${t(entityName)} (0%)`, true)

  return (e) =>
    setLoadingMessage(`${t('downloading')} ${t(entityName)} (${floor((e.loaded * 100) / e.total)}%)`, true)
}

export const createHandleProgressWithPrefix = (prefix, entityName) => {
  setLoadingWithPrefix(prefix, `${t('downloading')} ${t(entityName)} (0%)`, true)

  return (e) =>
    setLoadingWithPrefix(
      prefix,
      `${t('downloading')} ${t(entityName)} (${floor((e.loaded * 100) / e.total)}%)`,
      true
    )
}

const createSetItems = (key) => (items) => setSharedItem(`offlineData.${key}`, items)

const createGetItems = (key) => () => getSharedItem(`offlineData.${key}`, [])

const createSetSharedItems = (key) => (items) => {
  const { subdomain, tenantType } = getSessionItem(AUTH_SESSION_KEY).current.tenant
  const tenantTypeIsDcrib = toLower(tenantType) === 'dcrib'

  return tenantTypeIsDcrib
    ? setSharedItemNoScope(`${subdomain}.0.offlineData.${key}`, items)
    : setSharedItem(`offlineData.${key}`, items)
}

const createGetSharedItems = (key) => () => {
  const { subdomain, tenantType } = getSessionItem(AUTH_SESSION_KEY).current.tenant
  const tenantTypeIsDcrib = toLower(tenantType) === 'dcrib'

  return tenantTypeIsDcrib
    ? getSharedItemNoScope(`${subdomain}.0.offlineData.${key}`, [])
    : getSharedItem(`offlineData.${key}`, [])
}

export const setSharedAssetCategories = createSetItems('assetCategories')
export const getSharedAssetCategories = createGetItems('assetCategories')

export const setSharedAssets = createSetItems('assets')
export const getSharedAssets = createGetItems('assets')

export const setSharedAssetOptions = createSetItems('assets.options')
export const getSharedAssetOptions = createGetItems('assets.options')

export const setSharedJobs = createSetItems('jobs')
export const getSharedJobs = createGetItems('jobs')

export const setSharedJobOptions = createSetItems('jobs.options')
export const getSharedJobOptions = createGetItems('jobs.options')

export const setSharedJobGroupOptions = createSetItems('jobGroups.options')
export const getSharedJobGroupOptions = createGetItems('jobGroups.options')

export const setSharedJobStatusOptions = createSetItems('jobStatus.options')
export const getSharedJobStatusOptions = createGetItems('jobStatus.options')

export const setSharedJobReasonOptions = createSetItems('jobReasons.options')
export const getSharedJobReasonOptions = createGetItems('jobReasons.options')

export const setSharedJobReasonGroupOptions = createSetItems('jobReasonGroups.options')
export const getSharedJobReasonGroupOptions = createGetItems('jobReasonGroups.options')

export const setSharedOrders = createSetItems('orders')
export const getSharedOrders = createGetItems('orders')

export const setSharedUserOptions = createSetItems('users.options')
export const getSharedUserOptions = createGetItems('users.options')

export const setSharedOperatorOptions = createSetItems('operators.options')
export const getSharedOperatorOptions = createGetItems('operators.options')

export const setSharedLocations = createSetItems('locations')
export const getSharedLocations = createGetItems('locations')

export const setSharedLocationGroups = createSetItems('locationGroups')
export const getSharedLocationGroups = createGetItems('locationGroups')

export const setSharedInventory = createSetItems('inventory')
export const getSharedInventory = createGetItems('inventory')

export const setSharedProducts = createSetSharedItems('products')
export const getSharedProducts = createGetSharedItems('products')

export const setSharedSuppliers = createSetSharedItems('suppliers')
export const getSharedSuppliers = createGetSharedItems('suppliers')

export const setSharedSupplierOptions = createSetSharedItems('suppliers.options')
export const getSharedSupplierOptions = createGetSharedItems('suppliers.options')

const getTrimmer = (entityName) => {
  switch (entityName) {
    case entityNames.inventory:
      return ({
        id,
        barcode,
        barcode2,
        barcode3,
        binLocation,
        inventoryBarcode,
        inventoryId,
        description,
        itemDescription,
        itemNumber,
        itemPackageSize,
        itemSupplierId,
        locationId,
        locationName,
        lastCycleCount,
        lockedForCycleCount,
        max,
        min,
        mustCount,
        onHand,
        onHand2,
        onOrder,
        packageSizeUom,
        price,
        productId,
        supplierId,
      }) => ({
        id,
        barcode,
        barcode2,
        barcode3,
        binLocation,
        inventoryBarcode,
        inventoryId,
        description,
        itemDescription,
        itemNumber,
        itemPackageSize,
        itemSupplierId,
        locationId,
        locationName,
        lastCycleCount,
        lockedForCycleCount,
        max,
        min,
        mustCount,
        onHand,
        onHand2,
        onOrder,
        packageSizeUom,
        price,
        productId,
        supplierId,
      })

    case entityNames.products:
      return ({
        id,
        barcode,
        description,
        itemNumber,
        packageSize,
        packageSizeUom,
        price,
        productId,
        unitOfMeasureDescription,
      }) => ({
        id,
        barcode,
        description,
        itemNumber,
        packageSize,
        packageSizeUom,
        price,
        productId,
        unitOfMeasureDescription,
      })

    case entityNames.locations:
      return ({
        id,
        barcode,
        cartType,
        description,
        displayName,
        itemsAtLocation,
        lastCount,
        locationGroupIds,
        locationId,
        locationType,
        minPar,
        name,
      }) => ({
        id,
        barcode,
        cartType,
        description,
        displayName,
        itemsAtLocation,
        lastCount,
        locationGroupIds,
        locationId,
        locationType,
        minPar,
        name,
      })

    default:
      return (each) => each
  }
}

export const trimMobileDownloadItems = (entityName) => (response) => {
  const trimmer = getTrimmer(entityName)

  return produce(response, (draft) => {
    draft.value.data.items = draft.value.data.items.map(trimmer)
  })
}

export const createCheckOfflineInventory =
  ({ props, updateState, isOnline }) =>
  async () => {
    if (!isOnline) {
      try {
        const timestamp = await getSharedItem('offlineData.inventory.timestamp')

        if (isNil(timestamp)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('noInventoryDataFound')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    }
  }

export const createCheckOfflineJobs =
  ({ props, updateState, isOnline }) =>
  async () => {
    if (!isOnline) {
      try {
        const timestamp = await getSharedItem('offlineData.jobs.timestamp')

        if (isNil(timestamp)) {
          updateState((draft) => {
            draft.alertIsOpen = true
            draft.alertMessage = t('noJobDataFound')
            draft.alertButtons = [
              {
                text: t('ok'),
                role: 'cancel',
                handler: () => props.history.goBack(),
              },
            ]
          })
        }
      } catch (error) {
        showError({ error })
      }
    }
  }

/* eslint-disable max-params */
import * as React from 'react'
import styled from 'styled-components'
import { isNil } from 'lodash'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton } from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import Help from 'elements/Help'

const SPACE = 16
const HEADER = 45
const PADDING_V = 0.3 // vertical padding pct between view and work frame
const PADDING_H = 0.2 // horizontal padding pct between view and work frame
const CONSTRAINS = {
  audio: false,
  video: {
    facingMode: 'environment',
    width: { min: 800, ideal: 1280, max: 1920 },
    height: { min: 600, ideal: 720, max: 1080 },
  },
}

const Container = styled.div`
  padding: ${SPACE}px;
  padding-bottom: 0;
  width: 100%;
  @media screen and (orientation: portrait) {
    min-height: calc((100vh * 50 / 100) - ${HEADER}px);
  }
  @media screen and (orientation: landscape) {
    min-height: calc(100vh - ${HEADER}px);
  }
  canvas {
    width: 100%;
  }
`

let raf = null // requestAnimationFrame handle

export default function (props) {
  const contRef = React.useRef()
  const viewRef = React.useRef()
  const workRef = React.useRef()
  const videoRef = React.useRef()
  const barcodeDetector = new window.BarcodeDetector()

  async function startScanner() {
    const $video = videoRef.current

    try {
      const stream = await navigator.mediaDevices.getUserMedia(CONSTRAINS)
      window.stream = stream // make stream available to browser console
      $video.srcObject = stream

      raf = null

      setCanvasSizes()
      drawFrame()
      doScan()
    } catch (error) {
      showError({ error })
    }
  }

  function stopScanner($video) {
    try {
      if (!isNil(raf)) {
        window.cancelAnimationFrame(raf)
        raf = null
      }

      if ($video.srcObject) {
        $video.srcObject.getTracks().forEach((track) => track.stop())
        $video.srcObject = null
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function doScan() {
    const $workCanvas = workRef.current

    if (isNil($workCanvas)) {
      return
    }

    try {
      const results = await barcodeDetector.detect($workCanvas)

      handleDecodeResult(results)
    } catch (error) {
      showError({ error })
    }
  }

  function drawFrame() {
    const $viewCanvas = viewRef.current
    const $workCanvas = workRef.current

    if (isNil($viewCanvas) || isNil($workCanvas)) {
      return
    }

    const ctx = $viewCanvas.getContext('2d')
    const wCtx = $workCanvas.getContext('2d')

    drawVideo($viewCanvas, ctx)

    // copy from view to work canvas - before drawing overlays
    const dw = $workCanvas.width
    const dh = $workCanvas.height
    const sw = dw
    const sh = dh
    const sx = $viewCanvas.width * PADDING_H
    const sy = $viewCanvas.height * PADDING_V

    wCtx.drawImage($viewCanvas, sx, sy, sw, sh, 0, 0, dw, dh)
    drawLine($viewCanvas, ctx)
    drawMask($viewCanvas, ctx, sx, sy, sw, sh)

    // keep the cycle going
    raf = window.requestAnimationFrame(drawFrame)
  }

  function drawVideo($canvas, ctx) {
    const $video = videoRef.current
    const dw = $canvas.width
    const dh = $canvas.height
    const sw = $video.videoWidth
    const sh = Math.ceil((dh / dw) * sw)
    const sx = Math.floor(($video.videoWidth - sw) / 2)
    const sy = Math.floor(($video.videoHeight - sh) / 2)

    ctx.drawImage($video, sx, sy, sw, sh, 0, 0, dw, dh)
  }

  function drawLine($canvas, ctx) {
    const dw = $canvas.width
    const dh = $canvas.height

    ctx.strokeStyle = 'rgba(255, 0, 0, 0.9)'
    ctx.beginPath()
    ctx.moveTo(dw * PADDING_H, dh * 0.5)
    ctx.lineTo(dw * (1 - PADDING_H), dh * 0.5)
    ctx.stroke()
  }

  function drawMask($canvas, ctx, x, y, w, h) {
    const ow = $canvas.width // outer width
    const oh = $canvas.height // outer height
    const vd = (oh - h) / 2 // vertical delta
    const hd = (ow - w) / 2 // horizontal delta

    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'
    ctx.fillRect(0, 0, ow, vd)
    ctx.fillRect(0, oh - vd, ow, vd)
    ctx.fillRect(0, vd, hd, h)
    ctx.fillRect(ow - hd, vd, hd, h)
  }

  function setCanvasSizes() {
    const $container = contRef.current
    const $viewCanvas = viewRef.current
    const $workCanvas = workRef.current
    const $video = videoRef.current
    const portrait = window.innerHeight > window.innerWidth
    const containerWidth = $container.offsetWidth + SPACE
    const containerHeight = (window.innerHeight * (portrait ? 50 : 100)) / 100 - HEADER - SPACE
    const videoWidth = $video.videoWidth
    // const videoHeight = $video.videoHeight

    $viewCanvas.width = Math.min(Math.max(containerWidth, videoWidth), 1280)
    $viewCanvas.height = ($viewCanvas.width * containerHeight) / containerWidth
    $workCanvas.width = $viewCanvas.width * (1 - 2 * PADDING_H)
    $workCanvas.height = $viewCanvas.height * (1 - 2 * PADDING_V)
  }

  function handleDecodeResult(results = []) {
    if (results?.length !== 1) {
      doScan()
      return
    }

    props.onScan?.(results[0])

    // cancel next scan
    if (raf !== null) {
      window.cancelAnimationFrame(raf)
      raf = null
    }
  }

  React.useEffect(() => {
    const $video = videoRef.current
    $video.addEventListener('playing', setCanvasSizes)
    window.addEventListener('resize', setCanvasSizes)
    window.setTimeout(() => startScanner(), 250)

    return () => {
      $video.removeEventListener('playing', setCanvasSizes)
      window.removeEventListener('resize', setCanvasSizes)
      stopScanner($video)
    }
  }, [])

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => {}}>
              <Help title={t('scanBarcodeHelp')} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('scanBarcode')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={props.onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <Container ref={contRef}>
          <canvas ref={viewRef}></canvas>
          <canvas ref={workRef} className="ion-hide w-full"></canvas>
          <video ref={videoRef} className="ion-hide" playsInline autoPlay></video>
        </Container>
      </IonContent>
    </>
  )
}

import { without } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { message, PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { formatDateTime } from 'helpers/dateTime'
import { showError, showClientNotifications } from 'helpers/errors'
import { setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Filter from 'containers/Assets/ListView/Filter'
import Icon from 'elements/Icon'

export default createListViewComponent({
  getStorageKey: () => 'assets.listView',
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      lines="full"
      routerLink={self.state.rowActionsIsOpen ? undefined : `${self.props.match.url}/${item.id}`}
      onClick={
        self.state.rowActionsIsOpen
          ? () => {
              self.updateState((draft) => {
                draft.selectedRowKeys = draft.selectedRowKeys.includes(item.id)
                  ? without(draft.selectedRowKeys, item.id)
                  : [...draft.selectedRowKeys, item.id]
              })
            }
          : undefined
      }
      detail={!self.state.rowActionsIsOpen}
    >
      <IonLabel>
        {item.barcode}
        <br />
        <IonText color="medium">
          <small>
            {item.description}
            <br />
            {t('category:')} {item.assetCategoryName || t('none')}
            <br />
            {t('location:')} {item.locationName || PLACEHOLDER}, {t('subLocation:')}{' '}
            {item.binLocation || PLACEHOLDER}
            <br />
            {t('lastActivity:')} {formatDateTime(item.lastActivityDate, { showTime: true })}, {t('status:')}{' '}
            {item.statusName}
          </small>
        </IonText>
      </IonLabel>
      {self.state.rowActionsIsOpen ? (
        <Icon.Check
          color={self.state.selectedRowKeys.includes(item.id) ? 'secondary' : 'dark'}
          checked={self.state.selectedRowKeys.includes(item.id)}
        />
      ) : null}
    </IonItem>
  ),
  getItemSize: () => 111,
  allowSearch: (self) => true,
  storeSearch: (self) => true,
  allowDelete: (self) => (item) => getUserPermission('Assets') === 'Edit',
  allowOffline: (self) => false,
  getPopoverContent: (self) =>
    getUserPermission('Assets') === 'Edit' ? (
      <IonItem
        lines="full"
        onClick={() => {
          self.updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
          self.props.history.push(`${self.props.match.url}/0`)
        }}
      >
        <IonLabel>{t('createAsset')}</IonLabel>
      </IonItem>
    ) : null,
  getFooter: (self) =>
    getUserPermission('Assets') === 'Edit' ? (
      <IonButton
        color="secondary"
        expand="full"
        onClick={() => {
          self.props.history.push(`${self.props.match.url}/0`)
        }}
        disabled={!self.isOnline}
      >
        {t('createAsset')}
      </IonButton>
    ) : null,
  getSortByFields: (self) => [
    { fieldName: 'barcode', languageKey: 'assetId' },
    { fieldName: 'assetCategoryName', languageKey: 'assetCategory' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'lastActivityDate', languageKey: 'lastActivity' },
    { fieldName: 'statusName', languageKey: 'status' },
  ],
  getRowActionItems: (self) =>
    [
      getUserPermission('Assets') === 'Edit' ? { key: 'delete', underline: true } : undefined,
      { key: 'createJob', underline: true, maxSelectedRowKeys: 1 },
      { key: 'checkIn', underline: false },
      { key: 'move', underline: false },
    ].filter(Boolean),
  getRowActionHandler: (self) => async (rowActionKey, selectedRowKeys) => {
    // console.log({ rowActionKey, selectedRowKeys })
    switch (rowActionKey) {
      case 'delete': {
        const buttons = [
          { text: t('cancel'), role: 'cancel' },
          {
            text: t('delete'),
            handler: async () => {
              try {
                const response = await self.props.deleteItems(selectedRowKeys)

                showClientNotifications({ response })
                self.setState('selectedRowKeys', [])
                self.prefetchItems()
              } catch (error) {
                showError({ error })
              }
            },
          },
        ]

        self.updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmDeleteSelectedItems')
          draft.alertButtons = buttons
        })
        break
      }

      case 'createJob': {
        const {
          locationId = 0,
          locationName = '',
          id: assetId,
          name: assetName,
        } = self.state.items.find((one) => one.id === selectedRowKeys[0])

        self.updateState((draft) => {
          draft.rowActionKey = ''
          draft.selectedRowKeys = []
        })
        setSessionItem('jobs.formView.newItemParams', {
          assetId,
          assetName,
          locationId,
          locationName,
        })
        removeSessionItem('jobs.formView')
        window.location.href = '/jobs/jobs/0'
        break
      }

      case 'checkIn':
        try {
          self.setState('loadingIsOpen', true)

          const response = await self.props.checkinAssets({ assetIds: selectedRowKeys })

          showClientNotifications({ response })

          self.prefetchItems()
        } catch (error) {
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
        break

      case 'move':
        removeSessionItem('assets.listView.moveAssets')
        self.props.history.push(`${self.props.match.url}/moveAssets`)
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  },
})(Filter)

import * as React from 'react'
import styled from 'styled-components'
import { IonText, IonSpinner } from '@ionic/react'
import { get, isNil } from 'lodash'
import { useImmer } from 'use-immer'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { getPrivateItem } from 'helpers/localForage'
import { LOCATION_COUNTS_UPLOAD_RESULTS_KEY } from 'options/locationCounts'
import { strEqual, PLACEHOLDER } from 'helpers/utils'
import Page from 'elements/Page'

const List = styled.dl`
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 50%;
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid var(--ion-border-color);
  }

  dd {
    width: 50%;
    text-align: right;
    padding: 8px 16px 8px 0;
    border-bottom: 1px solid var(--ion-border-color);
  }
`

const fieldSettings = {
  locationName: 'location',
  itemsAtLocation: 'itemsAtLocation',
  itemsCounted: 'itemsCounted',
  itemsAdjusted: 'itemsAdjusted',
  inventoryUpdateStatusDescription: 'inventoryUpdateStatus',
  itemsOrdered: 'itemsOrdered',
  orderId: 'orderID',
  orderStatusDescription: 'orderStatus',
  orderCreateStatusDescription: 'orderUploadStatus',
  id: 'locationCountID',
  inventoryTransferLogId: 'inventoryTransferLogID',
  orderTransferLogId: 'orderTransferLogID',
}

const getColor = (item, dtoFieldName) => {
  if (dtoFieldName === 'inventoryUpdateStatusDescription') {
    return item.inventoryUpdateStatus === 'Success' ? 'success' : 'danger'
  }

  if (dtoFieldName === 'orderCreateStatusDescription') {
    return item.orderCreateStatus === 'Success' ? 'success' : 'danger'
  }

  return undefined
}

export default function (props) {
  const [state, updateState] = useImmer({})

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      const response = await getPrivateItem(LOCATION_COUNTS_UPLOAD_RESULTS_KEY)

      updateState((draft) => {
        draft.item = response.find((one) => strEqual(one.id, itemId))
      })
    } catch (error) {
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItem()
  }, [props.match.params.itemId])

  const pageTitle = t('uploadDetails')

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page title={pageTitle}>
      <List>
        {Object.entries(fieldSettings).map(([dtoFieldName, labelLanguageKey]) => (
          <React.Fragment key={dtoFieldName}>
            <dt>{t(labelLanguageKey)}</dt>
            <dd>
              <IonText color={getColor(state.item, dtoFieldName)}>
                {get(state.item, dtoFieldName) || PLACEHOLDER}
              </IonText>
            </dd>
          </React.Fragment>
        ))}
      </List>
      <p className="ion-margin-horizontal">{state.item.commitResult}</p>
    </Page>
  )
}

import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import actions from 'actions/inventory'
import Component from 'components/Inventory/FormView/Transfer'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getItem: actions.getItem,
  moveItems: actions.moveItems,
  getMoveData: actions.getMoveData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

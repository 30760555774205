import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
  IonTextarea,
  IonToggle,
  IonRow,
  IonCol,
  IonLoading,
  IonAlert,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { set, isNil, isEqual, pick, cloneDeep, isEmpty, omit } from 'lodash'
import useOnlineStatus from '@rehooks/online-status'
import { message, tryParseInt, filterKeys, PLACEHOLDER, asyncSleep } from 'helpers/utils'
import { showError, showValidationErrors } from 'helpers/errors'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { getUserPermission } from 'helpers/auth'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { createSaveImage } from 'helpers/formViews'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import Icon from 'elements/Icon'
import Help from 'elements/Help'
import Comments from 'elements/Comments'
import Image from 'elements/Image'
import FileUpload from 'elements/FileUpload'
import { useSelector } from 'react-redux'
import selectors from 'selectors'
import { getCountryName } from 'helpers/countries'

export const getStorageKey = () => 'inventory.formView'

export default function (props) {
  const isOnline = useOnlineStatus()
  const locale = useSelector(selectors.auth.locale)

  const [state, updateState] = useImmer({ tabsActiveKey: 'inventory' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, `item.${name}`, value)

      if (draft.item.min === 0 && draft.item.max === 0 && props.customer.generalSettings.autoDoNotReorder) {
        draft.item.doNotReorder = true
      }
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    const newItemParams = getSessionItem('inventory.formView.newItemParams')

    if (isNil(newItemParams) && itemId === '0') {
      window.location.href = '/inventory/inventory'
      return
    }

    try {
      const item =
        itemId !== '0'
          ? await props.getItem(itemId).then((r) => cloneDeep(r.value.data))
          : await props.newItem(newItemParams).then((r) => cloneDeep(r.value.data))

      const image =
        item.id && item.productId
          ? await props
              .getDocumentContents({
                request: {
                  domainObjectId: item.productId,
                  domainObjectType: 'Product',
                  documentType: 'ObjectImage',
                  documentName: item.barcode,
                },
              })
              .catch(() => {})
          : null

      updateState((draft) => {
        draft.item = cloneDeep(item)
        draft.itemOriginal = cloneDeep(item)
        draft.image = cloneDeep(image?.value?.data)
        draft.imageOriginal = cloneDeep(image?.value?.data)
      })

      removeSessionItem('inventory.formView.newItemParams')
    } catch (error) {
      showError({ error })
    }
  }

  function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    callback(errors, values)
  }

  function saveItem() {
    const saveImage = createSaveImage({ props, state }, 'Product')

    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        setState('loadingIsOpen', true)

        try {
          const saved = values.id ? await props.updateItem(values) : await props.createItem(values)

          await saveImage(saved.value.data.productId, saved.value.data.barcode)

          if (props.match.params.itemId === '0') {
            await asyncSleep()

            window.location.href = `/inventory/inventory/${saved.value.data.id}`
          } else {
            await fetchItem(saved.value.data.id)
          }
        } catch (error) {
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewWillEnter(() => {
    setState('errors', {})
  })

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  function hasUnsavedChanges() {
    return !state.item?.id || !isEqual(state.item, state.itemOriginal)
  }

  if (isNil(state.item)) {
    return (
      <Page>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const readOnly = !isOnline || props.readOnly || getUserPermission('Inventory') !== 'Edit'

  const doNotReorderDisabled =
    state.item && state.item.min === 0 && state.item.max === 0 && props.customer.generalSettings.autoDoNotReorder

  async function handleActionsMenuClick(key) {
    updateState((draft) => {
      draft.popoverIsOpen = false
      draft.popoverEvent = null
    })

    switch (key) {
      case 'issueThisItem':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          setSessionItem('issue.issueItem', {
            quickscan: true,
            requireQuantity: true,
            showTagFields: true,
            inventory: state.item,
            item: {
              barcode: state.item.barcode,
              inventoryBarcode: state.item.inventoryBarcode,
              locationId: state.item.locationId,
              locationDisplayName: state.item.locationName,
              operatorId: props.user.operatorId,
              operatorDisplayName: props.user.operatorName,
              operatorBarcode: props.user.operatorBarcode,
              quantityIssued: 1,
              userName: props.user.userName,
            },
          })
          window.location.href = '/inventory/issue/issueItem'
        }
        break

      case 'orderThisItem':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          try {
            const [newOrder, fieldSettings, product] = await Promise.all([
              props.newOrder().then((r) => r.value.data),
              props.getOrderSettings().then((r) => r.value.data.fieldSettings),
              props.getProduct(state.item.productId).then((r) => r.value.data),
            ])
            setSessionItem('orders.formView', {
              tabsActiveKey: 'items',
              loadingIsOpen: false,
              fieldSettings,
              item: newOrder,
              itemOriginal: cloneDeep(newOrder),
              orderItems: [],
              orderItemsOriginal: [],
              orderSupplierItems: [],
              orderSupplierItemsOriginal: [],
              documentItems: [],
              documentItemsOriginal: [],
              orderItemsFilterDto: {},
            })
            setSessionItem('orders.addItems', {
              quickscan: true,
              requireQuantity: true,
              showTagFields: true,
              product,
              item: {
                barcode: product.barcode,
                locationId: state.item.locationId,
                locationDisplayName: state.item.locationName,
                operatorId: props.user.operatorId,
                operatorDisplayName: props.user.operatorName,
                operatorBarcode: props.user.operatorBarcode,
                quantityOrdered: 1,
                userName: props.user.userName,
              },
            })
            window.location.href = '/orders/orders/0/addItems'
          } catch (error) {
            showError({ error })
          }
        }
        break

      case 'transferToLocation':
        if (hasUnsavedChanges()) {
          message.error(t('saveChangesFirst'))
        } else {
          props.history.push(`${props.match.url}/transferToLocation`)
        }
        break

      case 'uploadImage':
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmUploadProductImage')
          draft.alertButtons = [
            { text: t('cancel'), role: 'cancel' },
            {
              text: t('ok'),
              handler: () => {
                setState('uploadImageIsOpen', true)
              },
            },
          ]
        })
        break

      case 'removeImage':
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmRemoveProductImage')
          draft.alertButtons = [
            { text: t('cancel'), role: 'cancel' },
            {
              text: t('ok'),
              handler: () => {
                setState('image', null)
              },
            },
          ]
        })
        break

      default:
        message.info(t('underDevelopment'))
        break
    }
  }

  const actionsMenuItems = (function () {
    if (!state.item.id) {
      return []
    }

    const defaultItems = [
      <IonItem key="orderThisItem" lines="none" onClick={() => handleActionsMenuClick('orderThisItem')}>
        <IonLabel>{t('orderThisItem')}</IonLabel>
      </IonItem>,
      <IonItem
        key="issueThisItem"
        lines="full"
        onClick={() => handleActionsMenuClick('issueThisItem')}
        disabled={state.item.lockedForCycleCount}
      >
        <IonLabel>{t('issueThisItem')}</IonLabel>
      </IonItem>,
      <IonItem
        key="transferToLocation"
        lines="none"
        onClick={() => handleActionsMenuClick('transferToLocation')}
        disabled={readOnly || state.item.lockedForCycleCount}
      >
        <IonLabel>{t('transferToLocation')}</IonLabel>
      </IonItem>,
    ]

    switch (state.tabsActiveKey) {
      case 'image':
        return [
          !readOnly && getUserPermission('Products') === 'Edit' ? (
            <IonItem
              key="uploadImage"
              lines="none"
              onClick={() => handleActionsMenuClick('uploadImage')}
              disabled={!isOnline}
            >
              <IonLabel>{t('uploadImage')}</IonLabel>
            </IonItem>
          ) : null,
          !readOnly && getUserPermission('Products') === 'Edit' ? (
            <IonItem
              key="removeImage"
              lines="full"
              onClick={() => handleActionsMenuClick('removeImage')}
              disabled={isEmpty(state.image) || !isOnline}
            >
              <IonLabel>{t('removeImage')}</IonLabel>
            </IonItem>
          ) : null,
          ...defaultItems,
        ].filter(Boolean)

      default:
        return defaultItems
    }
  })().filter(Boolean)

  const showPricing = props.customer.moduleSettings.enablePricing && getUserPermission('DisplayPricing') === 'Yes'

  return (
    <Page
      title={state.item.barcode}
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
            {t('save')}
          </IonButton>
        )
      }
      toolbarButton={
        !isEmpty(actionsMenuItems) ? (
          <IonButton
            onClick={(e) => {
              updateState((draft) => {
                draft.popoverIsOpen = true
                draft.popoverEvent = e
                draft.popoverContent = actionsMenuItems
              })
            }}
            disabled={!isOnline}
          >
            <Icon type="Menu" size="26" />
          </IonButton>
        ) : null
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="inventory" tab={t('inventory')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('barcode')}</IonLabel>
            <IonInput value={state.item.barcode} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('productDescription')}</IonLabel>
            <IonInput value={state.item.itemDescription} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          {props.useInventoryBarcode && (
            <IonItem lines="full">
              <IonLabel position="stacked">{t('inventoryBarcode')}</IonLabel>
              <IonInput
                value={state.item.inventoryBarcode}
                onIonInput={(e) => setItemValue('inventoryBarcode', e.target.value)}
                disabled={readOnly}
                placeholder={PLACEHOLDER}
              />
            </IonItem>
          )}
          {state.item.id ? (
            <IonItem lines="full">
              <IonLabel position="stacked">{t('location')}</IonLabel>
              <IonInput value={state.item.locationName} placeholder={PLACEHOLDER} disabled />
            </IonItem>
          ) : (
            <IonItem
              lines="full"
              className={cx('tofino-stacked-item tofino-required-item', {
                'tofino-error-item': state.errors?.locationId,
              })}
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectLocation`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>{t('location')}</small>
                </IonText>
                <br />
                {state.item.locationName || state.item.locationId || (
                  <span className="tofino-placeholder">{PLACEHOLDER}</span>
                )}
              </IonLabel>
            </IonItem>
          )}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('binLocation')}</IonLabel>
            <IonInput
              value={state.item.binLocation}
              onIonInput={(e) => setItemValue('binLocation', e.target.value)}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          <IonRow>
            {['onHand', 'onHand2'].map((each) => (
              <IonCol key={each}>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t(each)}</IonLabel>
                  <IonInput
                    type="number"
                    inputmode="number"
                    inputMode="number"
                    value={state.item[each]}
                    onIonInput={(e) => setItemValue(each, tryParseInt(e.target.value, 0))}
                    onKeyPress={filterKeys(/\D/)}
                    disabled={readOnly || state.item.lockedForCycleCount}
                    min={0}
                    placeholder={PLACEHOLDER}
                    clearOnEdit
                  />
                  {state.item.lockedForCycleCount && (
                    <Icon
                      type="Lock"
                      color="danger"
                      size="20"
                      style={{ marginTop: '16px', marginRight: '0' }}
                      slot="end"
                      onClick={(e) =>
                        updateState((draft) => {
                          draft.popoverIsOpen = true
                          draft.popoverEvent = e
                          draft.popoverContent = (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: t('onhandQuantitiesLockedForCycleCount'),
                              }}
                              style={{
                                color: 'var(--ion-color-black-tint)',
                                fontSize: 'var(--tofino-font-size-small)',
                              }}
                              className="ion-margin"
                            />
                          )
                        })
                      }
                    />
                  )}
                </IonItem>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('minReorderPoint')}</IonLabel>
                <IonInput
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  value={state.item.min}
                  onIonInput={(e) => setItemValue('min', tryParseInt(e.target.value, 0))}
                  onKeyPress={filterKeys(/\D/)}
                  disabled={readOnly}
                  min={0}
                  placeholder={PLACEHOLDER}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('maxReorderTarget')}</IonLabel>
                <IonInput
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  value={state.item.max}
                  onIonInput={(e) => setItemValue('max', tryParseInt(e.target.value, 0))}
                  onKeyPress={filterKeys(/\D/)}
                  disabled={readOnly}
                  min={0}
                  placeholder={PLACEHOLDER}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
          </IonRow>
          {readOnly ? (
            <IonItem lines="full">
              <IonLabel position="stacked">
                {t('preferredSupplier')} <Help title={t('inventoryPreferredSupplierInfo')} size="17" />
              </IonLabel>
              <IonInput value={!state.item.supplierId ? t('notAssigned') : state.item.supplierName} disabled />
            </IonItem>
          ) : (
            <IonItem
              lines="full"
              className="tofino-stacked-item"
              onClick={() => {
                setSessionItem(getStorageKey(), pick(state, ['item']))
                props.history.push(`${props.match.url}/selectSupplier`)
              }}
              button
            >
              <IonLabel>
                <IonText color="medium">
                  <small>
                    {t('preferredSupplier')} <Help title={t('inventoryPreferredSupplierInfo')} size="17" />
                  </small>
                </IonText>
                <br />
                {state.item.supplierName || state.item.supplierId || t('notAssigned')}
              </IonLabel>
            </IonItem>
          )}
          <IonItem lines="full">
            <IonLabel position="stacked">{t('accountNumber')}</IonLabel>
            <IonInput
              value={state.item.accountNumber}
              onIonInput={(e) => setItemValue('accountNumber', e.target.value)}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('inventoryDescription')}</IonLabel>
            <IonTextarea
              value={state.item.description}
              onIonInput={(e) => setItemValue('description', e.target.value)}
              rows={1}
              disabled={readOnly}
              placeholder={PLACEHOLDER}
              autoGrow
            />
          </IonItem>
          <Comments
            value={state.item.customerComment}
            onChange={(value) => setItemValue('customerComment', value)}
            disabled={readOnly}
          />
          <IonItem lines="full">
            <IonLabel>
              {t('doNotReorder')} <Help title={t('inventoryDoNotReorderInfo')} />
            </IonLabel>
            <IonToggle
              checked={state.item.doNotReorder}
              onIonChange={(e) => setItemValue('doNotReorder', e.detail.checked)}
              disabled={readOnly || doNotReorderDisabled}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              {t('overrideQuantity')} <Help title={t('inventoryOverrideQuantityInfo')} />
            </IonLabel>
            <IonToggle
              checked={state.item.overrideQuantity}
              onIonChange={(e) => setItemValue('overrideQuantity', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>
              {t('setUsageEqualOrder')} <Help title={t('inventoryUsageEqualsOrderInfo')} />
            </IonLabel>
            <IonToggle
              checked={state.item.setUsageEqualOrder}
              onIonChange={(e) => setItemValue('setUsageEqualOrder', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('critical')}</IonLabel>
            <IonToggle
              checked={state.item.criticalItem}
              onIonChange={(e) => setItemValue('criticalItem', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('mustCount')}</IonLabel>
            <IonToggle
              checked={state.item.mustCount}
              onIonChange={(e) => setItemValue('mustCount', e.detail.checked)}
              disabled={readOnly || (props.tenantGroupIsCardinal && state.item.cartType === 'REQ')}
            />
          </IonItem>
        </Tabs.TabPane>
        <Tabs.TabPane key="item" tab={t('item')} forceRender>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('barcode')}</IonLabel>
            <IonInput value={state.item.barcode} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('productDescription')}</IonLabel>
            <IonInput value={state.item.itemDescription} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('itemNumber')}</IonLabel>
            <IonInput value={state.item.itemNumber} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('manufacturer')}</IonLabel>
            <IonInput value={state.item.manufacturer} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('preferredSupplier')}</IonLabel>
            <IonInput value={state.item.itemSupplierName} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('countryOfOrigin')}</IonLabel>
            <IonInput value={getCountryName(state.item.originCountryCode, locale)} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('package')}</IonLabel>
                <IonInput value={state.item.packageSizeUom} placeholder={PLACEHOLDER} disabled />
              </IonItem>
            </IonCol>
            {showPricing ? (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('price')}</IonLabel>
                  <IonInput value={toLocaleCurrency(state.item.basePrice)} placeholder={PLACEHOLDER} disabled />
                </IonItem>
              </IonCol>
            ) : null}
          </IonRow>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('newRework')}</IonLabel>
            <IonInput value={state.item.reworkTypeDescription} placeholder={PLACEHOLDER} disabled />
          </IonItem>
          <IonItem lines="full" className="ion-margin-bottom">
            <IonLabel position="stacked">{t('expires')}</IonLabel>
            <IonInput value={state.item.expiresDescription} placeholder={PLACEHOLDER} disabled />
          </IonItem>
        </Tabs.TabPane>
        {isOnline && (
          <Tabs.TabPane key="image" tab={t('image')} forceRender>
            <Image value={state.image} />
          </Tabs.TabPane>
        )}
      </Tabs>
      <IonPopover
        isOpen={state.popoverIsOpen}
        event={state.popoverEvent}
        onDidDismiss={() =>
          updateState((draft) => {
            draft.popoverIsOpen = false
            draft.popoverEvent = null
          })
        }
      >
        {state.popoverContent}
      </IonPopover>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
      <FileUpload
        title={null}
        label={t('uploadImage')}
        hint={t('uploadImageHintMobile')}
        isOpen={state.uploadImageIsOpen}
        onCancel={() => setState('uploadImageIsOpen', false)}
        onUpload={(fileList) =>
          updateState((draft) => {
            if (fileList[0]) {
              draft.image = {
                contents: fileList[0].fileContents,
                fileType: fileList[0].fileType,
              }
            }

            draft.uploadImageIsOpen = false
          })
        }
        accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/png,image/gif"
      />
    </Page>
  )
}

import { IonItem, IonLabel } from '@ionic/react'
import { t } from 'helpers/i18n'

export default function (props) {
  const { filterItems = () => true } = props

  return [
    <IonItem
      key="selectAll"
      lines="none"
      onClick={() => {
        props.updateState((draft) => {
          draft.selectedRowKeys = draft.items.filter(filterItems).map((each) => each.id)
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
      }}
      detail={false}
      button
    >
      <IonLabel>{t('selectAll')}</IonLabel>
    </IonItem>,
    <IonItem
      key="deselectAll"
      lines="none"
      onClick={() => {
        props.updateState((draft) => {
          draft.selectedRowKeys = []
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })
      }}
      detail={false}
      button
    >
      <IonLabel>{t('deselectAll')}</IonLabel>
    </IonItem>,
  ]
}

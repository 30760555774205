import { memoize } from 'lodash'
import { Switch, Redirect, Route } from 'react-router-dom'
import { restricted } from 'helpers/auth'
import Login from 'containers/Login'
import NotFound from 'containers/NotFound'
import Orders from './Orders'
import Inventory from './Inventory'
import Jobs from './Jobs'
import Assets from './Assets'
import Distributor from './Distributor'
import More from './More'
import Welcome from './Welcome'

const createRoutes = () => (
  <Switch>
    <Redirect from="/" to="/welcome" exact />
    <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
    <Route path="/login" component={Login} exact />
    <Route path="/welcome" component={restricted(Welcome)} />
    <Route path="/orders" component={restricted(Orders)} />
    <Route path="/inventory" component={restricted(Inventory)} />
    <Route path="/jobs" component={restricted(Jobs)} />
    <Route path="/assets" component={restricted(Assets)} />
    <Route path="/distributor" component={restricted(Distributor)} />
    <Route path="/more" component={restricted(More)} />
    <Route component={NotFound} />
  </Switch>
)

export default memoize(createRoutes, JSON.stringify)

import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import tagListActions from 'actions/tagLists'
import Component from 'components/Orders/AddItems/SelectTagE'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(tagListActions.getItemsOffline()).then((r) =>
        produce(r, (draft) => {
          draft.value.data.items = draft.value.data.items
            .filter((each) => each.tagType === 'E')
            .map((each) => ({
              id: each.description,
              displayName: each.description,
            }))
          draft.value.data.items.unshift({ id: '', displayName: '' })
          draft.value.data.recordCount = draft.value.data.items.length
        })
      ),
  }),
})(Component)

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'itemCondition',
  allowInfiniteLoader: () => false,
  allowSearch: () => false,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.itemCondition,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.itemCondition = each.id
    }),
})()

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitle: () => t('jobTemplate'),
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.jobTaskSettings?.templateId ?? 0,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.jobTaskSettings.templateId = each.id
      draft.item.jobTaskSettings.jobTemplateName = each.displayName || each.name
    }),
})()

import { IonItem, IonLabel, IonText } from '@ionic/react'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createListViewComponent } from 'factories/ListView'
import Header from './Header'

export default createListViewComponent({
  getStorageKey: () => 'locationCounts.finishMustCount',
  pageTitleLanguageKey: 'finishMustCount',
  getSearchHeader: (self) => <Header className="ion-margin tofino-tabular-list mb-0" />,
  renderItem: (self) => (item) => {
    const barcode = self.props.useInventoryBarcode ? item.inventoryBarcode : item.barcode

    return (
      <IonItem
        key={item.id}
        lines="full"
        routerLink={`${self.props.match.url}/${encodeURIComponent(barcode)}`}
      >
        <IonLabel>
          {barcode}
          <br />
          <IonText color="medium">
            <small>{item.itemDescription || PLACEHOLDER}</small>
            {self.props.scannerSettings.showInventoryDescription ? (
              <>
                <br />
                <small>{item.description || PLACEHOLDER}</small>
              </>
            ) : null}
          </IonText>
          <br />
          <IonText color={item.isCounted ? 'success' : 'danger'}>
            <small>{t(item.isCounted ? 'counted' : 'notCounted')}</small>
          </IonText>
        </IonLabel>
      </IonItem>
    )
  },
  allowInfiniteLoader: () => false,
  allowSearch: (self) => true,
})()

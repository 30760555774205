import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import orderActions from 'actions/orders'
import { createChildItemsDispatchToProps, createDocumentsDispatchToProps } from 'helpers/formViews'
import documentActions from 'actions/documents'
import orderItemActions from 'actions/orders/orderItems'
import orderSupplierActions from 'actions/orders/orderSuppliers'
import Component from 'components/DraftOrder'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = (dispatch, props) => ({
  getDraftOrder: () => dispatch(orderActions.getDraftOrder()),
  getItem: (orderId) => dispatch(orderActions.getItem(orderId)),
  updateItem: (params) => dispatch(orderActions.updateItem(params)),
  submitItem: (orderId) => dispatch(orderActions.submitItem(orderId)),
  deleteItem: (orderId) => dispatch(orderActions.deleteItem(orderId)),
  ...createDocumentsDispatchToProps(dispatch, documentActions),
  ...createChildItemsDispatchToProps(dispatch, 'Order', orderItemActions),
  ...createChildItemsDispatchToProps(dispatch, 'OrderSupplier', orderSupplierActions),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { partition } from 'lodash'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'

export default createListViewComponent({
  getStorageKey: () => 'inventory.count.uploadResults',
  pageTitleLanguageKey: 'uploadResults',
  getSearchHeader: ({ props, state, updateState, fetchItems }) => {
    const uploadedItems = state.items ?? []
    const [successful, unsuccessful] = partition(uploadedItems, (each) => each.id !== 0)

    return (
      <dl className="ion-margin tofino-tabular-list">
        <dt>{t('countedItems')}</dt>
        <dd>{uploadedItems.length}</dd>
        <dt>{t('uploadedSuccessfully')}</dt>
        <dd>{successful.length}</dd>
        <dt>{t('uploadedWithErrors')}</dt>
        <dd>{unsuccessful.length}</dd>
      </dl>
    )
  },
  renderItem:
    ({ props, state, updateState, fetchItems }) =>
    (item) => {
      const success = item.id !== 0
      const message = success ? t('quantityHasBeenSaved') : t('unableToUpdateOnhandQuantities')

      return (
        <IonItem
          key={item.id}
          lines="full"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = true
              draft.alertHeader = success ? t('success') : t('error')
              draft.alertMessage = message
            })
          }}
          detail={false}
          button
        >
          <IonLabel>
            {props.useInventoryBarcode ? item.inventoryBarcode || item.barcode : item.barcode}
            <br />
            <small>
              <IonText color="medium">
                {item.description || PLACEHOLDER}
                <br />
                {item.locationName}, {t('counted:')}{' '}
                {`${(props.scannerSettings.showCount2 ? [item.count, item.count2] : [item.count]).join('/')}`}
              </IonText>
              <br />
              {success ? (
                <IonText color="success">{`${t('success')} - ${message}`}</IonText>
              ) : (
                <IonText color="danger">{`${t('error')} - ${message}`}</IonText>
              )}
            </small>
          </IonLabel>
        </IonItem>
      )
    },
  allowInfiniteLoader: () => false,
})()

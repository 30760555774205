import actions from 'actions/tasks'
import { createChildFormViewContainer } from 'factories/ChildFormView'
import jobActions from 'actions/jobs'
import operatorActions from 'actions/operators'
import Component from 'components/Assets/Tasks/FormView'

export default createChildFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    getOperator: (operatorId) => dispatch(operatorActions.getItem(operatorId)),
    getJobTemplate: (jobId) => dispatch(jobActions.getItem(jobId)),
    createFromTemplate: (jobId, params) => dispatch(jobActions.createFromTemplate(jobId, params)),
  }),
})(Component)

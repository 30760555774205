import { createSelectionListViewContainer } from 'factories/SelectionListView'
import labourTypes from 'options/jobLabourTypes'
import { wrapItems } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Component from 'components/Jobs/Labour/FormView/SelectLabourType'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      wrapItems(
        Object.entries(labourTypes)
          .filter(([key, value]) => key !== 'All')
          .map(([key, value]) => ({ id: key, displayName: t(value) }))
      ),
  }),
})(Component)

import { snakeCase } from 'lodash'
import { createChildActions } from 'helpers/actions'
import entityNames from 'options/entityNames'
import { createChildPost } from 'helpers/api'

const entityName = entityNames.cycleCounts
const ENTITY_NAME = snakeCase(entityName).toUpperCase()

const childName = 'cycleCountDetails'
const CHILD_NAME = snakeCase(childName).toUpperCase()

export default {
  ...createChildActions(entityName, childName),

  populateCycleCountItems: (parentId, params) => ({
    type: `${ENTITY_NAME}_${CHILD_NAME}_POPULATE`,
    payload: createChildPost(entityName, childName, { action: 'populate' })(parentId, params),
  }),
}

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonSearchbar,
} from '@ionic/react'
import { useImmer } from 'use-immer'
import { FixedSizeList as List } from 'react-window'
import { isEqual, isEmpty, without, uniq, toUpper } from 'lodash'
import { t } from 'helpers/i18n'
import AutoSizer from 'react-virtualized-auto-sizer'
import Icon from 'elements/Icon'

const ALLOW_SEARCH_LENGTH = 15

export default function (props) {
  const {
    options = [],
    onChange = () => {},
    onCancel = () => {},
    allowMultiple,
    allowSearch = options.length >= (allowMultiple ? ALLOW_SEARCH_LENGTH - 1 : ALLOW_SEARCH_LENGTH),
  } = props

  const [state, updateState] = useImmer({
    search: '',
  })

  const value = allowMultiple ? props.value ?? [] : props.value ?? null
  const searchParts = toUpper(state.search).split(' ').filter(Boolean)
  let items = allowMultiple ? [{ value: [], text: t('all'), isAll: true }, ...options] : options

  if (allowSearch && searchParts) {
    items = items.filter(({ text }) => searchParts.every((part) => toUpper(text).includes(part)))
  }

  const handleItemClick = (each, isAll) => {
    if (allowMultiple) {
      if (isAll) {
        onChange([])
      } else if (value.includes(each)) {
        onChange(uniq(without(value, each)))
      } else {
        onChange(uniq([...value, each]))
      }
    } else {
      onChange(each)
      onCancel() // props.history.goBack();
    }
  }

  const isItemSelected = (val, isAll) => {
    if (allowMultiple) {
      if (isAll && isEmpty(value)) {
        return true
      }

      if (value.includes(val)) {
        return true
      }

      return false
    }

    return isEqual(val, value)
  }

  const Check = allowMultiple ? Icon.Check : Icon.Radio

  const Row = ({ index, style }) => {
    const item = items[index]

    return (
      <div style={style}>
        <IonItem lines="full" onClick={() => handleItemClick(item.value, item.isAll)} detail={false} button>
          <IonLabel>{item.text}</IonLabel>
          <Check checked={isItemSelected(item.value, item.isAll)} />
        </IonItem>
      </div>
    )
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" onClick={onCancel} defaultHref="/" />
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
        </IonToolbar>
        {allowSearch ? (
          <IonSearchbar
            onIonChange={(e) => {
              updateState((draft) => {
                draft.search = e.detail.value
              })
            }}
            maxlength={200}
          />
        ) : null}
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <AutoSizer>
          {({ height, width }) => (
            <List height={height} itemSize={45} width={width} itemCount={items.length}>
              {Row}
            </List>
          )}
        </AutoSizer>
      </IonContent>
    </>
  )
}

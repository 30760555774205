import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'location',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.locationId,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.locationId = each.id
      draft.item.locationName = each.name
      draft.item.barcode = ''
      draft.item.inventoryBarcode = ''
      draft.item.count = null
      draft.item.count2 = null
      draft.inventory = null
    }),
})()

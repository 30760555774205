import { getPrivateItem } from 'helpers/localForage'
import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import { LOCATION_COUNTS_CURRENT_KEY } from 'options/locationCounts'
import LocationCountsCurrentLocation from 'containers/LocationCounts/CurrentCount'
import LocationCountsSelectLocation from 'containers/LocationCounts/SelectLocation'
import LocationCountsClearItems from 'containers/LocationCounts/CurrentCount/ClearItems'
import LocationCountsCountItems from 'containers/LocationCounts/CurrentCount/CountItems'
import LocationCountsCheckMustCount from 'containers/LocationCounts/CurrentCount/CheckMustCount'
import LocationCountsFinishMustCount from 'containers/LocationCounts/CurrentCount/FinishMustCount'
import LocationCountsReviewItems from 'containers/LocationCounts/CurrentCount/ReviewItems'
import LocationCountsReviewOrder from 'containers/LocationCounts/CurrentCount/ReviewOrder'
import LocationCountsCompletedResults from 'containers/LocationCounts/CurrentCount/CompletedResults'
import LocationCountsEditCountedLocations from 'containers/LocationCounts/EditCounts'
import LocationCountsUploadLocationCounts from 'containers/LocationCounts/UploadItems'
import LocationCountsUploadLocationCountsResults from 'containers/LocationCounts/UploadResults'
import LocationCountsUploadLocationCountsResultDetails from 'containers/LocationCounts/UploadResults/Details'
import InventoryListView from 'containers/Inventory/ListView'
import InventoryFormView from 'containers/Inventory/FormView'
import InventoryCreate from 'containers/Inventory/Create'
import InventoryFormViewSelectSupplier from 'containers/Inventory/FormView/SelectSupplier'
import InventoryFormViewSelectLocation from 'containers/Inventory/FormView/SelectLocation'
import InventoryFormViewTransferToLocation from 'containers/Inventory/FormView/Transfer'
import InventoryFormViewTransferSelectLocation from 'containers/Inventory/FormView/Transfer/SelectLocation'
import IssueItem from 'containers/Issue/IssueItem'
import IssueItemSearchInventory from 'containers/Issue/IssueItem/SearchInventory'
import IssueItemSelectLocation from 'containers/Issue/IssueItem/SelectLocation'
import IssueItemSelectOperator from 'containers/Issue/IssueItem/SelectOperator'
import IssueItemSelectJob from 'containers/Issue/IssueItem/SelectJob'
import IssueItemSelectAsset from 'containers/Issue/IssueItem/SelectAsset'
import IssueItemSelectTagA from 'containers/Issue/IssueItem/SelectTagA'
import IssueItemSelectTagB from 'containers/Issue/IssueItem/SelectTagB'
import IssueItemSelectTagC from 'containers/Issue/IssueItem/SelectTagC'
import IssueItemSelectTagD from 'containers/Issue/IssueItem/SelectTagD'
import IssueItemSelectTagE from 'containers/Issue/IssueItem/SelectTagE'
import IssueItemReviewItems from 'containers/Issue/ReviewItems'
import IssueItemUploadItems from 'containers/Issue/UploadItems'
import IssueItemUploadResults from 'containers/Issue/UploadResults'
import IssueItemClearItems from 'containers/Issue/ClearItems'
import CountItems from 'containers/Count/CountItems'
import CountItemsSelectLocation from 'containers/Count/CountItems/SelectLocation'
import CountItemsSearchInventory from 'containers/Count/CountItems/SearchInventory'
import CountItemsReviewItems from 'containers/Count/ReviewItems'
import CountItemsClearItems from 'containers/Count/ClearItems'
import CountItemsUploadItems from 'containers/Count/UploadItems'
import CountItemsUploadResults from 'containers/Count/UploadResults'
import DraftOrder from 'containers/DraftOrder'
import DraftOrderSelectBillTo from 'containers/DraftOrder/SelectBillTo'
import DraftOrderSelectShipTo from 'containers/DraftOrder/SelectShipTo'
import DraftOrderSuppliersFormView from 'containers/DraftOrder/Suppliers/FormView'
import DraftOrderItemsFormView from 'containers/DraftOrder/Items/FormView'
import DraftOrderItemsFormViewSelectAsset from 'containers/DraftOrder/Items/FormView/SelectAsset'
import DraftOrderItemsFormViewSelectJob from 'containers/DraftOrder/Items/FormView/SelectJob'
import DraftOrderItemsFormViewSelectLocation from 'containers/DraftOrder/Items/FormView/SelectLocation'
import DraftOrderItemsFormViewSelectOperator from 'containers/DraftOrder/Items/FormView/SelectOperator'
import DraftOrderItemsFormViewSelectSupplier from 'containers/DraftOrder/Items/FormView/SelectSupplier'
import DraftOrderItemsFormViewSelectTagA from 'containers/DraftOrder/Items/FormView/SelectTagA'
import DraftOrderItemsFormViewSelectTagB from 'containers/DraftOrder/Items/FormView/SelectTagB'
import DraftOrderItemsFormViewSelectTagC from 'containers/DraftOrder/Items/FormView/SelectTagC'
import DraftOrderItemsFormViewSelectTagD from 'containers/DraftOrder/Items/FormView/SelectTagD'
import DraftOrderItemsFormViewSelectTagE from 'containers/DraftOrder/Items/FormView/SelectTagE'
import CycleCountsListView from 'containers/CycleCounts/ListView'
import CycleCountsFormView from 'containers/CycleCounts/FormView'
import CycleCountsFormViewSelectUser from 'containers/CycleCounts/FormView/SelectUser'
import CycleCountsFormViewAddFromInventory from 'containers/CycleCounts/FormView/AddFromInventory'
import CycleCountsCreate from 'containers/CycleCounts/Create'
import CycleCountsCreateSelectLocationGroup from 'containers/CycleCounts/Create/SelectLocationGroup'
import CycleCountsCreateSelectLocation from 'containers/CycleCounts/Create/SelectLocation'
import CycleCountsCreateSelectType from 'containers/CycleCounts/Create/SelectType'
import CycleCountsDetailsFormView from 'containers/CycleCounts/Details/FormView'
import CycleCountsSplit from 'containers/CycleCounts/Split'
import CycleCountsSplitSelectType from 'containers/CycleCounts/Split/SelectType'

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/inventory/inventory': InventoryListView,
    '/inventory/inventory/create': InventoryCreate,
    '/inventory/inventory/:itemId(\\d+)': InventoryFormView,
    '/inventory/inventory/:itemId(\\d+)/selectSupplier': InventoryFormViewSelectSupplier,
    '/inventory/inventory/:itemId(\\d+)/selectLocation': InventoryFormViewSelectLocation,
    '/inventory/inventory/:itemId(\\d+)/transferToLocation': InventoryFormViewTransferToLocation,
    '/inventory/inventory/:itemId(\\d+)/transferToLocation/selectLocation':
      InventoryFormViewTransferSelectLocation,
    '/inventory/currentLocation': LocationCountsCurrentLocation,
    '/inventory/currentLocation/clearLocation': LocationCountsClearItems,
    '/inventory/currentLocation/countItems': LocationCountsCountItems,
    '/inventory/currentLocation/checkMustCount/:itemBarcode': LocationCountsCountItems,
    '/inventory/currentLocation/checkMustCount': LocationCountsCheckMustCount,
    '/inventory/currentLocation/finishMustCount/:itemBarcode': LocationCountsCountItems,
    '/inventory/currentLocation/finishMustCount': LocationCountsFinishMustCount,
    '/inventory/currentLocation/reviewItems/:itemBarcode': LocationCountsCountItems,
    '/inventory/currentLocation/reviewItems': LocationCountsReviewItems,
    '/inventory/currentLocation/reviewOrder/:itemBarcode': LocationCountsCountItems,
    '/inventory/currentLocation/reviewOrder': LocationCountsReviewOrder,
    '/inventory/locationCounts/completedResults': LocationCountsCompletedResults,
    '/inventory/locationCounts/selectLocation': LocationCountsSelectLocation,
    '/inventory/locationCounts/viewEditCountedLocations': LocationCountsEditCountedLocations,
    '/inventory/locationCounts/uploadCountsAndOrders': LocationCountsUploadLocationCounts,
    '/inventory/locationCounts/uploadResults/:itemId(\\d+)': LocationCountsUploadLocationCountsResultDetails,
    '/inventory/locationCounts/uploadResults': LocationCountsUploadLocationCountsResults,
    '/inventory/count/countItems': CountItems,
    '/inventory/count/countItems/selectLocation': CountItemsSelectLocation,
    '/inventory/count/countItems/searchInventory': CountItemsSearchInventory,
    '/inventory/count/reviewSavedCountedItems': CountItemsReviewItems,
    '/inventory/count/reviewSavedCountedItems/:itemId(-\\d+)': CountItems,
    '/inventory/count/reviewSavedCountedItems/:itemId(-\\d+)/selectLocation': CountItemsSelectLocation,
    '/inventory/count/clearSavedCountedItems': CountItemsClearItems,
    '/inventory/count/uploadSavedCountedItems': CountItemsUploadItems,
    '/inventory/count/uploadResults': CountItemsUploadResults,
    '/inventory/issue/issueItem': IssueItem,
    '/inventory/issue/issueItem/selectLocation': IssueItemSelectLocation,
    '/inventory/issue/issueItem/selectOperator': IssueItemSelectOperator,
    '/inventory/issue/issueItem/selectJob': IssueItemSelectJob,
    '/inventory/issue/issueItem/selectAsset': IssueItemSelectAsset,
    '/inventory/issue/issueItem/selectTagA': IssueItemSelectTagA,
    '/inventory/issue/issueItem/selectTagB': IssueItemSelectTagB,
    '/inventory/issue/issueItem/selectTagC': IssueItemSelectTagC,
    '/inventory/issue/issueItem/selectTagD': IssueItemSelectTagD,
    '/inventory/issue/issueItem/selectTagE': IssueItemSelectTagE,
    '/inventory/issue/issueItem/searchInventory': IssueItemSearchInventory,
    '/inventory/issue/reviewSavedIssuedItems': IssueItemReviewItems,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)': IssueItem,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectLocation': IssueItemSelectLocation,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectOperator': IssueItemSelectOperator,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectJob': IssueItemSelectJob,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectAsset': IssueItemSelectAsset,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectTagA': IssueItemSelectTagA,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectTagB': IssueItemSelectTagB,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectTagC': IssueItemSelectTagC,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectTagD': IssueItemSelectTagD,
    '/inventory/issue/reviewSavedIssuedItems/:itemId(-\\d+)/selectTagE': IssueItemSelectTagE,
    '/inventory/issue/uploadSavedIssuedItems': IssueItemUploadItems,
    '/inventory/issue/uploadResults': IssueItemUploadResults,
    '/inventory/issue/clearSavedIssuedItems': IssueItemClearItems,
    '/inventory/issue/draftOrder': DraftOrder,
    '/inventory/issue/draftOrder/selectBillTo': DraftOrderSelectBillTo,
    '/inventory/issue/draftOrder/selectShipTo': DraftOrderSelectShipTo,
    '/inventory/issue/draftOrder/issueItem': IssueItem,
    '/inventory/issue/draftOrder/issueItem/selectLocation': IssueItemSelectLocation,
    '/inventory/issue/draftOrder/issueItem/selectOperator': IssueItemSelectOperator,
    '/inventory/issue/draftOrder/issueItem/selectJob': IssueItemSelectJob,
    '/inventory/issue/draftOrder/issueItem/selectAsset': IssueItemSelectAsset,
    '/inventory/issue/draftOrder/issueItem/selectTagA': IssueItemSelectTagA,
    '/inventory/issue/draftOrder/issueItem/selectTagB': IssueItemSelectTagB,
    '/inventory/issue/draftOrder/issueItem/selectTagC': IssueItemSelectTagC,
    '/inventory/issue/draftOrder/issueItem/selectTagD': IssueItemSelectTagD,
    '/inventory/issue/draftOrder/issueItem/selectTagE': IssueItemSelectTagE,
    '/inventory/issue/draftOrder/issueItem/searchInventory': IssueItemSearchInventory,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderSuppliers/:itemId(\\d+)': DraftOrderSuppliersFormView,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)': DraftOrderItemsFormView,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectAsset':
      DraftOrderItemsFormViewSelectAsset,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectJob':
      DraftOrderItemsFormViewSelectJob,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectLocation':
      DraftOrderItemsFormViewSelectLocation,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectOperator':
      DraftOrderItemsFormViewSelectOperator,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectSupplier':
      DraftOrderItemsFormViewSelectSupplier,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectTagA':
      DraftOrderItemsFormViewSelectTagA,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectTagB':
      DraftOrderItemsFormViewSelectTagB,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectTagC':
      DraftOrderItemsFormViewSelectTagC,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectTagD':
      DraftOrderItemsFormViewSelectTagD,
    '/inventory/issue/draftOrder/:parentId(\\d+)/orderItems/:itemId(\\d+)/selectTagE':
      DraftOrderItemsFormViewSelectTagE,
    '/inventory/cycleCounts': CycleCountsListView,
    '/inventory/cycleCounts/create': CycleCountsCreate,
    '/inventory/cycleCounts/create/selectLocationGroup': CycleCountsCreateSelectLocationGroup,
    '/inventory/cycleCounts/create/selectLocation': CycleCountsCreateSelectLocation,
    '/inventory/cycleCounts/create/selectType': CycleCountsCreateSelectType,
    '/inventory/cycleCounts/:itemId(\\d+)': CycleCountsFormView,
    '/inventory/cycleCounts/:itemId(\\d+)/selectUser': CycleCountsFormViewSelectUser,
    '/inventory/cycleCounts/:itemId(\\d+)/addFromInventory': CycleCountsFormViewAddFromInventory,
    '/inventory/cycleCounts/:parentId(\\d+)/detailItems/:itemId(-\\d+|\\d+)': CycleCountsDetailsFormView,
    '/inventory/cycleCounts/:itemId(\\d+)/split': CycleCountsSplit,
    '/inventory/cycleCounts/:itemId(\\d+)/split/selectType': CycleCountsSplitSelectType,
  }),
  getNavListItemProps: (self) => ({
    locationCounts: {
      onClick: async () => {
        if (await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)) {
          self.props.history.push('/inventory/currentLocation')
        } else {
          self.props.history.push('/inventory/locationCounts')
        }
      },
      button: true,
    },
  }),
})

export default createTabViewContainer()(Component)

import produce from 'immer'
import { createListViewContainer } from 'factories/ListView'
import { filterBySearch, filterLocationsByGroupIds, wrapItems, sortItems } from 'helpers/utils'
import { getSharedLocations } from 'helpers/offlineData'
import { getIsCounted } from 'helpers/locationCounts'
import Component from 'components/LocationCounts/SelectLocation'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const items = await getSharedLocations().then((r) =>
        r
          .filter((each) => ['Primary', 'Secondary'].includes(each.locationType))
          .filter(filterLocationsByGroupIds(params.locationGroupIds))
          .filter(filterBySearch(params.search))
      )
      const isCounted = await Promise.all(items.map(getIsCounted))
      const unsortedItems = items
        .map((each, index) =>
          produce(each, (draft) => {
            draft.isCounted = isCounted[index]
          })
        )
        .filter((each) => {
          if (params.countStatus === 'Counted') {
            return each.isCounted
          }

          if (params.countStatus === 'NotCounted') {
            return !each.isCounted
          }

          return true
        })

      return Promise.resolve(sortItems(unsortedItems, params.sortByField, params.sortOrder)).then(wrapItems)
    },
  }),
})(Component)

import actions from 'actions/assets'
import { createFormViewContainer } from 'factories/FormView'
import {
  createChildItemsDispatchToProps,
  createLinkedItemsDispatchToProps,
  createDocumentsDispatchToProps,
} from 'helpers/formViews'
import documentActions from 'actions/documents'
import assetWarrantyActions from 'actions/assets/assetWarranties'
import assetToleranceActions from 'actions/assets/assetTolerances'
import assetHistoryActions from 'actions/assets/assetHistory'
import taskActions from 'actions/tasks'
import inventoryActions from 'actions/inventory'
import Component from 'components/Assets/FormView'

export default createFormViewContainer({
  actions,
  extraDispatchToProps: (dispatch, props) => ({
    generateId: (params) => dispatch(actions.generateId(params)),
    getInventoryAvailability: (params) => dispatch(inventoryActions.getAvailability(params)),
    updateMileage: (params) => dispatch(actions.updateMileage(params)),
    updateServiceHours: (params) => dispatch(actions.updateServiceHours(params)),
    ...createDocumentsDispatchToProps(dispatch, documentActions),
    ...createLinkedItemsDispatchToProps(dispatch, 'Task', taskActions),
    ...createChildItemsDispatchToProps(dispatch, 'Warranty', assetWarrantyActions),
    ...createChildItemsDispatchToProps(dispatch, 'Tolerance', assetToleranceActions),
    ...createChildItemsDispatchToProps(dispatch, 'History', assetHistoryActions),
  }),
})(Component)

import { notification, message } from 'helpers/utils'
import { isEmpty, uniq, toString as str, isNil, camelCase, invoke } from 'lodash'
import { t } from 'helpers/i18n'

export const showError = ({ error } = {}) => {
  if (isNil(error)) {
    return
  }

  console.error(error)

  if (!isEmpty(error.data?.clientNotifications)) {
    showClientNotifications({ clientNotifications: error.data.clientNotifications })

    return
  }

  if (!isEmpty(error.response?.data?.Errors)) {
    error.response.data.Errors.forEach((each) => {
      const notificationType = camelCase(
        each.Type || each.type || each.ErrorType || each.errorType || 'error'
      )
      const titleLanguageKey = each.TitleLanguageKey || each.titleLanguageKey || each.Title || each.title

      invoke(notification, notificationType, {
        header: titleLanguageKey ? t(titleLanguageKey) : undefined,
        message: t(
          each.DescriptionLanguageKey ||
            each.descriptionLanguageKey ||
            each.Description ||
            each.description ||
            each.DeveloperError ||
            each.developerError
        ),
      })
    })

    return
  }

  if (error.message) {
    message.error(error.message)
  }
}

export const showClientNotifications = ({
  response,
  clientNotifications = response?.value?.data?.clientNotifications ?? [],
} = {}) =>
  clientNotifications.forEach(({ type, description }) => {
    switch (type) {
      case 'Success':
        message.success(t(description))
        break

      case 'Warning':
        message.warn(t(description))
        break

      case 'Error':
        message.error(t(description))
        break

      default:
        message.info(t(description))
        break
    }
  })

export const showValidationErrors = ({ errors } = {}) => {
  message.destroy()
  uniq(Object.values(errors)).forEach((value) => {
    message.error(str(value))
  })
}

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'reason',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.reasonId,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.reasonId = each.id
      draft.item.reasonDescription = each.displayName

      const { duration = 0, useDuration = false } = self.state.items.find((one) => one.id === each.id) || {}

      if (useDuration) {
        draft.item.dueDate = new Date(new Date().getTime() + duration * 24 * 60 * 60 * 1000)
      }
    }),
})()

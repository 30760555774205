import produce from 'immer'
import { snakeCase, memoize, set, invoke, omitBy, isNil, unset } from 'lodash'
import { cancelable } from 'cancelable-promise'
import {
  createChildPost,
  createDelete,
  createGet,
  createOrphanedChildPost,
  createPost,
  createPut,
  deferForbiddenWith,
  ignoreForbiddenWith,
} from 'helpers/api'
import { baseUrl as defaultBaseUrl } from 'config'

export const emptyList = {
  data: {
    items: [],
    pageCount: 0,
    pageIndex: 1,
    recordCount: 0,
    actualStartDate: '0001-01-01T00:00:00Z',
    actualEndDate: '0001-01-01T00:00:00Z',
    pageTotals: {},
  },
}

export const createActions = (
  entityName,
  {
    idField = 'id',
    entityUrl = entityName,
    baseUrl = defaultBaseUrl,
    basePath = 'api',
    useSelectionList = false,
    memoizeGetOptions = false,
    transformGetItemsParams = () => ({}),
    transformGetOptionsParams = transformGetItemsParams,
    transformDownloadItemsParams = transformGetItemsParams,
    transformGetCalendarItemsParams = transformGetItemsParams,
    transformGetItemsResponse = (response) => response,
    transformGetOptionsResponse = transformGetItemsResponse,
    transformGetCalendarItemsResponse = transformGetOptionsResponse,
    allowGetItems = () => true,
  } = {}
) => {
  const newItem = createPost(entityName, {
    action: 'new',
    basePath,
    baseUrl,
  })
  const ENTITY_NAME = snakeCase(entityName).toUpperCase()
  const getItem = createGet(entityName, { basePath, baseUrl, entityUrl })
  const createItem = createPost(entityName, { basePath, baseUrl, entityUrl })
  const updateItem = createPut(entityName, { basePath, baseUrl, entityUrl, idField })
  const deleteItem = createDelete(entityName, { basePath, baseUrl, entityUrl })
  const createItems = createPost(entityName, {
    action: 'create',
    basePath,
    baseUrl,
    entityUrl,
  })
  const updateItems = createPost(entityName, {
    action: 'update',
    basePath,
    baseUrl,
    entityUrl,
  })
  const getItems = createPost(entityName, {
    action: 'list',
    basePath,
    baseUrl,
    entityUrl,
  })
  const deleteItems = createPost(entityName, {
    action: 'delete',
    basePath,
    baseUrl,
    entityUrl,
  })
  const deactivateItems = createPost(entityName, {
    action: 'deactivate',
    basePath,
    baseUrl,
    entityUrl,
  })
  const getSettings = memoize(
    createGet(entityName, {
      action: 'clientSettings',
      basePath,
      baseUrl,
      entityUrl,
    }),
    JSON.stringify
  )
  const getCalendarItems = createPost(entityName, {
    action: 'calendarList',
    basePath,
    baseUrl,
    entityUrl,
  })
  const getOptionsNow = createPost(entityName, {
    action: useSelectionList ? 'selectionList' : 'list',
    basePath,
    baseUrl,
    entityUrl,
  })
  const getOptions = memoizeGetOptions ? memoize(getOptionsNow, JSON.stringify) : getOptionsNow
  const clearCache = () => memoizeGetOptions && invoke(getOptions, 'cache.clear')

  return {
    newItem: (params, options) => ({
      type: `${ENTITY_NAME}_ITEM_NEW`,
      payload: newItem(params, options)
        .then((response) =>
          produce(response, (draft) => {
            set(draft, 'data.id', undefined)
          })
        )
        .finally(clearCache),
    }),

    getItem: (itemId) => ({
      type: `${ENTITY_NAME}_ITEM_GET`,
      payload: getItem(itemId),
    }),

    createItem: (params, options) => ({
      type: `${ENTITY_NAME}_ITEM_CREATE`,
      payload: createItem(params, options).finally(clearCache),
    }),

    createItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_CREATE`,
      payload: createItems(params, options).finally(clearCache),
    }),

    updateItem: (params, options) => ({
      type: `${ENTITY_NAME}_ITEM_UPDATE`,
      payload: updateItem(params, options).finally(clearCache),
    }),

    deleteItem: (params) => ({
      type: `${ENTITY_NAME}_ITEM_DELETE`,
      payload: deleteItem(params).finally(clearCache),
    }),

    getSettings: (params) => ({
      type: `${ENTITY_NAME}_SETTINGS_GET`,
      payload: getSettings(params),
    }),

    getItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_GET`,
      payload: allowGetItems(params)
        ? getItems({ ...params, ...transformGetItemsParams(params) }, options)
            .then(transformGetItemsResponse)
            .catch(deferForbiddenWith(emptyList))
        : Promise.resolve(emptyList),
    }),

    getListViewItems: (params, options) => ({
      type: `${ENTITY_NAME}_LIST_VIEW_ITEMS_GET`,
      payload: cancelable(
        allowGetItems(params)
          ? getItems({ ...params, ...transformGetItemsParams(params) }, options)
              .then(transformGetItemsResponse)
              .catch(deferForbiddenWith(emptyList))
          : Promise.resolve(emptyList)
      ),
      meta: {
        pageIndex: params?.pageIndex,
        pageSize: params?.pageSize,
      },
    }),

    getOptions: (params) => ({
      type: `${ENTITY_NAME}_OPTIONS_GET`,
      payload: allowGetItems(params)
        ? getOptions({
            pageIndex: 0,
            ...params,
            ...transformGetOptionsParams(params),
          })
            .then(transformGetOptionsResponse)
            .catch(ignoreForbiddenWith(emptyList))
        : Promise.resolve(emptyList),
    }),

    getCalendarItems: (params, options) => ({
      type: `${ENTITY_NAME}_CALENDAR_ITEMS_GET`,
      payload: allowGetItems(params)
        ? getCalendarItems({ ...params, ...transformGetCalendarItemsParams(params) }, options)
            .then(transformGetCalendarItemsResponse)
            .catch(deferForbiddenWith(emptyList))
        : Promise.resolve(emptyList),
    }),

    getCalendarViewItems: (params, options) => ({
      type: `${ENTITY_NAME}_CALENDAR_VIEW_ITEMS_GET`,
      payload: cancelable(
        allowGetItems(params)
          ? getCalendarItems({ ...params, ...transformGetCalendarItemsParams(params) }, options)
              .then(transformGetCalendarItemsResponse)
              .catch(deferForbiddenWith(emptyList))
          : Promise.resolve(emptyList)
      ),
    }),

    updateItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_UPDATE`,
      payload: updateItems(params, options).finally(clearCache),
    }),

    deactivateItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_DEACTIVATE`,
      payload: deactivateItems(params, options).finally(clearCache),
    }),

    deleteItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_DELETE`,
      payload: deleteItems(params, options).finally(clearCache),
    }),

    downloadItems: (params, options) => ({
      type: `${ENTITY_NAME}_ITEMS_DOWNLOAD`,
      payload: createPost(entityName, {
        action: 'list',
        responseType: 'blob',
        acceptMimeType: params?.acceptMimeType ?? 'text/csv',
        basePath,
        baseUrl,
        entityUrl,
      })({ ...params, ...transformDownloadItemsParams(params), pageIndex: 0, pageSize: undefined }, options),
    }),
  }
}

export const createChildActions = (
  entityName,
  childName,
  { baseUrl = defaultBaseUrl, basePath = 'api' } = {}
) => {
  const ENTITY_NAME = snakeCase(entityName).toUpperCase()
  const CHILD_NAME = snakeCase(childName).toUpperCase()
  const getSettings = memoize(
    createGet(`${entityName}/${childName}`, {
      action: 'clientSettings',
      basePath,
      baseUrl,
    }),
    JSON.stringify
  )
  const getChildItems = createChildPost(entityName, childName, {
    action: 'list',
    basePath,
    baseUrl,
  })
  const createChildItems = createChildPost(entityName, childName, {
    basePath,
    baseUrl,
  })
  const updateChildItems = createChildPost(entityName, childName, {
    action: 'update',
    basePath,
    baseUrl,
  })
  const deleteChildItems = createChildPost(entityName, childName, {
    action: 'delete',
    basePath,
    baseUrl,
  })

  return {
    getSettings: (params) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_SETTINGS_GET`,
      payload: getSettings(params),
    }),

    getChildItems: (parentId, params) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_ITEMS_GET`,
      payload: getChildItems(parentId, {
        pageIndex: 0,
        ...params,
      }).catch(deferForbiddenWith(emptyList)),
    }),

    createChildItems: (parentId, items) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_ITEMS_CREATE`,
      payload: createChildItems(parentId, items),
    }),

    updateChildItems: (parentId, items) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_ITEMS_UPDATE`,
      payload: updateChildItems(parentId, items),
    }),

    deleteChildItems: (parentId, itemIds) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_ITEMS_DELETE`,
      payload: deleteChildItems(parentId, itemIds),
    }),

    downloadChildItems: (parentId, params) => ({
      type: `${ENTITY_NAME}_${CHILD_NAME}_ITEMS_DOWNLOAD`,
      payload: createChildPost(entityName, childName, {
        action: 'list',
        responseType: 'blob',
        acceptMimeType: params?.acceptMimeType ?? 'text/csv',
        basePath,
        baseUrl,
      })(parentId, { ...params, pageIndex: 0, pageSize: undefined }),
    }),

    downloadItems: (params) => ({
      type: `${CHILD_NAME}_ITEMS_DOWNLOAD`,
      payload: createOrphanedChildPost(entityName, childName, {
        action: 'list',
        responseType: 'blob',
        acceptMimeType: params?.acceptMimeType ?? 'text/csv',
        basePath,
        baseUrl,
      })({ ...params, pageIndex: 0, pageSize: undefined }),
    }),
  }
}

export const normalizeDateRange = (params = {}, defaultDateRangeField = 'All') => {
  if (params.mobileDownload) {
    unset(params, 'dateRange')
    unset(params, 'dateRangeField')
  } else {
    const dateRangeType = params.dateRange?.dateRangeType ?? 'YearToDate'
    const dateRangeField = params.dateRangeField ?? defaultDateRangeField

    set(params, 'dateRange.dateRangeType', dateRangeType)
    set(params, 'dateRangeField', dateRangeField)

    params.dateRange = omitBy(params.dateRange, isNil)

    if (params.dateRange?.customStartDate?.toJSON) {
      params.dateRange.customStartDate = params.dateRange.customStartDate.toJSON()
    }

    if (params.dateRange?.customEndDate?.toJSON) {
      params.dateRange.customEndDate = params.dateRange.customEndDate.toJSON()
    }
  }
}

import * as React from 'react'
import { useImmer } from 'use-immer'
import produce from 'immer'
import {
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonAlert,
  IonSpinner,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import { set, isNil, isEmpty, remove } from 'lodash'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { setPrivateItem, removePrivateItem, getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import {
  LOCATION_COUNTS_CURRENT_KEY,
  LOCATION_COUNTS_SAVED_KEY,
  LOCATION_COUNTS_COMPLETED_RESULTS_KEY,
} from 'options/locationCounts'
import { getCurrentCount, getShowCount, getStoredInventoryForLocationId } from 'helpers/locationCounts'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import Header from './Header'

export default function (props) {
  const [state, updateState] = useImmer({})

  const locationCountDetails = state.item?.locationCountDetails ?? []
  const showCount = getShowCount(state.location)

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  function saveItem() {
    try {
      if (isEmpty(state.mustCountItems)) {
        const buttons = [
          {
            text: t('cancel'),
            role: 'cancel',
          },
          {
            text: state.item.id ? t('update') : t('complete'),
            handler: async () => {
              try {
                await removePrivateItem(LOCATION_COUNTS_COMPLETED_RESULTS_KEY)

                const locationCount = await getPrivateItem(LOCATION_COUNTS_CURRENT_KEY)
                const completedLocation = produce(locationCount, (draft) => {
                  draft.id = draft.id || Date.now() * -1
                  draft.completedDate = new Date().toJSON()
                })

                await updatePrivateItem(LOCATION_COUNTS_SAVED_KEY, [], (draft) => {
                  remove(draft, (each) => each.id === completedLocation.id)
                  draft.unshift(completedLocation)
                })

                await removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)

                if (state.item.id) {
                  window.location.href = '/inventory/locationCounts/viewEditCountedLocations'
                } else {
                  await setPrivateItem(LOCATION_COUNTS_COMPLETED_RESULTS_KEY, completedLocation)
                  window.location.href = '/inventory/locationCounts/completedResults'
                }
              } catch (error) {
                showError({ error })
              }
            },
          },
        ]

        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = state.item.id ? t('confirmUpdateCount') : t('confirmCompleteCount')
          draft.alertButtons = buttons
        })
      } else {
        const buttons = [
          {
            text: t('cancel'),
            role: 'cancel',
          },
          {
            text: t('ok'),
            handler: () => {
              window.location.href = '/inventory/currentLocation/finishMustCount'
            },
          },
        ]

        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = t('confirmFinishMustCount')
          draft.alertButtons = buttons
        })
      }
    } catch (error) {
      showError({ error })
    }
  }

  async function handleAbandon() {
    if (isEmpty(locationCountDetails)) {
      try {
        await removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)
        window.location.href = '/inventory/locationCounts/selectLocation'
      } catch (error) {
        showError({ error })
      }
    } else {
      updateState((draft) => {
        draft.alertIsOpen = true
        draft.alertMessage = t('confirmAbandonCount')
        draft.alertButtons = [
          {
            text: t('cancel'),
            role: 'cancel',
          },
          {
            text: t('abandon'),
            handler: async () => {
              try {
                await removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)
                window.location.href = '/inventory/locationCounts/selectLocation'
              } catch (error) {
                showError({ error })
              }
            },
          },
        ]
      })
    }
  }

  async function handleCancel() {
    if (state.item.orderId) {
      try {
        await removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)
        window.location.href = '/inventory/locationCounts/viewEditCountedLocations'
      } catch (error) {
        showError({ error })
      }

      return
    }

    updateState((draft) => {
      draft.alertIsOpen = true
      draft.alertMessage = t('confirmCancelCount')
      draft.alertButtons = [
        {
          text: t('no'),
          role: 'cancel',
        },
        {
          text: t('yes'),
          handler: async () => {
            try {
              await removePrivateItem(LOCATION_COUNTS_CURRENT_KEY)
              window.location.href = '/inventory/locationCounts/viewEditCountedLocations'
            } catch (error) {
              showError({ error })
            }
          },
        },
      ]
    })
  }

  async function fetchItem() {
    try {
      setState('loadingIsOpen', true)

      const response = await getCurrentCount()

      let mustCountItems = []

      if (getShowCount(response.currentLocation)) {
        mustCountItems = await getStoredInventoryForLocationId(response.currentCount.locationId).then((r) =>
          r
            .filter((each) => each.mustCount && !each.lockedForCycleCount)
            .map((each) =>
              produce(each, (draft) => {
                draft.isCounted = response.currentCount.locationCountDetails.some(
                  (one) => one.inventoryId === draft.id && (!isNil(one.count) || !isNil(one.count2))
                )
              })
            )
            .filter((each) => !each.isCounted)
        )
      }

      updateState((draft) => {
        draft.item = response.currentCount
        draft.location = response.currentLocation
        draft.mustCountItems = mustCountItems
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  useIonViewWillEnter(() => {
    setState('loadingIsOpen', true)
  })

  useIonViewDidEnter(() => {
    fetchItem()
  })

  const backButtonHref = '/inventory'

  if (isNil(state.item)) {
    return (
      <Page title={t('currentLocation')} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  return (
    <Page
      title={t('currentLocation')}
      backButtonHref={backButtonHref}
      header={<Header className="ion-margin tofino-tabular-list" showCountedToday />}
      footer={
        <IonRow className={state.loadingIsOpen ? 'ion-hide' : undefined}>
          {state.item.id ? (
            <IonCol>
              <IonButton color="transparent" expand="full" onClick={handleCancel}>
                {t('cancel')}
              </IonButton>
            </IonCol>
          ) : (
            <IonCol>
              <IonButton color="transparent" expand="full" onClick={handleAbandon}>
                {t(isEmpty(locationCountDetails) ? 'cancel' : 'abandon')}
              </IonButton>
            </IonCol>
          )}
          <IonCol>
            <IonButton
              color="secondary"
              expand="full"
              onClick={() => saveItem()}
              disabled={
                (!state.item?.id && isEmpty(state.mustCountItems) && isEmpty(locationCountDetails)) ||
                state.item?.orderId
              }
            >
              {state.item.id ? t('update') : t('complete')}
            </IonButton>
          </IonCol>
        </IonRow>
      }
    >
      {state.loadingIsOpen ? (
        <IonSpinner className="ion-margin" />
      ) : (
        <>
          <IonItem lines="full" routerLink={`${props.match.url}/countItems`} disabled={state.item?.orderId}>
            <Icon slot="start" type="find_in_page" />
            <IonLabel>{showCount ? t('countItems') : t('orderItem')}</IonLabel>
          </IonItem>
          {showCount && (
            <IonItem lines="full" routerLink={`${props.match.url}/checkMustCount`}>
              <Icon slot="start" type="report" />
              <IonLabel>{t('checkMustCount')}</IonLabel>
            </IonItem>
          )}
          {showCount ? (
            <IonItem lines="full" routerLink={`${props.match.url}/reviewItems`}>
              <Icon slot="start" type="grading" />
              <IonLabel>{t('reviewItems')}</IonLabel>
            </IonItem>
          ) : (
            <IonItem lines="full" routerLink={`${props.match.url}/reviewOrder`}>
              <Icon slot="start" type="grading" />
              <IonLabel>{t('reviewOrder')}</IonLabel>
            </IonItem>
          )}
          <IonItem
            lines="full"
            routerLink={`${props.match.url}/clearLocation`}
            disabled={state.item?.orderId}
          >
            <Icon slot="start" type="delete_sweep" />
            <IonLabel>{t('clearLocation')}</IonLabel>
          </IonItem>
        </>
      )}
      <IonAlert
        backdropDismiss={false}
        isOpen={state.alertIsOpen}
        header={state.alertHeader}
        message={state.alertMessage}
        buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
        onDidDismiss={() => setState('alertIsOpen', false)}
      />
    </Page>
  )
}

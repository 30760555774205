import { createListViewContainer } from 'factories/ListView'
import listActions from 'actions/lists'
import listItemActions from 'actions/lists/listItems'
import requisitionItemActions from 'actions/requisitions/requisitionItems'
import { getStorageItem, updateStorageItem } from 'helpers/localStorage'
import { wrapItems } from 'helpers/utils'
import Component from 'components/Requisitions/AddItems/AddFromList'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const listId = getStorageItem('requisitions.addItems.addFromList', {}).filterDto?.listId

      if (listId) {
        return dispatch(
          listItemActions.getChildItems(listId, {
            domainObjectType: 'Requisition',
            listItemMode: 'ProductListProcurementRecord',
            listType: 'Product',
            ...params,
          })
        )
      }

      return dispatch(listActions.getItems({ type: 'Product' })).then((r) => {
        const list = r.value.data.items[0]

        if (list) {
          updateStorageItem('requisitions.addItems.addFromList', {}, (draft) => {
            draft.filterDto.listId = list.id
            draft.filterDto.listName = list.name
          })

          return dispatch(
            listItemActions.getChildItems(list.id, {
              domainObjectType: 'Requisition',
              listItemMode: 'ProductListProcurementRecord',
              listType: 'Product',
              ...params,
            })
          )
        }

        return wrapItems([])
      })
    },
    populateRequisitionItems: (params) => dispatch(requisitionItemActions.populateRequisitionItems(params)),
  }),
})(Component)

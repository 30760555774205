import * as React from 'react'
import { isEmpty, get } from 'lodash'
import { IonRow, IonCol } from '@ionic/react'
import { MAX_NUMBER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import Select, { Option } from 'elements/Select'
import Icon from 'elements/Icon'

export default function (props) {
  const {
    rowActionItems = [],
    selectedRowKeys = [],
    idField = 'id',
    items = [],
    onRowAction = () => {},
    setState = () => {},
    ...rest
  } = props

  const [selectedRowAction, setSelectedRowAction] = React.useState('')

  const rowActionItem = rowActionItems.find((one) => one.key === selectedRowAction)
  const rowActionsButtonEnabled =
    rowActionItem &&
    selectedRowKeys.length > 0 &&
    selectedRowKeys.length <= get(rowActionItem, 'maxSelectedRowKeys', MAX_NUMBER)
  const rowActionsSelectAllChecked = !isEmpty(items) && selectedRowKeys.length === items.length

  return (
    <IonRow {...rest}>
      <IonCol>
        <Select placeholder={t('selectAction')} onSelect={(value) => setSelectedRowAction(value)}>
          {rowActionItems.map((each) => (
            <Option
              key={each.key}
              value={each.key}
              style={each.underline ? { borderBottom: '1px solid var(--ion-border-color)' } : null}
            >
              {t(each.key)}
            </Option>
          ))}
        </Select>
      </IonCol>
      <IonCol style={{ padding: '2px 0px 2px 5px' }}>
        <IonRow>
          <IonCol>
            <Icon
              style={{ transform: 'rotate(90deg)' }}
              type="arrow_circle_up"
              size="30"
              color={rowActionsButtonEnabled ? 'primary' : 'dark'}
              onClick={() => {
                if (rowActionsButtonEnabled) {
                  onRowAction(selectedRowAction, selectedRowKeys)
                }
              }}
            />
          </IonCol>
          <IonCol className="ion-text-right ion-text-nowrap">
            {`${selectedRowKeys.length} ${t('rows')}`}{' '}
            <Icon.Check
              style={{ verticalAlign: 'middle' }}
              color={rowActionsSelectAllChecked ? 'secondary' : 'dark'}
              checked={rowActionsSelectAllChecked}
              onClick={() =>
                setState(
                  'selectedRowKeys',
                  rowActionsSelectAllChecked ? [] : items.map((each) => each[idField])
                )
              }
            />
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  )
}

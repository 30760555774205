import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import { getStorageItem } from 'helpers/localStorage'
import { t } from 'helpers/i18n'
import Component from 'components/Download/Customer/SelectLocation'

const getStorageKey = () => 'customer.offlineData.inventory'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) => {
      const locationGroupIds = getStorageItem(getStorageKey())?.locationGroupIds

      return dispatch(
        locationActions.getItems({
          locationGroupIds,
          locationVendingTypes: ['NonVending'],
          ...params,
        })
      ).then((r) =>
        produce(r, (draft) => {
          draft.value.data.items.unshift({ id: 0, displayName: t('all') })
          draft.value.data.recordCount++
        })
      )
    },
  }),
})(Component)

import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitleLanguageKey: 'performedBy',
  getItemSize: () => 45,
  allowSearch: () => true,
  getValue: () => getSessionItem(getStorageKey(), {}).item?.performedById,
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.performedById = each.id
      draft.item.performedByName = each.displayName
    }),
})()

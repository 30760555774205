import { createSelectionListViewComponent } from 'factories/SelectionListView'
import { getSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import { t } from 'helpers/i18n'
import { getStorageKey } from '.'

export default createSelectionListViewComponent({
  pageTitle: () => t('sendTo'),
  getItemSize: () => 45,
  allowSearch: () => true,
  getIdField: () => 'userName',
  getValue: () => getSessionItem(getStorageKey(), {}).item?.sendTo || '',
  getOnChange: (self) => (each) =>
    updateSessionItem(getStorageKey(), {}, (draft) => {
      draft.item.sendTo = each.userName
      draft.item.sendToName = each.fullname
    }),
})()

import { createTabViewComponent, createTabViewContainer } from 'factories/TabView'
import Welcome from 'containers/Welcome'
import JobRequestsFormViewSelectLocation from 'containers/JobRequests/FormView/SelectLocation'
import JobRequestsFormViewSelectAsset from 'containers/JobRequests/FormView/SelectAsset'
import JobRequestsFormViewSelectAssetCategory from 'containers/JobRequests/FormView/SelectAssetCategory'

const Component = createTabViewComponent({
  getAvailableRoutes: ({ props }) => ({
    '/welcome': Welcome,
    '/welcome/selectLocation': JobRequestsFormViewSelectLocation,
    '/welcome/selectAsset': JobRequestsFormViewSelectAsset,
    '/welcome/selectAssetCategory': JobRequestsFormViewSelectAssetCategory,
  }),
})

export default createTabViewContainer()(Component)

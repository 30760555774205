import * as React from 'react'
import produce from 'immer'
import { useImmer } from 'use-immer'
import { get, set } from 'lodash'
import {
  IonItem,
  IonLabel,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonSpinner,
} from '@ionic/react'
import { t } from 'helpers/i18n'
import { showError } from 'helpers/errors'
import { tryParseInt, FILTER_SCROLL_TOP } from 'helpers/utils'
import { getMultiSelectDisplayNameFromArray } from 'helpers/formViews'
import { getSessionItem, setSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import ModalSelect from 'elements/ModalSelect'

export default function (props) {
  const { filterDto = {}, onChange = () => {}, onClose = () => {} } = props

  const contentRef = React.useCallback((node) => {
    if (node !== null) {
      const scrollTop = tryParseInt(getSessionItem(FILTER_SCROLL_TOP))

      if (scrollTop) {
        node.scrollToPoint(0, scrollTop, 0)
        removeSessionItem(FILTER_SCROLL_TOP)
      }
    }
  }, [])

  const [state, updateState] = useImmer({})

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const assignFilter = (value) => {
    onChange(
      produce(filterDto, (draft) => {
        Object.assign(draft, value)
      })
    )
  }

  async function fetchItems() {
    try {
      setState('loadingIsOpen', true)

      const responses = await Promise.all([props.getOperatorGroups()])

      updateState((draft) => {
        draft.operatorGroups = [
          { id: -1, displayName: t('unassigned') },
          ...get(responses[0], 'value.data.items', []),
        ]
        draft.loadingIsOpen = false
      })
    } catch (error) {
      setState('loadingIsOpen', false)
      showError({ error })
    }
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const operatorGroupIds = filterDto?.operatorGroupIds ?? []

  if (state.activeView === 'operatorGroupIds') {
    return (
      <ModalSelect
        title={t('operatorGroup')}
        options={(state.operatorGroups ?? []).map((each) => ({
          value: each.id,
          text: each.displayName,
        }))}
        value={operatorGroupIds}
        onChange={(values) => {
          assignFilter({
            operatorGroupIds: values,
          })
        }}
        onCancel={() => setState('activeView', null)}
        allowMultiple
      />
    )
  }

  if (state.loadingIsOpen) {
    return <IonSpinner className="ion-margin" color="light" />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onChange({})}>
              {t('clear')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t('filter')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={onClose}>
              {t('close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        forceOverscroll={false}
        onIonScroll={(e) => setSessionItem(FILTER_SCROLL_TOP, e.detail.scrollTop)}
        scrollEvents
      >
        <IonItem
          lines="full"
          className="tofino-stacked-item"
          onClick={() => setState('activeView', 'operatorGroupIds')}
          button
        >
          <IonLabel>
            <IonText color="medium">
              <small>{t('operatorGroup')}</small>
            </IonText>
            <br />
            {getMultiSelectDisplayNameFromArray(state.operatorGroups, operatorGroupIds)}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  )
}

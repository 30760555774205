import * as React from 'react'
import { useImmer } from 'use-immer'
import { useDebouncedCallback } from 'use-debounce'
import {
  IonSearchbar,
  IonButton,
  IonAlert,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLoading,
  IonPopover,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react'
import { FixedSizeList as List } from 'react-window'
import useOnlineStatus from '@rehooks/online-status'
import { set, isNil, isEmpty, floor, pick } from 'lodash'
import { asyncSleep, isSignificant, message, DEBOUNCE, FILTER_SCROLL_TOP } from 'helpers/utils'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { setSessionItem, getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import { showError, showClientNotifications } from 'helpers/errors'
import { t } from 'helpers/i18n'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import Sort from 'elements/Sort'
import Page from 'elements/Page'
import Icon from 'elements/Icon'
import RowActions from 'elements/RowActions'
import SearchbarContainer from 'elements/SearchbarContainer'
import Button from 'elements/Button'
import BarcodeScanner from 'elements/BarcodeScanner'

const DEFAULT_PAGE_SIZE = 50

export default ({
    getStorageKey = (self) => self.props.storageKey ?? '__LIST_VIEW__',
    pageTitleLanguageKey,
    pageTitle = (self) => (pageTitleLanguageKey ? t(pageTitleLanguageKey) : undefined),
    allowSearch = (self) => false,
    storeSearch = (self) => false,
    allowDelete = (self) => (item) => false,
    getDeleteConfirmMessage = (self) => t('confirmDeleteItem'),
    allowOffline = (self) => true,
    renderItem = (self) => (item) => null,
    allowInfiniteLoader = (self) => true,
    getItemSize = (self) => {
      throw Error('getItemSize is not defined')
    },
    getSearchHeader = (self) => null,
    getInsignificantFilterDtoFields = (self) => ['dateRange', 'dateRangeField'],
    getListHeader = (self) => null,
    getFooter = (self) => null,
    getToolbarIconColor = (self) =>
      Object.entries(self.state?.filterDto ?? {})
        .filter(([key, _]) => !getInsignificantFilterDtoFields(self).includes(key))
        .some(([_, value]) => isSignificant(value))
        ? 'warning'
        : undefined,
    getToolbarIcon = (self) => <Icon type="Menu" size="26" color={getToolbarIconColor(self)} />,
    getPopoverContent = (self) => null,
    getViewDidEnterHandler = (self) => () => {},
    getSortByFields = (self) => [],
    getIdField = (self) => 'id',
    getRowActionItems = (self) => [],
    getRowActionHandler = (self) => (rowActionKey, selectedRowKeys) => {
      // console.log({ rowActionKey, selectedRowKeys })
      message.info(t('underDevelopment'))
    },
  } = {}) =>
  (Filter) =>
  (props) => {
    const isOnline = useOnlineStatus()
    const refBarcode = React.useRef()

    const firstPageIndex = () => (!allowInfiniteLoader({ props }) ? 0 : isOnline ? 1 : 0)

    const [state, updateState] = useImmer({
      search: '',
      filterDto: {},
      recordCount: 0,
      selectedRowKeys: [],
      pageIndex: firstPageIndex(),
      ...getSessionItem(getStorageKey({ props }), {}),
      ...getStorageItem(getStorageKey({ props }), {}),
    })

    const setState = React.useCallback((name, value) => {
      updateState((draft) => {
        set(draft, name, value)
      })
    }, [])

    async function prefetchItems(resetItems = false) {
      let response

      const { pageIndex = state.pageIndex } = getSessionItem(getStorageKey({ props }), {})

      const {
        search = state.search,
        sortByField = state.sortByField,
        sortOrder = state.sortOrder,
        filterDto = state.filterDto,
      } = getStorageItem(getStorageKey({ props }), {})

      try {
        if (resetItems) {
          updateState((draft) => {
            draft.items = null
            draft.recordCount = 0
          })
        }

        response = await props.getItems({
          pageIndex: firstPageIndex(),
          pageSize: (pageIndex + 1) * DEFAULT_PAGE_SIZE + 1,
          search,
          sortByField,
          sortOrder,
          ...filterDto,
        })

        updateState((draft) => {
          draft.items = response.value.data.items
          draft.recordCount = response.value.data.recordCount || draft.items.length
          draft.search = search
          draft.sortByField = sortByField
          draft.sortOrder = sortOrder
          draft.filterDto = filterDto
        })
      } catch (error) {
        setState('items', [])
        showError({ error })
      } finally {
        setState('viewDidEnter', true)
      }

      return response
    }

    async function fetchItems() {
      let response

      try {
        const { search = '', pageIndex = firstPageIndex(), filterDto = {}, sortByField, sortOrder } = state

        response = await props.getItems({
          pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          search,
          sortByField,
          sortOrder,
          ...filterDto,
        })

        if (isOnline) {
          updateState((draft) => {
            if (pageIndex <= firstPageIndex()) {
              draft.items = []
            } else {
              draft.items = draft.items || []
            }

            draft.items.splice((pageIndex - 1) * DEFAULT_PAGE_SIZE, 0, ...response.value.data.items)
            draft.recordCount = response.value.data.recordCount || draft.items.length
          })
        } else {
          updateState((draft) => {
            draft.items = response.value.data.items
            draft.recordCount = response.value.data.recordCount || draft.items.length
          })
        }
      } catch (error) {
        showError({ error })
        updateState((draft) => {
          draft.items = []
          draft.recordCount = 0
        })
      }

      return response
    }

    const searchItems = useDebouncedCallback(fetchItems, DEBOUNCE)

    const self = {
      props,
      state,
      updateState,
      setState,
      fetchItems,
      prefetchItems,
      isOnline,
      firstPageIndex,
    }

    async function handleBarcodeScan({ rawValue: value }) {
      setState('scanBarcodeIsOpen', false)
      await asyncSleep(500)
      refBarcode.current.setFocus()
      refBarcode.current.value = value
      setState('search', value)
    }

    function handleDelete(item) {
      const buttons = [
        { text: t('cancel'), role: 'cancel' },
        {
          text: t('delete'),
          handler: async () => {
            try {
              const response = await props.deleteItem(item[getIdField(self)])

              showClientNotifications({ response })
            } catch (error) {
              showError({ error })
            } finally {
              prefetchItems()
            }
          },
        },
      ]

      return () => {
        updateState((draft) => {
          draft.alertIsOpen = true
          draft.alertMessage = getDeleteConfirmMessage(self)
          draft.alertButtons = buttons
        })
      }
    }

    function loadMoreItems(startIndex) {
      setState('pageIndex', isOnline ? floor(startIndex / DEFAULT_PAGE_SIZE) + 1 : 0)
    }

    function isItemLoaded(index) {
      return state.items?.length > index
    }

    const Row = ({ index, style }) => {
      if (isItemLoaded(index)) {
        const item = state.items[index]
        const renderedItem = renderItem(self)(item)

        return (
          <div key={`${index}-${item[getIdField(self)]}`} style={style}>
            {allowDelete(self)(item) ? (
              <IonItemSliding>
                {renderedItem}
                <IonItemOptions side="end">
                  <IonItemOption color="danger" onClick={handleDelete(item)} expandable>
                    {t('delete')}
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ) : (
              renderedItem
            )}
          </div>
        )
      }

      return (
        <div style={style}>
          <div className="ion-text-center ion-margin-vertical">
            <IonSpinner name="lines-small" />
          </div>
        </div>
      )
    }

    const rowActionItems = getRowActionItems(self)
    const sortByFields = getSortByFields(self)
    const popoverExtraContent = getPopoverContent(self)
    const popoverContent = [
      !isNil(popoverExtraContent) ? <div key="extraContent">{popoverExtraContent}</div> : null,
      !isNil(Filter) ? (
        <IonItem
          key="showFilter"
          lines="none"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.popoverIsOpen = false
              draft.popoverEvent = null
              draft.modalIsOpen = true
              draft.modalContent = 'filter'
            })
          }}
          detail={false}
          button
        >
          <IonLabel>{t('showFilter')}</IonLabel>
        </IonItem>
      ) : null,
      !isEmpty(sortByFields) ? (
        <IonItem
          key="showSort"
          lines="none"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.popoverIsOpen = false
              draft.popoverEvent = null
              draft.popoverEvent = false
              draft.modalIsOpen = true
              draft.modalContent = 'sort'
            })
          }}
          detail={false}
          button
        >
          <IonLabel>{t('sortOptions')}</IonLabel>
        </IonItem>
      ) : null,
      !isEmpty(rowActionItems) ? (
        <IonItem
          key="showRowAction"
          onClick={() => {
            updateState((draft) => {
              draft.alertIsOpen = false
              draft.popoverIsOpen = false
              draft.popoverEvent = null
              draft.popoverEvent = false
              draft.rowActionsIsOpen = !draft.rowActionsIsOpen
              draft.selectedRowAction = null
              draft.selectedRowKeys = []
            })
          }}
          detail={false}
          button
        >
          <IonLabel>{state.rowActionsIsOpen ? t('hideRowActions') : t('showRowActions')}</IonLabel>
        </IonItem>
      ) : null,
    ].filter((each) => !isNil(each))

    const searchHeader = getSearchHeader(self)
    const listHeader = getListHeader(self)
    const pageToolbarButton = !isEmpty(popoverContent) ? (
      <IonButton
        onClick={(e) => {
          updateState((draft) => {
            draft.popoverIsOpen = true
            draft.popoverEvent = e
          })
        }}
        disabled={!isOnline && !allowOffline(self)}
      >
        {getToolbarIcon(self)}
      </IonButton>
    ) : null
    const searchBar = allowSearch(self) ? (
      <SearchbarContainer lines="none">
        <IonSearchbar
          ref={refBarcode}
          value={state.search}
          onIonChange={(e) => setState('search', e.detail.value)}
          debounce={DEBOUNCE}
          maxlength={200}
        />
        {props.scannerSettings.enableCameraScanning && (
          <Button onClick={() => setState('scanBarcodeIsOpen', true)}>
            <Icon type="barcode_scanner" style={{ color: 'var(--ion-color-primary)' }} symbols />
          </Button>
        )}
      </SearchbarContainer>
    ) : undefined
    const rowActions = state.rowActionsIsOpen ? (
      <RowActions
        style={{ padding: '0 11px 8px 12px' }}
        {...{
          setState,
          rowActionItems,
          idField: getIdField(self),
          items: state.items,
          onRowAction: getRowActionHandler(self),
          selectedRowKeys: state.selectedRowKeys,
        }}
      />
    ) : undefined
    const pageFooter = !isNil(state.items) ? getFooter(self) : undefined
    const pageHeader = [searchHeader, searchBar, listHeader, rowActions].some(Boolean) ? (
      <>
        {searchHeader}
        {searchBar}
        {listHeader}
        {rowActions}
      </>
    ) : null
    const itemCount = isNil(state.items)
      ? 0
      : isOnline
        ? Math.min(state.items.length + 1, state.recordCount)
        : state.items.length

    async function handleViewDidEnter() {
      try {
        updateState((draft) => {
          draft.alertIsOpen = false
          draft.modalIsOpen = false
          draft.popoverIsOpen = false
          draft.popoverEvent = null
        })

        await getViewDidEnterHandler(self)()
      } catch (error) {
        console.warn(error)
      } finally {
        prefetchItems(true)
      }
    }

    React.useEffect(() => {
      setStorageItem(
        getStorageKey({ props }),
        pick(
          state,
          [storeSearch({ props }) ? 'search' : null, 'sortByField', 'sortOrder', 'filterDto'].filter(Boolean)
        )
      )
    }, [state.search, state.sortByField, state.sortOrder, JSON.stringify(state.filterDto)])

    React.useEffect(() => {
      setSessionItem(
        getStorageKey({ props }),
        pick(state, ['pageIndex', 'rowActionsIsOpen', 'selectedRowKeys'])
      )
    }, [state.pageIndex, state.rowActionsIsOpen, state.selectedRowKeys])

    React.useEffect(() => {
      if (state.viewDidEnter) {
        fetchItems()
      }
    }, [state.pageIndex])

    React.useEffect(() => {
      if (state.viewDidEnter) {
        updateState((draft) => {
          draft.items = null
          draft.pageIndex = firstPageIndex()
          // draft.selectedRowKeys = [];
        })
        fetchItems()
      }
    }, [state.sortByField, state.sortOrder])

    React.useEffect(() => {
      if (state.viewDidEnter) {
        updateState((draft) => {
          draft.items = null
          draft.pageIndex = firstPageIndex()
          draft.selectedRowKeys = []
        })
        searchItems()
      }
    }, [state.search, JSON.stringify(state.filterDto)])

    React.useEffect(() => {
      if (state.viewDidEnter) {
        handleViewDidEnter()
      }
    }, [isOnline])

    useIonViewWillEnter(() => {
      setState('viewDidEnter', false)
    })

    useIonViewDidEnter(() => {
      handleViewDidEnter()
    })

    return (
      <Page title={pageTitle(self)} toolbarButton={pageToolbarButton} header={pageHeader} footer={pageFooter}>
        {isNil(state.items) ? (
          <IonSpinner className="ion-margin" />
        ) : isEmpty(state.items) ? (
          <p className="ion-margin">
            {!isOnline && !allowOffline(self)
              ? `${t('offlineStatus')} ${t('offlineStatusDescription')}`
              : t('noData')}
          </p>
        ) : allowInfiniteLoader(self) ? (
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={DEFAULT_PAGE_SIZE}
                threshold={floor(DEFAULT_PAGE_SIZE / 2)}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    ref={ref}
                    height={height}
                    itemCount={itemCount}
                    itemSize={getItemSize(self)}
                    width={width}
                    onItemsRendered={onItemsRendered}
                  >
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <IonList>
            {state.items.map((item, index) => {
              const renderedItem = renderItem(self)(item)
              return (
                <div key={`${index}-${item[getIdField(self)]}`}>
                  {allowDelete(self)(item) ? (
                    <IonItemSliding>
                      {renderedItem}
                      <IonItemOptions side="end">
                        <IonItemOption color="danger" onClick={handleDelete(item)} expandable>
                          {t('delete')}
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ) : (
                    renderedItem
                  )}
                </div>
              )
            })}
          </IonList>
        )}
        <IonModal isOpen={state.modalIsOpen}>
          {state.modalIsOpen && state.modalContent === 'filter' && (
            <Filter
              filterDto={state.filterDto ?? {}}
              onChange={(value) => {
                updateState((draft) => {
                  draft.filterDto = isEmpty(value)
                    ? pick(draft.filterDto, ['dateRange', 'dateRangeField'])
                    : value
                })
              }}
              onClose={() => {
                setState('modalIsOpen', false)
                removeSessionItem(FILTER_SCROLL_TOP)
              }}
            />
          )}
          {state.modalIsOpen && state.modalContent === 'sort' && (
            <Sort
              sortByFields={sortByFields}
              sortByField={state.sortByField}
              sortOrder={state.sortOrder}
              onChange={(e) => {
                updateState((draft) => {
                  draft.sortByField = e.sortByField
                  draft.sortOrder = e.sortOrder
                })
              }}
              onClose={() => setState('modalIsOpen', false)}
            />
          )}
        </IonModal>
        <IonPopover
          isOpen={state.popoverIsOpen}
          event={state.popoverEvent}
          onDidDismiss={() =>
            updateState((draft) => {
              draft.popoverIsOpen = false
              draft.popoverEvent = null
            })
          }
        >
          {popoverContent}
        </IonPopover>
        <IonLoading
          spinner="lines-small"
          isOpen={state.loadingIsOpen}
          message={state.loadingMessage ?? t('pleaseWait...')}
        />
        <IonAlert
          backdropDismiss={false}
          isOpen={state.alertIsOpen}
          header={state.alertHeader}
          message={state.alertMessage}
          buttons={state.alertButtons ?? [{ text: t('ok'), role: 'cancel' }]}
          onDidDismiss={() => setState('alertIsOpen', false)}
        />
        <IonModal isOpen={state.scanBarcodeIsOpen}>
          {state.scanBarcodeIsOpen && (
            <BarcodeScanner onScan={handleBarcodeScan} onClose={() => setState('scanBarcodeIsOpen', false)} />
          )}
        </IonModal>
      </Page>
    )
  }

import { isEmpty } from 'lodash'
import { IonItem, IonLabel, IonText, IonButton } from '@ionic/react'
import { createListViewComponent } from 'factories/ListView'
import { PLACEHOLDER } from 'helpers/utils'
import { t } from 'helpers/i18n'
import { createCheckOfflineJobs } from 'helpers/offlineData'
import { formatUserTime } from 'helpers/dateTime'
import { showError } from 'helpers/errors'
import { getSessionItem, removeSessionItem } from 'helpers/sessionStorage'
import Icon from 'elements/Icon'
import Filter from 'containers/Jobs/FormView/CreateFromTemplate/Filter'

export default createListViewComponent({
  pageTitleLanguageKey: 'selectTemplate',
  getStorageKey: () => 'jobs.formView.createFromTemplate',
  getViewDidEnterHandler: createCheckOfflineJobs,
  renderItem: (self) => (item) => (
    <IonItem
      key={item.id}
      onClick={() => self.setState('selectedRowKeys', [item.id])}
      lines="full"
      detail={false}
      button
    >
      <IonLabel>
        {item.barcode} - {item.name}
        <br />
        <IonText color="medium">
          <small>
            {t('location:')} {item.locationName || PLACEHOLDER}
            <br />
            {t('asset:')} {item.assetName || t('none')}
            <br />
            {t('created:')} {formatUserTime(item.createdDate, item.createdBy)}
          </small>
        </IonText>
      </IonLabel>
      <Icon.Radio checked={self.state.selectedRowKeys.includes(item.id)} />
    </IonItem>
  ),
  getFooter: (self) => (
    <IonButton
      color="secondary"
      expand="full"
      disabled={isEmpty(self.state.selectedRowKeys) || !self.isOnline}
      onClick={async () => {
        try {
          self.setState('loadingIsOpen', true)

          const parentState = getSessionItem('jobs.formView', {})

          const saved = await self.props.createFromTemplate(self.state.selectedRowKeys[0], {
            assetId: parentState.item?.assetId ?? 0,
            assetLocationId: parentState.item?.locationId ?? 0,
            assignedOperatorId: parentState.item?.assignedToId ?? 0,
          })

          removeSessionItem('jobs.formView')

          window.location.href = `/jobs/jobs/${saved.value.data.id}`
        } catch (error) {
          showError({ error })
        } finally {
          self.setState('loadingIsOpen', false)
        }
      }}
    >
      {t('select')}
    </IonButton>
  ),
  getItemSize: () => 93,
  allowSearch: (self) => true,
  allowOffline: (self) => false,
  getSortByFields: (self) => [
    { fieldName: 'id', languageKey: 'id' },
    { fieldName: 'createdDate', languageKey: 'createdDate' },
    { fieldName: 'locationName', languageKey: 'location' },
    { fieldName: 'assetBarcode', languageKey: 'asset' },
  ],
})(Filter)

import { getSessionItem } from 'helpers/sessionStorage'
import { getStorageItem } from 'helpers/localStorage'
import { SETTINGS_USER_STARTUP_KEY, SETTINGS_CUSTOMER_STARTUP_KEY } from 'options/settings'
import { customerStartupOptions, userStartupOptions } from 'options/startupOptions'
import { AUTH_SESSION_KEY } from 'options/auth'

export const getCustomerSettings = () => ({
  startupPage: getStorageItem(SETTINGS_CUSTOMER_STARTUP_KEY, Object.keys(customerStartupOptions)[0]),
})

export const getUserSettings = () => ({
  startupPage: getStorageItem(SETTINGS_USER_STARTUP_KEY, Object.keys(userStartupOptions)[0]),
  languageCode: getSessionItem(AUTH_SESSION_KEY, {}).current?.user?.languageCode ?? 'en',
})

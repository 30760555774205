import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import entityListActions from 'actions/entityLists'
import listItemsActions from 'actions/lists/listItems'
import Component from 'components/Lists/ChildListView'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getItems: entityListActions.getItems,
  deleteItems: entityListActions.deleteItems,
  getChildItems: listItemsActions.getChildItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

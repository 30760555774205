import produce from 'immer'
import { remove } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import { LOCATION_COUNTS_SAVED_KEY } from 'options/locationCounts'
import {
  wrapItems,
  sortItems,
  filterBySearch,
  filterLocationsByGroupIds,
  filterLocationsByVendingTypes,
} from 'helpers/utils'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { getSharedLocations } from 'helpers/offlineData'
import Component from 'components/LocationCounts/EditCounts'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const [items, locations] = await Promise.all([
        getPrivateItem(LOCATION_COUNTS_SAVED_KEY, []),
        getSharedLocations(),
      ])

      const unsortedItems = items
        .map((each) =>
          produce(each, (draft) => {
            const location = locations.find((one) => one.id === draft.locationId)
            draft.locationName = location.displayName
            draft.locationGroupIds = location.locationGroupIds
            draft.locationType = location.locationType
          })
        )
        .filter(filterLocationsByGroupIds(params.locationGroupIds))
        .filter(filterLocationsByVendingTypes(params.locationVendingType ? [params.locationVendingType] : []))
        .filter(filterBySearch(params.search))

      return Promise.resolve(sortItems(unsortedItems, params.sortByField, params.sortOrder)).then(wrapItems)
    },
    deleteItem: (id) =>
      updatePrivateItem(LOCATION_COUNTS_SAVED_KEY, [], (draft) => {
        remove(draft, (each) => each.id === id)
      }),
  }),
})(Component)

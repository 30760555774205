import produce from 'immer'
import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import { getSharedLocations } from 'helpers/offlineData'
import { wrapItems, filterBySearch } from 'helpers/utils'
import Component from 'components/Requisitions/AddItems/SelectLocation'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(locationActions.getItems({ locationVendingTypes: ['NonVending'], ...params }))
        .catch(async (error) => {
          if (!error.response) {
            const items = await getSharedLocations().then((r) =>
              r
                .filter((each) => ['Primary', 'Secondary'].includes(each.locationType))
                .filter(filterBySearch(params.search))
            )

            return wrapItems(items)
          }

          throw error
        })
        .then((r) =>
          produce(r, (draft) => {
            if (params.pageIndex <= 1 && !params.search) {
              draft.value.data.items.unshift({ id: 0, displayName: '' })
              draft.value.data.recordCount++
            }
          })
        ),
  }),
})(Component)

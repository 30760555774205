import { connect } from 'react-redux'
import { createAuthStateToProps } from 'helpers/auth'
import locationGroupActions from 'actions/locationGroups'
import locationActions from 'actions/locations'
import Component from 'components/Inventory/ListView/Filter'

const mapStateToProps = (state, props) => ({ ...createAuthStateToProps(state, props) })

const mapDispatchToProps = {
  getLocationGroups: locationGroupActions.getItemsOffline,
  getLocations: locationActions.getItemsOffline,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)

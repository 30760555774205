import { createSelectionListViewContainer } from 'factories/SelectionListView'
import locationActions from 'actions/locations'
import { getSharedLocations } from 'helpers/offlineData'
import { wrapItems, filterBySearch } from 'helpers/utils'
import Component from 'components/Issue/IssueItem/SelectLocation'

export default createSelectionListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: (params = {}) =>
      dispatch(locationActions.getItems({ locationVendingTypes: ['NonVending'], ...params })).catch(
        async (error) => {
          if (!error.response) {
            const items = await getSharedLocations().then((r) =>
              r
                .filter((each) => ['Primary', 'Secondary'].includes(each.locationType))
                .filter(filterBySearch(params.search))
            )

            return wrapItems(items)
          }

          throw error
        }
      ),
  }),
})(Component)

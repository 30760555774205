import * as React from 'react'
import { useImmer } from 'use-immer'
import {
  IonSpinner,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonToggle,
  IonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import cx from 'clsx'
import { get, set, isNil, isEmpty, pick, camelCase, cloneDeep, toString as str, omit, trimStart } from 'lodash'
import { tryParseInt, getTagDisplayNameField, getTagName, tryParseFloat, PLACEHOLDER, message } from 'helpers/utils'
import { allowPricing, getUserPermission } from 'helpers/auth'
import { stopEvent } from 'helpers/events'
import { t } from 'helpers/i18n'
import { formatUserTime } from 'helpers/dateTime'
import { showError, showValidationErrors, showClientNotifications } from 'helpers/errors'
import { getSessionItem, setSessionItem, removeSessionItem, updateSessionItem } from 'helpers/sessionStorage'
import {
  validateOperatorTag,
  validateJobTag,
  validateAssetTag,
  validateOtherTags,
  validateQuantityRequisitioned,
  validateQuantityIssued,
  getTagFieldNames,
} from 'helpers/procurement'
import Page from 'elements/Page'
import Tabs from 'elements/Tabs'
import Icon from 'elements/Icon'
import useOnlineStatus from '@rehooks/online-status'

export const tagTypes = [
  '2', // Operator
  '1', // Job
  '3', // Asset
  'A',
  'B',
  'C',
  'D',
  'E',
]

export const getStorageKey = () => 'requisitions.formView.requisitionItems.formView'

export default function (props) {
  const { parentStorageKey = 'requisitions.formView' } = props

  const isOnline = useOnlineStatus()

  const [state, updateState] = useImmer({ tabsActiveKey: 'item' })

  const setState = React.useCallback((name, value) => {
    updateState((draft) => {
      set(draft, name, value)
    })
  }, [])

  const setItemValue = React.useCallback((name, value) => {
    updateState((draft) => {
      if (!props.customer.generalSettings.allowOverIssueFromRequisition) {
        if (name === 'quantity') {
          validateQuantityRequisitioned(draft.item.quantityIssued, value)
        }

        if (name === 'quantityIssued') {
          validateQuantityIssued(draft.item.quantity, value)
        }
      }

      set(draft, `item.${name}`, value)
    })
  }, [])

  async function fetchItem(itemId = props.match.params.itemId) {
    try {
      let item = null
      const parentState = getSessionItem(parentStorageKey)
      const parentRecord = parentState.item

      if (itemId) {
        item = cloneDeep(parentState.requisitionItems.find((one) => str(one.id) === itemId))
      } else {
        const values = getSessionItem('requisitions.addItems', {}).item

        item = await props
          .newItem({
            assetDisplayName: values.assetDisplayName,
            assetId: values.assetId,
            jobDisplayName: values.jobDisplayName,
            jobId: values.jobId,
            locationDisplayName: values.locationDisplayName,
            locationId: values.locationId,
            locationName: values.locationName,
            operatorDisplayName: values.operatorDisplayName,
            operatorId: values.operatorId,
            tagA: values.tagA,
            tagB: values.tagB,
            tagC: values.tagC,
            tagD: values.tagD,
            tagE: values.tagE,
            quantity: values.quantity || 1,
            orderSupplierId: 0,
            nonStock: true,
            quantityIssued: 0,
            price: 0,
            barcode: `SO${Date.now() * 10000 + 621355968000000000}`,
            packageSize: 1,
            userName: props.user.userName,
            populated: false,
          })
          .then((r) => r.value.data)
          .then(cloneDeep)
      }

      const assetName = trimStart(item.assetName, '*')
      item.assetDisplayName = item.assetId
        ? await props
            .getAsset(item.assetId)
            .then((r) => r.value.data.displayName)
            .catch(() => assetName)
        : assetName

      const jobName = trimStart(item.jobName, '*')
      item.jobDisplayName = item.jobId
        ? await props
            .getJob(item.jobId)
            .then((r) => r.value.data.displayName)
            .catch(() => jobName)
        : jobName

      const operatorName = trimStart(item.operatorName, '*')
      item.operatorDisplayName = item.operatorId
        ? await props
            .getOperator(item.operatorId)
            .then((r) => r.value.data.displayName)
            .catch(() => operatorName)
        : operatorName

      updateState((draft) => {
        draft.item = item
        draft.parentState = parentState
        draft.parentRecord = parentRecord
      })
    } catch (error) {
      showError({ error })
    }
  }

  function getTagIsEnabled(tagType) {
    return props.user.coreUserSettings.tagSettings[`tag${tagType}EnabledPurch`]
  }

  function getTagIsRequired(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Required`]
  }

  function getTagIsShowList(tagType) {
    return ['1', '2', '3'].includes(tagType) || props.customer.tagSettings[`tag${tagType}ShowList`]
  }

  function getTagIsRestricted(tagType) {
    return getTagIsEnabled(tagType) && props.customer.tagSettings[`tag${tagType}Restricted`]
  }

  function getTagDisplayName(tagType) {
    return state.item[getTagDisplayNameField(tagType)]
  }

  function handleTagClick(tagType) {
    return (e) => {
      stopEvent(e)
      setSessionItem(getStorageKey(), pick(state, ['item']))
      props.history.push(`${props.match.url}/select${getTagName(tagType)}`)
    }
  }

  function handleTagInput(tagType) {
    return (e) => {
      updateState((draft) => {
        draft.item[getTagDisplayNameField(tagType)] = e.target.value || ''

        if (['1', '2', '3'].includes(tagType)) {
          ;['id', 'number', 'name', 'barcode'].forEach((field) => {
            set(draft.item, camelCase(`${getTagName(tagType)}-${field}`), undefined)
          })
        }
      })
    }
  }

  async function validateFields(callback) {
    const errors = {}
    const values = cloneDeep(state.item)

    if (isEmpty(values.barcode)) {
      errors.barcode = t('errorMissingRequiredField')
    }

    if (!values.quantity) {
      setItemValue('quantity', 1)
    }

    if (!values.locationId) {
      errors.locationId = t('errorMissingRequiredField')
    }

    if (isEmpty(values.itemNumber)) {
      errors.itemNumber = t('errorMissingRequiredField')
    }

    if (isEmpty(values.description)) {
      errors.description = t('errorMissingRequiredField')
    }

    if (isEmpty(values.unitOfMeasure) && state.item.nonStock && !(state.item.id > 0)) {
      errors.unitOfMeasure = t('errorMissingRequiredField')
    }

    await validateOperatorTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateJobTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateAssetTag({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    await validateOtherTags({ props, values, errors, getTagIsRestricted, getTagIsRequired })

    setState('item', values)

    callback(errors, values)
  }

  function saveItem() {
    validateFields(async (errors, values) => {
      setState('errors', errors)

      if (isEmpty(errors)) {
        try {
          setState('loadingIsOpen', true)

          const { copyTagValuesToAllItems } = values

          const requisitionItem = {
            ...values,
            quantity: values.quantity || 1,
            quantityIssued: values.quantityIssued || 0,
            assetName: values.assetName || `*${values.assetDisplayName}`,
            jobName: values.jobName || `*${values.jobDisplayName}`,
            locationName: values.locationName || `*${values.locationDisplayName}`,
            operatorName: values.operatorName || `*${values.operatorDisplayName}`,
            assetDisplayName: values.assetDisplayName,
            operatorDisplayName: values.operatorDisplayName,
            jobDisplayName: values.jobDisplayName,
            locationDisplayName: values.locationDisplayName,
            copyTagValuesToAllItems: false,
          }

          const response = await props.populateRequisitionItems([omit(requisitionItem, ['id', 'requisitionId'])])

          setState('loadingIsOpen', false)
          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }

          const populatedItem = cloneDeep(response.value.data.items[0])

          populatedItem.id = populatedItem.requisitionItemId = values.id || Date.now() * -1
          populatedItem.requisitionId = tryParseInt(props.match.params.parentId, 0)
          populatedItem.populated = true
          populatedItem.timestamp = new Date().toJSON()

          updateSessionItem(parentStorageKey, {}, (draft) => {
            try {
              const index = draft.requisitionItems.findIndex((one) => str(one.id) === props.match.params.itemId)

              if (index > -1) {
                draft.requisitionItems.splice(index, 1, populatedItem)
              } else {
                draft.requisitionItems.unshift(populatedItem)
              }

              message.success(`${populatedItem.barcode} ${t('addToRequisitionSuccess')}`)

              if (copyTagValuesToAllItems) {
                const item = draft.requisitionItems.find((one) => str(one.id) === props.match.params.itemId)
                const tagValues = pick(item, getTagFieldNames())
                draft.requisitionItems
                  .filter((each) => each.id !== item.id)
                  .forEach((each) => Object.assign(each, tagValues))
              }

              props.history.goBack()
            } catch (error) {
              showError({ error })
            }
          })
        } catch (error) {
          setState('loadingIsOpen', false)
          showError({ error })
        } finally {
          setState('loadingIsOpen', false)
        }
      } else {
        setState('loadingIsOpen', false)
        showValidationErrors({ errors })
      }
    })
  }

  useIonViewDidEnter(() => {
    const sessionItem = getSessionItem(getStorageKey())

    if (isNil(sessionItem)) {
      fetchItem()
    } else {
      updateState((draft) => {
        Object.assign(draft, omit(sessionItem, ['loadingIsOpen']))
      })
    }

    removeSessionItem(getStorageKey())
  })

  const pageTitle = props.match.params.itemId ? t('requisitionItem') : t('addNonStockItem')
  const backButtonHref = `/orders/requisitions/${props.match.params.parentId}`

  if (isNil(state.item)) {
    return (
      <Page title={pageTitle} backButtonHref={backButtonHref}>
        <IonSpinner className="ion-margin" />
      </Page>
    )
  }

  const enabledTagTypes = tagTypes.filter(getTagIsEnabled)
  const quantityIssuedEnabled =
    state.parentRecord.approved === true &&
    ['Open', 'Closed'].includes(state.parentRecord.status) &&
    getUserPermission('Issue') === 'Yes'
  const { readOnly } = state.parentState ?? {}

  return (
    <Page
      title={pageTitle}
      backButtonHref={backButtonHref}
      footer={
        readOnly ? (
          <IonButton color="secondary" expand="full" onClick={() => props.history.goBack()}>
            {t('close')}
          </IonButton>
        ) : (
          <IonButton color="secondary" expand="full" onClick={() => saveItem()} disabled={!isOnline}>
            {props.match.params.itemId ? t('update') : t('save')}
          </IonButton>
        )
      }
    >
      <Tabs activeKey={state.tabsActiveKey} onChange={(value) => setState('tabsActiveKey', value)}>
        <Tabs.TabPane key="item" tab={t('item')} forceRender>
          <IonItem
            lines="full"
            className={cx('tofino-required-item', {
              'tofino-error-item': state.errors?.barcode,
            })}
          >
            <IonLabel position="stacked">{t('barcode')}</IonLabel>
            <IonInput
              value={state.item.barcode}
              onIonInput={(e) => setItemValue('barcode', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly || !state.item.nonStock || state.item.id > 0}
            />
          </IonItem>
          {['itemNumber', 'description'].map((each) => (
            <IonItem
              key={each}
              lines="full"
              className={cx('tofino-required-item', {
                'tofino-error-item': get(state, `errors.${each}`),
              })}
            >
              <IonLabel position="stacked">{t(each)}</IonLabel>
              <IonInput
                value={get(state, `item.${each}`)}
                onIonInput={(e) => setItemValue(each, e.target.value)}
                placeholder={PLACEHOLDER}
                disabled={readOnly}
              />
            </IonItem>
          ))}
          <IonRow>
            <IonCol>
              <IonItem
                lines="full"
                className={cx('tofino-required-item', {
                  'tofino-error-item': state.errors?.quantity,
                })}
              >
                <IonLabel position="stacked">{t('quantityRequisitioned')}</IonLabel>
                <IonInput
                  value={state.item.quantity}
                  onIonInput={(e) => setItemValue('quantity', tryParseInt(e.target.value, 1))}
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  placeholder={PLACEHOLDER}
                  min={1}
                  disabled={readOnly}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
            {allowPricing() && (
              <IonCol>
                <IonItem lines="full">
                  <IonLabel position="stacked">{t('price')} ($)</IonLabel>
                  <IonInput
                    value={state.item.price}
                    onIonInput={(e) => setItemValue('price', tryParseFloat(e.target.value, 0.0))}
                    type="number"
                    inputmode="number"
                    inputMode="number"
                    placeholder={PLACEHOLDER}
                    min={0}
                    disabled={readOnly}
                    clearOnEdit
                  />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('quantityIssued')}</IonLabel>
                <IonInput
                  value={state.item.quantityIssued}
                  onIonInput={(e) => setItemValue('quantityIssued', tryParseInt(e.target.value, 0))}
                  type="number"
                  inputmode="number"
                  inputMode="number"
                  placeholder={PLACEHOLDER}
                  min={0}
                  disabled={readOnly || !quantityIssuedEnabled}
                  clearOnEdit
                />
              </IonItem>
            </IonCol>
            {state.item.nonStock && !(state.item.id > 0) && (
              <IonCol>
                <IonItem
                  lines="full"
                  className={cx('tofino-stacked-item tofino-required-item', {
                    'tofino-error-item': state.errors?.unitOfMeasure,
                  })}
                  onClick={() => {
                    setSessionItem(getStorageKey(), pick(state, ['item']))
                    props.history.push(`${props.match.url}/selectUnitOfMeasure`)
                  }}
                  disabled={readOnly}
                  button
                >
                  <IonLabel>
                    <IonText color="medium">
                      <small>{t('uom')}</small>
                    </IonText>
                    <br />
                    {state.item.unitOfMeasure || <span className="tofino-placeholder">{PLACEHOLDER}</span>}
                  </IonLabel>
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('package')}</IonLabel>
                <IonInput
                  value={state.item.id > 0 ? state.item.packageSizeUom : state.item.packageSize}
                  placeholder={PLACEHOLDER}
                  disabled
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="full">
                <IonLabel position="stacked">{t('binLocation')}</IonLabel>
                <IonInput
                  value={state.item.binLocation}
                  onIonInput={(e) => setItemValue('binLocation', e.target.value)}
                  placeholder={PLACEHOLDER}
                  disabled={readOnly}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem
            lines="full"
            className={cx('tofino-stacked-item tofino-required-item', {
              'tofino-error-item': state.errors?.locationId,
            })}
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectLocation`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('location')}</small>
              </IonText>
              <br />
              {state.item.locationName || state.item.locationId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            className="tofino-stacked-item"
            onClick={() => {
              setSessionItem(getStorageKey(), pick(state, ['item']))
              props.history.push(`${props.match.url}/selectSupplier`)
            }}
            disabled={readOnly}
            button
          >
            <IonLabel>
              <IonText color="medium">
                <small>{t('supplier')}</small>
              </IonText>
              <br />
              {state.item.orderSupplierName || state.item.orderSupplierId || (
                <span className="tofino-placeholder">{PLACEHOLDER}</span>
              )}
            </IonLabel>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('manufacturer')}</IonLabel>
            <IonInput
              value={state.item.manufacturer}
              onIonInput={(e) => setItemValue('manufacturer', e.target.value)}
              placeholder={PLACEHOLDER}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('expedited')}</IonLabel>
            <IonToggle
              checked={state.item.expedite}
              onIonChange={(e) => setItemValue('expedite', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('taxable')}</IonLabel>
            <IonToggle
              checked={state.item.taxable}
              onIonChange={(e) => setItemValue('taxable', e.detail.checked)}
              disabled={readOnly}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel>{t('nonInventoryItem')}</IonLabel>
            <IonToggle
              checked={state.item.nonInventory}
              onIonChange={(e) => setItemValue('nonInventory', e.detail.checked)}
              disabled={readOnly || !state.item.nonStock || state.item.id > 0}
            />
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="stacked">{t('itemOrdered')}</IonLabel>
            <IonInput
              value={state.item.itemOrdered ? formatUserTime(state.item.orderDate, state.item.orderBy) : t('no')}
              placeholder={PLACEHOLDER}
              disabled
            />
          </IonItem>
        </Tabs.TabPane>
        {!isEmpty(enabledTagTypes) && (
          <Tabs.TabPane key="tags" tab={t('tags')} forceRender>
            {enabledTagTypes.map((tagType) => (
              <IonItem
                key={tagType}
                lines="full"
                className={cx('tofino-stacked-item', {
                  'tofino-required-item': getTagIsRequired(tagType),
                  'tofino-error-item': get(state, `errors.${tagType}`),
                })}
                disabled={readOnly}
              >
                <IonLabel position="stacked">{props.customer.tagSettings[`tag${tagType}`]}</IonLabel>
                <IonInput
                  value={getTagDisplayName(tagType)}
                  placeholder={PLACEHOLDER}
                  onIonInput={handleTagInput(tagType)}
                  onIonBlur={() => validateFields(() => setState('loadingIsOpen', false))}
                  disabled={readOnly}
                />
                {getTagIsShowList(tagType) && <Icon.Chevron onClick={handleTagClick(tagType)} />}
              </IonItem>
            ))}
            {!readOnly ? (
              <IonItem lines="full">
                <IonLabel>{t('copyTagValuesToAllItems')}</IonLabel>
                <IonToggle
                  checked={state.item.copyTagValuesToAllItems}
                  onIonChange={(e) => setItemValue('copyTagValuesToAllItems', e.detail.checked)}
                />
              </IonItem>
            ) : null}
          </Tabs.TabPane>
        )}
      </Tabs>
      <IonLoading
        spinner="lines-small"
        isOpen={state.loadingIsOpen}
        message={state.loadingMessage ?? t('pleaseWait...')}
      />
    </Page>
  )
}

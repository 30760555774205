export const NOTIFICATION = 'NOTIFICATION'
export const DEFER_403_WITH = 'DEFER_403_WITH'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS'

export const LISTS_DELETE_ITEM_EVENT = 'lists.childListView.deleteItem'
export const LISTS_FETCH_ITEMS_EVENT = 'lists.childListView.fetchItems'
export const LISTS_SELECT_ITEM_EVENT = 'lists.childListView.selectItem'
export const LISTS_CHANGE_ITEM_EVENT = 'lists.childListView.changeItem'
export const LISTS_CREATE_ITEMS_EVENT = 'lists.childListView.createItems'

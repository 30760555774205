import { remove, isEmpty, defaultTo } from 'lodash'
import { createListViewContainer } from 'factories/ListView'
import { COUNTED_ITEMS_SAVED_KEY } from 'options/inventory/count'
import { filterBySearch, filterLocationsByGroupIds, wrapItems, sortItems } from 'helpers/utils'
import { getPrivateItem, updatePrivateItem } from 'helpers/localForage'
import { getSharedLocations } from 'helpers/offlineData'
import Component from 'components/Count/ReviewItems'

export default createListViewContainer({
  extraDispatchToProps: (dispatch, props) => ({
    getItems: async (params = {}) => {
      const { locationGroupIds = [], locationIds = [] } = params
      const [items, locations] = await Promise.all([
        getPrivateItem(COUNTED_ITEMS_SAVED_KEY, []),
        getSharedLocations(),
      ])

      const filterLocationIds =
        isEmpty(locationIds) && !isEmpty(locationGroupIds)
          ? locations.filter(filterLocationsByGroupIds(locationGroupIds)).map((each) => each.id)
          : locationIds

      const unsortedItems = items
        .map((each) => ({
          ...each,
          quantityCounted: defaultTo(each.count, 0) + defaultTo(each.count2, 0),
        }))
        .filter((each) => isEmpty(filterLocationIds) || filterLocationIds.includes(each.locationId))
        .filter(filterBySearch(params.search))

      return Promise.resolve(
        sortItems(unsortedItems, params.sortByField ?? 'timestamp', params.sortOrder ?? 'desc')
      ).then(wrapItems)
    },
    deleteItem: (id) =>
      updatePrivateItem(COUNTED_ITEMS_SAVED_KEY, [], (draft) => {
        remove(draft, (each) => each.id === id)
      }),
  }),
})(Component)
